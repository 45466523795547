import axios from "axios";
import React, { useEffect, useState } from "react";
import ButtonFilled from "./ButtonFilled";
import HotelCard from "./card/HotelCard";
import "../App.css";
import ButtonOutlined from "./ButtonOutlined";
import HorizontalBar from "./HorizontalBar/HorizontalBar";
import Loading from "./Loader/Loading";
import Pagenation from "./pagenation/Pagenation";
import { useDispatch, useSelector } from "react-redux";
import { hotelsInfo } from "../store/admin/hotel/api/adminContractApi";
import { adminRoute, merchantRoute, UserType } from "../contants/Constants";
import { returnAdminOrUserBasedOnPath } from "../utils/returnAdminOrUserBasedOnPath";
import { useLocation, useNavigate } from "react-router";
import dashboardOrRegistrationRoute, {
  RouteType,
} from "../utils/dashboardOrRegistrationRoute";

const AddHotel = ({ withoutHeader }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let superContractId = localStorage.getItem("superContractId");
  const [totalElement, setTotalElement] = useState(0);
  const [listHotel, setListHotel] = useState();

  const response = useSelector((state) => state.hotelsInfoGetReducer);

  console.log(location, "location");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      hotelsInfo(
        returnAdminOrUserBasedOnPath(location.pathname),
        superContractId
      )
    );
  }, []);

  useEffect(() => {
    if (response && response.data && response.data.propertyDataList) {
      setTotalElement(response.data.totalElements);
      setListHotel(response.data.propertyDataList);
    }
  }, [response]);

  const handleNewHotel = () => {
    window.location.href = `${merchantRoute.HOTEL + 0}`;
  };
  const buttonOutlinedHandle = () => {
    if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
      navigate(adminRoute.ADMIN_DOCUMENT);
    } else {
      navigate(merchantRoute.DOCUMENT);
    }
  };
  const buttonFilledHandle = () => {
    if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
      navigate(adminRoute.ADMIN_CATEGORIES);
    } else {
      navigate(merchantRoute.CATEGORIES);
    }
  };

  return (
    <div className="w-full">
      {withoutHeader ? (
        <></>
      ) : (
        <>
          {returnAdminOrUserBasedOnPath(location.pathname) == UserType.USER ? (
            <Pagenation owner="owner" />
          ) : null}
          {dashboardOrRegistrationRoute() === RouteType.REGISTRATION && (
            <HorizontalBar selectedBar="Hotel" />
          )}
        </>
      )}

      {response.loading ? (
        <div className="ml-[45%]">
          <Loading />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-0.5 p-10 xl:px-0 xl:container mx-auto">
            {listHotel && listHotel?.length > 0
              ? listHotel?.map((each) => {
                  return (
                    <div
                      className="w-auto h-auto text-lg m-[10px] rounded-[8px] "
                      key={each.id}
                    >
                      <HotelCard
                        id={each.id}
                        place={each.address.place}
                        zip={each.address.zip}
                        street={each.address.street}
                        propertyName={each.propertyName}
                        bedRooms={each.numberOfRooms}
                        status={each.status}
                        // image={each.images[0].url ? each.images[0].url : null}
                      />
                    </div>
                  );
                })
              : null}
            {returnAdminOrUserBasedOnPath(location.pathname) ==
            UserType.USER ? (
              <div
                onClick={handleNewHotel}
                className="w-[315px] h-[92%] cursor-pointer font-medium text-center text-btn-secondary custom-shadow py-[50px] bg-bg-primary text-lg m-[10px] rounded-[16px] "
              >
                <h2 className="py-6 text-lg">Add Hotel</h2>
                <h1 className=" bg-btn-secondary text-bg-white rounded-full ml-[45%] w-[27px] h-[27px]">
                  +
                </h1>
              </div>
            ) : null}
          </div>
          {/* <HotelCard /> */}
          <div className="flex mt-16 px-10 xl:p-0 xl:container mx-auto">
            <ButtonOutlined
              title="Back"
              buttonOutlinedHandle={buttonOutlinedHandle}
              w="176px"
              h="49px"
            />
            {listHotel && listHotel.length > 0 ? (
              <ButtonFilled
                title="Next"
                // disable="yes"
                buttonFilledHandle={buttonFilledHandle}
                w="291px"
                h="49px"
              />
            ) : (
              <ButtonFilled
                title="Next"
                disable="yes"
                buttonFilledHandle={buttonFilledHandle}
                w="291px"
                h="49px"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddHotel;
