import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Calender from "../../components/Calender";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { convertStringToObject } from "../AllPackages/ParentPackageSelected";

const Abibility = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [packageTypeSummary, setPackageTypeSummary] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [rejectionArray, setRejectionArray] = useState([]);

  const convertStateObject = (originalState) => {
    // Extract relevant information from the original state object
    const {
      requestId,
      tour_package_id = convertData?.packageId,
      tour_start_date = startDate
        .toLocaleDateString("en-CA", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-"),
      total_seats = 4,
      bookable_seats = 4,
      tour_package_accommodation_options,
      tour_package_food_options,
      tour_package_transfer_options,
      tour_package_transportation_packages,
      tour_package_accommodation_is_inclusive,
      tour_package_food_is_inclusive,
      tour_package_transfer_is_inclusive,
      tour_package_guide_options_is_inclusive,
      tour_package_guide_options,
      tour_package_spot_entry_options,
      tour_package_spot_entry_is_inclusive,
    } = originalState;

    // Convert accommodation options
    const convertedAccommodationOptions =
      tour_package_accommodation_options.map((accommodationOption) => ({
        tour_package_accommodation_packages:
          accommodationOption.tour_package_accommodation_packages.map(
            (accommodationPackage) => ({
              tour_package_accommodation_package_id:
                accommodationPackage.tour_package_accommodation_package_id,
              per_night_room_price: Number(
                accommodationPackage.per_night_room_price
              ),
            })
          ),
        tour_package_option_is_active:
          accommodationOption.tour_package_option_is_active,
        tour_package_option_is_default:
          accommodationOption.tour_package_option_is_default,
      }));

    // Convert food options
    const convertedFoodOptions = tour_package_food_options.map(
      (foodOption) => ({
        tour_package_meal_packages: foodOption.tour_package_meal_packages.map(
          (mealPackage) => ({
            tour_package_meal_package_id:
              mealPackage.tour_package_meal_package_id,
            per_meal_package_price: Number(mealPackage.per_meal_package_price),
          })
        ),
        tour_package_option_is_active: foodOption.tour_package_option_is_active,
        tour_package_option_is_default:
          foodOption.tour_package_option_is_default,
      })
    );

    // Convert transfer options
    const convertedTransferOptions = tour_package_transfer_options.map(
      (transferOption) => ({
        tour_package_option_is_active:
          transferOption.tour_package_option_is_active,
        tour_package_option_is_default:
          transferOption.tour_package_option_is_default,
        tour_package_transfer_packages:
          transferOption.tour_package_transfer_packages.map(
            (transferPackage) => ({
              per_vehicle_per_trip_price: Number(
                transferPackage.per_vehicle_per_trip_price
              ),
              tour_package_transfer_package_id:
                transferPackage.tour_package_transfer_package_id,
            })
          ),
      })
    );

    // Convert transportation packages
    const convertedTransportationPackages =
      tour_package_transportation_packages.map((transportationPackage) => ({
        tour_package_option_is_active:
          transportationPackage.tour_package_option_is_active,
        tour_package_option_is_default:
          transportationPackage.tour_package_option_is_default,
        tour_package_transportation_package_id:
          transportationPackage.tour_package_transportation_package_id,
        transportation_package_unit_price: Number(
          transportationPackage.transportation_package_unit_price
        ),
      }));

    // Convert spot packages
    const convertedSpotPackages = tour_package_spot_entry_options?.map(
      (spotEntity) => ({
        tour_package_option_is_active: spotEntity.tour_package_option_is_active,
        tour_package_option_is_default:
          spotEntity.tour_package_option_is_default,
        tour_package_spot_entry_packages:
          spotEntity.tour_package_spot_entries.map((entryPackage) => ({
            tour_package_spot_entry_package_id:
              entryPackage.tour_package_spot_entry_package_id,
            spot_entry_price_per_person: Number(
              entryPackage.spot_entry_price_per_person
            ),
          })),
      })
    );

    // Convert Guide packages

    const convertedGuidePackages = tour_package_guide_options?.map(
      (guideEntity) => ({
        tour_package_option_is_active:
          guideEntity.tour_package_option_is_active,
        tour_package_option_is_default:
          guideEntity.tour_package_option_is_default,
        tour_package_guide_packages: guideEntity.guide_packages.map(
          (entryPackage) => ({
            tour_package_guide_package_id:
              entryPackage.tour_package_guide_package_id,
            tour_package_guide_package_price: Number(
              entryPackage.total_guide_price_for_day
            ),
          })
        ),
      })
    );
    // Create the new state object
    const newState = {
      requestId,
      tour_package_id,
      tour_start_date,
      total_seats,
      bookable_seats,
      tour_package_accommodation_options: convertedAccommodationOptions,
      tour_package_food_options: convertedFoodOptions,
      tour_package_transfer_options: convertedTransferOptions,
      tour_package_transportation_packages: convertedTransportationPackages,
      tour_package_spot_entry_options: convertedSpotPackages,
      tour_package_guide_options: convertedGuidePackages,
      tour_package_accommodation_is_inclusive,
      tour_package_food_is_inclusive,
      tour_package_transfer_is_inclusive,
      tour_package_spot_entry_is_inclusive,
      tour_package_guide_options_is_inclusive,
    };

    return newState;
  };

  const handleAccommodationOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const accommodationOption =
      updatedAb.tour_package_accommodation_options[index];

    accommodationOption.tour_package_option_is_active =
      !accommodationOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const handleTransferOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const transferOption = updatedAb.tour_package_transfer_options[index];

    transferOption.tour_package_option_is_active =
      !transferOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const handleSpotOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const transferOption = updatedAb.tour_package_spot_entry_options[index];

    transferOption.tour_package_option_is_active =
      !transferOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const handleGuideOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const transferOption = updatedAb.tour_package_guide_options[index];

    transferOption.tour_package_option_is_active =
      !transferOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const handleFoodOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const foodOption = updatedAb.tour_package_food_options[index];

    foodOption.tour_package_option_is_active =
      !foodOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const handleTransportationOptionToggle = (index) => {
    const updatedAb = { ...packageTypeSummary };

    const transportationOption =
      updatedAb.tour_package_transportation_packages[index];

    transportationOption.tour_package_option_is_active =
      !transportationOption.tour_package_option_is_active;

    setPackageTypeSummary(updatedAb);
  };

  const updateTransferPackagePrice = (tranferIndex, packageIndex, e) => {
    console.log(tranferIndex, packageIndex, e.target.value);
    setPackageTypeSummary((prevState) => {
      const newState = { ...prevState };

      newState.tour_package_transfer_options[
        tranferIndex
      ].tour_package_transfer_packages[packageIndex] = {
        ...newState.tour_package_transfer_options[tranferIndex]
          .tour_package_transfer_packages[packageIndex],
        per_vehicle_per_trip_price: e.target.value,
      };

      return newState;
    });
  };

  const updateSpotPackagePrice = (tranferIndex, packageIndex, e) => {
    setPackageTypeSummary((prevState) => {
      const newState = { ...prevState };

      newState.tour_package_spot_entry_options[
        tranferIndex
      ].tour_package_spot_entries[packageIndex] = {
        ...newState.tour_package_spot_entry_options[tranferIndex]
          .tour_package_spot_entries[packageIndex],
        spot_entry_price_per_person: e.target.value,
      };

      return newState;
    });
  };

  const updateGuidePackagePrice = (tranferIndex, packageIndex, e) => {
    setPackageTypeSummary((prevState) => {
      const newState = { ...prevState };

      newState.tour_package_guide_options[tranferIndex].guide_packages[
        packageIndex
      ] = {
        ...newState.tour_package_guide_options[tranferIndex].guide_packages[
          packageIndex
        ],
        total_guide_price_for_day: e.target.value,
      };

      return newState;
    });
  };

  const updateMealPackagePrice = (foodIndex, packageIndex, e) => {
    setPackageTypeSummary((prevAb) => {
      const newAb = { ...prevAb };

      if (
        foodIndex >= 0 &&
        foodIndex < newAb?.tour_package_food_options?.length
      ) {
        if (
          packageIndex >= 0 &&
          packageIndex <
            newAb.tour_package_food_options[foodIndex]
              .tour_package_meal_packages.length
        ) {
          newAb.tour_package_food_options[foodIndex].tour_package_meal_packages[
            packageIndex
          ].per_meal_package_price = e.target.value;
        }
      }

      return newAb;
    });
  };
  const updateAccommodationPrice = (accommodationIndex, packageIndex, e) => {
    setPackageTypeSummary((prevAb) => {
      const newAb = { ...prevAb };

      if (
        accommodationIndex >= 0 &&
        accommodationIndex < newAb?.tour_package_accommodation_options?.length
      ) {
        if (
          packageIndex >= 0 &&
          packageIndex <
            newAb.tour_package_accommodation_options[accommodationIndex]
              .tour_package_accommodation_packages.length
        ) {
          newAb.tour_package_accommodation_options[
            accommodationIndex
          ].tour_package_accommodation_packages[
            packageIndex
          ].per_night_room_price = e.target.value;
        }
      }

      return newAb;
    });
  };
  const updateTransportationPackagePrice = (index, e) => {
    setPackageTypeSummary((prevState) => {
      const newState = { ...prevState };

      newState.tour_package_transportation_packages[index] = {
        ...newState.tour_package_transportation_packages[index],
        transportation_package_unit_price: e.target.value,
      };

      return newState;
    });
  };
  const updateAccommodationInclusive = () => {
    setPackageTypeSummary((prevState) => ({
      ...prevState,
      tour_package_accommodation_is_inclusive:
        !prevState.tour_package_accommodation_is_inclusive,
    }));
  };

  const updateTransferInclusive = () => {
    setPackageTypeSummary((prevState) => ({
      ...prevState,
      tour_package_transfer_is_inclusive:
        !prevState.tour_package_transfer_is_inclusive,
    }));
  };

  const updateSpotInclusive = () => {
    setPackageTypeSummary((prevState) => ({
      ...prevState,
      tour_package_spot_entry_is_inclusive:
        !prevState.tour_package_spot_entry_is_inclusive,
    }));
  };
  const updateFoodInclusive = () => {
    setPackageTypeSummary((prevState) => ({
      ...prevState,
      tour_package_food_is_inclusive: !prevState.tour_package_food_is_inclusive,
    }));
  };
  const updateGuideInclusive = () => {
    setPackageTypeSummary((prevState) => ({
      ...prevState,
      tour_package_guide_options_is_inclusive:
        !prevState.tour_package_guide_options_is_inclusive,
    }));
  };
  const getPackageTypeSummary = () => {
    authAxios
      .get(
        `${apiEndPoint.MARCHANT_GET_PACKAGE_AVAILABILITY}${convertData.packageId}?requestId=abibility`
      )
      .then((res) => {
        setPackageTypeSummary((prevState) => ({
          ...(res?.data || {}),
          tour_package_accommodation_is_inclusive: true,
          tour_package_food_is_inclusive: true,
          tour_package_transfer_is_inclusive: true,
          tour_package_guide_options_is_inclusive: true,
          tour_package_spot_entry_is_inclusive: true,
        }));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackageTypeSummary();
  }, []);

  const [inputVisible, setInputVisible] = useState(false);
  const [checkField, setCheckField] = useState({
    field: "",
    ind: null,
    foodInd: null,
  });

  const handleDivClick = (sates, ind, foodInd) => {
    setCheckField({
      field: sates,
      ind: ind,
      foodInd: foodInd,
    });

    if (foodInd === undefined) {
      const inputId = document.getElementById(sates + ind);
      if (inputId) {
        inputId.focus();
      }
    } else {
      const inputId = document.getElementById(sates + ind + foodInd);
      if (inputId) {
        inputId.focus();
      }
    }

    setInputVisible(true);
  };

  const handleInputBlur = () => {
    setInputVisible(false);
  };

  const switchStyles = {
    display: "flex",
    alignItems: "center",
  };

  const sliderStyles = {
    position: "relative",
    display: "inline-block",
    width: "60px",
    height: "34px",

    borderRadius: "34px",
    cursor: "pointer",
    transition: "0.4s",
  };
  const handleSubmitAbibility = () => {
    const payload = convertStateObject(packageTypeSummary);
    console.log("payload", payload);
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.MARCHANT_POST_PACKAGE_AVAILABILITY}`,
        payload
      )
      .then((res) => {
        toast.success("Abibility set Successfully!");
      })
      .catch((err) => {
        console.error(err);
      });
    console.log("payload", payload);
  };

  return (
    <div>
      <p className="md:text-[20px] text-[18px] text-center text-btn-secondary my-[20px]">
        Abibility
      </p>
      <div className="my-[20px]">
        {/* tour start date */}

        <div className="mb-[6px]">
          <p>Tour Start Date</p>
          <Calender
            setSelectedDate={setStartDate}
            selectedData={startDate ? new Date(startDate) : null}
            placeholder="Tour start date"
            isReadOnly={false}
            rejectionArray={rejectionArray}
            setRejectionArray={setRejectionArray}
            name="startDate"
          />
        </div>
        {/* accommondation Option */}
        <div className="flex justify-start items-center gap-x-[15px] mt-[30px]">
          <h2>Accommondation</h2>
          <div className="flex justify-start gap-x-[5px] items-center">
            <div
              onClick={() => updateAccommodationInclusive()}
              className={`w-[20px] h-[20px] border border-btn-primary rounded-[4px] cursor-pointer  ${
                packageTypeSummary?.tour_package_accommodation_is_inclusive
                  ? "bg-btn-secondary text-bg-white"
                  : ""
              }`}
            >
              {packageTypeSummary?.tour_package_accommodation_is_inclusive && (
                <span className="flex justify-center items-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              )}
            </div>
            <p>Included</p>
          </div>
        </div>
        <table className="min-table mb-[40px] mt-[10px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Night Details</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_accommodation_options?.map(
              (item, accommodationIndex) => (
                <tr key={accommodationIndex}>
                  <td>{accommodationIndex + 1}</td>
                  <td className="bg-[#cce4f3]">
                    <div className="flex flex-col mx-auto ">
                      <div className="flex justify-start items-start px-[16px] py-[4px] hidden">
                        <div className="w-[80px]">Nights</div>
                        <div className="w-full bg-bg-white "> Details</div>
                        <div className="w-[100px]">Price</div>
                      </div>
                      <div className="flex justify-start items-start   gap-x-[16px] flex-col gap-y-[6px]">
                        {item?.tour_package_accommodation_packages?.map(
                          (items, packageIndex) => (
                            <div className="w-full  bg-bg-white px-[16px] rounded-[4px]">
                              <div className="w-[120px] h-full   px-[10px] py-[3px]  ">
                                <span className="text-[20px]">
                                  Night {items?.night_number}
                                </span>
                              </div>
                              <div className="w-full">
                                <div className="bg-bg-white   rounded overflow-clip relative">
                                  <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                                    {items?.tour_package_accommodation_name} -{" "}
                                    {items?.tour_package_room_category_name}
                                  </p>

                                  <div className="xl:text-[14px] text-[12px] flex justify-between">
                                    <p className="text-txt-primary">
                                      {" "}
                                      <span className="text-bg-semiblack">
                                        Room Type:
                                      </span>{" "}
                                      {items?.tour_package_room_type_name}{" "}
                                    </p>
                                    <p className="text-txt-primary">
                                      <span className="text-bg-semiblack">
                                        Bed Configuration:{" "}
                                      </span>
                                      {items?.bed_configuration} suitable for{" "}
                                      {items?.suitable_for_persons} persons{" "}
                                    </p>
                                    <div className="flex justify-between">
                                      <p className="text-txt-primary">
                                        <span className="text-bg-semiblack">
                                          Shareable:{" "}
                                        </span>
                                        {items?.is_room_shareable
                                          ? "Yes"
                                          : "No"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="w-[150px]">
                                <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                                  <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                                    {
                                      <input
                                        id={`accommodation${accommodationIndex}${packageIndex}`}
                                        className={`px-[4px] py-[1px] w-full   ${
                                          inputVisible &&
                                          checkField?.field ===
                                            "accommodation" &&
                                          checkField?.ind ===
                                            accommodationIndex &&
                                          checkField?.foodInd === packageIndex
                                            ? "border-btn-secondary border-b"
                                            : "border-none"
                                        } `}
                                        type="number"
                                        placeholder="Set Price"
                                        value={items?.per_night_room_price}
                                        onChange={(e) =>
                                          updateAccommodationPrice(
                                            accommodationIndex,
                                            packageIndex,
                                            e
                                          )
                                        }
                                        onClick={() =>
                                          handleDivClick(
                                            "accommodation",
                                            accommodationIndex,
                                            packageIndex
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      />
                                    }
                                  </div>

                                  <span
                                    onClick={() =>
                                      handleDivClick(
                                        "accommodation",
                                        accommodationIndex,
                                        packageIndex
                                      )
                                    }
                                    className="cursor-pointer  right-0"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-[20px] h-[20px]"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() =>
                              handleAccommodationOptionToggle(
                                accommodationIndex
                              )
                            }
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="flex justify-start items-center gap-x-[15px] mt-[30px]">
          <h2>Transfer</h2>
          <div className="flex justify-start gap-x-[5px] items-center">
            <div
              onClick={() => updateTransferInclusive()}
              className={`w-[20px] h-[20px] border border-btn-primary rounded-[4px] cursor-pointer  ${
                packageTypeSummary?.tour_package_transfer_is_inclusive
                  ? "bg-btn-secondary text-bg-white"
                  : ""
              }`}
            >
              {packageTypeSummary?.tour_package_transfer_is_inclusive && (
                <span className="flex justify-center items-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              )}
            </div>
            <p>Included</p>
          </div>
        </div>
        <table className="min-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Transfer Details</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_transfer_options?.map(
              (item, transferIndex) => (
                <tr key={transferIndex}>
                  <td>{transferIndex + 1}</td>
                  <td className="bg-[#cce4f3]">
                    <div className="flex flex-col mx-auto ">
                      <div className="flex justify-start items-start px-[16px] py-[4px] hidden">
                        <div className="w-[80px]">Nights</div>
                        <div className="w-full bg-bg-white "> Details</div>
                        <div className="w-[100px]">Price</div>
                      </div>
                      <div className="flex justify-start items-start ] flex-col   gap-y-[6px]">
                        {item?.tour_package_transfer_packages?.map(
                          (items, packageIndex) => (
                            <div className="  bg-bg-white w-full px-[16px]">
                              <div className="w-[120px] h-full   px-[10px] py-[3px]  ">
                                <span className="text-[20px]">
                                  Package {packageIndex + 1}
                                </span>
                              </div>
                              <div className="w-full">
                                <div className="bg-bg-white   rounded overflow-clip relative">
                                  <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                                    {items?.tour_package_transfer_route} -{" "}
                                    {
                                      items?.tour_package_transportation_mode_name
                                    }{" "}
                                    ({items?.is_ac ? "AC" : "Non-Ac"})
                                  </p>

                                  <div className="xl:text-[14px] text-[12px] flex justify-between">
                                    <p className="text-txt-primary">
                                      {" "}
                                      <span className="text-bg-semiblack">
                                        Provider:
                                      </span>{" "}
                                      {
                                        items?.tour_package_transportation_provider_name
                                      }
                                    </p>
                                    <p className="text-txt-primary">
                                      Suitable for {items?.suitable_for_persons}{" "}
                                      persons{" "}
                                    </p>
                                    <div className="flex justify-between">
                                      <p className="text-txt-primary">
                                        <span className="text-bg-semiblack">
                                          Trip Type:{" "}
                                        </span>
                                        {items?.trip_type}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="w-[150px]">
                                <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                                  <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                                    {
                                      <input
                                        id={`transfer${transferIndex}${packageIndex}`}
                                        className={`px-[4px] py-[1px] w-full   ${
                                          inputVisible &&
                                          checkField.field === "transfer" &&
                                          checkField?.ind === transferIndex &&
                                          checkField?.foodInd === packageIndex
                                            ? "border-btn-secondary border-b"
                                            : "border-none"
                                        } `}
                                        type="number"
                                        placeholder="Set Price"
                                        value={
                                          items?.per_vehicle_per_trip_price
                                        }
                                        onChange={(e) =>
                                          updateTransferPackagePrice(
                                            transferIndex,
                                            packageIndex,
                                            e
                                          )
                                        }
                                        onClick={() =>
                                          handleDivClick(
                                            "transfer",
                                            transferIndex,
                                            packageIndex
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      />
                                    }
                                  </div>

                                  <span
                                    onClick={() =>
                                      handleDivClick(
                                        "transfer",
                                        transferIndex,
                                        packageIndex
                                      )
                                    }
                                    className="cursor-pointer  right-0"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-[20px] h-[20px]"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>

                  <td>
                    {/* <div>
                      <TourMerchantButton
                        name={"Update Capacity Price"}
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.ABIBILITY_PACKAGE_ID}${item?.tour_package_type_name}&packageId=${item?.tour_package_id}&subscribeId=${convertData.subscribeId}&tour_id=${convertData.tour_id}`
                          )
                        }
                      ></TourMerchantButton>
                    </div> */}

                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() =>
                              handleTransferOptionToggle(transferIndex)
                            }
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="flex justify-start items-center gap-x-[15px] mt-[30px]">
          <h2>Food</h2>
          <div className="flex justify-start gap-x-[5px] items-center">
            <div
              onClick={() => updateFoodInclusive()}
              className={`w-[20px] h-[20px] border border-btn-primary rounded-[4px] cursor-pointer  ${
                packageTypeSummary?.tour_package_food_is_inclusive
                  ? "bg-btn-secondary text-bg-white"
                  : ""
              }`}
            >
              {packageTypeSummary?.tour_package_food_is_inclusive && (
                <span className="flex justify-center items-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              )}
            </div>
            <p>Included</p>
          </div>
        </div>
        <table className="min-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Transfer Details</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_food_options?.map(
              (item, foodIndex) => (
                <tr key={foodIndex}>
                  <td>{foodIndex + 1}</td>
                  <td className="bg-[#cce4f3]">
                    <div className="flex flex-col mx-auto ">
                      <div className="flex justify-start items-start px-[16px] py-[4px] hidden">
                        <div className="w-[80px]">Nights</div>
                        <div className="w-full bg-bg-white "> Details</div>
                        <div className="w-[100px]">Price</div>
                      </div>
                      <div className="flex justify-start items-start px-[6px] py-[6px]   bg-bg-white gap-[16px] ">
                        {item?.tour_package_meal_packages?.map(
                          (items, packageIndex) => (
                            <div className="shadow-customShadow p-[6px] rounded-[4px]">
                              {/* <div className="w-[120px] h-full   px-[10px] py-[3px]  ">
                            <span className="text-[20px]">
                              Package {index + 1}
                            </span>
                          </div> */}
                              <div className="w-full">
                                <div className="bg-bg-white   rounded overflow-clip relative">
                                  <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                                    {items?.meal_type_name}
                                  </p>

                                  <div className="xl:text-[14px] text-[12px] flex justify-start gap-x-[12px]">
                                    {
                                      <>
                                        {Object.values(items?.food_items)?.map(
                                          (food, index) => (
                                            <div>
                                              <p>{food}</p>
                                            </div>
                                          )
                                        )}
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="w-[150px]">
                                <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                                  <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                                    {
                                      <input
                                        id={`food${foodIndex}${packageIndex}`}
                                        className={`px-[4px] py-[1px] w-full   ${
                                          inputVisible &&
                                          checkField?.field === "food" &&
                                          checkField?.ind === foodIndex &&
                                          checkField?.foodInd === packageIndex
                                            ? "border-btn-secondary border-b"
                                            : "border-none"
                                        } `}
                                        type="number"
                                        placeholder="Set Price"
                                        value={items?.per_meal_package_price}
                                        onChange={(e) =>
                                          updateMealPackagePrice(
                                            foodIndex,
                                            packageIndex,
                                            e
                                          )
                                        }
                                        onClick={() =>
                                          handleDivClick(
                                            "food",
                                            foodIndex,
                                            packageIndex
                                          )
                                        }
                                        onBlur={handleInputBlur}
                                      />
                                    }
                                  </div>

                                  <span
                                    onClick={() =>
                                      handleDivClick(
                                        "food",
                                        foodIndex,
                                        packageIndex
                                      )
                                    }
                                    className="cursor-pointer  right-0"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-[20px] h-[20px]"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>

                  <td>
                    {/* <div>
                      <TourMerchantButton
                        name={"Update Capacity Price"}
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.ABIBILITY_PACKAGE_ID}${item?.tour_package_type_name}&packageId=${item?.tour_package_id}&subscribeId=${convertData.subscribeId}&tour_id=${convertData.tour_id}`
                          )
                        }
                      ></TourMerchantButton>
                    </div> */}
                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() => handleFoodOptionToggle(foodIndex)}
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <h2>Transportaion</h2>
        <table className="fl-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Route</th>
              <th>Provider Name</th>
              <th>Mode</th>
              <th>Brand</th>
              <th>Ac</th>
              <th>Price</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_transportation_packages?.map(
              (item, transportationIndex) => (
                <tr key={transportationIndex}>
                  <td>{transportationIndex + 1}</td>
                  <td>{item?.tour_package_transportation_route_name}</td>
                  <td>{item?.tour_package_transportation_provider_name}</td>
                  <td>{item?.tour_package_transportation_mode_name}</td>
                  <td>{item?.tour_package_transportation_brand_name}</td>
                  <td>{item?.is_ac ? "Yes" : "No"}</td>
                  <td>
                    <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                      <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                        {
                          <input
                            id={`transportation${transportationIndex}`}
                            className={`px-[4px] py-[1px]  w-full   ${
                              inputVisible &&
                              checkField?.field === "transportation" &&
                              checkField?.ind === transportationIndex
                                ? "border-btn-secondary border-b"
                                : "border-none"
                            } `}
                            type="number"
                            placeholder="Set Price"
                            value={item?.transportation_package_unit_price}
                            onChange={(e) =>
                              updateTransportationPackagePrice(
                                transportationIndex,
                                e
                              )
                            }
                            onClick={() =>
                              handleDivClick(
                                "transportation",
                                transportationIndex
                              )
                            }
                            onBlur={handleInputBlur}
                          />
                        }
                      </div>

                      <span
                        onClick={() =>
                          handleDivClick("transportation", transportationIndex)
                        }
                        className="cursor-pointer  right-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[20px] h-[20px]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                          />
                        </svg>
                      </span>
                    </div>
                  </td>

                  <td>
                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() =>
                              handleTransportationOptionToggle(
                                transportationIndex
                              )
                            }
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="flex justify-start items-center gap-x-[15px] mt-[30px]">
          <h2>Spot Fee</h2>
          <div className="flex justify-start gap-x-[5px] items-center">
            <div
              onClick={() => updateSpotInclusive()}
              className={`w-[20px] h-[20px] border border-btn-primary rounded-[4px] cursor-pointer  ${
                packageTypeSummary?.tour_package_spot_entry_is_inclusive
                  ? "bg-btn-secondary text-bg-white"
                  : ""
              }`}
            >
              {packageTypeSummary?.tour_package_spot_entry_is_inclusive && (
                <span className="flex justify-center items-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              )}
            </div>
            <p>Included</p>
          </div>
        </div>
        <table className="min-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Spot Details</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_spot_entry_options?.map(
              (item, transferIndex) => (
                <tr key={transferIndex}>
                  <td>{transferIndex + 1}</td>
                  <td className="bg-[#cce4f3]">
                    <div className="flex flex-col mx-auto ">
                      <div className="flex justify-start items-start ] flex-col   gap-y-[6px]">
                        {item?.tour_package_spot_entries?.map(
                          (items, packageIndex) => (
                            <div className="  bg-bg-white w-full px-[16px]">
                              <div className="w-[120px] h-full   px-[10px] py-[3px]  ">
                                <span className="text-[20px]">
                                  Package {packageIndex + 1}
                                </span>
                              </div>
                              <div className="w-full">
                                <div className="bg-bg-white   rounded overflow-clip relative">
                                  <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                                    {items?.activity?.activity_name} -{" "}
                                    {items?.activity?.activity_short_location}
                                  </p>

                                  <div className="xl:text-[14px] text-[12px] flex justify-between">
                                    <p className="text-txt-primary">
                                      {" "}
                                      <span className="text-bg-semiblack">
                                        Remark:
                                      </span>{" "}
                                      {items?.remark}
                                    </p>

                                    <div className="w-[150px]">
                                      <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                                        <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                                          {
                                            <input
                                              id={`spot${transferIndex}${packageIndex}`}
                                              className={`px-[4px] py-[1px] w-full   ${
                                                inputVisible &&
                                                checkField.field === "spot" &&
                                                checkField?.ind ===
                                                  transferIndex &&
                                                checkField?.foodInd ===
                                                  packageIndex
                                                  ? "border-btn-secondary border-b"
                                                  : "border-none"
                                              } `}
                                              type="number"
                                              placeholder="Set Price"
                                              value={
                                                items?.spot_entry_price_per_person
                                              }
                                              onChange={(e) =>
                                                updateSpotPackagePrice(
                                                  transferIndex,
                                                  packageIndex,
                                                  e
                                                )
                                              }
                                              onClick={() =>
                                                handleDivClick(
                                                  "spot",
                                                  transferIndex,
                                                  packageIndex
                                                )
                                              }
                                              onBlur={handleInputBlur}
                                            />
                                          }
                                        </div>

                                        <span
                                          onClick={() =>
                                            handleDivClick(
                                              "spot",
                                              transferIndex,
                                              packageIndex
                                            )
                                          }
                                          className="cursor-pointer  right-0"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-[20px] h-[20px]"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </td>

                  <td>
                    {/* <div>
                      <TourMerchantButton
                        name={"Update Capacity Price"}
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.ABIBILITY_PACKAGE_ID}${item?.tour_package_type_name}&packageId=${item?.tour_package_id}&subscribeId=${convertData.subscribeId}&tour_id=${convertData.tour_id}`
                          )
                        }
                      ></TourMerchantButton>
                    </div> */}

                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() =>
                              handleSpotOptionToggle(transferIndex)
                            }
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="flex justify-start items-center gap-x-[15px] mt-[30px]">
          <h2>Guide </h2>
          <div className="flex justify-start gap-x-[5px] items-center">
            <div
              onClick={() => updateGuideInclusive()}
              className={`w-[20px] h-[20px] border border-btn-primary rounded-[4px] cursor-pointer  ${
                packageTypeSummary?.tour_package_guide_options_is_inclusive
                  ? "bg-btn-secondary text-bg-white"
                  : ""
              }`}
            >
              {packageTypeSummary?.tour_package_guide_options_is_inclusive && (
                <span className="flex justify-center items-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </span>
              )}
            </div>
            <p>Included</p>
          </div>
        </div>
        <table className="min-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option ID</span>
              </th>
              <th>Guide Details</th>

              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {packageTypeSummary?.tour_package_guide_options?.map(
              (item, transferIndex) => (
                <tr key={transferIndex}>
                  <td>{transferIndex + 1}</td>
                  <td className="bg-[#cce4f3]">
                    <div className="flex flex-col mx-auto ">
                      <div className="flex justify-start items-start ] flex-col   gap-y-[6px]">
                        {item?.guide_packages?.map((items, packageIndex) => (
                          <div className="  bg-bg-white w-full px-[16px]">
                            <div className="w-[120px] h-full   px-[10px] py-[3px]  ">
                              <span className="text-[20px]">
                                Package {packageIndex + 1}
                              </span>
                            </div>
                            <div className="w-full">
                              <div className="bg-bg-white   rounded overflow-clip relative">
                                <div className="xl:text-[14px] text-[12px] flex justify-between">
                                  <p className="text-txt-primary">
                                    {" "}
                                    <span className="text-bg-semiblack">
                                      Day Number:
                                    </span>{" "}
                                    {items?.day_number}
                                  </p>
                                  <p className="text-txt-primary">
                                    {" "}
                                    <span className="text-bg-semiblack">
                                      Guide for Day:
                                    </span>{" "}
                                    {items?.number_of_guides_for_day}
                                  </p>

                                  <div className="w-[150px]">
                                    <div className="flex justify-center items-center gap-x-[16px] relative w-[150px]">
                                      <div className="flex flex-col gap-y-[6px]  top-[4px] w-[80px]">
                                        {
                                          <input
                                            id={`guide${transferIndex}${packageIndex}`}
                                            className={`px-[4px] py-[1px] w-full   ${
                                              inputVisible &&
                                              checkField.field === "guide" &&
                                              checkField?.ind ===
                                                transferIndex &&
                                              checkField?.foodInd ===
                                                packageIndex
                                                ? "border-btn-secondary border-b"
                                                : "border-none"
                                            } `}
                                            type="number"
                                            placeholder="Set Price"
                                            value={
                                              items?.total_guide_price_for_day
                                            }
                                            onChange={(e) =>
                                              updateGuidePackagePrice(
                                                transferIndex,
                                                packageIndex,
                                                e
                                              )
                                            }
                                            onClick={() =>
                                              handleDivClick(
                                                "guide",
                                                transferIndex,
                                                packageIndex
                                              )
                                            }
                                            onBlur={handleInputBlur}
                                          />
                                        }
                                      </div>

                                      <span
                                        onClick={() =>
                                          handleDivClick(
                                            "guide",
                                            transferIndex,
                                            packageIndex
                                          )
                                        }
                                        className="cursor-pointer  right-0"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-[20px] h-[20px]"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>

                  <td>
                    {/* <div>
                      <TourMerchantButton
                        name={"Update Capacity Price"}
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.ABIBILITY_PACKAGE_ID}${item?.tour_package_type_name}&packageId=${item?.tour_package_id}&subscribeId=${convertData.subscribeId}&tour_id=${convertData.tour_id}`
                          )
                        }
                      ></TourMerchantButton>
                    </div> */}

                    <div className="">
                      <div style={switchStyles}>
                        <label
                          style={sliderStyles}
                          className={`${
                            item?.tour_package_option_is_active
                              ? "bg-btn-primary"
                              : "bg-btn-orange"
                          } `}
                        >
                          <input
                            type="checkbox"
                            checked={item?.tour_package_option_is_active}
                            onClick={() =>
                              handleGuideOptionToggle(transferIndex)
                            }
                            style={{ display: "none" }}
                          />
                          <span
                            style={{
                              transform: `translateX(${
                                item?.tour_package_option_is_active
                                  ? "26px"
                                  : "0"
                              })`,
                              transition: "transform 0.3s ease",
                              content: '""',
                              position: "absolute",
                              height: "26px",
                              width: "26px",
                              left: "4px",
                              bottom: "4px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                        </label>
                        {/* <p>{open ? "Active" : "Disabled"}</p> */}
                      </div>
                      {/* {item?.tour_package_option_is_active ? "Yes" : "No"} */}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => handleSubmitAbibility()}
          className="w-[150px] bg-btn-secondary text-bg-white h-[39px] rounded-[4px]"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Abibility;
