import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonFilled from "../../../components/ButtonFilled";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";
export default function TopHotelSet() {
  //   ADMIN_SET_TOP_HOTEL
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [hotelData, setHotelData] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [rentalId, setRentalId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All Hotel");
  const getThumbData = () => {
    if (selectedOption === "All Hotel") {
      authAxios
        .get(
          `${
            baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN
          }?pageNumber=${currentPage}&pageSize=10&requestId=123&propertyNameKeyword=${searchValue}`
        )
        .then((res) => {
          setHotelData(res?.data);
        })
        .catch((err) => {});
    } else {
      authAxios
        .get(`${baseUrl + apiEndPoint.FETCH_ALL_TOP_HOTELS_FOR_ADMIN}`)
        .then((res) => {
          setHotelData(res?.data);
          console.log("data", res.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getThumbData();
  }, [searchValue, currentPage, selectedOption]);
  // DELETE_HOTELS_TOP_FOR_ADMIN
  // rentalId=12&requestId=q

  const handleModal = (id) => {
    setToggleModal(true);
    setRentalId(id);
  };

  const setTopHotel = () => {
    console.log("rentalId", rentalId);
    setToggleModal(false);
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_SET_TOP_HOTEL}rentalId=${rentalId}&requestId=12`
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Top Hotel set successfully!");
      });
  };

  const setRemoveTopHotel = () => {
    setToggleModal(false);
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_REMOVE_TOP_HOTEL}rentalId=${rentalId}&requestId=12`
      )
      .then((res) => {
        console.log(res.data);
        getThumbData();
        toast.success("Top Hotel remove successfully!");
      });
  };
  console.log("hotelData", hotelData);

  const options = ["All Hotel", "Top Hotel"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (
    <div className="px-[16px]  min-h-screen pb-[20px]">
      <div className="max-w-[1100px]  mx-auto">
        <h2 className="text-center text-[22px] pt-[30px]">Set Top Hotel</h2>

        <>
          <div className="mt-[20px] grid grid-cols-12 gap-x-[30px] justify-between items-center">
            <label
              className={`relative block col-span-8 ${
                selectedOption === "All Hotel" ? "block" : "hidden"
              }`}
            >
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                className="placeholder:italic text-txt-primary placeholder:text-txt-hint block bg-bg-white w-full border border-bg-semiblack rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-bg-semiblack focus:ring-bg-semiblack focus:ring-1 sm:text-sm"
                placeholder="Search for hotel name..."
                type="text"
                name="search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
            <div
              className={` ${
                selectedOption === "All Hotel" ? "col-span-4" : "col-span-12"
              }  flex justify-end `}
            >
              <div className="custom-dropdown relative w-[200px]">
                <div
                  className={`w-full dropdown-header cursor-pointer flex justify-between items-center ${
                    isOpen ? "open" : ""
                  } bg-bg-white p-[4px] rounded-[4px]`}
                  onClick={toggleDropdown}
                >
                  <span> {selectedOption || "Select an option"}</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </div>
                {isOpen && (
                  <ul className="absolute z-[20] shadow-customShadow bg-bg-white w-full py-[10px] px-[10px] rounded-[8px] flex flex-col gap-y-[5px]">
                    {options.map((option, index) => (
                      <li
                        className="cursor-pointer"
                        key={index}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* <div>
                <button></button>
                <button onClick={() => handleTopApi()}>Top Hotel</button>
              </div> */}
            </div>
          </div>
          <div className="w-full bg-bg-primary mt-[25px]">
            {/* table all hotel images */}
            <table className="table-fixed  border-collapse  w-full">
              <thead className="sticky top-[88px] shadow-sm bg-btn-primary text-bg-primary  text-left">
                <tr>
                  <th className="px-[8px] border-b py-[10px] border-bg-semiblack w-[700px]">
                    Hotel Name
                  </th>
                  <th className=" border-b border-bg-semiblack w-[400px]">
                    Contact Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {hotelData?.numberOfEntries > 0 &&
                  (selectedOption === "All Hotel"
                    ? hotelData.entries
                    : hotelData.esTopHotels
                  )?.map((property, index) => (
                    <tr
                      key={index}
                      onClick={() =>
                        handleModal(
                          selectedOption === "All Hotel"
                            ? property?.propertyId
                            : property?.id
                        )
                      }
                      className="hover:bg-bg-primaryShadow cursor-pointer border-b border-bg-semiblack"
                    >
                      <td className="  py-[8px] flex justify-start items-center gap-x-[12px]">
                        {/* {facility === true ? "" :
                        <img
                          className="img-fluid w-[70px] h-[50px] "
                          src={
                            property?.images[0]?.url ||
                            "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                          }
                          alt=""
                        /> */}

                        {selectedOption === "All Hotel"
                          ? property?.propertyName
                          : property?.property_name}
                      </td>

                      <td className="  py-[8px]">{property?.contactNo}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div
              className={`${
                selectedOption === "All Hotel" ? "block" : "hidden"
              }`}
            >
              {
                <Paginations
                  currentPage={currentPage}
                  totalCount={hotelData?.totalPages}
                  pageSize={10}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              }
            </div>
          </div>
        </>
      </div>

      <ModalCommon
        shown={toggleModal}
        width="max-w-[450px]"
        outSideOff={false}
        height="min-h-[200px]"
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[20px] mt-[20px]">
          <>
            {selectedOption === "All Hotel" ? (
              <>
                {" "}
                <h2 className="text-center text-[22px] text-btn-secondary">
                  Set as top hotel?..
                </h2>
                <div className="flex justify-center items-center mx-auto mt-[40px]">
                  <ButtonFilled
                    title="Yes"
                    // disable="yes"
                    buttonFilledHandle={setTopHotel}
                    w="231px"
                    h="40px"
                  />
                </div>
              </>
            ) : (
              <>
                {" "}
                <h2 className="text-center text-[22px] text-btn-orange">
                  Remove as top hotel?..
                </h2>
                <div className="flex justify-center items-center mx-auto mt-[40px]">
                  <ButtonFilled
                    title="Yes"
                    // disable="yes"
                    buttonFilledHandle={setRemoveTopHotel}
                    w="231px"
                    h="40px"
                  />
                </div>
              </>
            )}
          </>
        </div>
      </ModalCommon>
    </div>
  );
}
