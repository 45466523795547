import React, { useRef, useState } from "react";
import Loading from "../Loader/Loading";
import { merchantRoute, ReviewFor, UserType } from "../../contants/Constants";
import { useEffect } from "react";
import SingleSelect from "../SingleSelect";
import InputComponent from "../InputComponent";
import SingleSelectPhone from "../dropdown/SingleSelectPhone";
import Calender from "../Calender";
import ButtonFilled from "../ButtonFilled";
import ButtonOutlined from "../ButtonOutlined";
import { useDispatch, useSelector } from "react-redux";
import CustomValidation from "../../utils/customValidation";
import { putOwnerInfoSave } from "../../store/registration/owner/api/ownerApi";
import { formatDateWithYYMMDD } from "./OwnerComponent";
import { useLocation, useNavigate, useParams, useRoutes } from "react-router";
import { reviewSubmission } from "../../store/admin/review/api/reviewApi";
import { authAxios } from "../../utils/axiosWrapper";
import { apiEndPoint } from "../../contants/Endpoints";
import { ownerInfo } from "../../store/admin/adminContract/api/adminContractApi";

export default function OwnerComponentV2({
  setActiveStep,
  rejectionArrays,
  setRejectionArrays,
}) {
  const router = useLocation();
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(0);

  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
    window.scrollTo(0, 0);
  }, []);
  const response = useSelector((state) => state.ownerInfoGetReducer);
  const [rejectionArray, setRejectionArray] = useState([]);

  const [title, setTitle] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDob] = useState(new Date());
  const [nid, setNid] = useState("");
  const [tin, setTin] = useState("");
  const [website, setWeb] = useState("");
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (response && response.data && response.data.firstName) {
      setFirstName(response.data.firstName);
      setMiddleName(response.data.middleName);
      setLastName(response.data.lastName);
      setPhone(response.data.phoneNumber);
      setPhoneCode(response.data.phoneCode);
      setEmail(response.data.email);
      setDob(formatDateWithYYMMDD(response.data.dateOfBirth));
      setWeb(response.data.website);
      setNid(response.data.nid);
      setTin(response.data.tin);
      setTitle(response.data.title);
      setRejectionArray(response.data.rejectionReasons);
    }
  }, [response]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      ownerInfo(process.env.REACT_APP_USER_TYPE, router?.search?.split("=")[1])
    );
  }, []);
  // button outline(Save)
  const buttonOutlinedHandle = () => {
    sessionStorage.setItem("owner", "yes");
    if (CustomValidation()) {
      dispatch(
        putOwnerInfoSave(
          localStorage.getItem("id"),
          {
            dateOfBirth: formatDateWithYYMMDD(dateOfBirth),
            firstName: firstName,
            lastName: lastName,
            middleName: middleName || "",
            nid: nid,
            requestId: 6,
            tin: tin,
            title: title,
            website: website || "",
            email,
            phoneNumber,
            phoneCode,
          },
          createContractMoveingDecideSave
        )
      );
    }
  };

  // button filled (next)
  const buttonFilledHandle = () => {
    sessionStorage.setItem("owner", "yes");
    if (CustomValidation()) {
      dispatch(
        putOwnerInfoSave(
          localStorage.getItem("id"),
          {
            dateOfBirth: formatDateWithYYMMDD(dateOfBirth),
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            nid: nid,
            requestId: 6,
            tin: tin,
            title: title,
            website: website,
            email,
            phoneNumber,
            phoneCode,
          },
          createContractMoveingDecideNext
        )
      );
    }
  };
  // for save button click
  const createContractMoveingDecideSave = () => {
    navigate(merchantRoute.LANDING);
  };

  // for next button click
  const createContractMoveingDecideNext = () => {
    navigate(merchantRoute.CONTRACT);
  };

  // submit admin review
  const adminReviewSubmit = () => {
    dispatch(
      reviewSubmission(ReviewFor.OWNER, response.data.contractId, {
        ownerReject: rejectionArray,
        requestId: "string",
      })
    );
  };

  const handleRejection = () => {
    setRejectionArrays((prevState) => ({
      ...prevState,
      ownerRejectionReasons: {
        ...(rejectionArray || {}),
      },
    }));
  };
  console.log("rejectionArray", rejectionArray);

  useEffect(() => {
    if (localStorage.getItem("id")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      authAxios
        .get(
          `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
            "id"
          )}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setCountryPhoneCodeArr(response.data.countries);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <div>
      <div className="p-4">
        {response.loading ? (
          <div className="ml-[45%]">
            <Loading />
          </div>
        ) : (
          <React.Fragment>
            <div className="">
              <div
                className={`p-10 xl:px-0 xl:container mx-auto ${
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? "gap-y-4 flex flex-col"
                    : "grid grid-cols-2 gap-28"
                }`}
              >
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 min-w-full" ref={ref}>
                    <SingleSelect
                      title={title}
                      setTitle={setTitle}
                      width={width}
                    />
                    <InputComponent
                      inputValue={firstName}
                      setInputValue={setFirstName}
                      placeholder="First Name"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={rejectionArray}
                      setRejectionArray={setRejectionArray}
                      name="firstName"
                      required={true}
                    />
                  </div>
                  <InputComponent
                    inputValue={middleName}
                    setInputValue={setMiddleName}
                    placeholder="Middle Name (optional)"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="middleName"
                  />
                  <InputComponent
                    inputValue={lastName}
                    setInputValue={setLastName}
                    placeholder="Last Name"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="lastName"
                    required={true}
                  />
                  <SingleSelectPhone
                    inputValue={phoneNumber}
                    setInputValue={setPhone}
                    title={phoneCode}
                    setTitle={setPhoneCode}
                    titleArr={countryPhoneCodeArr}
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="phoneNumber"
                    required={true}
                  />
                  <div>
                    <Calender
                      setSelectedDate={setDob}
                      selectedData={dateOfBirth ? new Date(dateOfBirth) : null}
                      placeholder="Date of Birth"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={rejectionArray}
                      setRejectionArray={setRejectionArray}
                      name="dateOfBirth"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <InputComponent
                    inputValue={email}
                    setInputValue={setEmail}
                    placeholder="Email"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="email"
                    required={true}
                  />
                  <InputComponent
                    inputValue={website}
                    setInputValue={setWeb}
                    placeholder="website(optional)"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="website"
                  />
                  <InputComponent
                    inputValue={nid}
                    setInputValue={setNid}
                    placeholder="#NID"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="nid"
                    required={true}
                  />
                  <InputComponent
                    inputValue={tin}
                    setInputValue={setTin}
                    placeholder="#TIN"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="tin"
                    required={true}
                  />
                </div>

                <div className="flex justify-end items-end ">
                  <button
                    onClick={() => {
                      setActiveStep(1);
                      handleRejection();
                    }}
                    className="w-[130px] h-[39px] bg-btn-secondary hover:bg-btn-primary active:bg-btn-secondary rounded-[4px] text-bg-white"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
