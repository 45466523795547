import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourAccomandationType() {
  const [modalShown, setToggleModal] = useState(false);
  const [allAccommandationType, setAllAccommandationType] = useState([]);
  const [accomandationType, setAccommandationType] = useState({
    accommodation_type_name: "",
  });

  const [accomandationTypes, setAccomandationTypes] = useState([]);

  const handleAccommandation = () => {
    setAccomandationTypes([...accomandationTypes, accomandationType]);
    setAccommandationType({ accommodation_type_name: "" });
    setToggleModal(false);
  };

  const removeAccommandationType = (ind) => {
    const newArray = accomandationTypes?.filter((meal, index) => index !== ind);
    setAccomandationTypes(newArray);
  };
  const handleAccommandationAdd = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_ACCOMMANDATION_TYPE_POST_API}`,
        {
          accommodations: accomandationTypes,
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toast.success("Successfully added accommodation type");
          getAccomandationTypes();
          setAccomandationTypes([]);
          setAccommandationType({
            accommodation_type_name: "",
          });
        }
      });
  };

  const getAccomandationTypes = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_ACCOMMANDATION_TYPE_GET_API}`)
      .then((response) =>
        setAllAccommandationType(response?.data?.tour_accommodation_types)
      );
  };

  useEffect(() => {
    getAccomandationTypes();
  }, []);
  return (
    <div className="mt-[20px]">
      <div className="max-w-[1100px] mx-auto mt-[10px]">
        <h2>All Accommodation Type</h2>
        <div className="flex flex-col gap-y-[12px] mt-[8px] max-w-[450px] w-full max-h-[550px] overflow-y-scroll p-[12px]">
          {allAccommandationType?.map((acc) => (
            <div className="shadow-customShadow p-[10px] rounded-[8px]">
              <p className="text-[14px]">
                <span className="text-[12px]">Accom. Name:</span>{" "}
                {acc?.tour_accommodation_type_name}
              </p>
            </div>
          ))}
        </div>
        <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed right-[30px] top-[90px] w-full">
          <h2 className="text-[14px] text-center underline">
            Accommondation Type
          </h2>
          <div className="flex flex-col gap-y-[15px] mt-[4px]">
            {accomandationTypes?.map((accomandationType, index) => (
              <div
                key={index}
                className="border p-[5px] rounded-[8px] border-[#ddd] relative"
              >
                <p className="text-[14px] text-txt-primary">
                  {" "}
                  <span className="text-[12px]">Acc. Name :</span>{" "}
                  {accomandationType?.accommodation_type_name}
                </p>

                <span
                  onClick={() => removeAccommandationType(index)}
                  className="absolute top-[-12px] right-[-10px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px] text-[#EC7063]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>
          <div className="w-full flex justify-end mt-[10px]">
            <button
              className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
                accomandationTypes?.length > 0 ? "w-[150px] " : "w-full"
              }`}
              onClick={() => setToggleModal(true)}
            >
              Add New
            </button>
          </div>
          {accomandationTypes?.length > 0 && (
            <button
              onClick={() => handleAccommandationAdd()}
              className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white"
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={true}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="mt-[10px] p-[30px]">
          <label className="custom-field one w-full">
            <input
              onChange={(e) =>
                setAccommandationType((prev) => ({
                  ...prev,
                  accommodation_type_name: e.target.value,
                }))
              }
              className="input w-full"
              type="text"
              placeholder=" "
              value={accomandationType.accommodation_type_name}
            />
            <span className="placeholder">Accommodations Type Name:</span>
          </label>
          <button
            disabled={accomandationType?.accommodation_type_name?.length === 0}
            className="w-full bg-btn-secondary h-[40px] rounded-[8px] mt-[30px] text-bg-white disabled:bg-bg-semiblack"
            onClick={() => handleAccommandation()}
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
