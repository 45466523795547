import React from "react";
import "../App.css";
import { customValidationSingle } from "../utils/customValidation";
import RootComponent from "./rootComponent/RootComponent";
//h-[${height}]
function InputComponent({
  inputValue,
  setInputValue,
  placeholder,
  type,
  hintText,
  warningText,
  isAdmin,
  isReadOnly = false,
  warningType,
  rejectionArray,
  setRejectionArray,
  warningView,
  name,
  dropdown = false,
  addSpan = false,
  spanValue,
  required = false,
  password = false,
  number = false,
  passwordToggle,
  selectWhole = false,
  clickHandler = null,
  height = "49px",
  width,
}) {
  // const ref = useRef(null);
  const handleClickButton = () => {
    console.log();
    const input = document.getElementById(`input${name}`);
    if (input) {
      input.focus();
    }
    clickHandler && clickHandler();
  };

  const input = document.getElementById(`input${name}`);
  if (input) {
    // Prevent mousewheel event
    input.addEventListener("mousewheel", (e) => {
      e.preventDefault();
    });
  }

  let component = (
    <div id={name} className={`w-full text-left ${required && "required"}`}>
      <div
        onClick={handleClickButton}
        className={`w-full rounded-[8px] h-[49px] flex items-center outline-none border-[#9e9989] validation relative border `}
      >
        {placeholder && (
          <div
            onClick={handleClickButton}
            className="flex items-center md:text-xs text-[#9e9989]"
          >
            <div className="pl-4 validation text-[#9e9989] flex items-center md:text-xs text-left  truncate pr-2 ">
              {placeholder}
              {required ? "*" : null}
            </div>
            :
          </div>
        )}
        <input
          value={inputValue}
          onChange={(e) => {
            number
              ? !isNaN(e.target.value.replace(/,/g, "")) &&
                setInputValue(e.target.value.replace(/,/g, ""))
              : setInputValue(e.target.value);
            required && customValidationSingle(name);
          }}
          autoComplete="off"
          placeholder={hintText}
          className={` h-full validation appearance-none text-base11 sm:text-xs w-full bg-[transparent] px-2 outline-0 outline-none`}
          onClick={(e) => {
            selectWhole && e.target.select();
          }}
          type={type ? type : "text"}
          name={name}
          id={`input${name}`}
          readOnly={isReadOnly}
          role="presentation"
          onBlur={() => {
            required && customValidationSingle(name);
          }}
        />
        {addSpan && (
          <button className="bg-btn-primary w-[70px] rounded-[8px] flex items-center justify-center text-bg-white ml-auto h-full">
            {dropdown ? (
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              spanValue
            )}
          </button>
        )}
        {password && (
          <button
            tabIndex={"-1"}
            type="button"
            onClick={() => {
              passwordToggle(`input${name}`);
            }}
            className="p-2 rounded-[8px]  text-bg-semiblack hover:text-txt-black absolute right-0 h-full"
          >
            <i className="fa fa-eye"></i>
          </button>
        )}
      </div>
      <div className="errorMessage validationError"></div>
    </div>
  );
  let readOnlyViewComponent = <p>{inputValue}</p>;

  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        warningView={warningView}
        name={name}
        width={width}
      />
    </div>
  );
}

export default InputComponent;
