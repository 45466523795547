import React, { useState } from "react";
import loginOrRegisterPage from "../../utils/loginOrRegisterPage";
import ModalWithSingleInput from "../modal/ModalWithSingleInput";
import WarningIcon from "../warning/WarningIcon";

function RootComponent({
  isAdmin,
  isReadOnly,
  component,
  readOnlyViewComponent,
  warningText,
  warningType,
  rejectionArray,
  setRejectionArray,
  warningView = true,
  name,
  type = "input",
}) {
  const [modalTrigger, setModalTrigger] = useState(false);
  const handleRegistrationSubmit = () => {
    setModalTrigger(true);
  };
  function decideComponent() {
    if (isAdmin === true) {
      if (isReadOnly === true) {
        return readOnlyViewComponent;
      } else {
        return rejectionArray && rejectionArray[name] ? (
          <div className="grid grid-cols-4 gap-2">
            <div className="w-full col-span-3">{component}</div>
            <div onClick={handleRegistrationSubmit} className="">
              <WarningIcon
                rejectionArray={rejectionArray}
                warningText={rejectionArray[name]}
                warningType={warningType ? warningType : "warning"}
                name={name}
              />
            </div>

            <ModalWithSingleInput
              setOpenDefault={setModalTrigger}
              openDefault={modalTrigger}
              name={name}
              setRejectionArray={setRejectionArray}
              rejectionArray={rejectionArray}
            />
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-2">
            <div
              className={`w-full ${
                type === "map" ? "col-span-1" : "col-span-3"
              }`}
            >
              {component}
            </div>
            <button
              type="button"
              onClick={handleRegistrationSubmit}
              className={`bg-btn-warning rounded-[8px] h-[49px] ${
                type === "map" ? "col-span-3" : "px-4 col-span-1"
              } `}
            >
              Reject
            </button>
            <ModalWithSingleInput
              setOpenDefault={setModalTrigger}
              openDefault={modalTrigger}
              name={name}
              setRejectionArray={setRejectionArray}
              rejectionArray={rejectionArray}
            />
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="flex">
            <div className="w-full">{component}</div>
            {warningView && (
              <div className={`w-[49px] ml-auto pl-2`}>
                {rejectionArray && rejectionArray[name] && (
                  <WarningIcon
                    rejectionArray={rejectionArray}
                    warningText={rejectionArray[name]}
                    warningType={warningType ? warningType : "warning"}
                    name={name}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
  return <>{decideComponent()}</>;
}

export default RootComponent;
