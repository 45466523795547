import React, { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT, baseUrl } from "../../contants/Endpoints";
import { changeUrl } from "../utils/common";
import { toast } from "react-toastify";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import Loading from "../../components/Loader/Loading";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";

const Excludes = ({ setActiveStep }) => {
  const [allData, setAllData] = useState([]);
  const [includes, setIncludes] = useState([]);
  const [excludes, setExcludes] = useState([]);
  const [afterFilterIncludes, setAfterFilterIncludes] = useState([]);
  const [afterFilterExcludes, setAfterFilterExcludes] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));

  const getAllExcludeAndInclude = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_GET_EXCURSION_FETCH_ALL_COMPONENTS}`
      )
      .then((res) => {
        setAllData(res?.data?.excursionComponentData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllExcludeAndInclude();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let notIncluded = [];

    if (excludes?.data?.length > 0) {
      notIncluded = allData.filter(
        (item) =>
          !excludes.data.some((includedItem) => includedItem.id === item.id)
      );
    } else {
      notIncluded = allData;
    }
    setAfterFilterIncludes(notIncluded);

    let notExcluded = [];

    if (includes?.data?.length > 0) {
      notExcluded = allData.filter(
        (item) =>
          !includes.data.some((includedItem) => includedItem.id === item.id)
      );
    } else {
      notExcluded = allData;
    }
    setAfterFilterExcludes(notExcluded);

    setIsLoading(false);
  }, [includes, excludes, allData]);

  const iconArray = [
    {
      iconCheck: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          className="text-[#86efac]"
          viewBox="0 0 448 512"
        >
          <path
            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
            fill="#4ade80"
          />
        </svg>
      ),
      iconX: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 384 512"
        >
          <path
            d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
            fill="#ef4444"
          />
        </svg>
      ),
    },
  ];

  const payloadMethodExclude = excludes?.data?.map((item) => item.id);

  const onSubmitButton = () => {
    addExcludes()
    .then(() => {
      setActiveStep(8);
    changeUrl(Number(url.searchParams.get("id")), 8);
    })
    .catch((error) => {
      console.error("Error in addExcludes: ", error);
    })
  };

  // check already Data has
  useEffect(() => {
    if (excursionID !== 0) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_BY_ID}?excursionId=${excursionID}&requestId=123`
        )
        .then((res) => {
          setIncludes((prev) => ({
            ...prev,
            data: res?.data?.excursionData?.includedComponent,
          }));
          setExcludes((prev) => ({
            ...prev,
            data: res?.data?.excursionData?.excludedComponent,
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, []);

  const addExcludes = () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      authAxios
        .post(
          `${baseUrl}${
            MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_EXCLUDES
          }?excursionId=${excursionID}&requestId=${123}`,
          payloadMethodExclude
        )
        .then((res) => {
          toast.success("Excludes Added successfully!");
          setIsLoading(false);
          resolve(res.data); // Resolve with optional data if needed
        })
        .catch((error) => {
          console.log("Error: ", error);
          toast.error("Excludes Added Failed!");
          setIsLoading(false);
          reject(error); // Reject the Promise with the error
        });
    });
  };
  
  const backAndSave = () => {
    addExcludes()
    .then(() => {
      const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
    })
    .catch((error) => {
      console.error("Error in addExcludes: ", error);
    })
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId")
  }, [])

  
  return (
    <div className="w-[343px] md:w-[712px]">
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]">Excludes</span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Excluding to streamline and details what's Excluded.
        </p>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="shadow-customShadow py-[20px] px-[24px]">
          <div className="flex flex-col md:flex-row gap-x-[24px] md:gap-x-[32px]">
            <div className="w-full">
              <div>
                <p className="text-btn-secondary">Excludes: </p>
              </div>
              <div className="rounded mb-[30px]">
                <Select
                  closeMenuOnSelect={false}
                  value={excludes?.data}
                  onChange={(data) => {
                    setExcludes((prev) => ({
                      ...prev,
                      data,
                    }));
                    // handleOnChange(data)
                  }}
                  components={animatedComponents}
                  options={afterFilterExcludes}
                  placeholder={"Select Food: "}
                  styles={colorStyles}
                  isMulti
                />
                <div className="mt-[20px] flex justify-end">
                  <TourMerchantButton
                    onClick={() => addExcludes()}
                    name={"Update Excludes"}
                  ></TourMerchantButton>
                </div>
              </div>
              <div className=" rounded-[4px] overflow-hidden shadow-customShadow">
                <p className="bg-btn-primary text-bg-white py-[8px] px-[8px] text-center">
                  Selected Exclude {excludes?.data?.length} Items.
                </p>
                <div className="flex flex-col gap-y-[8px] py-[8px] px-[16px]">
                  {(excludes?.length === 0 || excludes?.data?.length === 0) && (
                    <p className="text-btn-secondary">No Exclude Selected!</p>
                  )}
                  {excludes?.data?.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center bg-btn-secondary text-bg-white text-[20px]  px-[12px] py-[4px] rounded-[4px]"
                    >
                      <div className="mr-[8px] text-[16px]">
                        {" "}
                        {iconArray[0]?.iconX}
                      </div>
                      <p className="text-[14px]">{item?.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[20px] flex justify-center items-center w-full">
            <CustomButtonGroupExcruions
              setSaveAndNext={backAndSave}
              onSubmit={onSubmitButton}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Excludes;
