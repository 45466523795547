import { useLocation, useNavigate } from "react-router";
import { adminRoute } from "../../../contants/Constants";
import Paginations from "../Paginations/Paginations";
import React, { Fragment, useState } from "react";
import { tr } from "date-fns/locale";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../../ToursMarchent/Utilities/SelectAnimation";

const CommonThumbFacility = (props) => {
  const navigate = useNavigate();
  // console.log(props)

  const {
    currentData,
    pageSize,
    totalCount,
    setCurrentPage,
    currentPage,
    facility,
    setValue,
    value,
    searchValue,
    setSearchValue,
  } = props;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const { pathname } = useLocation();


  return (
    <>
      {/* searchbar */}
      <div className="mt-[20px] grid grid-cols-12 gap-x-[30px] justify-between items-center">
        <label className="relative block col-span-8">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </span>
          <input
            className="placeholder:italic text-txt-primary placeholder:text-txt-hint block bg-bg-white w-full border border-bg-semiblack rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-bg-semiblack focus:ring-bg-semiblack focus:ring-1 sm:text-sm"
            placeholder="Search for hotel name..."
            type="text"
            name="search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </label>
        <div className="col-span-4 flex gap-x-[10px] items-center justify-end">
          <p>Show Result for : </p>
          <select value={value} onChange={handleChange} className="p-[6px]">
            <option value="active">All</option>
            <option value="Deactivate">
              {pathname === "/admin/hotel_facility"
                ? "Without Facility"
                : "Without Thumb"}
            </option>
          </select>
        </div>
      </div>
      <div className="w-full bg-bg-primary mt-[25px]">


        {/* New Table */}
        <div>
          <table className="fl-table my-[20px]">
            <thead className="sticky top-[88px] z-50">
              <tr>
                <th>
                  <span>Hotel Name</span>
                </th>
                <th>Contact Number</th>
                <th>Short Address</th>
                <th>Thumbnail</th>
              </tr>
            </thead>
            <tbody>
              {currentData?.length > 0 && currentData?.map((property, index) => (
                <tr key={index}
                className="cursor-pointer"
                onClick={() =>
                  navigate(
                    facility
                      ? `${adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY}/${property?.propertyId}`
                      : `${adminRoute.ADMIN_THUMB_IMAGE}/${property?.propertyId}`
                  )
                }
                >
                  <td className="cursor-pointer"
                  
                  >{property?.propertyName}</td>
                  <td>{property?.contactNo}</td>
                  <td>{property?.shortAddress? property?.shortAddress : "No Short Address Found!" }</td>
                  <td className="">
                  {/* <img className="h-max-[40] w-[40px]" src={property?.thumbImageUrl} alt="" /> */}
                  <img
                          className="w-[200px] h-[100px] object-cover mx-auto"
                          src={property?.thumbImageUrl}
                          alt={property?.propertyName}
                        />
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Old table all hotel images */}
        {/* <table className="table-fixed  border-collapse  w-full">
          <thead className="sticky top-[88px] shadow-sm bg-btn-primary text-bg-primary  text-left">
            <tr>
              <th className="px-[8px] border-b py-[10px] border-bg-semiblack w-[700px]">
                Hotel Name
              </th>
              <th className=" border-b border-bg-semiblack w-[400px]">
                Contact Number
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData?.length > 0 &&
              currentData?.map((property, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    navigate(
                      facility
                        ? `${adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY}/${property?.propertyId}`
                        : `${adminRoute.ADMIN_THUMB_IMAGE}/${property?.propertyId}`
                    )
                  }
                  className="hover:bg-bg-primaryShadow cursor-pointer border-b border-bg-semiblack"
                >
                  <td className="  py-[8px] flex justify-start items-center gap-x-[12px]">
                    {property?.propertyName}
                  </td>

                  <td className="  py-[8px]">
                    {pathname === "/admin/hotel_facility"
                      ? property?.contactNo
                      : property?.contactNumber}
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}

        <div className="">
          {pageSize > 1 && (
            <Paginations
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CommonThumbFacility;
