import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import { FaCheck } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";
import { authAxios } from "../../utils/axiosWrapper";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  apiEndPoint,
} from "../../contants/Endpoints";
import { toast } from "react-toastify";
import { changeUrl, numberInputOnWheelPreventChange } from "../utils/common";
import ModalCommon from "../../components/common/ModalCommon";
import Paginations from "../../pages/admin/Paginations/Paginations";
import Loading from "../../components/Loader/Loading";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import ReactModal from "react-modal";

const CreatePackage = ({ setActiveStep }) => {
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));

  const [checkValidCreatePackage, setCheckValidCreatePackage] = useState(false);

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalSecond, setToggleModalSecond] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [allVariables, setAllVariables] = useState([]);
  const [allPackageData, setAllPackageData] = useState([]);

  const [filteredVariables, setFilteredVariables] = useState([]);

  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 5;

  const [groupData, setGroupData] = useState({
    name: "",
    description: "",
    variableId: "",
    variableName: "",
    // timeUnit: {value: "Minute", label: "Minute"},
    timeUnit: "",
    duration: 0,
    isCheck: false,
    excursionPackageComponentData: {
      excursionPackageComponentValue: "",
      excursionPackageComponentId: "",
      id: 0,
    },
  });

  const [addPackageData, setAddPackageData] = useState({
    excursionPackageComponentValue: "",
    excursionPackageComponentId: 0,
    excursionPackageVariableData: {},
  });

  const [addGroupPackage, setAddGroupPackage] = useState([]);

  const [timeUnit, setTimeUnit] = useState([
    { id: 1, label: "Minute", value: "Minute" },
    { id: 2, label: "Hour", value: "Hour" },
  ]);

  useEffect(() => {
    authAxios
      ?.get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_EXCURSION_MULTI_PACKAGE}excursionId=${excursionID}&requestId=123`
      )
      .then((res) => {
        setCheckValidCreatePackage(res?.data?.success);
        if (res?.data?.success === false) {
          setActiveStep(11);
          changeUrl(Number(url.searchParams.get("id")), 11);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onCheckChange = () => {
    setIsCheck(!isCheck);
  };

  const transformData = (inputData) =>
    inputData.map((item) => ({
      componentValue: item?.excursionPackageComponentValue,
      excursionPackageVariableId: item?.excursionPackageVariableData?.id,
      id: item?.excursionPackageComponentId,
    }));

  const payloadMethod = {
    duration: parseFloat(groupData?.duration),
    excursionId: excursionID,
    excursionPackageComponentData: transformData(addGroupPackage),
    id: groupData?.variableId || 0,
    packageDescription: groupData?.description,
    packageName: groupData?.name,
    requestId: "123",
  };

  const onSubmit = () => {
    setSuccess(true);
    if (
      groupData?.duration === 0 ||
      groupData?.name?.length === 0 ||
      groupData?.description?.length === 0 ||
      addGroupPackage?.length === 0
    ) {
      setShowError(true);
    } else {
      authAxios
        .post(
          `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_UPDATE_EXCURSION_PACKAGE}`,
          payloadMethod
        )
        .then((res) => {
          toast.success("Successfully Added!");
          setSuccess(false);
          setToggleModal(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Added Failed!");
          setSuccess(false);
        });
    }
  };

  const onEditButton = (id) => {
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_EXCURSION_PACKAGE_BY_PACKAGE_ID}excursionPackageId=${id}&requestId=123`
      )
      .then((res) => {
        setGroupData((prev) => ({
          ...prev,
          name: res?.data?.packageName,
          description: res?.data?.packageDescription,
          duration: res?.data?.duration,
          excursionPackageComponentData:
            res?.data?.excursionPackageComponentVariableDataList,
          variableId: res?.data?.id,
        }));
        setAddGroupPackage(
          res?.data?.excursionPackageComponentVariableDataList
        );
      })
      .catch((err) => {
        console.log({ err });
      });
    setToggleModal(true);
  };

  const onDeletePackageById = (id) => {
    setSuccess(true);
    authAxios
      .delete(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_DELETE_EXCURSION_PACKAGE_BY_ID}excursionPackageId=${id}&requestId=123`
      )
      .then((res) => {
        toast.success("Successfully Deleted!");
        setSuccess(false);
      })
      .catch((err) => {
        toast.error("Delete Failed!");
        setSuccess(false);
      });
  };

  const onSubmitVariable = () => {
    setAddGroupPackage((prev) => {
      if (!prev || prev.length === 0) {
        return [addPackageData];
      } else {
        return [...prev, addPackageData];
      }
    });
    setAddPackageData({
      excursionPackageComponentValue: "",
      excursionPackageComponentId: 0,
      excursionPackageVariableData: {},
    });
    setToggleModalSecond(false);
    // toast.success("Successfully added packages.")
  };

  const onDeleteVariableID = (id, subId) => {
    if (id !== 0) {
      authAxios
        .delete(
          `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_EXCURSION_PACKAGE_DELETE_BY_PACKAGE_ID}excursionPackageComponentId=${id}&requestId=123`
        )
        .then((res) => {
          setSuccess(!success);
          const updatedObject = addGroupPackage.filter((item) => {
            return item.excursionPackageComponentId !== id;
          });
          setAddGroupPackage(updatedObject);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Delete Failed!");
        });
    } else {
      const updatedObject = addGroupPackage
        ?.filter((item) => item?.excursionPackageComponentId?.id !== id)
        .filter(
          (subItem) => subItem?.excursionPackageVariableData?.id !== subId
        );

      setAddGroupPackage(updatedObject);
    }
  };

  useEffect(() => {
    authAxios
      .get(
        `${
          apiEndPoint.ADMIN_GET_PAGINATION_PACKAGE_VARIABLE
        }?pageNumber=${1}&pageSize=${100}&queryString=${" "}&requestId=123`
      )
      .then((res) => {
        setAllVariables(res?.data?.entries);
        // setTotalPage(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_EXCURSION_LIST_BY_ID_PACKAGE}?excursionId=${excursionID}&pageNumber=${currentPage}&pageSize=${pageSize}&queryString=${searchValue}&requestId=123`
      )
      .then((res) => {
        setAllPackageData(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [currentPage, searchValue, totalPage, success]);

  const onToggleNewAdd = () => {
    setGroupData({
      name: "",
      description: "",
      variableId: "",
      variableName: "",
      timeUnit: "",
      duration: 0,
      isCheck: false,
      excursionPackageComponentData: {
        excursionPackageComponentValue: "",
        excursionPackageComponentId: "",
        id: 0,
      },
    });

    setAddGroupPackage([]);

    setToggleModal(!toggleModal);
  };

  useEffect(() => {
    const filtered = allVariables.filter(
      (variable) =>
        !addGroupPackage.some(
          (packageItem) =>
            packageItem.excursionPackageVariableData.id === variable.id
        )
    );
    setFilteredVariables(filtered);
  }, [addGroupPackage, allVariables]);

  const onSelectTimeSlot = (id) => {
    authAxios
      .post(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_SET_PACKAGE_MULTI_TIME_SLOT}excursionId=${excursionID}&requestId=123`
      )
      .then((res) => {
        localStorage.setItem("excursionCreatePackageId", id);
        setActiveStep(11);
        changeUrl(Number(url.searchParams.get("id")), 11);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to Select Time Slot");
      });
  };

  const onSubmitButton = () => {
    localStorage.removeItem("excursionCreatePackageId");
    setActiveStep(11);
    changeUrl(Number(url.searchParams.get("id")), 11);
  };

  const backAndSave = () => {
    localStorage.removeItem("excursionCreatePackageId");
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
  };

  // useEffect(() => {
  //   console.log("called")
  //   localStorage.removeItem("excursionCreatePackageId")
  // }, [])

  return (
    <>
      {checkValidCreatePackage && (
        <div className="w-[343px] md:w-[763px] lg:w-[1100px]">
          <div className=" mb-[20px]">
            <p
              className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
            >
              <span className="border-x-[4px] px-[8px]"> Create Package</span>
            </p>
            <p className="text-center text-btn-secondary text-[14px]">
              Join us for a personalized adventure that promises to immerse you
              like never before.
            </p>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center">
              <Loading></Loading>
            </div>
          ) : (
            <>
              <div>
                <div className="my-[40px] flex justify-center">
                  <div className="w-[50%]">
                    <TourMerchantButton
                      name={"Add New Package"}
                      width={"w-full"}
                      onClick={() => onToggleNewAdd()}
                    ></TourMerchantButton>
                  </div>
                </div>
              </div>

              {/* Show if data has */}
              <div>
                {allPackageData?.length > 0 && (
                  <div>
                    <table className="fl-table my-[20px]">
                      <thead className="sticky top-[88px]">
                        <tr>
                          <th>Sl No.</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>ID</th>
                          <th>Duration</th>
                          <th>Package</th>
                          <th>Add Time Slot</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPackageData?.length > 0 &&
                          allPackageData?.map((property, index) => (
                            <tr key={index}>
                              <td>
                                {index + 1 + pageSize * currentPage - pageSize}.
                              </td>
                              <td className="cursor-pointer mx-auto">
                                {property?.packageName}
                              </td>
                              <td>
                                {property?.packageDescription?.length < 60
                                  ? property?.packageDescription
                                  : property?.packageDescription?.slice(0, 60) +
                                    "..."}
                              </td>
                              <td>{property?.id}</td>
                              <td>{property?.duration} min</td>
                              <td>{property?.numberOfPackageComponent}</td>
                              <td>
                                <button
                                  onClick={() => onSelectTimeSlot(property?.id)}
                                  className="bg-btn-secondary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-md shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg "
                                >
                                  Select
                                </button>
                              </td>
                              <td className="flex justify-center gap-x-[8px]">
                                <button
                                  onClick={() => onEditButton(property?.id)}
                                  className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    onDeletePackageById(property?.id)
                                  }
                                  className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="my-10">
                      <Paginations
                        currentPage={currentPage}
                        totalCount={totalPage}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {/* <ModalCommon
            shown={toggleModal}
            width="max-w-[600px]"
            height="min-h-[300px]"
            outSideOff={false}
            close={() => {
              setToggleModal(false);
            }}
          > */}
          <ReactModal
        style={{ content: { width: "60vw" } }}
        isOpen={toggleModal}
        onRequestClose={() => {
          setToggleModal(false);
        }}
      >
            <div className="py-[32px] px-[40px]">
              <div>
                <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                  Create Package
                </h2>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Name: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Name : "
                      type="text"
                      value={groupData?.name || ""}
                      onChange={(e) =>
                        setGroupData((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                    {showError &&
                      (groupData?.name?.length === 0 ||
                        groupData?.name === null) && (
                        <p className="text-[14px]  text-[#f87171] mt-[2px]">
                          This field is required.
                        </p>
                      )}
                  </div>
                </div>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Description: </p>
                  <div className="rounded">
                    <textarea
                      className="w-full p-[8px] min-h-[100px] rounded border-[2px] outline-none border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Description : "
                      type="text"
                      value={groupData.description || ""}
                      onChange={(e) =>
                        setGroupData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                    {showError &&
                      (groupData?.description?.length === 0 ||
                        groupData?.description === null) && (
                        <p className="text-[14px]  text-[#f87171] mt-[2px]">
                          This field is required.
                        </p>
                      )}
                  </div>
                </div>

                {/* <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Variable Items: </p>
              </div>
              <div className="rounded">
                <Select
                  value={groupData?.variableName}
                  onChange={(data) => {
                    setGroupData((prev) => ({
                      ...prev,
                      variableId: data?.id,
                      variableName: { label: data?.label, value: data?.value },
                    }));
                  }}
                  components={animatedComponents}
                  options={allVariables}
                  placeholder={"Select Variables: "}
                  styles={colorStyles}
                  isClearable
                />
              </div>
            </div> */}

                {/* Duration */}
                <div className="flex gap-x-[24px] my-[16px]">
                  <div className="w-full">
                    <p className="text-btn-secondary">
                      Duration:
                      <span className="text-btn-primary">
                        {" "}
                        {groupData?.timeUnit === "Hour"
                          ? groupData?.duration * 60
                          : groupData?.duration}{" "}
                        minute
                      </span>
                    </p>
                    <div className="rounded">
                      <input
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        placeholder="Duration : "
                        type="number"
                        value={groupData?.duration || ""}
                        onWheel={numberInputOnWheelPreventChange}
                        onChange={(e) =>
                          setGroupData((prev) => ({
                            ...prev,
                            duration: e.target.value,
                          }))
                        }
                      />
                      {showError &&
                        (groupData?.duration === 0 ||
                          groupData?.duration?.length === 0) && (
                          <p className="text-[14px]  text-[#f87171] mt-[2px]">
                            Duration is required.
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="w-full">
                    <div>
                      <p className="text-btn-secondary">Time Unit: </p>
                    </div>
                    <div className="rounded">
                      <Select
                        value={groupData?.timeUnit?.label}
                        onChange={(data) => {
                          setGroupData((prev) => ({
                            ...prev,
                            timeUnit: data?.value,
                            // variableName: { label: data?.label, value: data?.value },
                          }));
                        }}
                        components={animatedComponents}
                        defaultValue={timeUnit[0]}
                        options={timeUnit}
                        placeholder={"Select Time Unit: "}
                        styles={colorStyles}
                        isClearable
                      />
                    </div>
                  </div>

                  {/* <div className=" w-[150px] bg-btn-primary mb-[4px] mt-[24px] px-[12px] rounded-[4px] flex items-center gap-x-[12px]">
                <div
                  onClick={() => setIsHourCheck(!isHourCheck)}
                  className="border-[2px] bg-bg-white text-txt-secondary p-[4px]  rounded-[2px] cursor-pointer"
                >
                  {isHourCheck ? (
                    <FaCheck className="text-[#22c55e]" />
                  ) : (
                    <HiXMark className="text-[#ef4444]" />
                  )}
                </div>
                <p className="text-txt-secondary">Hour ?</p>
              </div> */}

                  {/* <div className="">
            <div className=" bg-btn-primary mb-[4px] mt-[12px] px-[12px] rounded-[4px] flex items-center gap-x-[12px]">
              <div
                onClick={() => onCheckChange()}
                className="border-[2px] bg-bg-white text-txt-secondary p-[4px]  rounded-[2px] cursor-pointer"
              >
                {isCheck ? (
                  <FaCheck className="text-[#22c55e]" />
                ) : (
                  <HiXMark className="text-[#ef4444]" />
                )}
              </div>
              <p className="text-txt-secondary">Hour ?</p>
            </div>
            <div className=" bg-btn-primary mb-[4px] mt-[12px] px-[12px] rounded-[4px] flex items-center gap-x-[12px]">
              <div
                onClick={() => onCheckChange()}
                className="border-[2px] bg-bg-white text-txt-secondary p-[4px]  rounded-[2px] cursor-pointer"
              >
                {isCheck ? (
                  <FaCheck className="text-[#22c55e]" />
                ) : (
                  <HiXMark className="text-[#ef4444]" />
                )}
              </div>
              <p className="text-txt-secondary">Minute ?</p>
            </div>
          </div> */}
                </div>

                {/* Check if packages array have data */}
                <div>
                  {addGroupPackage?.length ? (
                    <div>
                      <p className="pt-[8px] text-btn-secondary">
                        Package Component :{" "}
                      </p>
                      {addGroupPackage?.map((pack, index) => (
                        <div key={index}>
                          <div className="flex items-center bg-txt-dotted text-btn-primary px-[16px] py-[8px] mb-[8px] rounded-[4px]">
                            <p className="w-[3%]">{index + 1}.</p>
                            <p className="w-[25%]">
                              {pack?.excursionPackageVariableData?.label}
                            </p>
                            <p className="w-[67%]">
                              {pack?.excursionPackageComponentValue}
                            </p>
                            <div className="w-[5%]">
                              <div
                                onClick={() =>
                                  onDeleteVariableID(
                                    pack,
                                    pack?.excursionPackageComponentId,
                                    pack?.excursionPackageComponentValue,
                                    pack?.excursionPackageVariableData?.id
                                  )
                                }
                                className="bg-[#ef4444] rounded-[4px] py-[2px] px-[4px] cursor-pointer hover:bg-[#f87171]"
                              >
                                <HiXMark className="text-bg-white h-[20px] w-full" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex items-center  text-btn-primary   rounded-[4px]">
                      <p className="">Package Component :</p>
                    </div>
                  )}
                </div>

                <div className="">
                  {/* <TourMerchantButton
                name={"Add Package Component"}
                onClick={() => setToggleModalSecond(!toggleModalSecond)}
                width={"w-full"}
              ></TourMerchantButton> */}
                  <button
                    onClick={() => setToggleModalSecond(!toggleModalSecond)}
                    className="bg-btn-orange text-bg-white w-full px-[10px] py-[8px] hover:bg-[#48c9af16] border-[2px] border-btn-orange rounded-md shadow-md transition-all duration-500 hover:text-btn-orange hover:shadow-lg "
                  >
                    Add Package Component
                  </button>

                  {showError &&
                    (addGroupPackage?.length === 0 ||
                      addGroupPackage === null) && (
                      <p className="text-[14px]  text-[#f87171] mt-[2px]">
                        Package Component is required.
                      </p>
                    )}
                </div>

                {/* <div className="my-[20px]">
              <div className="  py-[8px]  rounded-[4px] flex items-center gap-x-[12px]">
                <div
                  onClick={() => onCheckChange()}
                  className="border-[2px] bg-btn-primary  text-txt-secondary p-[4px] w-[28px] h-[28px] rounded-[8px] cursor-pointer"
                >
                  {isCheck ? (
                    <FaCheck className="text-bg-white" />
                  ) : (
                    <HiXMark className="text-bg-white" />
                  )}
                </div>
                <p className="text-btn-secondary">Is Multi Time Slot ?</p>
              </div>
            </div> */}
              </div>

              <div className="mt-[40px] mb-[20px]">
                <TourMerchantButton
                  name={"Submit"}
                  width={"w-full"}
                  onClick={() => onSubmit()}
                ></TourMerchantButton>
              </div>
            </div>
            <div>
            <ModalCommon
            shown={toggleModalSecond}
            width="max-w-[400px]"
            height="min-h-[150px]"
            outSideOff={false}
            close={() => {
              setToggleModalSecond(false);
            }}
          >
            <div className="py-[16px] px-[32px]">
              <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                Create Package Variables
              </h2>
              <div className="my-[16px]">
                <div>
                  <p className="text-btn-secondary">Variable Items: </p>
                </div>
                <div className="rounded">
                  <Select
                    // value={addPackageData?.excursionPackageVariableData ?  addPackageData?.excursionPackageVariableData : null}
                    value={
                      addPackageData?.excursionPackageVariableData !== undefined
                        ? addPackageData?.excursionPackageVariableData
                        : null
                    }
                    onChange={(data) =>
                      setAddPackageData((prev) => ({
                        ...prev,
                        excursionPackageComponentId: 0,
                        excursionPackageVariableData: data,
                      }))
                    }
                    components={animatedComponents}
                    options={filteredVariables}
                    placeholder={"Select Variables: "}
                    styles={colorStyles}
                    isClearable
                  />
                </div>
              </div>

              <div className="my-[16px]">
                <p className="text-btn-secondary">Package Content: </p>
                <div className="rounded">
                  <input
                    className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                    placeholder="Package Content : "
                    type="text"
                    value={addPackageData?.excursionPackageComponentValue || ""}
                    onChange={(e) =>
                      setAddPackageData((prev) => ({
                        ...prev,
                        excursionPackageComponentValue: e.target.value,
                      }))
                    }
                  />
                  {showError &&
                    (groupData?.name?.length === 0 ||
                      groupData?.name === null) && (
                      <p className="text-[14px]  text-[#f87171] mt-[2px]">
                        This field is required.
                      </p>
                    )}
                </div>
              </div>

              <div className="my-[20px]">
                <TourMerchantButton
                  name={"Submit"}
                  width={"w-full"}
                  onClick={() => onSubmitVariable()}
                ></TourMerchantButton>
              </div>
            </div>
          </ModalCommon>
            </div>
            </ReactModal>
          {/* </ModalCommon> */}

          

          <div className="mt-[200px] flex justify-center items-center w-full">
            <CustomButtonGroupExcruions
              setSaveAndNext={backAndSave}
              onSubmit={onSubmitButton}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePackage;
