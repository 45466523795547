import React, { useState, useEffect } from "react";
import ButtonFilled from "./ButtonFilled";
import ButtonOutlined from "./ButtonOutlined";
// import { submitReviewInfo } from "../store/registration/submitReview/api/submitReviewApi";
import ModalGlobal from "./ModalGlobal";
import { useDispatch, useSelector } from "react-redux";
import HorizontalBar from "./HorizontalBar/HorizontalBar";
import Pagenation from "./pagenation/Pagenation";
import "./common/header.css";
import { categoriesInfo } from "../store/admin/adminContract/api/adminContractApi";
import {
  adminRoute,
  merchantRoute,
  ReviewFor,
  UserType,
} from "../contants/Constants";
import { reviewSubmission } from "../store/admin/review/api/reviewApi";
import Loading from "./Loader/Loading";
import { fetchSummeryGet } from "../store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import { reviewSubmitDisableDecision } from "../utils/reviewSubmitDisableDecision";
import { returnAdminOrUserBasedOnPath } from "../utils/returnAdminOrUserBasedOnPath";
import { useLocation, useNavigate } from "react-router";
import OutsideClickHandler from "react-outside-click-handler";
import dashboardOrRegistrationRoute, {
  RouteType,
} from "../utils/dashboardOrRegistrationRoute";

const CategoriesTableComponent = ({ withoutHeader }) => {
  const [categoryListData, setCategoryListData] = useState();
  const [modalTrigger, setModalTrigger] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let superContractId = localStorage.getItem("superContractId");

  const handleCategoryEdit = (id) => {
    // console.log("clicked")
    if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
      navigate(adminRoute.ADMIN_CATEGORY + id);
    } else {
      navigate(merchantRoute.CATEGORY + id);
    }
  };
  // const handleCategoryEdit = (id) => {
  //   navigate(
  //     returnAdminOrUserBasedOnPath(location.pathname) == UserType.USER
  //       ? merchantRoute.CATEGORY + id
  //       : adminRoute.ADMIN_CATEGORY + id
  //   );
  // };
  // useEffect for loading upper part
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  const response = useSelector((state) => state.categoriesInfoGetReducer);

  console.log(fetchSummeryData, "resres");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      categoriesInfo(
        returnAdminOrUserBasedOnPath(location.pathname),
        superContractId
      )
    );
  }, []);

  useEffect(() => {
    if (response && response.data) {
      setCategoryListData(response.data?.roomCategoryList);
    }
  }, [response]);

  console.log("categoryListData", categoryListData);

  const buttonOutlinedHandle = () => {
    navigate(merchantRoute.HOTELS);
  };
  let adminSubmit = useSelector((state) => state.submitReviewReducer);

  const buttonFilledHandle = () => {
    dispatch(
      reviewSubmission(
        ReviewFor.SUBMIT,
        localStorage.getItem("contractId"),
        {},
        () => {
          setModalTrigger(true);
          dispatch(fetchSummeryGet(localStorage.getItem("id")));
        }
      )
    );
  };
  useEffect(() => {
    if (adminSubmit.data && adminSubmit.data.status == 200) {
      setModalTrigger(true);
    }
  }, [adminSubmit]);
  const newAddHandleClick = () => {
    navigate(merchantRoute.CATEGORY + 0);
  };

  const handleDeleteClick = (id, index) => {
    // setDeleteCandidate({ ...deleteCandidate, id: id, index: index });
    // setShowDeleteModal(true);
  };
  const showEditMenu = (idName) => {
    console.log("showing");
    document.getElementById(`menu${idName}`).classList.toggle("hidden");
  };
  const hideEditMenu = (idName) => {
    document.getElementById(`menu${idName}`).classList.add("hidden");
  };
  return (
    <div className="w-full">
      <div>
        {withoutHeader ? (
          <></>
        ) : (
          <>
            {returnAdminOrUserBasedOnPath(location.pathname) ===
            UserType.USER ? (
              <Pagenation owner="owner" />
            ) : null}
            {dashboardOrRegistrationRoute() === RouteType.REGISTRATION && (
              <HorizontalBar selectedBar="Categories" />
            )}
            <div className="px-12 2xl:px-0 2xl:container mx-auto flex justify-between p-4">
              <div className="text-[27px] py-2">Categories</div>
              {returnAdminOrUserBasedOnPath(location.pathname) ===
              UserType.USER ? (
                <button
                  className="cursor-pointer h-[60px] w-[160px] hover:text-txt-hint flex justify-center gap-2 items-center"
                  onClick={newAddHandleClick}
                >
                  <div className="w-[200px]">Add new</div>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="10 10 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_3650_18025)">
                      <rect
                        x="19"
                        y="16"
                        width="40"
                        height="40"
                        rx="20"
                        fill="#4E90A8"
                      />
                      <path
                        d="M37.724 29V34.859H32V36.938H37.724V42.77H40.019V36.938H45.77V34.859H40.019V29H37.724Z"
                        fill="#FFFBEF"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_3650_18025"
                        x="0"
                        y="0"
                        width="72"
                        height="72"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="-3" />
                        <feGaussianBlur stdDeviation="8" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_3650_18025"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_3650_18025"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
              ) : null}
            </div>
          </>
        )}

        <div className="px-12 2xl:px-0 2xl:container mx-auto">
          <div className="h-[78px] px-5 py-3 border-b-2 border-gray-200 rounded-t-[25px] overflow-hidden bg-btn-primary flex  text-center text-xl font-normal text-bg-white tracking-wider">
            <div className="w-[90px] flex items-center"></div>

            <div className="flex-grow max-w-[300px] flex items-center justify-start">
              Category Name
            </div>
            <div className="w-[150px] flex items-center justify-start">
              Status
            </div>
            <div className="w-[100px] flex items-center justify-start">
              Price
            </div>
            <div className="w-[100px] flex items-center justify-start">
              Rooms
            </div>
            <div className="w-[100px] flex items-center justify-start">
              Available
            </div>
            <div className="w-[100px] flex items-center justify-start">
              Taken
            </div>
            <div className="w-[220px] flex items-center justify-start">
              Amenties
            </div>
            <div className="w-[200px] flex items-center justify-start">
              Capacity
            </div>
            <div className="w-[100px] flex items-center justify-start">
              Options
            </div>
          </div>
          <div className="mt-6"></div>
          {categoryListData &&
            categoryListData.map((member, index) => {
              const {
                roomCategoryId,
                categoryName,
                noOfKingBeds,
                noOfQueenBeds,
                noOfRoomsInCategory,
                baseRate,
                maxNumberOfAdultOccupants,
                maxNumberOfChildOccupants,
                status,
              } = member; //destructuring
              return (
                <div
                  className={`h-[78px] px-5 py-3 ${
                    index < categoryListData?.length - 1 && "border-b-0 "
                  } border rounded-[8px] specialBody flex  text-left text-md font-normal tracking-wider`}
                >
                  <div className="w-[90px] flex items-center">
                    {roomCategoryId}
                  </div>
                  <div className=" flex-grow max-w-[300px] flex items-center justify-start">
                    {categoryName}
                  </div>
                  <div className=" w-[150px] flex items-center justify-start">
                    <div
                      className={`p-1 text-bg-white 
                    ${status === "ACCEPTED" && "bg-[#0c0]"}
                    ${status === "DRAFT" && "bg-[#dd0]"}
                    ${status === "REJECTED" && "bg-[#d00]"}
                    `}
                    >
                      {status}
                    </div>
                  </div>
                  <div className=" w-[100px] flex items-center justify-start">
                    {baseRate}
                  </div>
                  <div className=" w-[100px] flex items-center justify-start">
                    {noOfRoomsInCategory}
                  </div>
                  <div className=" w-[100px] flex items-center justify-start">
                    {noOfRoomsInCategory}
                  </div>
                  <div className=" w-[100px] flex items-center justify-start">
                    {noOfRoomsInCategory}
                  </div>
                  <div className=" w-[220px] flex items-center justify-start">
                    {noOfKingBeds} King sized beds <br />
                    {noOfQueenBeds} Queen sized beds
                  </div>

                  <div className=" w-[200px] flex items-center justify-start">
                    up to {maxNumberOfAdultOccupants} adults
                    <br />
                    up to {maxNumberOfChildOccupants} children
                  </div>

                  <div className="w-[100px] flex items-center justify-start relative">
                    {process.env.REACT_APP_USER_TYPE === UserType.ADMIN && (
                      <div
                        className="block px-4 py-2 rounded-lg shadow-md cursor-pointer text-sm leading-5 text-gray-700 focus:bg-[rgb(235,231,219)] focus:outline-none hover:bg-[rgb(235,231,219)] transition duration-150 ease-in-out"
                        role="menuitem"
                        onClick={() => {
                          navigate(adminRoute.ADMIN_CATEGORY + roomCategoryId);
                        }}
                      >
                        Review
                      </div>
                    )}

                    {process.env.REACT_APP_USER_TYPE !== UserType.ADMIN && (
                      <div
                        className="block px-4 py-2 rounded-lg shadow-md cursor-pointer text-sm leading-5 text-gray-700 focus:bg-[rgb(235,231,219)] focus:outline-none hover:bg-[rgb(235,231,219)] transition duration-150 ease-in-out"
                        role="menuitem"
                        onClick={() => {
                          handleCategoryEdit(roomCategoryId);
                        }}
                      >
                        Edit
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {returnAdminOrUserBasedOnPath(location.pathname) == UserType.USER ? (
          response.loading ? (
            <div className="ml-[45%]">
              <Loading />
            </div>
          ) : (
            <div className="flex gap-x-2 my-10  px-12 2xl:px-0 2xl:container mx-auto">
              <ButtonOutlined
                title="Back"
                buttonOutlinedHandle={buttonOutlinedHandle}
                w="176px"
                h="49px"
                // disable={fetchSummeryData.status == "PENDING" ? "yes" : "no" }
              />
              <ButtonFilled
                title="Submit"
                buttonFilledHandle={buttonFilledHandle}
                // disable={categoryStatus == "DRAFT" ? "no" : "yes"}
                w="291px"
                h="49px"
                disable={reviewSubmitDisableDecision(
                  fetchSummeryData.status,
                  fetchSummeryData.categoryStatus
                )}
              />
            </div>
          )
        ) : null}

        <ModalGlobal
          header="The request is submitted successfully"
          body="Our team will review the submission, and notify you within 72 hours. Stay in touch!"
          openDefault={modalTrigger}
        />
      </div>
    </div>
  );
};

export default CategoriesTableComponent;
