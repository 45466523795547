import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AIRTICKET_CONTROLLER_API, baseUrl } from "../../contants/Endpoints";
import Paginations from "../../pages/admin/Paginations/Paginations";
import { authAxios } from "../../utils/axiosWrapper";

const AirportCreate = () => {
  const [allAirport, setAllAirport] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [totalAirport, setTotalAirport] = useState(0);
  let pageSize = 10;
  const [formData, setFormData] = useState({
    iata_code: "",
    latitude: 0,
    longitude: 0,
    name: "",
    city: "",
    country_code: "",
    state: "",
    country: "",
    woeid: "",
    time_zone: "",
    phone: "",
    airport_type: "",
    email: "",
    url: "",
    runway_length: 0,
    elevation: 0,
    icao: "",
    direct_flights: 0,
    carriers: 0,
    id: 0,
  });

  // Handle input change for each form field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "carriers" ||
        name === "direct_flights" ||
        name === "elevation" ||
        name === "latitude" ||
        name === "longitude" ||
        name === "runway_length"
          ? parseFloat(value)
          : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    authAxios
      .post(`${baseUrl}${AIRTICKET_CONTROLLER_API.AIRPORT_INFO_API}`, {
        ...formData,
      })
      .then((res) => {
        toast.success("Airport Created successfully!");
        handleGetAllAirportData();
        setFormData({
          iata_code: "",
          latitude: 0,
          longitude: 0,
          name: "",
          city: "",
          country_code: "",
          state: "",
          country: "",
          woeid: "",
          time_zone: "",
          phone: "",
          airport_type: "",
          email: "",
          url: "",
          runway_length: 0,
          elevation: 0,
          icao: "",
          direct_flights: 0,
          carriers: 0,
          id: 0,
        });
      })
      .catch((err) => {
        toast.error("Airport Add Failed!");
      });
  };
  const handleGetAllAirportData = () => {
    //
    authAxios
      ?.get(
        `${
          AIRTICKET_CONTROLLER_API.AIRPORT_INFO_GET_ALL_API
        }?pageNumber=${currentPage}&pageSize=${10}&queryString=${searchText}&requestId=123`
      )
      .then((res) => {
        setAllAirport(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
        setTotalAirport(res?.data?.numberOfEntries);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const delay = setTimeout(() => {
      handleGetAllAirportData();
    }, 1000); // Delay of 2000 milliseconds (2 seconds)

    return () => clearTimeout(delay);
  }, [currentPage, searchText]);

  return (
    <div className="flex flex-col justify-start gap-[60px] my-[20px] max-w-[900px]">
      <div className="max-w-[540px] flex justify-between items-center gap-[20px] h-full">
        <div className="w-full">
          <p className="text-[18px]">Search </p>
          <input
            placeholder="Search"
            className="w-full px-[12px] border border-btn-border rounded-[12px] py-[4px]"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <p className="whitespace-nowrap">Total Airport : {totalAirport}</p>
      </div>
      <div className="w-[2800px]">
        <table className="table-fixed w-full">
          <thead className="py-2 border-0">
            <tr className="bg-btn-secondary ">
              {Object.keys(formData)?.map((key) => (
                <th
                  className="text-[12px] whitespace-nowrap text-txt-secondary py-4"
                  key={key}
                >
                  {key.replace(/_/g, " ").toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="h-[10px] overflow-y-scroll">
            {allAirport &&
              allAirport?.map((airport, index) => {
                return (
                  <tr
                    onClick={() => setFormData(airport)}
                    key={index}
                    className="border-b last:border-0 border-btn-border  hover:bg-btn-warning cursor-pointer"
                  >
                    <td className="text-[12px] py-4 text-center">
                      {airport?.iata_code}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.latitude}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.longitude}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.name}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.city}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.country_code}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.state}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.country}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.woeid}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.time_zone}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.phone}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.airport_type}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.email}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.url}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.runway_length}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.elevation}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.icao}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.direct_flights}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airport?.direct_flights}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="my-10">
          <Paginations
            currentPage={currentPage}
            totalCount={totalPage}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <div>
        <h2 className="text-center mb-[20px] text-base18 text-btn-secondary">
          Airport create
        </h2>
        <form className="w-full " onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-[12px]">
            {Object.keys(formData)
              .filter((key) => key !== "id")
              .map((key) => (
                <div
                  className="w-full"
                  key={key}
                  style={{ marginBottom: "10px" }}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    {key.replace(/_/g, " ").toUpperCase()}
                  </label>
                  <input
                    className="border border-bg-semiblack p-[10px] rounded-[8px] w-full"
                    type={typeof formData[key] === "number" ? "number" : "text"}
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
          </div>

          <button
            className="bg-btn-secondary hover:bg-btn-primary active:bg-btn-primary py-[12px] w-full rounded-[8px] text-bg-white text-base16"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AirportCreate;
