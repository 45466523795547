import React, { useRef, useState } from "react";

const SliderCart = ({
  cart,
  children,
  containerWidth,
  cartWidth,
  cartViewNumber,
}) => {
  const [position, setPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const sliderRef = useRef(null);

  const handleLeftClick = () => {
    setPosition((prevPosition) =>
      prevPosition + cartWidth > 0 ? 0 : prevPosition + cartWidth
    );
  };

  const handleRightClick = () => {
    setPosition((prevPosition) =>
      prevPosition - cartWidth < -(cart?.length - cartViewNumber) * cartWidth
        ? -(cart.length - cartViewNumber) * cartWidth
        : prevPosition - cartWidth
    );
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.nativeEvent.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.nativeEvent.clientX;
    const diff = x - startX;

    if (diff < 0) {
      setPosition((prevPosition) =>
        prevPosition - Math.abs(diff) <
        -(cart?.length - cartViewNumber) * cartWidth
          ? -(cart.length - cartViewNumber) * cartWidth
          : prevPosition - Math.abs(diff)
      );
    } else {
      setPosition((prevPosition) =>
        prevPosition + diff > 0 ? 0 : prevPosition + diff
      );
    }

    setStartX(x);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      ref={sliderRef}
      className={`overflow-hidden ${containerWidth}`}
      //   style={{ width: "343px" }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="flex justify-between items-center">
        <span onClick={handleLeftClick} className="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
        </span>

        <span onClick={handleRightClick} className="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
            />
          </svg>
        </span>
      </div>
      <div
        className="flex flex-row gap-x-[6px]"
        style={{
          transform: `translateX(${position}px)`,
          transition: "transform 0.5s ease",
          width: `${cart?.length * cartWidth}px`,
        }}
        onMouseDown={handleMouseDown}
      >
        {children}
      </div>
    </div>
  );
};

export default SliderCart;
