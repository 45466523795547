import { DashboardRoutes } from "../contants/Constants";
import MerchantDashboard from "../pages/admin/Dashboard/MerchantDashboard";
import MerchantDashboardCategories from "../pages/admin/Dashboard/MerchantDashboardCategories";
import MerchantDashboardHotelDetails from "../pages/admin/Dashboard/MerchantDashboardHotelDetails";
import MerchantDashboardHotels from "../pages/admin/Dashboard/MerchantDashboardHotels";

export const decideMerchantDashboardComponent = (route) => {
  switch (route) {
    case DashboardRoutes.DASHBOARD:
      return <MerchantDashboard />;
    case DashboardRoutes.HOTELS:
      return <MerchantDashboardHotels />;
    case DashboardRoutes.HOTEL_DETAILS:
      return <MerchantDashboardHotelDetails />;
    case DashboardRoutes.CATEGORIES:
      return <MerchantDashboardCategories />;
    default:
      return <MerchantDashboard />;
  }
};
