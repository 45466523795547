import React, { useState } from "react";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import Includes from "./Includes";
import Excludes from "./Excludes";
import CreateItinerary from "./CreateItinerary";
import { toast } from "react-toastify";
import { numberInputOnWheelPreventChange } from "../utils/common";

const ExcursionCreateOption = () => {
  const [showError, setShowError] = useState(false);
  const [groupData, setGroupData] = useState({
    name: "",
    maxParticipant: 0,
    minParticipant: 0,
    startDate: "",
    endDate: "",
    duration: 0,
  });

  const onSubmit = () => {
    if(groupData?.name?.length === 0 || groupData?.maxParticipant?.length === 0 || groupData?.minParticipant?.length === 0 || groupData?.startDate?.length === 0  || groupData?.endDate?.length === 0 || groupData?.duration?.length === 0){
      setShowError(true);
      toast.error("Please Provide all the information")
    }
    console.log("GroupData: ", groupData);
  };

  return (
    <div>
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]">
            Excursion option create
          </span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Aiming to captivate users audience with engaging details about the
          experiences they offer.
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-[20px] mx-[20px] md:mx-[40px]">
        <div className="w-full">
          <div className="w-full shadow-customShadow px-[24px] py-[8px] rounded-[4px]">
            <div className="my-[16px]">
              <p className="text-btn-secondary">Option Name: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Option name : "
                  type="text"
                  value={groupData.name || ""}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.name?.length === 0 ||
                    groupData?.name === null) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      Option Name is required.
                    </p>
                  )}
              </div>
            </div>
            <div className="my-[16px]">
              <p className="text-btn-secondary">Max no of participants: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Max no of participants:"
                  type="number"
                  inputMode="numeric"
                  value={groupData.maxParticipant || ""}
                  onWheel={numberInputOnWheelPreventChange}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      maxParticipant: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.maxParticipant?.length === 0 || groupData?.maxParticipant === 0) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      Max no of participants is required.
                    </p>
                  )}
              </div>
            </div>
            <div className="my-[16px]">
              <p className="text-btn-secondary">Min no of participants: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Min no of participants:"
                  type="number"
                  value={groupData.minParticipant || ""}
                  onWheel={numberInputOnWheelPreventChange}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      minParticipant: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.minParticipant?.length === 0 || groupData?.minParticipant === 0) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      Min no of participants is required.
                    </p>
                  )}
              </div>
            </div>
            <div className="my-[16px]">
              <p className="text-btn-secondary">Starting Date: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Min no of participants:"
                  type="date"
                  value={groupData.startDate || ""}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.startDate?.length === 0 ||
                    groupData?.startDate === null) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      Starting Date is required.
                    </p>
                  )}
              </div>
            </div>
            <div className="my-[16px]">
              <p className="text-btn-secondary">End Date: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] outline-none border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Min no of participants:"
                  type="date"
                  value={groupData.endDate || ""}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.endDate?.length === 0 ||
                    groupData?.endDate === null) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      End Date is required.
                    </p>
                  )}
              </div>
            </div>
            <div className="my-[16px]">
              <p className="text-btn-secondary">Duration: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Duration:"
                  type="number"
                  value={groupData?.duration || ""}
                  onWheel={numberInputOnWheelPreventChange}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      duration: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.duration?.length === 0) && (
                    <p className="text-[14px]  text-[#f87171] mt-[2px]">
                      Duration is required.
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full shadow-customShadow px-[24px] py-[8px] rounded-[4px]">
          <div className="mb-[32px]">
            <CreateItinerary></CreateItinerary>
          </div>
          <div className="mb-[32px]">
            <Includes></Includes>
          </div>
          <div className="mb-[24px]">
            <Excludes></Excludes>
          </div>
        </div>
      </div>

      <div className="my-[40px] flex justify-center">
        <TourMerchantButton
          name={"Submit"}
          width={"w-[200px] md:w-[500px]"}
          onClick={onSubmit}
        ></TourMerchantButton>
      </div>
    </div>
  );
};

export default ExcursionCreateOption;
