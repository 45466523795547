/* eslint-disable react-hooks/exhaustive-deps */
import { addDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import EachCalenderDateCard from "../../components/calender/EachCalenderDateCard";
import Checkbox from "../../components/Checkbox";
import InputComponent from "../../components/InputComponent";
import Loading from "../../components/Loader/Loading";
import MultiSelectParent from "../../components/multiselect/MultiSelectParent";
import SingleSelectParentCategoryReturn from "../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import { UserType } from "../../contants/Constants";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { DateFormatTranferToSixDigit } from "../../utils/DateFormatTranferToSixDigit";
import { toastMessage } from "../../utils/toast";

export default function AdminPriceCalc() {
  const date = new Date();
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [selectedDays, setSelectedDays] = React.useState([]);

  const [blackPrice, setBlackPrice] = useState(0);
  const [basePrice, setBasePrice] = useState(0);
  const [redPrice, setRedPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [ghuddyCommissionPercent, setGhuddyCommissionPercent] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [availableRoom, setAvailableRoom] = useState(0);
  const [shurjoCommission, setShurjoCommission] = useState(2.5);
  const [commissionIncluded, setCommissionIncluded] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [month, setMonth] = React.useState(date.getMonth());
  const [year, setYear] = React.useState(date.getFullYear());
  // const [calculated, setCalculated] = useState(false);
  const [allWeek, setAllWeek] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [startData, setStartData] = useState();
  const [endData, setEndData] = useState();

  const [Scenarios, setScenarios] = useState([
    { name: "Date range", value: "dateRange" },
    { name: "Weekly", value: "weekly" },
    { name: "Specific Date", value: "singleDate" },
  ]);

  const [currentScenario, setCurrentScenario] = useState(Scenarios[0]);
  const [getAvailableData, setAvailableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    authAxios
      .get(
        `${apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?contractId=1&requestId=2&pageSize=200&pageNumber=1`
      )
      .then((res) => {
        setHotelList(res.data.entries);
        setSelectedHotel(res.data.entries[0]);
      })
      .catch((err) => {
        toastMessage("Error fecthing data", "error");
      });
  }, []);

  useEffect(() => {
    if (selectedHotel) {
      authAxios
        .get(
          `${apiEndPoint.ADMIN_GET_CATEGORY_BY_PROPERTY}${selectedHotel.propertyId}?requestId=113`
        )
        .then((res) => {
          if (res.data.roomCategories.length > 0) {
            setCategories(res.data.roomCategories);
            setSelectedCategory(res.data.roomCategories[0]);
          } else {
            setSelectedCategory(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedHotel]);

  useEffect(() => {
    if (selectedCategory) {
      setBlackPrice(90);
      // setBasePrice(selectedCategory.baseRate);
      setBasePrice(1100);
      setRedPrice(100);
      setDiscountPercent(0);
      setGhuddyCommissionPercent(0);
      setDiscount(0);
      setCalculated(false);
      setAllWeek(null);
    }
  }, [selectedCategory]);

  // useEffect(() => {
  //     setDiscount(basePrice * (discountPercent / 100));
  // }, [discountPercent]);

  useEffect(() => {
    if (commissionIncluded) {
      setShurjoCommission(0);
      setGhuddyCommissionPercent(0);
    } else {
      setShurjoCommission(2.5);
    }
  }, [commissionIncluded]);

  const handleDiscount = (type, val) => {
    if (type === "amount") {
      setDiscount(val);
      setDiscountPercent(parseFloat((val / basePrice) * 100).toFixed(2));
    } else {
      setDiscountPercent(val);
      setDiscount(parseFloat(basePrice * (val / 100)).toFixed(2));
    }
  };

  const Calculate = () => {
    authAxios
      .post(
        `${apiEndPoint.ADMIN_BILL_CALCULATE}/${selectedCategory?.roomCategoryId}/calculation`,
        {
          discount: discountPercent,
          ghuddyCommission: ghuddyCommissionPercent,
          isGhuddyCommissionIncluded: commissionIncluded,
          requestId: "calc",
        }
      )
      .then((res) => {
        setBlackPrice(res.data.data.blackPrice);
        setDiscount(res.data.data.discountAmount);
        setRedPrice(res.data.data.redPrice);
        setCalculated(true);
      })
      .catch((err) => {});
  };

  const ApplyPrice = () => {
    if (calculated) {
      if (selectedCategory.noOfRoomsInCategory >= availableRoom) {
        setLoading(true);
        const availabilityDataList = [];

        if (currentScenario.value === "dateRange") {
          const diff = Math.ceil(
            (new Date(endData).getTime() - new Date(startData).getTime()) /
              (1000 * 3600 * 24)
          );
          for (let i = 0; i < diff + 1; i++) {
            const current = addDays(new Date(startData), i);
            const obj = {
              date: DateFormatTranferToSixDigit(current),
              discountPercent: discountPercent,
              ghuddyCommission: ghuddyCommissionPercent,
            };
            availabilityDataList.push(obj);
          }
        } else if (currentScenario.value === "weekly") {
          const diff = Math.ceil(
            (new Date(endData).getTime() - new Date(startData).getTime()) /
              (1000 * 3600 * 24)
          );
          for (let i = 0; i < diff + 1; i++) {
            const current = addDays(new Date(startData), i);
            if (selectedDays.findIndex((el) => el == current.getDay()) > -1) {
              const obj = {
                date: DateFormatTranferToSixDigit(current),
                discountPercent: discountPercent,
                ghuddyCommission: ghuddyCommissionPercent,
              };
              availabilityDataList.push(obj);
            }
          }
        } else {
          const obj = {
            date: DateFormatTranferToSixDigit(startData),
            discountPercent: discountPercent,
            ghuddyCommission: ghuddyCommissionPercent,
          };
          availabilityDataList[0] = obj;
        }

        const finalObject = {
          availabilityDataList,
          isGhudyCommissionIncluded: commissionIncluded,
          requestId: "setAv",
          availableRoom,
        };
        console.log(finalObject);

        authAxios
          .post(
            `${apiEndPoint.ADMIN_SET_AVAILABILITY}${selectedCategory?.roomCategoryId}`,
            finalObject
          )
          .then((res) => {
            getAvailabilityData();
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        toastMessage(
          "Available room cannot be greater than total room",
          "error"
        );
      }
    } else {
      toastMessage("Please calculate first", "error");
    }
  };

  useEffect(() => {
    setStartDate(getStartDate(new Date(`${year}-${month + 1}-01`)));
    setEndDate(getEndDate(new Date(`${year}-${month + 1}-01`)));
  }, [month, year]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getPreviousMonthAndYear = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  function getStartDate(date) {
    let firstDate = 1;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var firstDayOfMonth = new Date(`${year}-${month}-${firstDate}`);
    return moment(firstDayOfMonth)
      .subtract(firstDayOfMonth.getDay(), "days")
      .toDate();
  }

  function getEndDate(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    console.log("lastDayOfMonth", month);
    var lastDayOfMonth = new Date(year, month, 0);
    console.log(lastDayOfMonth, "lastDayOfMonth");
    let diff = 6 - lastDayOfMonth.getDay();
    console.log(diff, lastDayOfMonth.getDay(), "lastDayOfMonth");
    return moment(lastDayOfMonth).add(diff, "days").toDate();
  }

  const getNextMonthAndYear = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  useEffect(() => {
    if (getAvailableData) {
      formatData(
        Object.keys(getAvailableData).map((key) => {
          return { ...getAvailableData[key], dayInt: key };
        })
      );
    }
  }, [getAvailableData]);

  const getAvailabilityData = () => {
    authAxios
      .get(
        `${apiEndPoint.ADMIN_GET_AVAILABILITY}${
          selectedCategory?.roomCategoryId
        }?endDate=${DateFormatTranferToSixDigit(
          endDate
        )}&requestId=WEREW&startDate=${DateFormatTranferToSixDigit(startDate)}`
      )
      .then((resData) => {
        setAvailableData(resData.data.data);
        setLoading(false);
      })
      .catch((err2) => {
        setLoading(false);
      });
  };

  const formatData = (array) => {
    const mainArray = [];
    let index = 0;
    for (let i = 0; i < array.length; i += 7) {
      const subArrray = [];
      for (let j = 0; j < 7; j++) {
        if (array[index]) {
          subArrray.push({ ...array[index] });
        }
        index++;
      }
      if (subArrray.length) {
        mainArray.push(subArrray);
      }
    }
    console.log(mainArray);
    setAllWeek([...mainArray]);
  };

  useEffect(() => {
    if (selectedCategory && startDate) {
      getAvailabilityData();
    }
  }, [startDate, selectedCategory]);

  return (
    <div className="container mx-auto">
      <div className="text-3xl mb-6 text-center border-b pb-2">
        Price Calculator
      </div>

      <div className="md:w-[1000px]">
        <div className="flex gap-2 flex-col md:flex-row ">
          <div className="md:w-1/2">
            <SingleSelectParentCategoryReturn
              warningView={false}
              selectedValue={selectedHotel}
              setSelectedValue={setSelectedHotel}
              data={hotelList}
              placeholder="Select Hotel"
              displayField={"propertyName"}
              selectedFieldName="id"
            />
          </div>
          <div className="md:w-1/2">
            <SingleSelectParentCategoryReturn
              warningView={false}
              selectedValue={selectedCategory}
              setSelectedValue={setSelectedCategory}
              data={categories}
              placeholder="Category Name"
              displayField={"categoryName"}
              selectedFieldName="categoryId"
            />
          </div>
        </div>

        <div className="my-4">
          <Checkbox
            checked={commissionIncluded}
            handleChange={() => setCommissionIncluded(!commissionIncluded)}
            title="Ghuddy Commission Included"
          />
        </div>
        {/* <div className=" my-4 w-1/2">
                    <InputComponent
                        inputValue={discount}
                        placeholder={`Discount`}
                        setInputValue={setDiscount}
                    />
                </div> */}
        <div className="flex gap-2 items-center">
          <InputComponent
            name={"discount"}
            warningView={false}
            inputValue={discount}
            setInputValue={(val) => {
              // setDiscount(val);
              handleDiscount("amount", val);
            }}
            addSpan={true}
            spanValue="BDT"
          />
          or
          <InputComponent
            name={"discountPercent"}
            warningView={false}
            inputValue={discountPercent}
            setInputValue={(val) => {
              // setDiscountPercent(val);
              handleDiscount("percent", val);
            }}
            addSpan={true}
            spanValue="%"
          />
        </div>
        <div className="my-2">
          <div className="text-lg">Discount : {discountPercent} %</div>
          <input
            type="range"
            min="0"
            max="100"
            step={0.01}
            value={discountPercent}
            onChange={(e) => setDiscountPercent(e.target.value)}
            className="sliderCustom"
            id="myRange"
          />
        </div>
        {!commissionIncluded && (
          <div className="my-2">
            <div className="text-lg">
              Ghuddy Commission : {ghuddyCommissionPercent} %
            </div>
            <input
              type="range"
              min="0"
              max="100"
              step={0.01}
              value={ghuddyCommissionPercent}
              onChange={(e) => setGhuddyCommissionPercent(e.target.value)}
              className="sliderCustom"
              id="myRange"
            />
          </div>
        )}

        <SingleSelectParentCategoryReturn
          selectedValue={currentScenario}
          setSelectedValue={setCurrentScenario}
          displayField={"name"}
          selectedFieldName={"value"}
          required={true}
          placeholder="Select Scenario"
          data={Scenarios}
          rejectionArray={[]}
          setRejectionArray={() => {}}
          isAdmin={false}
          warningView={false}
          name="scenario"
        />

        <div className="flex gap-2 py-2">
          <div className="w-1/2 flex items-center gap-2">
            <ReactDatePicker
              title="Deal Start Date"
              placeholderText="Start Date"
              className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
              selected={startData}
              onChange={(date) => setStartData(date)}
            />
          </div>
          {currentScenario.value !== "singleDate" && (
            <div className="w-1/2 flex items-center gap-2">
              <ReactDatePicker
                placeholderText="End Date"
                className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                selected={endData}
                onChange={(date) => setEndData(date)}
              />
            </div>
          )}
        </div>
        <div className="mt-2">
          <InputComponent
            placeholder={`Available Room (Out of ${selectedCategory?.noOfRoomsInCategory})`}
            inputValue={availableRoom}
            setInputValue={setAvailableRoom}
            warningView={false}
          />
        </div>
        {currentScenario.value === "weekly" && (
          <div className="my-2">
            <MultiSelectParent
              data={[
                { name: "Friday", id: 5 },
                { name: "Saturday", id: 6 },
                { name: "Sunday", id: 0 },
                { name: "Monday", id: 1 },
                { name: "Tuesday", id: 2 },
                { name: "Wednesday", id: 3 },
                { name: "Thursday", id: 4 },
              ]}
              displayField="name"
              isAdmin={false}
              name="weeks"
              placeholder={"Select Days"}
              selectedArray={selectedDays}
              setSelectedArray={setSelectedDays}
              warningView={false}
            />
          </div>
        )}

        <div className="text-center py-10 text-xl">
          Base Rate: {basePrice} {selectedCategory?.currency}
        </div>

        <div className="md:max-w-[600px] mx-auto">
          {/* <div className="text-center mt-10 mb-2 text-xl font-semibold">
                        Breakdown
                    </div> */}
          <div className="rounded-md border px-4 py-6  border-[#9e9989] ">
            <div className="flex items-center gap-2">
              <div className="w-2/3">Black Price</div>
              <div className="text-justify flex-grow">
                : {parseFloat(blackPrice).toFixed(2)}{" "}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/3">Discount </div>
              <div className="text-justify flex-grow">
                : {discountPercent}
                {"%"}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/3">Ghuddy Commission</div>
              <div className="text-justify flex-grow">
                : {ghuddyCommissionPercent}
                {"%"}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/3">Shurjo Commission</div>
              <div className="text-justify flex-grow">
                : {shurjoCommission}
                {"%"}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/3">Discount Amount</div>
              <div className="text-justify flex-grow">
                : {parseFloat(discount).toFixed(2)}{" "}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className="w-2/3">Red Price</div>
              <div className="text-justify flex-grow">
                : {parseFloat(redPrice).toFixed(2)}{" "}
              </div>
            </div>

            {/* <div className="border-t my-2 border-dashed border-[#000] h-1"></div> */}
            {/* <div className="flex items-center gap-2">
                            <div className="w-2/3 text-lg font-semibold">
                                Checkout Price
                            </div>
                            <div className="text-justify flex-grow font-semibold">
                                : {totalCheckoutPrice.toFixed(2)}{" "}
                            </div>
                        </div> */}
          </div>
        </div>

        <div className="flex item-center justify-center gap-4 mt-8">
          <div className="w-[200px]">
            <ButtonOutlined
              w={"100%"}
              title={"Calculate"}
              buttonOutlinedHandle={() => {
                Calculate();
              }}
            />
          </div>
          <div className="w-[200px]">
            <ButtonFilled
              w={"100%"}
              title={"Apply"}
              buttonFilledHandle={() => {
                ApplyPrice();
              }}
            />
          </div>
        </div>

        <div className="">
          {allWeek && allWeek.length ? (
            <div className="h-full overflow-y-auto pt-20 w-full">
              <div className="flex-grow w-full flex justify-center gap-x-4">
                <div
                  className="shadow-2xl p-3 self-center cursor-pointer text-right"
                  onClick={getPreviousMonthAndYear}
                >
                  {"<"}
                </div>
                <div className="self-center text-center min-w-[180px]">{`${monthNames[month]}, ${year}`}</div>
                <div
                  className=" shadow-2xl p-3 self-center cursor-pointer"
                  onClick={getNextMonthAndYear}
                >
                  {">"}
                </div>
              </div>
              {!loading && (
                <table className="max-w-[1120px] mx-auto border-collapse columns-7 table">
                  <thead className="">
                    <td>S</td>
                    <td>M</td>
                    <td>T</td>
                    <td>W</td>
                    <td>T</td>
                    <td>F</td>
                    <td>S</td>
                  </thead>
                  <tbody>
                    {allWeek &&
                      allWeek.map((i, key) => (
                        <tr>
                          {i.map((data, index) => (
                            <div className="border w-[160px] px-1 py-1 h-[120px] table-cell">
                              <EachCalenderDateCard
                                data={data}
                                key={index}
                                selectedCategory={
                                  selectedCategory?.roomCategoryId
                                }
                              />
                            </div>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {loading && (
                <div className="w-full h-[300px] flex justify-center items-center">
                  <Loading />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
