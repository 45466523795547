import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
// import SingleSelect from "./SingleSelect";
import SingleSelectObjectReturn from "./SingleSelectObjectReturn";

import { customValidationSingle } from "../../../utils/customValidation";
import RootComponent from "../../rootComponent/RootComponent";

function SingleSelectNew({
  data,
  setSelectedObject,
  selectedValue,
  setSelectedValue,
  placeholder,
  displayField,
  selectedFieldName,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  warningView,
  // rejectionArray,
  setRejectionArray,
  name,
  required = false,
  image = false,
  temp,
  setTemp,
}) {
  const [visible, setVisible] = React.useState(false);

  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    //setTemp(selectedValue[selectedFieldName]);
    console.log(selectedValue, "temp", temp);
    //setSelectedValue({});
    //setTemp('');
  }, [temp]);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="w-full flex flex-col  rounded-lg ">
        <div id={name} className={`${required && "required"}`}>
          <div
            className="w-full rounded-[8px] h-[49px] flex outline-none "
            ref={ref}
            // onClick={() => setVisible(!visible)
            // }
            onClick={() => {
              setVisible(true);
            }}
          >
            <div className="cursor-auto text-[#9e9989] md:text-xs text-left validation h-full rounded-[8px] pl-3 flex items-center justify-center border-[#9e9989] border border-r-0 rounded-r-none">
              <span className="w-full whitespace-nowrap">
                {placeholder}
                {required ? "*" : null}:{" "}
              </span>
            </div>
            <input
              id={`input${name}`}
              onChange={(e) => {
                required && customValidationSingle(name);
                setTemp(e.target.value);
              }}
              className={`w-full text-left pl-4 h-full  bg-[transparent] validation focus:outline-none active:outline-none border-[#9e9989] rounded-l-none rounded-r-none border border-r-0 border-l-0 text-xs"
                }`}
              value={temp ? temp : ""}
              autoComplete="Off"
              //value={temp}
              onBlur={() => required && customValidationSingle(name)}
              onFocus={() => {
                if (selectedFieldName) {
                  //setTemp(selectedValue[selectedFieldName]);
                }
              }}
            />

            <button className="validation bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-r-[8px] rounded-l-none border border-l-0 border-[#314747] px-5 hover:bg-btn-secondary duration-500">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="errorMessage validationError"></div>
        </div>
        {visible && (
          <div
            className=" pt-4 rounded-2xl mt-16 bg-bg-primary custom-shadow z-50 absolute max-h-[200px] h-[200px] overflow-scroll"
            style={{ width: width }}
          >
            <div className="">
              {data && data.length
                ? data
                    .filter((each) =>
                      each?.title?.toLowerCase().includes(temp.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <SingleSelectObjectReturn
                          displayField={displayField}
                          key={index}
                          image={image}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                          item={item}
                          setVisible={setVisible}
                          selectedFieldName={selectedFieldName}
                          // setDisplay={setDisplay}
                          name={name}
                          validation={required}
                          setSelectedObject={setSelectedObject}
                          setTemp={setTemp}
                        />
                      );
                    })
                : null}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  );
  let readOnlyViewComponent = <p>{selectedValue}</p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        warningView={warningView}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        // rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default SingleSelectNew;
