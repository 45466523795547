import React from "react";

const AdminLanding = ({ setPosition, position, userType }) => {
  // const [position, setPosition] = useState("nothing");

  return (
    <div>
      <div className="flex flex-cols w-full">
        {/* <Sidebar /> */}
        <h1>Welcome to Admin Home Page</h1>
      </div>
    </div>
  );
};

export default AdminLanding;
