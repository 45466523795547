import React, { useState } from "react";
import TourNav from "../pages/admin/comp/TourNav";
import PrivateRoute from "../utils/privateRoute";
import TourSideNav from "./TourSideNav";
import TourCard from "./TourCard";
import TourSearch from "./TourSearch";
import { useLocation } from "react-router";
import TourStepper from "./TourStepper";

const ToursLayout = ({ Component }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-bg-white">
      <TourNav />

      <div className="my-[39px] max-w-[1440px] mx-auto">
        {location?.pathname === "/dashboard/tour" ? (
          <TourSearch></TourSearch>
        ) : location?.pathname === "/dashboard/TOUR" ? (
          <div></div>
        ) : (
          <TourStepper></TourStepper>
        )}
        {/* <ToursSideNavTwo></ToursSideNavTwo>
        <ToursSideNav></ToursSideNav> */}
        <div className="flex gap-x-[32px] px-[64px] py-[32px]">
          <div className="sticky top-[125px] h-screen">
            <TourSideNav></TourSideNav>
          </div>
          <div className="w-full bg-bg-white ">
            <div className="">
              <PrivateRoute Component={Component} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ToursLayout;
