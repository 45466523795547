import React from "react";
import { useNavigate } from "react-router";

export default function CustomButtonGroupExcruions({
  setSaveAndNext,
  step,
  onSubmit,
}) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center gap-[8px]">
      {step !== 0 && (
        <button
          onClick={() => setSaveAndNext()}
          className="bg-btn-secondary h-[39px] px-[10px] rounded-[4px] text-bg-white"
        >
          Save & Back
        </button>
      )}
      <button
        onClick={() => navigate("/dashboard/excursion")}
        className="bg-btn-secondary h-[39px] px-[10px] rounded-[4px] text-bg-white"
      >
        Cancel
      </button>
      <button
        onClick={() => {
          onSubmit();
        }}
        className="bg-btn-secondary h-[39px] px-[10px] rounded-[4px] text-bg-white"
      >
        Save & Next
      </button>
    </div>
  );
}
