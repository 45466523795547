import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  animatedComponents,
  colorStylesTour,
} from "../Utilities/SelectAnimation";

const MarchantTourSubscribeCard = ({
  tour,
  number_of_daysArray,
  tourSubscribe,
  setTourSubscribe,
}) => {
  const [activites, setActivites] = useState({
    activity_day_number: 0,
    activity_end_time: "",
    activity_id: 0,
    activity_start_time: "",
  });

  const handleActivites = (id) => {
    setTourSubscribe((prev) => ({
      ...prev,
      tour_activities: [
        ...prev.tour_activities,
        {
          activity_day_number: activites.activity_day_number,
          activity_end_time: activites.activity_end_time,
          activity_id: id,
          activity_start_time: activites.activity_start_time,
        },
      ],
    }));
    toast.success("Successfully Added!");

    // setActivites({
    //   activity_day_number: 0,
    //   activity_end_time: "",
    //   activity_id: 1,
    //   activity_start_time: "",
    // });
  };

  const iconObject = {
    timer: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_11251_6200)">
          <path
            d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM6.5 5.9395C6.50006 6.02426 6.47856 6.10764 6.43754 6.1818C6.39652 6.25597 6.33732 6.3185 6.2655 6.3635L4.3455 7.5635C4.28969 7.59837 4.22756 7.6219 4.16265 7.63275C4.09775 7.64361 4.03134 7.64157 3.96722 7.62676C3.9031 7.61195 3.84253 7.58465 3.78896 7.54643C3.73539 7.50821 3.68987 7.45981 3.655 7.404C3.62014 7.34819 3.59661 7.28606 3.58575 7.22115C3.5749 7.15624 3.57693 7.08984 3.59175 7.02572C3.60656 6.9616 3.63385 6.90102 3.67207 6.84745C3.71029 6.79388 3.75869 6.74837 3.8145 6.7135L5.5 5.6625V3.5C5.5 3.36739 5.55268 3.24022 5.64645 3.14645C5.74022 3.05268 5.86739 3 6 3C6.13261 3 6.25979 3.05268 6.35356 3.14645C6.44732 3.24022 6.5 3.36739 6.5 3.5V5.9395Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_11251_6200">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  return (
    <div className="bg-bg-white flex flex-row shadow-customTourPrimaryShadow rounded-[16px] h-[240px] xl:h-[220px] hover:shadow-customTourCard">
      <img
        className="h-full min-w-[196px] max-w-[196px] object-cover rounded-l-[16px]"
        src={tour?.tour_activity_images[0]?.activity_image_url}
        alt={tour?.tour_activity_images[0]?.activity_image_caption}
      />

      <div className="pt-[8px] pb-[16px] pl-[12px] pr-[16px] w-full flex flex-col  gap-[8px] relative">
        <p className="text-tour-text text-[16px] font-medium">
          {" "}
          {tour?.tour_activity_name}
        </p>
        <div>
          <div>
            <p className="text-tour-secondary text-[12px]">Day Number: </p>
          </div>
          <div className="rounded">
            <Select
              value={activites?.activity_day_number}
              onChange={(activity_day_number) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_day_number: activity_day_number,
                }))
              }
              components={animatedComponents}
              options={number_of_daysArray}
              placeholder={"Select Day Number: "}
              styles={colorStylesTour}
            />
          </div>
        </div>

        <div className="flex gap-x-[16px] mt-[4px]">
          <div className="w-full">
            <p className="text-[12px] text-tour-secondary">Start Time : </p>
            <div className="flex gap-[8px]">
              <input
                onChange={(e) =>
                  setActivites((prev) => ({
                    ...prev,
                    activity_start_time: e.target.value,
                  }))
                }
                type="time"
                id="session-time"
                name="appt"
                step="0"
                value={activites?.activity_start_time}
                required
                style={{
                  padding: "8px 9px",
                  border: "1px solid #B3C2C7",
                  borderRadius: "4px",
                  fontSize: "14px",
                  width: "100%",
                  height: "36px",
                  outline: "none",
                  backgroundColor: "#fff",
                  color: "#142B33",
                  fontFamily: "Arial, sans-serif",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                }}
              />
              <div className="bg-tour-primary flex justify-center items-center min-h-[36px] min-w-[36px] rounded-[8px]">
                {iconObject?.timer}
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-[12px] text-tour-secondary">End Time : </p>
            <div className="flex gap-[8px]">
              <input
                onChange={(e) =>
                  setActivites((prev) => ({
                    ...prev,
                    activity_end_time: e.target.value,
                  }))
                }
                type="time"
                id="session-time"
                name="appt"
                step="0"
                value={activites?.activity_end_time}
                required
                style={{
                  padding: "8px 9px",
                  border: "1px solid #B3C2C7",
                  borderRadius: "4px",
                  fontSize: "14px",
                  width: "100%",
                  height: "36px",
                  outline: "none",
                  backgroundColor: "#fff",
                  color: "#142B33",
                  fontFamily: "Arial, sans-serif",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                }}
              />
              <div className="bg-tour-primary flex justify-center items-center min-h-[36px] min-w-[36px] rounded-[8px]">
                {iconObject?.timer}
              </div>
            </div>
          </div>
        </div>

        <div className="w-[140px] ml-auto my-[8px] absolute bottom-[6px] right-[12px]">
          <button
            disabled={tourSubscribe.tour_activities
              .map((activity) => activity.activity_id)
              .includes(tour?.tour_activity_id)}
            onClick={() =>
              activites.activity_day_number &&
              activites.activity_end_time &&
              activites.activity_start_time &&
              handleActivites(tour?.tour_activity_id)
            }
            className={` ${
              activites.activity_day_number &&
              activites.activity_end_time &&
              activites.activity_start_time
                ? "bg-tour-primary hover:bg-tour-secondary hover:border-tour-secondary"
                : "bg-tour-input"
            }  py-[3px] disabled:bg-tour-input text-bg-white text-[16px] font-normal w-full px-[20px]  border-btn-primary rounded-[4px] shadow-md transition-all duration-500  hover:text-bg-white hover:shadow-lg`}
          >
            {tourSubscribe.tour_activities
              .map((activity) => activity.activity_id)
              .includes(tour?.tour_activity_id)
              ? "Added"
              : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarchantTourSubscribeCard;
