import React from "react";
import { CiEdit } from "react-icons/ci";
import moving from "../../assets/icon/moving.svg";
export default function TourGuideInitail({
  handlePackage,
  tour_package_guide_options,
}) {
  console.log("tour_package_guide_options", tour_package_guide_options);
  return (
    <>
      <div className="w-full flex xl:p-[16px] md:p-[8px] p-[4px]   bg-bg-white rounded  ">
        <div className="w-[8%]  flex items-center justify-center ">
          <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee]">
            <img
              className="xl:w-16 md:w-12 w-8 xl:h-16 md:h-12 h-[32px]"
              src={moving}
              alt=""
            />
          </span>
        </div>
        <div className=" w-[84%] xl:px-[12px] lg:px-[10px] md:px-[8px] px-[6px] flex flex-col items-start  xl:text-[14px] lg:text-[14px] md:text-[12px] text-[10px]">
          <div className="bg-btn-secondary  text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] mb-[8px] rounded">
            {"Guide"}
          </div>
          <div className="flex flex-wrap gap-y-[8px] w-full gap-[10px]">
            {tour_package_guide_options?.map((guide_options, index) => (
              <div className="">
                {guide_options?.tour_package_guide_packages?.map(
                  (guide, index) => (
                    <div
                      key={index}
                      className="shadow-customShadow p-[10px] rounded-[4px]"
                    >
                      <p>Guides Number: {guide?.number_of_guides_for_day} </p>
                      <p className="flex gap-x-[5px]">
                        <span key={index}>{guide?.day_number?.label}</span>
                      </p>
                      <p>Price : {guide?.tour_guide_price_per_day}</p>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-[8%] flex items-center text-[30px]">
          <div className="flex">
            <button
              onClick={() => handlePackage(4, "guide")}
              className="cursor-pointer text-[#22c55e]"
              title="Edit"
            >
              <CiEdit></CiEdit>
            </button>
          </div>
        </div>
      </div>
      <div className={`${"xl:h-[32px] md:h-[24px] h-[16px]"}`}></div>
    </>
  );
}
