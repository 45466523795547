import React from "react";
import ParentPackageAccomondationCard from "./ParentPackageAccomondationCard";
import ParentPackageFoodCard from "./ParentPackageFoodCard";
import ParentPackageTransferCard from "./ParentPackageTransferCard";
import ParentPackageTransportationCard from "./ParentPackageTransportationCard";

const ParentPackageSelectedAllData = ({
  tour_package_component_combinations,
  index,
}) => {
  console.log(
    "tour_package_component_combinations",
    tour_package_component_combinations?.tour_package_accommodation_option
  );
  return (
    <>
      <div className="bg-[#EBEDEF] mb-[40px] mt-[20px] rounded-[4px] px-[8px]">
        <div className="mb-[10px] pt-[20px]">
          <p className="mb-[20px] text-[14px]">
            <span className="bg-btn-primary px-[20px] py-[12px] text-bg-white rounded-[4px]">
              Option {index + 1}
            </span>
          </p>
        </div>
        <div className="mb-[40px] bg-[#EBEDEF] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5  flex-wrap gap-[20px]">
          {/* Accommodation */}
          {tour_package_component_combinations?.tour_package_accommodation_option !==
            null && (
            <div className="bg-bg-white h-[260px] relative rounded-[4px] overflow-hidden shadow-customShadow">
              <p className="text-center text-[14px] bg-btn-secondary py-[6px] text-bg-white">
                Accommodation
              </p>
              <div className="h-[200px] overflow-y-auto  grid  grid-cols-1 gap-[8px] p-[10px] border-b border-[#ddd]">
                {tour_package_component_combinations?.tour_package_accommodation_option?.tour_package_accommodation_packages?.map(
                  (accommondation, indx) => (
                    <ParentPackageAccomondationCard
                      accommondation={accommondation}
                      key={indx}
                      indx={indx}
                    ></ParentPackageAccomondationCard>
                  )
                )}
              </div>
              <p className=" text-btn-secondary py-[2px] text-[16px] text-center mx-[20px] rounded-t-[4px]">
                Total Price:{" "}
                {
                  tour_package_component_combinations
                    ?.tour_package_accommodation_option
                    ?.tour_package_option_price_per_person
                }{" "}
                ৳
              </p>
            </div>
          )}

          {/* Transportation */}
          {tour_package_component_combinations?.tour_package_transportation_package !==
            null && (
            <div className="bg-bg-white h-[260px]  relative rounded-[4px] overflow-hidden shadow-customShadow">
              <p className="text-center text-[14px] bg-btn-secondary py-[6px] text-bg-white">
                Transportation
              </p>
              <div className="h-[200px] border-[#ddd] border-b overflow-y-auto  flex flex-col gap-[8px] p-[10px]">
                {tour_package_component_combinations?.tour_package_transportation_package && (
                  <ParentPackageTransportationCard
                    transportation={
                      tour_package_component_combinations?.tour_package_transportation_package
                    }
                    // indx={indx}
                  ></ParentPackageTransportationCard>
                )}
              </div>
              <p className="text-btn-secondary py-[2px] text-[16px] text-center mx-[20px] rounded-t-[4px]">
                Total Price:{" "}
                {
                  tour_package_component_combinations
                    ?.tour_package_transportation_package
                    ?.transportation_package_unit_price
                }{" "}
                ৳
              </p>
            </div>
          )}

          {/* Transfer */}
          <div className="bg-bg-white h-[260px]  relative rounded-[4px] overflow-hidden shadow-customShadow">
            <p className="text-center text-[14px] bg-btn-secondary py-[6px] text-bg-white">
              Transfer
            </p>
            <div className="h-[200px] overflow-y-auto flex flex-col border-b border-[#ddd] gap-[8px] p-[10px]">
              {tour_package_component_combinations?.tour_package_transfer_option?.transfer_packages?.map(
                (transfer, indx) => (
                  <ParentPackageTransferCard
                    key={indx}
                    transfer={transfer}
                    indx={indx}
                  ></ParentPackageTransferCard>
                )
              )}
            </div>
            <p className="text-btn-secondary py-[2px] text-center mx-[20px] rounded-t-[4px] text-[16px]">
              Total Price:{" "}
              {
                tour_package_component_combinations
                  ?.tour_package_transfer_option
                  ?.tour_package_option_price_per_person
              }{" "}
              ৳
            </p>
          </div>

          {/* Food */}
          {tour_package_component_combinations?.tour_package_food_option !==
            null && (
            <div className="bg-bg-white h-[260px]  relative rounded-[4px] overflow-hidden shadow-customShadow">
              <p className="text-center text-[14px] bg-btn-secondary py-[6px] text-bg-white">
                Food
              </p>
              <div className="h-[200px] overflow-y-auto flex flex-col gap-[8px] p-[10px] border-b border-[#ddd]">
                {tour_package_component_combinations?.tour_package_food_option?.meal_packages?.map(
                  (food, indx) => (
                    <ParentPackageFoodCard
                      key={indx}
                      food={food}
                      indx={indx}
                    ></ParentPackageFoodCard>
                  )
                )}
              </div>
              <p className="text-btn-secondary py-[2px] text-[16px] text-center mx-[20px] rounded-t-[4px]">
                Total Price:{" "}
                {
                  tour_package_component_combinations?.tour_package_food_option
                    ?.tour_package_option_price_per_person
                }{" "}
                ৳
              </p>
            </div>
          )}

          {/* Total Price */}
          <div className="flex items-center justify-center pb-[20px]">
            <p className=" py-[12px] text-btn-primary text-center font-bold rounded-[4px]">
              <span className="text-btn-secondary">Total Price: </span>
              {
                tour_package_component_combinations?.tour_package_total_option_price_per_person
              }{" "}
              ৳
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentPackageSelectedAllData;
