import React, { useEffect, useState } from "react";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../components/common/ModalCommon";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import ReactDatePicker from "react-datepicker";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT } from "../../contants/Endpoints";
import { toast } from "react-toastify";
import Paginations from "../../pages/admin/Paginations/Paginations";
import Loading from "../../components/Loader/Loading";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { changeUrl } from "../utils/common";

const CreateTimeSlot = ({ setActiveStep }) => {
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));

  const getCreatePackageId = parseInt(
    localStorage.getItem("excursionCreatePackageId")
  );

  const [allData, setAllData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);

  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 5;

  const [groupData, setGroupData] = useState({
    slot_name: "",
    slot_time: "",
    excursion_package_id: getCreatePackageId,
    id: 0,
  });

  const payloadMethod = {
    excursionPackageId: groupData?.excursion_package_id,
    id: groupData?.id,
    requestId: "123",
    slotName: groupData?.slot_name,
    slotTime: groupData?.slot_time,
  };

  const onAddNewButton = () => {
    setGroupData({
      slot_name: "",
      slot_time: "",
      excursion_package_id: getCreatePackageId,
      id: 0,
    });
    setToggleModal(!toggleModal);
  };

  const onSubmitSlot = () => {
    setIsLoading(true);
    authAxios
      .post(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_UPDATE_PACKAGE_TIME_SLOT}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Successfully added!");
        setToggleModal(false);
        setSuccess(!success);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Added Failed");
      });
    // }
  };

  useEffect(() => {
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID}excursionPackageId=${getCreatePackageId}&pageNumber=${currentPage}&pageSize=${pageSize}&requestId=123`
      )
      .then((res) => {
        console.log({ res });
        setAllData(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setActiveStep(12);
        changeUrl(Number(url.searchParams.get("id")), 12);
      });
  }, [currentPage, pageSize, success, getCreatePackageId]);

  const onEditButton = (id) => {
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_SINGLE_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID}excursionPackageTimeSlotId=${id}&requestId=123`
      )
      .then((res) => {
        setGroupData({
          slot_name: res?.data?.slotName,
          slot_time: res?.data?.slotTime,
          excursion_package_id: res?.data?.excursionPackageId,
          id: res?.data?.id,
        });
        setToggleModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeletePackageById = (id) => {
    authAxios
      .delete(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_DELETE_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID}excursionPackageTimeSlotId=${id}&requestId=123`
      )
      .then((res) => {
        setSuccess(!success);
        toast.success("Successfully deleted!");
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const backAndSave = () => {
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
  };

  const onSubmitButton = () => {
    setActiveStep(12);
    changeUrl(Number(url.searchParams.get("id")), 12);
  };

  return (
    <div className="w-[343px] md:w-[763px] lg:w-[1100px]">
      {/* <div>CreateTimeSlot</div> */}
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]">Time Slot</span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Craft your own time slot, tailor-made to suit your schedule and
          preferences, ensuring a personalized experience.
        </p>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div>
          <div>
            <div className="my-[40px] flex justify-center">
              <div className="w-[50%]">
                <TourMerchantButton
                  name={"Add New Time Slot"}
                  width={"w-full"}
                  onClick={() => onAddNewButton()}
                ></TourMerchantButton>
              </div>
            </div>
          </div>

          <div>
            <div>
              {allData?.length === 0 && (
                <p className="text-center text-btn-secondary">
                  Please Add new time slot.
                </p>
              )}
              {allData?.length > 0 && (
                <div>
                  <table className="fl-table my-[20px]">
                    <thead className="sticky top-[88px]">
                      <tr>
                        <th>Sl No.</th>
                        <th>Package Id</th>
                        <th>Slot Name</th>
                        <th>ID</th>
                        <th>Slot Time</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allData?.length > 0 &&
                        allData?.map((property, index) => (
                          <tr key={index}>
                            <td>
                              {index + 1 + pageSize * currentPage - pageSize}.
                            </td>
                            <td className="cursor-pointer mx-auto">
                              {getCreatePackageId}
                            </td>
                            <td className="cursor-pointer mx-auto">
                              {property?.slotName}
                            </td>

                            <td>{property?.id}</td>
                            <td>{property?.slotTime}</td>
                            <td className="flex justify-center gap-x-[8px]">
                              <button
                                onClick={() => onEditButton(property?.id)}
                                className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  onDeletePackageById(property?.id)
                                }
                                className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="my-10">
                    <Paginations
                      currentPage={currentPage}
                      totalCount={totalPage}
                      pageSize={pageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[200px] flex justify-center items-center w-full">
            <CustomButtonGroupExcruions
              setSaveAndNext={backAndSave}
              onSubmit={onSubmitButton}
            />
          </div>
        </div>
      )}

      <ModalCommon
        shown={toggleModal}
        width="max-w-[400px]"
        height="min-h-[150px]"
        outSideOff={false}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="py-[16px] px-[32px]">
          <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
            Create Time Slot
          </h2>

          <div className="my-[16px]">
            <p className="text-btn-secondary">Slot Time: </p>
            <div className="rounded">
              {/* <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Like: 12:20 PM"
                type="time"
                value={groupData?.slot_time || ""}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    slot_time: e.target.value,
                  }))
                }
                
              /> */}
              <input
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    slot_time: e.target.value,
                  }))
                }
                type="time"
                id="session-time"
                name="appt"
                step="0"
                value={groupData?.slot_time}
                required
                style={{
                  padding: "4px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "16px",
                  width: "100%",
                  outline: "none",
                  backgroundColor: "#f5f5f5",
                  color: "#9e9989",
                  fontFamily: "Arial, sans-serif",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                }}
              />
              {showError &&
                (groupData?.slot_time?.length === 0 ||
                  groupData?.slot_time === null) && (
                  <p className="text-[14px]  text-[#f87171] mt-[2px]">
                    Slot Time is required.
                  </p>
                )}
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Slot Name: </p>
            </div>
            <div className="rounded">
              <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Slot Name: "
                type="text"
                value={groupData?.slot_name || ""}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    slot_name: e.target.value,
                  }))
                }
              />
              {showError &&
                (groupData?.slot_name?.length === 0 ||
                  groupData?.slot_name === null) && (
                  <p className="text-[14px]  text-[#f87171] mt-[2px]">
                    Slot Name is required.
                  </p>
                )}
            </div>
          </div>

          <div className="my-[20px]">
            <TourMerchantButton
              name={"Submit"}
              width={"w-full"}
              onClick={() => onSubmitSlot()}
            ></TourMerchantButton>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default CreateTimeSlot;
