import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { merchantTourRoute } from "../../contants/Constants";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { convertStringToObject } from "../AllPackages/ParentPackageSelected";
import TourMerchantButton from "../TourMerchantButton";
// import { convertStringToObject } from '../AllPackages/ParentPackageSelected';

const AbibilityPackage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [packageDetails, setPackageDetails] = useState();

  const getPackageTypeSummary = () => {
    authAxios
      .get(
        `${apiEndPoint.MARCHANT_GET_DETAILS_PACKAGE_SUMMARY}${convertData.packageId}?requestId=123`
      )
      .then((res) => {
        setPackageDetails(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackageTypeSummary();
  }, []);
  const handleBackButton = () => {
    navigate(-1);
  };

  const setPackage = [
    {
      optionId: "P101",
      package_component: [{ type: "Food" }, { type: "Accommodation" }],
      calculated_cost: 11380,
      rack_rate: 14000,
      corporate_rate: 12000,
      total_seat: 5,
    },
    {
      optionId: "P102",
      package_component: [
        { type: "Food" },
        { type: "Accommodation" },
        { type: "Transfer" },
      ],
      calculated_cost: 12380,
      rack_rate: 16000,
      corporate_rate: 14000,
      total_seat: 10,
    },
    {
      optionId: "P103",
      package_component: [
        { type: "Food" },
        { type: "Accommodation" },
        { type: "Transfer" },
      ],
      calculated_cost: 15680,
      rack_rate: 18000,
      corporate_rate: 15000,
      total_seat: 7,
    },
  ];

  return (
    <div>
      <p className="md:text-[20px] text-[18px] text-center text-btn-secondary my-[20px]">
        {"Couple Package"}
      </p>

      <div className="my-[20px]">
        <table className="fl-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Option_ID</span>
              </th>
              <th>Package Components</th>
              <th>Calculated Cost</th>
              <th>Rack Rate</th>
              <th>Corporate Rate</th>
              <th>Total Seat</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {setPackage?.map((item, i) => (
              <tr key={i}>
                <td>{item?.optionId}</td>
                <td>
                  {item?.package_component.map((type, typeId) => (
                    <span key={typeId}>{`${type.type}, `}</span>
                  ))}
                </td>
                <td>{item?.calculated_cost}</td>
                <td>{item?.rack_rate}</td>
                <td>{item?.corporate_rate}</td>
                <td>{item?.total_seat}</td>
                <td>
                  <div>
                    <TourMerchantButton
                      name={"Abibility Set Price"}
                      onClick={() =>
                        navigate(
                          `${merchantTourRoute.PRICE_TOOL}${item?.optionId}&tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}&packageId=${convertData.packageId}`
                        )
                      }
                    ></TourMerchantButton>
                    {/* <TourMerchantButton name={"Abibility Set Price"}
                    onClick={() => navigate(`${merchantTourRoute?.ABIBILITY_PACKAGE_ID}${item?._id}?packageId=${item?.package_name}&optionId=${item?.optionId}`)}
                    ></TourMerchantButton> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <div className="my-[10px] ">
          <button
            onClick={() => handleBackButton()}
            className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
          >
            <span className="text-start text-[20px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbibilityPackage;
