import React, { useEffect, useState } from "react";

import FileMultiple from "../../../components/multipleimage/FileMultiple";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import Select from "react-select";

import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../../../ToursMarchent/Utilities/SelectAnimation";

import RemoveableImageUploadViewCategory from "../../../components/RemoveableImageUploadViewCategory";
import CustomTextEditor from "../../CustomTextEditor";
import { authAxios } from "../../../utils/axiosWrapper";
import { baseUrl, BLOG_CONTROLLER_API } from "../../../contants/Endpoints";
import { toast } from "react-toastify";
import FileSingleAdd from "../../../components/multipleimage/FileSingleAdd";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router";

function generateImageData(imageUrl) {
  const fileName = imageUrl?.split("/")?.pop();
  const tags = [fileName];

  return [
    {
      fileName,
      tags,
      url: imageUrl,
    },
  ];
}

function transformImageData(data) {
  if (!Array?.isArray(data) || data?.length === 0) {
    return [];
  }

  return data.map((item) => {
    const fileName = item?.image_file_name;
    const tags = [fileName];

    return {
      fileName,
      tags,
      url: item?.image_url,
    };
  });
}

function transformTagData(tagData) {
  if (!Array.isArray(tagData) || tagData?.length === 0) {
    return { data: [] };
  }

  const transformedData = tagData?.map((item) => {
    return {
      label: item?.tag_name,
      value: item?.tag_id,
    };
  });

  return { data: transformedData };
}

export default function Blogs({ blogId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const passedState = location.state;
  const [allTags, setAllTags] = useState([]);
  const [title, setTitle] = useState(passedState?.title || "");
  const [userSelectTags, setUserSelectTags] = useState(
    transformTagData(passedState?.tags) || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [blogDetails, setBlogDetails] = useState(passedState?.content || "");
  const [summary, setSummary] = useState(passedState?.summary || "")
  const [needUpdate, setNeedUpdate] = useState(Math.random());
  const [urlTempStorageArray, setUrlTempStorageArray] = useState(
    transformImageData(passedState?.blog_images) || []
  );
  const [urlThumbImage, setUrlThumbImage] = useState(
    generateImageData(passedState?.thumb_image_url) || []
  );
  const [isShowError, setIsShowError] = useState(false);

  const tagsItems = transformArray(allTags, "tag_id", "tag_name");

  const handleClick = () => {
    navigate("/content/3bbblog/all/blog/ghuddy");
  };

  const payloadMethod = {
    blog_post_id: parseInt(blogId),
    blog_tags: userSelectTags?.data?.map((item) => item?.value),
    content: blogDetails,
    images: urlTempStorageArray?.map((item) => ({
      file_name: item?.fileName,
      image_caption: item?.tags[0],
      image_url: item?.url,
    })),
    summary: summary,
    requestId: "123",
    thumb_image_url: urlThumbImage[0]?.url,
    title: title,
  };

  const handlePostBlog = () => {
    authAxios
      .post(`${baseUrl}${BLOG_CONTROLLER_API.USER_CREATE_BLOG}`, payloadMethod)
      .then((res) => {
        setIsShowError(false);
        toast.success("Blog Created successfully!");
        setTitle("");
        setUserSelectTags([]);
        setBlogDetails("");
        setUrlThumbImage([]);
        setUrlTempStorageArray([]);
        navigate('/content/3bbblog/all/blog/ghuddy')
      })
      .catch((err) => {
        console.log({ err });
        toast.error("Blog Add Failed!");
        setIsShowError(true);
      });
  };

  const onHandleUpdatePostBlog = () => {
    authAxios
      .put(`${baseUrl}${BLOG_CONTROLLER_API.USER_UPDATE_BLOG}`, payloadMethod)
      .then((res) => {
        setIsShowError(false);
        toast.success("Blog Update successfully!");
        setTitle("");
        setUserSelectTags([]);
        setBlogDetails("");
        setUrlThumbImage([]);
        setUrlTempStorageArray([]);
        navigate('/content/3bbblog/all/blog/ghuddy')
      })
      .catch((err) => {
        console.log({ err });
        toast.error("Blog Update Failed!");
        setIsShowError(true);
      });
  }

  const getAllBlogs = () => {
    setIsLoading(true)
    authAxios
      ?.get(`${BLOG_CONTROLLER_API.USER_BLOG_TAG_GET_ALL}?requestId=123`)
      .then((res) => {
        setAllTags(res?.data?.blog_tags);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllBlogs()
  }, []);

  const checkStatus = () => {
    return (
      userSelectTags?.data?.length === 0 ||
      userSelectTags === undefined ||
      title?.length === 0 ||
      blogDetails?.length === 0 ||
      urlThumbImage?.length === 0 ||
      urlTempStorageArray?.length === 0 || 
      summary?.length === 0 || 
      summary?.length > 250
    );
  };

  return (
    <div className="max-w-[1180px] mx-auto">
      <h2 className="text-[22px] text-center  mt-[10px] text-btn-primary">
        Create Your Awesome Blog
      </h2>
      <div className="w-full flex justify-end my-[12px]">
        <button
          onClick={()=> handleClick()}
          className="w-[200px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[4px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
        >
          Back to all blog
        </button>
      </div>

      <div className="w-full mx-auto flex justify-start gap-x-[20px]  mt-[30px]">
        <div className="flex flex-col gap-y-[20px]  w-full mx-auto bg-bg-white p-[16px] shadow-customShadow rounded-[6px]">
          <div>
            <input
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              type="text"
              placeholder="Blog Title"
              name="title"
              className="h-[39px] w-full py-[2px] px-[12px]  border-b rounded-none border-btn-secondary hover:border-btn-primary"
            />
            {isShowError && title?.length === 0 && (
              <p className="text-[#ef4444] text-[14px] my-[2px]">
                This field is required.
              </p>
            )}
          </div>

          <div className="rounded">
            <Select
              value={userSelectTags?.data || ""}
              onChange={(data) =>
                setUserSelectTags((prev) => ({
                  ...prev,
                  data,
                }))
              }
              isClearable
              isMulti
              components={animatedComponents}
              options={tagsItems}
              placeholder={"Select Tags: "}
              styles={colorStyles}
            />
            {isShowError && userSelectTags?.length === 0 && (
              <p className="text-[#ef4444] text-[14px] my-[2px]">
                This field is required.
              </p>
            )}
          </div>

          <div>
            <CustomTextEditor
              details={blogDetails}
              setDetails={setBlogDetails}
              placeholderText={"Blog Description"}
            ></CustomTextEditor>
            {isShowError && blogDetails?.length === 0 && (
              <p className="text-[#ef4444] text-[14px] my-[2px]">
                This field is required.
              </p>
            )}
          </div>

          <div className="">
                <p className="text-btn-secondary text-[14px]">
                  Summary:{" "}
                </p>
                <div className="rounded">
                  <textarea
                    className="w-full p-[8px] min-h-[100px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                    placeholder="Enter summary : "
                    type="text"
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setSummary(inputVal);
                    }}
                    value={summary}
                  />
                </div>
                { summary?.length > 250 && (
                  <p className="text-[13px] text-[#f87171]">
                    {"Please make sure charter less then 250"}
                  </p>
                )}
              </div>

          <p>Add Thumb Image</p>
          <div className="flex justify-between gap-x-[10px] mt-[10px] max-w-[400px] mx-auto">
            <div className="mb-10  w-[200px]">
              <FileSingleAdd
                width={"w-full"}
                id={1}
                setNeedUpdate={() => {}}
                urlTempStorageArray={urlThumbImage}
                setUrlTempStorageArray={setUrlThumbImage}
                multiple={false}
                setIsLoading={setIsLoading}
              />

              {isShowError && urlThumbImage?.length === 0 && (
                <p className="text-[#ef4444] text-[14px] my-[8px]">
                  Please add Thumb Image.
                </p>
              )}
            </div>
            {isLoading ? (
              <div
                className={`flex items-center justify-center  w-2/5 ${
                  urlThumbImage?.length ? "h-[245px]" : ""
                }`}
              >
                <p>Loading...</p>
              </div>
            ) : (
              <div className="w-[200px] ">
                {
                  // urlThumbImage[0]?.url.slice(urlThumbImage[0]?.url.length - 3, urlThumbImage[0]?.url?.length) === "pdf"?
                  // //  <iframe src={urlThumbImage[0]?.url} title="ghuddy" />
                  //  <p className="text-[#ef4444]">Please add jpg or png</p>
                  //  :
                  <img
                    className={`rounded-lg w-full ${
                      urlThumbImage?.length ? "h-[170px]" : ""
                    }`}
                    src={urlThumbImage[0]?.url || ""}
                    alt=""
                  />
                }

                <div className="text-txt-primary text-center mt-[10px] text-[12px]">
                  Image File:{" "}
                  {urlThumbImage?.length > 0
                    ? urlThumbImage[urlThumbImage?.length - 1]?.fileName
                    : `No image`}
                </div>
              </div>
            )}
          </div>
          <p>Add Others Image</p>
          <div className="flex justify-between">
            <div className="text-[#1b1b1b] w-full">
              <FileMultipleCategory
                setNeedUpdate={setNeedUpdate}
                urlTempStorageArray={urlTempStorageArray}
                setUrlTempStorageArray={setUrlTempStorageArray}
              />
            </div>

            <div className=" w-full">
              <div className="w-full flex gap-y-[8px] flex-col">
                {urlTempStorageArray &&
                  urlTempStorageArray?.length > 0 &&
                  urlTempStorageArray?.map((image, index) => {
                    return (
                      <RemoveableImageUploadViewCategory
                        key={index}
                        url={image?.url}
                        userType={"USER"}
                        // id={index}
                        setUrlTempStorageArray={setUrlTempStorageArray}
                        urlTempStorageArray={urlTempStorageArray}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div>
            {passedState?.blog_post_id ? <button
            onClick={() => onHandleUpdatePostBlog()}
            disabled={checkStatus()}
            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] mb-[8px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[4px]
            hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-btn-border disabled:text-bg-white"
          >
            Update
          </button>: <button
            onClick={() => handlePostBlog()}
            disabled={checkStatus()}
            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] mb-[8px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[4px]
            hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-btn-border disabled:text-bg-white"
          >
            Submit
          </button>}
          </div>
        </div>

        {/* <div className="w-full shadow-customShadow py-[12px] px-[20px]">
          <h2 className="text-[22px]">
            <span className="text-[16px] text-btn-secondary/70">Title: </span>
            {title}
          </h2>
          <div className="flex flex-col gap-y-[4px]">
            <span className="text-[16px] text-btn-secondary/70">
              All Tags:{" "}
            </span>
            {userSelectTags?.data?.map((tag, i) => (
              <div
                key={i}
                className="flex justify-between gap-y-[4px] border  w-full bg-btn-secondary px-[12px] py-[3px] text-bg-white  rounded-[4px]"
              >
                <p>{tag?.label}</p>
              </div>
            ))}
          </div>

          <div className="my-[4px]">
            <span className="text-[16px] text-btn-secondary/70">Content: </span>
            <div dangerouslySetInnerHTML={{ __html: blogDetails }} />
          </div>
        </div> */}
        <div className="w-full ">
          <div className="w-full bg-bg-white flex flex-col gap-y-[24px]">
            <div className="">
              <div className="border-[1px] border-tour-secondaryHint rounded-[12px]">
                <p className="px-[12px] py-[12px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {"Title:  "}
                  <span className="text-tour-secondary">{title}</span>
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Tags: "}</span>
                      <span className="text-tour-secondary">
                        {userSelectTags?.data?.map((item, ind) => (
                          <span key={ind}>
                            {item?.label}
                            {", "}
                          </span>
                        ))}
                      </span>
                    </p>
                    <p className="text-tour-text  mt-[12px]">
                      <span className="mb-[4px]">Content:</span>
                      <ReactQuill value={blogDetails} readOnly={true} />
                    </p>

                    <p className="px-[12px] py-[12px] text-tour-text font-normal  rounded-[8px] mt-[8px]">
                  <span className="">{"Summary :  "}</span>
                  <span className="text-tour-secondary">{summary}</span>
                </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}