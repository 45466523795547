import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  colorStylesTour,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";

export default function Food({ setIsLoading, editAblePackage, changeSelectedNewItemList }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [foodItemList, setFoodItemList] = useState([]);
  const [foodMealTypeList, setFoodMealTypeList] = useState([]);
  const [foodPackageList, setFoodPackageList] = useState([]);
  const [foodPackage, setFoodPackage] = useState({
    meal_type_id: {},
    food_items_ids: [],
    meal_package_unit_price: "",
  });

  const convertObject = (originalObject) => {
    const { meal_type_id, meal_type_name, food_items, per_meal_package_price } =
      originalObject;

    const mealTypeObject = {
      label: meal_type_name,
      value: meal_type_id,
    };

    const foodItemsArray = food_items?.map((foodItem) => ({
      label: foodItem?.food_item_name,
      value: foodItem?.food_item_id,
    }));

    const convertedObject = {
      meal_type_id: mealTypeObject,
      food_items_ids: foodItemsArray,
      meal_package_unit_price: per_meal_package_price,
    };

    return convertedObject;
  };

  const getfoodItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_FOOD_ITEM_LIST_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.food_items);
        setFoodItemList(res?.data?.food_items);
      })
      .catch((err) => console.log(err));
  };

  const getMealItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_TYPE_LIST_GET_API}`
      )
      .then((res) => {
        setFoodMealTypeList(res?.data?.meal_types);
      })
      .catch((err) => console.log(err));
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${convertData.subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getfoodItemList();
    getMealItemList();
    getMealPackage();
  }, []);

  const foodItems = transformArray(
    foodItemList,
    "food_item_id",
    "food_item_name"
  );

  const foodMealType = transformArray(
    foodMealTypeList,
    "meal_type_id",
    "meal_type_name"
  );

  function transformPayload(payload) {
    const newPayload = {
      meal_type_id: payload.meal_type_id.value,
      food_items_ids: payload.food_items_ids.map((item) => item.value),
      meal_package_unit_price: parseInt(payload.meal_package_unit_price),
    };

    return newPayload;
  }

  const handleSubmitFood = () => {
    const newPayload = transformPayload(foodPackage);
    setIsLoading(true);
    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_FOOD_PACKAGE}`, {
        requestId: "food-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_meal_package: newPayload,
      })
      .then((res) => {
        if (res?.status === 201) {
          toast.success("Food Add");
          getMealPackage();
          setFoodPackage({
            meal_type_id: "",
            food_items_ids: [],
            meal_package_unit_price: "",
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleEdit = (food) => {
    const editFood = convertObject(food);
    setFoodPackage(editFood);
  };

  useEffect(() => {
    if (editAblePackage?.hasOwnProperty("meal_package_id") && editAblePackage?.hasOwnProperty("meal_type_id") && editAblePackage?.hasOwnProperty("meal_type_name") && editAblePackage?.hasOwnProperty("food_items") && editAblePackage?.hasOwnProperty("per_meal_package_price")) {
      const editFood = convertObject(editAblePackage);
      setFoodPackage(editFood);
    }
  }, [editAblePackage]);

  

  // const changeSelectedNewItemList = (name, toggle) => {
  //   setSelectedNewListItem({ name: name, toggle: toggle });
  //   setItemListNew((prevList) => {
  //     return prevList?.map((item) => {
  //       return { ...item, toggle: item?.label === name ? toggle : false };
  //     });
  //   });
  // };

  return (
    <div className="py-[16px] pl-[16px]">
      {/* <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
      </div> */}
      {/* <h2 className="text-[18px] text-center">Food Add</h2> */}
      <div className="flex flex-col justify-start items-start gap-y-[24px]">
        <div className="w-full flex flex-col gap-[8px]">
          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Meal Type :{" "}
              </p>
            </div>
            <div className="rounded-[50px]">
              <Select
                // defaultValue={foodItemOption || ""}
                value={foodPackage?.meal_type_id || ""}
                onChange={(data) =>
                  setFoodPackage((prev) => ({
                    ...prev,
                    meal_type_id: data,
                  }))
                }
                isClearable
                components={animatedComponents}
                options={foodMealType}
                placeholder={"Select Options: "}
                styles={colorStylesTour}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Food Items:{" "}
              </p>
            </div>
            <div className="rounded">
              <Select
                value={foodPackage?.food_items_ids || ""}
                onChange={(data) => {
                  setFoodPackage((prev) => ({
                    ...prev,
                    food_items_ids: data,
                  }));
                }}
                components={animatedComponents}
                options={foodItems}
                placeholder={"Select Food: "}
                styles={colorStylesTour}
                isMulti
              />
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Meal Unit per Price:{" "}
              </p>
            </div>

            <div className="relative">
              <input
                className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                placeholder="Meal Price:"
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;

                  if (!isNaN(inputVal)) {
                    setFoodPackage((prev) => ({
                      ...prev,
                      meal_package_unit_price: inputVal,
                    }));
                  }
                }}
                onWheel={numberInputOnWheelPreventChange}
                value={foodPackage?.meal_package_unit_price}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
                BDT
              </span>
            </div>
          </div>

          {/* <button
            disabled={
              foodPackage?.food_items_ids?.length < 1 &&
              foodPackage?.meal_package_unit_price !== Object &&
              foodPackage?.meal_type_id !== Object
            }
            onClick={() => handleSubmitFood()}
            className="h-[36px] w-full flex justify-center items-center px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] rounded-[50px] hover:bg-bg-white "
          >
            Add
          </button> */}
        </div>
        {/* view */}
        {/* <div className="w-full flex flex-col gap-y-[10px]">
          {foodPackageList?.map((food, i) => (
            <div
              onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div>
          ))}

          {foodPackageList?.length > 0 && (
            <button
              onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )}
        </div> */}
        <div className="w-full flex gap-[16px]">
      <button
        disabled={
          foodPackageList?.length === 0
        }
        onClick={() => changeSelectedNewItemList("Accommodation", true)}
        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
      >
        Save & Next
      </button>
      <button
        disabled={
          (foodPackage?.meal_type_id?.label?.length === 0 || foodPackage?.meal_type_id?.label?.length === undefined ) ||
          foodPackage?.food_items_ids?.length === 0 ||
          foodPackage?.meal_package_unit_price?.length === 0
        }
        onClick={() => handleSubmitFood()}
        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
      >
        Add more
      </button>
      </div>
      </div>

      
    </div>
  );
}
