import React, { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";
import ReactModal from "react-modal";
import { useParams } from "react-router";
import ButtonFilled from "../../components/ButtonFilled";
import OptionDeltaPrice from "./OptionDeltaPrice";
import OptionPrice from "./OptionPrice";

const OptionTable = () => {
  const [priceModal, setPriceModal] = useState(false);
  const [filterPriceModal, setFilterPriceModal] = useState(false);

  const { id } = useParams();
  console.log(id);
  return (
    <div>
      <div className="flex ">
        <ButtonFilled
          title="Set Price"
          buttonFilledHandle={() => {
            setPriceModal(true);
          }}
          w="400px"
          h="49px"
        />

        <ButtonFilled
          title="Set Filter Price"
          buttonFilledHandle={() => {
            setFilterPriceModal(true);
          }}
          w="400px"
          h="49px"
        />
      </div>
      <ReactModal
        isOpen={priceModal}
        onRequestClose={() => setPriceModal(false)}
      >
        <div className=" flex items-center justify-center bg-bg-primary p-10 relative w-[800px]">
          <div
            onClick={() => {
              setPriceModal(false);
            }}
            className="cursor-pointer absolute top-0 right-0"
          >
            <CiCircleRemove></CiCircleRemove>
          </div>
          <OptionPrice />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={filterPriceModal}
        onRequestClose={() => setFilterPriceModal(false)}
      >
        <div className=" flex items-center justify-center bg-bg-primary p-10 relative w-[800px]">
          <div
            onClick={() => {
              setFilterPriceModal(false);
            }}
            className="cursor-pointer absolute top-0 right-0"
          >
            <CiCircleRemove></CiCircleRemove>
          </div>
          <OptionDeltaPrice />
        </div>
      </ReactModal>
    </div>
  );
};

export default OptionTable;
