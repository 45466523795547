import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import SingleSelectNew from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import CommonSearch from "../CommonSearch";
import Paginations from "../Paginations/Paginations";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";

const ExcursionSpeciality = () => {
  const [modalShown, setToggleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [iconsList, setIconsList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [temp, setTemp] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  //fetch icon
  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  const [tableData, setTableData] = useState([]);
  const [editCondition, setEditCondition] = useState(true);
  const [inputData, setInputData] = useState({
    icon: "",
    id: 0,
    label: "",
    value: "",
    description: "",
    facilityGroupId: 0,
  });

  const iconUrl = selectedIcon?.url || inputData?.iconUrl;

  const payloadMethod = {
    description: inputData?.description,
    facilityGroupId: selectedGroup?.id || inputData.facilityGroupId,
    iconUrl: iconUrl,
    id: editCondition === true ? 0 : inputData?.id,
    label: inputData?.label,
    requestId: "1234",
    value: inputData?.value,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getActivityList = () => {
    authAxios
      .get(
        `${baseUrl}${
          apiEndPoint.ADMIN_GET_FACILITIES
        }?pageNumber=${currentPage}&pageSize=${10}&queryString=${searchValue}&requestId=${123}`
      )
      .then((response) => {
        setTotalCount(response?.data?.totalPages);
        console.log("RESS", response?.data);
        setActivityList(response?.data?.entries);
      });
  };
  const handleSubmit = () => {
    console.log(payloadMethod);
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_FACILITIES}`, payloadMethod)
      .then((res) => {
        console.log("res", res);
        toast.success("Added successfully!");
        setToggleModal(!modalShown);
        setShowModal(false);
        getActivityList();
        setSelectedIcon(null);
        setSelectedGroup(null);
        setTemp("");
        // getFetchData();
        setInputData({});
        console.log(inputData);
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.warn("Add failed!");
      });

    setTableData([...tableData, inputData]);

    setInputData({});
  };

  console.log("activityList: ", activityList);

  const removeActivityApi = (id) => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_DELETE_FACILITIES}?id=${id}&requestId=123`
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Successfully deleted activity");
          getActivityList();
          inputData({});
        }
      });
  };

  useEffect(() => {
    getActivityList();
    // getFetchData();
  }, []);

  useEffect(() => {
    getActivityList();
    // getFetchData();
  }, [currentPage]);

  // const getFetchData = async () => {
  //   const res = await authAxios.get(
  //     `${baseUrl}${apiEndPoint.ADMIN_GET_DISPLAY_SPECIALITY}?pageNumber=${currentPage}&pageSize=10&requestId=1234&queryString=${searchValue}`
  //   );
  //   setTableData(res?.data);
  // };

  // useEffect(() => {
  //   authAxios
  //     .get(
  //       `${baseUrl}${
  //         apiEndPoint.ADMIN_GET_FACILITIES_GROUP
  //       }?pageNumber=${currentPage}&pageSize=${10}&queryString=${searchValue}&requestId=${123}`
  //     )
  //     .then((res) => setGroupList(res?.data?.entries));
  // }, [currentPage]);

  const getFaciliteisById = (id) => {
    console.log(id);
    authAxios
      .get(
        `${baseUrl}${
          apiEndPoint.ADMIN_GET_FACILITY_BYID
        }?id=${id}&requestId=${123}`
      )
      .then((response) => {
        const tempData = response.data;
        console.log(tempData);
        setSelectedIcon({ url: tempData.iconUrl });
        setEditCondition(false);
        setInputData({
          id: tempData.id,
          label: tempData.label,
          value: tempData.value,
          icon: tempData.iconUrl,
          facilityGroupId: tempData.excursionFacilityGroupId,
          description: tempData.description,
        });
      });
  };

  useEffect(() => {
    getActivityList();
  }, []);
  if (true) {
    return (
      <div className=" flex h-[100vh] w-full justify-center items-center">
        <h2 className=" text-btn-orange">This component is developing.</h2>
      </div>
    );
  } else {
    return (
      <div className="pt-[40px] px-[20px]">
        <div>
          <button
            className="flex justify-center items-center px-[8px] py-[4px] bg-btn-secondary border-2 text-bg-white text-lg  rounded-md"
            onClick={() => {
              setShowModal(true);
              setEditCondition(true);
            }}
          >
            Add Specialties
          </button>
        </div>
        <div>
          <table className="fl-table my-[20px]">
            <thead className="sticky top-[88px] z-50">
              <tr>
                <th>SI No.</th>
                <th>Icons</th>
                <th>Labels</th>
                {/* <th>Ratings</th> */}
                <th>Values</th>
                <th>Descriptions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {activityList?.map((activity, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * 10 + index + 1}</td>

                  <td className="mx-auto">
                    <img
                      src={activity?.iconUrl}
                      height={30}
                      width={30}
                      alt=""
                    />
                  </td>
                  <td>{activity?.label.slice(0, 10)}</td>
                  <td> {activity?.value.slice(0, 10)}...</td>
                  <td> {activity?.description?.slice(0, 15)}...</td>
                  {/* <td>4.5</td> */}

                  <td>
                    <button
                      className="bg-btn-primary text-bg-white px-[10px] py-[8px] my-[16px] mx-[4px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                      onClick={() => {
                        getFaciliteisById(activity?.id);
                        setEditCondition(false);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-btn-primary text-bg-white px-[10px] py-[8px] my-[16px] mx-[4px] hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                      onClick={() => {
                        getFaciliteisById(activity?.id);
                        setEditCondition(false);
                        setShowModal(true);
                      }}
                    >
                      View
                    </button>
                    <button
                      className=" bg-[#b1333b] text-bg-white  px-[10px] py-[8px] my-[16px] mx-[4px] hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                      onClick={() => removeActivityApi(activity.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className=" my-10">
            <Paginations
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={10}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>

        {showModal && (
          <div className=" fixed w-full h-full top-0 left-0 z-[70] bg-bg-semiblack ">
            <div className=" absolute left-[40%] top-[120px] bg-bg-primaryShadow shadow-customShadow rounded-[8px] p-8">
              <div
                className=" absolute top-4 right-8 cursor-pointer hover:bg-bg-semiwhite"
                onClick={() => {
                  setShowModal(false);
                  setSelectedIcon(null);

                  setInputData({});
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18.0037 5.99982C17.8162 5.81235 17.5619 5.70703 17.2967 5.70703C17.0316 5.70703 16.7773 5.81235 16.5897 5.99982L12.0037 10.5858L7.41772 5.99982C7.2302 5.81235 6.97589 5.70703 6.71072 5.70703C6.44556 5.70703 6.19125 5.81235 6.00372 5.99982C5.81625 6.18735 5.71094 6.44165 5.71094 6.70682C5.71094 6.97198 5.81625 7.22629 6.00372 7.41382L10.5897 11.9998L6.00372 16.5858C5.81625 16.7733 5.71094 17.0277 5.71094 17.2928C5.71094 17.558 5.81625 17.8123 6.00372 17.9998C6.19125 18.1873 6.44556 18.2926 6.71072 18.2926C6.97589 18.2926 7.2302 18.1873 7.41772 17.9998L12.0037 13.4138L16.5897 17.9998C16.7773 18.1873 17.0316 18.2926 17.2967 18.2926C17.5619 18.2926 17.8162 18.1873 18.0037 17.9998C18.1912 17.8123 18.2965 17.558 18.2965 17.2928C18.2965 17.0277 18.1912 16.7733 18.0037 16.5858L13.4177 11.9998L18.0037 7.41382C18.1912 7.22629 18.2965 6.97198 18.2965 6.70682C18.2965 6.44165 18.1912 6.18735 18.0037 5.99982Z"
                    fill="#374957"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-y-[10px]">
                <div className=" pt-[10px]">
                  <p className="pb-[5px]">Select display Group icons:</p>

                  <div className="flex justify-start items-center gap-x-[8px]">
                    <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                      <img
                        className="w-[40px] h-[40px]"
                        src={selectedIcon?.url || inputData?.iconUrl}
                        alt=""
                      />
                    </div>

                    <SingleSelectNew
                      warningView={false}
                      data={iconsList}
                      displayField="title"
                      placeholder={"Icons"}
                      selectedFieldName={"title"}
                      selectedValue={selectedIcon}
                      setSelectedValue={setSelectedIcon}
                      setSelectedObject={() => {}}
                      image={true}
                      isReadOnly={false}
                      temp={temp}
                      setTemp={setTemp}
                    />
                  </div>
                </div>

                <div>
                  <label className="custom-field one w-full">
                    <input
                      onChange={(event) => handleChange(event)}
                      className="input w-full"
                      type="text"
                      name="label"
                      placeholder=" "
                      value={inputData?.label || ""}
                      required
                    />
                    <span className="placeholder">Label:</span>
                  </label>
                </div>

                <div className=" pt-[10px] w-full">
                  <p className="pb-[5px] block mb-2 text-sm font-medium text-txt-hint dark:text-white">
                    Display Group Name
                  </p>

                  <SingleSelectParentReturnObject
                    warningView={false}
                    data={groupList}
                    displayField="label"
                    placeholder={"display group name"}
                    selectedFieldName={"label"}
                    selectedValue={selectedGroup}
                    setSelectedValue={setSelectedGroup}
                    setSelectedObject={() => {}}
                    image={false}
                  />
                </div>

                <div>
                  <label className="custom-field one w-full">
                    <input
                      onChange={(event) => handleChange(event)}
                      className="input w-full"
                      type="text"
                      name="value"
                      placeholder=" "
                      value={inputData?.value || ""}
                      required
                    />
                    <span className="placeholder">Value:</span>
                  </label>
                </div>

                <div>
                  <label className="custom-field one w-full">
                    <textarea
                      type="text"
                      name="description"
                      onChange={(event) => handleChange(event)}
                      className="input w-full min-h-[150px] max-h-[200px]"
                      placeholder=" "
                      value={inputData?.description || ""}
                    />
                    <span className="placeholder">Activity Description :</span>
                  </label>
                </div>

                {editCondition ? (
                  <button
                    onClick={() => handleSubmit()}
                    className="px-[12px] py-[6px] mt-[10px] cursor-pointer text-bg-primary rounded-[4px] bg-btn-secondary flex justify-center w-full"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleSubmit();
                      setEditCondition(true);
                    }}
                    className="px-[12px] py-[6px] mt-[10px] cursor-pointer text-bg-primary rounded-[4px] bg-btn-secondary flex justify-center w-full"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default ExcursionSpeciality;
