import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import SingleSelectPhoneTwo from "../../components/dropdown/SearchPhoneTwo";
import InputComponent from "../../components/InputComponent";
import Loading from "../../components/Loader/Loading";
import SingleSelect from "../../components/SingleSelect";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import CustomValidation from "../../utils/customValidation";
import { toastMessage } from "../../utils/toast";

export default function AdminCreateUser() {
  const data_Object = {
    email: "",
    firebaseToken: "string",
    firstName: "",
    lastName: "",
    password: "",
    phoneCode: "",
    phoneNumber: "",
    requestId: "",
    title: "Mr",
    tncVersion: 0,
    userType: "MERCHANT",
  };

  const [phoneCode, setPhoneCode] = useState("+880");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("Mr");
  const [loading, setLoading] = useState(false);
  const [termsAndConditionsData, setTermsAndConditionsData] = useState();
  const [tncVersion, setTncVersion] = useState();

  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };

    noAuthAxios
      .get(`${apiEndPoint.FETCH_TERMS_AND_CONDITIONS}?requestId=wrwr`, {
        headers: headers,
      })
      .then((response) => {
        setTermsAndConditionsData(response.data.termsAndConditionData);
        setTncVersion(response.data.termsAndConditionData.tncVersion);
      })
      .catch((error) => {
        console.log(error);
      });
    noAuthAxios
      .get(`${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=asdf`)
      .then((response) => {
        setCountryPhoneCodeArr(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toggleShowPass = (elementId) => {
    const input = document.getElementById(elementId);
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
    } else {
      input.setAttribute("type", "password");
    }
  };

  const CreateUser = () => {
    if (CustomValidation()) {
      setLoading(true);
      const tmp = data_Object;
      tmp.firstName = firstName;
      tmp.lastName = lastName;
      tmp.email = email;
      tmp.password = password;
      tmp.phoneCode = phoneCode;
      tmp.tncVersion = tncVersion;
      tmp.title = title;
      tmp.phoneNumber =
        phone.length > 0 && phone[0] === "0" ? phone.substring(1) : phone;

      authAxios
        .post(`${apiEndPoint.ADMIN_CREATE_USER}`, tmp)
        .then((res) => {
          toastMessage("Creation Successful");

          setFirstName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setRePassword("");
          setPhone("");
          setTitle("Mr");
          setPhoneCode("+880");
          setLoading(false);
        })
        .catch((error) => {
          toastMessage(error.response.data.message, "error");
          setLoading(false);
        });
    }
  };

  return (
    <div className="container mx-auto flex justify-center items-center min-h-[700px]">
      {loading && (
        <div className="fixed h-screen w-screen bg-[#fffbef91] z-50 bottom-0 left-0 flex justify-center items-center">
          <Loading />
        </div>
      )}

      <div>
        <div className="text-3xl font-semibold text-center">
          Create New User
        </div>
        <div className="w-full lg:w-[900px] p-4 mt-12">
          <div
            className="flex gap-1 md:gap-5 min-w-full items-center h-[50px]"
            ref={ref}
          >
            <div className="w-[103px] h-[50px]">
              <SingleSelect
                title={title}
                setTitle={setTitle}
                width={width + 1}
              />
            </div>
            <div className="w-full h-full">
              <div className="h-full">
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={firstName}
                  setInputValue={setFirstName}
                  placeholder={"First Name"}
                  name="firstName"
                />
              </div>
            </div>
          </div>

          <div className=" h-[50px] my-6 rounded-[8px]">
            <InputComponent
              warningView={false}
              required={true}
              inputValue={lastName}
              setInputValue={setLastName}
              placeholder={"Last Name"}
              name="lastName"
            />
          </div>
          <div className="my-6">
            <SingleSelectPhoneTwo
              required={true}
              title={phoneCode}
              setTitle={setPhoneCode}
              setInputValue={setPhone}
              inputValue={phone || ""}
              register={true}
              name={"phone"}
              warningView={false}
              dataArray={countryPhoneCodeArr}
            />
          </div>

          <div className=" h-[50px] w-full">
            <InputComponent
              warningView={false}
              required={true}
              inputValue={email}
              setInputValue={setEmail}
              placeholder={"Email"}
              name="email"
            />
          </div>
          <input className="bg-transparent h-0 w-0" type="text" name="email" />
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 gap-y-5 mb-5">
            <div className="col-span-1">
              <InputComponent
                warningView={false}
                required={true}
                inputValue={password}
                setInputValue={setPassword}
                placeholder={"Password"}
                type={"password"}
                name="pass"
                password={true}
                passwordToggle={toggleShowPass}
              />
            </div>
            <div className="col-span-1">
              <InputComponent
                warningView={false}
                required={true}
                inputValue={rePassword}
                setInputValue={setRePassword}
                placeholder={"Repeat password"}
                type={"password"}
                name="confirmPass"
                password={true}
                passwordToggle={toggleShowPass}
              />
            </div>
          </div>

          <button
            onClick={() => {
              CreateUser();
            }}
            className={`flex justify-center items-center mt-12 h-[49px] rounded-[300px] w-full px-2 border border-[#9e9989] active:scale-90 duration-300 ${
              "bg-btn-primary hover:bg-btn-secondary text-bg-white "
              // : "text-[#9e9989] bg-btn-base"
            }`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
