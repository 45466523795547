import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function AdminTNCToll() {
  const { id } = useParams();

  const route = useLocation();
  const property = decodeURIComponent(route.search);

  const [hotelTnc, setHotelTnc] = useState();
  const [updateCondition, setUpdateCondition] = useState(false);
  const [updateInd, setUpdateInd] = useState(0);

  const [tncDetails, setTncDetailsArray] = useState([]);
  const [tncDetailsData, setTncDetails] = useState({
    title: "",
    description: "",
    id: 0,
    //rentalPropertyId: Number(id),
  });
  const [modalShown, setToggleModal] = useState(false);
  const hotelTncDataFetch = () => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.FETCH_ALL_SINGLE_HOTEL_TNC
        }pageNumber=1&pageSize=10&rentalPropertyId=${id}&requestId=123`
      )
      .then((res) => {
        // setPhotoUrl(res?.data);
        setHotelTnc(res?.data);
      })
      .catch((err) => {
        // toastMessage("Error fetching data", "error");
      });
  };

  console.log("hotelTnc", hotelTnc);
  const handleTncUpdate = () => {
    if (updateInd >= 0 && updateInd < tncDetails.length) {
      tncDetails[updateInd] = {
        title: tncDetailsData?.title,
        description: tncDetailsData?.description,
      };
      setToggleModal(false);
      setTncDetails({
        title: "",
        description: "",
        id: 0,
        // rentalPropertyId: Number(id),
      });
    } else {
      console.log("Invalid index");
    }
  };
  useEffect(() => {
    hotelTncDataFetch();
  }, []);
  const handleUpdateTncModal = (tnc, ind) => {
    setToggleModal(true);
    setUpdateCondition(true);
    setTncDetails({
      title: tnc?.title,
      description: tnc?.description,
      id: tnc?.id > 0 ? tnc.id : 0,
      //rentalPropertyId: Number(id),
    });
    setUpdateInd(ind);
  };

  const handleTncAdd = () => {
    setUpdateCondition(false);
    setToggleModal(true);
  };

  const handleTnc = () => {
    setTncDetailsArray([...tncDetails, tncDetailsData]);
    setTncDetails({
      title: "",
      description: "",
      id: 0,
     // rentalPropertyId: Number(id),
    });
    setToggleModal(false);
  };

  const handleTncAddToApi = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_HOTEL_TNC}?rentalId=${id}`, tncDetails)
      .then((res) => {
        console.log("res", res?.data);
        toast.success("Added successfully!");
        setToggleModal(false);
        hotelTncDataFetch();
        setTncDetailsArray([]);
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.warn("Add failed!");
      });
  };

  const handleTncUpdateApi = () => {
    authAxios
      .put(`${apiEndPoint.ADMIN_UPDATE_TNC}?rentalId=${tncDetailsData?.rentalPropertyId}`, {
        description: tncDetailsData?.description,
        id: tncDetailsData?.id,
        //rentalPropertyId: tncDetailsData?.rentalPropertyId,
        title: tncDetailsData?.title,
      })
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Successfully update TNC");
          hotelTncDataFetch();
        }
        setToggleModal(false);
      });
  };

  const handleDelete = (id) => {
    authAxios
      .delete(
        `${baseUrl}${apiEndPoint.ADMIN_DELETE_TNC}id=${id}&requestId=delete`
      )
      .then((res) => {
        if (res?.data?.success === true) {
          toast.success("TNC deleted successfully!");
          hotelTncDataFetch();
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="mt-[20px]">
      <h2 className="text-[20px] text-center flex flex-col">
        Terms and Conditions By{" "}
        <span className="text-[14px]">{property.split("=")?.[1]}</span>{" "}
      </h2>
      <div className="mt-[30px] max-w-[1100px] 2xl:max-w-[1440px] mx-auto ">
        <div className="flex gap-x-[60px]">
          {/* new terms add */}
          <div className="w-full">
            {tncDetails?.length > 0 ? (
              <div className="flex justify-start flex-col items-start h-[75vh] shadow-customShadow rounded-[20px] relative p-[16px] ">
                <p>Draft</p>
                <div className="flex flex-col gap-[10px] w-full max-h-[400px]  overflow-y-auto">
                  {tncDetails?.length > 0 &&
                    tncDetails?.map((tnc, index) => (
                      <div className="w-full">
                        <div className="flex flex-col gap-[5px] justify-start items-start border p-[4px] rounded-[12px] border-[#27AE60] relative mt-[10px] w-full ">
                          <div
                            onClick={() => handleUpdateTncModal(tnc, index)}
                            className="cursor-pointer absolute  right-0 top-[-10px] border rounded-full p-[2px] border-[#27AE60]"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-[14px] h-[14px] text-[#1E8449]"
                            >
                              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                            </svg>
                          </div>

                          <p className="text-[16px] text-[#27AE60] flex justify-center items-center gap-x-[5px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-[16px] text-[] h-[16px] "
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                clipRule="evenodd"
                              />
                            </svg>

                            <span className="text-[16px] "> {tnc?.title}</span>
                          </p>
                          <p className="text-[14px] text-[#52BE80] flex justify-center items-start gap-x-[5px]">
                            <span className="min-w-[16px] min-[16px]">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-[14px] h-[14px] text-[#52BE80]"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 01-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0113.5 1.5H15a3 3 0 012.663 1.618zM12 4.5A1.5 1.5 0 0113.5 3H15a1.5 1.5 0 011.5 1.5H12z"
                                  clipRule="evenodd"
                                />
                                <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 019 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0116.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625v-12z" />
                                <path d="M10.5 10.5a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963 5.23 5.23 0 00-3.434-1.279h-1.875a.375.375 0 01-.375-.375V10.5z" />
                              </svg>
                            </span>

                            <span className="text-[14px] ">
                              {" "}
                              {tnc?.description}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  onClick={() => handleTncAdd()}
                  className="flex justify-center items-center text-[14px] text-bg-white rounded-[12px] cursor-pointer ml-auto mt-[20px] bg-btn-secondary w-[150px] h-[49px]"
                >
                  Add New+
                </button>
                <div className="absolute   bottom-[10px] w-full left-0  flex justify-center items-center px-[16px]">
                  <button
                    className="text-[14px] text-bg-white rounded-[12px] cursor-pointer  mt-[30px] bg-btn-secondary w-full mx-auto  h-[49px]"
                    onClick={() => handleTncAddToApi()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              tncDetails?.length === 0 && (
                <button
                  onClick={() => handleTncAdd()}
                  className="flex justify-center items-center text-[14px] text-bg-white rounded-[12px] cursor-pointer mx-auto mt-[20px] bg-btn-secondary w-[350px] h-[49px]"
                >
                  Add New+
                </button>
              )
            )}
          </div>
          {/* exist terms view and update */}
          <div
            className={`w-full ${
              hotelTnc?.numberOfEntries > 0 ? "block" : "hidden"
            }`}
          >
            <div className="w-full flex flex-col gap-[10px] shadow-customShadow p-[16px] rounded-[12px] h-screen  overflow-y-auto">
              <p>Already added TNC</p>
              {hotelTnc?.rentalPropertyTNCDataList?.map((tnc, index) => (
                <div className="flex flex-col gap-[8px] justify-start items-start border p-[4px] rounded-[12px] border-[#27AE60] relative mt-[10px] w-full ">
                  <div className="absolute gap-x-[5px] right-0 top-[-10px] flex justify-start items-center flex-row-reverse">
                    <div
                      onClick={() => handleUpdateTncModal(tnc, index)}
                      className="cursor-pointer border rounded-full p-[2px] border-[#27AE60]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-[14px] h-[14px] text-[#1E8449]"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                      </svg>
                    </div>
                    <div
                      onClick={() => handleDelete(tnc?.id)}
                      className="cursor-pointer border rounded-full p-[2px] border-[#EC7063]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[14px] h-[14px] text-[#EC7063]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </div>
                  <p className="text-[16px] text-[#27AE60] flex justify-center items-center gap-x-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-[16px] text-[] h-[16px] "
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="text-[16px] "> {tnc?.title}</span>
                  </p>
                  <p className="text-[14px] text-[#52BE80] flex justify-center items-start gap-x-[5px]">
                    <span className="min-w-[16px] min-[16px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-[14px] h-[14px] text-[#52BE80]"
                      >
                        <path
                          fillRule="evenodd"
                          d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 01-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0113.5 1.5H15a3 3 0 012.663 1.618zM12 4.5A1.5 1.5 0 0113.5 3H15a1.5 1.5 0 011.5 1.5H12z"
                          clipRule="evenodd"
                        />
                        <path d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 019 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0116.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625v-12z" />
                        <path d="M10.5 10.5a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963 5.23 5.23 0 00-3.434-1.279h-1.875a.375.375 0 01-.375-.375V10.5z" />
                      </svg>
                    </span>

                    <span className="text-[14px] "> {tnc?.description}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {modalShown && (
        <ModalCommon
          shown={modalShown}
          width="max-w-[500px]"
          height="min-h-[412px]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
            setTncDetails({
              title: "",
              description: "",
              id: 0,
              //rentalPropertyId: Number(id),
            });
          }}
        >
          <div>
            <div className="border rounded-[12px] p-[10px]  border-[#95A5A6] max-w-[390px] mx-auto mt-[30px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTncDetails((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={tncDetailsData?.title}
                />
                <span className="placeholder">TNC Title :</span>
              </label>
              <label className="custom-field one w-full">
                <textarea
                  onChange={(e) =>
                    setTncDetails((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="input w-full min-h-[150px] max-h-[200px]"
                  type="text"
                  placeholder=" "
                  value={tncDetailsData?.description}
                ></textarea>
                <span className="placeholder">TNC Description :</span>
              </label>
              {updateCondition ? (
                tncDetailsData?.id > 0 ? (
                  <button
                    onClick={() => handleTncUpdateApi()}
                    className="bg-[#27AE60] mx-auto w-[250px] flex justify-center rounded-[4px] py-[6px] mt-[10px] text-bg-white cursor-pointer"
                  >
                    Update TNC
                  </button>
                ) : (
                  <button
                    onClick={() => handleTncUpdate()}
                    className="bg-[#27AE60] mx-auto w-[250px] flex justify-center rounded-[4px] py-[6px] mt-[10px] text-bg-white cursor-pointer"
                  >
                    Modify TNC
                  </button>
                )
              ) : (
                <button
                  onClick={() => handleTnc()}
                  className="bg-[#27AE60] mx-auto w-[250px] flex justify-center rounded-[4px] py-[6px] mt-[10px] text-bg-white cursor-pointer"
                >
                  Add TNC
                </button>
              )}
            </div>
          </div>
        </ModalCommon>
      )}
    </div>
  );
}
