import React, { useEffect, useState } from "react";
import MultiSelectParent from "../../../components/multiselect/MultiSelectParent";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { noAuthAxios } from "../../../utils/axiosWrapperOpen";
import { toastMessage } from "../../../utils/toast";

export default function TourAddedDashboard() {
  const [destinationList, setDestinationList] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [getAddTours, setGetAddTours] = useState([]);
  const [tourData, setTourData] = useState({
    destination_location_id: 0,

    number_of_days: "",
    number_of_nights: "",
    short_address: "",
  });

  useEffect(() => {
    setTourData((prev) => ({
      ...prev,
      destination_location_id: selectedDestination[0],
    }));
  }, [selectedDestination]);

  const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});
  const getDestinationList = () => {
    noAuthAxios
      .get(`${apiEndPoint.ADMIN_GET_INSPIRED}?requestId=123&currency=BDT`)
      .then((res) => {
        setDestinationList(res.data.destinationLocationDataOptimizedList);
      })
      .catch((err) => {
        toastMessage("Error fething travel destination", "error");
      });
  };

  useEffect(() => {
    getDestinationList();
  }, []);
  const getAddTour = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_TOURS_GET_API}`)
      .then((response) => {
        setGetAddTours(response?.data?.tours);
      });
  };
  //
  useEffect(() => {
    getAddTour();
  }, []);
  const handleTourAdd = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_ADD_POST_API}`, {
        ...tourData,
      })
      .then((response) => {
        console.log("response", response);
        setTourData({
          destination_location_id: 0,

          number_of_days: "",
          number_of_nights: "",
          short_address: "",
        });
        setSelectedDestination([]);
        getAddTour();
      });

    // http://192.168.0.101:8080/api/v1/admin/tours/add
  };

  return (
    <div className="max-w-[1100px] mx-auto mt-[30px]">
      <p className="text-btn-secondary">Tour Added list</p>
      <div className="flex flex-col gap-y-[14px]  max-w-[450px] w-full  mt-[12px] overflow-y-scroll max-h-[550px] p-[12px]">
        {getAddTours?.map((tourData, index) => (
          <div
            key={index}
            className="flex gap-y-[3px]  flex-col shadow-customShadow p-[10px] rounded-[8px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Day:</span>{" "}
              {tourData?.number_of_days}
            </p>
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Night:</span>{" "}
              {tourData?.number_of_nights}
            </p>

            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Short Address:</span>{" "}
              {tourData?.short_address}
            </p>
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Destination Location :</span>{" "}
              {tourData?.destination_location}
            </p>
          </div>
        ))}
      </div>
      <div className="max-w-[450px] mx-auto mt-[30px] flex flex-col gap-y-[10px] shadow-customShadow rounded-[8px] p-[12px] fixed right-[40px] top-[90px]">
        <h2 className=" text-center text-[22px] underline">Tour Add</h2>
        <div className="flex justify-between items-center gap-x-[15px] ">
          <div className="w-full">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourData((prev) => ({
                    ...prev,
                    number_of_days: e.target.value,
                  }))
                }
                className="input w-full"
                type="number"
                placeholder=" "
                value={tourData.number_of_days}
              />
              <span className="placeholder">No of Day :</span>
            </label>
          </div>
          <div className="w-full">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourData((prev) => ({
                    ...prev,
                    number_of_nights: e.target.value,
                  }))
                }
                className="input w-full"
                type="number"
                placeholder=" "
                value={tourData.number_of_nights}
              />
              <span className="placeholder">No of Night :</span>
            </label>
          </div>
        </div>

        <div>
          <label className="custom-field one w-full">
            <input
              onChange={(e) =>
                setTourData((prev) => ({
                  ...prev,
                  short_address: e.target.value,
                }))
              }
              className="input w-full"
              type="text"
              placeholder=" "
              value={tourData.short_address}
            />
            <span className="placeholder">Short Address :</span>
          </label>
        </div>
        <div className="">
          <p className="text-[16px] mb-[3px]">Select Destination</p>

          <MultiSelectParent
            required={true}
            selectedArray={selectedDestination}
            setSelectedArray={setSelectedDestination}
            displayField={"name"}
            selectionKey="id"
            data={destinationList}
            placeholder="Travel Destinations"
            // isAdmin={
            //   process.env.REACT_APP_USER_TYPE === UserType.ADMIN
            //     ? true
            //     : false
            // }
            isReadOnly={false}
            rejectionArray={facilitiesRejectArray}
            setRejectionArray={setFacilitiesRejectArray}
            name="selectedDestinations"
          />
        </div>

        <button
          disabled={
            tourData?.number_of_days.length === 0 ||
            tourData?.number_of_nights.length === 0 ||
            tourData?.short_address.length === 0 ||
            tourData?.destination_location_id === undefined
          }
          onClick={() => handleTourAdd()}
          className="bg-btn-primary disabled:bg-bg-semiblack
           h-[49px] text-bg-white rounded-[4px]"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
