import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ModalCommon from "../components/common/ModalCommon";
import Loading from "../components/Loader/Loading";
import { merchantExcursionRoute } from "../contants/Constants";
import { baseUrl, MERCHANT_EXCURSION_ENDPOINT } from "../contants/Endpoints";
import Paginations from "../pages/admin/Paginations/Paginations";
import TourMerchantButton from "../ToursMarchent/TourMerchantButton";
import { authAxios } from "../utils/axiosWrapper";

const ExcursionDashboard = () => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [toggleModalSecond, setToggleModalSecond] = useState(false);

  const [excursionId, setExcursionId] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 10;

  const checkComplete = (item, value) => {
    const iconArray = [
      {
        iconCheck: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            className="text-[#86efac]"
            viewBox="0 0 448 512"
          >
            <path
              d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
              fill="#f8fafc"
            />
          </svg>
        ),
        iconX: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 384 512"
          >
            <path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              fill="#f8fafc"
            />
          </svg>
        ),
      },
    ];
    const completeArray = ["Step 1: Created"];
    const inCompleteArray = [];

    if (item?.excursion_address_present) completeArray.push("Step 2: Address");
    else inCompleteArray.push("Step 2: Address");
    if (item?.excursion_meeting_point_present)
      completeArray.push("Step 3: MeetingPoint");
    else inCompleteArray.push("Step 3: MeetingPoint");
    if (item?.excursion_additional_information_present)
      completeArray.push("Step 4: AdditionalInformation");
    else inCompleteArray.push("Step 4: AdditionalInformation");
    if (item?.excursion_suitable_for_present)
      completeArray.push("Step 5: SuitableFor");
    else inCompleteArray.push("Step 5: SuitableFor");
    if (item?.excursion_unsuitable_for_present)
      completeArray.push("Step 6: UnsuitableFor");
    else inCompleteArray.push("Step 6: UnsuitableFor");
    if (item?.excursion_included_component_present)
      completeArray.push("Step 7: Included");
    else inCompleteArray.push("Step 7: Included");
    if (item?.excursion_excluded_component_present)
      completeArray.push("Step 8: Excluded");
    else inCompleteArray.push("Step 8: Excluded");
    if (item?.excursion_itinerary_step_present)
      completeArray.push("Step 9: Itinerary");
    else inCompleteArray.push("Step 9: Itinerary");
    if (item?.excursion_cancellation_policy_present)
      completeArray.push("Step 10: Cancellation Policy");
    else inCompleteArray.push("Step 10: Cancellation Policy");

    if (value === "complete") {
      return (
        <div>
          {completeArray.length === 0 ? (
            <div className="flex items-start">
              <p className="mr-[4px] text-[14px]">{iconArray[0]?.iconX}</p>
              <p>All are incomplete</p>
            </div>
          ) : (
            <div className="flex flex-col items-start ">
              {completeArray?.map((info, index) => (
                <div key={index} className="flex items-center py-[4px]">
                  <p className="mr-[4px] text-[12px] bg-[#22c55e] py-[3px] px-[4px] rounded-[50%]">
                    {iconArray[0]?.iconCheck}
                  </p>
                  <p className="text-txt-primary">{info}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else if (value === "incomplete") {
      return (
        <div>
          {inCompleteArray?.length === 0 ? (
            <div className="flex items-center py-[4px]">
              <p className="mr-[4px] text-[12px] bg-[#22c55e] py-[3px] px-[4px] rounded-[50%]">
                {iconArray[0]?.iconCheck}
              </p>
              <p className="text-txt-primary">All are Completed!</p>
            </div>
          ) : (
            <div className="flex flex-col items-start ">
              {inCompleteArray?.map((info, index) => (
                <div key={index} className="flex items-center py-[4px]">
                  <p className="mr-[4px] text-[10px] bg-[#f87171] py-[3px] px-[4px] rounded-[50%]">
                    {iconArray[0]?.iconX}
                  </p>
                  <p>{info}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  // const getAllExcursion = () => {
  //   authAxios
  //     .get(
  //       `${
  //         baseUrl + MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ALL
  //       }?requestId=${Number(localStorage.getItem("id"))}`
  //     )
  //     .then((res) => {
  //       setAllData(res?.data?.excursionDataList);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  const getsAllExcursionWithPagination = () => {
    authAxios
      .get(
        `${
          baseUrl +
          MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ALL_WITH_PAGINATION
        }?pageNumber=${currentPage}&pageSize=${pageSize}&queryString=${searchValue}&requestId=&${123}`
      )
      .then((res) => {
        // console.log("res Pagination: ", res?.data?.entries);
        setAllData(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Err Pagination: ", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    // getAllExcursion();
    getsAllExcursionWithPagination();
  }, [currentPage, searchValue]);

  const onViewButton = (id, step) => {
    // console.log({id, step})
    // const newUrl = changeUrl(id, step);
    // navigate(newUrl);
    navigate(`${merchantExcursionRoute.EXCURSION_CREATE}?step=${0}&id=${id}`);
  };

  const deleteExcursion = (id) => {
    setExcursionId(id);
    setToggleModalSecond(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    authAxios
      .delete(
        `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_DELETE_BY_ID}?excursionId=${excursionId}&requestId=123`
      )
      .then((res) => {
        toast.success("Delete Excursion");
        // getAllExcursion();
        getsAllExcursionWithPagination();
        setToggleModalSecond(false);
      })
      .catch((error) => toast.error(error));
  };

  return (
    <div className="w-full xl:px-[48px]  mx-auto">
      {/* <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
        Additional Information
      </h2> */}
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]"> Excursion Dashboard</span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          An Excursion Dashboard organizes all activities and trip info for
          smooth planning and execution.
        </p>
      </div>

      <div className="md:w-[300px] w-[200px] mx-auto">
        <TourMerchantButton
          width={"w-full"}
          name={"Add New Excursion"}
          onClick={() => {
            navigate(`${merchantExcursionRoute.EXCURSION_CREATE}?step=&id=`);
            // console.log("test: ", merchantExcursionRoute.EXCURSION_CREATE);
          }}
        ></TourMerchantButton>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div>
          {/* List Items */}
          <div>
            {allData?.length === 0 ? (
              <p className="my-[20px] text-center text-txt-primary">
                You have No Excursion List. Please Add Some.
              </p>
            ) : (
              <div className="mt-[20px] flex justify-between">
                <p className="text-btn-secondary">
                  <span className="shadow-customShadow py-[8px] px-[16px]">
                    Your Excursion List:{" "}
                  </span>
                </p>
                <div className="relative text-gray-600">
                  {/* <input value={textSearch} placeholder="Search" className="py-[8px] px-[8px] border-[1px]" type="text"  /> */}
                </div>
              </div>
            )}
            {allData?.length > 0 && (
              <div>
                <table className="fl-table my-[20px]">
                  <thead className="sticky top-[88px]">
                    <tr>
                      <th>Sl No.</th>
                      <th>Name</th>
                      <th>ID</th>
                      <th>Address</th>

                      <th>Complete</th>
                      <th>Incomplete</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData?.length > 0 &&
                      allData?.map((item, index) => (
                        <tr key={index} className="text-txt-primary">
                          <td className="cursor-pointer mx-auto">
                            {index + 1 + pageSize * currentPage - pageSize}.
                          </td>

                          <td>
                            {item?.excursionName
                              ? item?.excursionName
                              : "No Name Found!"}
                          </td>
                          <td className="cursor-pointer mx-auto">
                            {item.excursionId}
                          </td>
                          <td>
                            {item?.address
                              ? item?.address
                              : "No Address Found!"}
                          </td>

                          <td>{checkComplete(item, "complete")}</td>
                          <td>{checkComplete(item, "incomplete")}</td>
                          <td className="">
                            <div className="flex flex-row items-center justify-center gap-x-[8px]">
                              <button
                                onClick={() =>
                                  onViewButton(item.excursionId, 0)
                                }
                                className="bg-btn-secondary text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-[4px] shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg "
                              >
                                View
                              </button>
                              <button
                                className="bg-[#f87171] text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-[4px] shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg "
                                onClick={() =>
                                  onViewButton(item.excursionId, 0)
                                }
                              >
                                Edit
                              </button>
                              <button
                                className="bg-[#f87171] text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-[4px] shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg"
                                onClick={() =>
                                  deleteExcursion(item?.excursionId)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="my-10">
        <Paginations
          currentPage={currentPage}
          totalCount={totalPage}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      <div>
        <ModalCommon
          shown={toggleModalSecond}
          width="max-w-[280px]"
          height="min-h-[150px]"
          outSideOff={false}
          close={() => {
            setToggleModalSecond(false);
          }}
        >
          <div className="p-[24px]">
            <p className="text-btn-primary">Are you sure you want to delete?</p>
            <div className="flex justify-between">
              <button
                onClick={() => setToggleModalSecond(false)}
                className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
              >
                CANCEL
              </button>
              <button
                onClick={() => onConfirmDelete()}
                className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
              >
                CONFIRM
              </button>
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default ExcursionDashboard;
