import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT, baseUrl } from "../../contants/Endpoints";

const Excludes = () => {
    const [allData, setAllData] = useState([]);
  const [excludes, setExcludes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllExcludeAndInclude = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_GET_EXCURSION_FETCH_ALL_COMPONENTS}`
      )
      .then((res) => {
        console.log("res: ", res?.data);
        setAllData(res?.data?.excursionComponentData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllExcludeAndInclude();
  }, []);

  return (
    <div>
      <div className="mt-[16px] mb-[8px]">
        {excludes?.data?.length === 0 || excludes?.data === undefined ? (
          <p className="bg-[#63a561] text-bg-primary py-[8px] px-[16px] rounded-[4px]">
           <span className="text-btn-warning">Exclude:</span> No Selected Exclude data.{" "}
          </p>
        ) : (
          <div className="shadow-customShadow rounded-[4px] overflow-hidden">
            <div className="bg-[#63a561]">
              <p className="text-bg-primary  text-center py-[8px]">
              <span className="text-btn-warning">Exclude:</span>  {excludes?.data?.length} Items selected.
              </p>
            </div>
            <div className="flex gap-[8px] w-[574px] overflow-x-auto px-[20px] py-[8px]">
              {excludes?.data?.length &&
                excludes?.data.map((item) => (
                  <div
                    key={item?.id}
                    className="shadow-customShadow min-w-[140px] my-[8px] rounded-[4px]"
                  >
                    <div className="py-[8px] px-[8px]">
                      <p className="text-[#63a561] text-[12px] text-center">
                        Exclude Id: #{item?.id}
                      </p>
                      <div className="flex justify-start gap-[8px] items-center my-[8px]">
                        <img
                          className="min-w-[40px] "
                          src={item?.iconUrl}
                          alt=""
                        />
                        <p className="text-btn-secondary">{item?.label}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        </div>
        <div>
          <div>
            <p className="text-btn-secondary">Excludes: </p>
          </div>
          <div className="rounded mb-[12px]">
            <Select
              closeMenuOnSelect={false}
              value={excludes?.data}
              onChange={(data) => {
                setExcludes((prev) => ({
                  ...prev,
                  data,
                }));
                // handleOnChange(data)
              }}
              components={animatedComponents}
              options={allData}
              placeholder={"Select Excludes: "}
              styles={colorStyles}
              isMulti
              isClearable
            />
            <div className="mt-[12px] flex justify-end">
              <TourMerchantButton
                // onClick={() => addIncludes()}
                name={"Update Includes"}
              ></TourMerchantButton>
            </div>
          </div>
        
      </div>
    </div>
  )
}

export default Excludes