import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";

import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../Utilities/SelectAnimation";

const TransportationPackage = ({
  setMarchantCreateDatas,
  setPackageSelect,
  marchantTourCreateDatas,
}) => {
  const [transportationBrandList, setTransportationBrandList] = useState([]);
  const [transportationModeList, setTransportationModeList] = useState([]);
  const [transportationProviderList, setTransportationProviderList] = useState(
    []
  );
  const [transportationRouteList, setTransportationRouteList] = useState([]);

  const handleBackButton = () => {
    setPackageSelect(null);
  };

  const getTransportationBrandList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_BRAND_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_brands);
        setTransportationBrandList(res?.data?.transportation_brands);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationModeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_MODE_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_modes);
        setTransportationModeList(res?.data?.transportation_modes);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationProviderList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_PROVIDER_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_providers);
        setTransportationProviderList(res?.data?.transportation_providers);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationRouteList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_ROUTE_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        console.log(res?.data);
        setTransportationRouteList(res?.data?.transportationRouteDataList);
        // setTransportationProviderList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTransportationBrandList();
    getTransportationModeList();
    getTransportationProviderList();
    getTransportationRouteList();
    getTransportationRouteList();
  }, []);

  const transportationBrandArray = transformArray(
    transportationBrandList,
    "transportation_brand_id",
    "transportation_brand_name"
  );
  const transportationModeArray = transformArray(
    transportationModeList,
    "transportation_mode_id",
    "transportation_mode_name"
  );
  const transportationProviderArray = transformArray(
    transportationProviderList,
    "transportation_provider_id",
    "transportation_provider_name"
  );

  const transportationRoute = transportationRouteList?.map((item) => ({
    value: item.transportation_route_id,
    label: `${item.transportation_route_destination_location} - ${item.transportation_route_source_location}`,
  }));

  const [transportationPackage, setTransportationPackage] = useState({
    transportation_route_id: "",
    transportation_provider_id: "",
    transportation_mode_id: "",
    transportation_brand_id: "",
    trip_type: "",
    is_ac: false,
    transportation_package_unit_price: "",
    transportation_package_quantity: "",
    transportation_package_total_price: "",
    transportation_package_is_default: false,
  });

  // console.log(transportationBrand, foodItemOption, priceOption);

  const transportationTripTypeArray = [
    { value: "ONE_WAY", label: "ONE_WAY" },
    { value: "ROUND_TRIP", label: "ROUND_TRIP" },
  ];

  const transportationWithAcArray = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  console.log(
    "transportationPackage",
    transportationPackage?.transportation_package_is_default
  );
  const handleAddButtonClick = () => {
    if (
      transportationPackage?.transportation_route_id?.label !== undefined &&
      transportationPackage?.transportation_provider_id?.label !== undefined &&
      transportationPackage?.transportation_mode_id?.label !== undefined &&
      transportationPackage?.transportation_brand_id?.label !== undefined &&
      transportationPackage?.trip_type?.label !== undefined &&
      transportationPackage?.is_ac?.label !== undefined &&
      transportationPackage?.transportation_package_unit_price > 0 &&
      transportationPackage?.transportation_package_quantity > 0
    ) {
      setMarchantCreateDatas((prevPackage) => ({
        ...prevPackage,
        tour_package_transportation_packages: [
          ...prevPackage.tour_package_transportation_packages,
          transportationPackage,
        ],
      }));

      setTransportationPackage({
        transportation_route_id: "",
        transportation_provider_id: "",
        transportation_mode_id: "",
        transportation_brand_id: "",
        trip_type: "",
        is_ac: false,
        transportation_package_unit_price: "",
        transportation_package_quantity: "",
        transportation_package_is_default: false,
      });
    }
  };

  const handleSubmitTransportation = () => {
    setPackageSelect(null);
  };

  const handleRemoveTransportation = (ind) => {
    const newArray =
      marchantTourCreateDatas?.tour_package_transportation_packages?.filter(
        (meal, index) => index !== ind
      );
    setMarchantCreateDatas((prevPackage) => ({
      ...prevPackage,
      tour_package_transportation_packages: newArray,
    }));
  };
  useEffect(() => {
    const quantity = Number(
      transportationPackage.transportation_package_quantity
    );
    const unitPrice = Number(
      transportationPackage.transportation_package_unit_price
    );

    if (!isNaN(quantity) && !isNaN(unitPrice)) {
      const total = quantity * unitPrice;
      setTransportationPackage((prev) => ({
        ...prev,
        transportation_package_total_price: total,
      }));
    } else {
      setTransportationPackage((prev) => ({
        ...prev,
        transportation_package_total_price: "",
      }));
    }
  }, [
    transportationPackage.transportation_package_quantity,
    transportationPackage.transportation_package_unit_price,
  ]);
  //   console.log("line 55: ", mealTypeOption, foodItemOption, priceOption);

  return (
    <>
      <p className="text-btn-secondary text-center mb-[24px] xl:text-[22px] md:text-[18px] text-[16px]">
        Transportation
      </p>

      <div className="my-[10px] ">
        <button
          onClick={() => handleBackButton(null)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
      </div>

      <div>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-[70%] w-full  rounded p-[24px] bg-bg-white h-full">
            <p className="w-full p-[12px] rounded bg-[#e2e8f0] text-btn-primary font-bold">
              Create Transportation:
            </p>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Transportation Route: </p>
              </div>
              <div className="rounded">
                <Select
                  value={transportationPackage?.transportation_route_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_route_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationRoute}
                  placeholder={"Select Route: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Transportation Provider: </p>
              </div>
              <div className="rounded">
                <Select
                  value={
                    transportationPackage?.transportation_provider_id || ""
                  }
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_provider_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationProviderArray}
                  placeholder={"Select Provider: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Transportation Mode: </p>
              </div>
              <div className="rounded">
                <Select
                  value={transportationPackage?.transportation_mode_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_mode_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationModeArray}
                  placeholder={"Select Mode: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Transportation Brand: </p>
              </div>
              <div className="rounded">
                <Select
                  // defaultValue={foodItemOption || ""}
                  value={transportationPackage?.transportation_brand_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_brand_id: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationBrandArray}
                  placeholder={"Select Brand: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Trip Type: </p>
              </div>
              <div className="rounded">
                <Select
                  // defaultValue={foodItemOption || ""}
                  value={transportationPackage?.trip_type || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      trip_type: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationTripTypeArray}
                  placeholder={"Select Type: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Ac included: </p>
              </div>
              <div className="rounded">
                <Select
                  value={transportationPackage?.is_ac || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      is_ac: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationWithAcArray}
                  placeholder={"Is Ac available: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Unit per price: </p>
              </div>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Unit per Price:"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setTransportationPackage((prev) => ({
                        ...prev,
                        transportation_package_unit_price: inputVal,
                      }));
                    }
                  }}
                  value={
                    transportationPackage?.transportation_package_unit_price
                  }
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Package Quantity: </p>
              </div>

              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Total Quantity:"
                  type="text"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setTransportationPackage((prev) => ({
                        ...prev,
                        transportation_package_quantity: inputVal,
                      }));
                    }
                  }}
                  value={transportationPackage?.transportation_package_quantity}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Total Price: </p>
              </div>

              <div className="rounded">
                <input
                  readOnly
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] cursor-wait"
                  placeholder="Total"
                  type="text"
                  value={
                    transportationPackage?.transportation_package_total_price
                  }
                />
              </div>
            </div>

            {/* <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">
                  Transportation is included:{" "}
                </p>
              </div>
              <div className="rounded">
                <Select
                  value={
                    transportationPackage?.transportation_package_is_default ||
                    ""
                  }
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_package_is_default: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationWithAcArray}
                  placeholder={"Is Ac available: "}
                  styles={colorStyles}
                />
              </div>
            </div> */}

            <div className="mt-[32px] mb-[16px]">
              <TourMerchantButton
                name={"Add"}
                width={"w-full"}
                onClick={handleAddButtonClick}
              ></TourMerchantButton>
            </div>
          </div>
          <div className="md:w-[30%] w-full bg-[#e5e7eb] md:pl-[16px] mt-[16px] md:mt-0">
            {marchantTourCreateDatas?.tour_package_transportation_packages
              .length === 0 && (
              <div className="bg-bg-white p-[16px] w-full rounded text-btn-secondary text-center">
                No Item Selected
              </div>
            )}

            {/* Right-side Nav */}
            <div className="max-h-[932px] overflow-y-scroll mb-[20px]">
              {marchantTourCreateDatas?.tour_package_transportation_packages?.map(
                (item, index) => (
                  <div
                    key={index}
                    className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded relative overflow-clip"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[15px] left-[-47px] rotate-[-45deg] ${
                        item?.transportation_package_is_default?.value
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <p className="text-[14px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>
                    <p className="p-[8px] text-center bg-[#e2e8f0] text-btn-primary mb-[16px] rounded">
                      {item?.transportation_route_id?.label}
                    </p>

                    <div className="xl:text-[14px] text-[12px]">
                      <p className="text-txt-primary">
                        {" "}
                        <span className="text-bg-semiblack">
                          {item?.transportation_mode_id?.label}:
                        </span>{" "}
                        {item?.transportation_provider_id?.label} -{" "}
                        {item?.transportation_brand_id?.label}
                      </p>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">Trip type: </span>
                        {item?.trip_type?.label}
                      </p>
                      <div className="">
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Included: </span>
                          {item?.transportation_package_is_default?.label}
                        </p>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Ac: </span>
                          {item?.is_ac?.label}
                        </p>
                      </div>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">
                          Price per quantity:{" "}
                        </span>
                        {item?.transportation_package_unit_price}৳ X{" "}
                        {item?.transportation_package_quantity}
                      </p>
                      <p className="py-[4px] xl:text-[18px] text-[16px] text-center">
                        Total Price: {item?.transportation_package_total_price}৳
                      </p>
                    </div>

                    <span
                      onClick={() => handleRemoveTransportation(index)}
                      className="absolute right-[2px] cursor-pointer top-[2px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[25px] h-[25px] text-btn-pink"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </div>
                )
              )}
            </div>

            {marchantTourCreateDatas?.tour_package_transportation_packages
              ?.length >= 1 && (
              <div className="">
                <TourMerchantButton
                  name={"Submit"}
                  width={"w-full"}
                  onClick={() => handleSubmitTransportation()}
                ></TourMerchantButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransportationPackage;
