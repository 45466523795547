import React, { useEffect, useState } from "react";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../components/common/ModalCommon";
import FileMultipleCategory from "../../components/multipleimage/FileMultipleCategory";
import Loading from "../../components/Loader/Loading";
import RemoveableImageUploadViewCategory from "../../components/RemoveableImageUploadViewCategory";
import ReactModal from "react-modal";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT } from "../../contants/Endpoints";
import { toast } from "react-toastify";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { changeUrl } from "../utils/common";

const Images = ({ setActiveStep }) => {
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));
  const [toggleModal, setToggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [needUpdate, setNeedUpdate] = useState(Math.random());
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 10;

  console.log("urlTempStorageArray: ", urlTempStorageArray);
  console.log({ imgUrl });

  const handleEdit = () => {
    setUrlTempStorageArray([]);
    setToggleModal(true);
  };

  const getAllImages = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_ALL_EXCURSION_IMAGES}?excursionId=${excursionID}&requestId=123`
      )
      .then((res) => {
        console.log({ res });
        setAllImages(res?.data?.imageDataOptimizedList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllImages();
  }, []);

  const payloadMethod = {
    excursionId: excursionID,
    images: urlTempStorageArray,
  };

  const onDeleteImageById = (id) => {
    setIsLoading(true);
    authAxios
      .delete(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_DELETE_EXCURSION_IMAGE_ID}${id}?requestId=123`
      )
      .then((res) => {
        toast.success("Successfully deleted image.");
        setIsLoading(false);
        getAllImages();
      })
      .catch((err) => {
        console.log(err);
        toast.delete("Delete failed!");
      });
  };

  const onSubmitImages = () => {
    authAxios
      .post(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_UPDATE_EXCURSION_IMAGES_ADD}`,
        payloadMethod
      )
      .then((res) => {
        console.log({ res });
        toast.success("SuccessFully Added.");
        setToggleModal(false);
        getAllImages();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Added Failed!");
      });
  };

  const backAndSave = () => {
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
  };

  const onSubmitButton = () => {
    setActiveStep(9);
    changeUrl(Number(url.searchParams.get("id")), 9);
  };

  return (
    <>
      <div className="w-[343px] md:w-[712px]">
        <div className=" mb-[20px]">
          <p
            className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
          >
            <span className="border-x-[4px] px-[8px]">Images</span>
          </p>
          <p className="text-center text-btn-secondary text-[14px]">
            Hope these super-short descriptions work well for your excursion
            images.
          </p>
        </div>

        <div className={`flex justify-center`}>
          <TourMerchantButton
            width={""}
            name={"Add New Images"}
            onClick={handleEdit}
          ></TourMerchantButton>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loading></Loading>
          </div>
        ) : (
          <div className=" py-[20px] px-[24px] my-[20px]">
            {allImages?.length > 0 && (
              <div>
                <table className="fl-table ">
                  <thead className="sticky top-[88px]">
                    <tr>
                      <th>Sl No.</th>
                      <th>Image</th>
                      <th>ID</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allImages?.length > 0 &&
                      allImages?.map((property, index) => (
                        <tr key={index}>
                          <td>
                            {index + 1 + pageSize * currentPage - pageSize}.
                          </td>
                          <td className="cursor-pointer mx-auto">
                            <img
                              className="mx-auto w-[100px] h-[80px]"
                              src={property?.url}
                              alt=""
                            />
                          </td>
                          <td>{property?.id}</td>
                          <td className="">
                            <button
                              className="bg-[#f87171] text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-[4px] shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg"
                              onClick={() => onDeleteImageById(property?.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        <div className="mt-[20px] flex justify-center items-center w-full">
          <CustomButtonGroupExcruions
            setSaveAndNext={backAndSave}
            onSubmit={onSubmitButton}
          />
        </div>
      </div>

      <ReactModal
        style={{ content: { width: "60vw" } }}
        isOpen={toggleModal}
        onRequestClose={() => {
          setToggleModal(false);
        }}
      >
        <div>
          <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
            Add Images
          </h2>
          <div className="flex justify-center">
            <div className="text-[#1b1b1b] w-full">
              <FileMultipleCategory
                setNeedUpdate={setNeedUpdate}
                urlTempStorageArray={urlTempStorageArray}
                setUrlTempStorageArray={setUrlTempStorageArray}
              />
            </div>

            <div className=" w-full">
              <div className="w-full flex gap-y-[8px] flex-col">
                {urlTempStorageArray &&
                  urlTempStorageArray?.length > 0 &&
                  urlTempStorageArray?.map((image, index) => {
                    return (
                      <RemoveableImageUploadViewCategory
                        key={index}
                        url={image.url}
                        userType={"USER"}
                        // id={index}
                        setUrlTempStorageArray={setUrlTempStorageArray}
                        urlTempStorageArray={urlTempStorageArray}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <button
            onClick={() => onSubmitImages()}
            className="bg-btn-primary mt-[20px] text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
          >
            Submit
          </button>
        </div>
      </ReactModal>
    </>
  );
};

export default Images;
