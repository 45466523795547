import React from 'react'

const TourMerchantButton = ({name, width, disabled, height, onClick}) => {
  return (
    <button className={`${width? width: ""} ${height? height : "xl:h-[48px] md:h-[40px] h-[32px]"} xl:py-[6px] md:py-[4px] py-[2px] xl:px-[24px] md:px-[16px] px-[12px]
     xl:text-[16px] md:text-[14px] text-[12px]
    bg-btn-secondary text-bg-primary hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-md shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg`} 
    type='submit' 
    disabled={disabled}
    width={"w-60"}
    onClick={onClick ? onClick : ()=> {}}
    >{name}</button>
  )
}

export default TourMerchantButton