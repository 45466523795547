const messageType = {
  required: "This field is required!",
  number: "This field must contain only numeric values",
};

export function customValidationSingle(
  elementId,
  validationClass = "validation"
) {
  const input = document.querySelector(`#${elementId} #input${elementId}`);
  if (input) {
    if (input?.value.length === 0 || input?.value === "none") {
      const items = document.querySelectorAll(
        `#${elementId} .${validationClass}`
      );
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.add("validationError");
        element.classList.add("placeholder:text-[#FE9A9A]");
      }
      document.querySelector(
        `#${elementId} .errorMessage`
      ).innerText = `This field is requred!`;
    } else {
      input.classList.remove("validationError");
      input.classList.remove("placeholder:text-[#FE9A9A]");
      const items = document.querySelectorAll(
        `#${elementId} .${validationClass}`
      );
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.remove("validationError");
        element.classList.remove("placeholder:text-[#FE9A9A]");
      }
      document.querySelector(`#${elementId} .errorMessage`).innerText = ``;
    }
  }
}

export default function CustomValidation(
  validationClass = "validation",
  type = "required"
) {
  let flag = 0;
  const Elements = [];
  const allRequiredFields = document.getElementsByClassName("required");

  for (let i = 0; i < allRequiredFields.length; i++) {
    Elements.push({ element: allRequiredFields[i], messages: [] });
  }

  for (let mainIndex = 0; mainIndex < Elements.length; mainIndex++) {
    const item = Elements[mainIndex];
    const element = item.element;
    const input = document.querySelector(`#${element.id} #input${element.id}`);
    if (input) {
      const result = CheckIfValueExists(input);
      if (result.error) {
        item.messages.push(result.message);
      }
      // if (input.classList.contains("numberType")) {

      // }
      // if (input.classList.contains("ng-")) {

      // }
    }
  }

  const errorItems = [];

  for (let j = 0; j < Elements.length; j++) {
    const itemElement = Elements[j];
    if (itemElement.messages.length > 0) {
      flag++;
      errorItems.push({ elem: itemElement.element });
      ShowError(itemElement.element, validationClass, itemElement.messages[0]);
    } else {
      clearError(itemElement.element, validationClass);
    }
  }

  if (flag > 0) {
    document
      .getElementById(errorItems[0].elem.id)
      .scrollIntoView({ behavior: "smooth" });
    return false;
  }
  return true;
}

function ShowError(element, validationClass, message) {
  const items = document.querySelectorAll(`#${element.id} .${validationClass}`);
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    element.classList.add("validationError");
    element.classList.add("placeholder:text-[#FE9A9A]");
  }
  document.querySelector(`#${element.id} .errorMessage`).innerText = message;
}

function clearError(element, validationClass) {
  const items = document.querySelectorAll(`#${element.id} .${validationClass}`);
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    element.classList.remove("validationError");
    element.classList.remove("placeholder:text-[#FE9A9A]");
  }
  document.querySelector(`#${element.id} .errorMessage`).innerText = ``;
}

function CheckIfValueExists(input) {
  if (input?.value.length === 0) {
    return { error: true, message: messageType.required };
  } else {
    return { error: false };
  }
}
