import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { animatedComponents, colorStyles } from "../Utilities/SelectAnimation";
import { convertStringToObject } from "./ParentPackageSelected";
export default function SpotFee({
  setMarchantCreateDatas,
  setPackageSelect,
  marchantTourCreateDatas,
}) {
  const [getTours, setGetTours] = useState();
  const { id } = useParams();
  const a = {
    spot_entries: [
      {
        activity_id: 1,
        remark: "FOR_ADULT",
        spot_entry_price_per_person: 500,
      },
    ],
  };
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [spotEntity, setSpotEntity] = useState({
    activity_id: "",
    remark: "FOR_ADULT",
    spot_entry_price_per_person: 0,
  });
  const handleSpotEntity = () => {
    if (
      spotEntity?.spot_entry_price_per_person > 0 &&
      spotEntity?.activity_id?.id > 0
    ) {
      setMarchantCreateDatas((prevPackage) => ({
        ...prevPackage,
        spot_entries: [...prevPackage.spot_entries, spotEntity],
      }));

      setSpotEntity({
        activity_id: 0,
        spot_entry_price_per_person: 0,
        remark: "FOR_ADULT",
      });
    }
  };
  const handleDeleteSpot = (ind) => {
    const newArray = marchantTourCreateDatas?.spot_entries?.filter(
      (meal, index) => index !== ind
    );
    setMarchantCreateDatas((prevPackage) => ({
      ...prevPackage,
      spot_entries: newArray,
    }));
  };
  const activityVariable = getTours?.tour_itinerary?.map((activity) => ({
    label: activity.tour_activity_name,
    id: activity.tour_activity_id,
  }));
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);
  const switchStyles = {
    display: "flex",
    alignItems: "center",
  };

  const sliderStyles = {
    position: "relative",
    display: "inline-block",
    width: "60px",
    height: "34px",

    borderRadius: "34px",
    cursor: "pointer",
    transition: "0.4s",
  };
  const handleSubmitSpot = () => {
    setPackageSelect(null);
  };
  return (
    <div className="">
      <div className="my-[10px] ">
        <button
          onClick={() => setPackageSelect(null)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
      </div>
      <h2 className="text-center">Spot Fee</h2>
      <div className="bg-bg-white w-full  py-[6px] flex justify-between mx-auto gap-x-[40px] items-start px-[12px]">
        <div className="w-full">
          <div className=" py-[8px] px-[12px]">
            <div className="w-full flex gap-x-[20px] items-center flex-col">
              <div className="w-full ">
                <div>
                  <p className="text-txt-primary text-[12px]">
                    Activity Name:{" "}
                  </p>
                </div>
                <div className="rounded">
                  <Select
                    value={spotEntity?.activity_id}
                    onChange={(activity) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        activity_id: activity,
                      }))
                    }
                    components={animatedComponents}
                    options={activityVariable}
                    placeholder={"Activity Name "}
                    styles={colorStyles}
                  />
                </div>
              </div>
              <div className="w-full mt-[10px]">
                <p className="text-[12px]">Spot fee :</p>
                <div>
                  <input
                    type="text"
                    className="border border-btn-secondary w-full h-full py-[4px] px-[12px] rounded-[4px]"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    value={spotEntity.spot_entry_price_per_person}
                    placeholder="entry fee"
                  />
                </div>
              </div>
              <div className="w-full mt-[10px]">
                <p className="text-[12px]">Remark :</p>
                <div>
                  <input
                    type="text"
                    className="border border-btn-secondary w-full h-full py-[4px] px-[12px] rounded-[4px]"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    //   value={spotEntity.spot_entry_price_per_person}
                    placeholder="Remark"
                  />
                </div>
              </div>
            </div>

            {/* <div className="w-full justify-end flex">
            <div style={switchStyles}>
              <label
                style={sliderStyles}
                className={`${
                  spotEntity.tour_package_option_is_active
                    ? "bg-btn-primary"
                    : "bg-btn-orange"
                } `}
              >
                <input
                  type="checkbox"
                  checked={true}
                  onClick={() =>
                    setSpotEntity((prev) => ({
                      ...prev,
                      tour_package_option_is_active:
                        !prev.tour_package_option_is_active,
                    }))
                  }
                  style={{ display: "none" }}
                />
                <span
                  style={{
                    transform: `translateX(${
                      spotEntity.tour_package_option_is_active ? "26px" : "0"
                    })`,
                    transition: "transform 0.3s ease",
                    content: '""',
                    position: "absolute",
                    height: "26px",
                    width: "26px",
                    left: "4px",
                    bottom: "4px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                ></span>
              </label>
            </div>
          </div> */}
          </div>
          <div className="flex justify-center">
            <button
              className="text-bg-white h-[29px] rounded-[4px] px-[6px] bg-btn-secondary"
              onClick={() => handleSpotEntity()}
            >
              Add Spot Fee
            </button>
          </div>
        </div>
        <div className="w-full flex justify-end ">
          <div>
            <div className="px-[12px] flex justify-start gap-x-[12px] ">
              {marchantTourCreateDatas?.spot_entries?.map((spot, index) => (
                <div
                  key={index}
                  className="shadow-customShadow py-[10px] px-[20px] rounded-[4px] relative"
                >
                  <p className="text-[22px]">
                    <span className="text-[16px]"> Spot :</span>{" "}
                    {spot?.activity_id?.label}
                  </p>
                  <p>Fee : {spot?.spot_entry_price_per_person}</p>
                  <span
                    onClick={() => handleDeleteSpot(index)}
                    className="border cursor-pointer w-[20px] h-[20px] border-btn-orange rounded-full absolute right-[-10px] top-[-4px]"
                  >
                    <span className="flex justify-center items-center h-full">
                      X
                    </span>
                  </span>
                </div>
              ))}
            </div>
            <button
              onClick={() => handleSubmitSpot()}
              className="mt-[30px] w-full bg-btn-secondary py-[6px] rounded-[4px] text-bg-white"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
