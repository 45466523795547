import React, { useState } from "react";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";
import FoodDraggableItem from "./SectionDraggable/FoodDraggableItem";
import AccommodationDraggableItem from "./SectionDraggable/AccommodationDraggableItem";
import TransferDraggableItem from "./SectionDraggable/TransferDraggableItem";
import TransportationDraggableItem from "./SectionDraggable/TransportationDraggableItem";
import GuideDraggableItem from "./SectionDraggable/GuideDraggableItem";
import SpotFeeDraggableItem from "./SectionDraggable/SpotFeeDraggableItem";

const TourPackageSelectedRightItem = ({
  allTourSchedulePackage,
  setAllTourSchedulePackage,
  setTourSchedulePackage,
  tourSchedulePackage,
  itemListNew,
  selectedNewListItem,
  setIsDragging,
  setRequestToPayload,
  requestToPayload,
  handleToggleClick,
  setChangePage,
  setResponseData,
}) => {
  const iconObject = {
    xIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
      >
        <path
          d="M10.333 1.23576L9.09725 0L5.33301 3.76424L1.56877 0L0.333008 1.23576L4.09725 5L0.333008 8.76424L1.56877 10L5.33301 6.23576L9.09725 10L10.333 8.76424L6.56877 5L10.333 1.23576Z"
          fill="#879FA8"
        />
      </svg>
    ),
  };

  const handleDeleteDate = (indexToRemove) => {
    

    if(tourSchedulePackage?.dateSchedule?.length === 1){
      setTourSchedulePackage((prev) => ({
        ...prev,
        dateSchedule: prev.dateSchedule.filter((_, index) => index !== indexToRemove),
        firstTimeAdded: false,
        initialBookableSeat: "",
        initialTotalSeat: "",
        packageType: {},
        tourDateIndex: -1,
      }));
    }
    else{
      setTourSchedulePackage((prev) => ({
        ...prev,
        dateSchedule: prev.dateSchedule.filter((_, index) => index !== indexToRemove),
        tourDateIndex: indexToRemove - 1,
        // initialBookableSeat: "",
        // initialTotalSeat: "",
      }));
    }
  };

  const rendersLabels = () => {
    const hasToggleTrue = itemListNew?.some((item) => item?.toggle);

    return (
      <div className="">
        {itemListNew?.map((item, index) => (
          <div key={index} className="">
            {item?.toggle ? (
              <span className="text-tour-text text-[18px] font-medium">
                {item?.label}
              </span>
            ) : (
              <span></span>
            )}
          </div>
        ))}
        {!hasToggleTrue && (
          <span className="text-tour-text text-[18px] font-medium">
            Tour Constructor
          </span>
        )}
      </div>
    );
  };

  const findValue = () => {
    itemListNew?.find((item) => item?.toggle);
  };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const item = event.dataTransfer.getData("item")

  //   if(item){
  //       setDraggedList([...draggedList, item])
  //       setIsDragging(false)
  //   }
  // }


  const onHandleChangeSeat = (index) => {
    if(tourSchedulePackage?.tourDateIndex === index){
      setTourSchedulePackage((prev) => ({
        ...prev,
        tourDateIndex: -1
      }))
    }
    else{
      setTourSchedulePackage((prev) => ({
        ...prev,
        tourDateIndex: index,
        initialTotalSeat: tourSchedulePackage?.dateSchedule[index]?.total_seat,
        initialBookableSeat: tourSchedulePackage?.dateSchedule[index]?.bookable_seat
      }))
    }
    
  };


  return (
    <div className="flex flex-col gap-[12px]">
      {/* <p className="text-tour-text text-[18px] font-medium">Tour Schedule</p> */}
      <div>{rendersLabels()}</div>
      {/* If No Data Found */}
      {selectedNewListItem?.name?.length === 0 && (
        <div className="h-[52px] p-[16px] rounded-[8px] border-[1px] border-tour-input">
          <p className="text-[14px] text-tour-text font-normal">
            Start adding Items on the left menu
          </p>
        </div>
      )}
      {/* Tour Schedule Package */}
      <div>
        {selectedNewListItem?.name === "Tour Schedule" &&
          selectedNewListItem?.toggle === true &&
          (tourSchedulePackage?.packageType !== undefined &&
            tourSchedulePackage?.packageType?.label?.length > 0 && tourSchedulePackage?.firstTimeAdded === true) && (
            // tourSchedulePackage?.packageType?.length > 0 &&
            // tourSchedulePackage?.totalSeat?.length > 0 &&
            // tourSchedulePackage?.bookableSeat?.length > 0 &&
            <div className="w-full flex flex-col gap-y-[24px] h-[780px] overflow-auto ">
              {
                <div className="">
                  <div className="border-[1px] border-tour-secondaryHint rounded-[12px]">
                    <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {" "}
                      {tourSchedulePackage?.packageType?.label}
                    </p>
                    <div className="flex flex-col gap-[37px] px-[12px] py-[16px]">
                      <div className="flex flex-col gap-[16px]">
                        <div className="flex flex-col gap-[8px] text-[14px] font-normal">
                          <p className="text-tour-text">
                            {/* {selectedNewListItem?.name} :{" "}
                             */}
                            {"Tour Dates Options: "}
                          </p>
                          <div className="grid grid-cols-2 gap-[8px]">
                            {tourSchedulePackage?.dateSchedule?.map(
                              (item, index) => (
                                
                                  
                                  <div key={index} className="text-[12px] text-tour-text">
                                    <div className="flex justify-between">
                                      <div className={`duration-300 hover:text-tour-primary cursor-pointer ${tourSchedulePackage?.tourDateIndex === index ? "text-tour-primary" : ""}`}
                                      onClick={() => onHandleChangeSeat(index)}
                                      >
                                        <span className="">
                                          <span>{`${ item?.start_date} - `}</span>

                                          <span>{`${item?.end_date}`}</span>
                                        </span>
                                      </div>
                                      <div
                                        className="p-[4px] cursor-pointer "
                                        // onClick={() => handleDeleteDate(ind)}
                                        onClick={() => handleDeleteDate(index)}
                                      >
                                        {iconObject?.xIcon}
                                      </div>
                                    </div>
                                  </div>
                               
                              )
                            )}
                            {/* {requestToPayload?.tour_start_date?.map(
                              (itm, ind) => (
                                // <div
                                //   key={ind}
                                //   className="flex flex-wrap justify-between items-start text-[11.5px] text-tour-secondary border-[1px] border-tour-input p-[4px] pl-[6px] rounded-[8px] h-[42px] w-[102.33334px]"

                                // >
                                <div
                                  key={ind}
                                  className="flex justify-between items-start text-[11.5px] text-tour-secondary border-[1px] border-tour-input p-[4px] pl-[6px] rounded-[8px] h-[42px] w-[102.33334px]"
                                >
                                  <div className="">
                                    <span className="">
                                      <span>{`${itm[0]} -`}</span>
                                      <br />
                                      <span>{`${itm[1]}`}</span>
                                    </span>
                                  </div>
                                  <div
                                    className="p-[4px] cursor-pointer"
                                    onClick={() => handleDeleteDate(ind)}
                                  >
                                    {iconObject?.xIcon}
                                  </div>
                                </div>
                              )
                            )} */}
                          </div>
                        </div>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Total Seat: </span>
                          <span className="text-tour-secondary">
                            {tourSchedulePackage?.tourDateIndex >=0 ? tourSchedulePackage?.dateSchedule[tourSchedulePackage?.tourDateIndex]?.total_seat : tourSchedulePackage?.initialTotalSeat}
                          </span>
                        </p>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Bookable Seat: </span>
                          <span className="text-tour-secondary">
                            {tourSchedulePackage?.tourDateIndex >=0 ? tourSchedulePackage?.dateSchedule[tourSchedulePackage?.tourDateIndex]?.bookable_seat : tourSchedulePackage?.initialBookableSeat}
                          </span>
                        </p>
                      </div>
                      {/* <div className="flex gap-[16px]">
                      <button
                        //   onClick={() => handleButtonPackage(food)}
                        className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                      >
                        Edit
                      </button>
                      <button
                        // onClick={() => handleButtonPackage(food)}
                        className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                      >
                        Duplicate
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              }
            </div>
          )}
      </div>
      {/* Food Package */}
      {selectedNewListItem?.name === "Food" && (
        <FoodDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
        ></FoodDraggableItem>
      )}
      {/* accommodation */}
      {selectedNewListItem?.name === "Accommodation" && (
        <AccommodationDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
        ></AccommodationDraggableItem>
      )}
      {/* transfer */}
      {selectedNewListItem?.name === "Transfer" && (
        <TransferDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
        ></TransferDraggableItem>
      )}
      {/* Transportation */}
      {selectedNewListItem?.name === "Transportation" && (
        <TransportationDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
        ></TransportationDraggableItem>
      )}
      {/* Guide */}
      {selectedNewListItem?.name === "Guide" && (
        <GuideDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
        ></GuideDraggableItem>
      )}
      {/* Spot Fee */}
      {selectedNewListItem?.name === "Spot-Fee" && (
        <SpotFeeDraggableItem
          selectedNewListItem={selectedNewListItem}
          setIsDragging={setIsDragging}
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          handleToggleClick={handleToggleClick}
          setChangePage={setChangePage}
          setResponseData={setResponseData}
          setTourSchedulePackage={setTourSchedulePackage}
          tourSchedulePackage={tourSchedulePackage}
        ></SpotFeeDraggableItem>
      )}
    </div>
  );
};

export default TourPackageSelectedRightItem;
