import { useEffect, useState } from "react";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourFoodItemName() {
  const [mealType, setMealType] = useState([]);
  const [mealTypeName, setMealTypeName] = useState({
    food_item_name: "",
  });
  const [getFood, setGetFood] = useState([]);
  const [modalShown, setToggleModal] = useState(false);
  const handleMeal = () => {
    setMealType([...mealType, mealTypeName]);
    setMealTypeName({ food_item_name: "" });
    setToggleModal(false);
  };

  const handleMealDelete = (ind) => {
    const newArray = mealType?.filter((meal, index) => index !== ind);
    setMealType(newArray);
  };

  const handleMealAdd = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_TOUR_FOOD_POST_API}`, {
        food_items: mealType,
        requestId: "string",
      })
      .then((response) => {
        if (response.status === 201) {
          fetchFood();
          setMealType([]);
        }
      });
  };
  const fetchFood = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_FETCH_TOUR_FOOD_LIST_GET_API}`)
      .then((response) => setGetFood(response?.data?.food_items));
  };
  useEffect(() => {
    fetchFood();
  }, []);
  return (
    <div className="mt-[20px]">
      <h2 className="text-[22px] text-center underline">Food Name</h2>
      <div className="flex justify-between gap-x-[40px] max-w-[1100px] mx-auto">
        <div className="w-full mx-auto h-[200px] mt-[30px] shadow-customShadow rounded-[8px] p-[12px] max-w-[450px]">
          <div className="flex flex-col gap-y-[10px] ">
            {mealType?.map((meal, index) => (
              <div
                key={index}
                className="flex justify-between gap-x-[20px] relative  border rounded-[8px] border-txt-hint text-txt-primary p-[4px]"
              >
                {" "}
                <p className="flex gap-x-[5px] whitespace-wrap">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                      />
                    </svg>
                  </span>{" "}
                  {meal?.food_item_name}
                </p>
                <span
                  onClick={() => handleMealDelete(index)}
                  className=" cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>
          {mealType?.length > 0 && (
            <div className="flex justify-end w-full">
              <button
                onClick={() => setToggleModal(true)}
                className="w-[150px] bg-btn-secondary h-[30px] mt-[10px] text-bg-white rounded-[8px] "
              >
                Add New
              </button>
            </div>
          )}
          {mealType?.length > 0 ? (
            <button
              onClick={() => handleMealAdd()}
              className="h-[40px] bg-btn-secondary w-full mt-[40px] text-bg-white rounded-[8px]"
            >
              Submit Meal
            </button>
          ) : (
            <div>
              <div className="mt-[10px]">
                <label className="custom-field one w-full">
                  <input
                    onChange={(e) =>
                      setMealTypeName((prev) => ({
                        ...prev,
                        food_item_name: e.target.value,
                      }))
                    }
                    className="input w-full"
                    type="text"
                    placeholder=" "
                    value={mealTypeName.food_item_name}
                  />
                  <span className="placeholder">Food Name :</span>
                </label>
              </div>
              <button
                onClick={() => handleMeal()}
                disabled={mealTypeName?.food_item_name?.length === 0}
                className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
              >
                Submit
              </button>
            </div>
          )}
          <ModalCommon
            shown={modalShown}
            width="max-w-[450px]"
            outSideOff={true}
            height="min-h-[200px]"
            close={() => {
              setToggleModal(false);
            }}
          >
            <div className="p-[30px]">
              <div>
                <div className="mt-[10px]">
                  <label className="custom-field one w-full">
                    <input
                      onChange={(e) =>
                        setMealTypeName((prev) => ({
                          ...prev,
                          food_item_name: e.target.value,
                        }))
                      }
                      className="input w-full"
                      type="text"
                      placeholder=" "
                      value={mealTypeName.food_item_name}
                    />
                    <span className="placeholder">Food Name :</span>
                  </label>
                </div>
                <button
                  onClick={() => handleMeal()}
                  disabled={mealTypeName?.food_item_name?.length === 0}
                  className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
                >
                  Submit
                </button>
              </div>
            </div>
          </ModalCommon>
        </div>
        <div
          className={`w-full max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] ${
            getFood?.length === 0 ? "hidden" : "block"
          }`}
        >
          <h2 className="text-[14px] text-center underline">Food List</h2>
          {getFood?.map((food, index) => (
            <div key={index}>
              <p className="text-[14px]">
                <span className="text-[12px]">Food Item : </span>{" "}
                {food?.food_item_name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
