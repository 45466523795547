import React from "react";

const ParentPackageAccomondationCard = ({ accommondation }) => {
  //   console.log("accommondation", accommondation);

  const {
    tour_package_accommodation_name,
    tour_package_room_category_name,
    tour_package_room_type_name,
    suitable_for_persons,
    bed_configuration,
    bed_count,
    is_room_shareable,
    per_night_room_price,
  } = accommondation;

  return (
    <div className="bg-bg-white py-[8px] px-[8px] shadow-customShadow rounded-[2px]">
      <div className="mb-[4px]">
        <p className="text-[11px] text-center bg-[#e2e8f0] text-btn-secondary">
          {tour_package_accommodation_name}-{tour_package_room_category_name}
        </p>
      </div>
      <div className="p-[4px] ring-[1px] rounded-[2px]">
        <div className="w-full flex-wrap">
          <p className="md:text-[12px] ">
            <span className="text-bg-semiblack text-[10px]">Shareable: </span>
            {is_room_shareable ? "Yes" : "No"}
          </p>
          <p className="md:text-[12px]">
            <span className="text-bg-semiblack">Room Type: </span>
            {tour_package_room_type_name}
          </p>
        </div>

        <p className="md:text-[12px] ">
          <span className="text-bg-semiblack">Price: </span>
          {per_night_room_price} ৳
        </p>
        <p className="md:text-[12px] ">
          {`${bed_count} ${bed_configuration} bed suitable for ${suitable_for_persons} person`}
        </p>
      </div>
    </div>
  );
};

export default ParentPackageAccomondationCard;
