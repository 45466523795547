import React, { Fragment, useEffect, useState } from "react";
import TourCard from "../../TourCard";
import { useLocation, useNavigate } from "react-router";
import TourPackageSelections from "../TourPackageSelections";
import TourPackageSelectedItem from "./TourPackageSelectedItem";
import TourPackageSelectedRightItem from "./TourPackageSelectedRightItem";
import { authAxios } from "../../../utils/axiosWrapper";
import { MERCHANT_TOUR_ENDPOINT, baseUrl } from "../../../contants/Endpoints";
import AvailibilitySetTable from "../PackageV2/AvailibilitySetTable";
import { merchantTourRoute } from "../../../contants/Constants";
import { toast } from "react-toastify";

const TourPackageAvailability = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.pathname);
  const subscribeId = parseInt(queryParams.get("subscribeId"));
  const [availablePackage, setAvailablePackage] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNewListItem, setSelectedNewListItem] = useState("");
  const [packageFoodList, setFoodPackageList] = useState([]);
  const [packageAccommodationList, setPackageAccommodationList] = useState([]);
  const [packageTransferList, setPackageTransferList] = useState([]);
  const [packageTransportationList, setPackageTransportationList] = useState(
    []
  );
  const [packageGuideList, setPackageGuideList] = useState([]);
  const [packageSpotEntryList, setPackageSpotEntryList] = useState([]);
  const [changePage, setChangePage] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [tourSchedulePackage, setTourSchedulePackage] = useState({
    packageType: {},
    initialTotalSeat: "",
    initialBookableSeat: "",
    dateSchedule: [],
    tourDateIndex: -1,
    firstTimeAdded: false,
  });

  const [dragging, setIsDragging] = useState(false);

  const [allTourSchedulePackage, setAllTourSchedulePackage] = useState({
    Dates: [],
    packageTypes: [],
    totalSeat: "",
    bookableSeat: "",
  });

  const [requestToPayload, setRequestToPayload] = useState({
    bookable_seats: "",
    total_seats: "",
    tour_package_accommodation_is_inclusive: true,
    tour_package_accommodation_options: [],
    tour_package_food_is_inclusive: true,
    tour_package_food_options: [],
    tour_package_guide_is_inclusive: true,
    tour_package_guide_options: [],
    tour_package_spot_entry_is_inclusive: true,
    tour_package_spot_entry_options: [],
    tour_package_transfer_is_inclusive: true,
    tour_package_transfer_options: [],
    tour_package_transportation_packages: [],
    tour_package_type_id: "",
    tour_start_date: "",
  });

  const [itemListNew, setItemListNew] = useState([
    {
      label: "Tour Schedule",
      route: "add-food",
      toggle: true,
    },
    {
      label: "Food",
      route: "add-food",
      toggle: false,
    },
    {
      label: "Accommodation",
      route: "add-accommodation",
      toggle: false,
    },
    {
      label: "Transfer",
      route: "add-transfer",
      toggle: false,
    },
    {
      label: "Transportation",
      route: "add-transportation",
      toggle: false,
    },
    {
      label: "Guide",
      route: "add-guide",
      toggle: false,
    },
    {
      label: "Spot-Fee",
      route: "add-spot-fee",
      toggle: false,
    },
  ]);

  const iconObject = {
    dropRight: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M6.75 15L12.75 9L6.75 3"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dropDown: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M3 6.75L9 12.75L15 6.75"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccommodation = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_GET_API}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageAccommodationList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransferPackage = () => {
    // merchant/tour/transfer/package/get/all/by/7?requestId=123
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSFER_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageTransferList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE}${subscribeId}?requestId=10`
      )
      .then((res) => {
        setPackageTransportationList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageGuideList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpotEntry = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageSpotEntryList(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      selectedNewListItem?.name === "Food" &&
      selectedNewListItem?.toggle === true
    ) {
      getMealPackage();
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Accommodation" &&
      selectedNewListItem?.toggle === true
    ) {
      getAccommodation();
      setFoodPackageList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Transfer" &&
      selectedNewListItem?.toggle === true
    ) {
      getTransferPackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Transportation" &&
      selectedNewListItem?.toggle === true
    ) {
      getTransportationPackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Guide" &&
      selectedNewListItem?.toggle === true
    ) {
      getGuidePackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Spot-Fee" &&
      selectedNewListItem?.toggle === true
    ) {
      getSpotEntry();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
    } else {
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    }
  }, [selectedNewListItem?.name]);

  const getAvailabilityPackageTableData = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_AVAILABLE_PACKAGE_DATA}${subscribeId}?requestId=package_data`
      )
      .then((res) => {
        setAvailablePackage(res?.data?.datePackageMap);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAvailabilityPackageTableData();
  }, []);

  const handleToggleClick = (index) => {
    setItemListNew((prev) =>
      prev.map((item, i) => ({
        ...item,
        toggle: i === index ? !item.toggle : false,
      }))
    );
  };

  useEffect(() => {
    const selectedItem = itemListNew.find((item) => item.toggle);
    setSelectedNewListItem({
      name: selectedItem ? selectedItem.label : "",
      toggle: selectedItem ? selectedItem.toggle : false,
    });
  }, [itemListNew]);

  // const handleAbibilityPage = () => {
  //   navigate(
  //     `${merchantTourRoute.MARCHANT_SET_ABIBILITY_V2}tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}&number_of_days=${getTours.number_of_days}&number_of_nights=${getTours.number_of_nights}`
  //   );
  // };

  const handleDisabledDateSchedule = (available_tour_package_id) => {
    authAxios
      .put(
        `${baseUrl}${MERCHANT_TOUR_ENDPOINT.MERCHANT_PUT_TOUR_PACKAGE_AVAILABILITY_DE_ACTIVATE_BY_AVAILABILITY_TOUR_PACKAGE_ID}?available-tour-package-id=${available_tour_package_id}&requestId=1234`
      )
      .then((res) => {
        // console.log(res)
        getAvailabilityPackageTableData();
        toast.success("Successfully disabled this date.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Disabled failed!");
      });
  };

  return (
    <div>
      {/* New Design Start */}
      <div className="w-full flex gap-[32px] mb-[120px]">
        {changePage ? (
          <AvailibilitySetTable
            responseData={responseData}
          ></AvailibilitySetTable>
        ) : (
          <>
            {/* Section 1 */}
            <div className="w-[304px]">
              {location?.state && (
                <TourCard
                  tours={location?.state}
                  subscribeButton={false}
                ></TourCard>
              )}
            </div>
            {/* Section 2 */}
            <div className="w-[357px]">
              <p className="text-tour-text text-[18px] font-medium pb-[24px]">
                Amenities{" "}
              </p>
              <div>
                <ul>
                  {itemListNew?.map((item, index) => (
                    <Fragment key={index}>
                      <li
                        key={index}
                        onClick={() => handleToggleClick(index)}
                        className={`border-l-[2px] ${
                          selectedNewListItem?.name === item?.label &&
                          selectedNewListItem?.toggle === item?.toggle
                            ? "border-tour-primary bg-[#F5F9FA]"
                            : "border-tour-input "
                        }`}
                      >
                        <p
                          className={`py-[9px] pl-[16px] pr-[8px] cursor-pointer text-[16px] text-tour-text`}
                        >
                          {item?.label}
                        </p>
                      </li>
                      <div className="border-l-[2px] border-tour-input">
                        {selectedNewListItem?.name === item?.label &&
                          selectedNewListItem?.toggle === true && (
                            <div>
                              <TourPackageSelectedItem
                                itemListNew={itemListNew}
                                selectedNewListItem={selectedNewListItem}
                                setAllTourSchedulePackage={
                                  setAllTourSchedulePackage
                                }
                                allTourSchedulePackage={allTourSchedulePackage}
                                packageFoodList={packageFoodList}
                                packageAccommodationList={
                                  packageAccommodationList
                                }
                                packageTransferList={packageTransferList}
                                packageTransportationList={
                                  packageTransportationList
                                }
                                packageGuideList={packageGuideList}
                                packageSpotEntryList={packageSpotEntryList}
                                setIsDragging={setIsDragging}
                                setRequestToPayload={setRequestToPayload}
                                handleToggleClick={handleToggleClick}
                                setTourSchedulePackage={setTourSchedulePackage}
                                tourSchedulePackage={tourSchedulePackage}
                              ></TourPackageSelectedItem>
                            </div>
                          )}
                      </div>
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>
            {/* Section 3 */}
            <div className="w-[347px]">
              <TourPackageSelectedRightItem
                allTourSchedulePackage={allTourSchedulePackage}
                setAllTourSchedulePackage={setAllTourSchedulePackage}
                itemListNew={itemListNew}
                setSelectedNewListItem={setSelectedNewListItem}
                selectedNewListItem={selectedNewListItem}
                setIsDragging={setIsDragging}
                setRequestToPayload={setRequestToPayload}
                requestToPayload={requestToPayload}
                handleToggleClick={handleToggleClick}
                setChangePage={setChangePage}
                setResponseData={setResponseData}
                setTourSchedulePackage={setTourSchedulePackage}
                tourSchedulePackage={tourSchedulePackage}
              ></TourPackageSelectedRightItem>
            </div>
            {/* <div>
        <TourPackageSelections
          isLoading={isLoading}
          setEditAblePackage={setEditAblePackage}
          selectedNewListItem={selectedNewListItem}
          setSelectedNewListItem={setSelectedNewListItem}
          setItemListNew={setItemListNew}
          getTours={getTours}
        ></TourPackageSelections>
      </div> */}
          </>
        )}
      </div>
      {/* New Design End */}

      <div>
        {Object.keys(availablePackage).length > 0 && (
          <p className="my-[12px] text-tour-primary text-[20px] text-center">
            Available Schedule
          </p>
        )}

        <div className="flex flex-col bg-bg-white shadow-customTourCard">
          {Object.keys(availablePackage)?.map((key, ind) => (
            <div key={ind} className="flex flex-row gap-y-[30px] w-full border-[1px] border-tour-secondaryHint ">
              <p className="border-r border-tour-secondaryHint flex justify-center items-center px-[30px] whitespace-nowrap">
                {key}
              </p>

              <div className="flex flex-col w-full">
                {availablePackage[key]?.map((valueData, index) => (
                  <div
                    key={index}
                    className={`${
                      index % 2 !== 0
                        ? "border-b border-tour-secondaryShade"
                        : "border-none"
                    }  p-[8px] flex justify-between items-center`}
                  >
                    <p>{valueData?.tour_package_type_name}</p>

                    {/* <div>
                          {valueData?.available_tour_package_id !== null ? (
                            <button
                              onClick={() =>
                                navigate(
                                  `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}packageId=${valueData?.available_tour_package_id}&date=${key}`
                                )
                              }
                            >
                              view
                            </button>
                          ) : (
                            <button 
                            // onClick={() => handleAbibilityPage()}
                            >
                              +
                            </button>
                          )}
                        </div> */}

                    <div className="w-[260px] flex gap-[16px] ">
                      <button
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}packageId=${valueData?.available_tour_package_id}&date=${key}`
                          )
                        }
                        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                      >
                        View
                      </button>
                      <button
                        onClick={() =>
                          handleDisabledDateSchedule(
                            valueData?.available_tour_package_id
                          )
                        }
                        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                      >
                        Disable
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourPackageAvailability;
