import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Calender from "../../../components/Calender";
import { merchantTourRoute } from "../../../contants/Constants";
import { baseUrl, MERCHANT_TOUR_ENDPOINT } from "../../../contants/Endpoints";
import SteperRaper from "../../../excursionMarchant/Steper/Steper";
import { authAxios } from "../../../utils/axiosWrapper";
import { convertStringToObject } from "../ParentPackageSelected";
import AvailabilityPackageCreate from "./AvailabilityPackageCreate";
import AvailibilitySetTable from "./AvailibilitySetTable";
import DragAndDrop from "./DragItem";

export const generateDates = (startDate, incrementValue) => {
  const startDateObj = new Date(startDate);

  if (isNaN(startDateObj.getTime())) {
  } else {
    const datesArray = [];

    for (let i = 0; i < incrementValue; i++) {
      const newDate = new Date(startDateObj);
      newDate.setDate(startDateObj.getDate() + i);
      datesArray.push(newDate.toISOString().split("T")[0]);
    }
    return datesArray;
  }
};
const AbibilitySetV2 = () => {
  const [requestToPayloadMultiple, setRequestToPayloadMultiple] = useState({
    available_tour_packages: [],
    requestId: "string",
    subscribed_tour_id: 2,
  });
  const naviagte = useNavigate();
  const [getGetMultipleDate, setGetMultipleDate] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [requestToPayload, setRequestToPayload] = useState({
    bookable_seats: "",
    total_seats: "",
    tour_package_accommodation_is_inclusive: true,
    tour_package_accommodation_options: [],
    tour_package_food_is_inclusive: true,
    tour_package_food_options: [],
    tour_package_guide_is_inclusive: true,
    tour_package_guide_options: [],
    tour_package_spot_entry_is_inclusive: true,
    tour_package_spot_entry_options: [],
    tour_package_transfer_is_inclusive: true,
    tour_package_transfer_options: [],
    tour_package_transportation_packages: [],
    tour_package_type_id: "",
    tour_start_date: "",
  });
  console.log("requestToPayload", requestToPayload);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  //multiple date convert
  const extractNumericValue = (packageName) => {
    const numericPart = packageName.replace("package", ""); // Remove "package" prefix
    return parseInt(numericPart, 10); // Convert the numeric part to an integer
  };
  const convertTourPackageAccommodationData = (data) => {
    return data.map((packageGroup) => {
      return {
        tour_package_accommodation_packages: Object.keys(packageGroup).map(
          (packageKey, nightIndex) => {
            const {
              tour_package_accommodation_package_id,
              per_night_room_price,
            } = packageGroup[packageKey];

            return {
              night_number: extractNumericValue(packageKey),
              per_night_room_price,
              tour_package_accommodation_package_id,
            };
          }
        ),
      };
    });
  };

  const transformData = (originalData) => {
    const transformedData = originalData.map((tourPackage) => {
      return {
        available_tour_packages: getGetMultipleDate?.map((tourData, i) => {
          return {
            bookable_seats: parseInt(tourPackage.bookable_seats),
            total_seats: parseInt(tourPackage.total_seats),
            tour_package_accommodation_is_inclusive:
              tourPackage.tour_package_accommodation_is_inclusive,
            tour_package_accommodation_options:
              convertTourPackageAccommodationData(
                tourPackage?.tour_package_accommodation_options
              ),
            // tourPackage.tour_package_accommodation_options.map(
            //   (accommodationOption) => {
            //     return {
            //       tour_package_accommodation_packages:
            //         accommodationOption.package1.map(
            //           (accommodationPackage, index) => {
            //             return {
            //               night_number: index + 1,
            //               per_night_room_price:
            //                 accommodationPackage.per_night_room_price,
            //               tour_package_accommodation_package_id:
            //                 accommodationPackage.tour_package_accommodation_package_id,
            //             };
            //           }
            //         ),
            //     };
            //   }
            // ),
            tour_package_food_is_inclusive:
              tourPackage.tour_package_food_is_inclusive,
            tour_package_food_options: transformDataFromNewFood(
              tourPackage.tour_package_food_options
            ),

            // processArray(
            //   tourPackage.tour_package_food_options
            // ),
            // tourPackage.tour_package_food_options.map((foodOption, index) => {
            //   return {
            //     day_number: index + 1,
            //     tour_package_meal_type_wise_meal_packages: {
            //       [foodOption.day1[0].meal_type_id]: foodOption.day1.map(
            //         (mealPackage) => {
            //           return {
            //             per_meal_package_price:
            //               mealPackage.per_meal_package_price,
            //             tour_package_meal_package_id:
            //               mealPackage.meal_package_id,
            //           };
            //         }
            //       ),
            //     },
            //   };
            // }),
            tour_package_guide_is_inclusive:
              tourPackage.tour_package_guide_is_inclusive,
            tour_package_guide_options: transformGuideData(
              tourPackage.tour_package_guide_options
            ),
            // tourPackage.tour_package_guide_options.map((guideOption) => {
            //   return {
            //     tour_package_guide_packages: guideOption?.package1?.map(
            //       (guidePackage) => {
            //         return {
            //           day_number: guidePackage.day_number,
            //           tour_package_guide_package_id:
            //             guidePackage.tour_package_guide_package_id,
            //           tour_package_guide_package_price:
            //             guidePackage.total_guide_price_for_day,
            //         };
            //       }
            //     ),
            //   };
            // }),
            tour_package_spot_entry_is_inclusive:
              tourPackage.tour_package_spot_entry_is_inclusive,
            // tour_package_spot_entry_options:
            //   tourPackage.tour_package_spot_entry_options.map(
            //     (spotEntryOption) => {
            //       const tour_package_spot_entry_packages =
            //         spotEntryOption.package1.concat(spotEntryOption.package2); // Add more concats if needed
            //       return {
            //         tour_package_spot_entry_packages:
            //           tour_package_spot_entry_packages.map(
            //             (spotEntryPackage) => {
            //               return {
            //                 spot_entry_price_per_person:
            //                   spotEntryPackage.spot_entry_price_per_person,
            //                 tour_package_spot_entry_package_id:
            //                   spotEntryPackage?.spot_entry_package_id,
            //               };
            //             }
            //           ),
            //       };
            //     }
            //   ),
            tour_package_spot_entry_options: transformSpotFeeData(
              tourPackage.tour_package_spot_entry_options
            ),
            // tourPackage.tour_package_spot_entry_options.map(
            //   (spotEntryOption) => {
            //     const tour_package_spot_entry_packages = [];

            //     // Concatenate all package properties dynamically
            //     for (let i = 1; ; i++) {
            //       const packageProperty = `package${i}`;
            //       if (!spotEntryOption[packageProperty]) break;

            //       tour_package_spot_entry_packages.push(
            //         ...spotEntryOption[packageProperty]
            //       );
            //     }

            //     return {
            //       tour_package_spot_entry_packages:
            //         tour_package_spot_entry_packages.map(
            //           (spotEntryPackage) => {
            //             return {
            //               spot_entry_price_per_person:
            //                 spotEntryPackage.spot_entry_price_per_person,
            //               tour_package_spot_entry_package_id:
            //                 spotEntryPackage?.spot_entry_package_id,
            //             };
            //           }
            //         ),
            //     };
            //   }
            // ),
            // tour_package_spot_entry_options: [],
            tour_package_transfer_is_inclusive:
              tourPackage.tour_package_transfer_is_inclusive,
            // tour_package_transfer_options:
            //   tourPackage.tour_package_transfer_options.map(
            //     (transferOption) => {
            //       const tour_package_transfer_packages =
            //         transferOption.package1.concat(transferOption.package2);
            //       return {
            //         tour_package_transfer_packages:
            //           tour_package_transfer_packages.map((transferPackage) => {
            //             return {
            //               per_vehicle_per_trip_price:
            //                 transferPackage?.per_vehicle_per_trip_price,
            //               tour_package_transfer_package_id:
            //                 transferPackage?.tour_package_transfer_package_id,
            //             };
            //           }),
            //       };
            //     }
            //   ),
            tour_package_transfer_options: transformTransferData(
              tourPackage.tour_package_transfer_options
            ),
            // tourPackage.tour_package_transfer_options.map(
            //   (transferOption) => {
            //     const tour_package_transfer_packages = [];

            //     // Concatenate all package properties dynamically
            //     for (let i = 1; ; i++) {
            //       const packageProperty = `package${i}`;
            //       if (!transferOption[packageProperty]) break;

            //       tour_package_transfer_packages.push(
            //         ...transferOption[packageProperty]
            //       );
            //     }

            //     return {
            //       tour_package_transfer_packages:
            //         tour_package_transfer_packages.map((transferPackage) => {
            //           return {
            //             per_vehicle_per_trip_price:
            //               transferPackage?.per_vehicle_per_trip_price,
            //             tour_package_transfer_package_id:
            //               transferPackage?.tour_package_transfer_package_id,
            //           };
            //         }),
            //     };
            //   }
            // ),
            // tour_package_transportation_packages:
            //   tourPackage.tour_package_transportation_packages.map(
            //     (transportationPackage) => {
            //       return {
            //         tour_package_transportation_package_id:
            //           transportationPackage.package1[0]
            //             .tour_package_transportation_package_id,
            //         transportation_package_unit_price:
            //           transportationPackage.package1[0]
            //             .transportation_package_unit_price,
            //       };
            //     }
            //   ),
            tour_package_transportation_packages: transformTransportationData(
              tourPackage.tour_package_transportation_packages
            ),
            // tourPackage.tour_package_transportation_packages
            //   .map((transportationPackage) => {
            //     const tour_package_transportation_packages = [];

            //     // Concatenate all package properties dynamically
            //     for (let i = 1; ; i++) {
            //       const packageProperty = `package${i}`;
            //       if (!transportationPackage[packageProperty]) break;

            //       tour_package_transportation_packages.push(
            //         ...transportationPackage[packageProperty]
            //       );
            //     }

            //     return tour_package_transportation_packages.map(
            //       (packageItem) => ({
            //         tour_package_transportation_package_id:
            //           packageItem.tour_package_transportation_package_id,
            //         transportation_package_unit_price:
            //           packageItem.transportation_package_unit_price,
            //       })
            //     );
            //   })
            //   .flat(),
            tour_package_type_id: tourPackage.tour_package_type_id.value,
            tour_start_date: tourData,
          };
        }),
        requestId: "string",
        subscribed_tour_id: convertData.subscribeId,
      };
    });
    console.log("transformedData", transformedData);
    return transformedData;
  };

  /// main single date

  // const transformData = (originalData) => {
  //   const transformedData = originalData.map((tourPackage) => {
  //     return {
  //       available_tour_packages: [
  //         {
  //           bookable_seats: parseInt(tourPackage.bookable_seats),
  //           total_seats: parseInt(tourPackage.total_seats),
  //           tour_package_accommodation_is_inclusive:
  //             tourPackage.tour_package_accommodation_is_inclusive,
  //           tour_package_accommodation_options:
  //             tourPackage.tour_package_accommodation_options.map(
  //               (accommodationOption) => {
  //                 return {
  //                   tour_package_accommodation_packages:
  //                     accommodationOption.package1.map(
  //                       (accommodationPackage, index) => {
  //                         return {
  //                           night_number: index + 1,
  //                           per_night_room_price:
  //                             accommodationPackage.per_night_room_price,
  //                           tour_package_accommodation_package_id:
  //                             accommodationPackage.tour_package_accommodation_package_id,
  //                         };
  //                       }
  //                     ),
  //                 };
  //               }
  //             ),
  //           tour_package_food_is_inclusive:
  //             tourPackage.tour_package_food_is_inclusive,
  //           tour_package_food_options:
  //             tourPackage.tour_package_food_options.map((foodOption, index) => {
  //               return {
  //                 day_number: index + 1,
  //                 tour_package_meal_type_wise_meal_packages: {
  //                   [foodOption.day1[0].meal_type_id]: foodOption.day1.map(
  //                     (mealPackage) => {
  //                       return {
  //                         per_meal_package_price:
  //                           mealPackage.per_meal_package_price,
  //                         tour_package_meal_package_id:
  //                           mealPackage.meal_package_id,
  //                       };
  //                     }
  //                   ),
  //                 },
  //               };
  //             }),
  //           tour_package_guide_is_inclusive:
  //             tourPackage.tour_package_guide_is_inclusive,
  //           tour_package_guide_options:
  //             tourPackage.tour_package_guide_options.map((guideOption) => {
  //               return {
  //                 tour_package_guide_packages: guideOption.package1.map(
  //                   (guidePackage) => {
  //                     return {
  //                       day_number: guidePackage.day_number,
  //                       tour_package_guide_package_id:
  //                         guidePackage.tour_package_guide_package_id,
  //                       tour_package_guide_package_price:
  //                         guidePackage.total_guide_price_for_day,
  //                     };
  //                   }
  //                 ),
  //               };
  //             }),
  //           tour_package_spot_entry_is_inclusive:
  //             tourPackage.tour_package_spot_entry_is_inclusive,
  //           // tour_package_spot_entry_options:
  //           //   tourPackage.tour_package_spot_entry_options.map(
  //           //     (spotEntryOption) => {
  //           //       const tour_package_spot_entry_packages =
  //           //         spotEntryOption.package1.concat(spotEntryOption.package2); // Add more concats if needed
  //           //       return {
  //           //         tour_package_spot_entry_packages:
  //           //           tour_package_spot_entry_packages.map(
  //           //             (spotEntryPackage) => {
  //           //               return {
  //           //                 spot_entry_price_per_person:
  //           //                   spotEntryPackage.spot_entry_price_per_person,
  //           //                 tour_package_spot_entry_package_id:
  //           //                   spotEntryPackage?.spot_entry_package_id,
  //           //               };
  //           //             }
  //           //           ),
  //           //       };
  //           //     }
  //           //   ),
  //           tour_package_spot_entry_options:
  //             tourPackage.tour_package_spot_entry_options.map(
  //               (spotEntryOption) => {
  //                 const tour_package_spot_entry_packages = [];

  //                 // Concatenate all package properties dynamically
  //                 for (let i = 1; ; i++) {
  //                   const packageProperty = `package${i}`;
  //                   if (!spotEntryOption[packageProperty]) break;

  //                   tour_package_spot_entry_packages.push(
  //                     ...spotEntryOption[packageProperty]
  //                   );
  //                 }

  //                 return {
  //                   tour_package_spot_entry_packages:
  //                     tour_package_spot_entry_packages.map(
  //                       (spotEntryPackage) => {
  //                         return {
  //                           spot_entry_price_per_person:
  //                             spotEntryPackage.spot_entry_price_per_person,
  //                           tour_package_spot_entry_package_id:
  //                             spotEntryPackage?.spot_entry_package_id,
  //                         };
  //                       }
  //                     ),
  //                 };
  //               }
  //             ),
  //           // tour_package_spot_entry_options: [],
  //           tour_package_transfer_is_inclusive:
  //             tourPackage.tour_package_transfer_is_inclusive,
  //           // tour_package_transfer_options:
  //           //   tourPackage.tour_package_transfer_options.map(
  //           //     (transferOption) => {
  //           //       const tour_package_transfer_packages =
  //           //         transferOption.package1.concat(transferOption.package2);
  //           //       return {
  //           //         tour_package_transfer_packages:
  //           //           tour_package_transfer_packages.map((transferPackage) => {
  //           //             return {
  //           //               per_vehicle_per_trip_price:
  //           //                 transferPackage?.per_vehicle_per_trip_price,
  //           //               tour_package_transfer_package_id:
  //           //                 transferPackage?.tour_package_transfer_package_id,
  //           //             };
  //           //           }),
  //           //       };
  //           //     }
  //           //   ),
  //           tour_package_transfer_options:
  //             tourPackage.tour_package_transfer_options.map(
  //               (transferOption) => {
  //                 const tour_package_transfer_packages = [];

  //                 // Concatenate all package properties dynamically
  //                 for (let i = 1; ; i++) {
  //                   const packageProperty = `package${i}`;
  //                   if (!transferOption[packageProperty]) break;

  //                   tour_package_transfer_packages.push(
  //                     ...transferOption[packageProperty]
  //                   );
  //                 }

  //                 return {
  //                   tour_package_transfer_packages:
  //                     tour_package_transfer_packages.map((transferPackage) => {
  //                       return {
  //                         per_vehicle_per_trip_price:
  //                           transferPackage?.per_vehicle_per_trip_price,
  //                         tour_package_transfer_package_id:
  //                           transferPackage?.tour_package_transfer_package_id,
  //                       };
  //                     }),
  //                 };
  //               }
  //             ),
  //           // tour_package_transportation_packages:
  //           //   tourPackage.tour_package_transportation_packages.map(
  //           //     (transportationPackage) => {
  //           //       return {
  //           //         tour_package_transportation_package_id:
  //           //           transportationPackage.package1[0]
  //           //             .tour_package_transportation_package_id,
  //           //         transportation_package_unit_price:
  //           //           transportationPackage.package1[0]
  //           //             .transportation_package_unit_price,
  //           //       };
  //           //     }
  //           //   ),
  //           tour_package_transportation_packages:
  //             tourPackage.tour_package_transportation_packages
  //               .map((transportationPackage) => {
  //                 const tour_package_transportation_packages = [];

  //                 // Concatenate all package properties dynamically
  //                 for (let i = 1; ; i++) {
  //                   const packageProperty = `package${i}`;
  //                   if (!transportationPackage[packageProperty]) break;

  //                   tour_package_transportation_packages.push(
  //                     ...transportationPackage[packageProperty]
  //                   );
  //                 }

  //                 return tour_package_transportation_packages.map(
  //                   (packageItem) => ({
  //                     tour_package_transportation_package_id:
  //                       packageItem.tour_package_transportation_package_id,
  //                     transportation_package_unit_price:
  //                       packageItem.transportation_package_unit_price,
  //                   })
  //                 );
  //               })
  //               .flat(),
  //           tour_package_type_id: tourPackage.tour_package_type_id.value,
  //           tour_start_date: tourPackage.tour_start_date,
  //         },
  //       ],
  //       requestId: "string",
  //       subscribed_tour_id: convertData.subscribeId,
  //     };
  //   });

  //   return transformedData;
  // };

  const [pageChange, setPageChange] = useState(false);
  const [pageInd, setPageInd] = useState(false);

  const handlePageChange = () => {
    setPageChange(false);
  };

  console.log("pageChange", pageInd);

  const saveDataMultipleToTourPackage = () => {
    setRequestToPayloadMultiple((prev) => ({
      ...prev,
      available_tour_packages: [
        ...prev.available_tour_packages,
        requestToPayload,
      ],
    }));
    // setGetMultipleDate([]);
    setRequestToPayload({
      bookable_seats: "",
      total_seats: "",
      tour_package_accommodation_is_inclusive: true,
      tour_package_accommodation_options: [],
      tour_package_food_is_inclusive: true,
      tour_package_food_options: [],
      tour_package_guide_is_inclusive: true,
      tour_package_guide_options: [],
      tour_package_spot_entry_is_inclusive: true,
      tour_package_spot_entry_options: [],
      tour_package_transfer_is_inclusive: true,
      tour_package_transfer_options: [],
      tour_package_transportation_packages: [],
      tour_package_type_id: "",
      tour_start_date: "",
    });
  };

  const transformTransferData = (originalData) => {
    const transformedData = originalData.map((item) => {
      const tourPackageTransferPackages = item.package1.map((packageItem) => {
        return {
          tour_package_transfer_package_id:
            packageItem.tour_package_transfer_package_id,
          per_vehicle_per_trip_price: packageItem.per_vehicle_per_trip_price,
        };
      });

      return { tour_package_transfer_packages: tourPackageTransferPackages };
    });

    return transformedData;
  };

  const transformSpotFeeData = (originalData) => {
    const transformedData = originalData.map((item) => {
      const tourPackageSpotPackages = item.package1.map((packageItem) => {
        return {
          tour_package_spot_entry_package_id: packageItem.spot_entry_package_id,
          spot_entry_price_per_person: packageItem.spot_entry_price_per_person,
        };
      });

      return { tour_package_spot_entry_packages: tourPackageSpotPackages };
    });

    return transformedData;
  };

  const transformGuideData = (originalData) => {
    const transformedData = originalData.map((item) => {
      const tourPackageGuidePackages = item.package1.map((packageItem) => {
        return {
          day_number: packageItem.day_number,
          tour_package_guide_package_id:
            packageItem.tour_package_guide_package_id,
          tour_package_guide_package_price:
            packageItem.total_guide_price_for_day,
        };
      });

      return { tour_package_guide_packages: tourPackageGuidePackages };
    });

    return transformedData;
  };

  const transformTransportationData = (inputArray) => {
    const resultArray = inputArray.map((item) => {
      const { package1 } = item;
      const {
        tour_package_transportation_package_id,
        transportation_package_unit_price,
      } = package1;
      return {
        tour_package_transportation_package_id,
        transportation_package_unit_price,
      };
    });

    return resultArray;
  };

  // const transformTransportationData = (originalData) => {
  //   const transportationdData = originalData.map((item) => {
  //     const tourPackageTransferPackages = item.package1.map((packageItem) => {
  //       return {
  //         tour_package_transportation_package_id:
  //           packageItem.tour_package_transportation_package_id,
  //         transportation_package_unit_price:
  //           packageItem.transportation_package_unit_price,
  //       };
  //     });

  //     return tourPackageTransferPackages;
  //   });

  //   return transportationdData;
  // };
  const transformFoodData = (originalData) => {
    const transformedData = originalData.map((days) => {
      const combinedDays = Object.values(days).flatMap((day) =>
        day.map((meal) => ({
          ...meal,
          food_items: Object.values(meal.food_items),
        }))
      );

      // Check for duplicate meal_package_id
      const uniqueMeals = [];
      const transformedDays = combinedDays.filter((meal) => {
        if (!uniqueMeals.includes(meal.meal_package_id)) {
          uniqueMeals.push(meal.meal_package_id);
          return true;
        }
        return false;
      });

      return { ...days, [Object.keys(days)[0]]: transformedDays };
    });

    const result = [];
    for (const [dayKey, meals] of Object.entries(transformedData[0])) {
      const dayNumber = parseInt(dayKey.replace("day", ""));
      const dayObject = {
        day_number: dayNumber,
        tour_package_meal_type_wise_meal_packages: {},
      };

      meals.forEach((meal) => {
        const { meal_type_id, meal_package_id, per_meal_package_price } = meal;

        if (
          !dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id]
        ) {
          dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id] =
            [];
        }

        dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id].push({
          per_meal_package_price: per_meal_package_price,
          tour_package_meal_package_id: meal_package_id,
        });
      });

      result.push(dayObject);
    }

    return result;
  };

  // const processArray = (inputArray) => {
  //   const b = {
  //     day1: [],
  //     day2: [],
  //   };

  //   inputArray.forEach((day) => {
  //     // Process day1
  //     if (day.day1) {
  //       day.day1.forEach((meal) => {
  //         const existingIndex = b.day1.findIndex(
  //           (item) => item.meal_package_id === meal.meal_package_id
  //         );

  //         if (existingIndex === -1) {
  //           b.day1.push({ ...meal });
  //         } else {
  //           b.day1[existingIndex] = {
  //             // meal_package_id: meal.meal_package_id,
  //             // data: meal,
  //             // meal_type_id: meal.meal_type_id,\
  //             ...meal,
  //           };
  //         }
  //       });
  //     }

  //     // Process day2
  //     if (day.day2) {
  //       day.day2.forEach((meal) => {
  //         const existingIndex = b.day2.findIndex(
  //           (item) => item.meal_package_id === meal.meal_package_id
  //         );

  //         if (existingIndex === -1) {
  //           b.day2.push({ ...meal });
  //         } else {
  //           b.day2[existingIndex] = {
  //             // meal_package_id: meal.meal_package_id,
  //             // data: meal,
  //             // meal_type_id: meal.meal_type_id,
  //             ...meal,
  //           };
  //         }
  //       });
  //     }
  //   });

  //   return b;
  // };
  const convertToObject = (originalObject) => {
    const result = [];

    Object.keys(originalObject).forEach((dayKey, index) => {
      const dayNumber = index + 1;

      const dayObject = {
        day_number: dayNumber,
        tour_package_meal_type_wise_meal_packages: {},
      };

      originalObject[dayKey].forEach((meal) => {
        const { meal_type_id, per_meal_package_price, meal_package_id } = meal;

        if (
          !dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id]
        ) {
          dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id] =
            [];
        }

        dayObject.tour_package_meal_type_wise_meal_packages[meal_type_id].push({
          per_meal_package_price,
          tour_package_meal_package_id: meal_package_id,
        });
      });

      result.push(dayObject);
    });

    return result;
  };
  const processArray = (inputArray) => {
    const result = {};

    inputArray.forEach((day) => {
      Object.keys(day).forEach((dayName) => {
        if (!result[dayName]) {
          result[dayName] = [];
        }

        day[dayName].forEach((meal) => {
          const existingIndex = result[dayName].findIndex(
            (item) => item.meal_package_id === meal.meal_package_id
          );

          if (existingIndex === -1) {
            result[dayName].push({ ...meal });
          } else {
            result[dayName][existingIndex] = {
              ...meal,
            };
          }
        });
      });
    });

    return convertToObject(result);
  };

  const transformDataFromNewFood = (foodData) => {
    return foodData.map((days) => {
      const transformedDays = Object.keys(days).map((dayKey) => {
        const meals = days[dayKey].map((meal) => ({
          per_meal_package_price: meal.per_meal_package_price,
          tour_package_meal_package_id: meal.meal_package_id,
        }));

        return {
          [dayKey.slice(3)]: meals,
        };
      });

      return {
        tour_package_meal_packages: Object.assign({}, ...transformedDays),
      };
    });
  };

  const handleSaveTour = () => {
    const createPayload = transformData(
      requestToPayloadMultiple.available_tour_packages
    );

    authAxios
      .post(`${baseUrl}${MERCHANT_TOUR_ENDPOINT.MERCHANT_POST_TOUR_PACKAGE}`, {
        ...createPayload[0],
      })
      .then((res) => {
        if (res?.status === 201) {
          setPageInd(true);
          setPageChange(true);
          setResponseData(res?.data?.entities);
          toast.success("Created successfully!");
        } else {
          toast.warn("Something went worng, please try again!");
        }
      })
      .catch((err) => {});
  };

  // const convertFoodDatas = (data) => {
  //   const result = [];

  //   data.forEach((dayData) => {
  //     Object.keys(dayData).forEach((dayKey) => {
  //       const dayNumber = parseInt(dayKey.match(/\d+/)[0]); // Extracting the numeric day from the key
  //       const tourPackageMealTypeWiseMealPackages = {};

  //       const meals = dayData[dayKey];

  //       meals.forEach((meal) => {
  //         const mealPackageId = meal.meal_package_id.toString();
  //         const perMealPackagePrice = meal.per_meal_package_price;
  //         const mealTypeId = meal.meal_type_id.toString();

  //         if (!tourPackageMealTypeWiseMealPackages[mealTypeId]) {
  //           tourPackageMealTypeWiseMealPackages[mealTypeId] = [];
  //         }

  //         // Check if meal_package_id already exists in the meal type array
  //         const existingMealIndex = tourPackageMealTypeWiseMealPackages[
  //           mealTypeId
  //         ].findIndex(
  //           (existingMeal) =>
  //             existingMeal.tour_package_meal_package_id === mealPackageId
  //         );

  //         if (existingMealIndex !== -1) {
  //           // If meal_package_id exists, update the entry
  //           tourPackageMealTypeWiseMealPackages[mealTypeId][existingMealIndex] =
  //             {
  //               per_meal_package_price: perMealPackagePrice,
  //               tour_package_meal_package_id: mealPackageId,
  //             };
  //         } else {
  //           // If meal_package_id doesn't exist, create a new entry
  //           tourPackageMealTypeWiseMealPackages[mealTypeId].push({
  //             per_meal_package_price: perMealPackagePrice,
  //             tour_package_meal_package_id: mealPackageId,
  //           });
  //         }
  //       });

  //       // Check if day_number already exists in the result array
  //       const existingEntryIndex = result.findIndex(
  //         (entry) => entry.day_number === dayNumber
  //       );

  //       if (existingEntryIndex !== -1) {
  //         // If day_number exists, update the entry
  //         result[existingEntryIndex].tour_package_meal_type_wise_meal_packages =
  //           {
  //             ...result[existingEntryIndex]
  //               .tour_package_meal_type_wise_meal_packages,
  //             ...tourPackageMealTypeWiseMealPackages,
  //           };
  //       } else {
  //         // If day_number doesn't exist, create a new entry
  //         result.push({
  //           day_number: dayNumber,
  //           tour_package_meal_type_wise_meal_packages:
  //             tourPackageMealTypeWiseMealPackages,
  //         });
  //       }
  //     });
  //   });

  //   return result;
  // };
  const convertFoodDatas = (data) => {
    const result = [];

    data.forEach((dayData) => {
      Object.keys(dayData).forEach((dayKey) => {
        const dayNumber = parseInt(dayKey.match(/\d+/)[0]); // Extracting the numeric day from the key
        const tourPackageMealTypeWiseMealPackages = {};

        const meals = dayData[dayKey];

        meals.forEach((meal) => {
          const mealPackageId = meal.meal_package_id.toString();
          const perMealPackagePrice = meal.per_meal_package_price;
          const mealTypeId = meal.meal_type_id.toString();

          if (!tourPackageMealTypeWiseMealPackages[mealTypeId]) {
            tourPackageMealTypeWiseMealPackages[mealTypeId] = [];
          }

          // Check if meal_package_id already exists for the meal type
          const existingMealIndex = tourPackageMealTypeWiseMealPackages[
            mealTypeId
          ].findIndex(
            (existingMeal) =>
              existingMeal.tour_package_meal_package_id === mealPackageId
          );

          if (existingMealIndex !== -1) {
            // If meal_package_id exists, update the entry
            tourPackageMealTypeWiseMealPackages[mealTypeId][existingMealIndex] =
              {
                per_meal_package_price: Number(perMealPackagePrice),
                tour_package_meal_package_id: Number(mealPackageId),
              };
          } else {
            // If meal_package_id doesn't exist for the meal type, create a new entry
            tourPackageMealTypeWiseMealPackages[mealTypeId].push({
              per_meal_package_price: Number(perMealPackagePrice),
              tour_package_meal_package_id: Number(mealPackageId),
            });
          }
        });

        // Check if day_number already exists in the result array
        const existingEntryIndex = result.findIndex(
          (entry) => entry.day_number === dayNumber
        );

        if (existingEntryIndex !== -1) {
          // If day_number exists, update the entry
          result[existingEntryIndex].tour_package_meal_type_wise_meal_packages =
            {
              ...result[existingEntryIndex]
                .tour_package_meal_type_wise_meal_packages,
              ...tourPackageMealTypeWiseMealPackages,
            };
        } else {
          // If day_number doesn't exist, create a new entry
          result.push({
            day_number: dayNumber,
            tour_package_meal_type_wise_meal_packages:
              tourPackageMealTypeWiseMealPackages,
          });
        }
      });
    });

    return result;
  };

  // const c = convertData(b);
  // console.log(c);

  return (
    <div className="flex justify-between flex-col mx-auto max-w-[1100px] h-full">
      <>
        {pageInd ? (
          <AvailibilitySetTable responseData={responseData} />
        ) : (
          // <>
          //   {pageChange ? (
          //     <AvailabilityPackageCreate
          //       setRequestToPayload={setRequestToPayload}
          //       requestToPayload={requestToPayload}
          //       setPageChange={setPageChange}
          //       saveDataMultipleToTourPackage={saveDataMultipleToTourPackage}
          //       setGetMultipleDate={setGetMultipleDate}
          //       getMultipleDate={getGetMultipleDate}
          //     />
          //   ) : (
          //     <div className="flex justify-center items-center h-full flex-col gap-y-[20px]">
          //       {packageList?.length > 0 && (
          //         <>
          //           <h2>Already Created Package</h2>
          //           <div className="flex flex-row gap-x-[10px]">
          //             {packageList?.map((pkg, index) => (
          //               <div
          //                 onClick={() =>
          //                   naviagte(
          //                     `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_LIST}package=${pkg.tour_package_type_id}&subscribeId=${convertData.subscribeId}&tour_id=${convertData.tour_id}`
          //                   )
          //                 }
          //                 key={index}
          //                 className="bg-btn-secondary text-center text-bg-white px-[10px] py-[6px] rounded-[4px] cursor-pointer "
          //               >
          //                 <p>{pkg?.tour_package_type_name}</p>
          //               </div>
          //             ))}
          //           </div>
          //         </>
          //       )}

          //       {requestToPayloadMultiple?.available_tour_packages?.length >
          //         0 && (
          //         <div className="">
          //           <div className="bg-btn-secondary h-[39px] px-[10px] rounded-[6px] flex justify-center items-center text-bg-white">
          //             {requestToPayloadMultiple?.available_tour_packages?.map(
          //               (packages, index) => (
          //                 <span key={index}>
          //                   {packages?.tour_package_type_id?.label}
          //                 </span>
          //               )
          //             )}
          //             {/* {requestToPayload?.tour_package_type_id?.label} */}
          //           </div>
          //         </div>
          //       )}

          //       <button
          //         className="bg-btn-secondary h-[39px] px-[10px] rounded-[6px] flex justify-center items-center text-bg-white w-[130px]"
          //         onClick={() => handlePageChange()}
          //       >
          //         Set Package
          //       </button>
          //       {requestToPayloadMultiple?.available_tour_packages?.length >
          //         0 && (
          //         <button
          //           onClick={() => handleSaveTour()}
          //           className="bg-btn-secondary h-[39px] px-[10px] rounded-[6px] flex justify-center items-center text-bg-white w-[130px]"
          //         >
          //           Save
          //         </button>
          //       )}
          //     </div>
          //   )}
          // </>
          <>
            {pageChange === false ? (
              <AvailabilityPackageCreate
                setRequestToPayload={setRequestToPayload}
                requestToPayload={requestToPayload}
                setPageChange={setPageChange}
                saveDataMultipleToTourPackage={saveDataMultipleToTourPackage}
                setGetMultipleDate={setGetMultipleDate}
                getMultipleDate={getGetMultipleDate}
              />
            ) : (
              <div className="flex flex-col gap-y-[30px] justify-center items-center h-full">
                {requestToPayloadMultiple?.available_tour_packages?.length >
                  0 && (
                  <div className="flex flex-col gap-y-[4px]">
                    <p>Created Your Package for</p>
                    <div className=" flex flex-col gap-y-[6px]">
                      {requestToPayloadMultiple?.available_tour_packages?.map(
                        (packages, index) => (
                          <span
                            key={index}
                            className="bg-[#117A65] h-[39px] px-[60px] rounded-[6px] flex justify-center items-center text-bg-white"
                          >
                            {packages?.tour_package_type_id?.label}
                          </span>
                        )
                      )}
                    </div>
                    {requestToPayloadMultiple?.available_tour_packages
                      ?.length === 0 && (
                      <button
                        className="bg-btn-secondary h-[39px] px-[10px] rounded-[6px] flex justify-center items-center text-bg-white w-[130px]"
                        onClick={() => handlePageChange()}
                      >
                        Set Package
                      </button>
                    )}
                  </div>
                )}

                {requestToPayloadMultiple?.available_tour_packages?.length >
                  0 && (
                  <button
                    onClick={() => handleSaveTour()}
                    className="bg-btn-secondary h-[39px] px-[10px] rounded-[6px] flex justify-center items-center text-bg-white w-[130px]"
                  >
                    Submit Tour
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default AbibilitySetV2;
