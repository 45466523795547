import React from 'react'

const ParentPackageTransportationCard = ({transportation}) => {
    const {tour_package_transportation_route_name, tour_package_transportation_provider_name, tour_package_transportation_brand_name, trip_type, is_ac, transportation_package_unit_price
    } = transportation;

    


  return (
    <div className="bg-bg-white py-[8px] px-[8px] shadow-customShadow rounded-[2px]">
      <div className="mb-[4px]">
        <p className="text-[13px] text-center bg-[#e2e8f0] text-btn-secondary">
          {tour_package_transportation_route_name}
        </p>
        </div>
        <div className="grid gap-y-[4px] p-[4px] ring-[1px] rounded-[2px]">
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Bus: </span>
          {tour_package_transportation_provider_name}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Brand: </span>
          {tour_package_transportation_brand_name}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Trip type: </span>
          {trip_type}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Ac: </span>
          {is_ac ? "Yes" : "No"}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Unit Price: </span>
          {transportation_package_unit_price} ৳
        </p>
        </div>
    </div>
  )
}

export default ParentPackageTransportationCard