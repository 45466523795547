import React, { useEffect, useState } from "react";

import SingleSelectPhoneTwo from "../../components/dropdown/SearchPhoneTwo";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  apiEndPoint,
  baseUrl,
} from "../../contants/Endpoints";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";

import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import { numberInputOnWheelPreventChange } from "../utils/common";

const ExcursionCreate = ({
    activeStep,
    excursionId,
    setExcursionId,
    setActiveStep,
  }) => {

    const [success, setSuccess] = useState(false);

  const [selectFacilites, setSelectFacilites] = useState([]);
  const [selectLanguages, setSelectLanguages] = useState([]);

  const [phoneCode, setPhoneCode] = useState("+880");
  const [confirmed, setConfirmed] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

  const [languageList, setLanguageList] = useState([]);
  const [facilityList, setFacilityList] = useState({});
  const [groupList, setGroupList] = useState({});
  const [subGroupData, setSubGroupData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDestination, setSelectedDestination] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [newCategoryGroup, setNewCategoryGroup] = useState([]);
  const [showError, setShowError] = useState(false);
  const url = new URL(window?.location?.toString());

  const [userSelectFacilities, setUserSelectFacilities] = useState();
  const [userSelectGroupFacilities, setUserSelectGroupFacilities] = useState();

  const [groupData, setGroupData] = useState({
    name: "",
    phoneNumber: "",
    descriptionTitle: "",
    descriptionDetails: "",
    duration: "",
    maxParticipants: "",
    minParticipants: "",
    basePrice: "",
    shortAddress: "",
    email: "",
  });

  const getDestinationList = () => {
    noAuthAxios
      .get(`${apiEndPoint?.ADMIN_GET_INSPIRED}`)
      .then((res) => {
        setDestinationList(
          res?.data?.destinationLocationDataOptimizedList?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      })
      .catch((err) => {
        toastMessage("Error fetching travel destination", "error");
      });
  };

  const getCountryCode = () => {
    noAuthAxios
      .get(`${apiEndPoint?.GET_COUNTRY_DETAILS}?requestId=asdf`)
      .then((response) => {
        setCountryPhoneCodeArr(response?.data?.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getDestinationList();
    getCountryCode();
    authAxios
      .get(
        `${baseUrl}${
          MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_ALL_EXCURSION
        }?&requestId=${123}`
      )
      .then((res) => {
        const tempData = res?.data;
        setLanguageList(tempData?.languages);
        setFacilityList(tempData?.excursionFacilities);
        setGroupList(tempData?.excursionCategoryGroup);
        setNewCategoryGroup(tempData?.excursionCategoryGroup);

        const newSubGroupData = Object?.keys(
          tempData?.excursionCategoryGroup
        ).reduce((acc, key) => {
          acc[key] = [];
          return acc;
        }, {});

        setSubGroupData(newSubGroupData);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const payloadMethod = {
    id: Number(url?.searchParams?.get("id")) || 0,
    basePrice: parseInt(groupData?.basePrice),
    contactCode: phoneCode,
    contactNo: phone,
    contractId: 0,
    description: groupData?.descriptionDetails,
    descriptionTitle: groupData?.descriptionTitle,
    duration: parseInt(groupData?.duration),
    email: groupData?.email,
    facilities:
      userSelectFacilities &&
      Object?.values(userSelectFacilities)?.flatMap((category) =>
        category && Array?.isArray(category)
          ? category?.map((item) => item?.id)
          : []
      ),
    groupCategories:
      userSelectGroupFacilities?.length &&
      userSelectGroupFacilities?.map((item) => item?.id),
    languages: selectLanguages,
    maxNumberOfParticipants: parseInt(groupData?.maxParticipants),
    minNumberOfParticipants: parseInt(groupData?.minParticipants),
    name: groupData?.name,
    requestId: "123",
    selectedDestinationIds:
      selectedDestination && selectedDestination?.map((item) => item?.value),
    shortAddress: groupData?.shortAddress,
  };

  const handleSubmit = () => {
    if (
      groupData?.email?.length > 0 &&
      groupData?.name?.length > 0 &&
      groupData?.descriptionDetails?.length > 0 &&
      groupData?.descriptionTitle?.length > 0 &&
      groupData?.duration > 0 &&
      groupData?.shortAddress?.length > 0 &&
      groupData?.maxParticipants > 0 &&
      groupData?.minParticipants > 0 &&
      groupData?.basePrice > 0
    ) {
      authAxios
        .post(
          `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT?.EXCURSION_CREATE}`,
          payloadMethod
        )
        .then((res) => {
          setExcursionId(res?.data?.excursionData?.id);
          if (res.status === 200) {
            setSuccess(true);
            toast.success("Excursion set successfully!");
            setSuccess(true);
            setActiveStep(1);
          }
        })

        .catch((error) => {
          setSuccess(false);
          toast.error("Excursion set not successfully!");
          console.error("error:", error);
        });
    } else {
      setShowError(true);
      toast.error("Excursion create not successfully!");
    }
  };

  function transformObjectsToObject(data) {
    const result = {};
    for (const category in data) {
      if (Array?.isArray(data[category])) {
        const categoryArray = [];
        for (const item of data[category]) {
          for (const subCategory in item) {
            if (Array?.isArray(item[subCategory])) {
              for (const subItem of item[subCategory]) {
                const { label, value, id } = subItem;
                categoryArray?.push({ label, value, id });
              }
            }
          }
        }
        result[category] = categoryArray;
      }
    }

    return result;
  }

  useEffect(() => {
    if (excursionId) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_EXCURSION_GET_BY_ID}?excursionId=${excursionId}&requestId=123`
        )
        .then((res) => {
          setGroupData({
            ...groupData,
            name: res?.data?.excursionData?.excursionName,
            descriptionTitle: res?.data?.excursionData?.description.title,
            descriptionDetails:
              res?.data?.excursionData?.description.description,
            duration: res?.data?.excursionData?.duration,
            maxParticipants: res?.data?.excursionData?.maxNumberOfParticipants,
            minParticipants: res?.data?.excursionData?.minNumberOfParticipants,
            basePrice: res?.data?.excursionData?.basePrice,
            shortAddress: res?.data?.excursionData?.shortAddress,
            email: res?.data?.excursionData?.email,
          });
          setSelectFacilites(
            Object.keys(res?.data?.excursionData?.facilities).flatMap(
              (key) => res?.data?.excursionData?.facilities[key]
            )
          );
          setSubGroupData(
            transformObjectsToObject(res?.data?.excursionData?.categoryGroup)
          );
          setPhoneCode(res?.data?.excursionData?.contactCode);
          setPhone(res?.data?.excursionData?.contactNo);
          setSelectLanguages(res?.data?.excursionData?.languages);
          setSelectedDestination(
            res?.data?.excursionData?.inspiredLocation?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))
          );
          setUserSelectFacilities(res?.data?.excursionData?.facilities);
          // setUserSelectGroupFacilities(res?.data?.excursionData?.categoryGroup);
          setUserSelectGroupFacilities(generateCategoryGroupOptions(res?.data?.excursionData?.categoryGroup));
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, []);

  const generateCategoryGroupOptions = (categoryGroup) => {
    return categoryGroup?.map((item) => ({
      id: item?.id,
      label: `${item?.level} (${item?.excursionCategoryGroupTwo}, ${item?.excursionCategoryGroupOne})`,
      value: `${item?.level} (${item?.excursionCategoryGroupTwo}, ${item?.excursionCategoryGroupOne})`,
    }));
  };

  const newCategoryGroupOptions = generateCategoryGroupOptions(newCategoryGroup);

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <div className="w-[343px] md:w-[712px] xl:w-[1100px]">
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]"> Excursion Create</span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          An Excursion Create allows you to quickly generate new trip plans.
        </p>
      </div>
      {isLoading ? (
        <div className=" flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className=" w-full flex flex-col md:flex-row md:gap-x-[24px] shadow-customShadow py-[16px] px-[20px] rounded-[4px]">
          <div className="w-full flex flex-col gap-y-[6px] ">
            <h2 className="text-[22px] text-btn-primary">Provider Details:</h2>
            <div className="">
              <SingleSelectPhoneTwo
                required={true}
                title={phoneCode}
                setTitle={setPhoneCode}
                confirmed={confirmed}
                setInputValue={setPhone}
                inputValue={phone || ""}
                register={true}
                name={"phone"}
                warningView={false}
                dataArray={countryPhoneCodeArr}
              />
              
              <div className="mt-[12px]">
                <p className="text-btn-secondary text-[14px]">
                  Provider Email:{" "}
                </p>
                <div className="rounded">
                  <input
                    className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                    placeholder="Enter your email : "
                    type="email"
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setGroupData((prev) => ({
                        ...prev,
                        email: inputVal,
                      }));
                    }}
                    value={groupData?.email}
                  />
                </div>
                {showError && groupData?.email?.length === 0 && (
                  <p className="text-[13px] text-[#f87171] mt-[2px]">
                    This field is required.
                  </p>
                )}
              </div>
            </div>
            <h2 className="text-[22px] text-btn-primary">Excursion Details:</h2>
            
            <div className="">
              <p className="text-btn-secondary text-[14px]">Excursion Name: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your excursion name : "
                  type="email"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      name: inputVal,
                    }));
                  }}
                  value={groupData?.name}
                />
              </div>
              {showError && groupData?.name?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  This field is required.
                </p>
              )}
            </div>

            <div className="my-[8px]">
              <p className="text-btn-secondary text-[14px]">
                Description Title:{" "}
              </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your description title : "
                  type="email"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      descriptionTitle: inputVal,
                    }));
                  }}
                  value={groupData?.descriptionTitle}
                />
              </div>
              {showError && groupData?.descriptionTitle?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  This field is required.
                </p>
              )}
            </div>

            <div className="">
              <p className="text-btn-secondary text-[14px]">
                Description details:{" "}
              </p>
              <div className="rounded">
                <textarea
                  className="w-full p-[8px] min-h-[150px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your description details : "
                  type="email"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      descriptionDetails: inputVal,
                    }));
                  }}
                  value={groupData?.descriptionDetails}
                />
              </div>
              {showError && groupData?.descriptionDetails?.length === 0 && (
                <p className="text-[13px] text-[#f87171]">
                  This field is required.
                </p>
              )}
            </div>

            <div className="">
              <p className="text-btn-secondary text-[14px]">Short Address: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your short address : "
                  type="email"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      shortAddress: inputVal,
                    }));
                  }}
                  value={groupData?.shortAddress}
                />
              </div>
              {showError && groupData?.shortAddress?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  This field is required.
                </p>
              )}
            </div>

            <div className="">
              <p className="text-btn-secondary text-[14px]">
                Duration (In Minute):{" "}
              </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your duration : "
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      duration: inputVal,
                    }));
                  }}
                  value={groupData?.duration}
                  onWheel={numberInputOnWheelPreventChange}
                />
              </div>
              {showError && groupData?.duration?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  This field is required.
                </p>
              )}
            </div>

            <div className="my-[8px]">
              <p className="text-btn-secondary text-[14px]">
                Max No Of Participants:{" "}
              </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your Max Participants : "
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      maxParticipants: inputVal,
                    }));
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                  value={groupData?.maxParticipants}
                />
              </div>
              {showError && groupData?.maxParticipants?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  Maximum participants is required then minimum.
                </p>
              )}
            </div>

            <div className="">
              <p className="text-btn-secondary text-[14px]">
                Min No Of Participants:{" "}
              </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your min participants : "
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      minParticipants: inputVal,
                    }));
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                  value={groupData?.minParticipants}
                />
              </div>
              {showError && groupData?.minParticipants?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  Minimum participants is required
                </p>
              )}
            </div>
          </div>

          {/* right side */}

          <div className="flex flex-col gap-y-2  w-full ">

            <div className="my-[8px]">
              <p className="text-btn-secondary text-[18px]">Base Price: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                  placeholder="Enter your base price : "
                  type="text"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      basePrice: inputVal,
                    }));
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                  value={groupData?.basePrice}
                />
              </div>
              {showError && groupData?.basePrice?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  This field is required
                </p>
              )}
            </div>

            {/* New Languages */}
            <div className="my-[12px]">
              <p className="text-[20px] text-btn-secondary">Languages: </p>
              <div className="rounded-[4px]">
                <Select
                  options={languageList}
                  components={animatedComponents}
                  styles={colorStyles}
                  value={selectLanguages}
                  placeholder={"Select languages: "}
                  onChange={(data) => {
                    setSelectLanguages(data);
                  }}
                  isMulti
                ></Select>
              </div>

              {(showError && selectLanguages?.length === 0) && (<p className="text-[13px] text-[#f87171] ">
                  This field is required
                </p>)}
            </div>

            {/* New Selected Destination */}
            <div className="">
              <p className="text-[22px] text-btn-secondary">
                Select Your Destination:{" "}
              </p>
              <div className="rounded">
                <Select
                  value={selectedDestination}
                  onChange={(data) => {
                    setSelectedDestination(data);
                  }}
                  components={animatedComponents}
                  options={destinationList}
                  placeholder={"Select Destination: "}
                  styles={colorStyles}
                  isClearable
                  isMulti
                />
              </div>
              {(showError && selectedDestination?.length === 0) && (<p className="text-[13px] text-[#f87171]">
                  This field is required
                </p>)}
            </div>
            {/* New Facilities */}
            <div>
              <p className=" mb-[4px] text-[22px] text-btn-primary">
                Facilities:{" "}
              </p>

              <div>
                {Object?.entries(facilityList)?.map(([key, value]) => (
                  <div key={key}>
                    <p className="text-[18px] text-btn-secondary"> {key} :</p>

                    <div key={key} className="rounded">
                      <Select
                        value={
                          userSelectFacilities === undefined
                            ? ""
                            : userSelectFacilities[key]
                        }
                        onChange={(data) => {
                          setUserSelectFacilities((prev) => ({
                            ...prev,
                            [key]: [...data],
                          }));
                        }}
                        components={animatedComponents}
                        options={value || []}
                        placeholder={"Select " + key + " :" || ""}
                        styles={colorStyles}
                        isClearable
                        isMulti
                      />
                    </div>
                  </div>
                ))}
              </div>
              {(showError && userSelectFacilities === undefined) && (<p className="text-[13px] text-[#f87171]">
                  One of Facilities field is required.
                </p>)}
            </div>

            {/* New CategoryGroup */}
            <div>
              <div className="">
                <p className="mb-[4px] text-[22px] text-btn-primary">
                  Category Group:{" "}
                </p>
                <div className="rounded">
                  <Select
                    value={userSelectGroupFacilities}
                    onChange={(data) => {
                      setUserSelectGroupFacilities(data);
                    }}
                    components={animatedComponents}
                    options={newCategoryGroupOptions}
                    placeholder={"Select your Destination: "}
                    styles={colorStyles}
                    isClearable
                    isMulti
                  />
                </div>
              </div>
              {(showError && (userSelectGroupFacilities?.length === 0 || userSelectGroupFacilities === undefined)) && (<p className="text-[13px] text-[#f87171]">
                  This field is required
                </p>)}
            </div>
          </div>
        </div>
      )}

      <div className="mt-[20px] flex justify-center items-center w-full">
        <CustomButtonGroupExcruions
          onSubmit={handleSubmit}
          step={success ? 1 : 0}
          setSaveAndNext={setActiveStep}
        />
      </div>
    </div>
  )
}

export default ExcursionCreate