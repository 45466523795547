import React from 'react'

const ButtonSecond = ({name, width, disabled, height, onClick}) => {
  return (
    <button className={`${width? width: ""} ${height? height : "h-12"} py-1.5 px-6 bg-[#48C9B0] text-bg-primary hover:bg-[#48c9af16] border-[2px] border-[#48C9B0] rounded-md shadow-md transition-all duration-300 hover:text-[#48C9B0] hover:shadow-lg hover:bg-transparent hover:animate-pulse`} 
    type='submit' 
    disabled={disabled}
    width={"w-60"}
    onClick={onClick ? onClick : ()=> {}}
    >{name}</button>
    // <button className={`${width? width: ""} ${height? height : ""} py-1.5 px-6 bg-[#86efac] text-txt-primary hover:bg-[#48c9af16] border-[2px] border-[#86efac] rounded-md shadow-md transition-all duration-300 hover:shadow-lg hover:bg-transparent hover:animate-pulse`} 
    // type='submit' 
    // disabled={disabled}
    // width={"w-60"}
    // >{name}</button>
  )
}

export default ButtonSecond
