import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiEndPoint, BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import CommonSearch from "./admin/CommonSearch";
import Paginations from "./admin/Paginations/Paginations";
import { bcolor, color, tcolor } from "./BookingReviewCart";
export const tcolors = {
  NEW: "text-[#4E90A8]",
  IN_PROGRESS: "text-[#FF9500]",
  ACCEPTED: "text-[#63a561]",
  REJECTED: "text-[#FE9A9A]",
};
export default function BookingSalesOrderReviewAdmin() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [salesorder, setSalesorder] = useState();
  const [currentInd, setCurrentInd] = useState(null);
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getBooking = () => {
    let url = `${BOOKING_ENDPOINTS.ADMIN_GET_ALL_SALESORDER}pageNumber=${currentPage}&pageSize=10&queryString=${searchValue}&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setSalesorder(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  };

  useEffect(() => {
    setLoading(true);
    getBooking();
  }, [currentPage, searchValue]);

  const handleSendEmail = (ind) => {
    setCurrentInd(ind);
  };

  const sendEmail = (orderCode) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(inputEmail)) {
      setError(true);
    } else {
      authAxios
        .post(
          `${apiEndPoint.SEND_EMAIL_MANUAL}email=${inputEmail}&orderCode=${orderCode}&requestId=df`
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Email sent successfully");
            setCurrentInd(null);
          } else {
            toast.warn("Something went wrong!⚠");
          }
          console.log(res);
        });
    }
  };

  return (
    <div>
      <div className="mt-[20px] max-w-[1100px] mx-auto">
        {/* filter option */}
        <div className=" text-[14px] relative flex justify-center  items-center">
          <div className="w-full min-w-[500px] flex justify-between items-center gap-x-[40px]">
            <CommonSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              label={"Search by order code..."}
            />
            {/* <h2 className={` text-[#48C9B0] $ text-[22px]`}>All Bookings</h2> */}
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center w-full h-screen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {/* view table */}
            <div className="mt-[40px] max-w-[1100px]">
              <table
                className={`table-fixed rounded-[8px] border-collapse  w-full`}
              >
                <thead
                  className={`sticky top-[88px] shadow-sm  bg-[#48C9B0] text-bg-primary  text-left`}
                >
                  <tr>
                    <th className="px-[8px]  py-[10px]  w-[350px]">
                      User Info.
                    </th>

                    <th className=" px-[8px]  py-[10px]  w-[350px]">
                      Booking Info.
                    </th>
                    <th className=" px-[8px]  py-[10px]  w-[350px]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salesorder?.rentalBookingWithSalesOrderData?.map(
                    (booking, index) => (
                      <tr
                        key={index}
                        className={` ${
                          index % 2 !== 0 ? "bg-[#48c9af16]" : ""
                        } cursor-pointer border-b text-[14px] border-[#eaecef]`}
                      >
                        <td className=" py-[10px] px-[8px] ">
                          <div className="flex flex-col items-start justify-start gap-[6px] ">
                            <span>Name : {booking?.userName}</span>
                            <span>Email : {booking?.email}</span>
                            <span>Phone : {booking?.phoneNumber}</span>
                            <span className={`${tcolor[`${booking?.state}`]}`}>
                              Sales Order Id : #{booking?.salesOrderId}
                            </span>
                            <span className={`${tcolor[`${booking?.state}`]}`}>
                              OrderCode : {booking?.orderCode}
                            </span>
                          </div>
                        </td>

                        <td className=" py-[10px] pr-[28px]  ">
                          <div>
                            <h2 className=" mb-[4px] text-txt-hint">
                              Order Summary :{" "}
                            </h2>
                            <div className="flex flex-col gap-y-[6px]">
                              {booking?.rentalLevelData?.map(
                                (rental, index) => (
                                  <div key={index}>
                                    <div>
                                      <p>{rental?.hotelName}</p>
                                      {rental?.roomBookingData?.map(
                                        (room, index) => (
                                          <div
                                            key={index}
                                            className={`${
                                              index >= 0 &&
                                              index !==
                                                booking?.rentalLevelData
                                                  ?.length -
                                                  1
                                                ? " border-txt-hint border-b-[0.5px]"
                                                : "border-none"
                                            } pb-[4px]`}
                                          >
                                            <div className="flex items-center justify-start space-x-[2px]">
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className={`w-[14px] h-[14px] text-txt-hint ${
                                                    tcolor[`${rental?.state}`]
                                                  } `}
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                                    clip-rule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                              <p>
                                                {room?.roomCategoryName}{" "}
                                                <span
                                                  className={`text-[14px] ${
                                                    tcolor[`${rental?.state}`]
                                                  }`}
                                                >
                                                  x{room?.noOfTimes}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="flex justify-between">
                                              <p>In : {room?.checkinDate}</p>
                                              <p>Out : {room?.checkoutDate}</p>
                                            </div>
                                            <div className="flex justify-between ">
                                              <div
                                                className={`flex  border-[0.3px] px-[5px] ${
                                                  bcolor[`${booking?.state}`]
                                                } rounded-[4px] `}
                                              >
                                                <p className="text-txt-hint">
                                                  Total :
                                                </p>
                                                <p className="text-txt-primary pl-[2px]">
                                                  {rental?.totalAmount} BDT
                                                </p>
                                              </div>
                                              <p
                                                className={`${
                                                  color[`${rental?.state}`]
                                                } px-[6px] py-[1px] text-bg-white rounded-[2px]`}
                                              >
                                                {rental?.state}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          {/* <span
                    className={`${
                      color[`${booking?.state}`]
                    } px-[8px] py-[3px] rounded-[4px]`}
                  >
                    {booking?.state}
                  </span>
                  <span className="flex  flex-col gap-[6px]">
                    {booking?.roomBookingData?.map((roomeBooking) => (
                      <div className="flex flex-col gap-[6px] border p-[4px] rounded-[8px]  text-[12px]">
                        <span>Category : {roomeBooking?.roomCategoryName}</span>
                        <span>Check In : {roomeBooking?.checkinDate}</span>
                        <span>Check Out : {roomeBooking?.checkoutDate}</span>
                      </div>
                    ))}
                  </span> */}
                        </td>

                        <td>
                          {currentInd === index ? (
                            <div className="">
                              <div className="relative">
                                <input
                                  onChange={(e) =>
                                    setInputEmail(e.target.value)
                                  }
                                  type="text"
                                  required={true}
                                  placeholder="Enter email"
                                  className="px-[4px] py-[4px] border-none border-0 active:border-none focus:border-none rounded-[4px] w-full"
                                />
                                <span
                                  onClick={() => sendEmail(booking?.orderCode)}
                                  className="absolute top-0 right-[0px] flex justify-center items-center h-full bg-btn-secondary rounded-r-[4px] w-[25px]"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-[15px] h-[15px] text-bg-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                    />
                                  </svg>
                                </span>
                              </div>
                              {error && (
                                <div
                                  className="text-[12px] text-center"
                                  style={{ color: "red" }}
                                >
                                  {error ? "Invalid Email Address" : null}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              onClick={() => handleSendEmail(index)}
                              className="mx-auto w-full flex justify-center items-center"
                            >
                              <button className="bg-btn-secondary px-[12px] py-[4px] rounded-[4px] text-bg-white flex justify-start items-center gap-x-[6px]">
                                <span>Send Email</span>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-[15px] h-[15px]"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="">
              <Paginations
                currentPage={currentPage}
                totalCount={Math.ceil(salesorder?.totalEntries / 10)}
                pageSize={10}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
