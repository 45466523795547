import React, { useState } from "react";
import ReactQuill from "react-quill";

import "./CustomTextEditor.css";

const CustomTextEditor = ({ placeholderText, details, setDetails }) => {
  const handleDetailsChange = (newDetails) => {
    setDetails(newDetails);
  };

  const modules = {
    toolbar: [
      [{ header: [3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          color: [
            "#376576",
            "#4E90A8",
            "#FEE29A",
            "#FFFBEF",
            "#2A220E",
            "#FEE29A",
            "#ffffff",
            "#eddb0e",
            "#FE9A9A",
            "#FF9500",
            "#000000",
            "#d4d4d4",
          ],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  // console.log(details);

  return (
    <>
      <ReactQuill
        className="custom-quills"
        theme="snow"
        placeholder={placeholderText}
        value={details}
        onChange={handleDetailsChange}
        modules={modules}
      ></ReactQuill>
    </>
  );
};

export default CustomTextEditor;
