import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router";
import ButtonSecond from "../../components/ButtonSecond";
import ModalCommon from "../../components/common/ModalCommon";
import InputComponentSecond from "../../components/InputComponentSecond";
import Loading from "../../components/Loader/Loading";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";
import AdminAddLocation from "./AdminAddLocation";
import Paginations from "./Paginations/Paginations";

export default function AdminInspiredLocations() {
  let pageSize = 10;
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [locations, setLocations] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState();
  const [modalShwon, setModalShwon] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loaderSpinner, setLoadderSpinner] = useState(false);

  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "200px",
      width: "1000px",
      borderRadius: "32px",
      backgroundColor: "#FFFBEF",
    },
  };
  useEffect(() => {
    if (searchValue) {
      getLocations(searchValue);
    } else {
      getLocations();
    }
  }, [currentPage, pageSize, searchValue]);

  const getLocations = (keyWord) => {
    var link;
    setLoadderSpinner(true);

    if (keyWord) {
      link = `${apiEndPoint?.ADMIN_GET_DESTINATION}keyword=${keyWord}&`;
    } else {
      link = `${apiEndPoint?.ADMIN_GET_DESTINATION}`;
    }
    authAxios
      .get(`${link}pageNumber=${currentPage}&pageSize=${pageSize}&requestId=11`)
      .then((res) => {
        setLocations(res?.data?.entries);
        setTotalElements(res?.data?.totalPages);
        setLoadderSpinner(false);
      })
      .catch((err) => {});
  };

  const DeleteLocation = (id) => {
    authAxios
      .delete(`${apiEndPoint?.ADMIN_DELETE_INSPIRED}/${id}`)
      .then((res) => {
        toastMessage("Deleted Successfully!");
        getLocations();
      })
      .catch((err) => {
        toastMessage("Operation failed!");
      });
  };

  const handleEdit = (locationData) => {
    setIsEdit(true);
    setSelected(locationData);
  };

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [searchValue]);

  return (
    <div className="container mx-auto">
      <div className="">
        <h2 className="my-12 text-4xl text-btn-primary text-center">
          Ghuddy Inspired Locations
        </h2>
        <div className="text-2xl text-btn-secondary my-4">
          Total Locations : {locations?.length}{" "}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="w-[400px]">
          {/* <ButtonFilled
            w={"100%"}
            ml={"0"}
            title={"Add new"}
            buttonFilledHandle={() => navigate("add")}
          /> */}
          <span className="" onClick={() => navigate("add")}>
            <ButtonSecond
              width={"xl:w-96 md:w-64 w-48"}
              height={"h-12"}
              name={"Add New"}
            ></ButtonSecond>
          </span>
        </div>
        <div className="ml-0">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setCurrentPage(1);
              getLocations(searchValue);
            }}
            className="my-4 flex gap-2 items-center max-w-[750px]"
          >
            {/* <div> Search : </div> */}
            {/* <input
              className="px-8 flex-grow outline-none rounded-full shadow-md bg-[transparent] focus:shadow-lg h-[50px] w-[400px] border-[1px] border-txt-hint"
              placeholder="Enter Location"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => {
                setSearchValue("");
              }}
            /> */}

            {/* <ButtonFilled
              title={"Search"}
              type="submit"
              w={"220px"}
              arrow="no"
            /> */}
            <div className="mx-2">
              <InputComponentSecond
                placeholder={"Enter Location"}
                width={"xl:w-96 md:72 w-60"}
                height={"h-12"}
                borderRadious={"rounded"}
                value={searchValue}
                setValue={setSearchValue}
                onFocusValue={true}
              ></InputComponentSecond>
            </div>
            {/* <div className="mx-4">
            <ButtonSecond name={"Search"} width={"xl:w-60 md:w-48 w-40"} height={"h-12"}></ButtonSecond>
            </div> */}
          </form>
        </div>
      </div>

      {loaderSpinner ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : locations?.length === 0 ? (
        <div className="my-20 text-center">
          <h2 className="text-2xl md:text-3xl xl:text-4xl text-btn-secondary">
            No Data Found!
          </h2>
        </div>
      ) : (
        <div className="mt-10 mb-20 overflow-x-auto">
          {/* <table id="students" className="min-w-full leading-normal">
          <thead>
            <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Description
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <div className="my-[14px]"></div>
          <tbody>
            {locations &&
              locations.map((location, index) => {
                const { id, name, metaData } = location; //destructuring
                return (
                  <tr key={id}>
                    <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                      {name}
                    </td>
                    <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm text-left">
                      {metaData.description}
                    </td>
                    <td className="h-[80px] px-5 py-5 border-b border-t border-r bg-white text-sm">
                      <button
                        onClick={() => {
                          // DeleteLocation(id);
                          //setSelected(location);
                          handleEdit(location);
                        }}
                        className="shadow-sm py-2 px-6 rounded-md"
                      >
                        {" "}
                        Edit{" "}
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table> */}

          <table className="table-auto min-w-full bg-btn-base">
            <thead className="border-b-[2px] border-bg-semiwhite">
              <tr className="text-bg-primary text-[12px] xl:text-[16px] md:text-[14px] bg-[#48C9B0]">
                <th className="p-[12px] min-h-[220px] md:min-w-[240px] xl:min-w-[260px] font-semibold text-bg-primary">
                  Image
                </th>
                <th className="p-[12px] min-h-[240px] md:min-w-[280px] xl:min-w-[320px] font-semibold text-bg-primary ">
                  Location Name
                </th>
                <th className="p-[12px] min-h-[220px] md:min-w-[50px] xl:min-w-[60px] font-semibold text-bg-primary">
                  ID
                </th>

                <th className="p-[12px] min-h-[180px] md:min-w-[200px] xl:min-w-[220px] font-semibold text-bg-primary">
                  Description
                </th>
                <th className="p-[12px] min-h-[180px] md:min-w-[200px] xl:min-w-[220px] font-semibold text-bg-primary">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {locations &&
                locations?.map((item, indx) => (
                  <Fragment key={indx}>
                    <tr className="bg-bg-semiwhite text-center text-[12px] xl:text-[16px] md:text-[14px] hover:bg-[#48c9af16]">
                      <td className=" ">
                        <img
                          className="w-[240px] h-[140px] object-cover"
                          src={item?.imageUrl}
                          alt={item?.name}
                        />
                      </td>
                      <td className="">{item?.name}</td>
                      <td className=" ">{item?.id}</td>

                      <td className="text-[14px] text-left min-h-[120px] ">
                        {item?.metaData?.description
                          ?.split(" ")
                          .slice(0, 25)
                          .join(" ")}
                        ...
                      </td>
                      <td className="">
                        {/* <button className='xl:py-[12px] xl:px-[32px] md:py-[10px] md:px-[28px] py-[8px] px-[24px] bg-btn-primary text-bg-primary rounded-[6px] transition-all duration-300 cursor-pointer hover:bg-btn-secondary'>Edit</button> */}
                        <div className="flex gap-x-[4px] justify-center items-center ">
                          <button
                            className="border px-[4px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[8px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                            onClick={() => {
                              handleEdit(item);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="border px-[4px] flex justify-center items-center cursor-pointer py-[8px] h-[28px] border-[#ff3d60] w-[58px] text-[12px] text-[#ff3d60] rounded-[4px] hover:bg-[#ff3d60] hover:text-bg-primary"
                            onClick={() => {
                              setModalShwon(true);
                              setModalData(item);
                            }}
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-[12px]"></tr>
                  </Fragment>
                ))}
            </tbody>
          </table>

          {/* <PaginationComponent
                    totalElements={totalElements}
                    currentPage={currentPage}
                    dataSize={locations.length}
                    pageSize={pageSize}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    showCount={true}
                /> */}
          <Paginations
            currentPage={currentPage}
            totalCount={totalElements}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}

      <ReactModal
        isOpen={edit}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        onRequestClose={() => setIsEdit(false)}
      >
        <AdminAddLocation
          selectedData={selected}
          setIsEdit={setIsEdit}
          edit={edit}
          loaderSpinner={loaderSpinner}
          setLoadderSpinner={setLoadderSpinner}
          open={true}
        />
      </ReactModal>

      <ModalCommon
        shown={modalShwon}
        outSideOff={false}
        width="max-w-[910px]"
        height="min-h-[412px]"
        close={() => {
          setModalShwon(false);
        }}
      >
        <div className="flex p-5 h-full">
          <div className="w-8/12 max-h-full flex items-center">
            <img
              className="h-[500px] w-full object-cover rounded"
              src={modalData?.imageUrl}
              alt={modalData?.name}
            ></img>
          </div>
          <div className="p-5 w-4/12 flex flex-col items-center justify-center">
            <h2 className="text-[22px] font-bold text-center text-btn-primary">
              {modalData?.name}
            </h2>
            <p className="text-center my-2 text-btn-secondary">
              {modalData?.metaData?.title || `${modalData?.name} Title`}
            </p>
            <p className="my-5 text-center text-btn-border">
              {
              modalData?.metaData?.description?.length >= 400? 
              modalData?.metaData?.description?.slice(0, 400)+"....." 
              : modalData?.metaData?.description
              }
            </p>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
}
