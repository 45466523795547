import React, { useEffect, useState } from "react";
import SecondaryNav from "../comp/SecondaryNav";
import CurrentTimeDateShow from "../comp/CurrentTimeDateShow.js";
import TopShortcutItem from "../comp/TopShortcutItem";
import CheckInCheckOutShortcutTable from "../comp/CheckInCheckOutShortcutTable";
import ComplainShortcutTable from "../comp/ComplainShortcutTable";
import ActionLogShortcutTable from "../comp/ActionLogShortcutTable";
import FooterButton from "../../../components/common/FooterButton";
import HotelCardWithoutBg from "../comp/HotelCard/HotelCardWithoutBg";
import HotelCardSlider from "../comp/HotelCard/HotelCardNext";
import HotelCardBlarImage from "../comp/HotelCard/HotelCardBlarImage";
import { CarouselSlider } from "../CarouselSlider";
import SliderCardWithDetails from "../SliderCardWithDetails";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "../../../contants/Constants";
import { hotelsInfo } from "../../../store/admin/hotel/api/adminContractApi";
import { decideMerchantDashboardComponent } from "../../../utils/decideMerchantDashboardComponent";
import { useLocation, useParams } from "react-router";
import TourNav from "../comp/TourNav";

function AdminDashboard({ setPosition, position, userType, route }) {
  
 
  let param = useLocation();
  console.log('route:' ,param)
  
  return (
    <>
      {param?.search === "tour" ?
        <TourNav route={route} />
      :
        <SecondaryNav route={route} />
        }
      <div className="mt-10">
        {decideMerchantDashboardComponent(route)}
      </div>
    </>
  );
}

export default AdminDashboard;
