import { setOptions } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import React from "react";
import { customValidationSingle } from "../utils/customValidation";
import "./calender.css";
import "./index.css";
import RootComponent from "./rootComponent/RootComponent";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
function TimePicker({
  setSelectedDate,
  placeholder,
  selectedData,
  type,
  hintText,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  warningView,
  name,
  required = false,
  onBlur = null,
}) {
  const inputProps = {
    // className: "md-mobile-picker-input",
    id: `input${name}`,
    onBlur: () => required && customValidationSingle(name),
  };

  // function handleIconClick() {
  //   document.getElementById(placeholder).click();
  // }
  let component = (
    <div id={`${name}`} className={`w-full ${required && "required "} `}>
      <div
        className={`w-full border-opacity-[45%] border border-txt-primary validation flex rounded-[8px] overflow-hidden justify-between items-center pr-[12px]`}
        // onClick={handleIconClick}
      >
        <div className="cursor-auto text-[#9e9989] md:text-sm text-left validation py-3 h-full rounded-[8px] pl-3 ">
          <span className="w-full text-[#9e9989] md:text-sm">
            {placeholder}
            {`${placeholder ? ":" : ""}`}
            {required ? "*" : null}
          </span>
        </div>
        <div className="relative">
          <input
            onChange={(event) => {
              setSelectedDate(event.target.value);
            }}
            type="time"
            id="session-time"
            name="appt"
            step="0"
            value={selectedData ? selectedData : null}
            required
            {...inputProps}
            onBlur={() => required && customValidationSingle(name)}
            style={{
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
              width: "200px",
              outline: "none",
              backgroundColor: "#f5f5f5",
              color: "#9e9989",
              fontFamily: "Arial, sans-serif",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "border-color 0.3s ease, box-shadow 0.3s ease",
            }}
          />
          {/* <Datepicker
            className="w-full bg-bg-primary"
            id={placeholder}
            controls={["time"]}
            inputComponent="input"
            inputProps={inputProps}
            value={selectedData ? selectedData : null}
            onChange={(event) => {
              setSelectedDate(event.value);
              required &&
                setTimeout(() => {
                  customValidationSingle(name);
                }, 200);
            }}
          /> */}
        </div>
      </div>
      <div className="errorMessage validationError"></div>
    </div>
  );

  let readOnlyViewComponent = <p></p>;

  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        warningView={warningView}
        name={name}
      />
    </div>
  );
}

export default TimePicker;
