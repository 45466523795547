import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { merchantRoute, ReviewFor, UserType } from "../../contants/Constants";
import {
  adminContractGet,
  ownerInfo,
} from "../../store/admin/adminContract/api/adminContractApi";
import { reviewSubmission } from "../../store/admin/review/api/reviewApi";
import { putOwnerInfoSave } from "../../store/registration/owner/api/ownerApi";
import ButtonFilled from "../ButtonFilled";
import ButtonOutlined from "../ButtonOutlined";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import InputComponent from "../InputComponent";
import Loading from "../Loader/Loading";
import Pagenation from "../pagenation/Pagenation";
import SingleSelect from "../SingleSelect";

// import { Formik, getIn,useFormikContext } from 'formik';
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import CustomValidation from "../../utils/customValidation";
import Calender from "../Calender";
import SingleSelectPhone from "../dropdown/SingleSelectPhone";

export const formatDateWithYYMMDD = (inputDate) => {
  const date = new Date(inputDate);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const OwnerComponent = ({ withoutHeader }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [rejectionArray, setRejectionArray] = useState([]);
  let superContractId = localStorage.getItem("superContractId");

  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  useEffect(() => {
    dispatch(adminContractGet(localStorage.getItem("contractId")));
  }, []);
  const response = useSelector((state) => state.ownerInfoGetReducer);
  const [title, setTitle] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDob] = useState(new Date());
  const [nid, setNid] = useState("");
  const [tin, setTin] = useState("");
  const [website, setWeb] = useState("");
  // some helper state
  console.log("dateOfBirth", dateOfBirth);
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

  const [submitType, setSubmitType] = useState("next");
  const [isLoading, setIsLoading] = useState(true);
  const [validationError, setValidationError] = useState(false);

  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(ownerInfo(process.env.REACT_APP_USER_TYPE, superContractId));
  }, [superContractId]);
  const customValidation = () => {
    setValidationError(CustomValidation());
  };
  useEffect(() => {
    if (response && response.data && response.data.firstName) {
      setFirstName(response.data.firstName);
      setMiddleName(response.data.middleName);
      setLastName(response.data.lastName);
      setPhone(response.data.phoneNumber);
      setPhoneCode(response.data.phoneCode);
      setEmail(response.data.email);
      setDob(formatDateWithYYMMDD(response.data.dateOfBirth));
      setWeb(response.data.website);
      setNid(response.data.nid);
      setTin(response.data.tin);
      setTitle(response.data.title);
      setRejectionArray(response.data.rejectionReasons);
      setIsLoading(false);
    }
  }, [response]);

  // for save button click
  const createContractMoveingDecideSave = () => {
    navigate(merchantRoute.LANDING);
  };

  // for next button click
  const createContractMoveingDecideNext = () => {
    navigate(merchantRoute.CONTRACT);
  };

  // button outline(Save)
  const buttonOutlinedHandle = () => {
    sessionStorage.setItem("owner", "yes");
    if (CustomValidation()) {
      dispatch(
        putOwnerInfoSave(
          localStorage.getItem("id"),
          {
            dateOfBirth: formatDateWithYYMMDD(dateOfBirth),
            firstName: firstName,
            lastName: lastName,
            middleName: middleName || "",
            nid: nid,
            requestId: 6,
            tin: tin,
            title: title,
            website: website || "",
            email,
            phoneNumber,
            phoneCode,
          },
          createContractMoveingDecideSave
        )
      );
    }
  };

  // button filled (next)
  const buttonFilledHandle = () => {
    sessionStorage.setItem("owner", "yes");
    if (CustomValidation()) {
      dispatch(
        putOwnerInfoSave(
          localStorage.getItem("id"),
          {
            dateOfBirth: formatDateWithYYMMDD(dateOfBirth),
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            nid: nid,
            requestId: 6,
            tin: tin,
            title: title,
            website: website,
            email,
            phoneNumber,
            phoneCode,
          },
          createContractMoveingDecideNext
        )
      );
    }
  };

  // submit admin review
  const adminReviewSubmit = () => {
    dispatch(
      reviewSubmission(ReviewFor.OWNER, response.data.contractId, {
        ownerReject: rejectionArray,
        requestId: "string",
      })
    );
  };

  useEffect(() => {
    if (localStorage.getItem("id")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      authAxios
        .get(
          `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
            "id"
          )}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("response all country data:", response.data.countries);
          setCountryPhoneCodeArr(response.data.countries);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  console.log("tin", tin);

  return (
    <div className="w-full">
      {withoutHeader ? (
        <></>
      ) : (
        <>
          {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
            <Pagenation />
          ) : null}
          <HorizontalBar selectedBar="Owner" />
        </>
      )}
      <div className="p-4">
        {response.loading ? (
          <div className="ml-[45%]">
            <Loading />
          </div>
        ) : (
          <React.Fragment>
            <div className="">
              <div
                className={`p-10 xl:px-0 xl:container mx-auto ${
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? "gap-y-4 flex flex-col"
                    : "grid grid-cols-2 gap-28"
                }`}
              >
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 min-w-full" ref={ref}>
                    <SingleSelect
                      title={title}
                      setTitle={setTitle}
                      width={width}
                    />
                    <InputComponent
                      inputValue={firstName}
                      setInputValue={setFirstName}
                      placeholder="First Name"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={rejectionArray}
                      setRejectionArray={setRejectionArray}
                      name="firstName"
                      required={true}
                    />
                  </div>
                  <InputComponent
                    inputValue={middleName}
                    setInputValue={setMiddleName}
                    placeholder="Middle Name (optional)"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="middleName"
                  />
                  <InputComponent
                    inputValue={lastName}
                    setInputValue={setLastName}
                    placeholder="Last Name"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="lastName"
                    required={true}
                  />
                  <SingleSelectPhone
                    inputValue={phoneNumber}
                    setInputValue={setPhone}
                    title={phoneCode}
                    setTitle={setPhoneCode}
                    titleArr={countryPhoneCodeArr}
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="phoneNumber"
                    required={true}
                  />
                  <div>
                    <Calender
                      setSelectedDate={setDob}
                      selectedData={dateOfBirth ? new Date(dateOfBirth) : null}
                      placeholder="Date of Birth"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={rejectionArray}
                      setRejectionArray={setRejectionArray}
                      name="dateOfBirth"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <InputComponent
                    inputValue={email}
                    setInputValue={setEmail}
                    placeholder="Email"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="email"
                    required={true}
                  />
                  <InputComponent
                    inputValue={website}
                    setInputValue={setWeb}
                    placeholder="website(optional)"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="website"
                  />
                  <InputComponent
                    inputValue={nid}
                    setInputValue={setNid}
                    placeholder="#NID"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="nid"
                    required={true}
                  />
                  <InputComponent
                    inputValue={tin}
                    setInputValue={setTin}
                    placeholder="#TIN"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={rejectionArray}
                    setRejectionArray={setRejectionArray}
                    name="tin"
                    required={true}
                  />
                </div>
                <div>
                  {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
                    response.loading ? null : (
                      <div className="flex px-10 xl:p-0 xl:container mx-auto">
                        <ButtonOutlined
                          title="Save"
                          buttonOutlinedHandle={buttonOutlinedHandle}
                          w="176px"
                          h="49px"
                          disable={
                            fetchSummeryData.status === "PENDING" ? "yes" : "no"
                          }
                        />
                        <ButtonFilled
                          title="Next"
                          buttonFilledHandle={buttonFilledHandle}
                          w="291px"
                          h="49px"
                          disable={
                            fetchSummeryData.status === "PENDING" ? "yes" : "no"
                          }
                        />
                      </div>
                    )
                  ) : response.loading ? null : (
                    <div className="flex justify-center">
                      <ButtonFilled
                        title="Review Submit"
                        buttonFilledHandle={adminReviewSubmit}
                        w="291px"
                        h="49px"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OwnerComponent;
