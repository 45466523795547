import React, { useState } from "react";
export const numberToArray = (day) => {
  const dayNumber = [];
  for (let i = 0; i < day; i++) {
    dayNumber.push(i + 1);
  }
  return dayNumber;
};

const DragAndDropAccommodation = ({
  keyName,
  selectedNumber,
  items,
  IdentifyName,
  setTargets,
  targets,
}) => {
  const convertedArray = numberToArray(selectedNumber);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();

    const targetId = `${keyName}${targetIndex}`;

    if (targets[targetId]?.includes(draggedItem)) {
      return;
    }

    // Update the state with the dropped item in the targets object
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      // newTargets[targetId] = [newTargets[targetId] || [], draggedItem];
      newTargets[targetId] = draggedItem;
      return newTargets;
    });
    // setTargets(draggedItem);
    // setTargets((prevTargets) => {
    //   const newTargets = { ...prevTargets };
    //   newTargets[targetId] = [...(newTargets[targetId] || []), draggedItem];
    //   return newTargets;
    // });

    // Reset the dragged item state
    setDraggedItem(null);
  };

  const handleRemoveItem = (targetId, item) => {
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      // newTargets[targetId] = newTargets[targetId].filter((i) => i !== item);

      // // Check if the array length is 0, and delete the key
      // if (newTargets[targetId].length === 0) {
      //   delete newTargets[targetId];
      // }
      delete newTargets[targetId];

      return newTargets;
    });
  };
  console.log(
    "targetssss",
    // Object.keys(targets)?.map((key) => key)
    targets[`${keyName}${1}`]
  );
  return (
    <div className="flex flex-col gap-y-[20px] ">
      <div className="min-h-[270px] overflow-y-scroll">
        <h2 className="text-[18px]">Availabile Packages :</h2>
        <div className="flex justify-start gap-[16px] max-h-[250px] overflow-y-scroll p-[10px] flex-col border-b border-btn-secondary">
          {items?.map((item, index) => (
            <div
              draggable
              className="shadow-customShadow border border-btn-secondary px-[10px] py-[4px] rounded-[4px] text-txt-primary w-full"
              onDragStart={(e) => handleDragStart(e, item)}
              key={index}
            >
              <div className="w-full">
                <p className="text-center border-b border-btn-secondary text-btn-secondary">
                  {item?.tour_package_accommodation_name}
                </p>
                <div className="flex flex-col gap-[3px] mt-[4px]">
                  {item?.tour_package_room_category_name} Category{" "}
                  {item?.tour_package_room_type_name} Type Room{" "}
                  {item?.bed_configuration} Bed
                </div>
                <p>Price per Night : {item?.per_night_room_price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="max-h-[300px]  overflow-y-scroll">
        {convertedArray?.map((index) => (
          <div
            key={index}
            id={`${keyName}${index}`}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, index)}
            className="shadow-customShadow  p-[10px] rounded-[4px] "
          >
            <div className="text-[16px] bg-btn-secondary text-bg-white p-[4px] text-center">
              {" "}
              {IdentifyName} {keyName} {index}
            </div>

            {targets[`${keyName}${index}`] && (
              <li
                // key={itemIndex}
                draggable
                onDragStart={(e) =>
                  handleDragStart(e, targets[`${keyName}${index}`])
                }
                className="mt-[10px] flex justify-start gap-[10px] flex-row shadow-customShadow p-[10px] relative w-[200px]"
              >
                <div className="w-full">
                  <p className="text-center border-b border-btn-secondary text-btn-secondary">
                    {
                      targets[`${keyName}${index}`]
                        ?.tour_package_accommodation_name
                    }
                  </p>
                  <div className="flex flex-col gap-[3px] mt-[4px]">
                    {
                      targets[`${keyName}${index}`]
                        ?.tour_package_room_category_name
                    }{" "}
                    Category{" "}
                    {targets[`${keyName}${index}`]?.tour_package_room_type_name}{" "}
                    Type Room {targets[`${keyName}${index}`]?.bed_configuration}{" "}
                    Bed
                  </div>

                  <p>
                    Price per Night :{" "}
                    {targets[`${keyName}${index}`]?.per_night_room_price}
                  </p>
                </div>
                <div className="absolute top-[-6px] right-[-6px]">
                  <button
                    className="border rounded-full  w-[25px] h-[25px] border-btn-pink text-btn-pink"
                    onClick={() =>
                      handleRemoveItem(
                        `${keyName}${index}`,
                        targets[`${keyName}${index}`]
                      )
                    }
                  >
                    X
                  </button>
                </div>
              </li>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DragAndDropAccommodation;
