import React from "react";

export const color = {
  NEW: "bg-[#4E90A8]",
  IN_PROGRESS: "bg-[#F8C471]",
  ACCEPTED: "bg-[#63a561]",
  REJECTED: "bg-[#FE9A9A]",
};
export const bcolor = {
  NEW: "border-[#4E90A8]",
  IN_PROGRESS: "border-[#FF9500]",
  ACCEPTED: "border-[#63a561]",
  REJECTED: "border-[#FE9A9A]",
};
export const tcolor = {
  NEW: "text-[#4E90A8]",
  IN_PROGRESS: "text-[#FF9500]",
  ACCEPTED: "text-[#63a561]",
  REJECTED: "text-[#FE9A9A]",
};

export default function BookingReviewCart({
  booking,
  handleInProgressState,
  status,
  dotView,
}) {
  // console.log("booking", booking);
  return (
    <div className="shadow-customShadow min-w-[280px] min-h-[169px] rounded-[4px] pb-[8px]">
      {/* card content */}
      <div className="px-[15px] text-[12px] flex flex-col gap-y-[12px] relative">
        {dotView && (
          <div
            onClick={() =>
              handleInProgressState(booking?.rentalBookingReviewId, status)
            }
            className="flex justify-end cursor-pointer w-[60px] absolute right-[10px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M23.9297 13.8086C22.8251 13.8086 21.9297 14.704 21.9297 15.8086C21.9297 16.9132 22.8251 17.8086 23.9297 17.8086C25.0343 17.8086 25.9297 16.9132 25.9297 15.8086C25.9297 14.704 25.0343 13.8086 23.9297 13.8086Z"
                fill="#376576"
              />
              <path
                d="M15.9297 13.8086C14.8251 13.8086 13.9297 14.704 13.9297 15.8086C13.9297 16.9132 14.8251 17.8086 15.9297 17.8086C17.0343 17.8086 17.9297 16.9132 17.9297 15.8086C17.9297 14.704 17.0343 13.8086 15.9297 13.8086Z"
                fill="#376576"
              />
              <path
                d="M7.92969 13.8086C6.82512 13.8086 5.92969 14.704 5.92969 15.8086C5.92969 16.9132 6.82512 17.8086 7.92969 17.8086C9.03426 17.8086 9.92969 16.9132 9.92969 15.8086C9.92969 14.704 9.03426 13.8086 7.92969 13.8086Z"
                fill="#376576"
              />
            </svg>
          </div>
        )}
        <div className="flex flex-col justify-start cursor-pointer w-[120px] absolute left-[12px] top-[12px]">
          <p className={`${tcolor[`${booking?.state}`]} `}>
            Review id : #{booking?.rentalBookingReviewId}
          </p>
        </div>

        <p className="text-center text-btn-secondary text-[11px] mt-[50px]">
          {booking?.hotelName}
        </p>

        <p className="text-center text-[12px] mt-[-10px]">
          Order Code : {booking?.orderCode}
        </p>
        <div className="flex flex-col gap-y-[6px]">
          <div className="flex justify-between">
            <p className="text-txt-hint whitespace-nowrap">Customer Name : </p>
            <p className="text-txt-primary pl-[2px] ">{booking?.userName}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-txt-hint">Customer Phone :</p>
            <p className="text-txt-primary">{booking?.phoneNumber}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-txt-hint">Booking Time :</p>
            <p className="text-txt-primary">{booking?.bookingTime}</p>
          </div>
        </div>

        <div>
          <h2 className=" mb-[4px] text-txt-hint">Order Summary : </h2>
          <div className="flex flex-col gap-y-[6px]">
            {booking?.roomBookingData?.map((room, index) => (
              <div
                className={`${
                  index >= 0 && index !== booking?.roomBookingData?.length - 1
                    ? " border-txt-hint border-b-[0.5px]"
                    : "border-none"
                } pb-[6px]`}
              >
                <div className="flex items-center justify-start space-x-[2px]">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={`w-[14px] h-[14px] text-txt-hint ${
                        tcolor[`${booking?.state}`]
                      } `}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <p>
                    {room?.roomCategoryName}{" "}
                    <span
                      className={`text-[14px]  ${tcolor[`${booking?.state}`]}`}
                    >
                      x{room?.noOfTimes}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>In : {room?.checkinDate}</p>
                  <p>Out : {room?.checkoutDate}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between ">
          <div
            className={`flex border border-[0.3px] px-[5px] ${
              bcolor[`${booking?.state}`]
            } rounded-[4px] `}
          >
            <p className="text-txt-hint">Total :</p>
            <p className="text-txt-primary pl-[2px]">
              {booking?.totalAmount} BDT
            </p>
          </div>
          <p
            className={`${
              color[`${booking?.state}`]
            } px-[6px] py-[1px] text-bg-white rounded-[2px]`}
          >
            {booking?.state}
          </p>
        </div>
      </div>
    </div>
  );
}
