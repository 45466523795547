// import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
// import { authAxios } from "../../utils/axiosWrapper";
// import TourMerchantButton from "../TourMerchantButton";
// import { animatedComponents, colorStyles } from "../Utilities/SelectAnimation";

// export default function TourGuide({
//   setMarchantCreateDatas,
//   setPackageSelect,
//   marchantTourCreateDatas,
// }) {
//   const [guidePackage, setGuidePackage] = useState({
//     number_of_guides_for_day: "",
//     tour_guide_price_per_day: "",
//     day_number: [],
//   });
//   const [currentIndex, setCurrentIndex] = useState(null);
//   const [guidePackageList, setGuidePackageList] = useState([]);
//   const [updateButtonEnable, setUpdateButtonEnable] = useState(false);

//   // useEffect(() => {
//   //   setGuidePackage({
//   //     number_of_guides:
//   //       marchantTourCreateDatas?.tour_package_guide_options?.number_of_guides,
//   //     tour_guide_price_per_day:
//   //       marchantTourCreateDatas?.tour_package_guide_options?.tour_guide_price_per_day,
//   //     tour_guide_provided_in_days:
//   //       marchantTourCreateDatas?.tour_package_guide_options
//   //         ?.tour_guide_provided_in_days,
//   //     tour_package_option_is_active:
//   //       marchantTourCreateDatas?.tour_package_guide_options
//   //         ?.tour_package_option_is_active,
//   //     tour_package_option_is_default:
//   //       marchantTourCreateDatas?.tour_package_guide_options
//   //         ?.tour_package_option_is_default,
//   //   });
//   // }, [marchantTourCreateDatas]);

//   const [getToursById, setGetToursById] = useState();

//   // For get Days
//   const numberOfDay = Array.from(
//     { length: getToursById?.number_of_days },
//     (_, index) => index + 1
//   );

//   useEffect(() => {
//     authAxios
//       .get(
//         `${
//           baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API
//         }${1}?requestId=er34`
//       )
//       .then((res) => {
//         setGetToursById(res?.data);
//         // console.log(res?.data);
//       })
//       .catch((err) => {});
//   }, []);

//   const dayProvide = numberOfDay?.map((day) => ({
//     label: `${day} Day`,
//     value: day,
//   }));
//   const handleUpdateButton = () => {
//     if (
//       guidePackage?.transfer_route !== undefined &&
//       guidePackage?.tour_transfer_mode_id?.label !== undefined &&
//       guidePackage?.tour_transfer_provider_id?.label !== undefined &&
//       guidePackage?.suitable_for_persons > 0 &&
//       guidePackage?.per_vehicle_per_trip_price > 0
//     ) {
//       setGuidePackageList((prev) => {
//         const updatedArray = prev.map((item, index) => {
//           if (index === currentIndex) {
//             return guidePackage;
//           }
//           return item;
//         });

//         return updatedArray;
//       });

//       setUpdateButtonEnable(false);
//       setGuidePackage({
//         number_of_guides_for_day: "",
//         tour_guide_price_per_day: "",
//         day_number: [],
//       });
//     }
//   };

//   const handleAddGuide = () => {
//     if (
//       guidePackage.number_of_guides_for_day > 0 &&
//       guidePackage.tour_guide_price_per_day > 0 &&
//       guidePackage.day_number?.length > 0
//     ) {
//       // setMarchantCreateDatas((prevPackage) => ({
//       //   ...prevPackage,
//       //   tour_package_guide_options: [
//       //     ...prevPackage.tour_package_guide_options,
//       //     guidePackage,
//       //   ],
//       // }));
//       setGuidePackageList((prev) => [
//         ...prev,
//         {
//           number_of_guides_for_day: guidePackage.number_of_guides_for_day,
//           tour_guide_price_per_day: guidePackage.tour_guide_price_per_day,
//           day_number: guidePackage.day_number,
//         },
//       ]);

//       setGuidePackage({
//         number_of_guides_for_day: "",
//         tour_guide_price_per_day: "",
//         day_number: [],
//       });
//       // setPackageSelect(null);
//     }
//   };
//   const handleSubmitGuide = () => {
//     setMarchantCreateDatas((prevPackage) => {
//       const updatedGuideOptions = [
//         ...prevPackage.tour_package_guide_options,
//         {
//           tour_package_guide_packages: guidePackageList,
//         },
//       ];

//       return {
//         ...prevPackage,
//         tour_package_guide_options: updatedGuideOptions,
//       };
//     });
//     setPackageSelect(null);
//   };
//   const [updateIndex, setUpdateIndex] = useState(null);
//   const [updateSubmitButtonCondition, setUpdateSubmitButtonCondition] =
//     useState(false);

//   const handleSubmitAccommondationUpdate = () => {
//     setMarchantCreateDatas((prevPackage) => {
//       const { tour_package_guide_options } = prevPackage;

//       const indexToUpdate = updateIndex;

//       const updatedAccommodationOptions = [...tour_package_guide_options];

//       updatedAccommodationOptions[indexToUpdate] = {
//         ...updatedAccommodationOptions[indexToUpdate],
//         tour_package_guide_packages: guidePackageList,
//       };

//       return {
//         ...prevPackage,
//         tour_package_guide_options: updatedAccommodationOptions,
//       };
//     });
//     setGuidePackageList([]);

//     setGuidePackage({
//       number_of_guides_for_day: "",
//       tour_guide_price_per_day: "",
//       day_number: [],
//     });
//   };
//   const handleRemoveAccommondation = (ind) => {
//     const newArray = guidePackageList?.filter((meal, index) => index !== ind);

//     setGuidePackageList(newArray);
//     setMarchantCreateDatas((prevPackage) => {
//       const updatedAccommodationOptions =
//         newArray?.length > 0
//           ? [
//               {
//                 tour_package_guide_packages: newArray,
//               },
//             ]
//           : [];

//       return {
//         ...prevPackage,
//         tour_package_guide_packages: updatedAccommodationOptions,
//       };
//     });

//     // setMarchantCreateData((prevPackage) => ({
//     //   ...prevPackage,
//     //   tour_package: {
//     //     ...prevPackage.tour_package,
//     //     tour_package_accommodation_packages: newArray,
//     //   },
//     // }));
//   };
//   const handleEditAccommondation = (ind) => {
//     // const newArray = accommondationPackageList?.filter(
//     //   (meal, index) => index === ind
//     // );

//     let newArray = guidePackageList?.filter((packages, index) => index === ind);
//     setGuidePackage({
//       number_of_guides_for_day: newArray[0].number_of_guides_for_day,
//       tour_guide_price_per_day: newArray[0].tour_guide_price_per_day,
//       day_number: newArray[0].day_number,
//     });
//     setUpdateIndex(ind);
//     setCurrentIndex(ind);
//     setUpdateButtonEnable(true);
//   };
//   return (
//     <>
//       <div className="my-[10px] ">
//         <button
//           onClick={() => setPackageSelect(null)}
//           className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
//         >
//           <span className="text-start text-[20px]">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               strokeWidth={1.5}
//               stroke="currentColor"
//               className="w-6 h-6"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 d="M15.75 19.5L8.25 12l7.5-7.5"
//               />
//             </svg>
//           </span>
//           Back
//         </button>
//       </div>
//       <div className="flex justify-between items-start bg-bg-white p-[12px] gap-x-[40px]">
//         <div className="w-full mx-auto shadow-customShadow p-[10px] rounded-[4px] ">
//           <h2 className="text-center text-[22px] text-btn-secondary">
//             Tour Guide
//           </h2>

//           <div className="my-[16px]">
//             <div>
//               <p className="text-btn-secondary">Number of Guide: </p>
//             </div>
//             <div className="rounded">
//               <input
//                 className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
//                 placeholder="Guide Number"
//                 type="number"
//                 onChange={(e) => {
//                   const inputVal = e.target.value;

//                   if (!isNaN(inputVal)) {
//                     setGuidePackage((prev) => ({
//                       ...prev,
//                       number_of_guides_for_day: inputVal,
//                     }));
//                   }
//                 }}
//                 value={guidePackage?.number_of_guides_for_day}
//               />
//             </div>
//           </div>

//           <div className="my-[16px]">
//             <div>
//               <p className="text-btn-secondary">Price Per Day: </p>
//             </div>
//             <div className="rounded">
//               <input
//                 className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
//                 placeholder="Per Day price"
//                 type="number"
//                 onChange={(e) => {
//                   const inputVal = e.target.value;

//                   if (!isNaN(inputVal)) {
//                     setGuidePackage((prev) => ({
//                       ...prev,
//                       tour_guide_price_per_day: inputVal,
//                     }));
//                   }
//                 }}
//                 value={guidePackage?.tour_guide_price_per_day}
//               />
//             </div>
//           </div>

//           <div className="my-[16px]">
//             <div>
//               <p className="text-btn-secondary">Day You Provide Gide: </p>
//             </div>
//             <div className="rounded">
//               <Select
//                 value={guidePackage?.day_number || ""}
//                 onChange={(data) => {
//                   setGuidePackage((prev) => ({
//                     ...prev,
//                     day_number: data,
//                   }));
//                 }}
//                 components={animatedComponents}
//                 options={dayProvide}
//                 placeholder={"Select Food: "}
//                 styles={colorStyles}
//                 isMulti
//               />
//             </div>
//           </div>

//           <div className="mt-[32px] mb-[16px]">
//             {updateButtonEnable ? (
//               <TourMerchantButton
//                 name={"Update"}
//                 width={"w-full"}
//                 onClick={() => handleUpdateButton()}
//               ></TourMerchantButton>
//             ) : (
//               <TourMerchantButton
//                 name={"Add"}
//                 width={"w-full"}
//                 onClick={() => handleAddGuide()}
//               ></TourMerchantButton>
//             )}
//           </div>
//         </div>
//         <div className="w-full flex justify-end ">
//           <div className="flex flex-col w-full gap-y-[10px]">
//             <div className="flex flex-wrap  gap-[16px]">
//               {guidePackageList?.map((guide, index) => (
//                 <div
//                   key={index}
//                   className="shadow-customShadow p-[10px] rounded-[4px] relative"
//                 >
//                   <p>Guide Number : {guide?.number_of_guides_for_day}</p>
//                   <p>Price : {guide?.tour_guide_price_per_day}</p>
//                   <p>Provided Day :</p>
//                   <div className="flex gap-x-[6px] ">
//                     {guide?.day_number?.map((day, index) => (
//                       <p
//                         key={index}
//                         className="bg-btn-secondary py-[4px] px-[6px] rounded-[4px] text-bg-white"
//                       >
//                         {day?.label}
//                       </p>
//                     ))}
//                   </div>
//                   <span
//                     onClick={() => handleRemoveAccommondation(index)}
//                     className="absolute right-[-12px] cursor-pointer top-[-12px]"
//                   >
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       strokeWidth={1.5}
//                       stroke="currentColor"
//                       className="w-[25px] h-[25px] text-btn-pink"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//                       />
//                     </svg>
//                   </span>
//                   <span
//                     onClick={() => handleEditAccommondation(index)}
//                     className="absolute left-[-12px] cursor-pointer top-[-12px]"
//                   >
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       strokeWidth={1.5}
//                       stroke="currentColor"
//                       className="w-6 h-6"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
//                       />
//                     </svg>
//                   </span>
//                 </div>
//               ))}
//             </div>

//             {guidePackageList?.length >= 1 && (
//               <>
//                 {updateSubmitButtonCondition ? (
//                   <div className="">
//                     <TourMerchantButton
//                       onClick={() => handleSubmitAccommondationUpdate()}
//                       name={"Update"}
//                       width={"w-full"}
//                     ></TourMerchantButton>
//                   </div>
//                 ) : (
//                   <div className="">
//                     <TourMerchantButton
//                       onClick={() => handleSubmitGuide()}
//                       name={"Submit"}
//                       width={"w-full"}
//                     ></TourMerchantButton>
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import Select from "react-select";

import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";
import { animatedComponents, colorStyles } from "../Utilities/SelectAnimation";
import TourGuidePackageTable from "./TourGuidePackageTable";

const TransferPackage = ({
  setMarchantCreateDatas,
  setPackageSelect,

  marchantTourCreateDatas,
  setViewPage,
  viewPage,
}) => {
  const [getToursById, setGetToursById] = useState();
  useEffect(() => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API
        }${1}?requestId=er34`
      )
      .then((res) => {
        setGetToursById(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  const [updateButtonEnable, setUpdateButtonEnable] = useState(false);

  const handleBackButton = () => {
    setPackageSelect(null);
  };

  const [guidePackageList, setGuidePackageList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [guidePackage, setGuidePackage] = useState({
    number_of_guides_for_day: "",
    tour_guide_price_per_day: "",
    day_number: "",
  });

  const [updateIndex, setUpdateIndex] = useState(null);
  const [updateSubmitButtonCondition, setUpdateSubmitButtonCondition] =
    useState(false);
  const handleEdit = (packageList, ind) => {
    setGuidePackageList(packageList);
    setPackageSelect(4);
    setViewPage(null);
    setUpdateSubmitButtonCondition(true);
    setUpdateIndex(ind);
  };

  const numberOfDay = Array.from(
    { length: getToursById?.number_of_days },
    (_, index) => index + 1
  );
  const dayProvide = numberOfDay?.map((day) => ({
    label: `${day} Day`,
    value: day,
  }));

  const handleAddButtonClick = () => {
    if (
      guidePackage?.number_of_guides_for_day > 0 &&
      guidePackage?.tour_guide_price_per_day > 0 &&
      guidePackage?.day_number?.label !== undefined
    ) {
      setGuidePackageList((prev) => [
        ...prev,
        {
          number_of_guides_for_day: guidePackage.number_of_guides_for_day,
          tour_guide_price_per_day: guidePackage.tour_guide_price_per_day,
          day_number: guidePackage.day_number,
        },
      ]);

      setGuidePackage({
        number_of_guides_for_day: "",
        tour_guide_price_per_day: "",
        day_number: "",
      });
    }
  };

  const handleUpdateButton = () => {
    if (
      guidePackage?.number_of_guides_for_day > 0 &&
      guidePackage?.tour_guide_price_per_day > 0 &&
      guidePackage?.day_number?.label !== undefined
    ) {
      setGuidePackageList((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === currentIndex) {
            return guidePackage;
          }
          return item;
        });

        return updatedArray;
      });

      setUpdateButtonEnable(false);
      setGuidePackage({
        number_of_guides_for_day: "",
        tour_guide_price_per_day: "",
        day_number: "",
      });
    }
  };

  const handleSubmitGuide = () => {
    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions = [
        ...prevPackage.tour_package_guide_options,
        {
          tour_package_guide_packages: guidePackageList,
        },
      ];

      return {
        ...prevPackage,
        tour_package_guide_options: updatedAccommodationOptions,
      };
    });

    setGuidePackageList([]);

    setGuidePackage({
      number_of_guides_for_day: "",
      tour_guide_price_per_day: "",
      day_number: "",
    });

    setViewPage(1);
  };

  const handleSubmitGuideUpdate = () => {
    setMarchantCreateDatas((prevPackage) => {
      const { tour_package_transfer_options } = prevPackage;

      const indexToUpdate = updateIndex;

      const updatedAccommodationOptions = [...tour_package_transfer_options];

      updatedAccommodationOptions[indexToUpdate] = {
        ...updatedAccommodationOptions[indexToUpdate],
        tour_package_transfer_packages: guidePackageList,
      };

      return {
        ...prevPackage,
        tour_package_transfer_options: updatedAccommodationOptions,
      };
    });
    setGuidePackageList([]);

    setGuidePackage({
      number_of_guides_for_day: "",
      tour_guide_price_per_day: "",
      day_number: "",
    });

    setViewPage(1);
  };

  const handleRemoveGuide = (ind) => {
    const newArray = guidePackageList?.filter((meal, index) => index !== ind);

    setGuidePackageList(newArray);
    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions =
        newArray?.length > 0
          ? [
              {
                tour_package_guide_packages: newArray,
              },
            ]
          : [];

      return {
        ...prevPackage,
        tour_package_guide_options: updatedAccommodationOptions,
      };
    });
  };

  const handleEditGuide = (ind) => {
    let newArray = guidePackageList?.filter((packages, index) => index === ind);
    setGuidePackage({
      number_of_guides_for_day: newArray[0].number_of_guides_for_day,
      tour_guide_price_per_day: newArray[0].tour_guide_price_per_day,
      day_number: newArray[0].day_number,
    });

    setCurrentIndex(ind);
    setUpdateButtonEnable(true);
  };

  return (
    <>
      {viewPage === null ? (
        <>
          <p className="text-btn-secondary text-center mb-[24px] xl:text-[20px] md:text-[18px] text-[16px]">
            Tour Guide
          </p>

          <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton(null)}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>

          <div>
            <div className="flex flex-col md:flex-row ">
              <div className="md:w-[70%] w-full  rounded p-[16px] bg-bg-white h-full">
                <div className="mt-[16px] mb-[8px] ">
                  <div className="my-[16px]">
                    <div>
                      <p className="text-btn-secondary">Number of Guide: </p>
                    </div>
                    <div className="rounded">
                      <input
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        placeholder="Guide Number"
                        type="number"
                        onChange={(e) => {
                          const inputVal = e.target.value;

                          if (!isNaN(inputVal)) {
                            setGuidePackage((prev) => ({
                              ...prev,
                              number_of_guides_for_day: inputVal,
                            }));
                          }
                        }}
                        value={guidePackage?.number_of_guides_for_day}
                      />
                    </div>
                  </div>

                  <div className="my-[16px]">
                    <div>
                      <p className="text-btn-secondary">Price Per Day: </p>
                    </div>
                    <div className="rounded">
                      <input
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        placeholder="Per Day price"
                        type="number"
                        onChange={(e) => {
                          const inputVal = e.target.value;

                          if (!isNaN(inputVal)) {
                            setGuidePackage((prev) => ({
                              ...prev,
                              tour_guide_price_per_day: inputVal,
                            }));
                          }
                        }}
                        value={guidePackage?.tour_guide_price_per_day}
                      />
                    </div>
                  </div>

                  <div className="my-[16px]">
                    <div>
                      <p className="text-btn-secondary">
                        Day You Provide Gide:{" "}
                      </p>
                    </div>
                    <div className="rounded">
                      <Select
                        value={guidePackage?.day_number || ""}
                        onChange={(data) => {
                          setGuidePackage((prev) => ({
                            ...prev,
                            day_number: data,
                          }));
                        }}
                        components={animatedComponents}
                        options={dayProvide}
                        placeholder={"Select Day: "}
                        styles={colorStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[32px] mb-[16px]">
                  {updateButtonEnable ? (
                    <TourMerchantButton
                      name={"Update"}
                      width={"w-full"}
                      onClick={() => handleUpdateButton()}
                    ></TourMerchantButton>
                  ) : (
                    <TourMerchantButton
                      name={"Add"}
                      width={"w-full"}
                      onClick={() => handleAddButtonClick()}
                    ></TourMerchantButton>
                  )}
                </div>
              </div>
              <div className="md:w-[30%] w-full bg-[#e5e7eb] md:pl-[16px] mt-[16px] md:mt-0">
                {guidePackageList?.length === 0 && (
                  <div className="bg-bg-white p-[16px] w-full rounded text-btn-secondary text-center">
                    No Item Selected
                  </div>
                )}

                {/* Right-Side Nav */}
                <div className="max-h-[1000px] overflow-y-scroll mb-[20px] mt-[30px] ">
                  <div className="w-full flex justify-end p-[12px]">
                    <div className="flex flex-col w-full gap-y-[10px]">
                      <div className="flex flex-col  gap-[16px]">
                        {guidePackageList?.map((guide, index) => (
                          <div
                            key={index}
                            className="shadow-customShadow p-[10px] rounded-[4px] relative"
                          >
                            <p>
                              Guide Number : {guide?.number_of_guides_for_day}
                            </p>
                            <p>Price : {guide?.tour_guide_price_per_day}</p>
                            <p>Provided Day :</p>
                            <div className="flex gap-x-[6px] ">
                              <p
                                key={index}
                                className="bg-btn-secondary py-[4px] px-[6px] rounded-[4px] text-bg-white"
                              >
                                {guide?.day_number?.label}
                              </p>
                            </div>
                            <span
                              onClick={() => handleRemoveGuide(index)}
                              className="absolute right-[-12px] cursor-pointer top-[-12px]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-[25px] h-[25px] text-btn-pink"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                            <span
                              onClick={() => handleEditGuide(index)}
                              className="absolute left-[-12px] cursor-pointer top-[-12px]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                              </svg>
                            </span>
                          </div>
                        ))}
                      </div>
                      {guidePackageList?.length >= 1 && (
                        <>
                          {updateSubmitButtonCondition ? (
                            <div className="">
                              <TourMerchantButton
                                onClick={() => handleSubmitGuideUpdate()}
                                name={"Update"}
                                width={"w-full"}
                              ></TourMerchantButton>
                            </div>
                          ) : (
                            <div className="">
                              <TourMerchantButton
                                onClick={() => handleSubmitGuide()}
                                name={"Submit"}
                                width={"w-full"}
                              ></TourMerchantButton>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <TourGuidePackageTable
          setPackageSelect={setPackageSelect}
          setViewPage={setViewPage}
          marchantTourCreateDatas={marchantTourCreateDatas}
          setMarchantCreateDatas={setMarchantCreateDatas}
          handleEdit={handleEdit}
          setUpdateSubmitButtonCondition={setUpdateSubmitButtonCondition}
        />
      )}
    </>
  );
};

export default TransferPackage;
