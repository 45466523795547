import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { UserType } from "../../contants/Constants";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { contractInfo } from "../../store/admin/contract/api/adminContractApi";
import { fetchSummeryGet } from "../../store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import { authAxios } from "../../utils/axiosWrapper";
import Calender from "../Calender";
import InputComponent from "../InputComponent";
import Loading from "../Loader/Loading";
import SingleSelectFromSimpleArray from "../singleSelect/SingleSelectFromSimpleArray";
import SingleSelectParent from "../singleSelect/SingleSelectParent";

const ContractComponentV2 = ({ setActiveStep, setRejectionArrays }) => {
  const router = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const contractId = JSON.parse(localStorage.getItem("contractId")) ?? 0;
  const [contractName, setContractName] = useState("");
  const [signedBy, setSignedby] = useState("");
  const [signDate, setSignData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [validDate, setValidDate] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [postOffice, setPostOffice] = useState("");
  const [countryCode, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street, setAddress] = useState("");
  const [optionalPart, setAddressOptional] = useState("");
  const [division, setDivision] = useState("");
  const [city, setCity] = useState("");
  const [municipility, setMunicipility] = useState("");
  const [community, setCommunity] = useState("");

  const [apiCity, setApiCity] = useState();
  const [apiMunicipility, setApiMunicipility] = useState();
  const [apicomm, setApicomm] = useState();
  const [apiDivision, setApiDivision] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [apiZips, setApiZips] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      contractInfo(
        process.env.REACT_APP_USER_TYPE,
        router?.search?.split("=")[1]
      )
    );
  }, []);

  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  console.log(fetchSummeryData, "fetchSummeryData");

  // some helper state
  //const [rejectionArray, setRejectionArray] = useState([]);
  const [addressRejectArray, setAddressRejectArray] = useState({});
  const [contractInfoRejectArray, setContractInfoRejectArray] = useState({});

  // console.log(rejectionArray, "rejectionArray");
  let response = useSelector((state) => state.contractInfoGetReducer);

  useEffect(() => {
    fetchSummeryGet(localStorage.getItem("id"));
  }, []);

  // get the data and fill up the existing data
  useEffect(() => {
    if (response && response.data && response.data.contractAddress) {
      setContractName(response.data.contractName);
      setSignedby(response.data.contractSignedBy);
      setSignData(response.data.contractSignDate);
      setStartDate(response.data.startDate);
      setValidDate(response.data.endDate);
      setOrganizationName(response.data.organizationName);
      setAddress(response.data.contractAddress.street);
      setAddressOptional(response.data.contractAddress.optionalPart);
      setCountry(response.data.contractAddress.country.countryCode);
      setZipCode(response.data.contractAddress.zip);
      setDivision(response.data.contractAddress.admin1);
      setCity(response.data.contractAddress.admin3);
      setMunicipility(response.data.contractAddress.admin2);
      setCommunity(response.data.contractAddress.admin4);
      setContractInfoRejectArray(response.data.contractInfoReject);
      setAddressRejectArray(response.data.contractAddress.addressReject);
      setIsLoading(false);
    }
  }, [response]);

  // country fetch
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    if (countryCode) {
      authAxios
        .get(
          `${
            PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE
          }${countryCode}?requestId=${localStorage.getItem("id")}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("response contry data: ", response.data);
          setApiDivision(response.data.admin1s);
          setApiCity(response.data.admin2s);
          setApiMunicipility(response.data.admin3s);
          setApicomm(response.data.places);
          setApiZips(response.data.zips);
        })
        .catch((error) => {
          console.log(error);
          console.log("error");
        });
    }
  }, [countryCode]);

  // fetch all countries
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(
        `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRejection = () => {
    setRejectionArrays((prevState) => ({
      ...prevState,
      addressRejectionReasons: {
        ...(addressRejectArray || {}),
      },
      contractInfoRejectionReasons: {
        ...(contractInfoRejectArray || {}),
      },
    }));
  };

  return (
    <div className="w-full">
      {response.loading ? (
        <div className="ml-[45%]">
          <Loading />
        </div>
      ) : (
        <div className="">
          <div
            className={`px-10  xl:container mx-auto ${
              process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                ? ""
                : "grid grid-cols-2 gap-28 xl:p-0"
            }`}
          >
            <div className="flex flex-col gap-y-4">
              <h2 className="tracking-wide font-fontFamily-sans  text-[27px] font-normal leading-[37.8px] text-txt-primary">
                Contract Details
              </h2>
              <InputComponent
                required={true}
                inputValue={contractName}
                setInputValue={setContractName}
                placeholder="Contract Name"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="contractName"
              />
              <InputComponent
                required={true}
                inputValue={signedBy}
                setInputValue={setSignedby}
                placeholder="Contract Signed By"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="signedBy"
              />
              <div className="">
                <Calender
                  setSelectedDate={setSignData}
                  selectedData={signDate ? new Date(signDate) : null}
                  placeholder="Contract Sign Date"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setSignData"
                />
              </div>
              <div className="">
                <Calender
                  setSelectedDate={setStartDate}
                  selectedData={startDate ? new Date(startDate) : null}
                  placeholder="Contract Start Date"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setStartDate"
                />
              </div>
              <div className="">
                <Calender
                  setSelectedDate={setValidDate}
                  selectedData={validDate ? new Date(validDate) : null}
                  placeholder="Contract Valid Until"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setValidDate"
                />
              </div>
              <InputComponent
                inputValue={organizationName}
                setInputValue={setOrganizationName}
                placeholder="Organization Name (Optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="organizationName"
              />
              <InputComponent
                inputValue={postOffice}
                setInputValue={setPostOffice}
                placeholder="Post Office (Optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="postOffice"
              />
              <br></br>
            </div>
            <div className="flex flex-col gap-y-4">
              <h2 className="tracking-wide font-fontFamily-sans  text-[27px] font-normal leading-[37.8px] text-txt-primary">
                Billing Details
              </h2>
              {/* <div className="grid grid-cols-2 gap-4"> */}
              <div
                className={`my-0 ${
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? "grid grid-cols-1 gap-y-4"
                    : "grid grid-cols-2 gap-x-4"
                }`}
              >
                {allCountries && allCountries.length > 0 && (
                  <SingleSelectParent
                    required={true}
                    selectedValue={countryCode}
                    setSelectedValue={setCountry}
                    data={allCountries}
                    placeholder="Country"
                    displayField={"country"}
                    selectedFieldName="countryCode"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={addressRejectArray}
                    setRejectionArray={setAddressRejectArray}
                    name="countryCode"
                  />
                )}
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiZips}
                  selectedValue={zipCode}
                  setSelectedValue={setZipCode}
                  placeholder="Zip Code"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="zip"
                />
              </div>
              <InputComponent
                required={true}
                inputValue={street}
                setInputValue={setAddress}
                placeholder="Address"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="street"
              />
              <InputComponent
                inputValue={optionalPart}
                setInputValue={setAddressOptional}
                placeholder="Address (optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="optionalPart"
              />
              <div
                className={`${
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? "grid grid-cols-1 gap-4"
                    : "grid grid-cols-2 gap-4"
                }`}
              >
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiDivision}
                  selectedValue={division}
                  setSelectedValue={setDivision}
                  placeholder="Division"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin1"
                />
                <SingleSelectFromSimpleArray
                  // key={Math.floor(Math.random() * (9999 - 1000) + 1000)}
                  required={true}
                  data={apiCity}
                  selectedValue={city}
                  setSelectedValue={setCity}
                  placeholder="City"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin3"
                />
              </div>
              <div className="grid grid-cols-1 w-full">
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiMunicipility}
                  selectedValue={municipility}
                  setSelectedValue={setMunicipility}
                  placeholder="Municipility"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin2"
                />
              </div>
              <div className="grid grid-cols-1 w-full">
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apicomm}
                  selectedValue={community}
                  setSelectedValue={setCommunity}
                  placeholder="Community"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin4"
                />
              </div>
            </div>

            <div className="flex justify-end items-end ">
              <button
                onClick={() => {
                  setActiveStep(2);
                  handleRejection();
                }}
                className="w-[130px] h-[39px] bg-btn-secondary hover:bg-btn-primary active:bg-btn-secondary rounded-[4px] text-bg-white"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractComponentV2;
