import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import CustomInputWithDropdown from "./CustomInputWithDropdown";

export default function TourAccomandationAdd() {
  const [modalShown, setToggleModal] = useState(false);
  const [allAccommandationType, setAllAccommandationType] = useState([]);
  const [allAccommandation, setAllAccordion] = useState([]);
  const [tourAccommandation, setTourAccommandation] = useState({
    accommodation_address: "",
    accommodation_name: "",
    accommodation_type_id: null,
  });
  const [tourAccommandationAdd, setAccomandationAdd] = useState([]);
  const handlePackage = () => {
    setAccomandationAdd([...tourAccommandationAdd, tourAccommandation]);
    setTourAccommandation({
      accommodation_address: "",
      accommodation_name: "",
      accommodation_type_id: null,
    });
    setToggleModal(false);
  };

  const handlePackageAdd = () => {
    let accommandationToSend = tourAccommandationAdd.map((item) => ({
      ...item,
      accommodation_type_id: item.accommodation_type_id.id,
    }));

    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_ACCOMMONDATION_ADD}`, {
        accommodations: accommandationToSend,
        requestId: "string",
      })
      .then((response) => {
        console.log(response?.status);
        if (response.status === 201) {
          getAllAccommondation();
          toast.success("Successfully Added accommodation");
          setTourAccommandation({
            accommodation_address: "",
            accommodation_name: "",
            accommodation_type_id: null,
          });
          setAccomandationAdd([]);
        }
      });
  };

  const removeAccommandationAdd = (ind) => {
    const newArray = tourAccommandationAdd?.filter(
      (meal, index) => index !== ind
    );
    setAccomandationAdd(newArray);
  };

  const getAccomandationTypes = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_ACCOMMANDATION_TYPE_GET_API}`)
      .then((response) =>
        setAllAccommandationType(response?.data?.tour_accommodation_types)
      );
  };

  const getAllAccommondation = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_ACCOMMANDATION_GET_API}`)
      .then((response) => setAllAccordion(response?.data?.tour_accommodations));
  };
  useEffect(() => {
    getAllAccommondation();
  }, []);
  useEffect(() => {
    getAccomandationTypes();
  }, []);
  const transformedArray = allAccommandationType?.map(
    ({ tour_accommodation_type_id, tour_accommodation_type_name }) => ({
      label: tour_accommodation_type_name,
      id: tour_accommodation_type_id,
    })
  );

  return (
    <div className="">
      <div className="max-w-[1100px] mx-auto  mt-[30px]">
        <p className="text-btn-secondary">Tour Accommondation Added List</p>
        <div className="flex flex-col gap-y-[14px]  max-w-[450px] w-full mt-[12px] overflow-y-scroll p-[12px] max-h-[550px]">
          {allAccommandation?.map((tourData, index) => (
            <div
              key={index}
              className="flex gap-y-[3px]  flex-col shadow-customShadow p-[10px] rounded-[8px]"
            >
              <p className="text-[16px]">
                {" "}
                <span className="text-[12px]">Acc. Name:</span>{" "}
                {tourData?.tour_accommodation_name}
              </p>
              <p className="text-[16px]">
                {" "}
                <span className="text-[12px]">Acc. Type:</span>{" "}
                {tourData?.tour_accommodation_type}
              </p>

              <p className="text-[16px]">
                {" "}
                <span className="text-[12px]">Acc. Address:</span>{" "}
                {tourData?.tour_accommodation_address}
              </p>
            </div>
          ))}
        </div>

        <div className="max-w-[450px] mx-auto mt-[30px] flex flex-col gap-y-[10px] shadow-customShadow rounded-[8px] p-[12px] fixed right-[40px] top-[90px] w-full">
          <h2 className="text-[22px] text-center underline">
            Accommodation Add
          </h2>
          <div className="mt-[20px] flex flex-col gap-y-[10px] ">
            {tourAccommandationAdd?.map((tour, index) => (
              <div
                key={index}
                className="border rounded-[12px]  p-[10px] relative border-[#ddd]"
              >
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Acc. Address :</span>{" "}
                  {tour?.accommodation_address}
                </p>
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Acc. Name :</span>{" "}
                  {tour?.accommodation_name}
                </p>
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Acc. Type: </span>
                  {tour?.accommodation_type_id?.label}
                </p>

                <span
                  onClick={() => removeAccommandationAdd(index)}
                  className="absolute top-[-12px] right-[-7px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px] text-[#EC7063]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>

          <div className="w-full flex justify-end mt-[10px]">
            <button
              className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
                tourAccommandationAdd?.length > 0 ? "w-[150px] " : "w-full"
              }`}
              onClick={() => setToggleModal(true)}
            >
              Add New
            </button>
          </div>
          {tourAccommandationAdd?.length > 0 && (
            <button
              onClick={() => handlePackageAdd()}
              className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white"
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={true}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourAccommandation((prev) => ({
                    ...prev,
                    accommodation_name: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={tourAccommandation.accommodation_name}
              />
              <span className="placeholder">Accommodation Name:</span>
            </label>
          </div>

          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourAccommandation((prev) => ({
                    ...prev,
                    accommodation_address: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={tourAccommandation.accommodation_address}
              />
              <span className="placeholder">Accommodation Address:</span>
            </label>
          </div>

          <div>
            <CustomInputWithDropdown
              setSelectedOption={(accommodation_type_id) =>
                setTourAccommandation((prev) => ({
                  ...prev,
                  accommodation_type_id: accommodation_type_id,
                }))
              }
              selectedOption={tourAccommandation?.accommodation_type_id?.label}
              labelText={"Accommandation Type"}
              options={transformedArray}
            />
          </div>

          <button
            disabled={
              tourAccommandation?.accommodation_address?.length === 0 ||
              tourAccommandation?.accommodation_name?.length === 0 ||
              tourAccommandation?.accommodation_type_id?.id === undefined
            }
            onClick={() => handlePackage()}
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[10px] disabled:bg-bg-semiblack"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
