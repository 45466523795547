import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postHotelPropertyInfoSave } from "../../store/registration/hotelProperty/api/rentalApi";
import ButtonFilled from "../ButtonFilled";

import MapModal from "../modal/MapModal";
import FileMultiple from "../multipleimage/FileMultiple";
import MultiSelectParent from "../multiselect/MultiSelectParent";
import MultiSelectParentObjectReturn from "../multiselect/MultiSelectParentObjectReturn";
import Pagenation from "../pagenation/Pagenation";
import RemoveableImageUploadView from "../RemoveableImageUploadView";
import SingleSelectFromSimpleArray from "../singleSelect/SingleSelectFromSimpleArray";
import SingleSelectParentReturnObject from "../singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import SingleSelectParent from "../singleSelect/SingleSelectParent";

import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  DashboardRoutes,
  merchantRoute,
  ReviewFor,
  UserType,
} from "../../contants/Constants";
import {
  apiEndPoint,
  CANCELLATION_ENDPOINTS,
  HOTEL_PROPERTY_API,
  PROPERTY_ENDPOINTS,
} from "../../contants/Endpoints";
import { hotelsDetails } from "../../store/admin/hotel/api/adminContractApi";
import { reviewSubmission } from "../../store/admin/review/api/reviewApi";
import { authAxios } from "../../utils/axiosWrapper";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import CustomValidation from "../../utils/customValidation";
import dashboardOrRegistrationRoute, {
  RouteType,
} from "../../utils/dashboardOrRegistrationRoute";
import { returnAdminOrUserBasedOnPath } from "../../utils/returnAdminOrUserBasedOnPath";
import { setterNullSafety } from "../../utils/setterException";
import { toastMessage } from "../../utils/toast";

import ButtonOutlined from "../ButtonOutlined";
import Cancellation from "../Cancellation";
import Checkbox from "../Checkbox";
import SingleSelectPhone from "../dropdown/SingleSelectPhone";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import InputComponent from "../InputComponent";
import Loading from "../Loader/Loading";

import YearPicker from "../calender/YearPicker";
import TimePicker from "../TimePicker";

export const updateKeyNames = (data) => {
  return data.map((property) => {
    const { rental_property_type_name, white_url, ...rest } = property;

    return {
      label: rental_property_type_name,
      value: rental_property_type_name,
      icon_url: white_url,
      ...rest,
    };
  });
};

const HotelComponent = ({ withoutHeader }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let param = useParams();

  let id = param.id;

  const contractId = JSON.parse(localStorage.getItem("id") ?? 0);
  const location = useLocation();
  const [selectedObject, setSelectedObject] = useState();
  //temp uploaded images url
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  const inputDateElement = document.querySelector('input[type="date"]');
  useEffect(() => {
    inputDateElement?.showPicker();
  }, []);

  // map
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  // info
  const [images, setImages] = useState();
  const [propertyName, setHotelName] = useState("");
  const [nearBy, setNearBy] = useState("");
  const [shortAddress, setShortAddress] = useState("");
  const [country, setCountry] = useState("");
  const [hotelWebsite, setHotelWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [addressOptional, setAddressOptional] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [description, setDescription] = useState("");

  // extra 3 field
  const [contactCode, setcontactCode] = useState("+880");
  const [contactNo, setcontactNo] = useState("");
  const [email, setemail] = useState("");
  // some helper state
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

  const [addressRejectArray, setAddressRejectArray] = useState({});
  const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});
  const [imageRejectArray, setImageRejectArray] = useState({});
  const [propertyRejectArray, setPropertyRejectArray] = useState({});

  const [zipCode, setZipCode] = useState("");
  const [division, setDivision] = useState("");
  const [starLevel, setStarLevel] = useState("None");
  const [city, setCity] = useState("");
  const [municipility, setMunicipility] = useState("");
  const [community, setCommunity] = useState("");
  //   property
  const [renovationDate, setRenovationDate] = useState("");

  const [buildDate, setBuildDate] = useState("");
  const [numberOfRooms, setRooms] = useState("");
  const [numberOfFloor, setFloors] = useState("");
  console.log("renovationDate", buildDate);
  //   policies

  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [maxChildAge, setChildAge] = useState("");
  console.log("checkOutTime", checkinTime);
  //   services,
  //   drink and food
  const [noOfRestaurants, setNumberRestaurants] = useState("");
  //   pools
  const [noOfPools, setNumberPools] = useState("");
  const [noOfIndoorPools, setIndoorPools] = useState("");
  const [noOfOutdoorPools, setOutdoorPools] = useState("");

  // Business
  const [noOfMeetingRooms, setMeetingRoom] = useState("");

  // api country data
  const [apiCity, setApiCity] = useState();
  const [apiMunicipility, setApiMunicipility] = useState();
  const [apicomm, setApicomm] = useState();
  const [apiDivision, setApiDivision] = useState();
  const [allCountries, setAllCountries] = useState();
  const [apiZips, setApiZips] = useState();
  const [isExpress, setIsExpress] = useState(false);

  // tag calls
  const [policysTag, setPolicysTag] = useState([]);
  const [serviceTag, setServiceTag] = useState([]);
  const [languageTag, setLanguageTag] = useState([]);
  const [foodDrinkTag, setFoodDrinkTag] = useState([]);
  const [familyTags, setFamilyTags] = useState([]);
  const [houseKeepingTag, sethouseKeepingTag] = useState([]);
  const [accessibilityTag, setAccessibilityTag] = useState([]);
  const [wellnesTag, setWellnesTag] = useState([]);
  const [activitityTag, setActivitityTag] = useState([]);
  const [paymentOptionTag, setPaymentOptionTag] = useState([]);
  const [connectivityTag, setConnectivityTag] = useState([]);
  const [businesssTag, setBusinesssTag] = useState([]);
  const [transportationTag, setTransportationTag] = useState([]);

  const [energyefficiency, setEnergyefficiency] = useState([]);
  const [enhancedcleaning, setEnhancedcleaning] = useState([]);
  const [increasedfoodsafety, setIncreasedfoodsafety] = useState([]);
  const [minimizedcontact, setMinimizedcontact] = useState([]);
  const [personalprotection, setPersonalprotection] = useState([]);
  const [physicaldistancing, setPhysicaldistancing] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [pools, setPools] = useState([]);
  const [pets, setPets] = useState([]);
  const [rentalPropertyClasses, setRentalPropertyClasses] = useState([]);

  const [wastereduction, setWastereduction] = useState([]);
  const [sustainablesourcing, setSustainablesourcing] = useState([]);
  const [sustainabilitycertifications, setSustainabilitycertifications] =
    useState([]);
  const [waterconservation, setWaterconservation] = useState([]);

  // for selection
  const [policysTagSelected, setPolicysTagSelected] = useState([]);
  const [enhancedcleaningSelected, setEnhancedcleaningSelected] = useState([]);
  const [serviceTagSelected, setServiceTagSelected] = useState([]);
  const [languageTagSelected, setLanguageTagSelected] = useState([]);
  const [foodDrinkTagSelected, setFoodDrinkTagSelected] = useState([]);
  const [familyTagsSelected, setFamilyTagsSelected] = useState([]);
  const [houseKeepingTagSelected, sethouseKeepingTagSelected] = useState([]);
  const [accessibilityTagSelected, setAccessibilityTagSelected] = useState([]);
  const [wellnesTagSelected, setWellnesTagSelected] = useState([]);
  const [activitityTagSelected, setActivitityTagSelected] = useState([]);
  const [paymentOptionTagSelected, setPaymentOptionTagSelected] = useState([]);
  const [connectivityTagSelected, setConnectivityTagSelected] = useState([]);
  const [businesssTagSelected, setBusinesssTagSelected] = useState([]);
  console.log(businesssTagSelected, "businesssTagSelected");
  const [transportationTagSelected, setTransportationTagSelected] = useState(
    []
  );

  const [energyefficiencySelected, setEnergyefficiencySelected] = useState([]);
  const [increasedfoodsafetySelected, setIncreasedfoodsafetySelected] =
    useState([]);
  const [minimizedcontactSelected, setMinimizedcontactSelected] = useState([]);
  const [personalprotectionSelected, setPersonalprotectionSelected] = useState(
    []
  );
  const [physicaldistancingSelected, setPhysicaldistancingSelected] = useState(
    []
  );
  const [parkingsSelected, setParkingsSelected] = useState([]);
  const [poolsSelected, setPoolsSelected] = useState([]);
  const [petsSelected, setPetsSelected] = useState([]);
  const [rentalPropertyClassesSelected, setRentalPropertyClassesSelected] =
    useState();
  const [wasteReductionSelected, setwasteReductionSelected] = useState([]);
  const [sustainableSourcingSelected, setSustainableSourcingSelected] =
    useState([]);
  const [
    sustainabilitycertificationsSelected,
    setSustainabilitycertificationsSelected,
  ] = useState([]);
  const [waterconservationSelect, setWaterconservationSelect] = useState([]);

  const [destinationList, setDestinationList] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);

  // some helper state
  const [needUpdate, setNeedUpdate] = useState(Math.random());
  const [submitType, setSubmitType] = useState("next");
  const [isLoading, setIsLoading] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [disabling, setDisabling] = useState(false);

  //cancellation policy state
  const [policy, setPolicy] = useState({
    100: {
      to: "",
      unit: "days",
      is100: false,
      isActive: false,
      policy: 100,
      timeInHour: null,
    },
    75: {
      to: "",
      unit: "days",
      is75: false,
      isActive: false,
      policy: 75,
      timeInHour: null,
    },
    50: {
      to: "",
      unit: "hours",
      is50: false,
      isActive: false,
      policy: 50,
      timeInHour: null,
    },
    25: {
      to: "",
      unit: "hours",
      is25: false,
      isActive: false,
      policy: 25,
      timeInHour: null,
    },
    0: {
      to: "",
      unit: "hours",
      is0: false,
      isActive: false,
      policy: 0,
      timeInHour: null,
    },
  });

  //cancellation policy get
  const [addedPolicy, setAddedPolicy] = useState([]);

  const postPolicy = (id) => {
    let isError = false;
    const selectedpolicy = [];
    const temp = [];
    console.log(policy, "temp-array");

    Object.values(policy).map((item) => {
      console.log(item.isActive, "and pol");
      if (item.isActive) {
        temp.push(item);
        var time;
        if (item.unit === "days") {
          time = parseInt(item.to) * 24;
        } else {
          time = parseInt(item.to);
        }
        const obj = { refundPercent: item.policy, timeInHour: time };
        selectedpolicy.push(obj);
      }
    });

    if (!validationError && selectedpolicy.length > 0) {
      const postObject = {
        policyData: selectedpolicy,
        propertyId: id,
        requestId: "11",
      };
      console.log(postObject, "and");
      updatePolicy(postObject);
      // authAxios
      //     .post(CANCELLATION_ENDPOINTS.POST_CANCELLATION, postObject)
      //     .then((response) => {
      //         console.log(response, "polll response");
      //     })
      //     .catch((error) => {
      //         console.log(error);
      //     });
    }
  };
  const updatePolicy = (reqPolicy) => {
    console.log(reqPolicy, "and req");
    authAxios
      .post(CANCELLATION_ENDPOINTS.POST_CANCELLATION, reqPolicy)
      .then((response) => {
        console.log(response, "polll response");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //frtch all cancellation policy
  const getCancellation = () => {
    authAxios
      .get(
        `${CANCELLATION_ENDPOINTS.GET_CANCELLATION_BY_PROPERTY}${id}/cancellation?requestId=11`
      )
      .then((response) => {
        setAddedPolicy(response?.data?.terms);
      })
      .catch((error) => {
        console.log(error, "cancell");
      });
  };

  const adminReviewSubmit = () => {
    setDisabling(true);
    dispatch(
      reviewSubmission(
        ReviewFor.HOTEL,
        param.id,
        {
          addressReject: addressRejectArray,
          facilitiesReject: facilitiesRejectArray,
          imageReject: imageRejectArray,
          propertyReject: propertyRejectArray,
          requestId: "string",
        },
        () => {
          setDisabling(false);
        },
        () => {
          setDisabling(false);
        }
      )
    );
  };

  function timeconverter(time) {
    // try {
    //   if (time && time.toString().length > 24) {
    //     return time.toString().slice(16, 24);
    //   } else {
    //     return time;
    //   }
    // } catch (err) {
    //   alert("invalid time zone", err);
    // }
    console.log("time is not", time.length);
    try {
      if (time && time.toString().length > 7) {
        return time;
        //return time.toString().slice(16, 24);
      } else {
        return time + ":00";
      }
    } catch (err) {
      alert("invalid time zone", err);
    }
  }

  // for save button click
  const createContractMoveingDecideSave = (id) => {
    postPolicy(id);
    setDisabling(false);
    if (dashboardOrRegistrationRoute() === RouteType.REGISTRATION) {
      navigate(merchantRoute.LANDING);
    } else {
      navigate(DashboardRoutes.HOTELS);
    }
  };

  // useEffect for loading upper part
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const obj_update = {
    address: {
      admin1: division,
      admin2: city,
      admin3: municipility,
      admin4: "string",
      billingAddress: true,
      country: country,
      latitude: lat,
      longitude: lng,
      optionalPart: addressOptional,
      organizationName: "string",
      place: community,
      primaryAddress: true,
      requestId: "string",
      street: address,
      zip: zipCode,
    },
    builtYear: buildDate,

    checkInTime: timeconverter(checkinTime) + ".999999999",
    checkOutTime: timeconverter(checkoutTime) + ".999999999",
    contactCode: contactCode,
    contactNo: contactNo,
    contractId: Number(contractId),
    selectedDestinationIds: selectedDestination,
    email: email,
    ratings: starLevel === "None" ? 0 : starLevel,
    facilities: [
      ...energyefficiencySelected,
      ...wasteReductionSelected,
      ...personalprotectionSelected,
      ...sustainableSourcingSelected,
      ...sustainabilitycertificationsSelected,
      ...waterconservationSelect,
      ...minimizedcontactSelected,
      ...poolsSelected,
      ...petsSelected,
      ...parkingsSelected,
      ...enhancedcleaningSelected,
      ...physicaldistancingSelected,
      ...increasedfoodsafetySelected,
      ...wellnesTagSelected,
      ...accessibilityTagSelected,
      ...houseKeepingTagSelected,
      ...familyTagsSelected,
      ...foodDrinkTagSelected,
      ...policysTagSelected,
      ...serviceTagSelected,
      ...transportationTagSelected,
      ...businesssTagSelected,
      ...connectivityTagSelected,
      ...activitityTagSelected,
      ...paymentOptionTagSelected,
    ],
    languages: languageTagSelected,
    lastRenovatedYear: renovationDate,
    maxChildAge: maxChildAge,
    nearBy,
    shortAddress,
    isExpress,
    maxNoOfKidsStayForFree: 0,
    noOfIndoorPools: noOfIndoorPools,
    noOfMeetingRooms: noOfMeetingRooms,
    noOfOutdoorPools: noOfOutdoorPools,
    noOfPools: noOfPools,
    noOfRestaurants: noOfRestaurants,
    numberOfFloor: numberOfFloor,
    numberOfRooms: numberOfRooms,
    propertyId: id,
    propertyName: propertyName,
    description,
    descriptionTitle,
    // propertyType: selectedObject,
    propertyType: rentalPropertyClassesSelected?.id,
    requestId: "string",
    servicesClassRating: 0,
    website: hotelWebsite,
  };
  console.log("obj_update", obj_update);
  // api call for post
  const buttonOutlinedHandle = () => {
    if (CustomValidation() && !validationError) {
      setDisabling(true);
      dispatch(
        postHotelPropertyInfoSave(
          obj_update,
          urlTempStorageArray,
          createContractMoveingDecideSave,
          () => {
            setDisabling(false);
          }
        )
      );
    } else {
      toastMessage("Please fill up all required fields!", "error");
    }
  };

  // for next button click
  const createContractMoveingDecideNext = (id) => {
    postPolicy(id);
    setDisabling(false);
    if (dashboardOrRegistrationRoute() === RouteType.REGISTRATION) {
      navigate(merchantRoute.CATEGORIES);
    } else {
      navigate(DashboardRoutes.HOTELS);
    }
  };

  const buttonFilledHandle = () => {
    if (CustomValidation() && !validationError) {
      setDisabling(true);
      dispatch(
        postHotelPropertyInfoSave(
          obj_update,
          urlTempStorageArray,
          createContractMoveingDecideNext,
          () => {
            setDisabling(false);
          }
        )
      );
    } else {
      toastMessage("Please fill up all required fields!", "error");
    }
  };

  // fetch country
  useEffect(() => {
    if (country) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      noAuthAxios
        .get(
          `${
            PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE
          }${country}?requestId=${localStorage.getItem("id")}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setApiDivision(response.data.admin1s);
          setApiCity(response.data.admin2s);
          setApiMunicipility(response.data.admin3s);
          setApicomm(response.data.places);
          setApiZips(response.data.zips);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [country]);

  // fetch country all
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    noAuthAxios
      .get(
        `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // fetch all country -> phone code
  useEffect(() => {
    if (localStorage.getItem("id")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      noAuthAxios
        .get(
          `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
            "id"
          )}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log("response all country data:", response.data.countries);
          setCountryPhoneCodeArr(response.data.countries);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const response = useSelector((state) => state.hotelsInfoDetailsGetReducer);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getDestinationList();
    if (id === "0") {
      setIsLoading(false);
    } else {
      dispatch(hotelsDetails(process.env.REACT_APP_USER_TYPE, id));
    }
  }, [needUpdate]);

  const getDestinationList = () => {
    noAuthAxios
      .get(`${apiEndPoint.ADMIN_GET_INSPIRED}`)
      // .get(`${baseUrl}open/rental-property/1/destination-locations?requestId=11`)
      .then((res) => {
        setDestinationList(res.data.destinationLocationDataOptimizedList);
      })
      .catch((err) => {
        toastMessage("Error fething travel destination", "error");
      });
  };

  useEffect(() => {
    if (id == 0) {
      return null;
    } else {
      getCancellation();
      if (response && response.data && response.data.propertyData) {
        // try{
        setterNullSafety(() => {
          setImages(response.data.propertyData.images);
        });
        setterNullSafety(() => {
          setDescription(response.data.propertyData.description.description);
        });
        setterNullSafety(() => {
          setDescriptionTitle(response.data.propertyData.description.title);
        });
        setterNullSafety(() => {
          setLng(response.data.propertyData.address.longitude);
        });
        setterNullSafety(() => {
          setLat(response.data.propertyData.address.latitude);
        });
        setterNullSafety(() => {
          setemail(response.data.propertyData.email);
        });
        setterNullSafety(() => {
          setIsExpress(response.data.propertyData.isExpress);
        });
        setterNullSafety(() => {
          setcontactCode(response.data.propertyData.contactCode);
        });
        setterNullSafety(() => {
          setcontactNo(response.data.propertyData.contactNo);
        });
        setterNullSafety(() => {
          setSelectedDestination(
            response.data.propertyData.inspiredLocation.map((item) => item.id)
          );
        });
        setterNullSafety(() => {
          setNearBy(response.data.propertyData.nearBy);
        });
        setterNullSafety(() => {
          setShortAddress(response.data.propertyData.shortAddress);
        });
        setterNullSafety(() => {
          setAddress(response.data.propertyData.address.street);
        });
        setterNullSafety(() => {
          setAddressOptional(response.data.propertyData.address.optionalPart);
        });
        setterNullSafety(() => {
          setCountry(response.data.propertyData.address.country);
        });
        setterNullSafety(() => {
          setDivision(response.data.propertyData.address.admin1);
        });
        setterNullSafety(() => {
          setCity(response.data.propertyData.address.admin2);
        });
        setterNullSafety(() => {
          setMunicipility(response.data.propertyData.address.admin3);
        });
        setterNullSafety(() => {
          setCommunity(response.data.propertyData.address.place);
        });
        setterNullSafety(() => {
          setZipCode(response.data.propertyData.address.zip);
        });
        setterNullSafety(() => {
          setRenovationDate(response.data.propertyData.lastRenovatedYear);
        });
        setterNullSafety(() => {
          setStarLevel(response.data.propertyData.ratings);
        });
        setterNullSafety(() => {
          setBuildDate(response.data.propertyData.builtYear);
        });
        setterNullSafety(() => {
          setHotelName(response.data.propertyData.propertyName);
        });
        setterNullSafety(() => {
          setHotelWebsite(response.data.propertyData.website);
        });
        setterNullSafety(() => {
          setRooms(response.data.propertyData.numberOfRooms);
        });
        setterNullSafety(() => {
          setFloors(response.data.propertyData.numberOfFloor);
        });
        setterNullSafety(() => {
          setMeetingRoom(response.data.propertyData.noOfMeetingRooms);
        });
        setterNullSafety(() => {
          setNumberPools(response.data.propertyData.noOfPools);
        });
        setterNullSafety(() => {
          setIndoorPools(response.data.propertyData.noOfIndoorPools);
        });
        setterNullSafety(() => {
          setOutdoorPools(response.data.propertyData.noOfOutdoorPools);
        });
        setterNullSafety(() => {
          setChildAge(response.data.propertyData.maxChildAge);
        });
        setterNullSafety(() => {
          setCheckinTime(response.data.propertyData.checkInTime);
        });
        setterNullSafety(() => {
          setCheckoutTime(response.data.propertyData.checkOutTime);
        });
        setterNullSafety(() => {
          setNumberRestaurants(response.data.propertyData.noOfRestaurants);
        });
        setterNullSafety(() => {
          // tags
          setRentalPropertyClassesSelected({
            label: response.data.propertyData.propertyType,
            value: response.data.propertyData.propertyType,
            id: response.data.propertyData.propertyTypeId,
          });
        });
        console.log("response", response.data.propertyData);
        setterNullSafety(() => {
          setServiceTagSelected(
            response.data.propertyData.facilities.services.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setLanguageTagSelected(response.data.propertyData.languages);
        });
        setterNullSafety(() => {
          sethouseKeepingTagSelected(
            response.data.propertyData.facilities.housekeeping.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setEnhancedcleaningSelected(
            response.data.propertyData.facilities.enhancedcleaning.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setIncreasedfoodsafetySelected(
            response.data.propertyData.facilities.increasedfoodsafety.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setMinimizedcontactSelected(
            response.data.propertyData.facilities.minimizedcontact.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPersonalprotectionSelected(
            response.data.propertyData.facilities.personalprotection.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPhysicaldistancingSelected(
            response.data.propertyData.facilities.physicaldistancing.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setParkingsSelected(
            response.data.propertyData.facilities.parking.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPolicysTagSelected(
            response.data.propertyData.facilities.policies.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPaymentOptionTagSelected(
            response.data.propertyData.facilities.paymentoptions.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPoolsSelected(
            response.data.propertyData.facilities.pools.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setWellnesTagSelected(
            response.data.propertyData.facilities.wellness.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setActivitityTagSelected(
            response.data.propertyData.facilities.activities.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setTransportationTagSelected(
            response.data.propertyData.facilities.transportation.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setFamilyTagsSelected(
            response.data.propertyData.facilities.families.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setConnectivityTagSelected(
            response.data.propertyData.facilities.connectivity.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setAccessibilityTagSelected(
            response.data.propertyData.facilities.accessibility.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setBusinesssTagSelected(
            response.data.propertyData.facilities.business.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPetsSelected(
            response.data.propertyData.facilities.pets.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setFoodDrinkTagSelected(
            response.data.propertyData.facilities.foodanddrink.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setWaterconservationSelect(
            response.data.propertyData.facilities.waterconservation.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setSustainabilitycertificationsSelected(
            response.data.propertyData.facilities.sustainabilitycertifications.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setSustainableSourcingSelected(
            response.data.propertyData.facilities.sustainablesourcing.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setwasteReductionSelected(
            response.data.propertyData.facilities.wastereduction.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setEnergyefficiencySelected(
            response.data.propertyData.facilities.energyefficiency.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setAddressRejectArray(
            response.data.propertyData.address.addressRejected
          );
        });
        setterNullSafety(() => {
          setFacilitiesRejectArray(
            response.data.propertyData.facilitiesRejected
          );
        });
        setterNullSafety(() => {
          setPropertyRejectArray(response.data.propertyData.propertyRejected);
        });
        // }catch(e){
        //   console.log(e)
        // }
        setIsLoading(false);
      }
    }
  }, [response]);

  console.log("rentalPropertyClassesSelected:", rentalPropertyClassesSelected);

  // fetch all tags
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(`${PROPERTY_ENDPOINTS.FETCH_FACILITIES}`, {
        headers: headers,
      })
      .then((response) => {
        setServiceTag(response.data.rentalPropertyFacilities.services); //done
        sethouseKeepingTag(response.data.rentalPropertyFacilities.housekeeping); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setIncreasedfoodsafety(
          response.data.rentalPropertyFacilities.increasedfoodsafety
        ); //done
        setMinimizedcontact(
          response.data.rentalPropertyFacilities.minimizedcontact
        ); //done
        setPersonalprotection(
          response.data.rentalPropertyFacilities.personalprotection
        ); //done
        setPhysicaldistancing(
          response.data.rentalPropertyFacilities.physicaldistancing
        ); //done
        setParkings(response.data.rentalPropertyFacilities.parking); //done
        setPolicysTag(response.data.rentalPropertyFacilities.policies); //done
        setPaymentOptionTag(
          response.data.rentalPropertyFacilities.paymentoptions
        );
        setFoodDrinkTag(response.data.rentalPropertyFacilities.foodanddrink);
        setPools(response.data.rentalPropertyFacilities.pools); //done
        setWellnesTag(response.data.rentalPropertyFacilities.wellness); //done
        setActivitityTag(response.data.rentalPropertyFacilities.activities);
        setTransportationTag(
          response.data.rentalPropertyFacilities.transportation
        );
        setFamilyTags(response.data.rentalPropertyFacilities.families);
        setConnectivityTag(response.data.rentalPropertyFacilities.connectivity);
        setBusinesssTag(response.data.rentalPropertyFacilities.business);
        setAccessibilityTag(
          response.data.rentalPropertyFacilities.accessibility
        );
        setPets(response.data.rentalPropertyFacilities.pets); //done
        setLanguageTag(response.data.languages);
        // setPets(response.data.rentalPropertyClasses); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setEnhancedcleaning(
          response.data.rentalPropertyFacilities.enhancedcleaning
        );
        setWastereduction(
          response.data.rentalPropertyFacilities.wastereduction
        ); //done
        setSustainablesourcing(
          response.data.rentalPropertyFacilities.sustainablesourcing
        ); //done
        setSustainabilitycertifications(
          response.data.rentalPropertyFacilities.sustainabilitycertifications
        ); //done
        setWaterconservation(
          response.data.rentalPropertyFacilities.waterconservation
        ); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setEnhancedcleaning(
          response.data.rentalPropertyFacilities.enhancedcleaning
        );
        setWastereduction(
          response.data.rentalPropertyFacilities.wastereduction
        ); //done
        setSustainablesourcing(
          response.data.rentalPropertyFacilities.sustainablesourcing
        ); //done
        setSustainabilitycertifications(
          response.data.rentalPropertyFacilities.sustainabilitycertifications
        ); //done
        setWaterconservation(
          response.data.rentalPropertyFacilities.waterconservation
        ); //done
        // setRentalPropertyClasses(response.data.rentalPropertyClasses);
      })
      .catch((error) => {
        console.log(error, "try");
      });
    authAxios
      .get(`${HOTEL_PROPERTY_API.HOTEL_PROPERTY_TYPE_GET}`, {
        headers: headers,
      })
      .then((response) => {
        const updateData = updateKeyNames(
          response?.data?.rentalPropertyTypeDataList
        );
        setRentalPropertyClasses(updateData);
      });
  }, []);

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  console.log("checkinTime", checkinTime);
  return (
    <div className="w-full relative">
      {disabling && (
        <div className="fixed h-screen w-screen bg-[#fffbef91] z-50 bottom-0 flex justify-center items-center">
          <Loading />
        </div>
      )}
      {withoutHeader ? (
        <></>
      ) : (
        <>
          {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
            <Pagenation owner="owner" />
          ) : null}
          {dashboardOrRegistrationRoute() === RouteType.REGISTRATION && (
            <HorizontalBar selectedBar="Hotel" />
          )}
        </>
      )}

      <div className="min-h-screen  flex flex-col content-center justify-items-center justify-center pl-12 pr-12 pb-12">
        {response.loading ? (
          <div className="ml-[45%]">
            <Loading />
          </div>
        ) : (
          <div className="relative">
            <div className="px-10 xl:px-0 xl:container mx-auto">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="flex mt-6 left-18 absolute  cursor-pointer"
              >
                <div>
                  <svg
                    width="39"
                    height="39"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="48"
                      height="48"
                      rx="24"
                      fill="#FFFBEF"
                    />
                    <path
                      d="M25.5 21L21.5 25L25.5 29"
                      stroke="#376576"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="48"
                      height="48"
                      rx="24"
                      stroke="#376576"
                    />
                  </svg>
                </div>
                <div>
                  <p className="h-full py-[2px] px-[10px] text-[27px] cursor-pointer">
                    Hotel list
                  </p>
                </div>
              </div>
              <div
                className={`pt-20 ${
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? ""
                    : "grid grid-cols-2 gap-28"
                }`}
              >
                <div>
                  <div>
                    <h2 className="font-fontFamily-sans my-5 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Hotel Information
                    </h2>

                    <div className="flex flex-col gap-y-4">
                      <InputComponent
                        inputValue={propertyName}
                        setInputValue={setHotelName}
                        required={true}
                        placeholder="Hotel Name"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="propertyName"
                      />
                      <div className="my-2">
                        <Checkbox
                          title={"Express booking available?"}
                          checked={isExpress}
                          handleChange={() => setIsExpress(!isExpress)}
                        />
                      </div>

                      <div id="desc" className="required">
                        <div className="w-full pr-[49px]">
                          <h1 className="font-fontFamily-sans mb-4 mt-7 text-[18px] font-normal leading-[37.8px] text-txt-primary">
                            Hotel Short Description
                          </h1>
                          <div className="mb-3">
                            <InputComponent
                              inputValue={descriptionTitle}
                              setInputValue={setDescriptionTitle}
                              required={true}
                              placeholder="Title"
                              isAdmin={
                                process.env.REACT_APP_USER_TYPE ===
                                UserType.ADMIN
                                  ? true
                                  : false
                              }
                              isReadOnly={false}
                              rejectionArray={propertyRejectArray}
                              setRejectionArray={setPropertyRejectArray}
                              name="title"
                            />
                          </div>

                          <ReactQuill
                            theme="snow"
                            placeholder="Description :"
                            value={description}
                            onChange={setDescription}
                            modules={modules}
                          />
                          {/* <textarea
                                                        id="inputdesc"
                                                        name="question"
                                                        placeholder="Hotel description"
                                                        value={description}
                                                        onChange={(e) => {
                                                            setDescription(
                                                                e.target.value
                                                            );
                                                            customValidationSingle(
                                                                "desc"
                                                            );
                                                        }}
                                                        className="resize-none w-full validation h-[12rem] align-text-top bg-bg-primary border border-[#9e9989] rounded-[0.8rem]  outline-none p-4 pt-[20px] "
                                                    /> */}
                        </div>
                        <div className="errorMessage validationError"></div>
                      </div>
                      <InputComponent
                        inputValue={email}
                        setInputValue={setemail}
                        required={true}
                        placeholder="Email"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="email"
                      />
                      <SingleSelectPhone
                        title={contactCode}
                        setTitle={setcontactCode}
                        titleArr={countryPhoneCodeArr}
                        inputValue={contactNo}
                        setInputValue={setcontactNo}
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="contactNo"
                      />

                      <MultiSelectParent
                        required={true}
                        selectedArray={selectedDestination}
                        setSelectedArray={setSelectedDestination}
                        displayField={"name"}
                        selectionKey="id"
                        data={destinationList}
                        placeholder="Travel Destinations"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={facilitiesRejectArray}
                        setRejectionArray={setFacilitiesRejectArray}
                        name="selectedDestinations"
                      />
                      {/* <SingleSelectParentReturnObject
                                                // required={true}
                                                selectedValue={
                                                    selectedDestination
                                                }
                                                setSelectedValue={
                                                    setSelectedDestination
                                                }
                                                data={destinationList}
                                                placeholder="Travel Destination"
                                                displayField={"name"}
                                                selectedFieldName="name"
                                                setSelectedObject={
                                                    setSelectedObject
                                                }
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    propertyRejectArray
                                                }
                                                setRejectionArray={
                                                    setPropertyRejectArray
                                                }
                                                name="selectedDestination"
                                            /> */}
                      <div
                        className={`${
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? "grid grid-cols-1 gap-4"
                            : "grid grid-cols-2 gap-4"
                        }`}
                      >
                        <SingleSelectParent
                          required={true}
                          selectedValue={country}
                          setSelectedValue={setCountry}
                          data={allCountries}
                          placeholder="Country"
                          displayField={"country"}
                          selectedFieldName="countryCode"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="country"
                        />
                        <SingleSelectFromSimpleArray
                          required={true}
                          data={apiZips}
                          selectedValue={zipCode}
                          setSelectedValue={setZipCode}
                          placeholder="Zip Code"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="zipCode"
                        />
                      </div>

                      <SingleSelectFromSimpleArray
                        data={["None", 1, 2, 3, 4, 5]}
                        selectedValue={starLevel}
                        setSelectedValue={setStarLevel}
                        placeholder="Hotel Star Rating"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="starLevel"
                      />
                      <InputComponent
                        inputValue={hotelWebsite}
                        setInputValue={setHotelWebsite}
                        // required={true}
                        placeholder="Hotel Website"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="hotelWebsite"
                      />

                      <InputComponent
                        inputValue={nearBy}
                        setInputValue={setNearBy}
                        required={true}
                        placeholder="Nearby Place"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="nearBy"
                      />

                      <InputComponent
                        inputValue={shortAddress}
                        setInputValue={setShortAddress}
                        required={true}
                        placeholder="Short Address"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="shortAddress"
                      />

                      <div className="grid grid-cols-4 gap-2 w-full items-center">
                        <div className="col-span-3">
                          <InputComponent
                            inputValue={address}
                            setInputValue={setAddress}
                            required={true}
                            placeholder="Address"
                            warningView={false}
                            name="address"
                          />
                        </div>
                        <MapModal
                          setLat={setLat}
                          setLng={setLng}
                          lat={lat}
                          lng={lng}
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="street"
                          type={"map"}
                        />
                      </div>

                      <InputComponent
                        inputValue={addressOptional}
                        setInputValue={setAddressOptional}
                        placeholder="Address (optional)"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="optionalPart"
                      />
                      <div
                        className={`${
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? "grid grid-cols-1 gap-4"
                            : "grid grid-cols-2 gap-4"
                        }`}
                      >
                        <SingleSelectFromSimpleArray
                          required={true}
                          data={apiDivision}
                          selectedValue={division}
                          setSelectedValue={setDivision}
                          placeholder="Division"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="division"
                        />
                        <SingleSelectFromSimpleArray
                          required={true}
                          data={apiCity}
                          selectedValue={city}
                          setSelectedValue={setCity}
                          placeholder="City"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="city"
                        />
                      </div>
                      <div className="grid grid-cols-1">
                        <SingleSelectFromSimpleArray
                          required={true}
                          data={apiMunicipility}
                          selectedValue={municipility}
                          setSelectedValue={setMunicipility}
                          placeholder="Municipility"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="municipility"
                        />
                      </div>
                      <div className="grid grid-cols-1">
                        <SingleSelectFromSimpleArray
                          required={true}
                          data={apicomm}
                          selectedValue={community}
                          setSelectedValue={setCommunity}
                          placeholder="Community"
                          isAdmin={
                            process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="community"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Energy Efficiency
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={energyefficiencySelected}
                      setSelectedArray={setEnergyefficiencySelected}
                      data={energyefficiency}
                      placeholder="Energy Efficiency Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="energyefficiencySelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Waste Reduction
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={wasteReductionSelected}
                      setSelectedArray={setwasteReductionSelected}
                      data={wastereduction}
                      placeholder="Waste Reduction Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="wasteReductionSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Personal Protection
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={personalprotectionSelected}
                      setSelectedArray={setPersonalprotectionSelected}
                      data={personalprotection}
                      placeholder="Personal Protection"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="personalprotectionSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Sustainable Sourcing
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={sustainableSourcingSelected}
                      setSelectedArray={setSustainableSourcingSelected}
                      data={sustainablesourcing}
                      placeholder="Sustainable Sourcing Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="sustainableSourcingSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Sustainability Certifications
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={sustainabilitycertificationsSelected}
                      setSelectedArray={setSustainabilitycertificationsSelected}
                      data={sustainabilitycertifications}
                      placeholder="Certifications Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="sustainabilitycertificationsSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Water Conservation
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={waterconservationSelect}
                      setSelectedArray={setWaterconservationSelect}
                      data={waterconservation}
                      placeholder="Water Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="waterconservationSelect"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Minimized Contact
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={minimizedcontactSelected}
                      setSelectedArray={setMinimizedcontactSelected}
                      data={minimizedcontact}
                      placeholder="Minimized Contact"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="minimizedcontactSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Pools
                    </h2>
                    <div className="flex flex-col gap-y-4">
                      <InputComponent
                        inputValue={noOfPools}
                        setInputValue={setNumberPools}
                        // required={true}
                        placeholder="Number Of Pools"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="noOfPools"
                        type="number"
                      />
                      <InputComponent
                        inputValue={noOfIndoorPools}
                        setInputValue={setIndoorPools}
                        // required={true}
                        placeholder="Number Of Indoor Pools"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="noOfIndoorPools"
                        type="number"
                      />
                      <InputComponent
                        inputValue={noOfOutdoorPools}
                        setInputValue={setOutdoorPools}
                        // required={true}
                        placeholder="Number Of Outdoor Pools "
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="noOfOutdoorPools"
                        type="number"
                      />
                      <MultiSelectParent
                        // required={true}
                        selectedArray={poolsSelected}
                        setSelectedArray={setPoolsSelected}
                        data={pools}
                        placeholder="Pools Tags"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={facilitiesRejectArray}
                        setRejectionArray={setFacilitiesRejectArray}
                        name="poolsSelected"
                      />
                    </div>
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Pets
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={petsSelected}
                      setSelectedArray={setPetsSelected}
                      data={pets}
                      placeholder="Pets Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="petsSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Parking
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={parkingsSelected}
                      setSelectedArray={setParkingsSelected}
                      data={parkings}
                      placeholder="Parkings"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="parkingsSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Enhanced Cleaning
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={enhancedcleaningSelected}
                      setSelectedArray={setEnhancedcleaningSelected}
                      data={enhancedcleaning}
                      placeholder="Enhanced Cleaning Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="enhancedcleaningSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Physical Distancing
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={physicaldistancingSelected}
                      setSelectedArray={setPhysicaldistancingSelected}
                      data={physicaldistancing}
                      placeholder="Physical Distancing Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="physicaldistancingSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Increased Food Safety
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={increasedfoodsafetySelected}
                      setSelectedArray={setIncreasedfoodsafetySelected}
                      data={increasedfoodsafety}
                      placeholder="Increased Food Safety"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="increasedfoodsafetySelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Wellness
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={wellnesTagSelected}
                      setSelectedArray={setWellnesTagSelected}
                      data={wellnesTag}
                      placeholder="Wellness Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="wellnesTagSelected"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Property Information
                    </h2>
                    <YearPicker
                      required={true}
                      selectedValue={renovationDate}
                      setSelectedValue={setRenovationDate}
                      placeholder="Renovation date"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="renovationDate"
                    />
                    {/* <YearDropdownSelect
                      placeholder={"Renovation date"}
                      setSelectedYear={setRenovationDate}
                      selectedYear={renovationDate}
                    /> */}

                    <YearPicker
                      required={true}
                      selectedValue={buildDate}
                      setSelectedValue={setBuildDate}
                      placeholder="Built date"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="buildDate"
                    />

                    {/* <YearDropdownSelect
                      placeholder={"Built date"}
                      setSelectedYear={setBuildDate}
                      selectedYear={buildDate}
                    /> */}
                    <InputComponent
                      inputValue={numberOfRooms}
                      setInputValue={setRooms}
                      required={true}
                      placeholder="Rooms"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="numberOfRooms"
                      type="number"
                    />
                    <InputComponent
                      inputValue={numberOfFloor}
                      setInputValue={setFloors}
                      required={true}
                      placeholder="Floors"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      type="number"
                      name="numberOfFloor"
                    />
                    <SingleSelectParentReturnObject
                      required={true}
                      selectedValue={rentalPropertyClassesSelected}
                      setSelectedValue={setRentalPropertyClassesSelected}
                      data={rentalPropertyClasses}
                      placeholder="Property Type Tags"
                      displayField={"label"}
                      selectedFieldName="value"
                      setSelectedObject={setSelectedObject}
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="rentalPropertyClassesSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Languages
                    </h2>
                    <MultiSelectParentObjectReturn
                      required={true}
                      selectedArray={languageTagSelected}
                      setSelectedArray={setLanguageTagSelected}
                      data={languageTag}
                      placeholder="Language Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="languageTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Payment Options
                    </h2>
                    <MultiSelectParent
                      required={true}
                      selectedArray={paymentOptionTagSelected}
                      setSelectedArray={setPaymentOptionTagSelected}
                      data={paymentOptionTag}
                      placeholder="Payment Option Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="paymentOptionTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Activities
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={activitityTagSelected}
                      setSelectedArray={setActivitityTagSelected}
                      data={activitityTag}
                      placeholder="Activity Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="activitityTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Connectivity
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={connectivityTagSelected}
                      setSelectedArray={setConnectivityTagSelected}
                      data={connectivityTag}
                      placeholder="Connectivity Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="connectivityTagSelected"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Business
                    </h2>
                    <InputComponent
                      inputValue={noOfMeetingRooms}
                      setInputValue={setMeetingRoom}
                      // required={true}
                      placeholder="Number of Meeting Rooms"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="noOfMeetingRooms"
                      number={true}
                    />
                    <div className="">
                      <MultiSelectParent
                        // required={true}
                        selectedArray={businesssTagSelected}
                        setSelectedArray={setBusinesssTagSelected}
                        data={businesssTag}
                        placeholder="Business Tags"
                        isAdmin={
                          process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={facilitiesRejectArray}
                        setRejectionArray={setFacilitiesRejectArray}
                        name="businesssTagSelected"
                      />
                    </div>
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Transportation
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={transportationTagSelected}
                      setSelectedArray={setTransportationTagSelected}
                      data={transportationTag}
                      placeholder="Transportation Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="transportationTagSelected"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Policies
                    </h2>
                    <TimePicker
                      required={true}
                      selectedData={checkinTime}
                      setSelectedDate={setCheckinTime}
                      placeholder="Check In Time"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="checkinTime"
                    />
                    {/* <div className="flex justify-between px-[16px] py-[8px] items-center flex-row border border-[#9e998b] rounded-[8px]">
                      <p className="text-[#9e9989] text-[14px]">
                        Check in Time :
                      </p>
                      <input
                        onChange={(e) => setCheckinTime(e.target.value)}
                        type="time"
                        id="session-time"
                        name="appt"
                        step="0"
                        value={checkinTime}
                        required
                        style={{
                          padding: "4px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "16px",
                          width: "200px",
                          outline: "none",
                          backgroundColor: "#f5f5f5",
                          color: "#9e9989",
                          fontFamily: "Arial, sans-serif",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          transition:
                            "border-color 0.3s ease, box-shadow 0.3s ease",
                        }}
                      />
                    </div> */}

                    <TimePicker
                      required={true}
                      selectedData={checkoutTime}
                      setSelectedDate={setCheckoutTime}
                      placeholder="Check Out Time"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="checkoutTime"
                    />

                    {/* <TimePicker
                      required={true}
                      selectedData={checkoutTime}
                      setSelectedDate={setCheckoutTime}
                      placeholder="Check Out Time"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="checkoutTime"
                    />
                    <InputComponent
                      setInputValue={setChildAge}
                      // required={true}
                      inputValue={maxChildAge}
                      placeholder="Maximum Child Age"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="maxChildAge"
                    /> */}
                    <InputComponent
                      setInputValue={setChildAge}
                      // required={true}
                      inputValue={maxChildAge}
                      placeholder="Maximum Child Age"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="maxChildAge"
                    />
                    <MultiSelectParent
                      // required={true}
                      selectedArray={policysTagSelected}
                      setSelectedArray={setPolicysTagSelected}
                      data={policysTag}
                      placeholder="Policy Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="policysTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Services
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={serviceTagSelected}
                      setSelectedArray={setServiceTagSelected}
                      data={serviceTag}
                      placeholder="Service Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="serviceTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Food and Drink
                    </h2>
                    <InputComponent
                      inputValue={noOfRestaurants}
                      setInputValue={setNumberRestaurants}
                      // required={true}
                      placeholder="Number Of Restaurants"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="noOfRestaurants"
                    />
                    <h1 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Restaurants
                    </h1>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={foodDrinkTagSelected}
                      setSelectedArray={setFoodDrinkTagSelected}
                      data={foodDrinkTag}
                      placeholder="Food & Drink Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="foodDrinkTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Families
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={familyTagsSelected}
                      setSelectedArray={setFamilyTagsSelected}
                      data={familyTags}
                      placeholder="Family Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="familyTagsSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      House Keeping
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={houseKeepingTagSelected}
                      setSelectedArray={sethouseKeepingTagSelected}
                      data={houseKeepingTag}
                      placeholder="House Keeping Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="houseKeepingTagSelected"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Accessibility
                    </h2>
                    <MultiSelectParent
                      // required={true}
                      selectedArray={accessibilityTagSelected}
                      setSelectedArray={setAccessibilityTagSelected}
                      data={accessibilityTag}
                      placeholder="Accessibility Tags"
                      isAdmin={
                        process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="accessibilityTagSelected"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[130px] mb-[60px] flex flex-col ">
                <Cancellation
                  policy={policy}
                  setPolicy={setPolicy}
                  validationError={validationError}
                  setValidationError={setValidationError}
                  addedPolicy={addedPolicy}
                />
                {/* <button className="border" onClick={()=>{postPolicy(187)}}>button</button>
                                <button className="border" onClick={()=>{console.log(policy,'and')}}>button 2</button> */}
              </div>
              {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
                <div className="mt-8">
                  <FileMultiple
                    setNeedUpdate={setNeedUpdate}
                    id={id}
                    urlTempStorageArray={urlTempStorageArray}
                    setUrlTempStorageArray={setUrlTempStorageArray}
                  />
                </div>
              ) : null}
              <div className="grid grid-cols-6 gap-4 my-14 ">
                {images &&
                  images.length > 0 &&
                  images.map((image, index) => {
                    return (
                      <RemoveableImageUploadView
                        fetchSummeryData={fetchSummeryData}
                        // setNeedUpdate={setNeedUpdate}
                        url={image.url}
                        id={image.id}
                        userType={returnAdminOrUserBasedOnPath(
                          location.pathname
                        )}
                        setUrlTempStorageArray={setImages}
                        urlTempStorageArray={images}
                      />
                    );
                  })}
                {/* temp uploaded imageview */}
                {urlTempStorageArray &&
                  urlTempStorageArray.length > 0 &&
                  urlTempStorageArray.map((image, index) => {
                    return (
                      <RemoveableImageUploadView
                        // setNeedUpdate={setNeedUpdate}
                        fetchSummeryData={fetchSummeryData}
                        url={image.url}
                        userType={returnAdminOrUserBasedOnPath(
                          location.pathname
                        )}
                        setUrlTempStorageArray={setUrlTempStorageArray}
                        urlTempStorageArray={urlTempStorageArray}
                        id={-1 * (index + 1)}
                      />
                    );
                  })}
              </div>
              <div className="">
                {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
                  response.loading ? null : (
                    <div className="flex p-10 xl:px-0 xl:container mx-auto">
                      <ButtonOutlined
                        title="Save"
                        buttonOutlinedHandle={buttonOutlinedHandle}
                        w="176px"
                        h="49px"
                        disable={
                          fetchSummeryData.status === "PENDING" || disabling
                            ? "yes"
                            : "no"
                        }
                      />
                      <ButtonFilled
                        title="Next"
                        buttonFilledHandle={buttonFilledHandle}
                        w="291px"
                        h="49px"
                        disable={
                          fetchSummeryData.status === "PENDING" || disabling
                            ? "yes"
                            : "no"
                        }
                      />
                    </div>
                  )
                ) : response.loading ? null : (
                  <div className="flex justify-center">
                    <ButtonFilled
                      title="Review Submit"
                      buttonFilledHandle={adminReviewSubmit}
                      w="291px"
                      h="49px"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelComponent;
