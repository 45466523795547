import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const colorStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    "&:hover": { borderColor: "#4E90A8", borderWidth: "2px" },
    border: "2px solid #afc1c8",
    boxShadow: `5px 10px 10px  '#4E90A8'`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#376576" : "inherit",
    "&:hover": { backgroundColor: state.isSelected ? "#376576" : "#ebf0f1" },
    lineHeight: '1',
  }),
};

const transformArray = (inputArray, idFieldName, nameFieldName) => {
  return inputArray?.map(
    ({ [idFieldName]: value, [nameFieldName]: label }) => ({
      label,
      value,
    })
  );
};

const colorStylesTour = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    "&:hover": { borderColor: "#4E90A8", borderWidth: "1px" },
    border: "1px solid #B3C2C7",
    fontSize: "12px",
    borderRadius: "8px",
    boxShadow: `5px 10px 10px  '#4E90A8'`,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#376576" : "inherit",
    "&:hover": { backgroundColor: state.isSelected ? "#376576" : "#ebf0f1" },
    lineHeight: '1',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "#879FA8", // Adjust the placeholder color as needed
  }),
};

export { colorStyles, animatedComponents, colorStylesTour,  transformArray };