import React, { useState } from "react";

import { useNavigate, useParams } from "react-router";
import { merchantTourRoute } from "../../contants/Constants";
import { convertStringToObject } from "../AllPackages/ParentPackageSelected";
import { useDispatch } from "react-redux";
import { setTourStepperValue } from "../../store/marchantDashboard/categoryTour/tourSetupStepper";

const TourSingleCard = ({ tour, handleViewButton, tourId }) => {
  const navigate = useNavigate();
  const {
    tour_description,
    tour_id,
    tour_itinerary,
    tour_name,
    tour_specialities,
    tour_thumb_image_url,
    tour_title,
    number_of_days,
    number_of_nights,
    is_subscribed_by_merchant
  } = tour;
  const dispatch = useDispatch();
  const handleSetStepperValue = (value) => {
    dispatch(setTourStepperValue(value));
  };
  const changeUrl = () => {
    handleSetStepperValue(0)
  navigate(`${merchantTourRoute.MY_TOUR}${tour_id}`)
  }
 


  console.log("is_subscribed_by_merchant", is_subscribed_by_merchant);

  return (
    <>
    {is_subscribed_by_merchant !== true && <div
        style={{ boxShadow: "0px 0px 4px 0px rgba(135, 159, 168, 0.15)" }}
        className={`h-full w-[240px] border-[1px] border-[#DDE7EB] rounded-[16px] ease-in duration-500 ${tourId === tour_id ? "bg-tour-primary text-bg-white" : ""}`}
      >
        <div className="">
          <img
            className="h-[127px] w-full rounded-t-[16px]"
            src={tour_thumb_image_url}
            alt=""
          />
        </div>
        
        <div className="px-[8px] pb-[16px] max-h-full ">
          <div className="flex flex-col h-full   gap-[16px]">
            <div className="flex  flex-col gap-[4px]">
              <p
                className={` pt-[8px] h-[30px] text-[16px] font-medium truncate ${tourId === tour_id ? "text-bg-white" : "text-[#142B33]"}`}
                title={tour_title}
              >
                {tour_title}
              </p>
              <div className="flex gap-x-[4px] h-[20px]">
                <p className={`text-[14px] font-normal ${tourId === tour_id ? "text-bg-white" : "text-[#142B33]"}`}>Days:</p>
                <p className={`text-[14px] font-normal ${tourId === tour_id ? "text-bg-white" : "text-[#466773]"}`}>
                  {number_of_days} days
                </p>
              </div>
              <div className="flex gap-x-[4px] h-[20px]">
                <p className={`text-[14px] ${tourId === tour_id ? "text-bg-white" : "text-[#142B33]"}`}>Nights:</p>
                <p className={` text-[14px] ${tourId === tour_id ? "text-bg-white" : "text-[#466773]"}`}>
                  {number_of_nights} nights
                </p>
              </div>
            </div>
            {tourId === tour_id ? <button
              className="bg-bg-white w-[222px] h-[36px] text-[14px] font-normal px-[16px] py-[10px] flex items-center justify-center text-tour-primary rounded-[50px] ease-in duration-300 hover:bg-tour-secondaryHint "
              onClick={() => {
                changeUrl()
              }}
            >
              Subscribe
            </button> :
            <button
            className="bg-[#4094B2]  w-[222px] h-[36px] text-[14px] font-normal  px-[16px] py-[10px] flex items-center justify-center text-bg-white rounded-[50px] ease-in duration-300 hover:bg-[#539fba]"
            onClick={() => {handleViewButton(tour)}}
          >
            View
          </button>
            }
            
          </div>
        </div>
       
      
      {/* <div className="w-[279px] bg-[#F5F9FA] rounded-[12px] shadow hover:shadow-lg mx-auto ">
        <div className="">
          <img
            className="rounded-t-[12px] w-[279px] h-[205px]"
            src={tour_thumb_image_url}
            alt=""
          />
        </div>
        <div className="h-[130px] ">
          <div className="text-[#142B33] flex flex-col justify-between gap-4">
            <div className="px-[12px]">
              <div className="mt-[8px]">
                <p className="text-[18px]">{tour_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>}
    </>
      
  );
};

export default TourSingleCard;
