import React, { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { BLOG_CONTROLLER_API } from "../../contants/Endpoints";
import Loading from "../Loader/Loading";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ModalCommon from "../common/ModalCommon";

const AllBlogs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const [blogId, setBlogId] = useState();
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [toggleModalSecond, setToggleModalSecond] = useState(false);
  let pageSize = 100;

  const getAllBlogs = () => {
    setIsLoading(true);
    authAxios
      .get(`${BLOG_CONTROLLER_API?.USER_GET_ALL_BLOGS}`)
      .then((res) => {
        setAllBlogs(res?.data?.blog_posts);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onNewAdd = () => {
    navigate("/content/3bbblog/blog/ghuddy/0");
  };

  const onEditButton = (blog) => {
    navigate(`/content/3bbblog/blog/ghuddy/${blog?.blog_post_id}`, {
      state: blog,
    });
  };

  const onConfirmDelete = () => {
    setIsLoading(true)
    authAxios.delete(`${BLOG_CONTROLLER_API.USER_BLOG_DELETE_BY_ID}/${blogId}?requestId=123`)
    .then((res) => {
      toast.success("Successfully deleted blog")
      setIsLoading(false)
      getAllBlogs();
      onIndexBlogAll()
      setToggleModalSecond(false)
    })
    .catch((err) => {
      toast.error("Deleted blog failed")
      console.log(err)
      setToggleModalSecond(false)
    })
  };

  const onIndexBlogAll = () => {
    authAxios.put(`${BLOG_CONTROLLER_API?.ADMIN_BLOG_INDEX_ALL}?requestId=222`)
    .then((res) => {
      toast.success("Successfully blog index called")
    })
    .catch((err) => {
      console.log(err)
      toast.error("Blog index call failed.")
    })
  } 

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div className="w-[343px] md:w-[712px] xl:w-[1100px] mx-auto">
      <p className="text-[22px] text-btn-secondary text-center mb-[20px]">
        All Blogs
      </p>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div>
          <div className="flex justify-end">
            <TourMerchantButton
              width={""}
              name={"Add New Blog"}
              onClick={() => onNewAdd()}
            ></TourMerchantButton>
          </div>
          {/* Show if data has */}
          {allBlogs?.length > 0 && (
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Sl No.</th>
                    <th>Thumb Image</th>
                    <th>Title</th>
                    <th>ID</th>
                    <th>Activated</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allBlogs?.length > 0 &&
                    allBlogs?.map((property, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + pageSize * currentPage - pageSize}.
                        </td>
                        <td className="cursor-pointer mx-auto">
                          {property?.thumb_image_url?.length > 12 ? (
                            <img
                              className="mx-auto w-[100px] h-[60px]"
                              src={property?.thumb_image_url}
                              alt=""
                            />
                          ) : (
                            "No Image!"
                          )}
                        </td>
                        <td className="whitespace-pre-line">
                          {property?.title}
                        </td>
                        <td>{property?.blog_post_id}</td>
                        <td className="whitespace-pre-line">
                          {property?.is_active ? "True" : "False"}
                        </td>
                        <td>
                          <div className="flex flex-row gap-x-[8px]">
                            <button
                              onClick={() => onEditButton(property)}
                              className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                setToggleModalSecond(true);
                                setBlogId(property?.blog_post_id);
                              }}
                              className="bg-[#f87171] text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-md shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg "
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div> */}
            </div>
          )}

          <div className="my-[30px] flex justify-center items-center w-full"></div>
        </div>
      )}

      <ModalCommon
        shown={toggleModalSecond}
        width="max-w-[280px]"
        height="min-h-[150px]"
        outSideOff={false}
        close={() => {
          setToggleModalSecond(false);
        }}
      >
        <div className="p-[24px]">
          <p className="text-btn-primary">Are you sure you want to delete?</p>
          <div className="flex justify-between">
            <button
              onClick={() => setToggleModalSecond(false)}
              className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
            >
              CANCEL
            </button>
            <button
              onClick={() => onConfirmDelete()}
              className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
            >
              CONFIRM
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default AllBlogs;
