import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { DashboardRoutes } from "../../contants/Constants";
import { apiEndPoint, CATEGORY_APIS } from "../../contants/Endpoints";
import { categoriesInfo } from "../../store/admin/adminContract/api/adminContractApi";
import { authAxios } from "../../utils/axiosWrapper";
import ButtonFilled from "../ButtonFilled";
import ButtonOutlined from "../ButtonOutlined";
import { CategoryDisableSvg, CategoryEnableSvg } from "../common/AllSVGImport";
import ModalGlobal from "../ModalGlobal";
import SingleSelectParentCategoryReturn from "../singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "200px",
    width: "450px",
    borderRadius: "32px",
    backgroundColor: "#FFFBEF",
  },
};
function DashboardCategoryTable({}) {
  let navigate = useNavigate();
  const router = useLocation();
  const [addCategory, setAddCategory] = useState("start");
  const [categories, setCategories] = React.useState([]);
  console.log("categories", categories);

  const [editId, setEditId] = useState();
  const [deleteCandidate, setDeleteCandidate] = useState({
    id: 0,
    index: -1,
  });
  const [enable, setEnable] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const dispatch = useDispatch();

  const handleCategoryEdit = (id) => {
    console.log("idididid:", id);
    setEditId(id);
    setAddCategory("add-a-cat");
  };

  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const response = useSelector((state) => state.categoriesInfoGetReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHotels();
    // dispatch(categoriesInfo("USER", 0));
  }, [addCategory]);

  useEffect(() => {
    if (selectedHotel) {
      authAxios
        .get(
          `${apiEndPoint.MERCHANT_GET_CATEGORY_BY_PROPERTY}${selectedHotel.id}?requestId=2`
        )
        .then((res) => {
          if (res.data.roomCategories.length > 0) {
            setCategories(res.data.roomCategories);
            // setSelectedCategory(res.data.roomCategoryList[0]);
          } else {
            setCategories([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedHotel]);

  const getHotels = () => {
    authAxios
      .get(`${apiEndPoint.MERCHANT_GET_OWN_HOTELS}`)
      .then((res) => {
        setHotelList([...res.data.propertyDataList]);
        setSelectedHotel(res.data.propertyDataList[0]);
      })
      .catch((err) => {});
  };

  // useEffect(() => {
  //     if (response && response.data) {
  //         setCategories(response.data.roomCategoryList);
  //     }
  // }, [response, addCategory, deleteCandidate]);

  const newAddHandleClick = () => {
    navigate(DashboardRoutes.CATEGORIES_DETAILS + "0");
  };

  const deleteCategory = () => {
    authAxios
      .delete(`${CATEGORY_APIS.DELETE}${deleteCandidate.id}?requestId=deleteMe`)
      .then((res) => {
        const temp = categories.filter((el) => el.id != deleteCandidate.id);
        setCategories([...temp]);
      })
      .catch((err) => {});
  };

  const handleDeleteClick = (id, index) => {
    setDeleteCandidate({ ...deleteCandidate, id: id, index: index });
    setShowDeleteModal(true);
  };

  return (
    <div>
      <div className="container mx-auto">
        <div>
          <div className="md:px-12 lg:px-0 flex justify-between p-4">
            <div className="text-[27px] py-2 flex items-center">
              Categories
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26C17.1046 26 18 25.1046 18 24Z"
                  fill="#2A220E"
                ></path>
                <path
                  d="M18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18C17.1046 18 18 17.1046 18 16Z"
                  fill="#2A220E"
                ></path>
                <path
                  d="M18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8Z"
                  fill="#2A220E"
                ></path>
              </svg>
            </div>
            <div>
              <SingleSelectParentCategoryReturn
                warningView={false}
                selectedValue={selectedHotel}
                setSelectedValue={setSelectedHotel}
                data={hotelList}
                placeholder="Select Hotel"
                displayField={"propertyName"}
                selectedFieldName="id"
              />
            </div>
            <div className="cursor-pointer" onClick={newAddHandleClick}>
              <svg
                width="50"
                height="50"
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_3650_18025)">
                  <rect
                    x="19"
                    y="16"
                    width="40"
                    height="40"
                    rx="20"
                    fill="#4E90A8"
                  />
                  <path
                    d="M37.724 29V34.859H32V36.938H37.724V42.77H40.019V36.938H45.77V34.859H40.019V29H37.724Z"
                    fill="#FFFBEF"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_3650_18025"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="-3" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_3650_18025"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_3650_18025"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>

          <div className="w-full rounded-t-[25px] overflow-hidden pb-20">
            <table id="students" className="leading-normal w-full  pb-12">
              <thead className="border border-btn-primary">
                <tr className="h-[48px]  bg-btn-primary text-left text-lg font-normal text-bg-white ">
                  <th></th>
                  <th className="px-5 py-3 bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Category Name
                  </th>
                  <th className="px-5 py-3  bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Price
                  </th>
                  <th className="px-5 py-3  bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Class
                  </th>
                  <th className="px-5 py-3   bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Rooms
                  </th>
                  <th className="px-5 py-3   bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Available
                  </th>
                  <th className="px-5 py-3   bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Taken
                  </th>
                  <th className="px-5 py-3   bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Amenties
                  </th>
                  <th className="px-5 py-3 bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Capacity
                  </th>
                  {/* <th className="px-5 py-3  bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Enabled
                  </th> */}
                  <th className="px-5 py-3  bg-gray-100 text-left text-lg font-normal text-gray-700 tracking-wider">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories &&
                  [...categories].map((member, index) => {
                    const {
                      categoryId,
                      categoryName,
                      noOfKingBeds,
                      noOfRoomsInCategory,
                      baseRate,
                      maxNumberOfAdultOccupants,
                      maxNumberOfChildOccupants,
                      currency,
                    } = member; //destructuring
                    return (
                      <tr
                        key={categoryId}
                        className={`mx-10 rounded-3xl border border-txt-hint ${
                          enable ? "" : "text-txt-hint"
                        }`}
                      >
                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                          {categoryId}
                        </td>
                        <td className="h-[80px] px-5 py-5  bg-white text-sm">
                          {categoryName}
                        </td>
                        <td className="h-[80px] px-5 py-5  bg-white text-sm">
                          Avg. {baseRate} {currency}
                        </td>
                        <td className="h-[80px] px-5 py-5  bg-white text-sm">
                          B
                        </td>
                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                          {noOfRoomsInCategory}
                        </td>
                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                          {noOfRoomsInCategory}
                        </td>
                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                          0
                        </td>

                        <td className="h-[80px] px-5 py-5 bg-white text-sm">
                          {noOfKingBeds} King Size Beds
                        </td>
                        <td className="px-5 py-5  bg-white text-sm">
                          up to {maxNumberOfAdultOccupants} adults
                          <br />
                          up to {maxNumberOfChildOccupants} children
                        </td>
                        <td className="px-5 py-5 bg-white text-sm">
                          <button
                            className="rounded-lg shadow px-4 py-1 active:scale-90 hover:bg-[rgb(235,231,219)]"
                            onClick={() => {
                              navigate(
                                `${DashboardRoutes.CATEGORIES_DETAILS}${categoryId}`
                              );
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                {(categories && categories.length) === 0 && (
                  <tr
                    className="mx-10 rounded-3xl border border-txt-hint ${
                                  enable"
                  >
                    <td className="px-5 py-5 bg-white text-sm">No Data</td>
                  </tr>
                )}
              </tbody>
            </table>

            <ReactModal
              isOpen={showDeleteModal}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick={true}
              style={customStyles}
              onRequestClose={() => setShowDeleteModal(false)}
            >
              <div className="h-full p-6">
                <div className="flex flex-col justify-between h-full">
                  <h1 className="text-center">Are you sure ?</h1>
                  {/* <hr className="flex-grow"/> */}
                  <div className="flex justify-center gap-x-10 items-center">
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="px-6 py-2 rounded-3xl border hover:bg-bg-semiwhite"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={deleteCategory}
                      className="px-6 py-2 rounded-3xl border-btn-primary text-bg-white bg-btn-primary border hover:bg-btn-secondary"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCategoryTable;
