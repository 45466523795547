import React from "react";
import { CiEdit } from "react-icons/ci";
import moving from "../../assets/icon/moving.svg";
export default function TourSpotFeeInitail({ handlePackage, spot_entries }) {
  return (
    <div className="w-full flex xl:p-[16px] md:p-[8px] p-[4px]   bg-bg-white rounded  ">
      <div className="w-[8%]  flex items-center justify-center ">
        <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee]">
          <img
            className="xl:w-16 md:w-12 w-8 xl:h-16 md:h-12 h-[32px]"
            src={moving}
            alt=""
          />
        </span>
      </div>
      <div className=" w-[84%] xl:px-[12px] lg:px-[10px] md:px-[8px] px-[6px] flex flex-col items-start  xl:text-[14px] lg:text-[14px] md:text-[12px] text-[10px]">
        <div className="bg-btn-secondary  text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] mb-[8px] rounded">
          {"Spot Fee"}
        </div>
        <div className="flex flex-col gap-y-[8px] w-full">
          {spot_entries?.map((spot, index) => (
            <div>
              <p>Spot : {spot?.activity_id?.label} </p>

              <p>Remark : {spot?.remark}</p>
              <p>Price : {spot?.spot_entry_price_per_person}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-[8%] flex items-center text-[30px]">
        <div className="flex">
          <button
            onClick={() => handlePackage(5, "spot")}
            className="cursor-pointer text-[#22c55e]"
            title="Edit"
          >
            <CiEdit></CiEdit>
          </button>
        </div>
      </div>
    </div>
  );
}
