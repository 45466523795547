const initialState = {
    value: 0
  };

  const tourSetupStepper = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_VALUE':
        return {
          ...state,
          value: action.payload
        };
      default:
        return state;
    }
  };

  export const setTourStepperValue = (value) => ({
    type: 'SET_VALUE',
    payload: value
  });

  export default tourSetupStepper;