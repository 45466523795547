import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import RemoveableImageUploadViewCategory from "../../../components/RemoveableImageUploadViewCategory";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import {
  animatedComponents,
  colorStyles,
} from "../../../ToursMarchent/Utilities/SelectAnimation";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourActivityAddDashboard() {
  const [imageCaption, setImageCaption] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [submitButton, setSubmitButton] = useState(false);
  const [allAccommandationType, setAllAccommandationType] = useState([]);
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
  const [modalShown, setToggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [activityAdds, setActivityAdds] = useState({
    activity_images: [],
    activity_name: "",
    activity_type_id: "",
    short_location: "",
  });

  const [activityType, setActivityType] = useState();
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint.ADMIN_FETCH_ACTIVITY_TYPE_GET_BY_SEARCH_API}`
      )
      .then((response) => setActivityType(response?.data?.activity_types));
  }, []);

  const getActivityList = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_FETCH_ACTIVITY_LIST_GET_API}`)
      .then((response) => setActivityList(response?.data));
  };

  useEffect(() => {
    getActivityList();
  }, []);

  // console.log("activityList", activityList);

  const handleActitvityImageAdd = () => {
    if (urlTempStorageArray?.length > 0) {
      setActivityAdds((prevState) => ({
        ...prevState,
        activity_images: [
          ...prevState.activity_images,
          ...urlTempStorageArray.map((item) => ({
            file_name: item.fileName,
            image_url: item.url,
          })),
        ],
      }));
    }
    setSubmitButton(true);
  };

  const getAccomandationTypes = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_ACCOMMANDATION_TYPE_GET_API}`)
      .then((response) =>
        setAllAccommandationType(response?.data?.tourAccommodationTypeDataList)
      );
  };

  useEffect(() => {
    getAccomandationTypes();
  }, []);
  const handlePackageAddApi = () => {
    let activitiesToSend = {
      ...activityAdds,
      activity_type_id: activityAdds?.activity_type_id?.value,
    };

    if (urlTempStorageArray?.length > 0) {
      activitiesToSend.activity_images = [
        ...activityAdds.activity_images,
        ...urlTempStorageArray.map((item) => ({
          file_name: item.fileName,
          image_url: item.url,
        })),
      ];
    }

    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_ACTIVITY_ADD_POST_API}`, {
        activity: activitiesToSend,
        requestId: "add-tour-activity",
      })
      .then((response) => {
        // console.log("response", response);
        if (response.status === 201) {
          toast.success("Wow!, you have successfully added");
          setActivityAdds({
            activity_images: [],
            activity_name: "",
            activity_type_id: "",
            short_location: "",
          });
          setUrlTempStorageArray([]);
          getActivityList();
        }
      });
  };

  const transformedArray = activityType?.map(
    ({ activity_type_id, activity_type_name }) => ({
      label: activity_type_name,
      value: activity_type_id,
    })
  );

  const removeActivityApi = (id) => {
    authAxios
      .delete(
        `${baseUrl}${apiEndPoint.ADMIN_DELETE_ACTIVITY_DELETE_API}${id}?requestId=wde`
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Successfully deleted activity");
          getActivityList();
        }
      });
  };

  return (
    <div className="max-w-[1100px] mx-auto mt-[30px]">
      <p>Tour Activity Added list</p>
      <div className=" flex flex-col gap-y-[15px] max-w-[500px] mt-[12px] overflow-y-scroll p-[12px] max-h-[550px]">
        {activityList?.activities?.map((activity, index) => (
          <div
            key={index}
            className="shadow-customShadow p-[10px] rounded-[4px] flex flex-col gap-y-[3px] relative"
          >
            <p className="text-[14px]">
              {" "}
              <span className="text-[12px]">Activity Name :</span>{" "}
              {activity?.activity_name}
            </p>
            <p className="text-[14px]">
              {" "}
              <span className="text-[12px]">Activity Type :</span>{" "}
              {activity?.activity_type_name}
            </p>
            <p className="text-[14px]">
              {" "}
              <span className="text-[12px]">Short Location :</span>{" "}
              {activity?.activity_short_location}
            </p>
            <div className="flex justify-start gap-x-[10px] flex-wrap">
              {activity?.activity_images?.map((activity, index) => (
                <img
                  key={index}
                  className="w-[150px] h-[80px] rounded-[4px]"
                  src={activity?.activity_image_url}
                  alt={activity}
                />
              ))}
            </div>

            <span
              onClick={() => removeActivityApi(activity?.activity_id)}
              className="absolute top-[-12px] right-[-8px] cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[25px] h-[25px] text-[#EC7063]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </div>
        ))}
      </div>
      <div className="max-w-[450px] w-full fixed right-[30px] top-[100px] shadow-customShadow py-[10px] rounded-[4px]">
        <h2 className="text-[16px] text-center underline">Tour Activity Add</h2>
        <div className="px-[30px]">
          <div>
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setActivityAdds((prev) => ({
                    ...prev,
                    activity_name: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={activityAdds?.activity_name}
              />
              <span className="placeholder">Activity Name :</span>
            </label>
          </div>
          <div>
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setActivityAdds((prev) => ({
                    ...prev,
                    short_location: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={activityAdds?.short_location}
              />
              <span className="placeholder">Short Location :</span>
            </label>
          </div>

          <div className="mt-[6px]">
            <Select
              value={activityAdds?.activity_type_id}
              onChange={(activity_type_id) =>
                setActivityAdds((prev) => ({
                  ...prev,
                  activity_type_id: activity_type_id,
                }))
              }
              components={animatedComponents}
              options={transformedArray}
              placeholder={"Activity Type: "}
              styles={colorStyles}
            />
            {/* <CustomInputWithDropdown
              setSelectedOption={(activity_type_id) =>
                setActivityAdds((prev) => ({
                  ...prev,
                  activity_type_id: activity_type_id,
                }))
              }
              selectedOption={activityAdds?.activity_type_id?.label}
              labelText={"Activity Type"}
              options={transformedArray}
            /> */}
          </div>

          <div className="mt-[10px]">
            <div className="flex justify-start items-center">
              <div className="flex flex-row gap-x-[4px]">
                {urlTempStorageArray &&
                  urlTempStorageArray?.length > 0 &&
                  urlTempStorageArray?.map((image, index) => {
                    return (
                      <RemoveableImageUploadViewCategory
                        url={image.url}
                        userType={"USER"}
                        // id={index}
                        setUrlTempStorageArray={setUrlTempStorageArray}
                        urlTempStorageArray={urlTempStorageArray}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="flex justify-between mt-[10px]">
              <div className="mb-10  w-[200px]">
                <FileMultipleCategory
                  width={"w-full"}
                  id={1}
                  setNeedUpdate={() => {}}
                  urlTempStorageArray={urlTempStorageArray}
                  setUrlTempStorageArray={setUrlTempStorageArray}
                  multiple={false}
                  setIsLoading={setIsLoading}
                />
              </div>
              {isLoading && (
                <div
                  className={`flex items-center justify-center  w-2/5 ${
                    urlTempStorageArray.length ? "h-[245px]" : ""
                  }`}
                >
                  <p>Loading...</p>
                </div>
              )}
            </div>
          </div>
          <button
            disabled={
              activityAdds?.activity_name?.length === 0 ||
              activityAdds?.short_location?.length === 0 ||
              activityAdds?.activity_type_id?.value === undefined ||
              urlTempStorageArray?.length === 0
            }
            onClick={() => handlePackageAddApi()}
            className="bg-btn-primary h-[49px] text-bg-white rounded-[4px] w-full mt-[20px] disabled:bg-bg-semiblack"
          >
            Submit
          </button>
          {/* {submitButton ? (
            <button
              onClick={() => handlePackageAddApi()}
              className="bg-btn-primary h-[49px] text-bg-white rounded-[4px] w-full mt-[20px]"
            >
              Submit
            </button>
          ) : (
            <button
              onClick={() => handleActitvityImageAdd()}
              className="bg-btn-primary h-[49px] text-bg-white rounded-[4px] w-full mt-[20px]"
            >
              Confirm Image Upload
            </button>
          )} */}
        </div>
      </div>

      {/* <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px]">
        <div className="flex flex-col gap-y-[10px]">
          {activityAdd?.map((activity, index) => (
            <div
              key={index}
              className="border border-[#ddd] rounded-[8px] p-[10px]"
            >
              <div className="flex flex-wrap justify-start items-center gap-x-[5px]">
                {activity?.activity_images?.map((url) => (
                  <img
                    className="w-[80px] h-[70px] rounded-[10px]"
                    src={url?.image_url}
                    alt=""
                  />
                ))}
              </div>
              <div className="text-[16px] mt-[10px]">
                <p className="">
                  {" "}
                  <span className="text-[12px]">Activity Name :</span>{" "}
                  {activity?.activity_name}
                </p>
                <p>
                  {" "}
                  <span className="text-[12px]">Activity Type :</span>{" "}
                  {activity?.activity_type_id}
                </p>
                <p>
                  {" "}
                  <span className="text-[12px]">Activity Location :</span>{" "}
                  {activity?.short_location}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-end mt-[10px]">
          <button
            className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
              activityAdd?.length > 0 ? "w-[150px] " : "w-full"
            }`}
            onClick={() => setToggleModal(true)}
          >
            Add New
          </button>
        </div>
        {activityAdd?.length > 0 && (
          <button
            onClick={() => handlePackageAddApi()}
            className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white"
          >
            Submit
          </button>
        )}
      </div> */}
    </div>
  );
}
