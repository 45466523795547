import { useNavigate } from "react-router";

export const changeUrl = (id, step) => {
  const state = window.history.state;
  const url = new URL(window.location.toString());
  url.href = url.origin + "/dashboard/excursion/create";
  url.searchParams.set("step", step);
  url.searchParams.set("id", id);
  window.history.pushState(state, document.title, url);

  // console.log("url: ", url);
  // navigate(url)
  return url;
};


export const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current     
    setTimeout(() => {
      e.target.focus()
  }, 0)
}

