import React, { useState } from "react";
import { useSelector } from "react-redux";
import SteperRaper from "../../excursionMarchant/Steper/Steper";
import ContractComponent from "./ContractComponent";
import ContractComponentV2 from "./ContractComponentV2";
import DocumentsV2 from "./registration/DocumentsV2";
import OwnerComponent from "./OwnerComponent";
import OwnerComponentV2 from "./OwnerComponentV2";

import { authAxios } from "../../utils/axiosWrapper";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

export default function AdminReviewRoot() {
  const contractId = JSON.parse(localStorage.getItem("contractId")) ?? 0;
  const router = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [rejectionArrays, setRejectionArrays] = useState({
    addressRejectionReasons: {},
    contractInfoRejectionReasons: {},
    documentRejectionReasons: {},
    merchantId: Number(router?.search?.split("=")[1]),
    ownerRejectionReasons: {},
    requestId: "review",
  });

  const hnadleReviewAdmin = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_SEND_REVIEW_REQUEST}`,
        rejectionArrays
      )
      .then((res) => {
        toast.success("Successfully Requested!");

        console.log(res, "test");
      })
      .catch((err) => {});
  };
  return (
    <div>
      <SteperRaper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={3}
      />

      <div>
        {activeStep === 0 && (
          <OwnerComponentV2
            setActiveStep={setActiveStep}
            setRejectionArrays={setRejectionArrays}
            rejectionArrays={rejectionArrays}
          />
        )}
        {activeStep === 1 && (
          <ContractComponentV2
            setActiveStep={setActiveStep}
            setRejectionArrays={setRejectionArrays}
            rejectionArrays={rejectionArrays}
          />
        )}
        {activeStep === 2 && (
          <DocumentsV2
            setRejectionArrays={setRejectionArrays}
            hnadleReviewAdmin={hnadleReviewAdmin}
          />
        )}
      </div>
    </div>
  );
}
