import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  colorStylesTour,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";

export default function Guide({
  setIsLoading,
  editAblePackage,
  changeSelectedNewItemList,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [guidePackageList, setGuidePackageList] = useState([]);
  const [guidePackage, setGuidePackage] = useState({
    number_of_guides_for_day: "",
    tour_guide_price_per_day: "",
    day_number: "",
  });
  const [getToursById, setGetToursById] = useState();

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetToursById(res?.data);
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);
  const numberOfDay = Array.from(
    { length: getToursById?.number_of_days },
    (_, index) => index + 1
  );
  const dayProvide = numberOfDay?.map((day) => ({
    label: `${day} Day`,
    value: day,
  }));

  function transformPayload(payload) {
    const newPayload = {
      day_number: payload.day_number.value,

      number_of_guides_for_day: parseInt(payload.number_of_guides_for_day),
      tour_guide_price_per_day: parseInt(payload.tour_guide_price_per_day),
    };

    return newPayload;
  }

  const handleSubmitGuide = () => {
    const newPayload = transformPayload(guidePackage);
    setIsLoading(true);
    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_GUIDE_PACKAGE}`, {
        requestId: "guide-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_guide_package: newPayload,
      })
      .then((res) => {
        toast.success("Guide Add");
        setGuidePackage({
          number_of_guides_for_day: "",
          tour_guide_price_per_day: "",
          day_number: "",
        });
        setIsLoading(false);
        getGuidePackage();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setGuidePackageList(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getGuidePackage();
  }, []);

  const transformObject = (originalObject) => {
    const b = {
      number_of_guides_for_day: originalObject?.number_of_guides_for_day,
      tour_guide_price_per_day:
        originalObject?.total_guide_price_for_day?.toString(),
      day_number: {
        label: originalObject?.day_number,
        value: originalObject?.day_number,
      },
    };

    return b;
  };
  const handleEditGuide = (guide) => {
    const editGuide = transformObject(guide);
    setGuidePackage(editGuide);
  };

  useEffect(() => {
    if (editAblePackage?.hasOwnProperty("tour_package_guide_package_id") &&
    editAblePackage?.hasOwnProperty("number_of_guides_for_day") &&
    editAblePackage?.hasOwnProperty("total_guide_price_for_day") &&
    editAblePackage?.hasOwnProperty("day_number")) {
      const editFood = transformObject(editAblePackage);
      setGuidePackage(editFood);
    }
  }, [editAblePackage]);

  return (
    <div className="py-[16px] pl-[16px]">
      {/* <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Guide Add</h2>
      </div> */}
      <div>
        <div className="flex">
          <div className="w-full flex flex-col gap-[8px]">
          <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Day Provide {" "}
                </p>
              </div>
              <div className="">
                <Select
                  value={guidePackage?.day_number || ""}
                  onChange={(data) => {
                    setGuidePackage((prev) => ({
                      ...prev,
                      day_number: data,
                    }));
                  }}
                  components={animatedComponents}
                  options={dayProvide}
                  placeholder={"Select Day: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                   Guide Quantity{" "}
                </p>
              </div>
              <div className="">
                <input
                  className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                  placeholder="Guide Number"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setGuidePackage((prev) => ({
                        ...prev,
                        number_of_guides_for_day: inputVal,
                      }));
                    }
                  }}
                  onWheel={numberInputOnWheelPreventChange}
                  value={guidePackage?.number_of_guides_for_day}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Price Per Day{" "}
                </p>
              </div>
              <div className="relative">
                <input
                  className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                  placeholder="Per Day price"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setGuidePackage((prev) => ({
                        ...prev,
                        tour_guide_price_per_day: inputVal,
                      }));
                    }
                  }}
                  value={guidePackage?.tour_guide_price_per_day}
                  onWheel={numberInputOnWheelPreventChange}
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
                BDT
              </span>
              </div>
            </div>

            

            {/* <button
              onClick={() => handleSubmitGuide()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button> */}
            {/* <button
              // disabled={
              //   foodPackage?.food_items_ids?.length < 1 &&
              //   foodPackage?.meal_package_unit_price !== Object &&
              //   foodPackage?.meal_type_id !== Object
              // }
              onClick={() => handleSubmitGuide()}
              className="w-full mt-[24px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Add
            </button> */}

            <div className="w-full flex gap-[16px] pt-[24px]">
              <button
                disabled={guidePackageList?.length === 0}
                onClick={() => changeSelectedNewItemList("Spot-Fee", true)}
                className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
              >
                Save & Next
              </button>
              <button
                disabled={
                  guidePackage?.number_of_guides_for_day === undefined ||
                  guidePackage?.number_of_guides_for_day?.length === 0 ||
                  guidePackage?.tour_guide_price_per_day === undefined ||
                  guidePackage?.tour_guide_price_per_day?.length === 0 ||
                  guidePackage?.day_number?.label === undefined ||
                  guidePackage?.day_number?.label?.length === 0
                }
                onClick={() => handleSubmitGuide()}
                className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
              >
                Add more
              </button>
            </div>
          </div>
          {/* view */}
          {/* <div className="w-full">
            <div className="flex flex-col  gap-[16px]">
              {guidePackageList?.map((guide, index) => (
                <div
                  key={index}
                  className="shadow-customShadow p-[10px] rounded-[4px] relative"
                >
                  <p>Guide Number : {guide?.number_of_guides_for_day}</p>
                  <p>Price : {guide?.total_guide_price_for_day}</p>
                  <p>Provided Day :</p>
                  <div className="flex gap-x-[6px] ">
                    <p
                      key={index}
                      className="bg-btn-secondary py-[4px] px-[6px] rounded-[4px] text-bg-white"
                    >
                      {guide?.day_number}
                    </p>
                  </div>
                  <span
                    // onClick={() => handleRemoveGuide(index)}
                    className="absolute right-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[25px] h-[25px] text-btn-pink"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span
                    onClick={() => handleEditGuide(guide)}
                    className="absolute left-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </span>
                </div>
              ))}

              {guidePackageList?.length > 0 && (
                <button
                  onClick={() => navigate(-1)}
                  className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
                >
                  Save
                </button>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
