import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT, apiEndPoint, baseUrl } from "../../contants/Endpoints";
import ModalCommon from "../../components/common/ModalCommon";
import SingleSelectNew from "../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { toast } from "react-toastify";

const CreateItinerary = () => {
    const [itineraryList, setItineraryList] = useState([]);
    const [allData, setAllData] = useState([]);
  const [includes, setIncludes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [iconsList, setIconsList] = useState([]);
  const [temp, setTemp] = useState("");
  const excursionID = 22;

  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 5;

  const [groupData, setGroupData] = useState([]);
  const [itinerary, setItinerary] = useState({
    title: "",
    duration: 0,
    address: "",
    description: "",
    vehicle: "",
    mode_icon_url: {},
  });

  const getItinerary = () => {
    if (excursionID !== 0) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ITINERARY_BY_ID}?excursionId=${excursionID}&pageNumber=${currentPage}&pageSize=${pageSize}&requestId=123`
        )
        .then((res) => {
          const tempData = res?.data?.entries;
          setItineraryList(tempData);
          setTotalPage(res?.data?.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getItinerary();
  }, [currentPage, pageSize]);

  const handleAdd = () => {
    setToggleModal(true);
    setTemp("");
    setItinerary({
      ...itinerary,
      title: "",
      duration: 0,
      address: "",
      description: "",
      vehicle: "",
      mode_icon_url: "",
    });
    // setEditId(0);
  };

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + MERCHANT_EXCURSION_ENDPOINT.VEHICLES_ALL}?requestId=123456`
      )
      .then((res) => {
        setVehicleList(res?.data?.vehicles);
      })
      .catch((err) => {});
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  const onSubmit = () => {
    setGroupData([
      ...groupData,
      itinerary
    ])
    toast.success("Itinerary Added Successfully!");
    setToggleModal(false)
  }

  return (
    <div>
      <div className="mt-[16px] mb-[8px]">
        {groupData?.length === 0 || groupData.length === 0 ? (
          <p className="bg-[#63a561] text-bg-primary py-[8px] px-[16px] rounded-[4px]">
            <span className="text-btn-warning">Itinerary:</span> No Selected Itinerary data.{" "}
          </p>
        ) : (
          <div className="shadow-customShadow rounded-[4px] overflow-hidden">
            <div className="bg-[#63a561]">
              <p className="text-bg-primary  text-center py-[8px]">
              <span className="text-btn-warning">Itinerary:</span>   {groupData.length} Items selected.
              </p>
            </div>
            <div className="flex gap-[8px] w-[574px] overflow-x-auto px-[20px] py-[8px]">
              {groupData?.length &&
                groupData?.map((item, index) => (
                  <div
                    key={index}
                    className="shadow-customShadow min-w-[140px] my-[8px] rounded-[4px]"
                  >
                    <div className="py-[8px] px-[8px]">
                      <p className="text-[#63a561] text-[12px] text-center">
                        Itinerary Id: #{index +1}
                      </p>
                      <p className="text-center text-btn-primary">{item?.title}</p>
                      <div className="flex justify-center gap-[8px] items-center my-[6px]">
                        <img
                          className="min-w-[40px] "
                          src={item?.mode_icon_url?.url}
                          alt=""
                        />
                        <p className="text-btn-secondary">{item?.vehicle}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        </div>
        <div>
            <TourMerchantButton name={"Add New Itinerary"} onClick={handleAdd}></TourMerchantButton>
        </div>


      <div>
          <ModalCommon
            shown={toggleModal}
            width="max-w-[450px]"
            height="min-h-[300px]"
            outSideOff={false}
            close={() => {
              setToggleModal(false);
              setShowError(false);
            }}
          >
            <div className="px-[40px] py-[20px]">
              <div>
                <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                  Itinerary
                </h2>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Title: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Title : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          title: inputVal,
                        }));
                      }}
                      value={itinerary?.title}
                    />
                  </div>
                  {showError && itinerary?.title.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Title is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Duration: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Duration : "
                      type="number"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          duration: inputVal,
                        }));
                      }}
                      value={itinerary?.duration || ""}
                    />
                  </div>
                  {showError && itinerary?.duration <= 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Duration is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Address: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Address : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          address: inputVal,
                        }));
                      }}
                      value={itinerary?.address}
                    />
                  </div>
                  {showError && itinerary?.address.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Address is required
                    </p>
                  )}
                </div>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Description: </p>
                  <div className="rounded">
                    <textarea
                      className="w-full p-[8px] h-[150px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                      placeholder="Description : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          description: inputVal,
                        }));
                      }}
                      value={itinerary?.description}
                    />
                  </div>
                  {showError && itinerary?.description.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Description is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                <p className="text-btn-secondary">Vehicle: </p>
                  <Select
                    value={
                      itinerary.vehicle
                        ? { label: itinerary.vehicle, value: itinerary.vehicle }
                        : null
                    }
                    placeholder={"Select Vehicles: "}
                    onChange={(data) => {
                      setItinerary((prev) => ({
                        ...prev,
                        vehicle: data ? data.label : "", // Ensure data is not null or undefined
                      }));
                    }}
                    components={animatedComponents}
                    options={vehicleList.map((item, index) => ({
                      label: item,
                      value: index,
                    }))}
                    
                    styles={colorStyles}
                  />
                  {showError && itinerary?.vehicle.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Vehicle is required
                    </p>
                  )}
                </div>

                <div className=" pt-[10px]">
                  <p className="pb-[5px]">Mode Icon:</p>

                  <div className="flex justify-start items-center gap-x-[8px]">
                    <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                      <img
                        className="w-[40px] h-[40px]"
                        src={itinerary?.mode_icon_url?.url}
                        alt=""
                      />
                    </div>

                    <SingleSelectNew
                      warningView={false}
                      data={iconsList}
                      displayField="title"
                      placeholder={"Icons"}
                      selectedFieldName={"title"}
                      selectedValue={itinerary?.mode_icon_url?.url}
                      setSelectedValue={(mode_icon_url) =>
                        setItinerary((prev) => ({
                          ...prev,
                          mode_icon_url: mode_icon_url,
                        }))
                      }
                      setSelectedObject={() => {}}
                      image={true}
                      isReadOnly={false}
                      temp={temp}
                      setTemp={setTemp}
                    />
                  </div>
                </div>
                {showError && itinerary?.mode_icon_url.length === 0 && (
                  <p className=" text-[#da5252] py-[2px] text-[14px]">
                    Mode icon is required
                  </p>
                )}
              </div>

              <div className="my-[20px]">
                {/* <button
                  name="submit"
                  className="px-[12px] py-[6px] bg-btn-secondary w-full flex justify-center items-center text-bg-primary rounded-[4px]"
                  onClick={addItinerary}
                >
                  Add
                </button> */}
                <TourMerchantButton
                   onClick={onSubmit}
                  width={"w-full"}
                  name={"Add"}
                ></TourMerchantButton>
              </div>
            </div>
          </ModalCommon>
        </div>
    </div>
  )
}

export default CreateItinerary