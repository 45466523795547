import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";

import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";
import { generateArray } from "../Tours/MarchantTourSubscribe";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../Utilities/SelectAnimation";
import AccommondationViewSelected from "./AccommondationViewSelected";
import { convertStringToObject } from "./ParentPackageSelected";

const AccomodationPackage = ({
  setMarchantCreateDatas,
  setPackageSelect,

  marchantTourCreateDatas,
  setViewPage,
  viewPage,
}) => {
  const [roomAllList, setRoomAllList] = useState([]);
  const [roomCategoryList, setRoomCategoryList] = useState([]);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [night, setNight] = useState(1);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [updateButtonEnable, setUpdateButtonEnable] = useState(false);

  const handleBackButton = () => {
    setPackageSelect(null);
  };
  const dispatch = useDispatch();

  const [getTours, setGetTours] = useState();
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
      })
      .catch((err) => {});
  }, []);
  const nightArray = generateArray(getTours?.number_of_nights);
  const getAllPackageList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_GET_ALL_PAGINATED_API}?page-number=1&page-size=10&requestId=100`
      )
      .then((res) => {
        setRoomAllList(res?.data?.tour_accommodations);
      })
      .catch((err) => console.log(err));
  };

  const getRoomCategoryList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_ROOM_CATEGORY_GET_PAGINATED_API}?page-number=1&page-size=10&requestId=100`
      )
      .then((res) => {
        setRoomCategoryList(res?.data?.tour_room_categories);
      })
      .catch((err) => console.log(err));
  };

  const getRoomTypeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_ROOM_TYPE_GET_PAGINATED_API}?page-number=1&page-size=10&requestId=100`
      )
      .then((res) => {
        setRoomTypeList(res?.data?.tour_room_types);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoomCategoryList();
    getRoomTypeList();
    getAllPackageList();
  }, []);

  const [accommondationPackageList, setAccomodationPackageList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [accomodationPackage, setAccomodationPackage] = useState({
    night_number: 0,
    accommodation_id: 0,
    room_category_id: 0,
    room_type_id: 0,
    is_shareable: false,
    number_of_beds: "",
    bed_configuration: "",
    suitable_for_persons: "",
    per_night_room_price: "",
  });

  // {
  //   "night_number": 1,
  //   "accommodation_id": 1,
  //   "room_category_id": 1,
  //   "room_type_id": 1,
  //   "per_night_room_price": 1200,
  //   "suitable_for_persons": 2,
  //   "is_shareable": true,
  //   "number_of_beds": 1,
  //   "bed_configuration": "Queen"
  // },

  // const copyNightData = (ind) => {
  //   let newAcc = accommondationPackageList?.reduce((acc, packages) => {
  //     if (packages?.night_number === ind) {
  //       acc = packages;
  //     }
  //     return acc;
  //   }, {});
  const [buttonActive, setButtonActive] = useState(false);

  const copyNightData = (ind) => {
    let newAcc = accommondationPackageList?.reduce((acc, packages) => {
      if (packages.night_number === ind) {
        acc = packages;
      }
      return acc;
    }, {});
    setButtonActive(!buttonActive);

    setAccomodationPackage({
      night_number: newAcc.night_number,
      accommodation_id: newAcc.accommodation_id,
      room_category_id: newAcc.room_category_id,
      room_type_id: newAcc.room_type_id,
      is_shareable: newAcc.is_shareable,
      number_of_beds: newAcc.number_of_beds,
      bed_configuration: newAcc.bed_configuration,
      suitable_for_persons: newAcc.suitable_for_persons,
      per_night_room_price: newAcc.per_night_room_price,
    });
  };
  const [updateIndex, setUpdateIndex] = useState(null);
  const [updateSubmitButtonCondition, setUpdateSubmitButtonCondition] =
    useState(false);
  const handleEdit = (packageList, ind) => {
    setAccomodationPackageList(packageList);

    setPackageSelect(1);
    setViewPage(null);
    setUpdateSubmitButtonCondition(true);
    setUpdateIndex(ind);
    setNight(null);
  };

  const is_shareableArary = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const accomodationNameArray = transformArray(
    roomAllList,
    "tour_accommodation_id",
    "tour_accommodation_name"
  );
  const accomodationCategoryArray = transformArray(
    roomCategoryList,
    "tour_room_category_id",
    "tour_room_category_name"
  );
  const accomodationRoomTypeArray = transformArray(
    roomTypeList,
    "tour_room_type_id",
    "tour_room_type_name"
  );

  const number_of_bedsArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];
  const suitable_for_personsArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  const package_is_defaultArray = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const handleAddButtonClick = () => {
    if (
      accomodationPackage?.accommodation_id?.label !== undefined &&
      accomodationPackage?.room_type_id?.label !== undefined &&
      accomodationPackage?.room_category_id?.label !== undefined &&
      accomodationPackage?.number_of_beds > 0 &&
      accomodationPackage?.bed_configuration !== "" &&
      accomodationPackage?.suitable_for_persons > 0 &&
      accomodationPackage?.per_night_room_price > 0
    ) {
      // setMarchantCreateDatas((prevPackage) => {
      //   const index = ind;

      //   const updatedAccommodationOptions = [
      //     ...prevPackage.tour_package_accommodation_options,
      //   ];

      //   if (updatedAccommodationOptions[index]) {
      //     const updatedAccommodationsList = [
      //       ...updatedAccommodationOptions[index]
      //         .tour_package_accommodations_package_list,
      //       {
      //         night_number: night,
      //         accommodation_id: accomodationPackage.accommodation_id,
      //         room_category_id: accomodationPackage.room_category_id,
      //         room_type_id: accomodationPackage.room_type_id,
      //         suitable_for_persons: accomodationPackage.suitable_for_persons,
      //         is_shareable: accomodationPackage.is_shareable,
      //         number_of_beds: accomodationPackage.number_of_beds,
      //         bed_configuration: accomodationPackage.bed_configuration,
      //         per_night_room_price:
      //           accomodationPackage.per_night_room_price,
      //         accommodation_package_quantity:
      //           accomodationPackage.accommodation_package_quantity,
      //         accommodation_package_total_price:
      //           accomodationPackage.accommodation_package_total_price,
      //         accommodation_package_is_default:
      //           accomodationPackage.accommodation_package_is_default,
      //       },
      //     ];
      //     updatedAccommodationOptions[index] = {
      //       ...updatedAccommodationOptions[index],
      //       tour_package_accommodations_package_list: updatedAccommodationsList,
      //     };
      //   } else {
      //     updatedAccommodationOptions.push({
      //       tour_package_accommodations_package_list: [
      //         {
      //           night_number: night,
      //           accommodation_id: accomodationPackage.accommodation_id,
      //           room_category_id: accomodationPackage.room_category_id,
      //           room_type_id: accomodationPackage.room_type_id,
      //           suitable_for_persons: accomodationPackage.suitable_for_persons,
      //           is_shareable: accomodationPackage.is_shareable,
      //           number_of_beds: accomodationPackage.number_of_beds,
      //           bed_configuration: accomodationPackage.bed_configuration,
      //           per_night_room_price:
      //             accomodationPackage.per_night_room_price,
      //           accommodation_package_quantity:
      //             accomodationPackage.accommodation_package_quantity,
      //           accommodation_package_total_price:
      //             accomodationPackage.accommodation_package_total_price,
      //           accommodation_package_is_default:
      //             accomodationPackage.accommodation_package_is_default,
      //         },
      //       ],
      //       tour_package_default_accommodation_option: false,
      //     });
      //   }

      //   return {
      //     ...prevPackage,
      //     tour_package_accommodation_options: updatedAccommodationOptions,
      //   };
      // });

      setAccomodationPackageList((prev) => [
        ...prev,
        {
          night_number: night,
          accommodation_id: accomodationPackage.accommodation_id,
          room_category_id: accomodationPackage.room_category_id,
          room_type_id: accomodationPackage.room_type_id,

          suitable_for_persons: accomodationPackage.suitable_for_persons,
          is_shareable: accomodationPackage.is_shareable,
          number_of_beds: accomodationPackage.number_of_beds,
          bed_configuration: accomodationPackage.bed_configuration,
          per_night_room_price: accomodationPackage.per_night_room_price,
        },
      ]);

      // setAccomodationPackageList((prev) => {
      //   const isDuplicate = prev.some((item) => {
      //     console.log("item", item, accomodationPackage);
      //     return (
      //       item.accommodation_id === accomodationPackage.accommodation_id &&
      //       item.room_category_id === accomodationPackage.room_category_id &&
      //       item.room_type_id === accomodationPackage.room_type_id &&
      //       item.suitable_for_persons ===
      //         accomodationPackage.suitable_for_persons &&
      //       item.is_shareable === accomodationPackage.is_shareable &&
      //       item.number_of_beds === accomodationPackage.number_of_beds &&
      //       item.bed_configuration === accomodationPackage.bed_configuration &&
      //       item.per_night_room_price ===
      //         accomodationPackage.per_night_room_price &&
      //       item.accommodation_package_quantity ===
      //         accomodationPackage.accommodation_package_quantity &&
      //       item.accommodation_package_total_price ===
      //         accomodationPackage.accommodation_package_total_price &&
      //       item.accommodation_package_is_default ===
      //         accomodationPackage.accommodation_package_is_default
      //     );
      //   });

      //   if (isDuplicate) {
      //     return prev.map((item) => {
      //       if (
      //         item.accommodation_id === accomodationPackage.accommodation_id &&
      //         item.room_category_id === accomodationPackage.room_category_id &&
      //         item.room_type_id === accomodationPackage.room_type_id &&
      //         item.suitable_for_persons ===
      //           accomodationPackage.suitable_for_persons &&
      //         item.is_shareable === accomodationPackage.is_shareable &&
      //         item.number_of_beds === accomodationPackage.number_of_beds &&
      //         item.bed_configuration ===
      //           accomodationPackage.bed_configuration &&
      //         item.per_night_room_price ===
      //           accomodationPackage.per_night_room_price &&
      //         item.accommodation_package_quantity ===
      //           accomodationPackage.accommodation_package_quantity &&
      //         item.accommodation_package_total_price ===
      //           accomodationPackage.accommodation_package_total_price &&
      //         item.accommodation_package_is_default ===
      //           accomodationPackage.accommodation_package_is_default
      //       ) {
      //         return {
      //           ...item,
      //           night_number: [...item.night_number, night],
      //         };
      //       }
      //       return item;
      //     });
      //   } else {
      //     return [
      //       ...prev,
      //       {
      //         night_number: [night],
      //         accommodation_id: accomodationPackage.accommodation_id,
      //         room_category_id: accomodationPackage.room_category_id,
      //         room_type_id: accomodationPackage.room_type_id,
      //         suitable_for_persons: accomodationPackage.suitable_for_persons,
      //         is_shareable: accomodationPackage.is_shareable,
      //         number_of_beds: accomodationPackage.number_of_beds,
      //         bed_configuration: accomodationPackage.bed_configuration,
      //         per_night_room_price:
      //           accomodationPackage.per_night_room_price,
      //         accommodation_package_quantity:
      //           accomodationPackage.accommodation_package_quantity,
      //         accommodation_package_total_price:
      //           accomodationPackage.accommodation_package_total_price,
      //         accommodation_package_is_default:
      //           accomodationPackage.accommodation_package_is_default,
      //       },
      //     ];
      //   }
      // });
      // setMarchantCreateData((prevPackage) => ({
      //   ...prevPackage,
      //   tour_package: {
      //     ...prevPackage.tour_package,
      //     tour_package_accommodation_packages: [
      //       ...prevPackage.tour_package.tour_package_accommodation_packages,
      //       accomodationPackage,
      //     ],
      //   },
      // }));
      setNight(night + 1);
      setAccomodationPackage({
        accommodation_id: 0,
        room_category_id: 0,
        room_type_id: 0,
        is_shareable: true,
        number_of_beds: "",
        bed_configuration: "",
        suitable_for_persons: "",
        per_night_room_price: "",
      });
    }
  };

  const handleUpdateButton = () => {
    if (
      accomodationPackage?.accommodation_id?.label !== undefined &&
      accomodationPackage?.room_type_id?.label !== undefined &&
      accomodationPackage?.room_category_id?.label !== undefined &&
      accomodationPackage?.number_of_beds > 0 &&
      accomodationPackage?.bed_configuration !== "" &&
      accomodationPackage?.suitable_for_persons > 0 &&
      accomodationPackage?.per_night_room_price > 0
    ) {
      setAccomodationPackageList((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (item?.night_number === currentIndex) {
            return accomodationPackage;
          }
          return item;
        });

        return updatedArray;
      });
      setNight(accommondationPackageList?.length + 1);
      setUpdateButtonEnable(false);
      setAccomodationPackage({
        accommodation_id: 0,
        room_category_id: 0,
        room_type_id: 0,
        is_shareable: true,
        number_of_beds: "",
        bed_configuration: "",
        suitable_for_persons: "",
        per_night_room_price: "",
      });
    }
  };

  const handleSubmitAccommondation = () => {
    // setPackageSelect(null);
    // setMarchantCreateDatas((prevPackage) => {
    //   const updatedAccommodationOptions =
    //     prevPackage.tour_package_accommodation_options.map(
    //       (accommodationOption, index) => {
    //         if (index === 0) {
    //           return {
    //             ...accommodationOption,
    //             tour_package_accommodations_package_list: [
    //               ...accommodationOption.tour_package_accommodations_package_list,
    //               accommondationPackageList,
    //             ],
    //           };
    //         }
    //         return accommodationOption;
    //       }
    //     );

    //   return {
    //     ...prevPackage,
    //     tour_package_accommodation_options: updatedAccommodationOptions,
    //   };
    // });

    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions = [
        ...prevPackage.tour_package_accommodation_options,
        {
          tour_package_accommodations_package_list: accommondationPackageList,

          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
        },
      ];

      return {
        ...prevPackage,
        tour_package_accommodation_options: updatedAccommodationOptions,
      };
    });

    setAccomodationPackageList([]);
    setAccomodationPackage({
      accommodation_id: 0,
      room_category_id: 0,
      room_type_id: 0,
      is_shareable: true,
      number_of_beds: "",
      bed_configuration: "",
      suitable_for_persons: "",
      per_night_room_price: "",
    });
    setNight(1);
    setViewPage(1);
  };

  const handleSubmitAccommondationUpdate = () => {
    setMarchantCreateDatas((prevPackage) => {
      const { tour_package_accommodation_options } = prevPackage;

      const indexToUpdate = updateIndex;

      const updatedAccommodationOptions = [
        ...tour_package_accommodation_options,
      ];

      updatedAccommodationOptions[indexToUpdate] = {
        ...updatedAccommodationOptions[indexToUpdate],
        tour_package_accommodations_package_list: accommondationPackageList,
      };

      return {
        ...prevPackage,
        tour_package_accommodation_options: updatedAccommodationOptions,
      };
    });
    setAccomodationPackageList([]);
    setAccomodationPackage({
      accommodation_id: 0,
      room_category_id: 0,
      room_type_id: 0,
      is_shareable: true,
      number_of_beds: "",
      bed_configuration: "",
      suitable_for_persons: "",
      per_night_room_price: "",
    });
    setNight(1);
    setViewPage(1);
  };

  // const newAccommondation = accommondationPackageList
  //   ?.map((item, index) => {
  //     return item.night_number.map((nightNumber) => ({
  //       ...item,
  //       night_number: nightNumber,
  //     }));
  //   })
  //   .flat();

  const handleRemoveAccommondation = (ind, nightNumber) => {
    const newArray = accommondationPackageList?.filter(
      (meal, index) => index !== ind
    );
    // setMarchantCreateDatas((prevPackage) => {
    //   const updatedAccommodationOptions = [
    //     {
    //       tour_package_accommodations_package_list: newArray,
    //       tour_package_default_accommodation_option: false,
    //     },
    //   ];

    //   return {
    //     ...prevPackage,
    //     tour_package_accommodation_options: updatedAccommodationOptions,
    //   };
    // });
    setAccomodationPackageList(newArray);
    setNight(nightNumber);
  };

  const handleEditAccommondation = (ind) => {
    // const newArray = accommondationPackageList?.filter(
    //   (meal, index) => index === ind
    // );

    let newArray = accommondationPackageList?.find(
      (packages) => packages?.night_number === ind
    );

    setAccomodationPackage({
      night_number: newArray?.night_number,
      accommodation_id: newArray?.accommodation_id,
      room_category_id: newArray.room_category_id,
      room_type_id: newArray.room_category_id,
      is_shareable: newArray.is_shareable,
      number_of_beds: newArray.number_of_beds,
      bed_configuration: newArray.bed_configuration,
      suitable_for_persons: newArray.suitable_for_persons,
      per_night_room_price: newArray.per_night_room_price,
    });
    setNight(newArray?.night_number);
    setCurrentIndex(ind);
    setUpdateButtonEnable(true);
  };

  return (
    <>
      {viewPage === null ? (
        <>
          <p className="text-btn-secondary text-center mb-[24px] xl:text-[20px] md:text-[18px] text-[16px]">
            Accomodation
          </p>

          <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton(null)}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>

          <div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-[70%] w-full  rounded p-[16px] bg-bg-white h-full">
                <div className="bg-[#e2e8f0] rounded-[4px] relative py-[4px]">
                  <div className="w-full p-[12px]   text-btn-primary font-bold flex gap-x-[12px]">
                    <p>For Night: </p>
                    {nightArray?.map((nght, index) => (
                      <p
                        key={index}
                        className={` ${
                          nght === night
                            ? "bg-btn-secondary text-bg-primary"
                            : "bg-bg-semiwhite text-txt-primary"
                        }  py-[3px] px-[12px] rounded-[4px] cursor-pointer`}
                      >
                        Night {nght}
                      </p>
                    ))}

                    {/* {accommondationPackageList?.lengh > 0 && ( */}

                    {/* )} */}
                  </div>
                  <div className="text-[16px] flex justify-end px-[30px]  gap-x-[10px] w-full">
                    <div className="flex  gap-x-[10px]">
                      {accommondationPackageList?.map((pack, index) => (
                        <button
                          onClick={() => copyNightData(index + 1)}
                          className="flex justify-start gap-x-[6px] items-center cursor-pointer bg-btn-primary px-[4px] rounded-[4px] text-bg-primary"
                        >
                          {/* <div className="w-[20px] h-[20px] rounded-full border border-btn-secondary flex justify-center items-center">
                            {buttonActive ? (
                              <div className="bg-btn-primary w-[15px] h-[15px] rounded-full "></div>
                            ) : null}
                          </div> */}
                          <p
                            key={index}
                            className=" cursor-pointer flex justify-center items-center rounded-[4px]"
                          >
                            Copy night : {index + 1}
                          </p>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-[16px] mb-[8px]">
                  <div>
                    <p className="text-btn-secondary">Accomodation Name: </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={accomodationPackage?.accommodation_id || ""}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          accommodation_id: data,
                        }))
                      }
                      isClearable
                      components={animatedComponents}
                      options={accomodationNameArray}
                      placeholder={"Select Hotel: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-between">
                  <div className="mt-[8px] mb-[8px] w-full">
                    <div>
                      <p className="text-btn-secondary">Room Type(s): </p>
                    </div>
                    <div className="rounded">
                      <Select
                        value={accomodationPackage?.room_type_id}
                        onChange={(data) =>
                          setAccomodationPackage((prev) => ({
                            ...prev,
                            room_type_id: data,
                          }))
                        }
                        components={animatedComponents}
                        options={accomodationRoomTypeArray}
                        placeholder={"Select Type: "}
                        styles={colorStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-[8px]">
                  <div>
                    <p className="text-btn-secondary">Room Category(ies): </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={accomodationPackage?.room_category_id}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          room_category_id: data,
                        }))
                      }
                      components={animatedComponents}
                      options={accomodationCategoryArray}
                      placeholder={"Select Category: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Is Shareble: </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={accomodationPackage?.is_shareable}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          is_shareable: data,
                        }))
                      }
                      components={animatedComponents}
                      options={is_shareableArary}
                      placeholder={"Select: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Number of Beds: </p>
                  </div>
                  <div className="rounded">
                    <div className="mt-[10px]">
                      <input
                        onChange={(e) =>
                          setAccomodationPackage((prev) => ({
                            ...prev,
                            number_of_beds: e.target.value,
                          }))
                        }
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        type="text"
                        placeholder="number of beds "
                        value={accomodationPackage?.number_of_beds}
                      />
                    </div>
                    {/* <Select
                      value={accomodationPackage?.number_of_beds}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          number_of_beds: data,
                        }))
                      }
                      components={animatedComponents}
                      options={number_of_bedsArray}
                      placeholder={"Select Bed: "}
                      styles={colorStyles}
                    /> */}
                  </div>
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Bed Configuration: </p>
                  <input
                    className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                    placeholder="Example: Queen"
                    type="text"
                    onChange={(e) =>
                      setAccomodationPackage((prev) => ({
                        ...prev,
                        bed_configuration: e.target.value,
                      }))
                    }
                    value={accomodationPackage?.bed_configuration}
                  />
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Suitable for Persons: </p>
                  </div>
                  <div className="rounded">
                    <div className="mt-[10px]">
                      <input
                        onChange={(e) =>
                          setAccomodationPackage((prev) => ({
                            ...prev,
                            suitable_for_persons: e.target.value,
                          }))
                        }
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        type="text"
                        placeholder="Suitable for persons "
                        value={accomodationPackage?.suitable_for_persons}
                      />
                    </div>
                    {/* <Select
                      value={accomodationPackage?.suitable_for_persons}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          suitable_for_persons: data,
                        }))
                      }
                      components={animatedComponents}
                      options={suitable_for_personsArray}
                      placeholder={"Select persons: "}
                      styles={colorStyles}
                    /> */}
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Set Price: </p>
                  </div>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      type="number"
                      onChange={(e) => {
                        const inputVal = e.target.value;

                        if (!isNaN(inputVal)) {
                          setAccomodationPackage((prev) => ({
                            ...prev,
                            per_night_room_price: inputVal,
                          }));
                        }
                      }}
                      placeholder="Unit per price "
                      value={accomodationPackage?.per_night_room_price}
                    />
                  </div>
                </div>

                <div className="mt-[32px] mb-[16px]">
                  {updateButtonEnable ? (
                    <TourMerchantButton
                      name={"Update"}
                      width={"w-full"}
                      onClick={() => handleUpdateButton()}
                    ></TourMerchantButton>
                  ) : (
                    <>
                      {getTours?.number_of_nights !==
                        accommondationPackageList?.length && (
                        <TourMerchantButton
                          name={"Add"}
                          width={"w-full"}
                          onClick={() => handleAddButtonClick()}
                        ></TourMerchantButton>
                      )}
                    </>
                  )}
                </div>
                {/* {night === 4 &&
              marchantTourCreateData?.tour_package
                ?.tour_package_accommodation_packages?.length >= 1 && (
                <div className="">
                  <TourMerchantButton
                    onClick={() => handleSubmitAccommondation()}
                    name={"Submit"}
                    width={"w-full"}
                  ></TourMerchantButton>
                </div>
              )} */}
              </div>
              <div className="md:w-[30%] w-full bg-[#e5e7eb] md:pl-[16px] mt-[16px] md:mt-0">
                {accommondationPackageList?.length === 0 && (
                  <div className="bg-bg-white p-[16px] w-full rounded text-btn-secondary text-center">
                    No Item Selected
                  </div>
                )}

                {/* Right-Side Nav */}
                <div className="max-h-[1000px] overflow-y-scroll mb-[20px]">
                  {accommondationPackageList?.map((item, index) => (
                    <div
                      key={index}
                      className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded overflow-clip relative"
                    >
                      <div
                        className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[15px] left-[-47px] rotate-[-45deg] `}
                      >
                        <p className="text-[14px] font-extrabold uppercase ">
                          Night : {item.night_number}
                        </p>
                      </div>
                      <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                        {item?.accommodation_id?.label} -{" "}
                        {item?.room_category_id?.label}
                      </p>

                      <div className="xl:text-[14px] text-[12px]">
                        <p className="text-txt-primary">
                          {" "}
                          <span className="text-bg-semiblack">
                            Room Type:
                          </span>{" "}
                          {item?.room_type_id?.label}{" "}
                        </p>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">
                            Bed Configuration:{" "}
                          </span>
                          {item?.number_of_beds} {item?.bed_configuration}{" "}
                          suitable for {item?.suitable_for_persons} persons{" "}
                        </p>
                        <div className="flex justify-between">
                          <p className="text-txt-primary">
                            <span className="text-bg-semiblack">
                              Shareable:{" "}
                            </span>
                            {item?.is_shareable?.label}
                          </p>
                        </div>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">
                            Price per quantity:{" "}
                          </span>
                          {item?.per_night_room_price}৳
                        </p>
                      </div>

                      {/* <span
                        onClick={() =>
                          handleRemoveAccommondation(index, item?.night_number)
                        }
                        className="absolute right-[2px] cursor-pointer top-[2px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[25px] h-[25px] text-btn-pink"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span> */}
                      <span
                        onClick={() =>
                          handleEditAccommondation(item?.night_number)
                        }
                        className="absolute right-[4px] cursor-pointer top-[2px] border-[1.5px] rounded-full border-btn-primary p-[4px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[15px] h-[15px] text-btn-primary"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
                {accommondationPackageList?.length >= 1 && (
                  <>
                    {updateSubmitButtonCondition ? (
                      <div className="">
                        <TourMerchantButton
                          onClick={() => handleSubmitAccommondationUpdate()}
                          name={"Update"}
                          width={"w-full"}
                        ></TourMerchantButton>
                      </div>
                    ) : (
                      <div className="">
                        {getTours?.number_of_nights ===
                          accommondationPackageList?.length && (
                          <TourMerchantButton
                            onClick={() => handleSubmitAccommondation()}
                            name={"Submit"}
                            width={"w-full"}
                          ></TourMerchantButton>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <AccommondationViewSelected
          setPackageSelect={setPackageSelect}
          setViewPage={setViewPage}
          marchantTourCreateDatas={marchantTourCreateDatas}
          setMarchantCreateDatas={setMarchantCreateDatas}
          handleEdit={handleEdit}
          setUpdateSubmitButtonCondition={setUpdateSubmitButtonCondition}
        />
      )}
    </>
  );
};

export default AccomodationPackage;
