import React from "react";
import { customValidationSingle } from "../../../utils/customValidation";

function SingleSelectObjectReturn({
  item,
  setSelectedValue,
  selectedValue,
  displayField,
  setVisible,
  selectedFieldName,
  // setDisplay,
  setSelectedObject,
  validation = false,
  name,
  image = false,
  setTemp,
}) {
  const handleSelect = () => {
    setSelectedValue(item);
    if (setTemp) {
      setTemp(item?.title);
    }
    //setTemp('');
    console.log(item?.title, "item");
    setSelectedObject(item);
    // setDisplay(item[displayField]);
    setVisible(false);
    if (validation) {
      setTimeout(() => {
        customValidationSingle(name);
      }, 200);
    }
  };
  return (
    <div
      className="py-2 hover:bg-btn-secondary hover:text-txt-secondary cursor-pointer px-4 flex gap-2 items-center"
      onClick={handleSelect}
    >
      {image && (
        <img src={item.url} alt={displayField} width="50px" height={"50px"} />
      )}
      {displayField ? item[displayField] : item.label}
    </div>
  );
}

export default SingleSelectObjectReturn;
