import React, { useEffect, useState } from "react";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  colorStylesTour,
} from "../../Utilities/SelectAnimation";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { convertStringToObject } from "../ParentPackageSelected";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";
import ModalCommon from "../../../components/common/ModalCommon";
import { useDispatch } from "react-redux";
import { setTourStepperValue } from "../../../store/marchantDashboard/categoryTour/tourSetupStepper";

export default function SpotFeeV2({
  editAblePackage,
  setIsLoading,
  changeSelectedNewItemList,
}) {
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const navigate = useNavigate();
  const [getTours, setGetTours] = useState();
  const [spotEntry, setSpotEntry] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.pathname);
  const subscribeId = parseInt(queryParams.get("subscribeId"));
  const [toggleModal, setToggleModal] = useState(false);


  const dispatch = useDispatch();
  const handleSetTourStepperValue = (value) => {
    dispatch(setTourStepperValue(value));
  };

  const [spotEntity, setSpotEntity] = useState({
    activity_id: "",
    remark: "FOR_ADULT",
    spot_entry_price_per_person: "",
  });
  const activityVariable = getTours?.tour_itinerary?.map((activity) => ({
    label: activity?.tour_activity_name,
    id: activity?.tour_activity_id,
  }));
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  function transformPayload(payload) {
    const newPayload = {
      activity_id: payload?.activity_id.id || payload?.activity_id?.value,
      remark: payload?.remark,

      spot_entry_price_per_person: parseInt(
        payload?.spot_entry_price_per_person
      ),
    };

    return newPayload;
  }

  const handleSubmitSpotFee = () => {
    const newPayload = transformPayload(spotEntity);
    setIsLoading(true);
    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_SPOTFEE_PACKAGE}`, {
        requestId: "spotFee-add",
        subscribed_tour_id: convertData?.subscribeId,
        tour_spot_entry_package: newPayload,
      })
      .then((res) => {
        toast.success("Spot fee Add");
        getSpotEntriy();
        setSpotEntity({
          activity_id: "",
          remark: "FOR_ADULT",
          spot_entry_price_per_person: 0,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSpotEntriy = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setSpotEntry(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSpotEntriy();
  }, []);

  const transformObject = (originalObject) => {
    const b = {
      activity_id: {
        label: originalObject?.activity?.activity_name,
        value: originalObject?.activity?.activity_id,
      },
      remark: originalObject?.remark,
      spot_entry_price_per_person:
        originalObject?.spot_entry_price_per_person?.toString(),
    };

    return b;
  };


  const handleEditSpot = (spot) => {
    const editSpot = transformObject(spot);
    setSpotEntity(editSpot);
  };

  useEffect(() => {
    if (
      editAblePackage?.hasOwnProperty("spot_entry_package_id") &&
      editAblePackage?.hasOwnProperty("remark") &&
      editAblePackage?.hasOwnProperty("spot_entry_price_per_person")
    ) {
      const editFood = transformObject(editAblePackage);
      setSpotEntity(editFood);
    }
  }, [editAblePackage]);

  const navigateToOtherPage = () => {
    handleSetTourStepperValue(2)
    navigate(
      `/dashboard/tour-availability/tour_id=${getTours?.tour_id}&subscribeId=${subscribeId}&number_of_days=${getTours?.number_of_days}&number_of_nights=${getTours?.number_of_nights}`,
      {
        state: getTours,
      }
    );
  };


  return (
    <div className="py-[16px] pl-[16px]">
      {/* <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Spot Add</h2>
      </div> */}
      <div>
        <div className="">
          <div className=" w-full">
            <div className="flex flex-col gap-[8px]">
              <div className="flex flex-col gap-[4px]">
                <p className="text-tour-secondary text-[12px] font-normal">
                  Activity Name:{" "}
                </p>

                <div className="">
                  <Select
                    value={spotEntity?.activity_id}
                    onChange={(activity) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        activity_id: activity,
                      }))
                    }
                    components={animatedComponents}
                    options={activityVariable}
                    placeholder={"Activity Name "}
                    styles={colorStylesTour}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[4px]">
                <p className="text-tour-secondary text-[12px] font-normal">
                  Spot fee :
                </p>
                <div className="relative">
                  <input
                    type="number"
                    className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    value={spotEntity.spot_entry_price_per_person}
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder="entry fee"
                  />
                  <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
                    BDT
                  </span>
                </div>
              </div>
              {/* <div className="flex flex-col gap-[4px]">
                <p className="text-tour-secondary text-[12px] font-normal">Remark :</p>
                <div>
                  <input
                    type="text"
                    className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    //   value={spotEntity.spot_entry_price_per_person}
                    placeholder="Remark"
                  />
                </div>
              </div> */}
              {/* <button
              onClick={() => handleSubmitSpotFee()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button> */}
              {/* <button
              // disabled={
              //   foodPackage?.food_items_ids?.length < 1 &&
              //   foodPackage?.meal_package_unit_price !== Object &&
              //   foodPackage?.meal_type_id !== Object
              // }
              onClick={() => handleSubmitSpotFee()}
              className="w-full mt-[24px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Add
            </button> */}
              <div className="w-full flex gap-[16px] pt-[24px]">
                <button
                  // disabled={guidePackageList?.length === 0}
                  
                  onClick={() => setToggleModal(true)}
                  className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                >
                  Save & Next
                </button>
                <button
                  disabled={
                    !spotEntity?.activity_id?.hasOwnProperty("label") ||
                    spotEntity?.spot_entry_price_per_person?.length === 0 ||
                    spotEntity?.spot_entry_price_per_person === undefined
                  }
                  onClick={() => handleSubmitSpotFee()}
                  className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                >
                  Add more
                </button>
              </div>
            </div>
          </div>
          {/* view */}
          {/* <div className="px-[12px] flex flex-col justify-start gap-y-[12px] w-full">
            {spotEntry?.map((spot, index) => (
              <div
                key={index}
                className="shadow-customShadow py-[10px] px-[20px] rounded-[4px] relative"
              >
                <p className="text-[22px]">
                  <span className="text-[16px]"> Spot :</span>{" "}
                  {spot?.activity?.activity_name}
                </p>
                <p>Location: {spot?.activity?.activity_short_location}</p>
                <p>Fee : {spot?.spot_entry_price_per_person}</p>
                <span
                  onClick={() => handleEditSpot(spot)}
                  className="border cursor-pointer w-[20px] h-[20px] border-btn-orange rounded-full absolute right-[-10px] top-[-4px]"
                >
                  <span className="flex justify-center items-center h-full">
                    X
                  </span>
                </span>
              </div>
            ))}

            {spotEntry?.length > 0 && (
              <button
                onClick={() => navigate(-1)}
                className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
              >
                Save
              </button>
            )}
          </div> */}
        </div>
      </div>

      <div>
          <ModalCommon
            shown={toggleModal}
            width="max-w-[280px]"
            height="min-h-[150px]"
            outSideOff={false}
            close={() => {
              setToggleModal(false);
            }}
          >
            <div className="p-[24px]">
              <p className="text-btn-primary">
                Are you sure you want to set price availability?
              </p>
              <div className="flex justify-between">
                <button
                  onClick={() => setToggleModal(false)}
                  className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                >
                  CANCEL
                </button>
                <button
                  onClick={() => navigateToOtherPage()}
                  className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </ModalCommon>
        </div>
    </div>
  );
}
