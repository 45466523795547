import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourPackageAdd() {
  const [modalShown, setToggleModal] = useState(false);
  const [getAllPackageType, setGetAllPackageType] = useState();
  const [tourPackage, setTourPackage] = useState({
    suitable_for_persons: "",
    tour_package_name: "",
    tour_package_type_description: "",
  });
  const [tourPackages, setTourPackages] = useState([]);
  const handlePackage = () => {
    setTourPackages([...tourPackages, tourPackage]);
    setTourPackage({
      suitable_for_persons: "",
      tour_package_name: "",
      tour_package_type_description: "",
    });
    setToggleModal(false);
  };
  console.log("getAllPackageType", getAllPackageType);
  const getPackageType = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_TOUR_PACKAGE_TYPE_API}`)
      .then((response) => {
        setGetAllPackageType(response?.data?.tour_package_types);
      });
  };
  useEffect(() => {
    getPackageType();
  }, []);
  const convertedData = tourPackages?.map((item) => ({
    ...item,
    suitable_for_persons: parseInt(item.suitable_for_persons, 10),
  }));
  const handlePackageAdd = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_PACKAGE_POST_API}`, {
        tour_package_types: convertedData,
        requestId: "string",
      })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Successfully Added tour package type");
          getPackageType();
          setTourPackage({
            suitable_for_persons: "",
            tour_package_name: "",
            tour_package_type_description: "",
          });
          setTourPackages([]);
        }
      });
  };

  return (
    <div className="mt-[20px]">
      <div className="relative ">
        <div className="mt-[30px]">
          <p className="text-btn-primary">Tour Added Package</p>
          <div className="mt-[20px] flex flex-col gap-y-[10px] max-w-[450px] max-h-[550px] overflow-y-scroll p-[12px]">
            {getAllPackageType?.map((tour, index) => (
              <div key={index} className="border rounded-[12px]  p-[10px]">
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Person :</span>{" "}
                  {tour?.tour_package_type_suitable_for}
                </p>
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Package Name:</span>{" "}
                  {tour?.tour_package_type_name}
                </p>
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Package Des: </span>
                  {tour?.tour_package_type_description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="right-[40px] w-full max-w-[450px] absolute top-[10px] mx-auto shadow-customShadow rounded-[8px] p-[12px]">
          <h2 className="text-[22px] text-center underline">
            Tour Package Type
          </h2>

          <div className="">
            <div className="mt-[20px] flex flex-col gap-y-[10px] ">
              {tourPackages?.map((tour, index) => (
                <div key={index} className="border rounded-[12px]  p-[10px]">
                  <p className="text-[14px]">
                    {" "}
                    <span className="text-[12px]">Person :</span>{" "}
                    {tour?.suitable_for_persons}
                  </p>
                  <p className="text-[14px]">
                    {" "}
                    <span className="text-[12px]">Package Name:</span>{" "}
                    {tour?.tour_package_name}
                  </p>
                  <p className="text-[14px]">
                    {" "}
                    <span className="text-[12px]">Package Des: </span>
                    {tour?.tour_package_type_description}
                  </p>
                </div>
              ))}
            </div>

            <div className="w-full flex justify-end mt-[10px]">
              <button
                className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
                  tourPackages?.length > 0 ? "w-[150px] " : "w-full"
                }`}
                onClick={() => setToggleModal(true)}
              >
                Add New
              </button>
            </div>
            {tourPackages?.length > 0 && (
              <button
                onClick={() => handlePackageAdd()}
                className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white "
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        outSideOff={true}
        height="min-h-[200px]"
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourPackage((prev) => ({
                    ...prev,
                    tour_package_name: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={tourPackage.tour_package_name}
              />
              <span className="placeholder">Tour Package Name:</span>
            </label>
          </div>
          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setTourPackage((prev) => ({
                    ...prev,
                    suitable_for_persons: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={tourPackage.suitable_for_persons}
              />
              <span className="placeholder">Suitable Persons:</span>
            </label>
          </div>

          <div className="w-full mt-[10px]">
            <label className="custom-field one w-full">
              <textarea
                onChange={(e) =>
                  setTourPackage((prev) => ({
                    ...prev,
                    tour_package_type_description: e.target.value,
                  }))
                }
                className="input w-full min-h-[150px] max-h-[200px]"
                type="text"
                placeholder=" "
                value={tourPackage.tour_package_type_description}
              ></textarea>
              <span className="placeholder">Tour Package Description :</span>
            </label>
          </div>
          <button
            onClick={() => handlePackage()}
            disabled={
              tourPackage?.suitable_for_persons?.length === 0 ||
              tourPackage?.tour_package_name?.length === 0 ||
              tourPackage?.tour_package_type_description?.length === 0
            }
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white disabled:bg-bg-semiblack"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
