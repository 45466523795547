import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  AIRTICKET_CONTROLLER_API,
  apiEndPoint,
  baseUrl,
} from "../../contants/Endpoints";
import Paginations from "../../pages/admin/Paginations/Paginations";
import { authAxios } from "../../utils/axiosWrapper";
import SingleSelectNew from "../singleSelect/singleSelectObjectReturn/SingleSelectNew";

const AirlineLogo = () => {
  const [allAirline, setAllAirline] = useState([]);
  const [iconsList, setIconsList] = useState([]);
  const [temp, setTemp] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 10;
  const [formData, setFormData] = useState({
    airlines_code: "",
    country_code: "",
    name: "",
    id: 0,
    icon_id: "",
  });

  // Handle input change for each form field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "airlines_code" || name === "country_code" || name === "name"
          ? value
          : value,
    });
  };
  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data?.iconData);
      })
      .catch((err) => {});
  }, []);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    authAxios
      .post(`${baseUrl}${AIRTICKET_CONTROLLER_API.AIRLINES_LOGO_POST}`, {
        ...formData,
        icon_id: formData.icon_id.id,
        requestId: "airline",
      })
      .then((res) => {
        toast.success("Airline Created successfully!");
        handleGetAllAirlineData();
        setFormData({
          airlines_code: "",
          country_code: "",
          name: "",
          id: 0,
          icon_id: "",
        });
      })
      .catch((err) => {
        toast.error("Airline Add Failed!");
      });
  };
  const handleGetAllAirlineData = () => {
    authAxios
      ?.get(
        `${AIRTICKET_CONTROLLER_API.AIRLINES_LOGO_GET_ALL_API}?requestId=123`
      )
      .then((res) => {
        setAllAirline(res?.data?.entries);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetAllAirlineData();
  }, []);

  return (
    <div className="flex flex-row gap-[60px]  my-[20px]">
      <div className="w-full">
        <form className="w-full" onSubmit={handleSubmit}>
          {Object.keys(formData)
            ?.filter((key) => key !== "icon_id" && key !== "id")
            ?.map((key) => (
              <div
                className="w-full"
                key={key}
                style={{ marginBottom: "10px" }}
              >
                <label style={{ display: "block", marginBottom: "5px" }}>
                  {key.replace(/_/g, " ").toUpperCase()}
                </label>

                <input
                  className="border border-bg-semiblack p-[10px] rounded-[8px] w-full"
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
          <div className=" pt-[10px]">
            <p className="pb-[5px]">Airline Icon:</p>

            <div className="flex justify-start items-center gap-x-[8px]">
              <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                <img
                  className="w-[40px] h-[40px]"
                  src={formData?.icon_id?.url}
                  alt=""
                />
              </div>

              <SingleSelectNew
                warningView={false}
                data={iconsList}
                displayField="title"
                placeholder={"Icons"}
                selectedFieldName={"title"}
                selectedValue={formData?.icon_id?.url}
                setSelectedValue={(icon_url) =>
                  setFormData((prev) => ({
                    ...prev,
                    icon_id: icon_url,
                  }))
                }
                setSelectedObject={() => {}}
                image={true}
                isReadOnly={false}
                temp={temp}
                setTemp={setTemp}
              />
            </div>
          </div>
          <button
            className="mt-[10px] bg-btn-secondary hover:bg-btn-primary active:bg-btn-primary py-[12px] w-full rounded-[8px] text-bg-white text-base16"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
      <div className="w-full">
        <table className="table-fixed w-full">
          <thead className="py-2 border-0">
            <tr className="bg-btn-secondary ">
              {Object.keys(formData)?.map((key) => (
                <th
                  className="text-[12px] whitespace-nowrap text-txt-secondary py-4"
                  key={key}
                >
                  {key.replace(/_/g, " ").toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="h-[10px] overflow-y-scroll">
            {allAirline &&
              allAirline?.map((airline, index) => {
                return (
                  <tr
                    onClick={() => setFormData(airline)}
                    key={index}
                    className="border-b last:border-0 border-btn-border  hover:bg-btn-warning cursor-pointer"
                  >
                    <td className="text-[12px] py-4 text-center">
                      {airline?.airlines_code}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airline?.country_code}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airline?.name}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      {airline?.id}
                    </td>
                    <td className="text-[12px] py-4 text-center">
                      <img
                        className="w-[40px] h-[40px]"
                        src={airline?.icon?.url}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="my-10">
          <Paginations
            currentPage={currentPage}
            totalCount={totalPage}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default AirlineLogo;
