import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { DashboardRoutes } from "../contants/Constants";
import { BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import CommonSearch from "./admin/CommonSearch";
import SecondaryNav from "./admin/comp/SecondaryNav";
import Paginations from "./admin/Paginations/Paginations";
import { bcolor, color, tcolor } from "./BookingReviewCart";

export default function SearchByOrderCode() {
  const [bookingData, setBookingData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const location = useLocation();
  const getBooking = () => {
    let url = `${BOOKING_ENDPOINTS.GET_SEARCH_BY_ORDER_CODE_BOOKING}pageNumber=${currentPage}&pageSize=10&queryString=${searchValue}&requestId=df`;

    authAxios
      .get(url)
      .then((res) => {
        setBookingData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  };

  useEffect(() => {
    setLoading(true);
    getBooking();
  }, [currentPage, searchValue]);

  return (
    <div className="">
      {location.pathname === DashboardRoutes.ALL_BOOKINGS ? (
        <SecondaryNav />
      ) : null}

      <div className="mt-[30px] max-w-[1100px] mx-auto">
        {/* filter option */}
        <div className="pt-[20px]">
          <CommonSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Search by order code"}
          />
        </div>
        {loading ? (
          <div className="flex justify-center items-center w-full h-screen">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {/* view table */}
            <div className="mt-[40px] max-w-[1100px]">
              <table
                className={`table-fixed rounded-[8px] border-collapse  w-full`}
              >
                <thead
                  className={`sticky top-[88px] shadow-sm bg-[#48C9B0]  text-bg-primary  text-left`}
                >
                  <tr>
                    <th className="  py-[10px] px-[8px]   w-[350px]">
                      Hotel Info.
                    </th>
                    <th className="px-[8px]  py-[10px]  w-[350px]">
                      User Info.
                    </th>

                    <th className=" px-[8px]  py-[10px]  w-[350px]">
                      Booking Info.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookingData?.rentalBookingReviewData?.map(
                    (booking, index) => (
                      <tr
                        key={index}
                        className={`hover:bg-[#48c9af16] cursor-pointer border-b text-[14px] border-[#eaecef]`}
                      >
                        <td className="py-[8px] px-[28px] gap-x-[12px] ">
                          <div className="flex flex-col gap-y-[8px]">
                            <span className="text-[16px]">
                              {" "}
                              Hotel Name : {booking?.hotelName}
                            </span>
                            <span className="text-[14px]">
                              Booking Time: {booking?.bookingTime}
                            </span>
                          </div>
                        </td>
                        <td className=" py-[10px] px-[8px] ">
                          <div className="flex flex-col items-start justify-start gap-[6px] ">
                            <span>Name : {booking?.userName}</span>
                            <span>Email : {booking?.email}</span>
                            <span>Phone : {booking?.phoneNumber}</span>
                            <span className={`${tcolor[`${booking?.state}`]}`}>
                              Review id : #{booking?.rentalBookingReviewId}
                            </span>

                            <span className={`${tcolor[`${booking?.state}`]}`}>
                              {" "}
                              Order Code : {booking?.orderCode}
                            </span>
                          </div>
                        </td>

                        <td className=" py-[10px] pr-[28px]  ">
                          <div>
                            <h2 className=" mb-[4px] text-txt-hint">
                              Order Summary :{" "}
                            </h2>
                            <div className="flex flex-col gap-y-[6px]">
                              {booking?.roomBookingData?.map((room, index) => (
                                <div
                                  className={`${
                                    index >= 0 &&
                                    index !==
                                      booking?.roomBookingData?.length - 1
                                      ? " border-txt-hint border-b-[0.5px]"
                                      : "border-none"
                                  } pb-[6px]`}
                                >
                                  <div className="flex items-center justify-start space-x-[2px]">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className={`w-[14px] h-[14px] text-txt-hint ${
                                          tcolor[`${booking?.state}`]
                                        } `}
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                    <p>
                                      {room?.roomCategoryName}{" "}
                                      <span
                                        className={`text-[14px] ${
                                          tcolor[`${booking?.state}`]
                                        }`}
                                      >
                                        x{room?.noOfTimes}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>In : {room?.checkinDate}</p>
                                    <p>Out : {room?.checkoutDate}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="flex justify-between ">
                            <div
                              className={`flex border border-[0.3px] px-[5px] ${
                                bcolor[`${booking?.state}`]
                              } rounded-[4px] `}
                            >
                              <p className="text-txt-hint">Total :</p>
                              <p className="text-txt-primary pl-[2px]">
                                {booking?.totalAmount} BDT
                              </p>
                            </div>
                            <p
                              className={`${
                                color[`${booking?.state}`]
                              } px-[6px] py-[1px] text-bg-white rounded-[2px]`}
                            >
                              {booking?.state}
                            </p>
                          </div>
                          {/* <span
                    className={`${
                      color[`${booking?.state}`]
                    } px-[8px] py-[3px] rounded-[4px]`}
                  >
                    {booking?.state}
                  </span>
                  <span className="flex  flex-col gap-[6px]">
                    {booking?.roomBookingData?.map((roomeBooking) => (
                      <div className="flex flex-col gap-[6px] border p-[4px] rounded-[8px]  text-[12px]">
                        <span>Category : {roomeBooking?.roomCategoryName}</span>
                        <span>Check In : {roomeBooking?.checkinDate}</span>
                        <span>Check Out : {roomeBooking?.checkoutDate}</span>
                      </div>
                    ))}
                  </span> */}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="">
              <Paginations
                currentPage={currentPage}
                totalCount={Math.ceil(bookingData?.totalEntries / 10)}
                pageSize={10}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
