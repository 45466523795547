import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourActivityTypeDashborad() {
  const [modalShown, setToggleModal] = useState(false);
  const [allActivityType, setAllActivityType] = useState([]);
  const [roomType, setRoomType] = useState({
    activity_type_name: "",
    description: "",
  });
  const [roomTypes, setRoomTypes] = useState([]);
  const handlePackage = () => {
    setRoomTypes([...roomTypes, roomType]);
    setRoomType({
      activity_type_name: "",
      description: "",
    });
    setToggleModal(false);
  };

  const getActivityType = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_FETCH_ACTIVITY_TYPE_GET_API}`)
      .then((response) => {
        setAllActivityType(response?.data);
      });
  };
  useEffect(() => {
    getActivityType();
  }, []);

  const handlePackageAdd = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_ACTIVITY_TYPE_ADD_POST_API}`, {
        activity_types: roomTypes,
      })
      .then((response) => {
        // console.log(response);
        if (response?.status === 201) {
          getActivityType();
          setRoomTypes([]);
        }
      });
  };
  const removeRoomType = (ind) => {
    const newArray = roomTypes?.filter((meal, index) => index !== ind);
    setRoomTypes(newArray);
  };

  const removeRoomTypeApi = (ind) => {
    authAxios
      .delete(
        `${baseUrl}${apiEndPoint.ADMIN_DELETE_ACTIVITY_TYPE_DELETE_API}${ind}?requestId=wsd`
      )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Successfully deleted activity type");
          getActivityType();
        }
      });
  };
  return (
    <div className="mt-[20px]">
      <div className="max-w-[1100px] mx-auto">
        <p className="text-btn-secondary">Activity Added list</p>
        <div className="mt-[30px] max-w-[500px] p-[12px] flex flex-col gap-y-[20px]  overflow-y-scroll max-h-[550px]">
          {allActivityType?.activity_types?.map((activity, index) => (
            <div
              key={index}
              className="shadow-customShadow p-[15px] rounded-[8px] relative"
            >
              <p className="text-[16px]">{activity?.activity_type_name}</p>
              <p className="text-[14px] mt-[4px]">
                {activity?.activity_type_description}
              </p>

              <span
                onClick={() => removeRoomTypeApi(index)}
                className="absolute top-[-12px] right-[-8px] cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px] text-[#EC7063]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
        <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed w-full right-[30px] top-[90px]">
          <h2 className="text-[16px] text-center underline">Activity Type</h2>
          <div className="mt-[20px] flex flex-col gap-y-[10px] ">
            {roomTypes?.map((tour, index) => (
              <div
                key={index}
                className="border rounded-[12px]  p-[10px] relative border-[#161010]"
              >
                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Activity Name :</span>{" "}
                  {tour?.activity_type_name}
                </p>

                <p className="text-[14px]">
                  {" "}
                  <span className="text-[12px]">Description: </span>
                  {tour?.description}
                </p>

                <span
                  onClick={() => removeRoomType(index)}
                  className="absolute top-[-12px] right-[-8px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px] text-[#EC7063]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ))}
          </div>

          <div className="w-full flex justify-end mt-[10px]">
            <button
              className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
                roomTypes?.length > 0 ? "w-[150px] " : "w-full"
              }`}
              onClick={() => setToggleModal(true)}
            >
              Add New
            </button>
          </div>
          {roomTypes?.length > 0 && (
            <button
              onClick={() => handlePackageAdd()}
              className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white"
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        outSideOff={false}
        height="min-h-[200px]"
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setRoomType((prev) => ({
                    ...prev,
                    activity_type_name: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={roomType.activity_type_name}
              />
              <span className="placeholder">Activity Name:</span>
            </label>
          </div>

          <div className="w-full">
            <label className="custom-field one w-full">
              <textarea
                onChange={(e) =>
                  setRoomType((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                className="input w-full min-h-[150px] max-h-[200px]"
                type="text"
                placeholder=" "
                value={roomType.description}
              ></textarea>
              <span className="placeholder">Description :</span>
            </label>
          </div>
          <button
            disabled={
              roomType?.activity_type_name.length === 0 ||
              roomType?.description.length === 0
            }
            onClick={() => handlePackage()}
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white disabled:bg-bg-semiblack"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
