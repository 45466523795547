import React from "react";
import { useNavigate } from "react-router";

export default function AvailibilitySetTable({ responseData }) {
  const navigate = useNavigate();

  console.log({ responseData });

  return (
    <div>
      {/* <table className={`table-fixed rounded-[8px] border-collapse  w-full`}>
        <thead
          className={`sticky top-[88px] shadow-sm bg-[#48C9B0]  text-bg-primary  text-left`}
        >
          <tr>
            <th className=" px-[8px]  py-[10px]  ">Food </th>
            <th className="  py-[10px] px-[8px]  ">Accommodation </th>
            <th className="px-[8px]  py-[10px] ">Transfer </th>

            <th className=" px-[8px]  py-[10px]  ">Transportation</th>
            <th className=" px-[8px]  py-[10px]  ">Guide</th>
            <th className=" px-[8px]  py-[10px]  ">Spot </th>
          </tr>
        </thead>
        <tbody className="">
          {availableOption?.map((packageData, index) => (
            <tr
              key={index}
              className={`hover:bg-[#48c9af16] cursor-pointer border-b text-[14px] border-[#eaecef]`}
            >
              <td>
                {packageData?.tour_package_available_food_options?.map(
                  (foodOption, index) => (
                    <div>
                      {foodOption?.tour_package_available_meal_packages?.map(
                        (foods, index) => (
                          <div key={index}>
                            <div>
                              <p className="text-center text-[16px] bg-btn-secondary text-bg-white">
                                {foods?.meal_type_name}
                              </p>
                              <p className="flex gap-x-[6px]">
                                {foods?.food_items?.map((fds, ind) => (
                                  <span key={ind}>{fds?.food_item_name}</span>
                                ))}
                              </p>
                              <p>Price: {foods?.meal_package_price}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
             
              <td>
                {packageData?.tour_package_available_accommodation_options?.map(
                  (packageData, index) => (
                    <div key={index}>
                      {packageData?.tour_package_available_accommodation_packages?.map(
                        (pkgData, ind) => (
                          <div key={ind}>
                            <p className="text-[16px] text-center bg-btn-secondary text-bg-white">
                              {pkgData?.tour_package_accommodation_name}
                            </p>
                            <p>
                              {" "}
                              {pkgData?.tour_package_room_category_name}{" "}
                              Category {pkgData?.tour_package_room_type_name}{" "}
                              type {pkgData?.bed_configuration} Bed
                            </p>
                            <p>Price {pkgData?.per_night_room_price}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_transfer_options?.map(
                  (trnsferPkg, index) => (
                    <div key={index}>
                      {trnsferPkg?.tour_package_available_transfer_packages?.map(
                        (trnsPkg, index) => (
                          <div key={index}>
                            <p className="text-[14px] bg-btn-secondary text-bg-white">
                              {trnsPkg?.tour_package_transfer_route}
                            </p>
                            <p>
                              {
                                trnsPkg?.tour_package_transportation_provider_name
                              }{" "}
                              {trnsPkg?.tour_package_transportation_mode_name}{" "}
                              {trnsPkg?.trip_type}
                            </p>
                            <p>Price : {trnsPkg?.per_vehicle_per_trip_price}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_transportation_packages?.map(
                  (trnsportation, index) => (
                    <div key={index}>
                      <p className="text-bg-white text-center bg-btn-secondary">
                        {trnsportation?.tour_package_transportation_route_name}
                      </p>
                      <p>
                        {
                          trnsportation?.tour_package_transportation_provider_name
                        }{" "}
                        {trnsportation?.tour_package_transportation_mode_name}{" "}
                        {trnsportation?.tour_package_transportation_brand_name}{" "}
                        brand
                      </p>
                      <p>{trnsportation?.trip_type}</p>
                      <p>
                        Price :{" "}
                        {trnsportation?.transportation_package_unit_price}
                      </p>
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_guide_options?.map(
                  (guidePkg, index) => (
                    <div key={index}>
                      {guidePkg?.tour_package_available_guide_packages?.map(
                        (guide, ind) => (
                          <div key={ind}>
                            <p>For Day : {guide?.number_of_guides_for_day}</p>
                            <p>Price: {guide?.total_guide_price_for_day}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_spot_entry_options?.map(
                  (spotPkg, index) => (
                    <div key={index}>
                      {spotPkg?.tour_package_available_spot_entry_packages?.map(
                        (spot, ind) => (
                          <div key={ind}>
                            <p className="text-center bg-btn-secondary text-bg-white">
                              {spot?.activity?.activity_name}
                            </p>
                            <p>{spot?.activity?.activity_short_location}</p>
                            <p>{spot?.spot_entry_price_per_person}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      {/* New Design */}
      <div className="w-full">
        <div className="">
          <p className="border-l-[2px] bg-tour-dnd border-tour-primary py-[8px]">
            <span className="ml-[16px] ">Tour Schedule: </span>
          </p>
          <div className="w-[357px]  flex flex-col  overflow-auto">
            {
              <div className="border-l-[1px] border-tour-input pt-[8px]">
                <div className="ml-[16px]">
                  <div className="border-[1px] border-tour-secondaryHint rounded-[12px] ">
                    <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {" "}
                      {responseData[0]?.tour_package_type_name}
                    </p>
                    <div className="flex flex-col gap-[37px] px-[12px] py-[16px]">
                      <div className="flex flex-col gap-[8px]">
                        <div className="flex flex-col  text-[14px] font-normal">
                          <p className="text-tour-text">
                            {"Tour Dates Options : "}{" "}
                            <span className="font-medium text-tour-secondary">
                              {responseData[0]?.tour_start_date}
                            </span>
                          </p>
                          <div className="grid grid-cols-2 gap-[8px]"></div>
                        </div>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Total Seat: </span>
                          <span className="text-tour-secondary">
                            {responseData[0]?.total_seats}
                          </span>
                        </p>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Bookable Seat: </span>
                          <span className="text-tour-secondary">
                            {responseData[0]?.bookable_seats}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        {responseData.map((availableOptionData, index) => (
          <div key={index}>
            {/* Food Package */}
            <div className="my-[12px] bg-bg-white ">
              <div className="border-l-[2px] bg-tour-dnd border-tour-primary py-[8px]">
                <h2 className="ml-[16px] ">Food Package</h2>
              </div>
              <div className="border-l-[1px] border-tour-input pt-[8px]">
                {availableOptionData?.tour_package_available_food_options?.map(
                  (foodOption, ind) => (
                    <div key={ind} className="flex flex-col ml-[16px]  ">
                      <div key={ind} className="">
                        <div className="text-tour-text text-[14px]">
                          <p className="text-[16px] mt-[16px]">
                            Option {ind + 1}
                          </p>
                          <p>
                            Active :{" "}
                            {foodOption?.tour_package_option_is_active
                              ? "Yes"
                              : "No"}
                          </p>
                          <p>
                            Price Per Person :{" "}
                            {foodOption?.tour_package_option_price_per_person}{" "}
                            BDT
                          </p>
                        </div>
                        {foodOption?.tour_package_available_meal_packages && (
                          <div className="flex  ">
                            {foodOption?.tour_package_available_meal_packages && (
                              <div className="max-h-[600px] overflow-y-scroll">
                                <div className="">
                                  {Object.keys(
                                    foodOption?.tour_package_available_meal_packages
                                  ).map((dayNumber, index) => (
                                    <div key={index} className="text-[14px] ">
                                      <p className="my-[8px]">
                                        Day {dayNumber}
                                      </p>
                                      <div className="grid grid-cols-3 gap-[12px]  ">
                                        {Object.keys(
                                          foodOption
                                            ?.tour_package_available_meal_packages[
                                            dayNumber
                                          ]
                                        ).map((mealType, ind) => (
                                          <div
                                            key={ind}
                                            className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                                          >
                                            <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                              {
                                                foodOption
                                                  ?.tour_package_available_meal_packages[
                                                  dayNumber
                                                ][mealType]?.meal_type_name
                                              }
                                            </p>
                                            <div className="flex flex-row  text-[14px] font-normal text-tour-text p-[8px]">
                                              <p>{"Food: "}</p>
                                              <p className="font-medium text-tour-secondary">
                                                {" "}
                                                {Object.values(
                                                  foodOption
                                                    ?.tour_package_available_meal_packages[
                                                    dayNumber
                                                  ][mealType]?.food_items
                                                ).join(", ")}
                                              </p>
                                            </div>
                                            <p className="px-[8px] text-tour-text text-[14px]">
                                              Meal Package Price:{" "}
                                              <span className="font-bold">
                                                {
                                                  foodOption
                                                    ?.tour_package_available_meal_packages[
                                                    dayNumber
                                                  ][mealType]
                                                    ?.meal_package_price
                                                }{" "}
                                                BDT
                                              </span>
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* accommodation */}
            <div className="border-l-[1px] border-tour-input my-[16px]">
              <div className="">
                <h2 className="border-l-[1px] border-tour-secondary py-[8px] bg-tour-dnd">
                  <span className="ml-[16px]">Accommodation</span>
                </h2>
              </div>

              <div className="pt-[8px] pl-[16px] border-tour-secondary">
                {availableOptionData?.tour_package_available_accommodation_options?.map(
                  (optionData, ind) => (
                    <div key={ind} className="mt-[10px]">
                      <p className="text-[16px] text-tour-text">
                        Option {ind + 1}
                      </p>
                      <div className="text-[14px] text-tour-text pt-[4px]">
                        <p>
                          Active :{" "}
                          {optionData?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          Price per person :{" "}
                          {optionData?.tour_package_option_price_per_person} BDT
                        </p>
                      </div>
                      <div>

                        {optionData?.tour_package_available_accommodation_packages && (
                          <div className="grid grid-cols-3 gap-[16px] mt-[8px]">
                            {Object.keys(
                              optionData?.tour_package_available_accommodation_packages,
                            )
                              ?.map(
                                (key) =>
                                  optionData
                                    ?.tour_package_available_accommodation_packages[
                                    key
                                  ]
                              )
                              ?.map((accommodation, index) => (
                                <div>
                                  <p className="text-[14px] text-tour-text mb-[8px]">Night {index + 1}</p>
                                  <div
                                  key={index}
                                  className="border-[1px] border-tour-secondaryHint rounded-[12px] "
                                >
                                  <h2 className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                    {
                                      accommodation?.tour_package_accommodation_name
                                    }
                                  </h2>
                                  <div className=" flex flex-col gap-[4px] px-[12px] py-[16px] text-[14px] text-tour-text">
                                    <p>
                                      Category :{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {
                                          accommodation?.tour_package_room_category_name
                                        }
                                      </span>
                                    </p>
                                    <p>
                                      Type:{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {
                                          accommodation?.tour_package_room_type_name
                                        }
                                      </span>
                                    </p>
                                    <p>
                                      Bed:{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {accommodation?.bed_configuration}
                                      </span>
                                    </p>
                                    <p>
                                      Bed Number :{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {accommodation?.bed_count}
                                      </span>
                                    </p>
                                    <p>
                                      Shareable :{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {accommodation?.is_room_shareable
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </p>
                                    <p>
                                      Night Number:{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {accommodation?.night_number}
                                      </span>
                                    </p>
                                    <p>
                                      Price:{" "}
                                      <span className="font-medium text-tour-secondary">
                                        {accommodation?.per_night_room_price}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Transportation */}
            <div className="border-l-[1px] border-tour-input">
              <h2 className="border-l-[1px] bg-tour-dnd border-tour-primary py-[8px] ">
                <span className="ml-[16px]">Transportation </span>
              </h2>
              <div className="my-[16px] ml-[16px]">
                <div className="grid grid-cols-3 gap-[16px] ">
                  {availableOptionData?.tour_package_available_transportation_packages?.map(
                    (transportationOption, index) => (
                      <div key={index} className="">
                        <p className=" mb-[8px] text-[16px] text-tour-text">
                          Option {index + 1}
                        </p>
                        <div className="text-[14px] border-[1px] border-tour-secondaryHint rounded-[12px]">
                          <h2 className="text-tour-text border-b-[1px] border-tour-secondaryHint font-normal px-[12px] py-[6px]">
                            {
                              transportationOption?.tour_package_transportation_route_name
                            }
                          </h2>
                          <div className="flex flex-col gap-[4px] px-[12px] py-[6px]">
                            <p>
                              Mode :{" "}
                              <span className="font-medium text-tour-secondary">
                                {
                                  transportationOption?.tour_package_transportation_mode_name
                                }
                              </span>
                            </p>
                            <p>
                              Provider Name :{" "}
                              <span className="font-medium text-tour-secondary">
                                {
                                  transportationOption?.tour_package_transportation_provider_name
                                }
                              </span>
                            </p>
                            <p>
                              Brand :{" "}
                              <span className="font-medium text-tour-secondary">
                                {
                                  transportationOption?.tour_package_transportation_brand_name
                                }
                              </span>
                            </p>
                            <p>
                              Ac :
                              <span className="font-medium text-tour-secondary">
                                {transportationOption?.is_ac ? "Yes" : "No"}
                              </span>
                            </p>
                            <p>
                              Trip Type :
                              <span className="font-medium text-tour-secondary">
                                {transportationOption?.trip_type}
                              </span>
                            </p>
                            <p>
                              Unit Per Price :{" "}
                              <span className="font-bold">
                                {
                                  transportationOption?.transportation_package_unit_price
                                }{" "}
                                BDT
                              </span>
                            </p>
                            <p>
                              Active :{" "}
                              <span className="font-medium text-tour-secondary">
                                {transportationOption?.transportation_package_is_active
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {/* Transfer */}
            <div className="border-l-[1px] border-tour-input my-[16px]">
              <div className="">
                <h2 className="border-l-[1px] border-tour-secondary py-[8px] bg-tour-dnd">
                  <span className="ml-[16px]">Transfer</span>
                </h2>
              </div>

              <div className="py-[8px] pl-[16px]">
                {availableOptionData?.tour_package_available_transfer_options?.map(
                  (transferDataOption, ind) => (
                    <div key={ind} className="mb-[16px]">
                      <p className="text-[16px] text-tour-text">
                        Option {ind + 1}
                      </p>
                      <div>
                        <p className="text-[14px] text-tour-text">
                          Active :{" "}
                          {transferDataOption?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p className="text-[14px] text-tour-text mb-[16px]">
                          Price Per Person :{" "}
                          {
                            transferDataOption?.tour_package_option_price_per_person
                          }{" "}
                          BDT
                        </p>
                      </div>
                      <div className="grid grid-cols-3 gap-[16px]">
                        {transferDataOption?.tour_package_available_transfer_packages?.map(
                          (transferData, ind) => (
                            <div
                              key={ind}
                              className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                            >
                              <h2 className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                {transferData?.tour_package_transfer_route}
                              </h2>
                              <div className="flex flex-col gap-[4px] px-[12px] py-[16px] text-[14px] text-tour-text">
                                <p>
                                  Mode :{" "}
                                  <span className="font-medium text-tour-secondary">
                                    {
                                      transferData?.tour_package_transportation_mode_name
                                    }
                                  </span>
                                </p>
                                <p>
                                  Provider :{" "}
                                  <span className="font-medium text-tour-secondary">
                                    {
                                      transferData?.tour_package_transportation_provider_name
                                    }
                                  </span>
                                </p>
                                <p>
                                  Suitable for :{" "}
                                  <span className="font-medium text-tour-secondary">
                                    {transferData?.suitable_for_persons} Person
                                  </span>
                                </p>
                                <p>
                                  Trip Type:{" "}
                                  <span className="font-medium text-tour-secondary">
                                    {transferData?.trip_type}
                                  </span>
                                </p>
                                <p>
                                  Ac :
                                  <span className="font-medium text-tour-text">
                                    {transferData?.is_ac ? "Yes" : "No"}
                                  </span>
                                </p>
                                <p>
                                  Price :{" "}
                                  <span className="font-bold">
                                    {transferData?.per_vehicle_per_trip_price}{" "}
                                    BDT
                                  </span>
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Guide */}
            <div className="border-l-[1px] border-tour-input my-[16px]">
              <div className="">
                <h2 className="border-l-[1px] border-tour-secondary py-[8px] bg-tour-dnd">
                  <span className="ml-[16px]">Guide</span>
                </h2>
              </div>

              <div className="py-[8px] pl-[16px]">
                {availableOptionData?.tour_package_available_guide_options?.map(
                  (guideDataOption, ind) => (
                    <div key={ind} className="mb-[16px]">
                      <p className="text-[16px] text-tour-text">
                        Option {ind + 1}
                      </p>
                      <div className="text-[14px] text-tour-text mb-[8px]">
                        <p>
                          Active :{" "}
                          {guideDataOption?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          Price per person :{" "}
                          {
                            guideDataOption?.tour_package_option_price_per_person
                          }{" "}
                          BDT
                        </p>
                      </div>

                      <div className="grid grid-cols-3 gap-[16px]">
                        {guideDataOption?.tour_package_available_guide_packages?.map(
                          (guideData, ind) => (
                            <div
                              key={ind}
                              className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                            >
                              <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                {guideData?.number_of_guides_for_day} {" Day"}
                              </p>
                              <div className="flex flex-col gap-[4px] px-[12px] py-[16px] text-[14px] text-tour-text">
                                <p>
                                  {"Guide: "}
                                  <span className="font-medium text-tour-secondary">
                                    {guideData?.number_of_guides_for_day}
                                  </span>
                                </p>
                                <p>
                                  {"Price: "}
                                  <span className="font-bold">
                                    {guideData?.total_guide_price_for_day} BDT
                                  </span>
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Spot */}
            <div className="border-l-[1px] border-tour-input my-[16px]">
              <div className="">
                <h2 className="border-l-[1px] border-tour-secondary py-[8px] bg-tour-dnd">
                  <span className="ml-[16px]">Spot</span>
                </h2>
              </div>

              <div className="py-[8px] pl-[16px]">
                {availableOptionData?.tour_package_available_spot_entry_options?.map(
                  (spotDataOption, ind) => (
                    <div key={ind} className="mb-[16px]">
                      <p className="text-[16px] text-tour-text">
                        Option {ind + 1}
                      </p>
                      <div className="text-[14px] text-tour-text mb-[8px]">
                        <p>
                          Active :{" "}
                          {spotDataOption?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          Price per person:{" "}
                          {spotDataOption?.tour_package_option_price_per_person}{" "}
                          BDT
                        </p>
                      </div>
                      <div className="grid grid-cols-3 gap-[16px]">
                        {spotDataOption?.tour_package_available_spot_entry_packages?.map(
                          (spotData, ind) => (
                            <div
                              key={ind}
                              className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                            >
                              <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                {spotData?.activity?.activity_name}
                              </p>
                              <div className="flex flex-col gap-[4px] px-[12px] py-[16px] text-[14px] text-tour-text">
                                <p>
                                  {"Location: "}
                                  <span className="font-medium text-tour-secondary">
                                    {
                                      spotData?.activity
                                        ?.activity_short_location
                                    }
                                  </span>
                                </p>
                                <p>
                                  {"Price: "}
                                  <span className="font-bold">
                                    {spotData?.spot_entry_price_per_person} BDT
                                  </span>
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
        <div>
          <button
            onClick={() => navigate("/dashboard/tours/active-tour")}
            className="w-[300px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
          >
            Back Tour
          </button>
        </div>
      </div>
      {/* Old Design */}
    </div>
  );
}

// Old Design
// {/* <div>
//       <div>
//         {responseData?.map((availableOptionData, index) => (
//           <div key={index} className="mt-[10px] ">
//             <div className="flex flex-col gap-[6px] text-[14px]">
//               <h2 className="text-[16px]">
//                 Package Name: {availableOptionData?.tour_package_type_name}
//               </h2>
//               <p>Start Date : {availableOptionData?.tour_start_date}</p>
//               <p>Total Seat : {availableOptionData?.total_seats}</p>
//               <p>Bookable Seat : {availableOptionData?.bookable_seats}</p>
//               <p>
//                 Accommodation Inclusive :{" "}
//                 {availableOptionData?.is_accommodation_inclusive ? "Yes" : "No"}
//               </p>
//               <p>
//                 Food Inclusive :{" "}
//                 {availableOptionData?.is_food_inclusive ? "Yes" : "No"}
//               </p>
//               <p>
//                 Guide Inclusive :{" "}
//                 {availableOptionData?.is_guide_inclusive ? "Yes" : "No"}
//               </p>
//               <p>
//                 {" "}
//                 Spot Inclusive :{" "}
//                 {availableOptionData?.is_spot_entry_inclusive ? "Yes" : "No"}
//               </p>
//               <p>
//                 {" "}
//                 Transfer Inclusive :{" "}
//                 {availableOptionData?.is_transfer_inclusive ? "Yes" : "No"}
//               </p>
//             </div>
//             <h2 className="text-center text-[18px] bg-btn-secondary py-[3px] text-bg-white mt-[4px]">
//               Tour Package Option {index + 1}
//             </h2>
//             {/* accommodation */}
//             <div className="bg-[#FDFEFE] p-[10px] mt-[10px]">
//               <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//                 <h2>Accommodation</h2>
//               </div>

//               {availableOptionData?.tour_package_available_accommodation_options?.map(
//                 (optionData, ind) => (
//                   <div key={ind} className="mt-[10px]">
//                     <p className="bg-btn-secondary py-[3px] text-center text-bg-white">
//                       Option {ind + 1}
//                     </p>
//                     <div>
//                       <p>
//                         Active :{" "}
//                         {optionData?.tour_package_option_is_active
//                           ? "Yes"
//                           : "No"}
//                       </p>
//                       <p>
//                         Price per person :{" "}
//                         {optionData?.tour_package_option_price_per_person} BDT
//                       </p>
//                     </div>
//                     <div>
//                       {optionData?.tour_package_available_accommodation_packages && (
//                         <div className="flex gap-x-[30px] mt-[10px] ">
//                           {Object.keys(
//                             optionData?.tour_package_available_accommodation_packages
//                           )
//                             ?.map(
//                               (key) =>
//                                 optionData
//                                   ?.tour_package_available_accommodation_packages[
//                                   key
//                                 ]
//                             )
//                             ?.map((accommodation, index) => (
//                               <div
//                                 key={index}
//                                 className="shadow-customShadow p-[10px] rounded-[6px] bg-bg-white"
//                               >
//                                 <h2 className="text-center border-b border-btn-secondary">
//                                   {
//                                     accommodation?.tour_package_accommodation_name
//                                   }
//                                 </h2>
//                                 <p>
//                                   Category :{" "}
//                                   {
//                                     accommodation?.tour_package_room_category_name
//                                   }
//                                 </p>
//                                 <p>
//                                   Type:{" "}
//                                   {accommodation?.tour_package_room_type_name}
//                                 </p>
//                                 <p>Bed : {accommodation?.bed_configuration}</p>
//                                 <p>Bed Number : {accommodation?.bed_count}</p>
//                                 <p>
//                                   Shareable :{" "}
//                                   {accommodation?.is_room_shareable
//                                     ? "Yes"
//                                     : "No"}
//                                 </p>
//                                 <p>
//                                   Night Number: {accommodation?.night_number}
//                                 </p>
//                                 <p>
//                                   Price: {accommodation?.per_night_room_price}
//                                 </p>
//                               </div>
//                             ))}
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 )
//               )}
//             </div>

//             {/* Food */}
//             <div className="mt-[10px] bg-bg-white p-[10px]">
//               <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//                 <h2>Food Package</h2>
//               </div>

//               <div>
//                 {availableOptionData?.tour_package_available_food_options?.map(
//                   (foodOption, ind) => (
//                     <div key={ind} className="flex flex-col  gap-[30px]">
//                       <div key={ind} className="mt-[20px] bg-[#EAECEE] p-[8px]">
//                         <div>
//                           <p>
//                             Active :{" "}
//                             {foodOption?.tour_package_option_is_active
//                               ? "Yes"
//                               : "No"}
//                           </p>
//                           <p>
//                             Price Per Person :{" "}
//                             {foodOption?.tour_package_option_price_per_person}{" "}
//                             BDT
//                           </p>
//                         </div>
//                         {foodOption?.tour_package_available_meal_packages && (
//                           <div className="flex  ">
//                             {foodOption?.tour_package_available_meal_packages && (
//                               <div className="max-h-[600px] overflow-y-scroll">
//                                 <div className="bg-[#F4F6F6] w-full p-[6px]">
//                                   {Object.keys(
//                                     foodOption?.tour_package_available_meal_packages
//                                   ).map((dayNumber, index) => (
//                                     <div key={index} className="text-[14px] ">
//                                       <p className="bg-btn-secondary text-bg-white">
//                                         Day {dayNumber} Meal Option
//                                       </p>
//                                       <div className="flex gap-[40px]">
//                                         {Object.keys(
//                                           foodOption
//                                             ?.tour_package_available_meal_packages[
//                                             dayNumber
//                                           ]
//                                         ).map((mealType, ind) => (
//                                           <div key={ind}>
//                                             <p className="text-[18px] font-bold">
//                                               {
//                                                 foodOption
//                                                   ?.tour_package_available_meal_packages[
//                                                   dayNumber
//                                                 ][mealType]?.meal_type_name
//                                               }
//                                             </p>
//                                             <div>
//                                               {Object.values(
//                                                 foodOption
//                                                   ?.tour_package_available_meal_packages[
//                                                   dayNumber
//                                                 ][mealType]?.food_items
//                                               ).join(", ")}
//                                             </div>
//                                             <p>
//                                               Meal Package Price:{" "}
//                                               {
//                                                 foodOption
//                                                   ?.tour_package_available_meal_packages[
//                                                   dayNumber
//                                                 ][mealType]?.meal_package_price
//                                               }{" "}
//                                               BDT
//                                             </p>
//                                           </div>
//                                         ))}
//                                       </div>
//                                     </div>
//                                   ))}
//                                 </div>
//                               </div>
//                             )}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>

//             {/* Transportation */}
//             <div className="bg-bg-white mt-[20px] p-[10px]">
//               <h2 className="bg-btn-primary text-bg-white py-[3px] ">
//                 Transportation{" "}
//               </h2>
//               <div className="flex flex-wrap gap-[30px] p-[10px]">
//                 {availableOptionData?.tour_package_available_transportation_packages?.map(
//                   (transportationOption, index) => (
//                     <div key={index} className="p-[10px] bg-[#EBF5FB]">
//                       <p className="border-b ">Option {index + 1}</p>
//                       <h2>
//                         Route Name :
//                         {
//                           transportationOption?.tour_package_transportation_route_name
//                         }
//                       </h2>
//                       <p>
//                         Mode :{" "}
//                         {
//                           transportationOption?.tour_package_transportation_mode_name
//                         }
//                       </p>
//                       <p>
//                         Provider Name :{" "}
//                         {
//                           transportationOption?.tour_package_transportation_provider_name
//                         }
//                       </p>
//                       <p>
//                         Brand :{" "}
//                         {
//                           transportationOption?.tour_package_transportation_brand_name
//                         }
//                       </p>
//                       <p>Ac : {transportationOption?.is_ac ? "Yes" : "No"}</p>
//                       <p>Trip Type : {transportationOption?.trip_type}</p>
//                       <p>
//                         Unit Per Price :{" "}
//                         {
//                           transportationOption?.transportation_package_unit_price
//                         }{" "}
//                         BDT
//                       </p>
//                       <p>
//                         Active :{" "}
//                         {transportationOption?.transportation_package_is_active
//                           ? "Yes"
//                           : "No"}
//                       </p>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>

//             {/* Transfer */}
//             <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
//               <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//                 <h2>Transfer</h2>
//               </div>

//               <div>
//                 {availableOptionData?.tour_package_available_transfer_options?.map(
//                   (transferDataOption, ind) => (
//                     <div key={ind}>
//                       <p className="border-b ">Option {ind + 1}</p>
//                       <div>
//                         <p>
//                           Active :{" "}
//                           {transferDataOption?.tour_package_option_is_active
//                             ? "Yes"
//                             : "No"}
//                         </p>
//                         <p>
//                           Price Per Person :{" "}
//                           {
//                             transferDataOption?.tour_package_option_price_per_person
//                           }{" "}
//                           BDT
//                         </p>
//                       </div>
//                       <div className="flex gap-x-[10px]">
//                         {transferDataOption?.tour_package_available_transfer_packages?.map(
//                           (transferData, ind) => (
//                             <div
//                               key={ind}
//                               className="shadow-customShadow p-[6px] rounded-[6px]"
//                             >
//                               <h2>
//                                 {transferData?.tour_package_transfer_route}
//                               </h2>
//                               <p>
//                                 Mode :{" "}
//                                 {
//                                   transferData?.tour_package_transportation_mode_name
//                                 }
//                               </p>
//                               <p>
//                                 Provider :{" "}
//                                 {
//                                   transferData?.tour_package_transportation_provider_name
//                                 }
//                               </p>
//                               <p>
//                                 Suitable for :{" "}
//                                 {transferData?.suitable_for_persons} Person
//                               </p>
//                               <p>Trip Type : {transferData?.trip_type}</p>
//                               <p>Ac :{transferData?.is_ac ? "Yes" : "No"}</p>
//                               <p>
//                                 Price :{" "}
//                                 {transferData?.per_vehicle_per_trip_price} BDT
//                               </p>
//                             </div>
//                           )
//                         )}
//                       </div>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>

//             {/* Guide */}
//             <div className="mt-[10px] bg-bg-white p-[10px]">
//               <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//                 <h2>Guide</h2>
//               </div>

//               {availableOptionData?.tour_package_available_guide_options?.map(
//                 (guideDataOption, ind) => (
//                   <div key={ind}>
//                     <p className="border-b">Option {ind + 1}</p>
//                     <div>
//                       <p>
//                         Active :{" "}
//                         {guideDataOption?.tour_package_option_is_active
//                           ? "Yes"
//                           : "No"}
//                       </p>
//                       <p>
//                         Price per person :{" "}
//                         {guideDataOption?.tour_package_option_price_per_person}{" "}
//                         BDT
//                       </p>
//                     </div>

//                     <div className="flex  gap-x-[10px] mt-[10px]">
//                       {guideDataOption?.tour_package_available_guide_packages?.map(
//                         (guideData, ind) => (
//                           <div
//                             key={ind}
//                             className="shadow-customShadow p-[6px] rounded-[4px]"
//                           >
//                             <p>
//                               For Day : {guideData?.number_of_guides_for_day}
//                             </p>
//                             <p>
//                               Price : {guideData?.total_guide_price_for_day} BDT
//                             </p>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   </div>
//                 )
//               )}
//             </div>
//             {/* Spot */}
//             <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
//               <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//                 <h2>Spot</h2>
//               </div>

//               <div>
//                 {availableOptionData?.tour_package_available_spot_entry_options?.map(
//                   (spotDataOption, ind) => (
//                     <div key={ind}>
//                       <p className="border-b">Option {ind + 1}</p>
//                       <div>
//                         <p>
//                           Active :{" "}
//                           {spotDataOption?.tour_package_option_is_active
//                             ? "Yes"
//                             : "No"}
//                         </p>
//                         <p>
//                           Price per person:{" "}
//                           {spotDataOption?.tour_package_option_price_per_person}{" "}
//                           BDT
//                         </p>
//                       </div>
//                       <div className=" flex gap-x-[10px] ">
//                         {spotDataOption?.tour_package_available_spot_entry_packages?.map(
//                           (spotData, ind) => (
//                             <div
//                               key={ind}
//                               className="shadow-customShadow p-[6px] rounded-[4px] mt-[10px]"
//                             >
//                               <p>{spotData?.activity?.activity_name}</p>
//                               <p>
//                                 {spotData?.activity?.activity_short_location}
//                               </p>
//                               <p>
//                                 Price per person :{" "}
//                                 {spotData?.spot_entry_price_per_person} BDT
//                               </p>
//                             </div>
//                           )
//                         )}
//                       </div>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       <button
//         onClick={() => navigate("/dashboard/tours/active-tour")}
//         className="text-bg-white text-[18px] bg-btn-secondary h-[39px] px-[10px] rounded-[4px] mt-[30px]"
//       >
//         Back Tour
//       </button>
//       </div> */}
