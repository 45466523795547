import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ButtonFilled from '../../components/ButtonFilled';
import InputComponent from '../../components/InputComponent';

const OptionDeltaPrice = () => {
    const [policyName,setPolicyName] = useState('');
    const [tourDate, setTourDate] = useState(new Date());
    const [policyType,setPolicyType] = useState('');

    const [deltaRackPrice, setdeltaRackPrice] = useState(0);
    const [deltaGhuddyCommissionAmount, setdeltaGhuddyCommissionAmount] = useState(0);
    const [deltaGhuddyCommissionPercentPoint, setdeltaGhuddyCommissionPercentPoint] = useState(0);
    const [deltaCorporatePrice, setdeltaCorporatePrice] = useState(0);
    const [deltaCorporateDiscountPercentPoint, setdeltaCorporateDiscountPercentPoint] = useState(0);
  
    const [deltaShurjoCommissionPercentPoint, setdeltaShurjoCommissionPercentPoint] = useState(0);
    const [deltaShurjoCommissionAmount, setdeltaShurjoCommissionAmount] = useState(0);
    
    const [deltaAfterGhuddyCommission, setdeltaAfterGhuddyCommision] = useState(0);
    const [deltaGhuddyWebSiteBlackPrice, setdeltaGhuddyWebSiteBlackPrice] = useState(0);
  
    const [deltaGhuddyWebSiteRedPrice, setdeltaGhuddyWebSiteRedPrice] = useState(0);
  
    const [deltaGhuddySubsidyAmount, setdeltaGhuddySubsidyAmount] = useState(0);
    const [deltaGhuddySubsidyPercentPoint, setdeltaGhuddySubsidyPercentpoint] = useState(0);
  
    const [deltaTotalDiscountPercentPoint, setdeltaTotalDiscountPercentPoint] = useState(0);
    const [deltaTotalGhuddyCommissionAmount, setdeltaTotalGhuddyCommissionAmount] =
      useState(0);
    const [deltaTotalGhuddyCommissionPercent, setdeltaTotalGhuddyCommissionPercent] =
      useState(0);
      const [deltaTotalShurjoCommissionAmount,setdeltaTotalShurjoCommissionAmount] = useState(0); 
  
    const [deltaTotalSeats, setdeltaTotalSeats] = useState(0);
    const [deltaBookableSeats, setdeltaBookableSeats] = useState(0);

    const baseData = {
        rack_price: 4000,
        corporate_price: 3200,
        corporate_discount_percent: 20,
        ghuddy_commission_percent: 10,
        ghuddy_commission_amount: 320,
        shurjo_commission_amount: 88,
        shurjo_commission_percent: 2.5,
        ghuddy_website_black_price: 3608,
        ghuddy_subsidy_amount: -92,
        ghuddy_subsidy_percent: -2.549889135254989,
        ghuddy_website_red_price : 3700,
        total_discount_percent : 7.5,
        total_shurjo_commission_amount : 92.5,
        total_ghuddy_commission_amount : 407.5,
        total_ghuddy_commission_percent : 12.734375,
        total_seats : 5,
        bookable_seats : 5,
        tour_package_option_id : 15,
        deleteAllFilters : true,
        requestId : "11"
    }
   
    const handleSetFilterPrice = ()=>{
        if(baseData){
          let filterObject ={
            delta_bookable_seats : deltaBookableSeats - baseData.bookable_seats,
            delta_corporate_discountPercent_point : deltaCorporateDiscountPercentPoint - baseData.corporate_discount_percent,
            delta_corporate_price : deltaCorporatePrice -baseData.corporate_price,
            delta_ghuddy_commission_amount : deltaGhuddyCommissionAmount - baseData.ghuddy_commission_amount,
            delta_ghuddy_commission_percent_point : deltaGhuddyCommissionPercentPoint - baseData.ghuddy_commission_percent,
            delta_ghuddy_subsidy_amount : deltaGhuddySubsidyAmount - baseData.ghuddy_subsidy_amount,
            delta_ghuddy_subsidy_percent_point : deltaGhuddySubsidyPercentPoint - baseData.ghuddy_subsidy_percent,
            delta_ghuddy_website_black_price : deltaGhuddyWebSiteBlackPrice - baseData.ghuddy_website_black_price, 
            delta_ghuddy_website_red_price : deltaGhuddyWebSiteRedPrice - baseData.ghuddy_website_red_price,
            delta_rack_price : deltaRackPrice - baseData?.rack_price,
            delta_shurjo_commission_amount : deltaShurjoCommissionAmount - baseData.shurjo_commission_amount ,
            delta_shurjo_commission_percent_point : deltaShurjoCommissionPercentPoint - baseData.shurjo_commission_percent,
            delta_total_discount_percent_point : deltaTotalDiscountPercentPoint  - baseData.total_discount_percent,

            delta_total_ghuddy_commission_amount : deltaTotalGhuddyCommissionAmount - baseData.total_ghuddy_commission_amount,
            delta_total_ghuddy_commission_percent_point : deltaTotalGhuddyCommissionPercent - baseData.total_ghuddy_commission_percent,
            delta_total_shurjo_commission_amount : deltaTotalShurjoCommissionAmount - baseData.total_shurjo_commission_amount,
            delta_total_seats : deltaTotalSeats - baseData.total_seats,
            policy_name : policyName,
            policy_type :policyType,
            requestId: "filter",
            tour_date : tourDate,
            capacity_price_id: 1,
          }
          console.log(filterObject,'filterObject')
        }
      }

//////////////////
      useEffect(() => {
        const temp = parseFloat(deltaCorporatePrice) + parseFloat(deltaGhuddyCommissionAmount);
        setdeltaAfterGhuddyCommision(temp);
      }, [
        deltaRackPrice,
        deltaGhuddyCommissionAmount,
        deltaCorporatePrice
      ]);
    
      useEffect(() => {
        const temp =
          parseFloat(deltaAfterGhuddyCommission) + parseFloat(deltaShurjoCommissionAmount);
        setdeltaGhuddyWebSiteBlackPrice(temp);
      }, [deltaAfterGhuddyCommission, deltaShurjoCommissionAmount]);
    
      useEffect(() => {
        const subsidyTemp =
          parseFloat(deltaGhuddyWebSiteBlackPrice) - parseFloat(deltaGhuddyWebSiteRedPrice);
    
        const subsidyPercentTemp =
          (parseFloat(subsidyTemp) / parseFloat(deltaGhuddyWebSiteBlackPrice)) * 100;
    
        const tempTotalShurjoCommision = (parseFloat(deltaGhuddyWebSiteRedPrice)* parseFloat(deltaShurjoCommissionPercentPoint)/100);
        setdeltaTotalShurjoCommissionAmount(tempTotalShurjoCommision);
    
        setdeltaGhuddySubsidyAmount(subsidyTemp);
        setdeltaGhuddySubsidyPercentpoint(subsidyPercentTemp);
      }, [deltaGhuddyWebSiteBlackPrice, deltaGhuddyWebSiteRedPrice]);
    
      useEffect(() => {
        const tempTotalDiscount =
          ((deltaRackPrice - deltaGhuddyWebSiteRedPrice) / deltaRackPrice) * 100;
        setdeltaTotalDiscountPercentPoint(tempTotalDiscount);
      }, [deltaRackPrice, deltaGhuddyWebSiteRedPrice]);
    
      useEffect(() => {
        const tempTotalCommission =
        parseFloat(deltaGhuddyWebSiteRedPrice) -(parseFloat(deltaShurjoCommissionAmount) + parseFloat(deltaCorporatePrice));
        
          setdeltaTotalGhuddyCommissionAmount(tempTotalCommission);
        const tempTotalCommisionPercent =
          (tempTotalCommission / deltaCorporatePrice) * 100;
        setdeltaTotalGhuddyCommissionPercent(tempTotalCommisionPercent);
      }, [
        deltaGhuddyCommissionAmount,
        deltaGhuddyCommissionPercentPoint,
        deltaShurjoCommissionAmount,
        deltaShurjoCommissionPercentPoint,
        deltaCorporatePrice,
        deltaGhuddyWebSiteRedPrice
      ]);
      /////

    const handleAmountAndPercent = (
        type,
        val,
        setAmount,
        setPercent,
        base,
      ) => {
        if (type === "amount") {
          setAmount(parseFloat(val));
          setPercent(parseFloat((base - val) * 100) / base);
        } else {
          setPercent(parseFloat(val));
          setAmount(parseFloat(base - base * (val / 100)));
        }
      };
    
      const handleConversion = (type, val, setPercentAmount, setPercent, base) => {
        const valInt = parseFloat(val);
        if (type === "amount") {
          setPercentAmount(valInt);
          setPercent((valInt * 100) / base);
        } else {
          setPercent(valInt);
          setPercentAmount((base * valInt) / 100);
        }
      };


    return (
        <div className='w-full'>
            <div className='w-full flex gap-[10px]'>
                <div className="w-1/2">
                    <span>Policy Name</span>
                    <InputComponent
                    name={"policyName"}
                    inputValue={policyName}
                    setInputValue={(val) => setPolicyName(val)}
                    warningView={false}
                    placeholder="Policy Name"
                    />
                </div>
                <div className="w-1/2">
                    <span>Tour Date</span>
                    <ReactDatePicker
                        title="Tour Date"
                        placeholderText="Tour Date"
                        className={` border  text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full  "border-btn-primary`}
                        selected={tourDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>{ setTourDate(date);
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <div className="my-2">
                <div className="text-lg mt-[1rem] mb-2">
                    <span>RackPrice:</span>

                    <InputComponent
                    name={"deltaRackPrice"}
                    inputValue={deltaRackPrice}
                    setInputValue={(val) => setdeltaRackPrice(Number(val))}
                    warningView={false}
                    type="number"
                    placeholder="New Rack Price"
                    required
                    spanValue="BDT"
                    addSpan={true}
                    />
                </div>

                
                </div>

                <div>
                <div className="text-base mb-2">Corporate Discount</div>
                <div className="flex gap-2 items-center">
                    <InputComponent
                    name={"deltaCorporatediscountAmount"}
                    warningView={false}
                    placeholder={"Corporate Rate"}
                    inputValue={deltaCorporatePrice}
                    setInputValue={(val) => {
                        handleAmountAndPercent(
                        "amount",
                        val,
                        setdeltaCorporatePrice,
                        setdeltaCorporateDiscountPercentPoint,
                        deltaRackPrice
                        );
                    }}
                    addSpan={true}
                    spanValue="BDT"
                    type="number"
                    />
                    or
                    <InputComponent
                    name={"deltaDiscountPercent"}
                    warningView={false}
                    inputValue={deltaCorporateDiscountPercentPoint}
                    setInputValue={(val) => {
                        // setDiscountPercent(val);
                        handleAmountAndPercent(
                        "percent",
                        val,
                        setdeltaCorporatePrice,
                        setdeltaCorporateDiscountPercentPoint,
                        deltaRackPrice
                        );
                    }}
                    placeholder={"Corporate discount %"}
                    type="number"
                    addSpan={true}
                    spanValue="%"
                    />
                </div>
                </div>

                <div>
                <div className="text-base mb-2">Ghuddy commision </div>
                <div className="flex gap-2 items-center">
                    <InputComponent
                    name={"deltaGhuddyCommissionAmount"}
                    warningView={false}
                    inputValue={deltaGhuddyCommissionAmount}
                    setInputValue={(val) => {
                        // setDiscount(val);
                        handleConversion(
                        "amount",
                        val,
                        setdeltaGhuddyCommissionAmount,
                        setdeltaGhuddyCommissionPercentPoint,
                        deltaCorporatePrice
                        );
                    }}
                    addSpan={true}
                    spanValue="BDT"
                    type="number"
                    />
                    or
                    <InputComponent
                    name={"deltaGhuddyCommissionPercemt"}
                    warningView={false}
                    inputValue={deltaGhuddyCommissionPercentPoint}
                    setInputValue={(val) => {
                        // setDiscountPercent(val);
                        handleConversion(
                        "percent",
                        val,
                        setdeltaGhuddyCommissionAmount,
                        setdeltaGhuddyCommissionPercentPoint,
                        deltaCorporatePrice
                        );
                    }}
                    addSpan={true}
                    spanValue="%"
                    type="number"
                    />
                </div>
                </div>
                <div className="py-4">
                Price after ghuddy commission :{deltaAfterGhuddyCommission}
                </div>
                <div>
                <div className="text-base mb-2">Shurjo commision </div>
                <div className="flex gap-2 items-center">
                    <InputComponent
                    name={"deltaShurjoCommissionAmount"}
                    warningView={false}
                    inputValue={deltaShurjoCommissionAmount}
                    setInputValue={(val) => {
                        // setDiscount(val);
                        handleConversion(
                        "amount",
                        val,
                        setdeltaShurjoCommissionAmount,
                        setdeltaShurjoCommissionPercentPoint,
                        deltaAfterGhuddyCommission
                        );
                    }}
                    addSpan={true}
                    spanValue="BDT"
                    type="number"
                    />
                    or
                    <InputComponent
                    name={"deltaShurjoCommissionPercemt"}
                    warningView={false}
                    inputValue={deltaShurjoCommissionPercentPoint}
                    setInputValue={(val) => {
                        // setDiscountPercent(val);
                        handleConversion(
                        "percent",
                        val,
                        setdeltaShurjoCommissionAmount,
                        setdeltaShurjoCommissionPercentPoint,
                        deltaAfterGhuddyCommission
                        );
                    }}
                    addSpan={true}
                    spanValue="%"
                    type="number"
                    />
                </div>
                </div>
                <div className="py-4">
                Price after shurjo commision (website black price) :{" "}
                {deltaGhuddyWebSiteBlackPrice}
                </div>

                <div className="text-base mb-2">ghuddy website Red price </div>
                <div className="flex gap-2 items-center">
                <InputComponent
                    name={"deltaGhuddyWebsiteRedPrice"}
                    warningView={false}
                    inputValue={deltaGhuddyWebSiteRedPrice}
                    setInputValue={(val) => {
                    setdeltaGhuddyWebSiteRedPrice(Number(val));
                    }}
                    addSpan={true}
                    spanValue="BDT"
                    type="number"
                />
                </div>

                <div>
                <div>Ghuddy Subsidy Amount : {deltaGhuddySubsidyAmount ? deltaGhuddySubsidyAmount.toFixed(2) : 0 } BDT</div>
                <div>Ghuddy Subsidy Percent : {deltaGhuddySubsidyPercentPoint ? deltaGhuddySubsidyPercentPoint.toFixed(2) : 0} %</div>
                <div>
                    total shurjo commission Amount : {deltaTotalShurjoCommissionAmount ? deltaTotalShurjoCommissionAmount.toFixed(3) : 0 } %
                </div>
                <div>Ghuddy total discount {deltaTotalDiscountPercentPoint ? deltaTotalDiscountPercentPoint.toFixed(2) : 0 } % </div>
                <div>
                    total ghuddy commision amount: {deltaTotalGhuddyCommissionAmount ? deltaTotalGhuddyCommissionAmount.toFixed(2) : 0 } BDT
                </div>
                <div>
                    total ghuddy commission Percent : {deltaTotalGhuddyCommissionPercent ? deltaTotalGhuddyCommissionPercent.toFixed(2) : 0 } %
                </div>

                </div>

                <div className="space-y-[10px]">
                <InputComponent
                    name={"deltaTotalSeats"}
                    inputValue={deltaTotalSeats}
                    setInputValue={(val) => setdeltaTotalSeats(Number(val))}
                    warningView={false}
                    placeholder="Total Seats"
                    type="number"
                />

                <InputComponent
                    name={"deltaBookableSeats"}
                    inputValue={deltaBookableSeats}
                    setInputValue={(val) => setdeltaBookableSeats(Number(val))}
                    warningView={false}
                    placeholder="Bookable Seats"
                    type="number"
                />

                </div>

                <div className="mt-[2rem]">
                <ButtonFilled
                    title="Submit"
                    buttonFilledHandle={handleSetFilterPrice}
                    w="100%"
                    h="49px"
                    ml={"ml-[0px]"}
                />
                </div>
            </div>

            
        </div>
    );
};

export default OptionDeltaPrice;