import React, { useState } from "react";
export const numberToArray = (day) => {
  const dayNumber = [];
  for (let i = 0; i < day; i++) {
    dayNumber.push(i + 1);
  }
  return dayNumber;
};

const DragAndDrop = ({
  keyName,
  selectedNumber,
  items,
  IdentifyName,
  setTargets,
  targets,
}) => {
  const convertedArray = numberToArray(selectedNumber);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();

    const targetId = `${keyName}${targetIndex}`;

    if (targets[targetId]?.includes(draggedItem)) {
      return;
    }

    // Update the state with the dropped item in the targets object
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      newTargets[targetId] = [...(newTargets[targetId] || []), draggedItem];
      return newTargets;
    });

    // Reset the dragged item state
    setDraggedItem(null);
  };

  const handleRemoveItem = (targetId, item) => {
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      newTargets[targetId] = newTargets[targetId].filter((i) => i !== item);

      // Check if the array length is 0, and delete the key
      if (newTargets[targetId].length === 0) {
        delete newTargets[targetId];
      }

      return newTargets;
    });
  };
  console.log("targets", targets);
  return (
    <div className="flex flex-col gap-y-[20px] ">
      <div className="min-h-[270px] overflow-y-scroll">
        <h2 className="text-[18px]">Availabile Packages :</h2>
        {/* <div className="flex justify-start gap-x-[10px]  ">
          {items?.map((item, index) => (
            <div
              draggable
              className="bg-btn-secondary px-[10px] py-[4px] rounded-[4px] text-bg-primary"
              onDragStart={(e) => handleDragStart(e, item)}
              key={index}
            >
              <p>{item}</p>
            </div>
          ))}
        </div> */}
        <div className="flex justify-between items-start gap-x-[30px]">
          {Object.keys(items)?.map((foodPackage, ind) => (
            <div
              key={ind}
              className="flex flex-col gap-y-[20px] w-full gap-x-[20px]"
            >
              {items[foodPackage]?.map((food, index) => (
                <div
                  draggable
                  onDragStart={(e) => handleDragStart(e, food)}
                  key={index}
                  className="shadow-customShadow w-full rounded-[6px] text-txt-primary p-[6px]"
                >
                  <p className="text-center ">
                    {food?.meal_type_name} Menu {index + 1}
                  </p>
                  <p>
                    {/* {food?.food_items?.map((foodItm, ind) => (
                      <span key={ind}>{foodItm}</span>
                    ))} */}

                    {Object.keys(food?.food_items)?.map((foodItm) => (
                      <span className=" gap-x-[6px] ">
                        {food?.food_items[foodItm]}{" "}
                      </span>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="max-h-[250px] overflow-y-scroll">
        {convertedArray?.map((index) => (
          <div
            key={index}
            id={`${keyName}${index}`}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, index)}
            className="shadow-customShadow p-[10px] rounded-[4px]"
          >
            <div className="text-[16px] bg-btn-secondary text-bg-white p-[4px] text-center">
              {" "}
              {IdentifyName} {keyName} {index}
            </div>
            <ul className="mt-[10px] flex flex-row gap-[10px] ">
              {targets[`${keyName}${index}`]?.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                  className="flex justify-start gap-[10px] flex-row shadow-customShadow p-[10px] relative w-[200px]"
                >
                  {/* {item} */}
                  {/* {item?.meal_type_name} */}
                  <div
                    // draggable
                    // onDragStart={(e) => handleDragStart(e, food)}
                    key={index}
                    className=" w-full rounded-[6px] text-txt-primary p-[6px]"
                  >
                    <p className="text-center ">{item?.meal_type_name}</p>
                    <p>
                      {/* {food?.food_items?.map((foodItm, ind) => (
                      <span key={ind}>{foodItm}</span>
                    ))} */}

                      {Object.keys(item?.food_items)?.map((foodItm) => (
                        <span className=" gap-x-[6px] ">
                          {item?.food_items[foodItm]}{" "}
                        </span>
                      ))}
                    </p>
                  </div>
                  <div className="absolute top-[-6px] right-[-6px]">
                    <button
                      className="border rounded-full  w-[25px] h-[25px] border-btn-pink text-btn-pink"
                      onClick={() =>
                        handleRemoveItem(`${keyName}${index}`, item)
                      }
                    >
                      X
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DragAndDrop;
