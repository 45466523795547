import React, { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_EXCURSION_ENDPOINT } from "../../contants/Endpoints";
import Cancellation from "../../components/Cancellation";
import Loading from "../../components/Loader/Loading";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { changeUrl } from "../utils/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { merchantExcursionRoute } from "../../contants/Constants";

const CancellationPolicy = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const [policy, setPolicy] = useState({
    100: {
      to: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      unit: "days",
      is100: false,
      isActive: false,
      policy: 100,
      timeInHour: null,
    },
    75: {
      to: "",
      unit: "days",
      is75: false,
      isActive: false,
      policy: 75,
      timeInHour: null,
    },
    50: {
      to: "",
      unit: "hours",
      is50: false,
      isActive: false,
      policy: 50,
      timeInHour: null,
    },
    25: {
      to: "",
      unit: "hours",
      is25: false,
      isActive: false,
      policy: 25,
      timeInHour: null,
    },
    0: {
      to: "",
      unit: "hours",
      is0: false,
      isActive: false,
      policy: 0,
      timeInHour: null,
    },
  });
  const [addedPolicy, setAddedPolicy] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));

  const getCancellation = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_GET_CANCELLATION_POLICY_BY_ID}?excursionId=${excursionID}&requestId=123`
      )
      .then((response) => {
        setAddedPolicy(response?.data?.terms);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "cancell1212");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCancellation();
  }, []);

  const backAndSave = () => {
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
  };

  const filteredValues = Object.values(policy)
    .filter(
      (item) =>
        item.hasOwnProperty("policy") &&
        item.policy !== undefined &&
        item.hasOwnProperty("timeInHour") &&
        item.timeInHour !== undefined
    )
    .map(({ policy, timeInHour }) => ({ refundPercent: policy, timeInHour }))
    .filter((item) => {
      return item?.timeInHour > 0;
    });

  const payloadMethod = {
    excursionId: excursionID,
    policyData: filteredValues,
    requestId: "123",
  };

  const onSubmitButton = () => {
    authAxios
      .post(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_EXCURSION_POST_CANCELLATION_POLICY}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Successfully Added Cancellation policy.");
        navigate(`${merchantExcursionRoute?.EXCURSION_DASHBOARD}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <div>
      <div>CancellationPolicy</div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div>
          <Cancellation
            policy={policy}
            setPolicy={setPolicy}
            validationError={validationError}
            setValidationError={setValidationError}
            addedPolicy={addedPolicy}
          />
          <div className="flex justify-center mt-[40px]">
            <CustomButtonGroupExcruions
              setSaveAndNext={backAndSave}
              onSubmit={onSubmitButton}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CancellationPolicy;
