
const transformOptionAccommodationData = (inputData) => {
  let transformedData = [];

    inputData?.option?.forEach((option) => {
        let accommodationPackages = [];

        Object?.keys(option)?.forEach((nightKey) => {
            // Extracting the night number using regular expressions
            let nightNumber = parseInt(nightKey?.match(/\d+/)[0]);

            let nightData = option[nightKey][0];

            if (nightData?.per_night_room_price !== undefined && nightData?.tour_package_accommodation_package_id !== undefined) {
              let accommodationPackage = {
                  night_number: nightNumber,
                  per_night_room_price: nightData?.per_night_room_price,
                  tour_package_accommodation_package_id: nightData?.tour_package_accommodation_package_id
              };

              accommodationPackages?.push(accommodationPackage);
          }
        });

        transformedData?.push({
            tour_package_accommodation_packages: accommodationPackages
        });
    });
    return transformedData;
};


const transformOptionFoodData = (data) => {
  const optionData = data?.option;
  const result = [];

  optionData?.forEach((dayOptions) => {
    const tourPackageMealPackages = {};

    Object.keys(dayOptions)?.forEach((day) => {
      const dayNumber = day?.replace('Day ', '');
      const meals = dayOptions[day]?.map((meal) => ({
        per_meal_package_price: meal?.per_meal_package_price,
        tour_package_meal_package_id: meal?.meal_package_id,
      }));
      tourPackageMealPackages[dayNumber] = meals;
    });

    result.push({ tour_package_meal_packages: tourPackageMealPackages });
  });


  return result;
};

const transformNewGuideData = (inputData)=> {
  return Object.entries(inputData)?.map(([key, value]) => {
      return {
          tour_package_guide_packages: value?.map(item => {
              return {
                  day_number: item?.day_number,
                  tour_package_guide_package_id: item?.tour_package_guide_package_id,
                  tour_package_guide_package_price: item?.total_guide_price_for_day
              };
          })
      };
  });
}

const transformNewTransportationData = (inputData) => {
  return Object?.entries(inputData)?.map(([key, value]) => {
      return value.map(item => {
          return {
              tour_package_transportation_package_id: item.tour_package_transportation_package_id,
              transportation_package_unit_price: item.transportation_package_unit_price
          };
      });
  }).flat();
}

const transformNewTransferData = (data) => {
  const transformedData = [];

  for (const day in data) {
    const dayData = data[day];
    const transferPackages = [];
    dayData?.forEach((item) => {
      const transferPackage = {
        per_vehicle_per_trip_price: item.per_vehicle_per_trip_price,
        tour_package_transfer_package_id: item.tour_package_transfer_package_id,
      };
      transferPackages.push(transferPackage);
    });
    transformedData.push({ tour_package_transfer_packages: transferPackages });
  }
  return transformedData;
};

// const transformNewSpotEntryData = (data) => {
//   const spotEntryPackages = [];

//   for (const day in data) {
//     const dayData = data[day];
//     dayData?.forEach((item) => {
//       const spotEntryPackage = {
//         spot_entry_price_per_person: item?.spot_entry_price_per_person,
//         tour_package_spot_entry_package_id: item?.spot_entry_package_id,
//       };
//       spotEntryPackages.push(spotEntryPackage);
//     });
//   }

//   return [{ tour_package_spot_entry_packages: spotEntryPackages }];
// };

const transformNewSpotEntryData = (data) => {
  const result = [];

  for (const day in data) {
    const dayData = data[day];
    const spotEntryPackages = [];

    dayData.forEach((item) => {
      const spotEntryPackage = {
        tour_package_spot_entry_package_id: item.spot_entry_package_id,
        spot_entry_price_per_person: item.spot_entry_price_per_person,
      };
      spotEntryPackages.push(spotEntryPackage);
    });

    result.push({ tour_package_spot_entry_packages: spotEntryPackages });
  }

  return result;
};


const dateFormatter = (inputDate) =>  {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export {
  transformOptionFoodData,
  transformOptionAccommodationData,
  transformNewTransferData,
  transformNewTransportationData,
  transformNewGuideData,
  transformNewSpotEntryData,
  dateFormatter
};
