import React from "react";
import { CiEdit } from "react-icons/ci";
import bed_2 from "../../assets/icon/bed_2.svg";

const AccomodationPackageInititial = ({
  tour_package_accommodation_packages,
  handlePackage,
}) => {
  //   console.log(tour_package_accommodation_packages);

  return (
    <>
      <div className="w-full flex xl:p-[16px] md:p-[8px] p-[4px]   bg-bg-white rounded">
        <div className="w-[8%]  flex items-center justify-center ">
          <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee]">
            <img
              className="xl:w-16 md:w-12 w-8 xl:h-16 md:h-12 h-[32px]"
              src={bed_2}
              alt=""
            />
          </span>
        </div>

        <div className="w-[84%] xl:px-[12px] lg:px-[10px] md:px-[8px] px-[6px] flex flex-col items-start  xl:text-[14px] lg:text-[14px] md:text-[12px] text-[10px]">
          <div className="bg-btn-secondary  text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] mb-[8px] rounded">
            {"Accomodation"}
          </div>
          <div className="flex flex-col gap-y-[8px] w-full">
            {/* <div className=" flex flex-wrap w-full gap-[16px]">
              {tour_package_accommodation_packages &&
                tour_package_accommodation_packages?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between w-[220px]  rounded p-[8px] shadow-customShadow overflow-clip relative"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[10px] left-[-55px] rotate-[-45deg] ${
                        item?.accommodation_package_is_default?.value
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <p className="text-[10px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>
                    <p className="bg-[#e1e5ed] text-btn-secondary xl:text-[14px] text-[12px] text-center px-[4px] py-[2px] rounded mb-[8px]">
                      {item?.accommodation_id?.label}
                    </p>

                    <div className="xl:text-[12px] text-[10px]">
                      <p className="text-txt-primary">
                        {" "}
                        <span className="text-bg-semiblack">
                          Room Type:
                        </span>{" "}
                        {item?.room_type_id?.label}{" "}
                      </p>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">
                          Bed Configuration:{" "}
                        </span>
                        {item?.number_of_beds?.label} {item?.bed_configuration}{" "}
                        suitable for {item?.suitable_for_persons?.label} persons{" "}
                      </p>
                      <div className="flex justify-between">
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Shareable: </span>
                          {item?.is_shareable?.label}
                        </p>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Included: </span>
                          {item?.is_shareable?.label}
                        </p>
                      </div>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">
                          Price per quantity:{" "}
                        </span>
                        {item?.accommodation_package_unit_price}৳ X{" "}
                        {item?.accommodation_package_quantity}
                      </p>
                      <p className="py-[4px] xl:text-[16px] text-[14px] text-center">
                        Total Price: {item?.accommodation_package_total_price}৳
                      </p>
                    </div>
                  </div>
                ))}
            </div> */}
            {tour_package_accommodation_packages?.map((tourPackage, index) => (
              <div className="shadow-customShadow p-[10px] rounded-[4px] w-full flex items-center justify-between">
                <p>Option {index + 1}</p>
                {/* <button
                  onClick={() => {
                    handlePackage(1);
                  }}
                  className="cursor-pointer text-[#22c55e] "
                  title="Edit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[30px] h-[40px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </button> */}
              </div>
            ))}
          </div>
        </div>

        <div className="w-[8%] flex items-center text-[30px]">
          <div className="flex">
            {/* <button
              className="cursor-pointer text-[#ef4444] mr-[8px]"
              title="Remove"
            >
              <CiCircleRemove></CiCircleRemove>
            </button> */}
            <button
              onClick={() => handlePackage(1, "acco")}
              className="cursor-pointer text-[#22c55e]"
              title="Edit"
            >
              <CiEdit></CiEdit>
            </button>
          </div>
        </div>
      </div>
      <div className={`${"xl:h-[32px] md:h-[24px] h-[16px]"}`}></div>
    </>
  );
};

export default AccomodationPackageInititial;
