import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../components/common/ModalCommon";
import { BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import SecondaryNav from "./admin/comp/SecondaryNav";
import BookingReviewCart, { color } from "./BookingReviewCart";
import "./taskManager.css";

const BookingReview = () => {
  const [bookingNewData, setBookingNewData] = useState();
  const [bookingInprogress, setBookingInprogress] = useState();
  const [bookingCompleted, setBookingCompleted] = useState();
  const [bookingCancel, setBookingCancel] = useState();
  const [singleBooking, setSingleBooking] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [pageCountP, setPageCountP] = useState(1);
  const [pageCountCom, setPageCountCom] = useState(1);
  const [pageCountCan, setPageCountCan] = useState(1);
  const [modalShown, setToggleModal] = useState(false);
  const [modalShown2, setToggleModal2] = useState(false);
  const [removeCondition, setRemoveCondition] = useState(false);
  const [reason, setReason] = useState("");
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingInprogress, setLoadingInprogress] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const getInprogressBooking = () => {
    let url = `${
      BOOKING_ENDPOINTS.GET_INPROGRESS_RENTAL_REVIEWS
    }pageNumber=1&pageSize=${pageCountP * 10}&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingInprogress(res.data);
        setLoadingInprogress(false);
      })
      .catch((err) => {
        //
      });
  };

  const getBooking = () => {
    let url = `${
      BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
    }pageNumber=1&pageSize=${pageCount * 10}&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingNewData(res.data);
        setLoadingNew(false);
      })
      .catch((err) => {
        //
      });
  };

  const getAcceptedBooking = () => {
    let url = `${BOOKING_ENDPOINTS.GET_ACCEPTED_BOOKING}pageNumber=1&pageSize=${
      pageCountCom * 10
    }&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingCompleted(res.data);
        setLoadingConfirm(false);
      })
      .catch((err) => {
        //
      });
  };

  const getBookingRejected = () => {
    let url = `${BOOKING_ENDPOINTS.GET_REJECTED_BOOKING}pageNumber=1&pageSize=${
      pageCountCan * 10
    }&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingCancel(res.data);
        setLoadingCancel(false);
      })
      .catch((err) => {
        //
      });
  };
  useEffect(() => {
    setLoadingConfirm(true);
    getAcceptedBooking();
  }, [pageCountCom]);

  useEffect(() => {
    setLoadingInprogress(true);
    getInprogressBooking();
  }, [pageCountP]);

  useEffect(() => {
    setLoadingNew(true);
    getBooking();
  }, [pageCount]);

  useEffect(() => {
    setLoadingCancel(true);
    getBookingRejected();
  }, [pageCountCan]);

  const handleInProgressState = (id, state) => {
    if (state) {
      let url = `${BOOKING_ENDPOINTS.SET_INPROGRESS_RENTAL_REVIEWS}${id}&requestId=11`;
      authAxios.post(url).then((res) => {
        getInprogressBooking();
        getBooking();
      });
    }
    let url = `${BOOKING_ENDPOINTS.GET_SINGLE_BOOKING}${id}&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setSingleBooking(res?.data?.rentalBookingData);
      })
      .catch((err) => {
        //
      });
    setToggleModal(!modalShown);
  };

  const handleConfirm = (id) => {
    let url = `${BOOKING_ENDPOINTS.CONFIRM_BOOKING}${id}&requestId=12W`;
    authAxios.put(url).then((data) => {
      console.log("data", data);
      setToggleModal(!modalShown);
      toast.success("successfully Accepted!");
      getAcceptedBooking();
      getInprogressBooking();
    });
  };

  const handleRejected = (id) => {
    setRemoveCondition(true);
    if (reason?.length === 0) {
      setToggleModal2(true);
    }
    if (reason?.length > 2) {
      authAxios
        .put(
          `${BOOKING_ENDPOINTS.REJECTED_BOOKING}note=${reason}&rentalBookingReviewId=${id}&requestId=12W`
        )
        .then((data) => {
          setToggleModal(!modalShown);
          toast.success("successfully Rejected!");
          setReason("");
          getBookingRejected();
          setToggleModal2(false);
          getInprogressBooking();
          setRemoveCondition(false);
        });
    }
  };
  console.log("singleBooking", pageCountCom * 10);
  return (
    <div>
      {process.env.REACT_APP_USER_TYPE === "USER" && <SecondaryNav />}
      <div
        className={`app ${
          process.env.REACT_APP_USER_TYPE === "USER" && "mt-10"
        } `}
      >
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-4 mb-4 gap-x-2">
            <div className="col-span-1">
              Select Hotel
              {/* <RoundedDropdownButton
                selectHandler={changeHotel}
                dropdownData={[{ propertyName: "All", id: null }, ...hotelList]}
                title={`${selectedHotel?.propertyName || "All"}`}
                w="full"
                h="49px"
                selector={"propertyName"}
              /> */}
            </div>
          </div>
          {/* main wraper area */}
          <div className="grid grid-cols-4 w-[1300px] gap-[16px]">
            {/* new card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow ">
              {/* heading area */}
              <div
                className={` ${color.NEW} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">New</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingNewData?.rentalBookingReviewData?.map((booking) => (
                    <BookingReviewCart
                      booking={booking}
                      handleInProgressState={handleInProgressState}
                      status={true}
                      dotView={true}
                    />
                  ))}
                </div>
                {loadingNew ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCount((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingNewData?.totalEntries <= pageCount * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* In Progress card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.IN_PROGRESS} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">In Progress</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingInprogress?.rentalBookingReviewData?.map(
                    (booking) => (
                      <BookingReviewCart
                        booking={booking}
                        handleInProgressState={handleInProgressState}
                        status={false}
                        dotView={true}
                      />
                    )
                  )}
                </div>
                {loadingInprogress ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountP((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingInprogress?.totalEntries <= pageCountP * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* Confirmed card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.ACCEPTED} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">Confirmed</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingCompleted?.rentalBookingReviewData?.map((booking) => (
                    <BookingReviewCart booking={booking} dotView={false} />
                  ))}
                </div>
                {loadingConfirm ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountCom((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingCompleted?.totalEntries <= pageCountCom * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* Cancelled card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.REJECTED} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">Cancelled</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingCancel?.rentalBookingReviewData?.map((booking) => (
                    <BookingReviewCart booking={booking} dotView={false} />
                  ))}
                </div>
                {loadingCancel ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountCan((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingCancel?.totalEntries <= pageCountCan * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalCommon
          shown={modalShown}
          width="max-w-[1200px]"
          height="min-h-[700px]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
            setRemoveCondition(false);
            setToggleModal2(false);
            setReason("");
          }}
        >
          <div className="px-[43px] ">
            <h2 className="mt-[29px] text-[20px]">Booking Details</h2>

            <div className="flex justify-between items-center mt-[23px] text-[12px]">
              <div className="w-full">
                {/* <p>Assigned To:</p> */}

                {/* <div className="mt-[20px]  flex justify-start gap-x-[15px]">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      viewBox="0 0 55 55"
                      fill="none"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="54"
                        height="54"
                        rx="27"
                        fill="#FEFCF5"
                      />
                      <path
                        d="M26.724 20V25.859H21V27.938H26.724V33.77H29.019V27.938H34.77V25.859H29.019V20H26.724Z"
                        fill="#376576"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="54"
                        height="54"
                        rx="27"
                        stroke="#376576"
                      />
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55px"
                      height="55px"
                      viewBox="0 0 55 55"
                      fill="none"
                    >
                      <circle cx="27.5" cy="27.5" r="27.5" fill="#FEFCF5" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M46.627 47.2588C51.7903 42.2595 55 35.2546 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 34.8218 2.86136 41.4752 7.52686 46.4031C7.6072 46.0377 7.69673 45.7386 7.79545 45.506C8.28788 44.752 9.32955 44.0278 10.9205 43.3333C11.072 43.254 12.3172 42.8175 14.6562 42.0238C16.9953 41.2302 18.1932 40.8234 18.25 40.8036C19.5568 40.3075 20.4138 39.9256 20.821 39.6577C21.2282 39.3899 21.536 39.0179 21.7443 38.5417L22.3977 37.1131C22.4924 36.6171 22.3788 36.2103 22.0568 35.8929C19.5379 33.5119 18.2879 30.2877 18.3068 26.2202C18.3068 22.9464 19.0739 20.2579 20.608 18.1548C22.142 16.0516 24.2727 15 27 15C29.7273 15 31.858 16.0516 33.392 18.1548C34.9261 20.2579 35.6932 22.9464 35.6932 26.2202C35.7121 30.2877 34.4621 33.5119 31.9432 35.8929C31.6212 36.2103 31.5076 36.6171 31.6023 37.1131L32.2557 38.5417C32.464 39.0179 32.7718 39.3899 33.179 39.6577C33.5862 39.9256 34.4432 40.3075 35.75 40.8036C35.8068 40.8234 37.0047 41.2302 39.3438 42.0238C41.6828 42.8175 42.928 43.254 43.0795 43.3333C44.6705 44.0278 45.7121 44.752 46.2045 45.506C46.3717 45.9 46.5125 46.4843 46.627 47.2588Z"
                        fill="#376576"
                      />
                    </svg>
                  </div>
                </div> */}
              </div>
              <div className="w-full flex justify-between items-center">
                <p className="w-[32%]">
                  Status: <span className="text-[#FF9500]"> In Progress</span>{" "}
                </p>
                <p className="border bg-[#FF9500] text-bg-white border-[#FF9500] w-[68%] rounded-[4px] px-[14px] py-[2px] flex justify-between gap-x-[8px]">
                  Total Amount:{" "}
                  <span className=""> {singleBooking?.totalAmount} BDT</span>{" "}
                </p>

                {/* <div className="flex justify-start items-center gap-x-[32px]">
                  <div className="flex justify-center items-center gap-x-[25px]">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <path
                          d="M42.9394 13.0209V42.1209C42.9394 43.2809 42.3594 44.3109 41.4794 44.9309C40.9294 45.3309 40.2394 45.5509 39.5094 45.5509H14.5394C13.8094 45.5509 13.1294 45.3209 12.5694 44.9309C11.6894 44.3109 11.1094 43.2909 11.1094 42.1209V7.88094C11.1094 6.72094 11.6894 5.69094 12.5794 5.07094C13.1294 4.69094 13.7994 4.46094 14.5294 4.46094H34.6394L42.9394 13.0209Z"
                          fill="#EAEAEA"
                        />
                        <path
                          d="M42.9367 13.0209H36.9267C35.6667 13.0209 34.6367 12.0009 34.6367 10.7309V4.46094L42.9367 13.0209Z"
                          fill="#A8A8A8"
                        />
                        <path
                          d="M38.8494 23.25V35.93C38.8494 36.57 38.3294 37.09 37.6894 37.09H11.1094V23.25H38.8494Z"
                          fill="#D4D4D6"
                        />
                        <path
                          d="M38.8486 23.2004V34.5104C38.8486 35.3604 38.1586 36.0604 37.2986 36.0604H8.60859C7.75859 36.0604 7.05859 35.3704 7.05859 34.5104V23.2004C7.05859 22.3504 7.74859 21.6504 8.60859 21.6504H37.3086C38.1586 21.6504 38.8486 22.3404 38.8486 23.2004Z"
                          fill="#F15642"
                        />
                        <path
                          d="M14.2969 32.18V25.5H16.4569C17.2769 25.5 17.8069 25.53 18.0569 25.6C18.4369 25.7 18.7569 25.92 19.0169 26.25C19.2769 26.59 19.4069 27.02 19.4069 27.55C19.4069 27.96 19.3369 28.3 19.1869 28.58C19.0369 28.86 18.8469 29.08 18.6169 29.24C18.3869 29.4 18.1569 29.5 17.9169 29.56C17.5969 29.62 17.1269 29.66 16.5169 29.66H15.6369V32.18H14.2969ZM15.6469 26.63V28.53H16.3869C16.9169 28.53 17.2769 28.5 17.4569 28.43C17.6369 28.36 17.7769 28.25 17.8769 28.1C17.9769 27.95 18.0269 27.78 18.0269 27.58C18.0269 27.34 17.9569 27.14 17.8169 26.98C17.6769 26.82 17.4969 26.72 17.2769 26.68C17.1169 26.65 16.7969 26.63 16.3069 26.63H15.6469Z"
                          fill="#EAEAEA"
                        />
                        <path
                          d="M20.5156 25.5H22.9856C23.5456 25.5 23.9656 25.54 24.2556 25.63C24.6456 25.75 24.9856 25.95 25.2656 26.25C25.5456 26.54 25.7556 26.91 25.9056 27.33C26.0556 27.76 26.1256 28.28 26.1256 28.91C26.1256 29.46 26.0556 29.93 25.9156 30.33C25.7456 30.82 25.5056 31.21 25.1956 31.51C24.9656 31.74 24.6456 31.92 24.2456 32.04C23.9456 32.13 23.5456 32.18 23.0556 32.18H20.5156V25.5ZM21.8656 26.63V31.05H22.8756C23.2556 31.05 23.5256 31.03 23.6956 30.99C23.9156 30.94 24.0956 30.84 24.2356 30.71C24.3756 30.58 24.4956 30.36 24.5856 30.07C24.6756 29.77 24.7256 29.36 24.7256 28.85C24.7256 28.33 24.6756 27.94 24.5856 27.66C24.4956 27.38 24.3656 27.17 24.2056 27.01C24.0456 26.86 23.8356 26.75 23.5856 26.7C23.3956 26.66 23.0256 26.64 22.4756 26.64H21.8656V26.63Z"
                          fill="#EAEAEA"
                        />
                        <path
                          d="M27.2656 32.18V25.5H31.8456V26.63H28.6156V28.21H31.4056V29.34H28.6156V32.18H27.2656Z"
                          fill="#EAEAEA"
                        />
                        <path
                          d="M38.2383 13.0215L42.9383 17.8615V13.0215H38.2383Z"
                          fill="#D4D4D6"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect
                          x="8"
                          y="18.7109"
                          width="33.8313"
                          height="17.7831"
                          rx="2.4"
                          fill="#4E90A8"
                        />
                        <mask id="path-2-inside-1_6268_17742" fill="white">
                          <rect
                            x="12.7734"
                            y="24.3496"
                            width="24.2892"
                            height="18.6506"
                            rx="2.4"
                          />
                        </mask>
                        <rect
                          x="12.7734"
                          y="24.3496"
                          width="24.2892"
                          height="18.6506"
                          rx="2.4"
                          fill="#FEFCF5"
                          stroke="#4E90A8"
                          stroke-width="6"
                          mask="url(#path-2-inside-1_6268_17742)"
                        />
                        <path
                          d="M24.699 17.8438L29.3943 12.9642H20.0037L24.699 17.8438Z"
                          fill="#4E90A8"
                        />
                        <rect
                          x="23.6133"
                          y="7"
                          width="2.16867"
                          height="6.07229"
                          fill="#4E90A8"
                        />
                        <rect
                          x="12.7734"
                          y="13.0723"
                          width="2.16867"
                          height="5.63855"
                          fill="#4E90A8"
                        />
                        <rect
                          x="34.8906"
                          y="13.0723"
                          width="2.16867"
                          height="5.63855"
                          fill="#4E90A8"
                        />
                      </svg>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <path
                          d="M35.9887 29.8828C36.1252 29.514 20.4556 17.6822 20.4556 17.6822C18.4938 16.2345 17.4621 16.2118 15.5222 17.6822C15.5222 17.6822 -0.124625 29.5044 0.00707031 29.8681L0.00390625 29.8708C0.00552344 29.874 0.008125 29.8761 0.0103047 29.879C0.14298 30.2407 0.336003 30.5773 0.581172 30.8744C0.684742 31 0.809547 31.1052 0.92957 31.2145L0.923734 31.219C0.933297 31.2276 0.946023 31.2315 0.955586 31.24C1.4643 31.6938 2.10583 31.9877 2.81851 32.0716C2.82969 32.0729 2.83869 32.0788 2.84987 32.0801H2.89291C3.00126 32.0904 3.10426 32.1164 3.21522 32.1164H32.7972C34.2538 32.1164 35.4879 31.2004 35.9782 29.9163C35.984 29.9067 35.993 29.8998 35.9983 29.89L35.9887 29.8828Z"
                          fill="#376576"
                        />
                        <path
                          d="M18.0098 16.3252L0.0859375 29.7681V10.724C0.0859375 8.86776 1.59041 7.36328 3.44666 7.36328H32.5729C34.4292 7.36328 35.9336 8.86776 35.9336 10.724V29.7681L18.0098 16.3252Z"
                          fill="#4E90A8"
                        />
                        <path
                          d="M0.292969 9.55471L15.5961 21.5746C17.5276 22.9999 18.5435 23.0224 20.4535 21.5746L35.7327 9.57377C35.4676 9.09325 35.0707 8.71363 34.6169 8.41094L34.827 8.24627C34.3141 7.77932 33.6584 7.47662 32.9306 7.39844L3.08613 7.39949C2.36037 7.47663 1.70365 7.78038 1.19177 8.24627L1.40039 8.4089C0.951797 8.70737 0.558609 9.08045 0.292969 9.55471Z"
                          fill="#FEFCF5"
                        />
                        <path
                          d="M0.290672 9.58016C0.156305 9.94333 15.5845 21.5927 15.5845 21.5927C17.516 23.018 18.5318 23.0405 20.4419 21.5927C20.4419 21.5927 35.8476 9.95275 35.7178 9.59458L35.721 9.59198C35.7194 9.58881 35.7168 9.58677 35.7147 9.58389C35.5841 9.22778 35.3941 8.89639 35.1527 8.6038C35.0507 8.48012 34.9278 8.37663 34.8097 8.26891L34.8154 8.26448C34.806 8.25611 34.7935 8.25217 34.7841 8.2438C34.2832 7.79704 33.6516 7.50763 32.9499 7.42502C32.9389 7.42368 32.93 7.41798 32.919 7.41665H32.8767C32.7701 7.40645 32.6686 7.38086 32.5593 7.38086H3.43308C1.99884 7.38086 0.783703 8.28283 0.301148 9.54712C0.295383 9.55654 0.286523 9.56336 0.28125 9.57299L0.290672 9.58016Z"
                          fill="#FEE29A"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="px-[20px] w-[315px] border border-btn-secondary rounded-full h-[41px] flex justify-center items-center">
                    <div className="flex justify-between gap-x-[219px] items-center">
                      <p>New</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <path
                          d="M2.5 5L6.5 9L10.5 5"
                          stroke="#376576"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="mt-[20px] flex justify-between flex-row gap-[18px]">
              <div className="flex flex-col gap-[10px] w-[60%]">
                {/* hotel details */}
                <div className="flex px-[13px] py-[10px] shadow-customShadow w-full rounded-[18px]">
                  <div className="flex flex-col gap-y-[16px]">
                    <h1 className=" text-[20px]">
                      {singleBooking?.propertyName}
                    </h1>
                    <p className="text-[14px]">
                      {singleBooking?.propertyAddress}
                    </p>
                    <div className="flex gap-x-[15px]">
                      <div className="flex gap-x-[5px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21.2831 8.27657L15.3323 7.41173L12.6722 2.01876C12.5995 1.8711 12.48 1.75157 12.3323 1.67891C11.962 1.4961 11.512 1.64845 11.3268 2.01876L8.66669 7.41173L2.7159 8.27657C2.55184 8.30001 2.40184 8.37735 2.28699 8.49454C2.14815 8.63724 2.07165 8.82923 2.07428 9.02831C2.07692 9.22739 2.15849 9.41728 2.30106 9.55626L6.60653 13.7539L5.58934 19.6813C5.56549 19.8191 5.58075 19.961 5.63338 20.0906C5.68602 20.2203 5.77394 20.3326 5.88716 20.4148C6.00038 20.497 6.13437 20.5459 6.27395 20.5558C6.41353 20.5658 6.5531 20.5364 6.67684 20.4711L11.9995 17.6727L17.3222 20.4711C17.4675 20.5484 17.6362 20.5742 17.7979 20.5461C18.2058 20.4758 18.48 20.0891 18.4097 19.6813L17.3925 13.7539L21.6979 9.55626C21.8151 9.44142 21.8925 9.29142 21.9159 9.12735C21.9792 8.7172 21.6933 8.33751 21.2831 8.27657Z"
                            fill="#FEE29A"
                          />
                        </svg>
                        <span className="text-[12px]">4.9</span>
                      </div>
                      <p className="text-[12px]">(144 Reviews)</p>
                    </div>

                    <div className="mt-[5px]">
                      <p className="flex justify-start gap-x-[20px]">
                        <span className="text-[16px]">Category : </span>
                        <span className="text-[16px]">
                          {singleBooking?.propertyType}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* user contact */}
                <div className="flex flex-row gap-x-[5px] px-[13px] py-[5px] shadow-customShadow w-full rounded-[18px]">
                  <div className="p-[16px] text-[14px] flex flex-col gap-y-[12px]">
                    <p className="flex flex-row gap-x-[5px]">
                      {" "}
                      <span>Contact</span> <span>Name</span> :
                    </p>
                    {/* <p> Quantity:</p> */}

                    <p> Email:</p>

                    <p> Number:</p>
                  </div>
                  <div className="p-[16px] text-[14px] flex flex-col gap-y-[12px] ">
                    <p>{singleBooking?.personName}</p>
                    {/* <p> {singleBooking?.roomBookings?.length}</p> */}
                    <p>{singleBooking?.personEmail}</p>
                    <p>{singleBooking?.personPhoneNumber}</p>
                  </div>
                </div>

                {/* total cost */}
                {/* <div className=" px-[13px] py-[5px] shadow-customShadow w-full rounded-[18px] text-[12px] ">
                  <div className="flex flex-row gap-x-[10px] justify-start items-center">
                    <div className="w-[70%] p-[10px] text-[12px] flex flex-col justify-end items-end ml-auto gap-y-[12px]">
                      <p>Total Amount:</p>
                      <p>Long Stay Discount:</p>
                    </div>
                    <div className="w-[30%] px-[10px] text-[12px] flex flex-col justify-end items-end ml-auto gap-y-[12px]">
                      <p>{singleBooking?.totalAmount} BDT</p>
                      <p>
                        {singleBooking?.totalAmount -
                          singleBooking?.totalPayableAmount ===
                        0
                          ? ""
                          : "-"}{" "}
                        {singleBooking?.totalPayableAmount -
                          singleBooking?.totalAmount}{" "}
                        BDT
                      </p>
                    </div>
                  </div>
                  <div className="w-full h-[1px] bg-bg-semiblack"></div>
                  <div className="mt-[10px] flex justify-end gap-x-[32px] px-[10px]">
                    <span className="w-[70%] flex justify-end"> Total :</span>{" "}
                    <span className="w-[30%] flex justify-end">
                      {singleBooking?.totalPayableAmount} BDT
                    </span>{" "}
                  </div>
                </div> */}
              </div>

              <div className="flex  justify-end items-start w-[35%]">
                {/* extra facility */}
                {/* <div className="flex flex-row gap-x-[10px] px-[13px] py-[20px] shadow-customShadow w-[400px] rounded-[18px]">
                  <div className="p-[16px] text-[16px] flex flex-col gap-y-[22px]">
                    <p className="flex flex-row  justify-start gap-x-[10px] items-center">
                      {" "}
                      <span className="w-[16px] h-[16px] border rounded-[4px] border-btn-primary">
                        {" "}
                      </span>
                      <span>Transfer</span>
                    </p>
                    <p className="flex flex-row  justify-start gap-x-[10px] items-center">
                      {" "}
                      <span className="w-[16px] h-[16px] border rounded-[4px] border-btn-primary">
                        {" "}
                      </span>
                      <span>Car for Rent</span>
                    </p>

                    <p className="flex flex-row  justify-start gap-x-[10px] items-center">
                      {" "}
                      <span className="w-[16px] h-[16px] border rounded-[4px] border-btn-primary">
                        {" "}
                      </span>
                      <span>Extra Cleaning</span>
                    </p>
                  </div>
                </div> */}
                {/* time condition */}
                <div className="px-[12px] gap-[10px] py-[10px]  w-[420px] max-h-[480px] rounded-[18px] flex flex-col overflow-y-scroll">
                  {singleBooking?.roomBookings?.map((booking) => (
                    <div className="flex flex-row shadow-customShadow w-full gap-x-[30px] px-[13px] py-[8px]  max-h-[130px] rounded-[10px]">
                      <div className=" text-[12px] flex flex-col gap-y-[12px]">
                        <p className="flex flex-row gap-x-[5px]">
                          {" "}
                          <span>Check </span> <span>In:</span>
                        </p>
                        <p className="flex flex-row gap-x-[5px]">
                          {" "}
                          <span>Check </span> <span>Out:</span>
                        </p>

                        <p> Duration:</p>
                        <p>Category : </p>
                      </div>
                      <div className=" text-[12px] flex flex-col gap-y-[12px]">
                        <p>{booking?.checkinDate}</p>
                        <p> {booking?.checkoutDate}</p>
                        <p>{booking?.numberNights} Night</p>
                        <p>
                          {booking?.roomCategoryMetaData?.categoryName}{" "}
                          <span className="text-[12px] text-[#FF9500]">
                            x{booking?.noOfTimes}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-[30px] absolute bottom-[20px] right-[30px] mb-[5px] flex justify-end items-end gap-x-[30px] ">
              {modalShown2 && (
                <div className="w-[380px] absolute bottom-[60px]">
                  <textarea
                    className="border w-full min-h-[100px] max-h-[100px]  py-[6px] px-[12px] rounded-[8px] border-btn-border"
                    onChange={(e) => setReason(e.target.value)}
                    type="text"
                    placeholder="Type your reason"
                  ></textarea>
                  <div className="flex justify-center items-center gap-x-[20px]">
                    {/* <button
                      onClick={() => handleRejectedReason()}
                      className="w-[80px] bg-btn-secondary text-bg-primary rounded-[4px] cursor-pointer"
                    >
                      Done
                    </button> */}
                    {/* <button
                      onClick={() => setToggleModal2(false)}
                      className="w-[80px] text-bg-primary rounded-[4px] bg-btn-orange"
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              )}

              <button
                onClick={() =>
                  handleRejected(singleBooking?.rentalBookingReviewId)
                }
                className={`w-[185px] border border-btn-primary rounded-full text-btn-secondary cursor-pointer h-[43px] flex justify-center items-center  ${
                  reason?.length > 2 ? "bg-btn-primary text-bg-primary" : ""
                }`}
              >
                Reject
              </button>
              {removeCondition === false && (
                <button
                  onClick={() =>
                    handleConfirm(singleBooking?.rentalBookingReviewId)
                  }
                  className="w-[292px] bg-btn-primary rounded-full text-bg-primary h-[43px] flex justify-center items-center cursor-pointer"
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default BookingReview;
