import AWS from "aws-sdk";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from "uuid";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY_SECRET,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function FileSingleAdd({
  setNeedUpdate,
  setUrlTempStorageArray,
  urlTempStorageArray,
  multiple = true,
  type = "default",
  upload = true,
  width,
  setIsLoading,
}) {
  let tempArr = [];
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const { pathname } = useLocation();
  console.log("route", pathname);
  // let urlTempStorageArray = [];
  // const handleFileInput = (e) => {
  //   setSelectedFile(e.target.files[0]);
  //   tempFile = e.target.files[0];
  //   console.log(tempFile);
  //   setSelectedFile(event.target.files[0]);
  //   setIsSelected(true);
  //   uploadFile(tempFile);
  // };
  const uploadFile = (file, len) => {
    console.log(file.type, "fileType");
    setFileIsUploading(true);
    if (setIsLoading) {
      setIsLoading(true);
    }

    const uniqueId = `${uuidv4().toString()}.${
      file.name.split(".")[file.name.split(".").length - 1]
    }`;

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: uniqueId,
      ContentType: file.type,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt, "test");
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data, success) => {
        if (err) console.log(err);
        console.log(data);
        console.log(success);
        myBucket.getSignedUrl(
          "getObject",
          {
            Bucket: S3_BUCKET,
            Key: uniqueId,
            Expires: 60,
          },
          (err, url) => {
            console.log(url);
            let path = url.split("?")[0];
            tempArr.push({
              fileName: file.name,
              tags: [file.name],
              url: path,
            });
            // if (len === tempArr.length) {
            setUrlTempStorageArray(() => tempArr);
            // }
            // }
            setIsFilePicked(true);
            setFileIsUploading(false);
            if (setIsLoading) {
              setIsLoading(false);
            }
          }
        );
      });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ["application/pdf", "image/*", "image/svg+xml"],
    multiple: multiple,
    onDrop: (acceptedFiles) => {
      setFileIsUploading(true);
      upload &&
        acceptedFiles.forEach((file) => {
          uploadFile(file, acceptedFiles.length);
        });
      !upload && setUrlTempStorageArray(() => acceptedFiles);
      setFileIsUploading(false);
    },
  });

  // const files = acceptedFiles.map((file) => (
  //     <li key={file.path}>
  //         {file.path} - {file.size} bytes
  //     </li>
  // ));

  return (
    <div className="flex content-center justify-center">
      <section
        className={`container border-2 rounded-3xl border-dashed border-txt-dotted self-center text-center ${
          width ? width : "w-2/3"
        } overflow-hidden`}
      >
        {fileIsUploading ? (
          <div className="w-full flex content-center justify-center my-20">
            <div>
              <svg
                className="animate-spin"
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.1"
                  d="M120 60C120 93.1371 93.1371 120 60 120C26.8629 120 0 93.1371 0 60C0 26.8629 26.8629 0 60 0C93.1371 0 120 26.8629 120 60ZM18 60C18 83.196 36.804 102 60 102C83.196 102 102 83.196 102 60C102 36.804 83.196 18 60 18C36.804 18 18 36.804 18 60Z"
                  fill="#4E90A8"
                />
                <path
                  d="M60 -2.62268e-06C52.1207 -2.27827e-06 44.3185 1.55194 37.039 4.56723C29.7595 7.58251 23.1451 12.0021 17.5736 17.5736C12.0021 23.1451 7.58251 29.7595 4.56722 37.039C1.55194 44.3185 -3.31151e-06 52.1207 -2.62268e-06 60L18 60C18 54.4845 19.0864 49.023 21.1971 43.9273C23.3078 38.8316 26.4015 34.2016 30.3015 30.3015C34.2016 26.4015 38.8316 23.3078 43.9273 21.1971C49.023 19.0864 54.4845 18 60 18L60 -2.62268e-06Z"
                  fill="#4E90A8"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div
            className=""
            {...getRootProps({
              className : "dropzone pt-2 pl-2 pr-2 pb-2"
              // className: `${`dropzone  ${
              //   pathname.includes("/admin/tour-activity-add") ||
              //   pathname.includes("/admin/tour-created") ||
              //   pathname.includes("admin/create-blog")
              //     ? "pt-2 pl-2 pr-2 pb-2"
              //     : "pt-12 pl-12 pr-12 pb-4"
              // }`}`,
            })}
          >
            <input {...getInputProps()} />
            {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
            <h2
            className = "text-[12px]"
              // className={` ${
              //   pathname.includes("/admin/tour-activity-add") ||
              //   pathname.includes("/admin/tour-created") ||
              //   pathname.includes("admin/create-blog")
              //     ? "text-[12px]"
              //     : "text-[27px]"
              // }`}
            >
              Drag your images or files here
            </h2>
            <h2
            className = "text-[12px]  mb-2 mt-2"
              // className={`${
              //   pathname.includes("/admin/tour-activity-add") ||
              //   pathname.includes("/admin/tour-created") ||
              //   pathname.includes("admin/create-blog")
              //     ? "text-[12px]  mb-2 mt-2 "
              //     : "text-[27px]  mb-6 mt-6 "
              // }`}
            >
              or
            </h2>
            {/* <p>or</p> */}
            <span
            className = "px-8 text-[12px]  py-2"
              // className={`mt-2  leading-normal bg-btn-primary text-txt-secondary rounded-full shadow-lg tracking-wide  cursor-pointer hover:bg-blue hover:text-white ${
              //   pathname.includes("/admin/tour-activity-add") ||
              //   pathname.includes("/admin/tour-created") ||
              //   pathname.includes("admin/create-blog")
              //     ? "px-8 text-[12px]  py-2"
              //     : "px-28  text-base  py-4"
              // }`}
            >
              Browse Files
            </span>
            {type === "default" && (
              <h6 className="mt-12 text-[12px] opacity-50">
                Accepted file formats: PNG, JPG
              </h6>
            )}
            {type === "alt" && (
              <h6 className="mt-12 text-[12px] opacity-50">
                Accepted file formats: PNG, JPG, PDF
              </h6>
            )}
            {/* <button className='p-2 bg-btn-primary rounded-3xl text-bg-primary text-xl '>Upload</button> */}
          </div>
        )}
      </section>
    </div>
  );
}

export default FileSingleAdd;
