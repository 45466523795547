import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourRoomCategory() {
  const [modalShown, setToggleModal] = useState(false);
  const [getRoomType, setGetRoomType] = useState([]);
  const [roomType, setRoomType] = useState({
    room_category_name: "",
    description: "",
  });
  const [roomTypes, setRoomTypes] = useState([]);
  const handlePackage = () => {
    setRoomTypes([...roomTypes, roomType]);
    setRoomType({
      room_category_name: "",
      description: "",
    });
    setToggleModal(false);
  };

  const getAllRoomType = () => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_ROOM_CATEGORY_GET_API}`)
      .then((res) => {
        setGetRoomType(res?.data?.tour_room_categories);
      });
  };

  useEffect(() => {
    getAllRoomType();
  }, []);

  const handlePackageAdd = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_ROOM_CATEGORY_POST_API}`, {
        room_categories: roomTypes,
      })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Room Category added successfully");
          setRoomTypes([]);
          getAllRoomType();
        }
      });
  };

  const removeRoomCategory = (ind) => {
    const newArray = roomTypes?.filter((meal, index) => index !== ind);
    setRoomTypes(newArray);
  };

  return (
    <div className="mt-[20px]">
      <p className="text-btn-secondary">Tour Added Room Category List</p>
      <div className="flex flex-col gap-y-[15px] w-full max-w-[450px] mt-[12px] overflow-y-scroll p-[6px] max-h-[550px]">
        {getRoomType?.map((roomType, index) => (
          <div
            key={index}
            className="shadow-customShadow rounded-[4px] p-[10px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Room Category :</span>{" "}
              {roomType?.tour_room_category_name}
            </p>
            <p className="text-[14px]">
              {" "}
              <span className="text-[12px]">Category Description :</span>{" "}
              {roomType?.tour_room_category_description}
            </p>
          </div>
        ))}
      </div>
      <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed top-[90px] right-[30px] w-full">
        <h2 className="text-[22px] text-center underline">Room Category</h2>
        <div className="mt-[20px] flex flex-col gap-y-[10px] ">
          {roomTypes?.map((tour, index) => (
            <div
              key={index}
              className="border rounded-[12px]  p-[10px] relative border-[#ddd]"
            >
              <p className="text-[14px]">
                {" "}
                <span className="text-[12px]">Room Category :</span>{" "}
                {tour?.room_category_name}
              </p>

              <p className="text-[14px]">
                {" "}
                <span className="text-[12px]">Description: </span>
                {tour?.description}
              </p>

              <span
                onClick={() => removeRoomCategory(index)}
                className="absolute top-[-12px] right-[-8px] cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[25px] h-[25px] text-[#EC7063]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>

        <div className="w-full flex justify-end mt-[10px]">
          <button
            className={`h-[30px]  bg-btn-secondary rounded-[8px] text-bg-white ${
              roomTypes?.length > 0 ? "w-[150px] " : "w-full"
            }`}
            onClick={() => setToggleModal(true)}
          >
            Add New
          </button>
        </div>
        {roomTypes?.length > 0 && (
          <button
            onClick={() => handlePackageAdd()}
            className="h-[40px] w-full mt-[20px] bg-btn-secondary rounded-[8px] text-bg-white"
          >
            Submit
          </button>
        )}
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={true}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div className="mt-[10px]">
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setRoomType((prev) => ({
                    ...prev,
                    room_category_name: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={roomType.room_category_name}
              />
              <span className="placeholder">Room Category Name:</span>
            </label>
          </div>

          <div className="w-full">
            <label className="custom-field one w-full">
              <textarea
                onChange={(e) =>
                  setRoomType((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                className="input w-full min-h-[150px] max-h-[200px]"
                type="text"
                placeholder=" "
                value={roomType.description}
              ></textarea>
              <span className="placeholder">Description :</span>
            </label>
          </div>
          <button
            onClick={() => handlePackage()}
            disabled={
              roomType?.room_category_name?.length === 0 ||
              roomType?.description?.length === 0
            }
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white disabled:bg-bg-semiblack"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
