import React, { useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { authAxios } from "../utils/axiosWrapper";
import { v4 as uuidv4 } from "uuid";
import { apiEndPoint } from "../contants/Endpoints";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY_SECRET,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function FileUpload({
  name,
  isSelected,
  setIsSelected,
  selectedFile,
  setSelectedFile,
  type,
}) {
  let tempFile = null;
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    tempFile = e.target.files[0];
    console.log(tempFile);
    // setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    uploadFile(tempFile);
  };

  const uploadFile = (file) => {
    const uniqueId = `${uuidv4().toString()}.${
      file.name.split(".")[file.name.split(".").length - 1]
    }`;
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: uniqueId,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt);
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data, success) => {
        if (err) console.log(err);
        console.log(data);
        console.log(success);
        myBucket.getSignedUrl(
          "getObject",
          {
            Bucket: S3_BUCKET,
            Key: uniqueId,
            Expires: 60,
          },
          (err, url) => {
            console.log(url);
            const sendPayload = {
              contractId: localStorage.getItem("contractId"),
              description: "string",
              documentType: name,
              fileName: file.name,
              fileType: "DOC",
              requestId: "reqdslkfjlgh",
              tags: ["string"],
              url: url.split("?")[0],
            };
            // contract/document
            authAxios.post(`${apiEndPoint.POST_DOCUMENT_LIST_ADD}`, {
              documentSaveRequest: [sendPayload],
            });
            setIsFilePicked(true);
          }
        );
      });
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  // const handleSubmission = () => {
  //   const formData = new FormData();

  //   formData.append("File", selectedFile);

  //   fetch("https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("Success:", result);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  // };
  function decideIcon(color) {
    if (color == "white")
      return (
        <div className="">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 7.41V15.5C1.5 17.0913 2.13214 18.6174 3.25736 19.7426C4.38258 20.8679 5.9087 21.5 7.5 21.5C9.0913 21.5 10.6174 20.8679 11.7426 19.7426C12.8679 18.6174 13.5 17.0913 13.5 15.5V5.5C13.5 4.43913 13.0786 3.42172 12.3284 2.67157C11.5783 1.92143 10.5609 1.5 9.5 1.5C8.43913 1.5 7.42172 1.92143 6.67157 2.67157C5.92143 3.42172 5.5 4.43913 5.5 5.5V14.682C5.5 14.9446 5.55173 15.2047 5.65224 15.4474C5.75275 15.69 5.90007 15.9105 6.08579 16.0962C6.2715 16.2819 6.49198 16.4292 6.73463 16.5298C6.97728 16.6303 7.23736 16.682 7.5 16.682V16.682C8.03043 16.682 8.53914 16.4713 8.91421 16.0962C9.28929 15.7211 9.5 15.2124 9.5 14.682V7.5"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    if (color == "black") {
      return (
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 7.41V15.5C1.5 17.0913 2.13214 18.6174 3.25736 19.7426C4.38258 20.8679 5.9087 21.5 7.5 21.5C9.0913 21.5 10.6174 20.8679 11.7426 19.7426C12.8679 18.6174 13.5 17.0913 13.5 15.5V5.5C13.5 4.43913 13.0786 3.42172 12.3284 2.67157C11.5783 1.92143 10.5609 1.5 9.5 1.5C8.43913 1.5 7.42172 1.92143 6.67157 2.67157C5.92143 3.42172 5.5 4.43913 5.5 5.5V14.682C5.5 14.9446 5.55173 15.2047 5.65224 15.4474C5.75275 15.69 5.90007 15.9105 6.08579 16.0962C6.2715 16.2819 6.49198 16.4292 6.73463 16.5298C6.97728 16.6303 7.23736 16.682 7.5 16.682V16.682C8.03043 16.682 8.53914 16.4713 8.91421 16.0962C9.28929 15.7211 9.5 15.2124 9.5 14.682V7.5"
            stroke="#376576"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
  }

  return (
    <div className="">
      <label
        className={
          "px-8 rounded-full shadow-lg tracking-wide cursor-pointer hover:bg-blue hover:text-white py-2.5 " +
          (isSelected
            ? "bg-btn-primary text-txt-secondary "
            : "text-btn-primary  my-2 border border-btn-primary")
        }
      >
        <span className="mt-2 text-base leading-normal">
          <div className=" inline-flex items-center">
            {!isSelected ? "Upload" : "Change"}
            {!isSelected ? decideIcon("black") : decideIcon("white")}
          </div>
        </span>
        <input
          type="file"
          name="file"
          className="hidden"
          onChange={handleFileInput}
        />
      </label>
      {/* {isSelected ? (
        <div>
          <p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
        </div>
      ) : (
        <p></p>
      )} */}
    </div>
  );
}
export default FileUpload;
