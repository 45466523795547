import React from "react";
import { useLocation, useNavigate } from "react-router";
import { DashboardRoutes } from "../../../contants/Constants";

const SecondaryNav = ({ marked }) => {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log(location, "locationlocation");
  return (
    <div>
      <ul className="list-none w-full overflow-hidden fixed top-[88px] z-[50] h-[39px] text-xs py-3 bg-btn-primary text-btn-base flex justify-between px-6 2xl:px-52">
        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.DASHBOARD)}
            className={`${
              location.pathname == DashboardRoutes.DASHBOARD
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Dashboard
          </a>
        </li>

        {/* <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.TOURS)}
            className={`${location.pathname == DashboardRoutes.TOURS
                ? "underline underline-offset-[8px]"
                : null
              } block text-center cursor-pointer`}
          >
            Tour
          </a>
        </li> */}
        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.BOOKING)}
            className={`${
              location.pathname == DashboardRoutes.BOOKING
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Bookings
          </a>
        </li>

        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.ALL_BOOKINGS)}
            className={`${
              location.pathname == DashboardRoutes.ALL_BOOKINGS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            All Bookings
          </a>
        </li>
        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.HOTELS)}
            className={`${
              location.pathname == DashboardRoutes.HOTELS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Hotels
          </a>
        </li>
        {/* <li className="float-left">
          <a className="block text-centercursor-pointer">
            Statistics
          </a>
        </li> */}
        {/* <li className="float-left">
          <a className="block text-center cursor-pointer">
            Reviews
          </a>
        </li> */}
        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.CREW)}
            className={`${
              location.pathname == DashboardRoutes.CREW
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Crew
          </a>
        </li>
        <li className="float-left">
          <a
            onClick={() => navigate(DashboardRoutes.CATEGORIES)}
            className={`${
              location.pathname == DashboardRoutes.CATEGORIES
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Categories
          </a>
        </li>
        {/* <li className="float-left">
          <a className="block text-center cursor-pointer">
            Contract
          </a>
        </li>
        <li className="float-left">
          <a className="block text-center cursor-pointer">
            Payment Data
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default SecondaryNav;
