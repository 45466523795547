import React from "react";

const ParentPackageFoodCard = ({ food }) => {
  const { meal_type_name, food_items, per_meal_package_price } = food;
  // console.log("food", food)

  return (
    <div className="bg-bg-white py-[8px] px-[8px] shadow-customShadow rounded-[2px]">
      <div className="mb-[4px]">
        <p className="text-[11px] text-center bg-[#e2e8f0] text-btn-secondary">
          {meal_type_name}
        </p>
      </div>
      <div className="grid gap-y-[4px] p-[4px] ring-[1px] rounded-[2px]">
        <div className="md:text-[12px] flex flex-row flex-wrap">
          <p className="text-bg-semiblack">Items: </p>
          {Object.entries(food_items).map(([key, value]) => (
            <span key={key}>&nbsp;{value}, </span>
          ))}
        </div>

        <p className="md:text-[12px] text-btn-secondary">
          Per Meal: {per_meal_package_price}৳
        </p>
      </div>
    </div>
  );
};

export default ParentPackageFoodCard;
