import React from "react";
import { DOTS, usePagination } from "./usePagination";
const Paginations = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  // when send the array length then lastPage working
  // let lastPage = Math.round(totalCount / 10);

  return (
    <div className="">
      <div className={`flex justify-center items-center mt-[12px]`}>
        {/* Left navigation arrow */}
        <button
          disabled={currentPage === 1}
          className={`cursor-pointer pr-[15px] ${
            currentPage === 1 && "text-bg-semiblack"
          }`}
          onClick={onPrevious}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <div className="flex gap-[8px]">
          {paginationRange?.map((pageNumber) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <p key={pageNumber} className="w-[8px] h-[8px]">
                  &#9679;
                </p>
              );
            }

            // Render our Page Pills
            return (
              <div
                key={pageNumber}
                className={`cursor-pointer border rounded-sm hover:bg-[#E5E7E9] border-btn-secondary w-[30px] flex justify-center items-center ${
                  pageNumber === currentPage &&
                  "bg-btn-secondary text-bg-primary"
                }`}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </div>
            );
          })}
        </div>
        {/*  Right Navigation arrow */}

        <button
          disabled={currentPage >= totalCount}
          className={` cursor-pointer pl-[15px] ${
            currentPage === totalCount && "text-bg-semiblack"
          }`}
          onClick={onNext}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Paginations;
