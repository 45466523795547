/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { adminRoute, merchantRoute, UserType } from "../../contants/Constants";
import { fetchSummeryGet } from "../../store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import "./HorizontalBar.css";

const HorizontalBar = ({ selectedBar, registration }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  // console.log(fetchSummeryData, "fetchSummeryData");
  useEffect(() => {
    dispatch(fetchSummeryGet(localStorage.getItem("id")));
  }, []);

  const checkStatusAndNavigate = (url) => {
    if (url === merchantRoute.OWNER) {
      if (fetchSummeryData.ownerStatus !== "EMPTY") {
        navigate(merchantRoute.OWNER);
      }
    }
  };
  return (
    <div className="">
      <div className="timeline">
        <div className="events">
          {registration ? (
            <ol>
              <ul>
                <li>
                  <a
                    className={
                      selectedBar == "Registration" &&
                      "selected text-txt-primary ml-[-80px] md:ml-0"
                    }
                  >
                    Registration
                  </a>
                </li>
              </ul>
            </ol>
          ) : (
            <ol>
              <ul>
                <li>
                  <a
                    // href=""
                    onClick={() => {
                      // returnAdminOrUserBasedOnPath(location.pathname) ==
                      //   UserType.USER
                      //   ? navigate(merchantRoute.OWNER)
                      //   : navigate(adminRoute.ADMIN_OWNER)
                      // if (fetchSummeryData.ownerStatus !== 'EMPTY') {
                      if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
                        navigate(adminRoute.ADMIN_OWNERV2);
                      } else {
                        navigate(merchantRoute.OWNER);
                      }
                      // }
                    }}
                    className={
                      selectedBar == "Owner"
                        ? "selected text-txt-primary"
                        : null
                    }
                  >
                    Owner
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
                        navigate(adminRoute.ADMIN_CONTRACT);
                      } else {
                        if (fetchSummeryData.contractInfoStatus !== "EMPTY") {
                          navigate(merchantRoute.CONTRACT);
                        }
                      }
                    }}
                    className={
                      selectedBar == "Contract"
                        ? "selected text-txt-primary"
                        : null
                    }
                  >
                    Contract
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
                        navigate(adminRoute.ADMIN_DOCUMENT);
                      } else {
                        if (fetchSummeryData.documentInfoStatus !== "EMPTY") {
                          navigate(merchantRoute.DOCUMENT);
                        }
                      }
                    }}
                    className={
                      selectedBar == "Document"
                        ? "selected text-txt-primary"
                        : null
                    }
                  >
                    Document
                  </a>
                </li>
                {/* for new tnc update */}
                {/* <li>
                  <a
                    onClick={() => {
                      if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
                        navigate(adminRoute.ADMIN_HOTELS)
                      }
                      else {
                        if (fetchSummeryData.propertyStatus !== 'EMPTY') {
                          navigate(merchantRoute.HOTELS)
                        }
                      }
                    }}
                    className={
                      selectedBar === "Hotel"
                        ? "selected text-txt-primary"
                        : null
                    }
                  >
                    Hotel
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
                        navigate(adminRoute.ADMIN_CATEGORIES)
                      }
                      else {
                        if (fetchSummeryData.categoryStatus !== 'EMPTY') {
                          navigate(merchantRoute.CATEGORIES)
                        }
                      }
                    }}
                    className={
                      selectedBar === "Categories"
                        ? "selected text-txt-primary"
                        : null
                    }
                  >
                    Categories
                  </a>
                </li> */}
              </ul>
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalBar;
