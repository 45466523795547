import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";

export default function TransportProvider() {
  const [getTransportationProvider, setGetTransportationProvider] = useState(
    []
  );
  const [transportProviders, setTransportProviders] = useState([]);
  const [transportProvider, setTransportProvider] = useState({
    hot_line_number: "",
    transportation_provider_name: "",
  });
  const [modalShown, setToggleModal] = useState(false);

  const getAllTransportationProvider = () => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint?.ADMIN_GET_ALL_TRANSPORTATION_PROVIDER_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_providers)
        setGetTransportationProvider(res?.data?.transportation_providers);
      });
  };

  useEffect(() => {
    getAllTransportationProvider();
  }, [transportProviders]);

  const handleMeal = () => {
    setTransportProviders([...transportProviders, transportProvider]);
    setTransportProvider({
      hot_line_number: "",
      transportation_provider_name: "",
    });
    setToggleModal(false);
  };

  const handleMealDelete = (ind) => {
    const newArray = transportProviders?.filter((meal, index) => index !== ind);
    setTransportProviders(newArray);
  };

  const handleMealAdd = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_TRANSPORT_PROVIDER_POST_API}`,
        {
          transportation_providers: transportProviders,
          requestId: "string",
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toast.success("Successfully added to transport provider");
          setTransportProviders([]);
          setTransportProvider({
            hot_line_number: "",
            transportation_provider_name: "",
          });
        }
      });
  };
  return (
    <div className="mt-[20px]">
      <p className="text-btn-secondary">Tour Added Provider List</p>
      <div className="flex flex-col gap-y-[15px] w-full max-w-[450px] mt-[12px] max-h-[550px] overflow-y-scroll p-[6px] ">
        {getTransportationProvider?.map((provider, index) => (
          <div
            key={index}
            className="shadow-customShadow rounded-[4px] p-[10px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Provider Name :</span>{" "}
              {provider?.transportation_provider_name}
            </p>
          </div>
        ))}
      </div>
      <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed top-[90px] right-[30px] w-full">
        <h2 className="text-[22px] text-center underline">
          Transport Provider Add
        </h2>
        <div className="flex flex-col gap-y-[10px] ">
          {transportProviders?.map((meal, index) => (
            <div
              key={index}
              className="flex justify-between gap-x-[20px] relative  border rounded-[8px] border-txt-hint text-txt-primary p-[4px]"
            >
              {" "}
              <div className="flex flex-col gap-y-[7px]">
                <p className="">
                  <span className="text-[12px]">Provider Name:</span>{" "}
                  {meal?.transportation_provider_name}
                </p>
                <p className="">
                  {" "}
                  <span className="text-[12px]">Hot Line:</span>{" "}
                  {meal?.hot_line_number}
                </p>
              </div>
              <span
                onClick={() => handleMealDelete(index)}
                className=" cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
        {transportProviders?.length > 0 && (
          <div className="flex justify-end w-full">
            <button
              onClick={() => setToggleModal(true)}
              className="w-[150px] bg-btn-secondary h-[30px] mt-[10px] text-bg-white rounded-[8px] "
            >
              Add New
            </button>
          </div>
        )}
        {transportProviders?.length > 0 ? (
          <button
            onClick={() => handleMealAdd()}
            className="h-[40px] bg-btn-secondary w-full mt-[40px] text-bg-white rounded-[8px]"
          >
            Submit Transport Mode
          </button>
        ) : (
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportProvider((prev) => ({
                      ...prev,
                      transportation_provider_name: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportProvider.transportation_provider_name}
                />
                <span className="placeholder">Provider Name :</span>
              </label>
            </div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportProvider((prev) => ({
                      ...prev,
                      hot_line_number: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportProvider.hot_line_number}
                />
                <span className="placeholder">Hot Line Number :</span>
              </label>
            </div>
            <button
              onClick={() => handleMeal()}
              disabled={
                transportProvider?.hot_line_number?.length === 0 ||
                transportProvider?.transportation_provider_name?.length === 0
              }
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        )}
      </div>
      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={false}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportProvider((prev) => ({
                      ...prev,
                      transportation_provider_name: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportProvider.transportation_provider_name}
                />
                <span className="placeholder">Provider Name :</span>
              </label>
            </div>

            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportProvider({ hot_line_number: e.target.value })
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportProvider.hot_line_number}
                />
                <span className="placeholder">Hot Line Number :</span>
              </label>
            </div>
            <button
              onClick={() => handleMeal()}
              disabled={
                transportProvider?.hot_line_number?.length === 0 ||
                transportProvider?.transportation_provider_name?.length === 0
              }
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
}
