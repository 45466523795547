import React, { useEffect, useState } from "react";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../components/common/ModalCommon";
import SingleSelectNew from "../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { authAxios } from "../../utils/axiosWrapper";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  apiEndPoint,
  baseUrl,
} from "../../contants/Endpoints";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import { changeUrl } from "../utils/common";
import Paginations from "../../pages/admin/Paginations/Paginations";

const AdditionalInformation = ({
  setActiveStep,
  excursionData,
  isLoadingExcursion,
}) => {
  const [success, setSuccess] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [excursionAdditionalInformation, setExcursionAdditionalInformation] =
    useState([]);
  const [isNewItem, setIsNewItem] = useState(false);
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 10;

  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));
  let [temp, setTemp] = useState("");
  const [editId, setEditId] = useState(0);

  const [fullInformation, setFullInformation] = useState({
    title: "",
    description: "",
    mode_icon_url: "",
  });

  const handleEdit = () => {
    setIsNewItem(true);
    setTemp("");
    const editedData = {
      title: "",
      description: "",
      mode_icon_url: {},
    };
    setFullInformation({
      ...fullInformation,
      ...editedData,
    });
    setToggleModal(true);
  };

  const payloadMethod = {
    description: fullInformation?.description,
    excursionId: excursionID,
    iconUrl: fullInformation?.mode_icon_url?.url,
    id: isNewItem ? 0 : editId,
    requestId: "164",
    title: fullInformation?.title,
  };

  const onAddNewButton = () => {
    if (
      fullInformation?.title?.length === 0 ||
      fullInformation?.description?.length === 0 ||
      fullInformation?.mode_icon_url?.length === 0
    ) {
      toast.warn("All the field is required!");
      setShowError(true);
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_ADDITIONAL_INFORMATION}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Information Added successfully!");
        setSuccess(true);
        fullInformation.title = "";
        fullInformation.description = "";
        fullInformation.mode_icon_url = "";
        setTemp = "";
        setIsNewItem(false);
        setSuccess(!success);
        setShowError(false);
        setToggleModal(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setSuccess(false);
      });
  };

  const onUpdateButton = () => {
    setIsNewItem(false);
    if (
      fullInformation?.title?.length === 0 ||
      fullInformation?.description?.length === 0 ||
      fullInformation?.mode_icon_url?.length === 0
    ) {
      toast.warn("All the field is required!");
      setShowError(true);
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_ADDITIONAL_INFORMATION}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Information Updated successfully!");
        setSuccess(true);
        fullInformation.title = "";
        fullInformation.description = "";
        fullInformation.mode_icon_url = "";
        setTemp = "";
        setIsNewItem(false);
        setSuccess(!success);
        setShowError(false);
        setToggleModal(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setSuccess(false);
      });
  };

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  // check already Data has
  const getAllAdditionalInformationData = () => {
    if (excursionID !== 0) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ADDITIONAL_INFORMATION_BY_ID}?excursionId=${excursionID}&pageNumber=${currentPage}&pageSize=${pageSize}&requestId=123`
        )
        .then((res) => {
          setExcursionAdditionalInformation(res?.data?.entries);
          setTotalPage(res?.data?.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getAllAdditionalInformationData();
  }, [excursionID, success, currentPage, searchValue, pageSize]);

  const onEditButton = (property) => {
    setIsNewItem(false);
    setEditId(property?.id);
    const editedData = {
      title: property?.title,
      description: property?.description,
      mode_icon_url: { url: property?.iconUrl },
    };
    setFullInformation({
      ...fullInformation,
      ...editedData,
    });
    setToggleModal(true);
  };

  const onSubmit = () => {
    setActiveStep(4);
    changeUrl(Number(url.searchParams.get("id")), 4);
  };

  const deleteAdditionalInformation = (excursionAdditionalInformationId) => {
    setIsLoading(true);
    authAxios
      ?.delete(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_EXCURSION_DELETE_ADDITIONAL_INFORMATION_BY_EXCURSION_ADDITIONAL_ID}?excursionAdditionalInformationId=${excursionAdditionalInformationId}&requestId=123`
      )
      .then((res) => {
        getAllAdditionalInformationData();
        toast.success("Successfully deleted.");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const backAndSave = () => {
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <div className="w-[343px] md:w-[712px]">
      <div className=" mb-[30px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]">
            {" "}
            Additional Information
          </span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Access exclusive insights and detailed guidance, enriching your travel
          experience effortlessly.
        </p>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="">
          <div className={`flex justify-center`}>
            <TourMerchantButton
              width={""}
              name={"Add New Information"}
              onClick={handleEdit}
            ></TourMerchantButton>
          </div>

          {/* Show if data has */}
          {excursionAdditionalInformation?.length > 0 && (
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Sl No.</th>
                    <th>Icon</th>
                    <th>Title</th>
                    <th>ID</th>
                    {/* <th>Ratings</th> */}
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {excursionAdditionalInformation?.length > 0 &&
                    excursionAdditionalInformation?.map((property, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + pageSize * currentPage - pageSize}.
                        </td>
                        <td className="cursor-pointer mx-auto">
                          <img
                            className="mx-auto w-[40px] h-[40px]"
                            src={property?.iconUrl}
                            alt=""
                          />
                        </td>
                        <td>{property?.title}</td>
                        <td>{property?.id}</td>
                        <td>{property?.description}</td>
                        <td className="flex flex-row items-center justify-center gap-x-[8px]">
                          <button
                            className="bg-btn-primary text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-[4px] shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg"
                            onClick={() => onEditButton(property)}
                          >
                            Edit
                          </button>

                          <button
                            className="bg-[#f87171] text-bg-white px-[8px] py-[4px] my-[4px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-[4px] shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg"
                            onClick={() =>
                              deleteAdditionalInformation(property?.id)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          )}

          <div>
            <ModalCommon
              shown={toggleModal}
              width="max-w-[450px]"
              height="min-h-[300px]"
              outSideOff={false}
              close={() => {
                setToggleModal(false);
              }}
            >
              <div className="px-[40px] py-[20px]">
                <div>
                  <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                    Additional Information
                  </h2>
                  <div className="my-[16px]">
                    <p className="text-btn-secondary">Title: </p>
                    <div className="rounded">
                      <input
                        className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] "
                        placeholder="Title : "
                        type="text"
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          setFullInformation((prev) => ({
                            ...prev,
                            title: inputVal,
                          }));
                        }}
                        value={fullInformation?.title}
                      />
                    </div>
                    {showError && fullInformation?.title?.length === 0 && (
                      <p className="text-[13px] text-[#f87171] mt-[2px]">
                        Title is required.
                      </p>
                    )}
                  </div>
                  <div className="my-[16px]">
                    <p className="text-btn-secondary">Description: </p>
                    <div className="rounded">
                      <textarea
                        className="w-full p-[8px] h-[150px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                        placeholder="Description : "
                        type="text"
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          setFullInformation((prev) => ({
                            ...prev,
                            description: inputVal,
                          }));
                        }}
                        value={fullInformation?.description}
                      />
                      {showError &&
                        fullInformation?.description?.length === 0 && (
                          <p className="text-[13px] text-[#f87171] mt-[2px]">
                            Description is required.
                          </p>
                        )}
                    </div>
                  </div>

                  <div className=" pt-[10px]">
                    <p className="pb-[5px]">Mode Icon:</p>

                    <div className="flex justify-start items-center gap-x-[8px]">
                      <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                        <img
                          className="w-[40px] h-[40px]"
                          src={
                            fullInformation?.mode_icon_url?.url
                              ? fullInformation?.mode_icon_url?.url
                              : fullInformation?.mode_icon_url
                          }
                          alt=""
                        />
                      </div>

                      <SingleSelectNew
                        warningView={false}
                        data={iconsList}
                        displayField="title"
                        placeholder={"Icons"}
                        selectedFieldName={"title"}
                        selectedValue={fullInformation?.mode_icon_url?.url}
                        setSelectedValue={(mode_icon_url) =>
                          setFullInformation((prev) => ({
                            ...prev,
                            mode_icon_url: mode_icon_url,
                          }))
                        }
                        setSelectedObject={() => {}}
                        image={true}
                        isReadOnly={false}
                        temp={temp}
                        setTemp={setTemp}
                      />
                    </div>
                    {showError &&
                      fullInformation?.mode_icon_url?.length === 0 && (
                        <p className="text-[13px] text-[#f87171] mt-[2px]">
                          Icon is required.
                        </p>
                      )}
                  </div>
                </div>

                <div className="my-[20px]">
                  {isNewItem ? (
                    <div>
                      <TourMerchantButton
                        onClick={() => onAddNewButton()}
                        width={"w-full"}
                        name={"Add New"}
                      ></TourMerchantButton>
                    </div>
                  ) : (
                    <div>
                      <TourMerchantButton
                        onClick={() => onUpdateButton()}
                        width={"w-full"}
                        name={"Update"}
                      ></TourMerchantButton>
                    </div>
                  )}
                </div>
              </div>
            </ModalCommon>
          </div>

          <div className="my-[30px] flex justify-center items-center w-full">
            <CustomButtonGroupExcruions
              setSaveAndNext={backAndSave}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalInformation;
