import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ModalCommon from "../../../../components/common/ModalCommon";
import { MERCHANT_TOUR_ENDPOINT } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import { convertStringToObject } from "../../ParentPackageSelected";

export default function TourDefaultOptionView() {
  // const queryString = useLocation();
  //   const urlParams = new URLSearchParams(queryString);
  //   const packageId = urlParams.get('packageId');

  const [availableOption, setAvailableOption] = useState([]);
  const [allAccommodationOption, setAllAccommodationOption] = useState([]);
  const [allTransferOption, setAllTransferOption] = useState([]);
  const [allGuideOption, setAllGuideOption] = useState([]);
  const [allSpotOption, setAllSpotOption] = useState([]);
  const [allFoodOption, setAllFoodOption] = useState([]);
  const [allTransportationOption, setAllTransportationOption] = useState([]);
  const [toggleModalSpot, setToggleModalSpot] = useState(false);
  const [toggleModalFood, setToggleModalFood] = useState(false);
  const [toggleModalTransportation, setToggleModalTransportation] =
    useState(false);
  const [toggleModalAccommodation, setToggleModalAccommodation] =
    useState(false);
  const [toggleModalTransfer, setToggleModalTransfer] = useState(false);
  const [toggleModalGuide, setToggleModalGuide] = useState(false);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const getAvailableOption = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_VIEW_PACKAGE_AVAILABEL_OPTION}${convertData?.packageId}/${convertData?.date}?requestId=packageList`
      )
      .then((res) => {
        setAvailableOption(res?.data?.available_tour_package);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  const getAllAccommodationPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_ACCOMMODATION_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllAccommodationOption(res?.data?.available_accommodation_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  const getAllTransferPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_TRANSFER_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllTransferOption(res?.data?.available_transfer_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  const getAllGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_GUIDE_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllGuideOption(res?.data?.available_guide_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  const getAllSpotPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_SPOT_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllSpotOption(res?.data?.available_spot_entry_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  const getAllFoodPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_FOOD_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllFoodOption(res?.data?.available_food_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  const getAllTransportationPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_TRANSPORTATION_OPTION}${convertData?.packageId}?requestId=packageList`
      )
      .then((res) => {
        setAllTransportationOption(res?.data?.available_transportation_options);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAvailableOption();
  }, []);

  const handleAccommodationOption = (isBool) => {
    getAllAccommodationPackage();
    setToggleModalAccommodation(isBool);
  };
  const handleTransferOption = (isBool) => {
    getAllTransferPackage();
    setToggleModalTransfer(isBool);
  };

  const handleGuideOption = (isBool) => {
    getAllGuidePackage();
    setToggleModalGuide(isBool);
  };
  const handleSpotOption = (isBool) => {
    setToggleModalSpot(isBool);
    getAllSpotPackage();
  };

  const handleFoodOption = (isBool) => {
    setToggleModalFood(isBool);
    getAllFoodPackage();
  };

  const handleTransportationOption = (isBool) => {
    setToggleModalTransportation(isBool);
    getAllTransportationPackage();
  };

  // console.log(
  //   allAccommodationOption?.map(
  //     (accommodation) =>
  //       accommodation.tour_package_available_accommodation_packages
  //   )
  // );

  const a = {
    1: {
      2: {
        tour_package_available_meal_package_id: 139,
        meal_type_id: 2,
        meal_type_name: "Dinner",
        food_items: {
          1: "Rice",
          3: "Murgi",
          4: "Vaji",
        },
        meal_package_price: 280,
        day_number: 2,
      },
    },
  };
  const renderMealInfo = (data) => {
    return Object.keys(data).map((dayNumber) => {
      const dayData = data[dayNumber];

      return Object.keys(dayData).map((mealType) => {
        const mealInfo = dayData[mealType];

        return (
          <div className="flex flex-col ">
            <p>Day : {dayNumber}</p>
            <div className="" key={`${dayNumber}-${mealType}`}>
              <p>{mealInfo?.meal_type_name}</p>
              <p>Food Items: {Object.values(mealInfo.food_items).join(", ")}</p>
              <p>Meal Package Price: {mealInfo.meal_package_price}</p>
            </div>
          </div>
        );
      });
    });
  };

  // availableOption?.tour_package_default_option?.tour_package_food_option
  //   ?.tour_package_available_meal_packages &&
  //   Object.keys(
  //     availableOption?.tour_package_default_option?.tour_package_food_option
  //       ?.tour_package_available_meal_packages
  //   )?.map((item) => console.log(item));

  // const tour_package_options = availableOption?.tour_package_default_option;
  // console.log({ tour_package_options });

  const iconObject = {
    dotIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
        ></path>
      </svg>
    ),
  };

  return (
    <>
      {/* New Design */}
      <div className="mb-[50px]">
        <p className="text-[24px] text-tour-text font-medium">Amenities</p>
        {/* tour schedule */}
        <div className="my-[16px]">
          <p className="border-l-[2px] bg-tour-dnd border-tour-primary py-[8px]">
            <span className="ml-[16px] ">Tour Schedule: </span>
          </p>
          <div className="w-[357px]  flex flex-col  overflow-auto">
            {
              <div className="border-l-[1px] border-tour-input pt-[8px]">
                <div className="ml-[16px]">
                  <div className="border-[1px] border-tour-secondaryHint rounded-[12px] ">
                    <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {" "}
                      {availableOption?.tour_package_type_name}
                    </p>
                    <div className="flex flex-col gap-[37px] px-[12px] py-[16px]">
                      <div className="flex flex-col gap-[8px]">
                        <div className="flex flex-col  text-[14px] font-normal">
                          <p className="text-tour-text">
                            {"Tour Dates Options : "}{" "}
                            <span className="font-medium text-tour-secondary">
                              {availableOption?.tour_start_date}
                            </span>
                          </p>
                          <div className="grid grid-cols-2 gap-[8px]"></div>
                        </div>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Total Seat: </span>
                          <span className="text-tour-secondary">
                            {availableOption?.total_seats}
                          </span>
                        </p>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Bookable Seat: </span>
                          <span className="text-tour-secondary">
                            {availableOption?.bookable_seats}
                          </span>
                        </p>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Red Price: </span>
                          <span className="font-bold">
                            {
                              availableOption?.tour_package_default_option
                                ?.tour_package_option_price_details_per_person
                                ?.ghuddy_website_red_price
                            }{" "}
                            BDT
                          </span>
                        </p>
                        <p className="text-tour-text  text-[14px]">
                          <span className=" font-normal">Black Price: </span>
                          <span className="font-bold">
                            {
                              availableOption?.tour_package_default_option
                                ?.tour_package_option_price_details_per_person
                                ?.ghuddy_website_black_price
                            }{" "}
                            BDT
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <p className="my-[16px]">Price: </p> */}
                </div>
              </div>
            }
          </div>
        </div>
        {/* Food Package */}
        <div className="border-l-[1px] border-tour-input my-[16px]">
          <div className="flex justify-between bg-tour-dnd ">
            <p className="border-l-[1px] border-tour-secondary py-[8px]">
              <span className="ml-[16px] font-medium text-[18px]">Food</span>
              <span className="text-tour-border">
                {availableOption?.is_food_inclusive
                  ? " (Active)"
                  : " (Inactive) "}
              </span>
            </p>
            <p className="py-[8px]" onClick={() => handleFoodOption(true)}>
              <span className="cursor-pointer">{iconObject?.dotIcon}</span>
            </p>
          </div>
          <div className="ml-[16px]">
            <p className="mt-[12px] text-tour-text text-[14px]">
              Price per person:
              <span className="font-bold">
                {" "}
                {
                  availableOption?.tour_package_default_option
                    ?.tour_package_food_option
                    ?.tour_package_option_price_per_person
                }{" "}
                BDT
              </span>
            </p>
            {availableOption?.tour_package_default_option
              ?.tour_package_food_option
              ?.tour_package_available_meal_packages && (
              <div className="max-h-[600px] overflow-y-scroll">
                {
                  <div>
                    {Object.keys(
                      availableOption?.tour_package_default_option
                        ?.tour_package_food_option
                        ?.tour_package_available_meal_packages
                    ).map((dayNumber, index) => (
                      <div key={index} className="text-[14px] my-[12px]">
                        <p className="text-tour-text py-[12px]">
                          Day {dayNumber}
                        </p>
                        <div className="grid grid-cols-3 gap-[16px]">
                          {Object.keys(
                            availableOption?.tour_package_default_option
                              ?.tour_package_food_option
                              ?.tour_package_available_meal_packages[dayNumber]
                          ).map((mealType, ind) => (
                            <div
                              key={ind}
                              className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                            >
                              <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                                {
                                  availableOption?.tour_package_default_option
                                    ?.tour_package_food_option
                                    ?.tour_package_available_meal_packages[
                                    dayNumber
                                  ][mealType]?.meal_type_name
                                }
                              </p>
                              <div className="flex  flex-row gap-[4px] text-tour-text px-[12px] pt-[12px] ">
                                <p>Food: </p>

                                <div className="text-tour-secondaryShade text-[12px]">
                                  {Object.values(
                                    availableOption?.tour_package_default_option
                                      ?.tour_package_food_option
                                      ?.tour_package_available_meal_packages[
                                      dayNumber
                                    ][mealType]?.food_items
                                  ).join(", ")}
                                </div>
                              </div>
                              <p className="text-tour-text px-[12px] py-[12px]">
                                Price:{" "}
                                <span className="font-bold">
                                  {
                                    availableOption?.tour_package_default_option
                                      ?.tour_package_food_option
                                      ?.tour_package_available_meal_packages[
                                      dayNumber
                                    ][mealType]?.meal_package_price
                                  }{" "}
                                  BDT
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
            )}
          </div>
        </div>

        {/* Accommodation */}
        <div className="border-l-[1px] border-tour-input my-[16px]">
          <div>
            <div className="flex justify-between bg-tour-dnd">
              <p className="border-l-[1px] border-tour-secondary py-[8px]">
                <span className="ml-[16px] font-medium text-[18px]">
                  Accommodation
                </span>
                <span className="text-tour-border">
                  {availableOption?.is_accommodation_inclusive
                    ? " (Active)"
                    : " (Inactive) "}
                </span>
              </p>
              <p
                className="py-[8px]"
                onClick={() => handleAccommodationOption(true)}
              >
                <span className="cursor-pointer">{iconObject?.dotIcon}</span>
              </p>
            </div>
          </div>
          <div className="ml-[16px] my-[16px]">
            <p className="my-[12px] text-tour-text text-[14px]">
              Price per person:
              <span className="font-bold">
                {" "}
                {
                  availableOption?.tour_package_default_option
                    ?.tour_package_accommodation_option
                    ?.tour_package_option_price_per_person
                }{" "}
                BDT
              </span>
            </p>
            {availableOption?.tour_package_default_option
              ?.tour_package_accommodation_option && (
              <div className="grid grid-cols-3 gap-[16px]">
                {Object.keys(
                  availableOption?.tour_package_default_option
                    ?.tour_package_accommodation_option
                    ?.tour_package_available_accommodation_packages
                )
                  ?.map(
                    (key) =>
                      availableOption?.tour_package_default_option
                        ?.tour_package_accommodation_option
                        ?.tour_package_available_accommodation_packages[key]
                  )
                  ?.map((accommodation, index) => (
                    <div
                      key={index}
                      className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                    >
                      <p className="text-[16px] px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                        {accommodation?.tour_package_accommodation_name}
                      </p>
                      <div className="text-[14px] text-tour-text flex flex-col gap-[8px] p-[12px]">
                        <p>
                          {"Room Type: "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.tour_package_room_type_name}
                          </span>
                        </p>
                        <p>
                          {"Room Category(ies): "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.tour_package_room_category_name}
                          </span>
                        </p>
                        <p>
                          {"Is Shareable: "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.is_room_shareable ? "Yes" : "No"}
                          </span>
                        </p>
                        <p>
                          {"Number of beds: "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.bed_count}
                          </span>
                        </p>

                        <p>
                          {"Bed Configuration: "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.bed_configuration}
                          </span>
                        </p>

                        <p>
                          {"Suitable for person: "}
                          <span className="text-tour-secondaryShade">
                            {accommodation?.suitable_for_persons}
                          </span>
                        </p>
                        <p>
                          {"Price: "}
                          <span className="font-bold ">
                            {accommodation?.per_night_room_price}
                            {" BDT"}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        {/* Transfer */}
        <div className="border-l-[1px] border-tour-input my-[16px]">
          <div className="flex justify-between bg-tour-dnd">
            <p className="border-l-[1px] border-tour-secondary py-[8px]">
              <span className="ml-[16px] font-medium text-[18px]">
                Transfer
              </span>
              <span className="text-tour-border">
                {availableOption?.is_transfer_inclusive
                  ? " (Active)"
                  : " (inactive) "}
              </span>
            </p>
            <p className="py-[8px]" onClick={() => handleTransferOption(true)}>
              <span className="cursor-pointer">{iconObject?.dotIcon}</span>
            </p>
          </div>
          <div className="ml-[16px] my-[16px]">
            <p className="my-[12px] text-tour-text text-[14px]">
              Price per person:
              <span className="font-bold">
                {" "}
                {
                  availableOption?.tour_package_default_option
                    ?.tour_package_transfer_option
                    ?.tour_package_option_price_per_person
                }{" "}
                BDT
              </span>
            </p>
            <div className="grid grid-cols-3 gap-[16px]">
              {availableOption?.tour_package_default_option?.tour_package_transfer_option?.tour_package_available_transfer_packages?.map(
                (transferData, ind) => (
                  <div
                    key={ind}
                    className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                  >
                    <p className="text-[16px] px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {transferData?.tour_package_transfer_route}
                    </p>
                    <div className="text-[14px] text-tour-text flex flex-col gap-[8px] p-[12px]">
                      <p>
                        {"Tour Transfer Mode: "}
                        <span className="text-tour-secondaryShade">
                          {transferData?.tour_package_transportation_mode_name}
                        </span>
                      </p>
                      <p>
                        {"Tour Transfer Provider: "}
                        <span className="text-tour-secondaryShade">
                          {
                            transferData?.tour_package_transportation_provider_name
                          }
                        </span>
                      </p>
                      <p>
                        {"Is Ac: "}
                        <span className="text-tour-secondaryShade">
                          {transferData?.is_ac ? "Yes" : "No"}
                        </span>
                      </p>
                      <p>
                        {"Trip Type: "}
                        <span className="text-tour-secondaryShade">
                          {transferData?.trip_type}
                        </span>
                      </p>

                      <p>
                        {"Suitable for Persons: "}
                        <span className="text-tour-secondaryShade">
                          {transferData?.suitable_for_persons}
                        </span>
                      </p>

                      <p>
                        {"Price: "}
                        <span className="font-bold">
                          {transferData?.per_vehicle_per_trip_price} BDT
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {/* Transportation */}
        <div className="border-l-[1px] border-tour-input my-[16px]">
          <div className="flex justify-between bg-tour-dnd">
            <p className="border-l-[1px] border-tour-secondary py-[8px]">
              <span className="ml-[16px] font-medium text-[18px]">
                Transportation
              </span>
              {/* <span className="text-tour-border">{availableOption?.is_transfer_inclusive ? " (Active)" : " (Inactive) "}</span> */}
            </p>
            <p
              className="py-[8px]"
              onClick={() => handleTransportationOption(true)}
            >
              <span className="cursor-pointer">{iconObject?.dotIcon}</span>
            </p>
          </div>
          <div className="ml-[16px] my-[16px]">
            <div>
              <p>No Data</p>
            </div>
          </div>
        </div>
        {/* Guide */}
        <div className="border-l-[1px] border-tour-input my-[16px]">
          <div className="flex justify-between bg-tour-dnd">
            <p className="border-l-[1px] border-tour-secondary py-[8px]">
              <span className="ml-[16px] font-medium text-[18px]">Guide</span>
              <span className="text-tour-border">
                {availableOption?.is_guide_inclusive
                  ? " (Active)"
                  : " (Inactive) "}
              </span>
            </p>
            <p className="py-[8px]" onClick={() => handleGuideOption(true)}>
              <span className="cursor-pointer">{iconObject?.dotIcon}</span>
            </p>
          </div>
          <div className="ml-[16px] my-[16px]">
            <p className="my-[12px] text-tour-text text-[14px]">
              Price per person:
              <span className="font-bold">
                {" "}
                {
                  availableOption?.tour_package_default_option
                    ?.tour_package_guide_option
                    ?.tour_package_option_price_per_person
                }{" "}
                BDT
              </span>
            </p>
            <div className="grid grid-cols-3 gap-[16px]">
              {availableOption?.tour_package_default_option?.tour_package_guide_option?.tour_package_available_guide_packages?.map(
                (guideData, ind) => (
                  <div
                    key={ind}
                    className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                  >
                    <p className="text-[16px] px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {guideData?.number_of_guides_for_day} {" Day"}
                    </p>
                    <div className="text-[14px] text-tour-text flex flex-col gap-[8px] p-[12px]">
                      <p>
                        {"Guide Quantity: "}
                        <span className="text-tour-secondaryShade">
                          {guideData?.number_of_guides_for_day}
                        </span>
                      </p>
                      <p>
                        {"Price: "}
                        <span className="font-bold">
                          {guideData?.total_guide_price_for_day} BDT
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {/* Spot Fee */}
        <div className="border-l-[1px] border-tour-input">
          <div className="flex justify-between bg-tour-dnd">
            <p className="border-l-[1px] border-tour-secondary py-[8px]">
              <span className="ml-[16px] font-medium text-[18px]">
                Spot Fee
              </span>
              <span className="text-tour-border">
                {availableOption?.is_spot_entry_inclusive
                  ? " (Active)"
                  : " (Inactive) "}
              </span>
            </p>
            <p className="py-[8px]" onClick={() => handleSpotOption(true)}>
              <span className="cursor-pointer">{iconObject?.dotIcon}</span>
            </p>
          </div>
          <div className="ml-[16px] my-[16px]">
            <p className="my-[12px] text-tour-text text-[14px]">
              Price per person:
              <span className="font-bold">
                {" "}
                {
                  availableOption?.tour_package_default_option
                    ?.tour_package_spot_entry_option
                    ?.tour_package_option_price_per_person
                }{" "}
                BDT
              </span>
            </p>
            <div className="grid grid-cols-3 gap-[16px]">
              {availableOption?.tour_package_default_option?.tour_package_spot_entry_option?.tour_package_available_spot_entry_packages?.map(
                (spotData, ind) => (
                  <div
                    key={ind}
                    className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                  >
                    <p className="text-[16px] px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {spotData?.activity?.activity_name}
                    </p>
                    <div className="text-[14px] text-tour-text flex flex-col gap-[8px] p-[12px]">
                      <p>
                        {"Spot Location: "}
                        <span className="text-tour-secondaryShade">
                          {spotData?.activity?.activity_short_location}
                        </span>
                      </p>
                      <p>
                        {"Price: "}
                        <span className="font-bold">
                          {spotData?.spot_entry_price_per_person} BDT
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Old design */}
      <div>
        {/* view package */}

        {/* accommodation */}
        <ModalCommon
          shown={toggleModalAccommodation}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalAccommodation(false);
          }}
        >
          <div className="flex flex-col gap-[20px] p-[30px]">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Accommodation Option
            </h2>
            {allAccommodationOption.map((item, index) => (
              <div key={index}>
                <p className="bg-btn-secondary py-[3px] text-bg-white text-center">
                  Option {index + 1}
                </p>
                <p>
                  Price per person: {item.tour_package_option_price_per_person}{" "}
                  BDT
                </p>
                <p>Active: {item.tour_package_option_is_active.toString()}</p>

                <div className="flex  flex-wrap gap-[20px]">
                  {Object.values(
                    item.tour_package_available_accommodation_packages
                  ).map((accommodation, index) => (
                    <div
                      key={index}
                      className="shadow-customShadow p-[10px] rounded-[6px] bg-bg-white"
                    >
                      <h2 className="text-center border-b border-btn-secondary">
                        {accommodation?.tour_package_accommodation_name}
                      </h2>
                      <p>
                        Category :{" "}
                        {accommodation?.tour_package_room_category_name}
                      </p>
                      <p>Type: {accommodation?.tour_package_room_type_name}</p>
                      <p>Bed : {accommodation?.bed_configuration}</p>
                      <p>Bed Number : {accommodation?.bed_count}</p>
                      <p>
                        Shareable :{" "}
                        {accommodation?.is_room_shareable ? "Yes" : "No"}
                      </p>
                      <p>Night Number: {accommodation?.night_number}</p>
                      <p>Price: {accommodation?.per_night_room_price}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </ModalCommon>

        {/* transfer */}
        <ModalCommon
          shown={toggleModalTransfer}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalTransfer(false);
          }}
        >
          <div className="flex flex-col gap-[20px] p-[30px]">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Transfer Option
            </h2>

            <div className="flex flex-col gap-[20px]">
              {allTransferOption?.map((option, ind) => (
                <div key={ind}>
                  <p className="bg-btn-secondary text-center text-bg-white py-[3px]">
                    Option : {ind + 1}
                  </p>
                  <p>
                    Active :{" "}
                    {option?.tour_package_option_is_active ? "Yes" : "No"}
                  </p>
                  <p>
                    Price Per Person :{" "}
                    {option?.tour_package_option_price_per_person} BDT
                  </p>
                  <div className="flex flex-wrap gap-x-[10px]">
                    {option?.tour_package_available_transfer_packages?.map(
                      (transferData, ind) => (
                        <div
                          key={ind}
                          className="shadow-customShadow p-[6px] rounded-[6px] "
                        >
                          <h2 className="text-center border-btn-secondary border-b">
                            {transferData?.tour_package_transfer_route}
                          </h2>
                          <p>
                            Mode :{" "}
                            {
                              transferData?.tour_package_transportation_mode_name
                            }
                          </p>
                          <p>
                            Provider :{" "}
                            {
                              transferData?.tour_package_transportation_provider_name
                            }
                          </p>
                          <p>
                            Suitable for : {transferData?.suitable_for_persons}{" "}
                            Person
                          </p>
                          <p>Trip Type : {transferData?.trip_type}</p>
                          <p>Ac :{transferData?.is_ac ? "Yes" : "No"}</p>
                          <p>
                            Price : {transferData?.per_vehicle_per_trip_price}{" "}
                            BDT
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalCommon>
        {/* transportation */}
        <ModalCommon
          shown={toggleModalTransportation}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalTransportation(false);
          }}
        >
          <div className="flex flex-col gap-[20px] p-[30px]">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Transportation Option
            </h2>

            <div className="grid grid-cols-2 gap-[20px]">
              {allTransportationOption?.map((transportationOption, ind) => (
                <div key={ind}>
                  <p>Option {ind + 1}</p>
                  <div className="p-[10px] bg-[#EBF5FB]">
                    <h2>
                      Route Name :
                      {
                        transportationOption?.tour_package_transportation_route_name
                      }
                    </h2>
                    <p>
                      Mode :{" "}
                      {
                        transportationOption?.tour_package_transportation_mode_name
                      }
                    </p>
                    <p>
                      Provider Name :{" "}
                      {
                        transportationOption?.tour_package_transportation_provider_name
                      }
                    </p>
                    <p>
                      Brand :{" "}
                      {
                        transportationOption?.tour_package_transportation_brand_name
                      }
                    </p>
                    <p>Ac : {transportationOption?.is_ac ? "Yes" : "No"}</p>
                    <p>Trip Type : {transportationOption?.trip_type}</p>
                    <p>
                      Unit Per Price :{" "}
                      {transportationOption?.transportation_package_unit_price}{" "}
                      BDT
                    </p>
                    <p>
                      Active :{" "}
                      {transportationOption?.transportation_package_is_active
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalCommon>

        {/* Guide  */}
        <ModalCommon
          shown={toggleModalGuide}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalGuide(false);
          }}
        >
          <div className="p-[30px]">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Guide Option
            </h2>
            {allGuideOption?.map((option, index) => (
              <div key={index}>
                <p className="bg-btn-secondary text-center text-bg-white py-[3px]">
                  Option : {index + 1}
                </p>
                <p>
                  Active :{" "}
                  {option?.tour_package_option_is_active ? "Yes" : "No"}
                </p>
                <p>
                  Price per person :{" "}
                  {option?.tour_package_option_price_per_person} BDT
                </p>
                <div className="flex flex-wrap gap-[10px]">
                  {option?.tour_package_available_guide_packages?.map(
                    (guidePackage, ind) => (
                      <div
                        key={ind}
                        className="shadow-customShadow p-[6px] rounded-[4px]"
                      >
                        <p>
                          For Day : {guidePackage?.number_of_guides_for_day}
                        </p>
                        <p>
                          Price : {guidePackage?.total_guide_price_for_day} BDT
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </ModalCommon>

        {/* Spot */}

        <ModalCommon
          shown={toggleModalSpot}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalSpot(false);
          }}
        >
          <div className="p-[30px]">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Spot Entry Option
            </h2>
            <div className="flex flex-col gap-[10px]">
              {allSpotOption?.map((option, ind) => (
                <div key={ind}>
                  <p className="bg-btn-secondary text-center text-bg-white py-[3px]">
                    Option : {ind + 1}
                  </p>
                  <p>
                    Active :{" "}
                    {option?.tour_package_option_is_active ? "Yes" : "No"}
                  </p>
                  <p>
                    Price per Person :{" "}
                    {option?.tour_package_option_price_per_person} BDT
                  </p>
                  <div className="flex flex-wrap gap-[10px]">
                    {option?.tour_package_available_spot_entry_packages?.map(
                      (spotData, index) => (
                        <div
                          key={index}
                          className="shadow-customShadow p-[6px] rounded-[4px] mt-[10px]"
                        >
                          <p>{spotData?.activity?.activity_name}</p>
                          <p>{spotData?.activity?.activity_short_location}</p>
                          <p>
                            Price per person :{" "}
                            {spotData?.spot_entry_price_per_person} BDT
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalCommon>

        {/* Food */}
        <ModalCommon
          shown={toggleModalFood}
          width="max-w-[800px]"
          height="min-h-[600px]"
          outSideOff={false}
          close={() => {
            setToggleModalFood(false);
          }}
        >
          <div className="p-[30px] ">
            <h2 className="mt-[20px] text-btn-secondary text-center text-[18px]">
              All Possible Food Option
            </h2>

            <div className="max-h-[600px] overflow-y-scroll">
              {allFoodOption
                ?.map(
                  (foodOption) =>
                    foodOption?.tour_package_available_meal_packages
                )
                ?.map((foods, i) => (
                  <div key={i}>
                    {Object.keys(foods).map((dayNumber) => (
                      <div key={dayNumber} className="text-[14px]">
                        <p className="bg-btn-secondary text-bg-white">
                          Day {dayNumber} Meal Option
                        </p>
                        {Object.keys(foods[dayNumber]).map((mealType) => (
                          <div>
                            <p className="text-[18px] font-bold">
                              {foods[dayNumber][mealType]?.meal_type_name}
                            </p>
                            <div>
                              {Object.values(
                                foods[dayNumber][mealType]?.food_items
                              ).join(", ")}
                            </div>
                            <p>
                              Meal Package Price:{" "}
                              {foods[dayNumber][mealType]?.meal_package_price}{" "}
                              BDT
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
            {/* const dayData = data[dayNumber];

return Object.keys(dayData).map((mealType) => {
  const mealInfo = dayData[mealType]; */}
          </div>
        </ModalCommon>
      </div>
    </>
  );
}

// View Package
// {/* <div className="flex flex-col gap-[6px] text-[14px]">
// <h2 className="text-[16px]">
//   Package Name's: {availableOption?.tour_package_type_name}
// </h2>
// <p>Start Date : {availableOption?.tour_start_date}</p>
// <p>Total Seat : {availableOption?.total_seats}</p>
// <p>Bookable Seat : {availableOption?.bookable_seats}</p>
// <p>
//   Accommodation Inclusive :{" "}
//   {availableOption?.is_accommodation_inclusive ? "Yes" : "No"}
// </p>
// <p>
//   Food Inclusive : {availableOption?.is_food_inclusive ? "Yes" : "No"}
// </p>
// <p>
//   Guide Inclusive :{" "}
//   {availableOption?.is_guide_inclusive ? "Yes" : "No"}
// </p>
// <p>
//   {" "}
//   Spot Inclusive :{" "}
//   {availableOption?.is_spot_entry_inclusive ? "Yes" : "No"}
// </p>
// <p>
//   {" "}
//   Transfer Inclusive :{" "}
//   {availableOption?.is_transfer_inclusive ? "Yes" : "No"}
// </p>
// </div>
// {/* default price view */}
// <div className="mt-[10px]">
// <h2 className="text-center bg-btn-secondary text-bg-white py-[3px]">
//   Default Price
// </h2>
// <div className="flex flex-col gap-[6px]">
//   <p>
//     Red Price :{" "}
//     {
//       availableOption?.tour_package_default_option
//         ?.tour_package_option_price_details_per_person
//         ?.ghuddy_website_red_price
//     }{" "}
//     BDT
//   </p>
//   <p>
//     Black Price :{" "}
//     {
//       availableOption?.tour_package_default_option
//         ?.tour_package_option_price_details_per_person
//         ?.ghuddy_website_black_price
//     }{" "}
//     BDT
//   </p>
// </div>
// </div>
// {/* default package view */}
// <div className="mt-[20px] ">
// <h2 className="text-center text-[18px] bg-btn-secondary py-[3px] text-bg-white">
//   Tour Package Default Option
// </h2>
// {/* accommodation */}
// <div className="bg-[#FDFEFE] p-[10px] mt-[30px]">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Accommodation</h2>
//     <button
//       onClick={() => handleAccommodationOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>

//   <div>
//     <p>
//       Active :{" "}
//       {availableOption?.tour_package_default_option
//         ?.tour_package_accommodation_option
//         ?.tour_package_option_is_active
//         ? "Yes"
//         : "No"}
//     </p>
//     <p>
//       Price per person :{" "}
//       {
//         availableOption?.tour_package_default_option
//           ?.tour_package_accommodation_option
//           ?.tour_package_option_price_per_person
//       }
//     </p>
//   </div>

//   {availableOption?.tour_package_default_option
//     ?.tour_package_accommodation_option && (
//     <div className="flex gap-x-[30px] mt-[10px] ">
//       {Object.keys(
//         availableOption?.tour_package_default_option
//           ?.tour_package_accommodation_option
//           ?.tour_package_available_accommodation_packages
//       )
//         ?.map(
//           (key) =>
//             availableOption?.tour_package_default_option
//               ?.tour_package_accommodation_option
//               ?.tour_package_available_accommodation_packages[key]
//         )
//         ?.map((accommodation, index) => (
//           <div
//             key={index}
//             className="shadow-customShadow p-[10px] rounded-[6px] bg-bg-white"
//           >
//             <h2 className="text-center border-b border-btn-secondary">
//               {accommodation?.tour_package_accommodation_name}
//             </h2>
//             <p>
//               Category :{" "}
//               {accommodation?.tour_package_room_category_name}
//             </p>
//             <p>Type: {accommodation?.tour_package_room_type_name}</p>
//             <p>Bed : {accommodation?.bed_configuration}</p>
//             <p>Bed Number : {accommodation?.bed_count}</p>
//             <p>
//               Shareable :{" "}
//               {accommodation?.is_room_shareable ? "Yes" : "No"}
//             </p>
//             <p>Night Number: {accommodation?.night_number}</p>
//             <p>Price: {accommodation?.per_night_room_price}</p>
//           </div>
//         ))}
//     </div>
//   )}
// </div>

// {/* Food */}
// <div className="mt-[10px] bg-bg-white p-[10px]">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Food Package</h2>
//     <button
//       onClick={() => handleFoodOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>

//   <div>
//     <p>
//       Active :{" "}
//       {availableOption?.tour_package_default_option
//         ?.tour_package_food_option?.tour_package_option_is_active
//         ? "Yes"
//         : "No"}
//     </p>
//     <p>
//       Price Per Person :{" "}
//       {
//         availableOption?.tour_package_default_option
//           ?.tour_package_food_option
//           ?.tour_package_option_price_per_person
//       }{" "}
//       BDT
//     </p>
//   </div>
//   {availableOption?.tour_package_default_option
//     ?.tour_package_food_option
//     ?.tour_package_available_meal_packages && (
//     <div className="flex gap-x-[10px]  mt-[4px]">
//       {/* {Object.keys(
//       availableOption?.tour_package_default_option
//         ?.tour_package_food_option
//         ?.tour_package_available_meal_packages
//     )
//       ?.map(
//         (key) =>
//           availableOption?.tour_package_default_option
//             ?.tour_package_food_option
//             ?.tour_package_available_meal_packages[key]
//       )
//       ?.map((foods) =>
//         Object.keys(foods)
//           ?.map((keyFood) => foods[keyFood])
//           ?.map((food) => (
//             <div className="shadow-customShadow p-[4px] rounded-[4px]">
//               <p className="text-center border-b border-btn-secondary">
//                 {food?.meal_type_name}
//               </p>
//               <p>For Day : {food?.day_number}</p>
//               <div className="flex gap-x-[3px]">
//                 {Object.keys(food?.food_items)?.map(
//                   (key) => food?.food_items[key]
//                 )}
//               </div>
//               <p className="">
//                 Meal Price : {food?.meal_package_price}
//               </p>
//             </div>
//           ))
//       )} */}

//       {availableOption?.tour_package_default_option
//         ?.tour_package_food_option
//         ?.tour_package_available_meal_packages && (
//         <div className="max-h-[600px] overflow-y-scroll">
//           {
//             <div>
//               {Object.keys(
//                 availableOption?.tour_package_default_option
//                   ?.tour_package_food_option
//                   ?.tour_package_available_meal_packages
//               ).map((dayNumber, index) => (
//                 <div key={index} className="text-[14px]">
//                   <p className="bg-btn-secondary text-bg-white">
//                     Day {dayNumber} Meal Option
//                   </p>
//                   {Object.keys(
//                     availableOption?.tour_package_default_option
//                       ?.tour_package_food_option
//                       ?.tour_package_available_meal_packages[
//                       dayNumber
//                     ]
//                   ).map((mealType, ind) => (
//                     <div key={ind}>
//                       <p className="text-[18px] font-bold">
//                         {
//                           availableOption?.tour_package_default_option
//                             ?.tour_package_food_option
//                             ?.tour_package_available_meal_packages[
//                             dayNumber
//                           ][mealType]?.meal_type_name
//                         }
//                       </p>
//                       <div>
//                         {Object.values(
//                           availableOption?.tour_package_default_option
//                             ?.tour_package_food_option
//                             ?.tour_package_available_meal_packages[
//                             dayNumber
//                           ][mealType]?.food_items
//                         ).join(", ")}
//                       </div>
//                       <p>
//                         Meal Package Price:{" "}
//                         {
//                           availableOption?.tour_package_default_option
//                             ?.tour_package_food_option
//                             ?.tour_package_available_meal_packages[
//                             dayNumber
//                           ][mealType]?.meal_package_price
//                         }{" "}
//                         BDT
//                       </p>
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           }
//         </div>
//       )}
//     </div>
//   )}
// </div>

// {/* Transportation */}
// <div className="p-[10px] ">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Transportation</h2>
//     <button
//       onClick={() => handleTransportationOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>
// </div>

// {/* Transfer */}
// <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Transfer</h2>
//     <button
//       onClick={() => handleTransferOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>
//   <div>
//     <p>
//       Active :{" "}
//       {availableOption?.tour_package_default_option
//         ?.tour_package_transfer_option?.tour_package_option_is_active
//         ? "Yes"
//         : "No"}
//     </p>
//     <p>
//       Price Per Person :{" "}
//       {
//         availableOption?.tour_package_default_option
//           ?.tour_package_transfer_option
//           ?.tour_package_option_price_per_person
//       }{" "}
//       BDT
//     </p>
//   </div>
//   <div className="flex gap-x-[10px]">
//     {availableOption?.tour_package_default_option?.tour_package_transfer_option?.tour_package_available_transfer_packages?.map(
//       (transferData, ind) => (
//         <div
//           key={ind}
//           className="shadow-customShadow p-[6px] rounded-[6px]"
//         >
//           <h2>{transferData?.tour_package_transfer_route}</h2>
//           <p>
//             Mode :{" "}
//             {transferData?.tour_package_transportation_mode_name}
//           </p>
//           <p>
//             Provider :{" "}
//             {transferData?.tour_package_transportation_provider_name}
//           </p>
//           <p>
//             Suitable for : {transferData?.suitable_for_persons} Person
//           </p>
//           <p>Trip Type : {transferData?.trip_type}</p>
//           <p>Ac :{transferData?.is_ac ? "Yes" : "No"}</p>
//           <p>
//             Price : {transferData?.per_vehicle_per_trip_price} BDT
//           </p>
//         </div>
//       )
//     )}
//   </div>
// </div>

// {/* Guide */}
// <div className="mt-[10px] bg-bg-white p-[10px]">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Guide</h2>
//     <button
//       onClick={() => handleGuideOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>

//   <div>
//     <p>
//       Active :{" "}
//       {availableOption?.tour_package_default_option
//         ?.tour_package_guide_option?.tour_package_option_is_active
//         ? "Yes"
//         : "No"}
//     </p>
//     <p>
//       Price per person :{" "}
//       {
//         availableOption?.tour_package_default_option
//           ?.tour_package_guide_option
//           ?.tour_package_option_price_per_person
//       }{" "}
//       BDT
//     </p>
//   </div>
//   <div className="flex  gap-x-[10px] mt-[10px]">
//     {availableOption?.tour_package_default_option?.tour_package_guide_option?.tour_package_available_guide_packages?.map(
//       (guideData, ind) => (
//         <div
//           key={ind}
//           className="shadow-customShadow p-[6px] rounded-[4px]"
//         >
//           <p>For Day : {guideData?.number_of_guides_for_day}</p>
//           <p>Price : {guideData?.total_guide_price_for_day} BDT</p>
//         </div>
//       )
//     )}
//   </div>
// </div>
// {/* Spot */}
// <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
//   <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
//     <h2>Spot</h2>
//     <button
//       onClick={() => handleSpotOption(true)}
//       className="cursor-pointer"
//     >
//       <span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
//           />
//         </svg>
//       </span>
//     </button>
//   </div>

//   <div>
//     <p>
//       Active :{" "}
//       {availableOption?.tour_package_default_option
//         ?.tour_package_spot_entry_option
//         ?.tour_package_option_is_active
//         ? "Yes"
//         : "No"}
//     </p>
//     <p>
//       Price per person:{" "}
//       {
//         availableOption?.tour_package_default_option
//           ?.tour_package_spot_entry_option
//           ?.tour_package_option_price_per_person
//       }{" "}
//       BDT
//     </p>
//   </div>
//   <div className=" flex gap-x-[10px] ">
//     {availableOption?.tour_package_default_option?.tour_package_spot_entry_option?.tour_package_available_spot_entry_packages?.map(
//       (spotData, ind) => (
//         <div
//           key={ind}
//           className="shadow-customShadow p-[6px] rounded-[4px] mt-[10px]"
//         >
//           <p>{spotData?.activity?.activity_name}</p>
//           <p>{spotData?.activity?.activity_short_location}</p>
//           <p>
//             Price per person : {spotData?.spot_entry_price_per_person}{" "}
//             BDT
//           </p>
//         </div>
//       )
//     )}
//   </div>
// </div>
// </div> */}
