import React, { Fragment, useEffect, useState } from "react";
import Loading from "../../components/Loader/Loading";
import { MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";

const PackageType = ({
  setMarchantCreateData,
  setPackageSelect,
  marchantTourCreateData,
  tour_package_type_id,
}) => {
  const [packageTypeItemList, setPackageTypeItemList] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const getPackageTypeData = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TOURS_TOURS_PACKAGES_TYPE_GET_API_ALL}?requestId=123`
      )
      .then((res) => {
        // console.log(res?.data?.tour_package_types);
        setPackageTypeItemList(res?.data?.tour_package_types);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPackageTypeData();
  }, []);

  const handleBackButton = () => {
    setPackageSelect(null);
  };

  const handleButtonClick = (item) => {
    setMarchantCreateData((prevPackage) => ({
      ...prevPackage,
      tour_package: {
        ...prevPackage.tour_package,
        tour_package_type_id: {
          value: item.tour_package_type_id,
          label: item.tour_package_type_name,
          description: item.tour_package_type_description,
        },
      },
    }));

    setPackageSelect(null);
  };

  return (
    <>
      {isloading && (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      )}
      {!isloading && (
        <div>
          <p className="text-btn-secondary text-center mb-[24px] xl:text-[20px] md:text-[18px] text-[16px]">
            Package Type
          </p>

          <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton(null)}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>

          {packageTypeItemList &&
            packageTypeItemList?.map((item) => (
              <Fragment key={item.tour_package_type_id}>
                <div className="w-full flex md:flex-row flex-col xl:p-[16px] md:p-[8px] p-[16px] mb-[40px] rounded  bg-bg-white shadow">
                  <div className="md:w-[85%] w-full  xl:px-[16px] lg:px-[12px] md:px-[12px] px-[8px] flex flex-col items-start  xl:text-[24px] md:text-[20px] text-[14px]">
                    <div className="flex flex-col">
                      {/* <p className=" xl:text-[14px] md:text-[12px] text-[10px]   text-[#609bb1]">
              {item?.tour_package_type_description}
            </p> */}
                      <p className="bg-btn-secondary text-bg-white px-[12px] py-[6px] xl:text-[20px] md:text-[18px] text-[16px] rounded">
                        {item?.tour_package_type_name}
                      </p>
                    </div>
                    <div>
                      <p className="text-[16px] leading-5 mt-[8px] text-[#2f5665]">
                        {item?.tour_package_type_description}
                      </p>
                    </div>
                  </div>
                  <div className="md:w-[15%] md:my-0 my-[16px] w-full flex items-center justify-center">
                    {marchantTourCreateData?.tour_package?.tour_package_type_id
                      ?.value === item?.tour_package_type_id ? (
                      <button className="bg-btn-orange text-bg-white w-full px-[40px] py-[12px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-orange rounded-md shadow-md transition-all duration-500 hover:text-btn-orange hover:shadow-lg ">
                        Selected
                      </button>
                    ) : (
                      <TourMerchantButton
                        name={"Select"}
                        width={"w-full"}
                        onClick={() => handleButtonClick(item)}
                      ></TourMerchantButton>
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      )}
    </>
  );
};

export default PackageType;
