import React from "react";
import TourBookings from "./TourBookings";

export default function MerchantTourBookingsLayout() {
  return (
    <div>
      <TourBookings />
    </div>
  );
}
