import React from "react";
import { useLocation, useNavigate } from "react-router";
import { DashboardRoutes, merchantExcursionRoute } from "../contants/Constants";

const ExcursionNav = ({ marked }) => {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log(location, "locationlocation");
  return (
    <div>
      <ul className="list-none w-full overflow-hidden fixed top-[88px] z-[50] h-[39px] text-xs py-3 bg-btn-secondary text-btn-base flex justify-start gap-x-[100px] px-6 2xl:px-52">
        <li className="float-left">
          {/* <button
            onClick={() => navigate(merchantExcursionRoute.EXCURSION_CREATE)}
            className={`${
              location.pathname === merchantExcursionRoute.EXCURSION_CREATE
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Excursion Dashboard
          </button> */}
          <button
            onClick={() => navigate(merchantExcursionRoute.EXCURSION_DASHBOARD)}
            className={`${
              location.pathname === merchantExcursionRoute.EXCURSION_DASHBOARD
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Excursion Dashboard
          </button>
        </li>

        <li className="float-left">
          <button
            onClick={() => navigate(merchantExcursionRoute.EXCURSION_CREATE)}
            className={`${
              location.pathname === merchantExcursionRoute.EXCURSION_CREATE
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Excursion create 
          </button>
        </li>
        <li className="float-left">
          <button
            onClick={() => navigate(merchantExcursionRoute.EXCURSION_CREATE_OPTION)}
            className={`${
              location.pathname === merchantExcursionRoute.EXCURSION_CREATE_OPTION
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Excursion option create 
          </button>
        </li>
        <li className="float-left">
          <button
            onClick={() => navigate(merchantExcursionRoute.EXCURSION_CREATE_MERCHANT_PRICE_TOOLS)}
            className={`${
              location.pathname === merchantExcursionRoute.EXCURSION_CREATE_MERCHANT_PRICE_TOOLS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Excursion Price Tools
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ExcursionNav;
