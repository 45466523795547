import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { merchantTourRoute } from "../contants/Constants";
import { useDispatch } from "react-redux";
import { setTourStepperValue } from "../store/marchantDashboard/categoryTour/tourSetupStepper";

const TourCard = ({ tours, subscribeButton }) => {
  // console.log({ tours });
  
  const navigate = useNavigate();
  const {
    thumb_image_url,
    subscribed_tour_name,
    destination_location_name,
    subscribed_tour_itinerary,
    tour_title,
    subscribed_tour_reporting_place,
    subscribed_tour_reporting_time,
    tour_specialities,
  } = tours;
  const [itineraryId, setItineraryId] = useState(0);
  const [specialtiesId, setSpecialtiesId] = useState(0);
  const [current, setCurrent] = useState(0);
  const handleItineraryView = () => {
    setItineraryId(
      (prevId) => (prevId + 1) % subscribed_tour_itinerary?.length
    );
    if (current === subscribed_tour_itinerary.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };
  const handleSpecialtiesIdView = () => {
    setSpecialtiesId((prevId) => (prevId + 1) % tour_specialities?.length);
  };
  useEffect(() => {
    setCurrent(0);
  }, [tours]);

  const dispatch = useDispatch();
  const handleSetStepperValue = (value) => {
    dispatch(setTourStepperValue(value));
  };

  const changeUrl = () => {
    handleSetStepperValue(1)
    navigate(
      `${merchantTourRoute.TOURONE}tour_id=${tours?.created_tour_id}&subscribeId=${tours?.subscribed_tour_id}`
    )
  }

  const iconObject = {
    star: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14.6184 5.29376L10.6512 4.71719L8.87779 1.12188C8.82935 1.02344 8.74967 0.943756 8.65123 0.895318C8.40435 0.773443 8.10435 0.875006 7.98092 1.12188L6.20748 4.71719L2.24029 5.29376C2.13091 5.30938 2.03091 5.36094 1.95435 5.43907C1.86179 5.5342 1.81079 5.66219 1.81254 5.79491C1.8143 5.92764 1.86868 6.05423 1.96373 6.14688L4.83404 8.94532L4.15591 12.8969C4.14001 12.9888 4.15018 13.0833 4.18528 13.1698C4.22037 13.2562 4.27898 13.3311 4.35446 13.3859C4.42994 13.4407 4.51927 13.4733 4.61232 13.4799C4.70537 13.4866 4.79842 13.467 4.88091 13.4234L8.42935 11.5578L11.9778 13.4234C12.0747 13.475 12.1872 13.4922 12.295 13.4734C12.5669 13.4266 12.7497 13.1688 12.7028 12.8969L12.0247 8.94532L14.895 6.14688C14.9731 6.07032 15.0247 5.97032 15.0403 5.86094C15.0825 5.5875 14.8919 5.33438 14.6184 5.29376Z"
          fill="#F6D731"
        />
      </svg>
    ),
    location: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00252 1.19718C5.77216 1.19718 4.59219 1.68594 3.72219 2.55593C2.8522 3.42593 2.36344 4.6059 2.36344 5.83626C2.36344 7.63253 3.53263 9.37796 4.81823 10.735C5.44879 11.4006 6.08139 11.9455 6.55701 12.3243C6.72933 12.4615 6.88041 12.5764 7.00251 12.6668C7.12462 12.5764 7.2757 12.4615 7.44802 12.3243C7.92365 11.9455 8.55624 11.4006 9.1868 10.735C10.4724 9.37796 11.6416 7.63253 11.6416 5.83626C11.6416 4.6059 11.1528 3.42593 10.2828 2.55593C9.41284 1.68594 8.23287 1.19718 7.00252 1.19718ZM7.00252 13.4018C6.67048 13.8998 6.67016 13.8996 6.67016 13.8996L6.66847 13.8985L6.66456 13.8959L6.65116 13.8868C6.63979 13.8791 6.62359 13.868 6.60288 13.8537C6.56146 13.825 6.50197 13.7832 6.42699 13.729C6.2771 13.6208 6.06495 13.4628 5.81125 13.2608C5.30481 12.8575 4.62799 12.2749 3.94913 11.5583C2.6159 10.151 1.16626 8.11372 1.16626 5.83626C1.16626 4.28838 1.78115 2.80391 2.87566 1.7094C3.97017 0.614889 5.45464 0 7.00252 0C8.55039 0 10.0349 0.614889 11.1294 1.7094C12.2239 2.80391 12.8388 4.28838 12.8388 5.83626C12.8388 8.11372 11.3891 10.151 10.0559 11.5583C9.37704 12.2749 8.70022 12.8575 8.19378 13.2608C7.94008 13.4628 7.72793 13.6208 7.57804 13.729C7.50306 13.7832 7.44358 13.825 7.40216 13.8537C7.38144 13.868 7.36524 13.8791 7.35387 13.8868L7.34047 13.8959L7.33656 13.8985L7.33532 13.8993C7.33532 13.8993 7.33455 13.8998 7.00252 13.4018ZM7.00252 13.4018L7.33532 13.8993C7.13425 14.0334 6.87122 14.0337 6.67016 13.8996L7.00252 13.4018Z"
          fill="#466773"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00502 4.60538C6.23364 4.60538 5.60831 5.23071 5.60831 6.00209C5.60831 6.77348 6.23364 7.39881 7.00502 7.39881C7.77641 7.39881 8.40174 6.77348 8.40174 6.00209C8.40174 5.23071 7.77641 4.60538 7.00502 4.60538ZM4.41113 6.00209C4.41113 4.56953 5.57246 3.4082 7.00502 3.4082C8.43759 3.4082 9.59892 4.56953 9.59892 6.00209C9.59892 7.43466 8.43759 8.59599 7.00502 8.59599C5.57246 8.59599 4.41113 7.43466 4.41113 6.00209Z"
          fill="#466773"
        />
      </svg>
    ),
    timer: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 2C6.81331 2 5.65328 2.35189 4.66658 3.01118C3.67989 3.67047 2.91085 4.60754 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C13.9983 6.40923 13.3656 4.88411 12.2407 3.75926C11.1159 2.63441 9.59077 2.00172 8 2V2ZM8 13C7.0111 13 6.0444 12.7068 5.22215 12.1573C4.39991 11.6079 3.75904 10.827 3.38061 9.91342C3.00217 8.99979 2.90315 7.99445 3.09608 7.02455C3.289 6.05464 3.76521 5.16373 4.46447 4.46447C5.16373 3.7652 6.05465 3.289 7.02455 3.09607C7.99446 2.90315 8.99979 3.00216 9.91342 3.3806C10.8271 3.75904 11.6079 4.3999 12.1574 5.22215C12.7068 6.04439 13 7.01109 13 8C12.9985 9.32564 12.4713 10.5966 11.5339 11.5339C10.5966 12.4713 9.32564 12.9985 8 13ZM9 8C9.0008 8.17573 8.95527 8.34856 8.86801 8.50109C8.78075 8.65362 8.65483 8.78046 8.50295 8.86884C8.35106 8.95722 8.17857 9.00402 8.00284 9.00452C7.82712 9.00501 7.65436 8.9592 7.50198 8.87168C7.34959 8.78416 7.22296 8.65803 7.13484 8.50599C7.04672 8.35396 7.00021 8.18139 7.00001 8.00566C6.99981 7.82993 7.04592 7.65725 7.1337 7.50502C7.22148 7.35278 7.34782 7.22637 7.5 7.1385V5.5C7.5 5.36739 7.55268 5.24022 7.64645 5.14645C7.74022 5.05268 7.86739 5 8 5C8.13261 5 8.25979 5.05268 8.35356 5.14645C8.44732 5.24022 8.5 5.36739 8.5 5.5V7.1385C8.65156 7.22565 8.77754 7.35113 8.8653 7.50233C8.95306 7.65354 8.99951 7.82517 9 8Z"
          fill="#466773"
        />
      </svg>
    ),
    locationTwo: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00252 1.69718C5.77216 1.69718 4.59219 2.18594 3.72219 3.05593C2.8522 3.92593 2.36344 5.1059 2.36344 6.33626C2.36344 8.13253 3.53263 9.87796 4.81823 11.235C5.44879 11.9006 6.08139 12.4455 6.55701 12.8243C6.72933 12.9615 6.88041 13.0764 7.00251 13.1668C7.12462 13.0764 7.2757 12.9615 7.44802 12.8243C7.92365 12.4455 8.55624 11.9006 9.1868 11.235C10.4724 9.87796 11.6416 8.13253 11.6416 6.33626C11.6416 5.1059 11.1528 3.92593 10.2828 3.05593C9.41284 2.18594 8.23287 1.69718 7.00252 1.69718ZM7.00252 13.9018C6.67048 14.3998 6.67016 14.3996 6.67016 14.3996L6.66847 14.3985L6.66456 14.3959L6.65116 14.3868C6.63979 14.3791 6.62359 14.368 6.60288 14.3537C6.56146 14.325 6.50197 14.2832 6.42699 14.229C6.2771 14.1208 6.06495 13.9628 5.81125 13.7608C5.30481 13.3575 4.62799 12.7749 3.94913 12.0583C2.6159 10.651 1.16626 8.61372 1.16626 6.33626C1.16626 4.78838 1.78115 3.30391 2.87566 2.2094C3.97017 1.11489 5.45464 0.5 7.00252 0.5C8.55039 0.5 10.0349 1.11489 11.1294 2.2094C12.2239 3.30391 12.8388 4.78838 12.8388 6.33626C12.8388 8.61372 11.3891 10.651 10.0559 12.0583C9.37704 12.7749 8.70022 13.3575 8.19378 13.7608C7.94008 13.9628 7.72793 14.1208 7.57804 14.229C7.50306 14.2832 7.44358 14.325 7.40216 14.3537C7.38144 14.368 7.36524 14.3791 7.35387 14.3868L7.34047 14.3959L7.33656 14.3985L7.33532 14.3993C7.33532 14.3993 7.33455 14.3998 7.00252 13.9018ZM7.00252 13.9018L7.33532 14.3993C7.13425 14.5334 6.87122 14.5337 6.67016 14.3996L7.00252 13.9018Z"
          fill="#142B33"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00502 5.10538C6.23364 5.10538 5.60831 5.73071 5.60831 6.50209C5.60831 7.27348 6.23364 7.89881 7.00502 7.89881C7.77641 7.89881 8.40174 7.27348 8.40174 6.50209C8.40174 5.73071 7.77641 5.10538 7.00502 5.10538ZM4.41113 6.50209C4.41113 5.06953 5.57246 3.9082 7.00502 3.9082C8.43759 3.9082 9.59892 5.06953 9.59892 6.50209C9.59892 7.93466 8.43759 9.09599 7.00502 9.09599C5.57246 9.09599 4.41113 7.93466 4.41113 6.50209Z"
          fill="#142B33"
        />
      </svg>
    ),
    rightArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className="hover:animate-pulse cursor-pointer"
        onClick={() => handleItineraryView()}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0C13.9705 0 18 4.02955 18 9C18 13.9705 13.9705 18 9 18C4.02955 18 0 13.9705 0 9C0 4.02955 4.02955 0 9 0ZM7.19427 6.30573C7.04523 6.15142 6.96277 5.94474 6.96463 5.73022C6.96649 5.51569 7.05254 5.31048 7.20424 5.15878C7.35594 5.00709 7.56115 4.92104 7.77567 4.91918C7.9902 4.91731 8.19687 4.99978 8.35118 5.14882L11.6239 8.42155C11.7773 8.57498 11.8635 8.78305 11.8635 9C11.8635 9.21695 11.7773 9.42502 11.6239 9.57845L8.35118 12.8512C8.19687 13.0002 7.9902 13.0827 7.77567 13.0808C7.56115 13.079 7.35594 12.9929 7.20424 12.8412C7.05254 12.6895 6.96649 12.4843 6.96463 12.2698C6.96277 12.0553 7.04523 11.8486 7.19427 11.6943L9.88855 9L7.19427 6.30573Z"
          fill="#4094B2"
        />
      </svg>
    ),
    specialties: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_11548_2041)">
          <path
            d="M14.0813 8.66614C14.59 8.66561 15.0777 8.4633 15.4375 8.10359C15.7972 7.74389 15.9995 7.25618 16 6.74748C15.9993 6.20976 15.8718 5.67978 15.6278 5.20058C15.3839 4.72138 15.0304 4.30645 14.596 3.98948L10.006 0.652143C9.42371 0.226639 8.72119 -0.00268555 8 -0.00268555C7.27881 -0.00268555 6.57629 0.226639 5.994 0.652143L1.404 3.98948C0.969635 4.30645 0.616132 4.72138 0.372187 5.20058C0.128241 5.67978 0.000728883 6.20976 0 6.74748C0.000529436 7.25618 0.202844 7.74389 0.562549 8.10359C0.922254 8.4633 1.40997 8.66561 1.91867 8.66614H7.33333V15.3328C7.33333 15.5096 7.40357 15.6792 7.5286 15.8042C7.65362 15.9292 7.82319 15.9995 8 15.9995C8.17681 15.9995 8.34638 15.9292 8.47141 15.8042C8.59643 15.6792 8.66667 15.5096 8.66667 15.3328V8.66614H14.0813ZM10.3333 5.38281C10.5513 5.71043 10.6673 6.0953 10.6667 6.48881V7.33281H8.66667V2.87481L10.3333 5.38281ZM13.812 5.06614C14.0767 5.25938 14.2921 5.51236 14.4406 5.80452C14.5891 6.09667 14.6665 6.41975 14.6667 6.74748C14.6665 6.90266 14.6048 7.05144 14.495 7.16117C14.3853 7.27091 14.2365 7.33263 14.0813 7.33281H12V6.48881C12.0006 5.83266 11.8069 5.19101 11.4433 4.64481L9.77667 2.13148L13.812 5.06614ZM1.33333 6.74748C1.33346 6.41975 1.41092 6.09667 1.55943 5.80452C1.70794 5.51236 1.9233 5.25938 2.188 5.06614L6.226 2.13281L4.55933 4.64614C4.19505 5.19167 4.00043 5.83284 4 6.48881V7.33281H1.91867C1.76348 7.33263 1.6147 7.27091 1.50497 7.16117C1.39524 7.05144 1.33351 6.90266 1.33333 6.74748ZM5.33333 7.33281V6.48881C5.33269 6.0953 5.44868 5.71043 5.66667 5.38281L7.33333 2.87481V7.33281H5.33333Z"
            fill="#374957"
          />
          <path
            d="M2.66667 12.6667H1.33333V10.6667C1.33333 10.4899 1.2631 10.3203 1.13807 10.1953C1.01305 10.0702 0.843478 10 0.666667 10C0.489856 10 0.320286 10.0702 0.195262 10.1953C0.0702379 10.3203 0 10.4899 0 10.6667L0 15.3333C0 15.5101 0.0702379 15.6797 0.195262 15.8047C0.320286 15.9298 0.489856 16 0.666667 16C0.843478 16 1.01305 15.9298 1.13807 15.8047C1.2631 15.6797 1.33333 15.5101 1.33333 15.3333V14H2.66667C2.84348 14 3.01305 14.0702 3.13807 14.1953C3.2631 14.3203 3.33333 14.4899 3.33333 14.6667V15.3333C3.33333 15.5101 3.40357 15.6797 3.5286 15.8047C3.65362 15.9298 3.82319 16 4 16C4.17681 16 4.34638 15.9298 4.4714 15.8047C4.59643 15.6797 4.66667 15.5101 4.66667 15.3333V14.6667C4.66667 14.1362 4.45595 13.6275 4.08088 13.2525C3.70581 12.8774 3.1971 12.6667 2.66667 12.6667Z"
            fill="#374957"
          />
          <path
            d="M15.3335 10C15.1567 10 14.9871 10.0702 14.8621 10.1953C14.7371 10.3203 14.6668 10.4899 14.6668 10.6667V12.6667H13.3335C12.8031 12.6667 12.2944 12.8774 11.9193 13.2525C11.5442 13.6275 11.3335 14.1362 11.3335 14.6667V15.3333C11.3335 15.5101 11.4037 15.6797 11.5288 15.8047C11.6538 15.9298 11.8234 16 12.0002 16C12.177 16 12.3465 15.9298 12.4716 15.8047C12.5966 15.6797 12.6668 15.5101 12.6668 15.3333V14.6667C12.6668 14.4899 12.7371 14.3203 12.8621 14.1953C12.9871 14.0702 13.1567 14 13.3335 14H14.6668V15.3333C14.6668 15.5101 14.7371 15.6797 14.8621 15.8047C14.9871 15.9298 15.1567 16 15.3335 16C15.5103 16 15.6799 15.9298 15.8049 15.8047C15.9299 15.6797 16.0002 15.5101 16.0002 15.3333V10.6667C16.0002 10.4899 15.9299 10.3203 15.8049 10.1953C15.6799 10.0702 15.5103 10 15.3335 10Z"
            fill="#374957"
          />
        </g>
        <defs>
          <clipPath id="clip0_11548_2041">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    rightArrowSpecialties: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className="hover:animate-pulse cursor-pointer"
        onClick={() => handleSpecialtiesIdView()}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0C13.9705 0 18 4.02955 18 9C18 13.9705 13.9705 18 9 18C4.02955 18 0 13.9705 0 9C0 4.02955 4.02955 0 9 0ZM7.19427 6.30573C7.04523 6.15142 6.96277 5.94474 6.96463 5.73022C6.96649 5.51569 7.05254 5.31048 7.20424 5.15878C7.35594 5.00709 7.56115 4.92104 7.77567 4.91918C7.9902 4.91731 8.19687 4.99978 8.35118 5.14882L11.6239 8.42155C11.7773 8.57498 11.8635 8.78305 11.8635 9C11.8635 9.21695 11.7773 9.42502 11.6239 9.57845L8.35118 12.8512C8.19687 13.0002 7.9902 13.0827 7.77567 13.0808C7.56115 13.079 7.35594 12.9929 7.20424 12.8412C7.05254 12.6895 6.96649 12.4843 6.96463 12.2698C6.96277 12.0553 7.04523 11.8486 7.19427 11.6943L9.88855 9L7.19427 6.30573Z"
          fill="#4094B2"
        />
      </svg>
    ),
  };

  return (
    <div className="rounded-[16px] w-[304px] shadow-customTourCard">
      <div className="pt-[8px] px-[8px]">
        <img
          className="w-full h-[178px] bg-center rounded-[8px]"
          src={thumb_image_url}
          alt={tour_title}
        />
      </div>
      {/* Content */}
      <div className="pt-[8px] pb-[24px]">
        <div className="flex flex-col gap-y-[8px]">
          {/* Content 1 */}
          {subscribed_tour_name && (
            <div className="flex flex-col gap-y-[8px] px-[8px]">
              <div className="flex flex-col gap-[4px]">
                <p className={`text-tour-text font-medium `}>
                  {subscribed_tour_name}
                </p>
                <div>
                  <div className="flex items-center gap-[4px]">
                    {iconObject?.star}
                    <p className="text-[14px] font-medium text-tour-text">
                      4,9{" "}
                      <span className="text-[12px] font-normal">
                        (144 Reviews)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-[4px] text-[14px] text-tour-primary font-normal">
                {iconObject?.location}
                <p>{destination_location_name}</p>
              </div>
              {/* <div className="flex items-center gap-x-[4px] text-[14px] text-tour-primary font-normal">
              {iconObject?.timer}
              <p>
                {number_of_days} Days, {number_of_nights} Nights
              </p>
            </div> */}
            </div>
          )}
          {/* Line */}
          <div className="bg-tour-secondaryHint h-[1px] px-[0px]"></div>
          {/* Content 2 */}
          {subscribed_tour_itinerary && (
            <div className="flex flex-col gap-y-[8px] px-[8px] pb-[4px]">
              <p className="text-[18px] text-tour-text font-medium">
                Itinerary:{" "}
              </p>
              <div className="flex items-center gap-x-[4px] text-[14px] text-tour-text font-normal">
                {iconObject?.location}
                <p>
                  {"Meeting point: "}
                  <span className="text-tour-text/80">
                    {subscribed_tour_reporting_place}
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-x-[4px] text-[14px] text-tour-text font-normal">
                {iconObject?.timer}
                <p>
                  {"Meeting Time: "}
                  <span className="text-tour-text/80">
                    {subscribed_tour_reporting_time}
                  </span>
                </p>
              </div>
              <div className="flex items-end">
                <div className="w-[249px] flex flex-col gap-y-[8px]">
                  <p className="text-[14px] text-tour-text font-medium">
                    {subscribed_tour_itinerary[itineraryId]?.activity_name}
                  </p>
                  <div className="flex items-center gap-x-[4px]">
                    {iconObject?.location}
                    <p className="text-[12px] text-tour-text ">
                      {subscribed_tour_itinerary[itineraryId]?.short_address}
                    </p>
                  </div>
                  <div className="flex items-center text-tour-text gap-x-[4px]">
                    {"Day: "}
                    <p className="text-[12px] text-tour-border ">
                      {
                        subscribed_tour_itinerary[itineraryId]
                          ?.activity_day_number
                      }
                    </p>
                  </div>
                  <div className="flex items-center text-tour-text gap-x-[4px]">
                    {"Start Time: "}
                    <p className="text-[12px] text-tour-border ">
                      {
                        subscribed_tour_itinerary[itineraryId]
                          ?.activity_start_time
                      }
                    </p>
                  </div>
                  <div className="flex items-center text-tour-text gap-x-[4px]">
                    {"End Time: "}
                    <p className="text-[12px] text-tour-border ">
                      {
                        subscribed_tour_itinerary[itineraryId]
                          ?.activity_end_time
                      }
                    </p>
                  </div>
                  <div>
                    <div className="overflow-hidden rounded-[8px]">
                      <div
                        className={`flex transition ease-out duration-300 `}
                        style={{ transform: `translateX(-${current * 100}%)` }}
                      >
                        {subscribed_tour_itinerary.map((item, id) => {
                          return (
                            <img
                              key={id}
                              className="h-[128px] min-w-[249px] "
                              src={item?.activity_images[0]?.activity_image_url}
                              alt={
                                subscribed_tour_itinerary[current]
                                  ?.activity_image_file_name
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[41px]  flex items-center h-[128px]">
                  <div className="py-[8px] px-[12px]">
                    {iconObject?.rightArrow}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Line */}
          <div className="bg-[#DDE7EB] h-[1px] px-[0px]"></div>
          {/* CustomCarousel */}
          {/* <div className="w-[68%] m-auto">
            <TourCarousel></TourCarousel>
          </div> */}
          {/* Content 3 */}
          {tour_specialities && (
            <div className="flex flex-col gap-y-[24px] px-[8px]">
              <div className="flex flex-col gap-y-[8px]">
                <p className="text-tour-text text-[18px] font-medium">
                  Tour Specialties:{" "}
                </p>
                <div className="flex">
                  <div className="w-[247px] flex items-start justify-center  rounded-[8px] border-[1px] border-[#DDE7EB] py-[12px] px-[8px]">
                    <div className="flex gap-[8px] items-center">
                      <div>{iconObject?.specialties}</div>
                      <p className="w-[207px]">
                        {
                          tour_specialities[specialtiesId]
                            ?.tour_speciality_title
                        }
                      </p>
                    </div>
                  </div>
                  <div className="w-[41px] py-[12px] px-[8px] flex items-center justify-center">
                    {iconObject?.rightArrowSpecialties}
                  </div>
                </div>
              </div>
            </div>
          )}

          {subscribeButton && (
            <div className="px-[16px] pt-[8px]">
              <button
                className="px-[16px] py-[10px] rounded-[50px] bg-[#4094B2] w-full text-[14px] text-bg-white font-normal duration-300 hover:bg-tour-secondaryShade"
                  onClick={() =>
                    changeUrl()
                  }
              >
                View
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TourCard;
