import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const CustomInputWithDropdown = ({
  options,
  setSelectedOption,
  selectedOption,
  labelText,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    toggleDropdown();
  };

  return (
    <div>
      <label>{labelText}</label>
      <div className="custom-dropdown w-full h-full  relative">
        <div className="relative h-[49px] px-[8px] bg-bg-primary border border-[#ddd] rounded-[4px] overflow-hidden ">
          <input
            type="text"
            value={selectedOption}
            placeholder="Select an option"
            onClick={toggleDropdown}
            readOnly
            className="w-full h-full bg-bg-primary"
          />
          <span
            onClick={toggleDropdown}
            className="absolute right-0 bg-btn-primary w-[50px] h-full  cursor-pointer"
          >
            <span className="flex justify-center items-center h-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[25px] h-[25px] text-bg-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </span>
        </div>

        <OutsideClickHandler
          onOutsideClick={() => {
            setShowDropdown(false);
          }}
        >
          {showDropdown && (
            <ul className="absolute w-full bg-bg-primary z-10 dropdown-options shadow-customShadow py-[8px] rounded-b-[4px] flex flex-col gap-y-[8px]">
              {options?.map((option, index) => (
                <li
                  className="hover:bg-btn-secondary px-[12px] cursor-pointer hover:text-bg-white py-[4px]"
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  {option?.label ? option.label : option}
                </li>
              ))}
            </ul>
          )}
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default CustomInputWithDropdown;
