import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { merchantRoute, merchantTourRoute } from "../../contants/Constants";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../contants/Endpoints";
import SliderCart from "../../pages/admin/NewTourCreateToll/SliderCart";
import { authAxios } from "../../utils/axiosWrapper";
import { convertStringToObject } from "./ParentPackageSelected";
import TourCard from "../TourCard";
import Food from "./PackageV2/Food";
import Accommondation from "./PackageV2/Accommondation";
import Transfer from "./PackageV2/Transfer";
import Transportation from "./PackageV2/Transportation";
import Guide from "./PackageV2/Guide";
import SpotFeeV2 from "./PackageV2/SpotFeeV2";
import TourPackageSelections from "./TourPackageSelections";
import { toast } from "react-toastify";

export default function TourPackageSeleted() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNewListItem, setSelectedNewListItem] = useState("");
  const [getTours, setGetTours] = useState();
  const [foodPackageList, setFoodPackageList] = useState([]);
  const [accommondationPackageList, setAccommondationPackageList] = useState(
    []
  );
  const [transferPackageList, setTransferPackageList] = useState([]);
  const [transportationPackages, setTransportationPackages] = useState([]);
  const [guidePackageList, setGuidePackageList] = useState([]);
  const [spotEntry, setSpotEntry] = useState([]);
  const [availablePackage, setAvailablePackage] = useState([]);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [editAblePackage, setEditAblePackage] = useState({});

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
      })
      .catch((err) => {});
  }, []);

  function mergeData(a, b) {
    const c = {
      tour_package_types: b?.tour_package_types?.map((item) => {
        const matchingPackage = a?.tour_packages.find(
          (packages) =>
            packages.tour_package_type_id === item.tour_package_type_id
        );

        if (matchingPackage) {
          return {
            tour_package_type_id: matchingPackage.tour_package_type_id,
            tour_package_id: matchingPackage.tour_package_id,
            tour_package_type_name: matchingPackage.tour_package_type_name,
            tour_package_type_description:
              matchingPackage.tour_package_description,
            tour_package_total_options:
              matchingPackage.tour_package_total_options,
            tour_package_default_option_price_per_person:
              matchingPackage.tour_package_default_option_price_per_person,
            priceSet: true,
          };
        } else {
          return item;
        }
      }),
    };

    return c;
  }

  const getSpotEntriy = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setSpotEntry(res?.data);
      })
      .catch((err) => {});
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setGuidePackageList(res?.data);
      })
      .catch((err) => {});
  };

  const getTransportationPackage = () => {
    // merchant/tour/transportation/package/get/all/by/
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE}${convertData?.subscribeId}?requestId=10`
      )
      .then((res) => {
        setTransportationPackages(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${convertData?.subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccommodation = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_GET_API}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setAccommondationPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransferPackage = () => {
    // merchant/tour/transfer/package/get/all/by/7?requestId=123
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSFER_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setTransferPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAvailibilityPackageTableData = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_AVAILABLE_PACKAGE_DATA}${convertData.subscribeId}?requestId=package_data`
      )
      .then((res) => {
        setAvailablePackage(res?.data?.datePackageMap);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMealPackage();
    getAccommodation();
    getTransferPackage();
    getTransportationPackage();
    getGuidePackage();
    getSpotEntriy();
    getAvailibilityPackageTableData();
  }, []);
  const itemList = [
    { label: "Food", route: "add-food" },
    { label: "Accommodation", route: "add-accommondation" },
    { label: "Transfer", route: "add-transfer" },
    { label: "Transportation", route: "add-transportaion" },
    { label: "Guide", route: "add-guide" },
    { label: "Spot Fee", route: "add-spot-fee" },
  ];
  const handleAbibilityPage = () => {
    navigate(
      `${merchantTourRoute.MARCHANT_SET_ABIBILITY_V2}tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}&number_of_days=${getTours.number_of_days}&number_of_nights=${getTours.number_of_nights}`
    );
  };

  const [itemListNew, setItemListNew] = useState([
    {
      label: "Food",
      route: "add-food",
      toggle: true,
    },
    {
      label: "Accommodation",
      route: "add-accommodation",
      toggle: false,
    },
    {
      label: "Transfer",
      route: "add-transfer",
      toggle: false,
    },
    {
      label: "Transportation",
      route: "add-transportation",
      toggle: false,
    },
    {
      label: "Guide",
      route: "add-guide",
      toggle: false,
    },
    {
      label: "Spot-Fee",
      route: "add-spot-fee",
      toggle: false,
    },
  ]);

  const iconObject = {
    dropRight: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M6.75 15L12.75 9L6.75 3"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dropDown: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M3 6.75L9 12.75L15 6.75"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };

  const handleToggleClick = (index) => {
    setItemListNew((prev) =>
      prev.map((item, i) => ({
        ...item,
        toggle: i === index ? !item.toggle : false,
      }))
    );
  };

  // For passing the label right component
  useEffect(() => {
    const selectedItem = itemListNew.find((item) => item.toggle);
    setSelectedNewListItem({
      name: selectedItem ? selectedItem.label : "",
      toggle: selectedItem ? selectedItem.toggle : false,
    });
  }, [itemListNew]);

  const changeSelectedNewItemList = (name, toggle) => {
    setSelectedNewListItem({ name: name, toggle: toggle });
    setItemListNew((prevList) => {
      return prevList?.map((item) => {
        return { ...item, toggle: item?.label === name ? toggle : false };
      });
    });
  };

  const handleDisabledDateSchedule = (available_tour_package_id) => {
    authAxios
      .put(
        `${baseUrl}${MERCHANT_TOUR_ENDPOINT.MERCHANT_PUT_TOUR_PACKAGE_AVAILABILITY_DE_ACTIVATE_BY_AVAILABILITY_TOUR_PACKAGE_ID}?available-tour-package-id=${available_tour_package_id}&requestId=1234`
      )
      .then((res) => {
        // console.log(res)
        // getAvailabilityPackageTableData();
        getAvailibilityPackageTableData();
        toast.success("Successfully disabled this date.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Disabled failed!");
      });
  };

  // const getAvailabilityPackageTableData = () => {
  //   authAxios
  //     .get(
  //       `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_AVAILABLE_PACKAGE_DATA}${subscribeId}?requestId=package_data`
  //     )
  //     .then((res) => {
  //       setAvailablePackage(res?.data?.datePackageMap);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getAvailabilityPackageTableData();
  // }, []);


  return (
    <div>
      {/* New Design Start */}
      <div className="w-full flex gap-[32px] mb-[120px]">
        {/* Section 1 */}
        <div className="w-[304px]">
          {getTours && (
            <TourCard tours={getTours} subscribeButton={false}></TourCard>
          )}
        </div>
        {/* Section 2 */}
        <div className="w-[357px]">
          <p className="text-tour-text text-[18px] font-medium pb-[24px]">
            Amenities{" "}
          </p>
          <div>
            <ul>
              {itemListNew?.map((item, index) => (
                <Fragment key={index}>
                  <li key={index} onClick={() => handleToggleClick(index)} className={`border-l-[2px] ${(selectedNewListItem?.name === item?.label && selectedNewListItem?.toggle === item?.toggle ) ? "border-tour-primary bg-[#F5F9FA]" : "border-tour-input "}`}>
                  <p className={`py-[9px] pl-[16px] pr-[8px] cursor-pointer text-[16px] text-tour-text font-medium`}>{item?.label}</p>
                  
                </li>
                <div className="border-l-[2px] border-tour-input">
                  
                  {item?.label === "Food" && item?.toggle === true && (
                   
                      <Food
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                      changeSelectedNewItemList={changeSelectedNewItemList}
                    ></Food>
                  )}
                  {item?.label === "Accommodation" && item?.toggle === true && (
                  
                     <Accommondation
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                      changeSelectedNewItemList={changeSelectedNewItemList}
                    ></Accommondation>
                   
                  )}
                  {item?.label === "Transfer" && item?.toggle === true && (
                      <Transfer
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                      changeSelectedNewItemList={changeSelectedNewItemList}
                    ></Transfer>
                  )}
                  {item?.label === "Transportation" &&
                    item?.toggle === true && (
                        <Transportation
                        setIsLoading={setIsLoading}
                        editAblePackage={editAblePackage}
                        changeSelectedNewItemList={changeSelectedNewItemList}
                      ></Transportation>
                    )}
                  {item?.label === "Guide" && item?.toggle === true && (
                    
                      <Guide
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                      changeSelectedNewItemList={changeSelectedNewItemList}
                    ></Guide>
                    
                  )}
                  {item?.label === "Spot-Fee" && item?.toggle === true && (
                    
                      <SpotFeeV2
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                      changeSelectedNewItemList={changeSelectedNewItemList}
                    ></SpotFeeV2>
                   
                  )}
                </div>
                </Fragment>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="w-[357px] flex flex-col gap-[24px]">
          <div className="">
          <p className="text-tour-text text-[18px] font-medium pb-[24px]">Amenities </p>
          {itemListNew?.map((item, index) => (
            <div className="w-full flex flex-col gap-y-[24px] border-l-[2px]">
              <div className=" " key={index}>
                <div
                  onClick={() => handleToggleClick(index)}
                  key={index}
                  className="flex justify-between items-center  bg-bg-white border-b-[1px] border-tour-border   h-[32px] cursor-pointer border-l-[2px] px-[8px]"
                >
                  <p className="text-[18px] text-tour-text flex justify-start items-center gap-x-[10px] border-tour-primary ">
                    {item?.label}{" "}
                    <span>
                      {item?.label === "Food"
                        ? "x" + foodPackageList?.length
                        : item?.label === "Accommodation"
                        ? "x" + accommondationPackageList?.length
                        : item?.label === "Transfer"
                        ? "x" + transferPackageList?.length
                        : item?.label === "Transportation"
                        ? "x" + transportationPackages?.length
                        : item?.label === "Guide"
                        ? "x" + guidePackageList?.length
                        : item?.label === "Spot-Fee"
                        ? "x" + spotEntry?.length
                        : null}
                    </span>
                  </p>
                  <span className="">
                    {item?.toggle
                      ? iconObject?.dropDown
                      : iconObject?.dropRight}
                  </span>
                </div>
                <div className="pt-[10px]">
                  
                  {item?.label === "Food" && item?.toggle === true && (
                    <Food
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                    ></Food>
                  )}
                  {item?.label === "Accommodation" && item?.toggle === true && (
                    <Accommondation
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                    ></Accommondation>
                  )}
                  {item?.label === "Transfer" && item?.toggle === true && (
                    <Transfer
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                    ></Transfer>
                  )}
                  {item?.label === "Transportation" &&
                    item?.toggle === true && (
                      <Transportation
                        setIsLoading={setIsLoading}
                        editAblePackage={editAblePackage}
                      ></Transportation>
                    )}
                  {item?.label === "Guide" && item?.toggle === true && (
                    <Guide
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                    ></Guide>
                  )}
                  {item?.label === "Spot-Fee" && item?.toggle === true && (
                    <SpotFeeV2
                      setIsLoading={setIsLoading}
                      editAblePackage={editAblePackage}
                    ></SpotFeeV2>
                  )}
                </div>
              </div>
            </div>
          ))}
          </div>
          <div className="w-full"></div>
        </div> */}
        {/* Section 3 */}
        <div>
          <TourPackageSelections
            isLoading={isLoading}
            setEditAblePackage={setEditAblePackage}
            selectedNewListItem={selectedNewListItem}
            setSelectedNewListItem={setSelectedNewListItem}
            setItemListNew={setItemListNew}
            getTours={getTours}
          ></TourPackageSelections>
        </div>
      </div>
      <div>
        {Object.keys(availablePackage).length > 0 && (
          <p className="my-[12px] text-tour-primary text-[20px] text-center">
            Available Schedule
          </p>
        )}

        <div className="flex flex-col bg-bg-white shadow-customTourCard">
          {Object.keys(availablePackage)?.map((key, ind) => (
            <div key={ind} className="flex flex-row gap-y-[30px] w-full border-[1px] border-tour-secondaryHint ">
              <p className="border-r border-tour-secondaryHint flex justify-center items-center px-[30px] whitespace-nowrap">
                {key}
              </p>

              <div className="flex flex-col w-full">
                {availablePackage[key]?.map((valueData, index) => (
                  <div
                    key={index}
                    className={`${
                      index % 2 !== 0
                        ? "border-b border-tour-secondaryShade"
                        : "border-none"
                    }  p-[8px] flex justify-between items-center`}
                  >
                    <p>{valueData?.tour_package_type_name}</p>

                    {/* <div>
                          {valueData?.available_tour_package_id !== null ? (
                            <button
                              onClick={() =>
                                navigate(
                                  `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}packageId=${valueData?.available_tour_package_id}&date=${key}`
                                )
                              }
                            >
                              view
                            </button>
                          ) : (
                            <button 
                            // onClick={() => handleAbibilityPage()}
                            >
                              +
                            </button>
                          )}
                        </div> */}

                    <div className="w-[260px] flex gap-[16px] ">
                      <button
                        onClick={() =>
                          navigate(
                            `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}packageId=${valueData?.available_tour_package_id}&date=${key}`
                          )
                        }
                        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                      >
                        View
                      </button>
                      <button
                        onClick={() =>
                          handleDisabledDateSchedule(
                            valueData?.available_tour_package_id
                          )
                        }
                        className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                      >
                        Disable
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* New Design End */}
    </div>
  );
}
