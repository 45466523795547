import React from 'react';
import Blogs from '../../pages/admin/Blogs/Blogs';
import { useParams } from 'react-router';

const NewBlog = () => {
    const { id } = useParams();
    
    return (
        <div className='mx-auto w-full flex justify-center items-center  container '>
           <div className='w-full '>
           <Blogs blogId={id}/>
           </div>
        </div>
    );
};

export default NewBlog;