import { Datepicker } from "@mobiscroll/react";
import { addMonths } from "date-fns";
import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calender.css";
import "./index.css";
import RootComponent from "./rootComponent/RootComponent";

function Calender({
  setSelectedDate,
  placeholder,
  selectedData,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
  isShow,
  endDate,
  startDate,
  onChange,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  function handleIconClick() {
    document.getElementById(`input${name}`).click();
    setIsOpen(!isOpen);
  }
  const startRef = useRef();
  const inputProps = {
    className: "md-mobile-picker-input cursor-pointer",
    placeholder: placeholder,
    id: `input${name}`,
  };

  const component = (
    <div id={name} className="bg-bg-primary required w-full relative">
      {/* <div className="absolute top-0  left-0 text-txt-primary z-[10] ">
        <p className=" flex justify-center items-center h-full">
          {placeholder}
        </p>
      </div> */}
      <button
        type="button"
        className=" border-[#9e9989] overflow-hidden validation border h-[50px] rounded-[8px] w-full flex items-center "
      >
        <Datepicker
          controls={["calendar"]}
          inputComponent="input"
          inputProps={inputProps}
          value={selectedData}
          onChange={(event) => {
            setSelectedDate(event.value);
          }}
          // max={new Date()}
        />
        {/* {isShow ? (
          <DatePicker
            className="w-full h-full py-[10px] px-[30px] placeholder:text-[12px]"
            selected={selectedData}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            minDate={new Date()}
            maxDate={addMonths(new Date(), 5)}
            // inline
            ref={startRef}
            scrollableYearDropdown
            peekNextMonth
            showDisabledMonthNavigation
            showMonthDropdown
            showYearDropdown
            isClearable
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={100}
            placeholderText={placeholder}
          />
        ) : (
          <DatePicker
            className="w-full h-full py-[10px] px-[30px] placeholder:text-[12px]"
            selected={selectedData}
            onChange={(date) => setSelectedDate(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            isClearable
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            minDate={new Date()}
            maxDate={addMonths(new Date(), 5)}
            placeholderText={placeholder}
            ref={startRef}
          />
        )} */}

        <button
          type="button"
          className="bg-btn-primary w-[49px] h-full rounded-[8px] px-4 hover:bg-btn-secondary duration-500 outline-none"
          // onClick={handleIconClick}
          onClick={() => startRef.current?.setOpen(true)}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7498 2.83203H4.24984C2.68503 2.83203 1.4165 4.10056 1.4165 5.66536V12.7487C1.4165 14.3135 2.68503 15.582 4.24984 15.582H12.7498C14.3146 15.582 15.5832 14.3135 15.5832 12.7487V5.66536C15.5832 4.10056 14.3146 2.83203 12.7498 2.83203Z"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.6665 1.41602V4.24935"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.333 1.41602V4.24935"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.4165 7.08203H15.5832"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </button>
      <div className="errorMessage validationError"></div>
    </div>
  );
  let readOnlyViewComponent = <p>{selectedData}</p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default Calender;
