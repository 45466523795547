import React, { useEffect, useState } from "react";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import SliderCart from "./SliderCart";

export default function AdminViewAllTour() {
  const [allTours, setAllTours] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    authAxios
      .get(`${baseUrl}${apiEndPoint.ADMIN_GET_ALL_TOURS_GET_API}`)
      .then((response) => {
        setAllTours(response?.data?.tours);
        setLoading(false);
      });
  }, []);

  const slideLeftSpecialityAdmin = () => {
    let sliders = document.getElementById("slidered-speciality-admin");

    sliders.scrollLeft = sliders.scrollLeft - 600;
  };

  const slideRightSpecialityAdmin = () => {
    let slider = document.getElementById("slidered-speciality-admin");

    slider.scrollLeft = slider.scrollLeft + 600;
  };

  console.log("allTours", allTours);
  return (
    <div className="max-w-[1100px] mx-auto mt-[20px]">
      <h2 className="text-[22px] text-center">All Tours</h2>
      <div className="flex flex-wrap gap-[20px] ">
        {/* {allTours?.map((tour, index) => (
          <div
            key={index}
            className="border-[#148F77] border-[0.0.001px] shadow-customShadow p-[10px] rounded-[4px] max-w-[350px] flex flex-col gap-[6px]"
          >
            <p className="text-[16px] text-btn-secondary">
              {tour?.added_tour_name}
            </p>
            <p className="text-[14px] flex justify-start items-center">
              {" "}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[14px] h-[14px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </span>{" "}
              {tour?.short_address}
            </p>
            <p className="text-[16px] flex justify-between">
              <span className="flex flex-start items-center gap-x-[4px]">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[16px] h-[16px] text-[#D35400]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  />
                </svg>
                {tour?.number_of_days} Day
              </span>
              <span className="flex flex-start items-center gap-x-[4px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[16px] text-[#1C2833] h-[16px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                  />
                </svg>
                {tour?.number_of_nights} Night
              </span>
            </p>
            <p className="text-[12px] mt-[6px] text-center ">
              #{tour?.added_tour_tag}
            </p>
          </div>
        ))} */}
        <div className=" flex flex-wrap gap-[20px] ">
          {allTours?.map((getTours, index) => (
            <div
              className="min-w-[350px] max-w-[350px]  shadow-customShadow rounded-[8px] overflow-hidden"
              key={index}
            >
              <div className=" mx-auto w-full h-[180px] relative">
                <img
                  src={getTours?.tour_thumb_image_url}
                  alt="ghuddy"
                  loading="lazy"
                  className="object-cover w-full h-full"
                />
                <p className="text-bg-white z-[10] absolute bottom-[10px] left-[10px] text-[16px] ">
                  #{getTours?.tour_title}
                </p>
              </div>
              <div className="mt-[14px] px-[6px]">
                <h2 className="text-[16px]">{getTours?.tour_name}</h2>
                <p className="text-[12px]">
                  {getTours?.tour_description?.slice(0, 150)}
                </p>
                {/* tour iteniry */}
                <div className="mt-[10px]">
                  <p className="text-[12px] text-btn-primary">Tour Itinerary</p>
                  <div className="w-full">
                    <SliderCart
                      cart={getTours?.tour_itinerary}
                      containerWidth={"w-[343px]"}
                      cartWidth={130}
                      cartViewNumber={2.5}
                    >
                      {getTours?.tour_itinerary?.map((itinerary, index) => (
                        <div
                          key={index}
                          className="max-w-[130px] min-w-[130px]"
                        >
                          <p
                            title={itinerary?.tour_activity_name}
                            className="text-[14px] truncate"
                          >
                            {itinerary?.tour_activity_name}
                          </p>
                          <div className="w-[130px] h-[80px] rounded-[8px] overflow-hidden">
                            <img
                              src={
                                itinerary?.tour_activity_images[0]
                                  ?.activity_image_url
                              }
                              alt="ghuddy"
                              loading="lazy"
                              className="object-cover w-full h-full"
                            />
                          </div>
                        </div>
                      ))}
                    </SliderCart>
                  </div>
                </div>

                {/* tour speciality */}
                <div className="mt-[10px]">
                  <p className="text-[12px] text-btn-primary">
                    Tour Speciality
                  </p>

                  <div className="w-full">
                    <SliderCart
                      cart={getTours?.tour_specialities}
                      containerWidth={"w-[343px]"}
                      cartWidth={230}
                      cartViewNumber={1.5}
                    >
                      {getTours?.tour_specialities?.map((speciality, index) => (
                        <div
                          key={index}
                          className="min-w-[230px] max-w-[230px] p-[8px] border-btn-secondary border-[0.0.001px] shadow-customShadow my-[6px]"
                        >
                          <p className="text-[13px] font-extrabold text-btn-secondary flex justify-start gap-x-[4px] items-center ">
                            <span className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px] ">
                              <img
                                className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]"
                                src={speciality?.tour_speciality_icon_url}
                                alt="Ghuddy"
                              />
                            </span>
                            {speciality?.tour_speciality_title}
                          </p>
                          <p className="text-[12px] mt-[3px]">
                            {speciality?.tour_speciality_description?.slice(
                              0,
                              80
                            )}
                          </p>
                        </div>
                      ))}
                    </SliderCart>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
