import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const sliderStyles = {
  position: "relative",
  display: "inline-block",
  width: "60px",
  height: "34px",

  borderRadius: "34px",
  cursor: "pointer",
  transition: "0.4s",
};

const GuideDraggableItem = ({
  selectedNewListItem,
  setIsDragging,
  setRequestToPayload,
  requestToPayload,
  handleToggleClick,
}) => {
  const [checkStatus, setCheckStatus] = useState({ id: 0, toggle: false });
  const [draggedList, setDraggedList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [addSingleItem, setAddSingleItem] = useState(false);

  useEffect(() => {
    setSelectedList(requestToPayload?.tour_package_guide_options);
  }, [requestToPayload?.tour_package_guide_options]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleSetGuide = () => {
    setRequestToPayload((prev) => ({
      ...prev,
      tour_package_guide_options: {
        ...prev.tour_package_guide_options,
        ...draggedList,
      },
    }));
    setAddSingleItem(false);
    setDraggedList([]);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const itemJSON = e.dataTransfer.getData("application/json");

    if (itemJSON) {
      const item = JSON.parse(itemJSON);
      const exists = Object.values(draggedList).some((day) =>
        day.some(
          (existingItem) =>
            existingItem.tour_package_guide_package_id ===
            item.tour_package_guide_package_id
        )
      );
      if (!exists) {
        const currentDay = `day${Object.keys(selectedList).length + 1}`;
        if (draggedList[currentDay]) {
          setDraggedList({
            ...draggedList,
            [currentDay]: [...draggedList[currentDay], item],
          });
        } else {
          setDraggedList({
            ...draggedList,
            [currentDay]: [item],
          });
        }
      }
      if(exists){
        toast.warn("Already exist on item")
      }

      setIsDragging(false);
    }
  };

  const iconObject = {
    dropRight: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M6.75 15L12.75 9L6.75 3"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dropDown: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M3 6.75L9 12.75L15 6.75"
          stroke="#879FA8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    edit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M13.3817 0.644005C12.9851 0.248036 12.4476 0.0256348 11.8872 0.0256348C11.3268 0.0256348 10.7893 0.248036 10.3927 0.644005L0.905373 10.1313C0.633687 10.4015 0.418268 10.7228 0.271581 11.0768C0.124893 11.4308 0.0498491 11.8103 0.05079 12.1934V13.391C0.05079 13.5457 0.112248 13.6941 0.221644 13.8035C0.331041 13.9129 0.479414 13.9743 0.634123 13.9743H1.83171C2.21482 13.9754 2.59434 13.9005 2.9483 13.7539C3.30226 13.6073 3.62362 13.392 3.89379 13.1203L13.3817 3.63242C13.7775 3.23587 13.9998 2.69849 13.9998 2.13821C13.9998 1.57794 13.7775 1.04056 13.3817 0.644005ZM3.06896 12.2955C2.73996 12.6223 2.29543 12.8063 1.83171 12.8077H1.21746V12.1934C1.21687 11.9635 1.26188 11.7358 1.3499 11.5234C1.43791 11.3111 1.56717 11.1183 1.73021 10.9562L8.93029 3.75609L10.272 5.09776L3.06896 12.2955ZM12.5563 2.80759L11.0945 4.27001L9.75279 2.93125L11.2152 1.46884C11.3033 1.38093 11.4078 1.31124 11.5229 1.26374C11.6379 1.21624 11.7612 1.19187 11.8856 1.192C12.0101 1.19214 12.1333 1.21678 12.2482 1.26453C12.3631 1.31228 12.4675 1.3822 12.5554 1.4703C12.6433 1.55839 12.713 1.66294 12.7605 1.77797C12.808 1.893 12.8324 2.01625 12.8323 2.1407C12.8321 2.26515 12.8075 2.38836 12.7597 2.50328C12.712 2.61821 12.6421 2.7226 12.554 2.81051L12.5563 2.80759Z"
          fill="#466773"
        />
      </svg>
    ),
    duplicate: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M11.1738 1.90867L9.84142 0.532583C9.67779 0.364563 9.48226 0.230907 9.2663 0.139452C9.05033 0.0479981 8.81828 0.000587085 8.58375 0L5.83333 0C5.16121 0.000848473 4.50994 0.233386 3.98927 0.658419C3.46861 1.08345 3.11039 1.67499 2.975 2.33333H2.91667C2.1434 2.33426 1.40208 2.64185 0.855295 3.18863C0.308515 3.73541 0.00092625 4.47674 0 5.25V11.0833C0.00092625 11.8566 0.308515 12.5979 0.855295 13.1447C1.40208 13.6915 2.1434 13.9991 2.91667 14H6.41667C7.18993 13.9991 7.93126 13.6915 8.47804 13.1447C9.02482 12.5979 9.33241 11.8566 9.33333 11.0833V11.025C9.99168 10.8896 10.5832 10.5314 11.0083 10.0107C11.4333 9.49006 11.6658 8.83879 11.6667 8.16667V3.12667C11.6675 2.67191 11.4907 2.23482 11.1738 1.90867ZM6.41667 12.8333H2.91667C2.45254 12.8333 2.00742 12.649 1.67923 12.3208C1.35104 11.9926 1.16667 11.5475 1.16667 11.0833V5.25C1.16667 4.78587 1.35104 4.34075 1.67923 4.01256C2.00742 3.68437 2.45254 3.5 2.91667 3.5V8.16667C2.91759 8.93993 3.22518 9.68126 3.77196 10.228C4.31874 10.7748 5.06007 11.0824 5.83333 11.0833H8.16667C8.16667 11.5475 7.98229 11.9926 7.65411 12.3208C7.32592 12.649 6.8808 12.8333 6.41667 12.8333ZM8.75 9.91667H5.83333C5.36921 9.91667 4.92409 9.73229 4.5959 9.4041C4.26771 9.07591 4.08333 8.6308 4.08333 8.16667V2.91667C4.08333 2.45254 4.26771 2.00742 4.5959 1.67923C4.92409 1.35104 5.36921 1.16667 5.83333 1.16667H8.16667V2.33333C8.16667 2.64275 8.28958 2.9395 8.50838 3.15829C8.72717 3.37708 9.02392 3.5 9.33333 3.5H10.5V8.16667C10.5 8.6308 10.3156 9.07591 9.98744 9.4041C9.65925 9.73229 9.21413 9.91667 8.75 9.91667Z"
          fill="#466773"
        />
      </svg>
    ),
  };
  const onHandleToggleClick = (index) => {
    setCheckStatus({ id: index, toggle: checkStatus?.id === index ? !checkStatus?.toggle : true  });
  };

  const handleRemoveNewItem = (day, id) => {
    setDraggedList((prev) => {
      const updatedDay = prev[day].filter(
        (guide) => guide.tour_package_guide_package_id !== id
      );

      return { ...prev, [day]: updatedDay };
    });
  };

  const handleAddNewItem = (day, newFoodItem) => {
    setDraggedList((prev) => {
      const updatedDay = prev[day]
        ? [...prev[day], newFoodItem]
        : [newFoodItem];
      return { ...prev, [day]: updatedDay };
    });
  };


  const handleRemoveFromRequestToPayload = (day, index) => {
    setRequestToPayload((prevState) => {
      const newState = { ...prevState };
      const dayArray = [...newState.tour_package_guide_options[day]];
      dayArray.splice(index, 1);
      newState.tour_package_guide_options[day] = dayArray;

      return newState;
    });
  };

  const handleDragOverOption = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDropOption = (e, day) => {
    e.preventDefault();
    const itemJSON = e.dataTransfer.getData("application/json");
    if (itemJSON) {
      const item = JSON.parse(itemJSON);
      const isExisting = requestToPayload.tour_package_guide_options[day]?.some(
        existingItem => existingItem.tour_package_guide_package_id === item.tour_package_guide_package_id
      );

      if(isExisting){
        toast.warn("Already exist on item")
      }
  
      if (!isExisting) {
        setRequestToPayload(prevState => {
          const newState = { ...prevState };
          if (!newState.tour_package_guide_options[day]) {
            newState.tour_package_guide_options[day] = [];
          }
          newState.tour_package_guide_options[day].push(item);
  
          return newState;
        });
      }
    }
  };

  return (
    <>
    {/* Try tabs */}
    <div>
      <ul className="grid grid-cols-4 gap-[8px] text-[12px]">
        {Object?.keys(requestToPayload?.tour_package_guide_options).length >
          0 &&
          Object.keys(requestToPayload?.tour_package_guide_options).map((item, index) => (
          <div key={index}>
            <li
              onClick={() => {
                onHandleToggleClick(index);
              }}
              className=""
            >
             
              <div
                href="#"
                aria-current="page"
                className={`w-full rounded-[8px] py-[6px] px-[8px] text-sm font-medium text-center cursor-pointer 
                ${checkStatus?.id === index && checkStatus?.toggle === true? " text-tour-dnd bg-tour-primary" : "bg-tour-border text-bg-white"}
                `}
              >
                {"Option "} {index + 1}{" "}
              </div>
            </li>
          </div>
        ))}
      </ul>
      
      </div>
      {/* New  */}
      <div className=" flex flex-col">
        {Object?.keys(requestToPayload?.tour_package_guide_options).length > 0 &&
          Object.keys(requestToPayload?.tour_package_guide_options).map((item, index) => (
            <div key={index} className="w-[357px] flex flex-col gap-y-[24px] mb-[16px]">
              {/* <div
                onClick={() => {
                  onHandleToggleClick(index);
                }}
                className="flex justify-between items-center  bg-bg-white border-b-[1px] border-tour-border px-[8px] h-[32px] cursor-pointer "
              >
                <p className="text-[18px]  text-tour-text flex justify-start items-center gap-x-[10px]">
                  {"Option "} {index + 1}{" "}
                  <span className="text-tour-border text-[14px]">{`(${selectedList[item]?.length} items)`}</span>
                </p>
                <span className="">
                  {checkStatus?.id === index && checkStatus?.toggle === true
                    ? iconObject?.dropDown
                    : iconObject?.dropRight}
                </span>
              </div> */}
              {checkStatus?.id === index &&
                checkStatus?.toggle === true &&
                selectedList[item]?.map((guide, ind) => (
                  <div
                    key={ind}
                    className="border-[1px] border-tour-secondaryHint rounded-[12px]"
                  >
                    <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                      {guide?.day_number} {" Day"}
                    </p>
                    <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                      <div className="flex flex-col gap-[4px]">
                        <p className="text-[14px] font-normal">
                          <span className="text-tour-text">
                            {"Guide Quantity: "}
                          </span>
                          <span className="text-tour-secondary">
                            {guide?.number_of_guides_for_day}
                          </span>
                        </p>
                        <p className="text-tour-text  ">
                          <span className="text-[14px] font-normal">
                            Price:{" "}
                          </span>
                          <span className="text-[16px] font-medium">
                            {guide?.total_guide_price_for_day} BDT
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-end items-center gap-[16px]">
                              <div
                                onClick={() =>
                                  handleRemoveFromRequestToPayload(item, ind)
                                }
                                className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                              >
                                <p>{iconObject?.edit}</p>
                                <p>Remove</p>
                              </div>
                            </div>
                      {/* <div className="flex gap-[16px]">
                        <button
                          // onClick={() => handleButtonPackage(guide)}
                          className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                        >
                          Edit
                        </button>
                        <button
                          // onClick={() => handleButtonPackage(guide)}
                          className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                        >
                          Duplicate
                        </button>
                      </div> */}
                    </div>
                    
                  </div>
                ))}

{checkStatus?.id === index && checkStatus?.toggle === true && (
                  <div
                    className="h-[156px]  flex justify-center items-center bg-tour-dnd border-[1px] border-tour-secondaryHint rounded-[8px]"
                    onDragOver={handleDragOverOption}
                    onDrop={(event) => handleDropOption(event, item, index)}
                  >
                    <p className="text-[14px] text-tour-text">
                      Drag the Guide option to this box
                    </p>
                  </div>
                )}
            </div>
          ))}
      </div>
      {/* {selectedList?.length > 0 && (
        <div className="w-[357px] flex flex-col gap-y-[24px]">
          {Object?.keys(selectedList).map((day, index) => (
            <div>
              {selectedList?.map((item, index) => (
                <div></div>
              ))}
            </div>
          ))}
        </div>
      )} */}
      {selectedNewListItem?.name === "Guide" && (
        <div className="flex flex-col gap-y-[16px]">
          {/* <div>
            Option {selectedList?.length + 1}{" "}
            <span className="text-tour-border text-[14px]">{`(${draggedList?.length} items)`}</span>
          </div> */}
          <div className="flex flex-col gap-[16px]">
          <p className="text-tour-text bg-tour-secondaryHint px-[16px] py-[8px] border-l-[2px] border-tour-primary mt-[20px]">
              Add New Option
            </p>
            {Object?.keys(draggedList)?.map((day, index) => (
              <div
                key={index}
                className="w-full max-h-[360px] flex flex-col gap-y-[24px] overflow-auto "
              >
                {draggedList[day]?.map((guide, i) => (
                  <div key={i} className="">
                    <div className="border-[1px] border-tour-secondaryHint rounded-[12px]">
                      <p className="px-[12px] py-[6px] text-tour-text border-b-[1px]  rounded-t-[12px] border-tour-secondaryHint font-normal">
                        {guide?.day_number}
                        {" day"}
                      </p>
                      <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                        <div className="flex flex-col gap-[4px]">
                          <p className="text-[14px] font-normal">
                            <span className="text-tour-text">
                              {"Guide Quantity : "}
                            </span>
                            <span className="text-tour-secondary">
                              {guide?.number_of_guides_for_day}
                            </span>
                          </p>
                          <p className="text-tour-text  ">
                            <span className="text-[14px] font-normal">
                              Price:{" "}
                            </span>
                            <span className="text-[16px] font-medium">
                              {guide?.total_guide_price_for_day} BDT
                            </span>
                          </p>
                        </div>
                        {/* <div className="flex gap-[16px]">
                          <button
                            onClick={() =>
                              handleRemoveNewItem(day, food?.meal_package_id)
                            }
                            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                            hover:bg-tour-secondaryHint transition ease-in duration-300"
                          >
                            Remove
                          </button>
                          <button
                            onClick={() => handleAddNewItem(day, food)}
                            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                          >
                            Duplicate
                          </button>
                        </div> */}
                        <div className="flex justify-end items-center gap-[16px]">
                          <div
                            onClick={() =>
                              handleRemoveNewItem(
                                day,
                                guide?.tour_package_guide_package_id
                              )
                            }
                            className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                          >
                            <p>{iconObject?.edit}</p>
                            <p>Remove</p>
                          </div>
                          {/* <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                          <div
                            // onClick={() => handleRemoveNewItem(day, food?.meal_package_id)}
                            className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                          >
                            <p>{iconObject?.duplicate}</p>
                            <p>Duplicate</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {/* {Object.entries(draggedList)?.length !== 0 && (
              <div className="w-full max-h-[360px] flex flex-col gap-y-[24px] overflow-auto ">
                {Object.keys(draggedList)?.map((day, i) => (
                  <div key={i} className="">
                    <div className="border-[1px] border-tour-secondaryHint rounded-[12px]">
                      <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] bg-[#E6E9EB] rounded-t-[12px] border-tour-secondaryHint font-normal">
                        {" "}
                        {food?.meal_type_name}
                      </p>
                      <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                        <div className="flex flex-col gap-[4px]">
                          <p className="text-[14px] font-normal">
                            <span className="text-tour-text">{"Food : "}</span>
                            <span className="text-tour-secondary">
                              {food?.food_items?.map((itm, ind) => (
                                <span key={ind}>
                                  {" " + itm?.food_item_name},
                                </span>
                              ))}
                            </span>
                          </p>
                          <p className="text-tour-text  ">
                            <span className="text-[14px] font-normal">
                              Price:{" "}
                            </span>
                            <span className="text-[16px] font-medium">
                              {food?.per_meal_package_price} BDT
                            </span>
                          </p>
                        </div>
                        <div className="flex gap-[16px]">
                          <button
                            onClick={() =>
                              handleRemoveNewItem(food?.meal_package_id)
                            }
                            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                            hover:bg-tour-secondaryHint transition ease-in duration-300"
                          >
                            Remove
                          </button>
                          <button
                            onClick={() => handleAddNewItem(food)}
                            className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                          >
                            Duplicate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )} */}
            <div
              className="h-[156px]  flex justify-center items-center bg-tour-dnd border-[1px] border-tour-secondaryHint rounded-[8px]"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p className="text-[14px] text-tour-text">
                Drag the Guide option to this box
              </p>
            </div>
          </div>

          <div className="mt-[8px]">
            {addSingleItem ? (
              ""
            ) : (
              <button
                disabled={draggedList?.length === 0}
                onClick={() => handleSetGuide()}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300 disabled:bg-bg-semiblack disabled:border-bg-semiblack"
              >
                Add Option
              </button>
            )}
          </div>
          {/* Added Toggle */}
          <div className="flex items-center justify-center gap-[12px] mt-[12px]">
            <p className="text-[14px] text-tour-text">
              Is item Is Inclusive by Default:
            </p>
            <label
              style={sliderStyles}
              className={`${
                requestToPayload?.tour_package_guide_is_inclusive
                  ? "bg-tour-secondary"
                  : "bg-tour-border"
              } `}
            >
              <input
                type="checkbox"
                checked={requestToPayload?.tour_package_guide_is_inclusive}
                onChange={() =>
                  setRequestToPayload((prev) => ({
                    ...prev,
                    tour_package_guide_is_inclusive:
                      !requestToPayload?.tour_package_guide_is_inclusive,
                  }))
                }
                style={{ display: "none" }}
              />
              <span
                style={{
                  transform: `translateX(${
                    requestToPayload?.tour_package_guide_is_inclusive
                      ? "26px"
                      : "0"
                  })`,
                  transition: "transform 0.3s ease",
                  content: '""',
                  position: "absolute",
                  height: "26px",
                  width: "26px",
                  left: "4px",
                  bottom: "4px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              ></span>
            </label>
          </div>
          <div className="mt-[32px]">
            <button
              onClick={() => handleToggleClick(6)}
              className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
            >
              Proceed with Spot-Fee
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GuideDraggableItem;
