import React, { useEffect, useRef, useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import SingleSelectSimpleArray from "./SingleSelectSimpleArray";
import RootComponent from "../rootComponent/RootComponent";
import "../../App.css";
import { customValidationSingle } from "../../utils/customValidation";
function SingleSelectFromSimpleArray({
  data,
  selectedValue,
  setSelectedValue,
  placeholder,
  warningView = true,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
  required = false,
  onBlur = null,
}) {
  const [visible, setVisible] = React.useState(false);
  const [display, setDisplay] = React.useState(selectedValue);
  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const [searchValue, SetSearchValue] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const handleSearchChange = (e) => {
    SetSearchValue(e.target.value);
    setSelectedValue(e.target.value);
  };

  const focusHandler = () => {
    const input = document.getElementById(`input${name}`);
    input.focus();
  };

  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
        // focusHandler();
      }}
    >
      <div className={`w-full grid grid-flow-col`}>
        <div id={name} className={`${required && "required"}`}>
          <div
            className="w-full cursor-pointer rounded-[8px] h-[49px] flex justify-between "
            ref={ref}
            onClick={() => {
              SetSearchValue("");
              setVisible(true);
            }}
          >
            <div
              className={`w-full h-full cursor-pointer ${
                selectedValue ? "text-txt-primary" : "text-bg-semiblack"
              }`}
            >
              <input
                value={selectedValue ? selectedValue : ""}
                placeholder={placeholder}
                onClick={(e) => e.preventDefault()}
                className="w-full text-left pl-4 h-full  bg-[transparent] validation focus:outline-none active:outline-none border-[#9e9989] rounded-l-[8px] rounded-r-0 border border-r-0 text-xs"
                onChange={handleSearchChange}
                style={{
                  background: "rgba(0,0,0,0)",
                  outline: "none",
                  cursor: "pointer",
                }}
                autoComplete="off"
                id={`input${name}`}
                onBlur={() => required && customValidationSingle(name)}
                readOnly={isReadOnly}
              />
            </div>
            <button className="validation bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-r-[8px] rounded-l-none border border-l-0 border-[#314747] px-5 hover:bg-btn-secondary duration-500 ">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="errorMessage validationError"></div>
        </div>
        {visible && data && data.length > 0 && (
          <div
            className="pt-4 rounded-[8px] mt-16 bg-bg-primary custom-shadow z-50 absolute max-h-[200px] h-auto overflow-y-auto"
            style={{ width: width }}
          >
            <div className="" id={`dropDown${name}`}>
              {data
                .filter((code) =>
                  JSON.stringify(code)
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
                )
                .map((item, index) => {
                  return (
                    <SingleSelectSimpleArray
                      key={index}
                      item={item}
                      setSelectedValue={setSelectedValue}
                      setVisible={setVisible}
                      setDisplay={setDisplay}
                      validation={required}
                      name={name}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  );

  let readOnlyViewComponent = <p>{selectedValue}</p>;
  return (
    <div className="w-full">
      <p className="text-[12px]">{placeholder}</p>
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        warningView={warningView}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default SingleSelectFromSimpleArray;
