import React from "react";
import { useLocation, useNavigate } from "react-router";
import { adminRoute, merchantRoute, UserType } from "../../contants/Constants";
import { isLoggedIn } from "../../utils/isLoggedIn";
import "./header.css";

function Header({ userType }) {
  const navigate = useNavigate();
  const location = useLocation();
  function handleSignOut() {
    localStorage.clear();
    window.location.reload();
  }
  const handleLogoClick = () => {
    // navigate(returnAdminOrUserBasedOnPath(location.pathname) == UserType.USER ? merchantRoute.LANDING : adminRoute.ADMIN_LANDING)
    if (userType === UserType.ADMIN) {
      navigate(adminRoute.ADMIN_LANDING);
    } else {
      navigate(merchantRoute.HOME);
    }
  };
  const handleSignIn = () => {
    navigate(merchantRoute.LOGIN);
  };
  const handleSignUp = () => {
    navigate(merchantRoute.SIGNUP);
  };
  const toggleNav = () => {
    const menu = document.getElementById("mobile-menu");
    menu.classList.toggle("hidden");
  };
  return (
    <>
      <nav className="nav-css sticky top-0 z-[60] p-6 bg-bg-primary max-w-[1440px] 2xl:max-w-full mx-auto">
        <div className="flex items-center flex-wrap md:flex-nowrap justify-between 2xl:container mx-auto">
          <div className="flex items-center flex-shrink-0 text-white mr-6 cursor-pointer">
            <a onClick={handleLogoClick}>
              <svg
                width="101"
                height="25"
                viewBox="0 0 101 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.0749 11.4272H44.7136V16.7772C43.4436 18.1047 41.3849 18.9323 39.4599 18.9323C35.6686 18.9323 32.4749 15.796 32.4749 12.0822C32.4749 8.36848 35.6699 5.25098 39.4599 5.25098C41.3836 5.25098 43.4436 6.05973 44.7136 7.38723L43.0011 8.98473C42.1736 8.02223 40.7499 7.44473 39.4599 7.44473C36.9961 7.44473 34.9186 9.56098 34.9186 12.0822C34.9186 14.6222 36.9974 16.7385 39.4599 16.7385C40.4599 16.7385 41.5374 16.3922 42.3661 15.796V13.391H39.0749V11.4272Z"
                  fill="#376576"
                />
                <path
                  d="M55.3549 12.3705V18.7018H53.0836V12.833C53.0836 11.4855 52.2174 10.5243 50.9861 10.5243C49.6586 10.5243 48.5611 11.4868 48.5611 12.6793V18.7018H46.2136V4.48926L48.5611 5.25051V10.1193C49.1574 9.15677 50.3311 8.52177 51.6586 8.52177C53.8349 8.52177 55.3549 10.1193 55.3549 12.3705Z"
                  fill="#376576"
                />
                <path
                  d="M66.015 8.75223V18.701H63.6674V17.3735C63.0512 18.2972 61.8974 18.9322 60.5887 18.9322C58.4137 18.9322 56.8937 17.3347 56.8937 15.0835V8.75223H59.1649V14.621C59.1649 15.9685 60.0312 16.9297 61.2624 16.9297C62.4937 16.9297 63.5137 16.1022 63.6674 15.0435V8.75098H66.015V8.75223Z"
                  fill="#376576"
                />
                <path
                  d="M77.5412 5.25008V18.7014H75.1937V17.1614C74.405 18.2776 73.2125 18.9314 71.7487 18.9314C69.1125 18.9314 67.1887 16.7764 67.1887 13.7163C67.1887 10.6763 69.1125 8.52008 71.7487 8.52008C73.2112 8.52008 74.4037 9.17384 75.1937 10.2901V4.48633L77.5412 5.25008ZM75.1937 13.7176C75.1937 11.8513 74.0387 10.5226 72.3837 10.5226C70.71 10.5226 69.5162 11.8501 69.5162 13.7176C69.5162 15.5838 70.7087 16.9314 72.3837 16.9314C74.0387 16.9314 75.1937 15.5838 75.1937 13.7176Z"
                  fill="#376576"
                />
                <path
                  d="M89.125 5.25008V18.7014H86.7774V17.1614C85.9887 18.2776 84.7962 18.9314 83.3324 18.9314C80.6961 18.9314 78.7723 16.7764 78.7723 13.7163C78.7723 10.6763 80.6961 8.52008 83.3324 8.52008C84.7949 8.52008 85.9874 9.17384 86.7774 10.2901V4.48633L89.125 5.25008ZM86.7774 13.7176C86.7774 11.8513 85.6224 10.5226 83.9674 10.5226C82.2936 10.5226 81.0999 11.8501 81.0999 13.7176C81.0999 15.5838 82.2924 16.9314 83.9674 16.9314C85.6224 16.9314 86.7774 15.5838 86.7774 13.7176Z"
                  fill="#376576"
                />
                <path
                  d="M90.9223 21.6455L91.4223 19.9517C91.846 20.183 92.326 20.318 92.6923 20.318C93.251 20.318 93.6735 20.1442 93.9623 19.6642L94.386 18.7792L90.171 8.75293H92.6535L95.5598 16.1804L98.2923 8.75293H100.717L96.5035 19.3942C95.7535 21.2805 94.5985 22.1455 93.0973 22.1655C92.346 22.1655 91.5185 21.973 90.9223 21.6455Z"
                  fill="#376576"
                />
                <path
                  d="M24.9287 12.105L12.8237 0L0.717407 12.105L9.92367 21.3113L6.76992 24.9638H18.8762L15.7224 21.3113L24.9287 12.105ZM21.9399 12.8851L14.6999 20.1251L13.6037 18.8563V12.8851H21.9399ZM21.9399 11.3238H13.6037V2.98751L21.9399 11.3238ZM10.1812 23.4013L12.8237 20.3413L15.4662 23.4013H10.1812ZM12.0424 18.8576L10.9462 20.1263L3.70616 12.8863H12.0424V18.8576ZM3.70616 11.3238L12.0424 2.98751V11.3238H3.70616Z"
                  fill="#376576"
                />
              </svg>
            </a>
          </div>
          <div className="block md:hidden">
            <button
              onClick={toggleNav}
              type="button"
              className="flex items-center px-3 py-2 border rounded text-black border-teal-400 hover:text-white hover:border-white"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div className="hidden md:flex items-center w-full" id="mobile-menu">
            <div className="flex space-between md:flex-row flex-col ml-auto">
              <a
                href="#responsive-header"
                className="py-3 text-xs px-2 block lg:inline-block lg:mt-0 text-black hover:text-white mr-4"
              >
                My Favoutites
              </a>
              <a
                href="#responsive-header"
                className="py-3 text-xs px-2 block lg:inline-block lg:mt-0 text-black hover:text-white mr-4"
              >
                Get Help
              </a>

              {isLoggedIn() ? (
                <div className="ml-3 relative z-[70]">
                  <button
                    className="pt-1 flex text-sm focus:outline-none focus:border-white transition duration-150 ease-in-out"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                  >
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 55 55"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="27.5" cy="27.5" r="27.5" fill="#FFFBEF" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M46.627 47.2588C51.7903 42.2595 55 35.2546 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5C0 34.8218 2.86136 41.4752 7.52686 46.4031C7.6072 46.0377 7.69673 45.7386 7.79545 45.506C8.28788 44.752 9.32955 44.0278 10.9205 43.3333C11.072 43.254 12.3172 42.8175 14.6562 42.0238C16.9953 41.2302 18.1932 40.8234 18.25 40.8036C19.5568 40.3075 20.4138 39.9256 20.821 39.6577C21.2282 39.3899 21.536 39.0179 21.7443 38.5417L22.3977 37.1131C22.4924 36.6171 22.3788 36.2103 22.0568 35.8929C19.5379 33.5119 18.2879 30.2877 18.3068 26.2202C18.3068 22.9464 19.0739 20.2579 20.608 18.1548C22.142 16.0516 24.2727 15 27 15C29.7273 15 31.858 16.0516 33.392 18.1548C34.9261 20.2579 35.6932 22.9464 35.6932 26.2202C35.7121 30.2877 34.4621 33.5119 31.9432 35.8929C31.6212 36.2103 31.5076 36.6171 31.6023 37.1131L32.2557 38.5417C32.464 39.0179 32.7718 39.3899 33.179 39.6577C33.5862 39.9256 34.4432 40.3075 35.75 40.8036C35.8068 40.8234 37.0047 41.2302 39.3438 42.0238C41.6828 42.8175 42.928 43.254 43.0795 43.3333C44.6705 44.0278 45.7121 44.752 46.2045 45.506C46.3717 45.9 46.5125 46.4843 46.627 47.2588Z"
                        fill="#376576"
                      />
                    </svg>
                  </button>
                  <div
                    id="user-menu-dropdown"
                    className="menu-hidden bg-bg-primary z-[70] origin-top-right absolute right-0 mt-2 w-48 rounded-[8px] shadow-xl"
                  >
                    <div
                      className="py-1  rounded-[8px] bg-bg-primary shadow-xs"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <a
                        href={"/"}
                        // onClick={() => navigate(merchantRoute.LANDING)}
                        className="block hover:bg-btn-primary hover:text-bg-primary cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        role="menuitem"
                      >
                        Dashboard
                      </a>
                      <a
                        href="#"
                        className="block hover:bg-btn-primary hover:text-bg-primary cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        role="menuitem"
                      >
                        Settings
                      </a>
                      <button
                        className="w-full text-left hover:bg-btn-primary hover:text-bg-primary"
                        onClick={handleSignOut}
                      >
                        <div
                          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition w-100 duration-150 ease-in-out"
                          role="menuitem"
                        >
                          Log out
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-px">
                  {/* <ModalPopup /> */}
                  <button
                    onClick={handleSignIn}
                    className="cursor-pointer rounded-full inline-block text-sm px-10 py-2 leading-none border  text-white border-white hover:bg-btn-secondary hover:text-btn-base hover:border-btn-secondary mr-6 bg-btn-primary text-txt-secondary border-btn-primary active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary"
                  >
                    Log In
                  </button>
                  <button
                    onClick={handleSignUp}
                    className="rounded-full inline-block text-sm px-10 py-2 leading-none border-2 border-button-default  text-button-default border-white hover:border-transparent hover:bg-btn-secondary hover:text-btn-base hover:border-btn-secondary text-btn-primary hover:bg-white bg-btn-base active:bg-txt-secondary  active:border-btn-primary active:text-btn-primary"
                  >
                    Register
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
