import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../../components/common/ModalCommon";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";

export default function TransportBrands() {
  const [getTransportationBrand, setGetTransportationBrand] = useState([]);
  const [transportBrands, setTransportBrands] = useState([]);
  const [transportBrand, setTransportBrand] = useState({
    transportation_brand_name: "",
  });
  const [modalShown, setToggleModal] = useState(false);

  const getAllTransportationMode = () => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint?.ADMIN_GET_ALL_TRANSPORTATION_BRAND_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_brands)
        setGetTransportationBrand(res?.data?.transportation_brands);
      });
  };

  useEffect(() => {
    getAllTransportationMode();
  }, [transportBrand]);

  const handleMeal = () => {
    setTransportBrands([...transportBrands, transportBrand]);
    setTransportBrand({ transportation_brand_name: "" });
    setToggleModal(false);
  };

  const handleMealDelete = (ind) => {
    const newArray = transportBrands?.filter((meal, index) => index !== ind);
    setTransportBrands(newArray);
  };

  const handleMealAdd = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_TRANSPORT_BRANDS_POST_API}`,
        {
          transportation_brands: transportBrands,
          requestId: "string",
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toast.success("Successfully added to transport Brand");
          setTransportBrands([]);
          setTransportBrand({
            transportation_brand_name: "",
          });
        }
      });
  };
  return (
    <div className="mt-[20px]">
      <p className="text-btn-secondary">Tour Added Brand List</p>
      <div className="flex flex-col gap-y-[15px] w-full max-w-[450px] mt-[12px] max-h-[550px] overflow-y-scroll p-[6px]">
        {getTransportationBrand?.map((brand, index) => (
          <div
            key={index}
            className="shadow-customShadow rounded-[4px] p-[10px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Brand Name :</span>{" "}
              {brand?.transportation_brand_name}
            </p>
          </div>
        ))}
      </div>

      <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed top-[90px] right-[30px] w-full">
        <h2 className="text-[22px] text-center underline">
          Transport Brand Add
        </h2>
        <div className="flex flex-col gap-y-[10px] ">
          {transportBrands?.map((meal, index) => (
            <div
              key={index}
              className="flex justify-between gap-x-[20px] relative  border rounded-[8px] border-txt-hint text-txt-primary p-[4px]"
            >
              {" "}
              <p className="flex gap-x-[5px] whitespace-wrap">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>
                </span>{" "}
                {meal?.transportation_brand_name}
              </p>
              <span
                onClick={() => handleMealDelete(index)}
                className=" cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
        {transportBrands?.length > 0 && (
          <div className="flex justify-end w-full">
            <button
              onClick={() => setToggleModal(true)}
              className="w-[150px] bg-btn-secondary h-[30px] mt-[10px] text-bg-white rounded-[8px] "
            >
              Add New
            </button>
          </div>
        )}
        {transportBrands?.length > 0 ? (
          <button
            onClick={() => handleMealAdd()}
            className="h-[40px] bg-btn-secondary w-full mt-[40px] text-bg-white rounded-[8px]"
          >
            Submit Brands
          </button>
        ) : (
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportBrand({
                      transportation_brand_name: e.target.value,
                    })
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportBrand.transportation_brand_name}
                />
                <span className="placeholder">Transportion Brand :</span>
              </label>
            </div>
            <button
              onClick={() => handleMeal()}
              disabled={transportBrand?.transportation_brand_name?.length === 0}
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        )}
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        outSideOff={false}
        height="min-h-[200px]"
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTransportBrand({
                      transportation_brand_name: e.target.value,
                    })
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportBrand.transportation_brand_name}
                />
                <span className="placeholder">Transportion Brand :</span>
              </label>
            </div>
            <button
              onClick={() => handleMeal()}
              disabled={transportBrand?.transportation_brand_name?.length === 0}
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
}
