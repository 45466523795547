import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  colorStylesTour,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";

export default function Transportation({
  setIsLoading,
  editAblePackage,
  changeSelectedNewItemList,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [transportationRouteList, setTransportationRouteList] = useState([]);
  const [transportationProviderList, setTransportationProviderList] = useState(
    []
  );
  const [transportationModeList, setTransportationModeList] = useState([]);
  const [transportationBrandList, setTransportationBrandList] = useState([]);
  const [transportationPackages, setTransportationPackages] = useState([]);
  const [transportationPackage, setTransportationPackage] = useState({
    transportation_route_id: "",
    transportation_provider_id: "",
    transportation_mode_id: "",
    transportation_brand_id: "",
    trip_type: "",
    is_ac: false,
    transportation_package_unit_price: "",
  });
  const transportationRoute = transportationRouteList?.map((item) => ({
    value: item.transportation_route_id,
    label: `${item.transportation_route_destination_location} - ${item.transportation_route_source_location}`,
  }));

  const transportationProviderArray = transformArray(
    transportationProviderList,
    "transportation_provider_id",
    "transportation_provider_name"
  );

  const transportationModeArray = transformArray(
    transportationModeList,
    "transportation_mode_id",
    "transportation_mode_name"
  );
  const transportationBrandArray = transformArray(
    transportationBrandList,
    "transportation_brand_id",
    "transportation_brand_name"
  );

  const transportationTripTypeArray = [
    { value: "ONE_WAY", label: "ONE_WAY" },
    { value: "ROUND_TRIP", label: "ROUND_TRIP" },
  ];

  const transportationWithAcArray = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const getTransportationRouteList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_ROUTE_LIST_GET_API}requestId=10`
      )
      .then((res) => {
        setTransportationRouteList(res?.data?.transportation_routes);
        // setTransportationProviderList(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const getTransportationProviderList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_PROVIDER_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        setTransportationProviderList(res?.data?.transportation_providers);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationModeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_MODE_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        setTransportationModeList(res?.data?.transportation_modes);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationBrandList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_BRAND_LIST_GET_API}requestId=10`
      )
      .then((res) => {
        setTransportationBrandList(res?.data?.transportation_brands);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getTransportationRouteList();
    getTransportationProviderList();
    getTransportationModeList();
    getTransportationBrandList();
    getTransportationPackage();
  }, []);

  function transformPayload(payload) {
    const newPayload = {
      transportation_route_id: payload.transportation_route_id.value,
      transportation_provider_id: payload.transportation_provider_id.value,
      transportation_mode_id: payload.transportation_mode_id.value,
      transportation_brand_id: payload.transportation_brand_id.value,
      trip_type: payload.trip_type.value,
      is_ac: payload.is_ac.value,

      transportation_package_per_unit_price: parseInt(
        payload.transportation_package_unit_price
      ),
    };

    return newPayload;
  }

  const a = {
    transportation_route_id: {
      value: 1,
      label: "Bandarban - Dhaka",
    },
    transportation_provider_id: {
      label: "Hanif",
      value: 1,
    },
    transportation_mode_id: {
      label: "Bus",
      value: 1,
    },
    transportation_brand_id: {
      label: "Hyundai",
      value: 1,
    },
    trip_type: {
      value: "ONE_WAY",
      label: "ONE_WAY",
    },
    is_ac: {
      value: true,
      label: "Yes",
    },
    transportation_package_unit_price: "123",
  };

  const b = {
    tour_package_transportation_package_id: 5,
    tour_package_transportation_route_id: 1,
    tour_package_transportation_route_name: "Dhaka - Bandarban",
    tour_package_transportation_mode_id: 1,
    tour_package_transportation_mode_name: "Bus",
    tour_package_transportation_brand_id: 1,
    tour_package_transportation_brand_name: "Hyundai",
    tour_package_transportation_provider_id: 1,
    tour_package_transportation_provider_name: "Hanif",
    trip_type: "ONE_WAY",
    is_ac: true,
    transportation_package_unit_price: 123,
  };
  const transformObject = (originalObject) => {
    const b = {
      transportation_route_id: {
        label: originalObject?.tour_package_transportation_route_name,
        value: originalObject?.tour_package_transportation_route_id,
      },
      transportation_provider_id: {
        label: originalObject?.tour_package_transportation_provider_name,
        value: originalObject?.tour_package_transportation_provider_id,
      },
      trip_type: {
        label: originalObject?.trip_type,
        value: originalObject?.trip_type,
      },
      transportation_brand_id: {
        label: originalObject?.tour_package_transportation_brand_name,
        value: originalObject?.tour_package_transportation_brand_id,
      },
      transportation_mode_id: {
        label: originalObject?.tour_package_transportation_mode_name,
        value: originalObject?.tour_package_transportation_mode_id,
      },
      transportation_package_unit_price:
        originalObject?.transportation_package_unit_price?.toString(),

      is_ac: {
        value: originalObject?.is_ac,
        label: originalObject?.is_ac ? "Yes" : "No",
      },
    };

    return b;
  };
  const handleSubmitTransportation = () => {
    const newPayload = transformPayload(transportationPackage);

    setIsLoading(true);
    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_TRANSPORTATION_PACKAGE}`, {
        requestId: "transportation-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_transportation_packages: newPayload,
      })
      .then((res) => {
        toast.success("Transportation Add");
        getTransportationPackage();
        setTransportationPackage({
          transportation_route_id: "",
          transportation_provider_id: "",
          transportation_mode_id: "",
          transportation_brand_id: "",
          trip_type: "",
          is_ac: false,
          transportation_package_unit_price: "",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTransportationPackage = () => {
    // merchant/tour/transportation/package/get/all/by/
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE}${convertData.subscribeId}?requestId=10`
      )
      .then((res) => {
        setTransportationPackages(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleEditTransportation = (trns) => {
    const editTrans = transformObject(trns);
    setTransportationPackage(editTrans);
  };

  useEffect(() => {
    if (
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_package_id"
      ) &&
      editAblePackage?.hasOwnProperty("tour_package_transportation_route_id") &&
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_route_name"
      ) &&
      editAblePackage?.hasOwnProperty("tour_package_transportation_mode_id") &&
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_mode_name"
      ) &&
      editAblePackage?.hasOwnProperty("tour_package_transportation_brand_id") &&
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_brand_name"
      ) &&
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_provider_id"
      ) &&
      editAblePackage?.hasOwnProperty(
        "tour_package_transportation_provider_name"
      ) &&
      editAblePackage?.hasOwnProperty("trip_type") &&
      editAblePackage?.hasOwnProperty("is_ac") &&
      editAblePackage?.hasOwnProperty("transportation_package_unit_price")
    ) {
      const editPackage = transformObject(editAblePackage);
      setTransportationPackage(editPackage);
    }
  }, [editAblePackage]);

  return (
    <div className="py-[16px] pl-[16px]">
      {/* <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Transportation Add</h2>
      </div> */}
      <div>
        <div className="w-full">
          <div className="flex flex-col gap-[8px]">
            {/* <p className="w-full p-[12px] rounded bg-[#e2e8f0] text-btn-primary font-bold">
              Create Transportation:
            </p> */}

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Transportation Route:{" "}
                </p>
              </div>
              <div className="rounded">
                <Select
                  value={transportationPackage?.transportation_route_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_route_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationRoute}
                  placeholder={"Select Route: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Transportation Provider:{" "}
                </p>
              </div>
              <div className="">
                <Select
                  value={
                    transportationPackage?.transportation_provider_id || ""
                  }
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_provider_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationProviderArray}
                  placeholder={"Select Provider: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Transportation Mode:{" "}
                </p>
              </div>
              <div className="">
                <Select
                  value={transportationPackage?.transportation_mode_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_mode_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transportationModeArray}
                  placeholder={"Select Mode: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Transportation Brand:{" "}
                </p>
              </div>
              <div className="">
                <Select
                  // defaultValue={foodItemOption || ""}
                  value={transportationPackage?.transportation_brand_id || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      transportation_brand_id: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationBrandArray}
                  placeholder={"Select Brand: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Trip Type:{" "}
                </p>
              </div>
              <div className="">
                <Select
                  // defaultValue={foodItemOption || ""}
                  value={transportationPackage?.trip_type || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      trip_type: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationTripTypeArray}
                  placeholder={"Select Type: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Ac included:{" "}
                </p>
              </div>
              <div className="rounded">
                <Select
                  value={transportationPackage?.is_ac || ""}
                  onChange={(data) =>
                    setTransportationPackage((prev) => ({
                      ...prev,
                      is_ac: data,
                    }))
                  }
                  isClearable
                  components={animatedComponents}
                  options={transportationWithAcArray}
                  placeholder={"Is Ac available: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Unit per price:{" "}
                </p>
              </div>
              <div className="rounded relative">
                <input
                  className="w-full py-[8px] px-[10px] text-[12px] text-tour-text rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                  placeholder="Unit per Price:"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setTransportationPackage((prev) => ({
                        ...prev,
                        transportation_package_unit_price: inputVal,
                      }));
                    }
                  }}
                  value={
                    transportationPackage?.transportation_package_unit_price
                  }
                  onWheel={numberInputOnWheelPreventChange}
                />
                 <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
                BDT
              </span>
              </div>
            </div>

            {/* <button
              onClick={() => handleSubmitTransportation()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button> */}
            {/* <button
              // disabled={
              //   foodPackage?.food_items_ids?.length < 1 &&
              //   foodPackage?.meal_package_unit_price !== Object &&
              //   foodPackage?.meal_type_id !== Object
              // }
              onClick={() => handleSubmitTransportation()}
              className="w-full mt-[24px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Add
            </button> */}

            <div className="w-full flex gap-[16px] pt-[24px]">
              <button
                disabled={transportationPackages?.length === 0}
                onClick={() => changeSelectedNewItemList("Guide", true)}
                className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
              >
                Save & Next
              </button>
              <button
                disabled={
                  transportationPackage?.transportation_route_id?.label ===
                    undefined ||
                  transportationPackage?.transportation_route_id?.label
                    ?.length === 0 ||
                  transportationPackage?.transportation_provider_id?.label ===
                    undefined ||
                  transportationPackage?.transportation_provider_id?.label
                    ?.length === 0 ||
                  transportationPackage?.transportation_mode_id?.label ===
                    undefined ||
                  transportationPackage?.transportation_mode_id?.label
                    ?.length === 0 ||
                  transportationPackage?.transportation_brand_id?.label ===
                    undefined ||
                  transportationPackage?.transportation_brand_id?.label
                    ?.length === 0 ||
                  transportationPackage?.trip_type?.label === undefined ||
                  transportationPackage?.trip_type?.label?.length === 0 ||
                  transportationPackage?.transportation_package_unit_price
                    ?.length === 0
                }
                onClick={() => handleSubmitTransportation()}
                className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
              >
                Add more
              </button>
            </div>
          </div>
          {/* view */}
          {/* <div className="max-h-[932px] overflow-y-scroll mb-[20px] w-full">
            {transportationPackages?.map((item, index) => (
              <div
                key={index}
                className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded relative overflow-clip"
              >
                <p className="p-[8px] text-center bg-[#e2e8f0] text-btn-primary mb-[16px] rounded">
                  {item?.tour_package_transportation_route_name}
                </p>

                <div className="xl:text-[14px] text-[12px]">
                  <p className="text-txt-primary">
                    {" "}
                    <span className="text-bg-semiblack">
                      {item?.tour_package_transportation_mode_name}:
                    </span>{" "}
                    {item?.tour_package_transportation_provider_name} -{" "}
                    {item?.tour_package_transportation_brand_name}
                  </p>
                  <p className="text-txt-primary">
                    <span className="text-bg-semiblack">Trip type: </span>
                    {item?.trip_type}
                  </p>
                  <div className="">
                    <p className="text-txt-primary">
                      <span className="text-bg-semiblack">Ac: </span>
                      {item?.is_ac ? "Yes" : "No"}
                    </p>
                  </div>
                  <p className="text-txt-primary">
                    <span className="text-bg-semiblack">
                      Price per quantity:{" "}
                    </span>
                    {item?.transportation_package_unit_price}৳
                  </p>
                  <p className="py-[4px] xl:text-[18px] text-[16px] text-center">
                    Total Price: {item?.transportation_package_unit_price}৳
                  </p>
                </div>

                <span
                  onClick={() => handleEditTransportation(item)}
                  className="absolute right-[2px] cursor-pointer top-[2px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px] text-btn-pink"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            ))}

            {transportationPackages?.length > 0 && (
              <button
                onClick={() => navigate(-1)}
                className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
              >
                Save
              </button>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}
