import React, { useEffect, useState } from "react";
import SteperRaper from "./Steper/Steper";
import MeetingPoint from "./excurionCreateSteps/MeetingPoint";
import AdditionalInformation from "./excurionCreateSteps/AdditionalInformation";
import SuitableFor from "./excurionCreateSteps/SuitableFor";
import Itinerary from "./excurionCreateSteps/Itinerary";
import UnsuitableFor from "./excurionCreateSteps/UnsuitableFor";
import Address from "./excurionCreateSteps/Address";
import Include from "./excurionCreateSteps/Include";
import Excludes from "./excurionCreateSteps/Excludes";
import { useLocation, useNavigate } from "react-router";
import { merchantExcursionRoute } from "../contants/Constants";
import { useSearchParams } from "react-router-dom";
import CancellationPolicy from "./excurionCreateSteps/CancellationPolicy";
import CreatePackage from "./excurionCreateSteps/CreatePackage";
import CreateTimeSlot from "./excurionCreateSteps/CreateTimeSlot";
import Images from "./excurionCreateSteps/Images";
import ExcursionCreate from "./excurionCreateSteps/ExcursionCreate";

// Step 1: Created
// Step 2: Address
// step 3: MeetingPoint
// Step 4: AdditionalInformation
// Step 5: SuitableFor
// Step 6: UnsuitableFor
// Step 7: IncludedExclude
// Step 8: Itinerary

const ExcursionCreateContainer = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [excursionData, setExcursionData] = useState([]);
  const url = new URL(window.location.toString());
  const [ids, setIds] = useSearchParams();
  const [excursionId, setExcursionId] = useState(Number(ids.get("id")));
  const [activeStep, setActiveStep] = useState(
    Number(url.searchParams.get("step")) || 0
  );
  const [isLoadingExcursion, setIsLoadingExcursion] = useState(false);

  // console.log( "excursionId: ", {excursionId})

  // console.log("id: ", id)
  // console.log("ids: ", Number(ids.get("id")) )

  useEffect(() => {
    navigate(
      `${merchantExcursionRoute.EXCURSION_CREATE}?step=${activeStep}&id=${
        excursionId ? excursionId : ""
      }`
    );
  }, [excursionId, activeStep]);

  // useEffect(() => {
  //   console.log("call two");

  //   if (excursionID !== 0) {
  //     setIsLoadingExcursion(true);

  //     authAxios
  //       .get(
  //         `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_BY_ID}?excursionId=${excursionID}&requestId=123`
  //       )
  //       .then((res) => {
  //         // console.log("res: ", res)
  //         setExcursionData(res?.data?.excursionData);
  //         setIsLoadingExcursion(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setIsLoadingExcursion(false);
  //       });
  //   }
  // }, []);

  return (
    <div>
      <div className="mt-[39px] pt-[22px] max-w-[1440px] mx-auto px-[22px]">
        <SteperRaper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={13}
        />
        <div className="mt-[20px] flex justify-center items-center">
          {activeStep === 0 ? (
            <ExcursionCreate
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
              excursionId={excursionId}
              setExcursionId={setExcursionId}
            />
          ) : activeStep === 1 ? (
            <Address
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 2 ? (
            <MeetingPoint
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 3 ? (
            <AdditionalInformation
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 4 ? (
            <SuitableFor
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 5 ? (
            <UnsuitableFor
              setActiveStep={setActiveStep}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 6 ? (
            <Include
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 7 ? (
            <Excludes
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 8 ? (
            <Images
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 9 ? (
            <Itinerary
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 10 ? (
            <CreatePackage
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 11 ? (
            <CreateTimeSlot
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : activeStep === 12 ? (
            <CancellationPolicy
              setActiveStep={setActiveStep}
              excursionData={excursionData}
              isLoadingExcursion={isLoadingExcursion}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExcursionCreateContainer;
