import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";

import "./AdminCouponManagerNew.css";
import Paginations from "./Paginations/Paginations";
import Loading from "../../components/Loader/Loading";
import { format } from "date-fns";
import ButtonSecond from "../../components/ButtonSecond";
import InputComponentSecond from "../../components/InputComponentSecond";

export default function AdminCouponMangerNew() {
  const [couponList, setCouponList] = React.useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [discountType, setDiscountType] = useState("");
  // const [discountOn, setDiscountOn] = useState("TOUR");
  //   const [selectedItem, setSelectedItem] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [couponCode, setCode] = useState();
  const [showModal, setShowModal] = useState(false);
  const [redemptionLimit, setRedemptionLimit] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [perUserRedemptionLimit, setperUserRedemptionLimit] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [active, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [couponCodePattern, setCouponCodePatten] = useState("");
  const [validFrom, setValidFrom] = useState();
  const [validUntil, setValidUntil] = useState();

  const discountTypes = ["PERCENTAGE", "FIXED_AMOUNT"];
  const [loaderSpinner, setLoadderSpinner] = useState(false);

  //get all coupons
  const getCoupons = () => {
    const reqObject = {
      couponCodePattern: "",
      pageNumber: page,
      pageSize: 10,
      requestId: "1234",
      validFrom: "",
      validUntil: "",
    };

    authAxios
      .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
      .then((res) => {
        setCouponList(res?.data?.entries);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (couponCodePattern === "") {
      getCoupons();
    }
  }, [page]);

  //search coupons by pattern
  const searchCoupons = () => {
    const reqObject = {
      couponCodePattern: couponCodePattern,
      pageNumber: page,
      pageSize: 10,
      requestId: "1235",
      validFrom: "",
      validUntil: "",
    };
    setLoadderSpinner(true);
    authAxios
      .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
      .then((res) => {
        setCouponList(res?.data?.entries);
        setLoadderSpinner(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // if (couponCodePattern !== "") {
    //   searchCoupons();
    // } else {
    //   getCoupons();
    // }
    searchCoupons();
  }, [couponCodePattern, page]);

  //search coupons by date
  const searchCouponsByDate = () => {
    if (validFrom || validUntil) {
      const reqObject = {
        couponCodePattern: "",
        pageNumber: 1,
        pageSize: 10,
        requestId: "123",
        validFrom: validFrom,
        validUntil: validUntil,
      };

      authAxios
        .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
        .then((res) => {
          setCouponList(res?.data?.entries);
        })
        .catch((err) => {});
    } else {
      return;
    }
  };

  useEffect(() => {
    searchCouponsByDate();
  }, [validFrom, validUntil]);

  //edit coupons
  const handleEdit = () => {
    const reqObject = {
      active: active,
      couponCode,
      discountType,
      discountValue,
      id: selectedItemId,
      perUserRedemptionLimit: perUserRedemptionLimit,
      redemptionLimit,
      validFrom: startDate,
      validUntil: endDate,
    };
    authAxios
      .post(`${apiEndPoint.ADMIN_UPDATE_COUPONS}`, reqObject)
      .then((res) => {
        toastMessage("Coupon updated successfully!");
        setShowModal(false);
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!!", "error");
      });
  };

  //add a new coupon
  const addNew = () => {
    const reqObject = {
      active: false,
      couponCode,
      discountType,
      discountValue,
      perUserRedemptionLimit: perUserRedemptionLimit,
      redemptionLimit: redemptionLimit,
      validFrom: startDate,
      validUntil: endDate,
    };
    authAxios
      .post(`${apiEndPoint.ADMIN_UPDATE_COUPONS}`, reqObject)
      .then((res) => {
        toastMessage("Coupon created successfully!");
        setShowModal(false);
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };

  //activate a coupon
  const activateCoupon = (id) => {
    authAxios
      .put(`${apiEndPoint.ADMIN_ACTIVATE_COUPONS}/${id}`)
      .then((res) => {
        toastMessage("Coupon activated successfully!");
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };

  //deactivate a coupon
  const deActivateCoupon = (id) => {
    authAxios
      .put(`${apiEndPoint.ADMIN_DEACTIVATE_COUPONS}/${id}`)
      .then((res) => {
        toastMessage("Coupon deactivated successfully!");
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };
  // console.log("date: ", validFrom, " ", validUntil);
  return (
    <div className="p-4 ">
      {/* <div className="flex justify-between items-center gap-2">
        <div className="text-3xl">
          <h2 className="text-3xl mb-6 text-center">Coupons</h2>
        </div>
        <ButtonFilled
          title={"Create new"}
          w="20%"
          arrow={"no"}
          buttonFilledHandle={() => {
            setSelectedItemId(null);
            setCode("");
            setStartDate(new Date());
            setEndDate(new Date());
            setDiscountValue();
            setRedemptionLimit();
            setperUserRedemptionLimit();
            setDiscountType(discountTypes[0]);
            setShowModal(true);
          }}
        />
      </div>
      <div className="w-[30rem] flex mb-[1rem]">
        <div className="mt-[1.8rem] mr-[1rem]">
          <input
            className="pl-[1rem] w-[20rem] h-[3rem] rounded-[8px] focus:outline-none border-[1px] focus:border-[#4E90A8]"
            type="search"
            name=""
            id=""
            placeholder="Search coupon/s"
            onChange={(e) => {
              setCouponCodePatten(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-2 w-full ">
          <div className="w-full">
            <div className="mb-1">Start Date</div>
            <input
              type={"date"}
              value={validFrom}
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
              className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
            />
          </div>
          <div className="w-full">
            <div className="mb-1">End Date</div>
            <input
              type={"date"}
              value={validUntil}
              onChange={(e) => {
                setValidUntil(e.target.value);
              }}
              className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
            />
          </div>
        </div>
      </div>
      <table id="students" className="min-w-full leading-normal ">
        <thead>
          <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Coupon code
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Type
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Discount value
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Valid from
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Valid until
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Redemption limit
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Redemption limit (per user)
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <div className="my-[14px]"></div>
        <tbody>
          {couponList &&
            couponList?.map((member, index) => {
              const {
                id,
                couponCode,
                discountType,
                discountValue,
                validFrom,
                validUntil,
                redemptionLimit,
                active,
                perUserRedemptionLimit,
              } = member; //destructuring
              return (
                <tr className="cursor-pointer" key={id}>
                  <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                    {couponCode}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {discountType}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {discountType === "PERCENTAGE"
                      ? discountValue + " %"
                      : discountValue}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {new Date(validFrom).toDateString()}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {new Date(validUntil).toDateString()}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {redemptionLimit}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {perUserRedemptionLimit}
                  </td>
                  <td className="h-[80px] px-5 py-2 border-b border-t border-r bg-white text-sm">
                    <div className="flex w-[150px] mb-[5px]">
                      <ButtonOutlined
                        title={"Edit"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          setSelectedItemId(id);
                          setCode(couponCode);
                          setActive(active);
                          setDiscountType(discountType);
                          setDiscountValue(discountValue);
                          setperUserRedemptionLimit(perUserRedemptionLimit);
                          setRedemptionLimit(redemptionLimit);
                          setStartDate(validFrom);
                          setEndDate(validUntil);
                          setShowModal(true);
                        }}
                      />
                    </div>
                    <div className="flex w-[150px] mb-[5px]">
                      <ButtonOutlined
                        title={active ? "Active" : "Activate"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          activateCoupon(id);
                        }}
                        disable={active && "yes"}
                      />
                    </div>
                    <div className="flex w-[150px]">
                      <ButtonOutlined
                        title={!active ? "Deactivated" : "Deactivate"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          deActivateCoupon(id);
                        }}
                        disable={!active && "yes"}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table> */}

      <div className="my-16">
        <h2 className="my-8 xl:text-4xl text-2xl font-semibold text-btn-primary text-center">
          Coupons
        </h2>

        <div className="my-8 flex gap-4 items-end justify-start py-2">
          <div className="relative text-txt-primary">
            <InputComponentSecond
              type={"text"}
              name={"search"}
              value={couponCodePattern}
              placeholder={"Search coupon's"}
              setValue={setCouponCodePatten}
              height={"h-12"}
              borderRadious={"rounded"}
              width={"xl:w-72 md:w-60 w-48"}
            ></InputComponentSecond>
          </div>

          <div className="relative text-gray-600">
            <label className="pl-2" htmlFor="Start Date">
              Start Date
            </label>
            <div>
              <input
                type="date"
                name="search"
                placeholder="Search coupon's"
                className="bg-[#48c9af16] h-12 w-48 px-5 rounded text-sm focus:outline-none focus:border-[#48C9B0]  border-[#86efac] border focus:shadow-md shadow-sm"
                value={validFrom}
                onChange={(e) => {
                  setValidFrom(e.target.value);
                }}
              />
              <button
                type="submit"
                className="absolute right-0 top-0 mt-3 mr-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-0 w-0 fill-current text-gray-500 cursor-pointer"
                  viewBox="0 0 24 24"
                >
                  <path
                    className="heroicon-ui"
                    d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 001.48-5.34C16 6.71 14.29 5 12.25 5S8.5 6.71 8.5 8.73c0 1.67.65 3.24 1.83 4.42l-.28.27H9.5l-2.83 2.83a1 1 0 000 1.42 1 1 0 001.42 0L12 11.41l2.83 2.83a1 1 0 001.42 0 1 1 0 000-1.42L15.5 14zm-3 0C10.01 14 9 12.99 9 11.5S10.01 9 11.5 9 14 10.01 14 11.5 12.99 14 11.5 14z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="relative text-gray-600">
            <label className="pl-2" htmlFor="Start Date">
              End Date
            </label>
            <div>
              <input
                type="date"
                name="search"
                placeholder="Search coupon's"
                className="bg-[#48c9af16] h-12 w-48 px-5 rounded text-sm focus:outline-none focus:border-[#48C9B0]  border-[#86efac] border focus:shadow-md shadow-sm"
                value={validUntil}
                onChange={(e) => {
                  setValidUntil(e.target.value);
                }}
              />
              <button
                type="submit"
                className="absolute right-0 top-0 mt-3 mr-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-0 w-0 fill-current text-gray-500 cursor-pointer"
                  viewBox="0 0 24 24"
                >
                  <path
                    className="heroicon-ui"
                    d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 001.48-5.34C16 6.71 14.29 5 12.25 5S8.5 6.71 8.5 8.73c0 1.67.65 3.24 1.83 4.42l-.28.27H9.5l-2.83 2.83a1 1 0 000 1.42 1 1 0 001.42 0L12 11.41l2.83 2.83a1 1 0 001.42 0 1 1 0 000-1.42L15.5 14zm-3 0C10.01 14 9 12.99 9 11.5S10.01 9 11.5 9 14 10.01 14 11.5 12.99 14 11.5 14z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="mr-6 ml-auto">
            {/* <ButtonFilled
              title={"Create new"}
              w="100%"
              arrow={"no"}
              buttonFilledHandle={() => {
                setSelectedItemId(null);
                setCode("");
                setStartDate(new Date());
                setEndDate(new Date());
                setDiscountValue();
                setRedemptionLimit();
                setperUserRedemptionLimit();
                setDiscountType(discountTypes[0]);
                setShowModal(true);
              }}
            /> */}

            <span
              onClick={() => {
                setShowModal(true);
              }}
            >
              <ButtonSecond
                name={"Create New"}
                width={"w-64"}
                height={"h-12"}
              ></ButtonSecond>
            </span>
          </div>
        </div>

        {loaderSpinner ? (
          <div className="flex items-center justify-center">
            <Loading></Loading>
          </div>
        ) : (
          <table className="table-auto min-w-full bg-btn-base">
            <thead className="border-b-[2px] border-bg-semiwhite">
              <tr className="text-bg-primary text-[12px] xl:text-[16px] md:text-[14px] bg-[#48C9B0]">
                <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                  Coupon Code
                </th>
                <th className="p-[12px] w-1/12 font-semibold text-bg-primary ">
                  Type
                </th>

                <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                  Discount value
                </th>
                <th className="p-[12px] w-2/12 font-semibold text-bg-primary">
                  Valid from
                </th>
                <th className="p-[12px] w-2/12 font-semibold text-bg-primary">
                  Valid until
                </th>
                <th className="p-[12px] w-1/12 font-semibold text-bg-primary text-[10px] md:text-[12px] xl:text-[14px]">
                  Redemption limit
                </th>
                <th className="p-[12px] w-1/12 font-semibold text-bg-primary text-[10px] md:text-[12px] xl:text-[14px]">
                  Redemption limit (per user)
                </th>
                <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                  Activate Status
                </th>

                <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                  Activate
                </th>

                <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {couponList &&
                couponList.map((item, indx) => (
                  <Fragment key={item.id}>
                    <tr className="bg-bg-semiwhite text-txt-primary text-center text-[12px] xl:text-[16px] md:text-[14px] hover:bg-[#48c9af16]">
                      <td className="h-[86px]">{item?.couponCode}</td>
                      <td className="">{item?.discountType}</td>

                      <td className="">
                        {item?.discountValue}{" "}
                        {item?.discountType === "PERCENTAGE" ? "%" : ""}{" "}
                      </td>
                      <td>
                        <span className={`px-2 py-1 rounded`}>
                          {item?.validFrom &&
                            new Date(item.validFrom).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                        </span>
                      </td>
                      <td className="">
                        <span className={`px-2 py-1 rounded`}>
                          {item?.validUntil &&
                            new Date(item.validUntil).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                        </span>
                      </td>
                      <td className="">
                        <span className={`px-2 py-1 rounded`}>
                          {item?.redemptionLimit}
                        </span>
                      </td>
                      <td className="">
                        <span className={`px-2 py-1 rounded`}>
                          {item?.perUserRedemptionLimit}
                        </span>
                      </td>
                      <td className="pt-2">
                        <span
                          className={`px-2 py-1 rounded ${
                            item?.active
                              ? "text-[#22c55e] text-[10px] xl:text-[14px] md:text-[12px]"
                              : "text-[#f43f5e] text-[10px] xl:text-[14px] md:text-[12px]"
                          }`}
                        >
                          {item?.active === true ? "Activate" : "Deactivate"}
                        </span>
                      </td>
                      <td className="">
                        <div className=" flex justify-center items-center ">
                          <label className="relative inline-block w-[55px]">
                            <input
                              type="checkbox"
                              className="peer invisible"
                              defaultChecked={item?.active}
                              onClick={() => {
                                if (item?.active) {
                                  deActivateCoupon(item?.id);
                                } else {
                                  activateCoupon(item?.id);
                                }
                              }}
                            />
                            <span className="absolute top-0 left-0 w-14 h-8 rounded-full bg-[#ef4444] border border-[#ef4444] transition-all duration-1000 peer-checked:border-[#22c55e] peer-checked:bg-[#22c55e]"></span>
                            <span className="absolute top-1 left-1 w-6 h-6 bg-bg-primary rounded-full z-10 transition-all duration-500 peer-checked:translate-x-6 cursor-pointer"></span>
                          </label>
                        </div>

                        {/* <div className="border border-[#86efac] bg-[#48c9af16] py-2 mx-3 rounded-full flex justify-center items-center">
                 <div
                          className={` px-[8px] py-[4px] mr-3 rounded-full cursor-pointer hover:opacity-80 ${item?.active === true? "scale-x-110 bg-[#4ade80] " : "scale-75 bg-[#d1d5db]"}`}
                          onClick={() => deActivateCoupon(item?.id)}
                          title={`Click to Deactivate`}
                        >
                          <i className="fa-solid fa-check text-bg-primary"></i>
                        </div>
                        <div
                          className={` px-[10px] py-1 rounded-full cursor-pointer hover:opacity-80 ${item?.active === false? "scale-110 bg-[#f87171] " : "scale-75 bg-[#d1d5db]"}`}
                          onClick={() => activateCoupon(item?.id)}
                          title={`Click to Activate`}
                        >
                          <i className="fa-solid fa-xmark text-bg-primary"></i>
                        </div>
                 </div> */}
                      </td>

                      <td>
                        <div className="flex justify-center items-center ">
                          <button
                            title="Edit Details"
                            className="border px-[32px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[12px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                            onClick={() => {
                              setSelectedItemId(item.id);
                              setCode(item.couponCode);
                              setActive(item.active);
                              setDiscountType(item.discountType);
                              setDiscountValue(item.discountValue);
                              setperUserRedemptionLimit(
                                item.perUserRedemptionLimit
                              );
                              setRedemptionLimit(item.redemptionLimit);
                              setStartDate(item.validFrom);
                              setEndDate(item.validUntil);
                              setShowModal(true);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-[20px]"></tr>
                  </Fragment>
                ))}
            </tbody>
          </table>
        )}
      </div>

      {
        <div className="flex justify-between">
          <button
            className={`${
              page === 1
                ? "bg-txt-hint text-txt-secondary border-bg-semiblack w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
                : "bg-btn-primary hover:bg-btn-secondary transition duration-300 hover:text-bg-white w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
            }`}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            disabled={page === 1 ? true : false}
          >
            Previous
          </button>

          <button
            className={`${
              couponList?.length < 10
                ? "bg-txt-hint text-txt-secondary border-bg-semiblack w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
                : "bg-btn-primary hover:bg-btn-secondary transition duration-300 hover:text-bg-white w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
            }`}
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={couponList?.length < 10 ? true : false}
          >
            Next
          </button>
        </div>
      }
      <ReactModal
        style={{ content: { width: "60vw" } }}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="px-10 py-8 relative">
          <button
            className="absolute top-5 right-6"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                fill="#2A220E"
                fillOpacity="0.45"
              />
            </svg>
          </button>
          <div className="text-center text-2xl mb-4">
            {selectedItemId ? "Edit Coupon" : "Add Coupon"}
          </div>
          <div>Coupon Code</div>
          <InputComponent
            name={"code"}
            inputValue={couponCode}
            setInputValue={setCode}
            required={true}
            warningView={false}
          />
          <div>Discount Type</div>
          <div className="h-[49px] w-full relative">
            <div
              onClick={() => setIsHidden(!isHidden)}
              className="cursor-pointer hover:border-[#b1ad9c] border-[#9e9989] border h-[50px] rounded-[8px] w-full flex items-center px-4 justify-between"
            >
              {!discountType && <div className="text-bg-semiblack"></div>}
              <div>{discountType}</div>

              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#9e9989"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <ul
              id="scroll-x"
              className={
                "max-h-[200px] w-full h-auto overflow-scroll custom-shadow dropdown-menu absolute text-gray-700 bg-bg-primary mt-2 rounded-[8px] custom-shadow z-10 " +
                (isHidden ? "hidden" : "")
              }
            >
              {discountTypes.map((each, index) => {
                return (
                  <li key={index}>
                    <div
                      onClick={() => {
                        setDiscountType(each);
                        setIsHidden(true);
                      }}
                      className="cursor-pointer text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 block whitespace-no-wrap"
                    >
                      {each}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {discountType === "PERCENTAGE" ? (
            <div>
              <div>Discount Percent</div>
              <InputComponent
                name={"code1"}
                inputValue={discountValue}
                setInputValue={setDiscountValue}
                required={true}
                warningView={false}
              />
            </div>
          ) : (
            <div>
              <div>Discount Amount</div>
              <InputComponent
                name={"code2"}
                inputValue={discountValue}
                setInputValue={setDiscountValue}
                required={true}
                warningView={false}
              />
            </div>
          )}

          <div>
            <div>Redemption Limit</div>
            <InputComponent
              name={"code3"}
              inputValue={redemptionLimit}
              setInputValue={setRedemptionLimit}
              required={true}
              warningView={false}
            />
          </div>
          <div>
            <div>Redemption Limit Per Person</div>
            <InputComponent
              name={"code4"}
              inputValue={perUserRedemptionLimit}
              setInputValue={setperUserRedemptionLimit}
              required={true}
              warningView={false}
            />
          </div>
          <div className="flex gap-2 w-full">
            <div className="w-full">
              <div className="mb-1"> Start Date </div>
              <input
                type={"date"}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
              />
            </div>
            <div className="w-full">
              <div className="mb-1"> End Date </div>
              <input
                type={"date"}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
              />
            </div>
          </div>
          {/* <Single */}

          <div className="flex justify-center gap-2 mt-8">
            <ButtonFilled
              title={"Submit"}
              w="200px"
              buttonFilledHandle={() => {
                if (
                  couponCode &&
                  discountValue &&
                  redemptionLimit &&
                  perUserRedemptionLimit &&
                  startDate &&
                  endDate
                ) {
                  selectedItemId && handleEdit();
                  !selectedItemId && addNew();
                } else {
                  toastMessage("Please fill all the required fields");
                }
              }}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
