import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";

const AdminSetReview = () => {
  const [hotelData, setHotelData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ratingSet, setRatingSet] = useState();
  const [userRating, setUserRating] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 10;

  const getThumbData = () => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN
        }?pageNumber=${currentPage}&pageSize=${pageSize}&requestId=123&propertyNameKeyword=${searchValue}`
      )
      .then((res) => {
        setHotelData(res?.data?.totalPages);
        setCurrentData(res?.data?.entries);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getThumbData();
  }, [searchValue, currentPage, pageSize]);

  const submitButton = (id) =>
    authAxios
      .put(
        `${
          baseUrl + apiEndPoint.ADMIN_PUT_RENTAL_PROPERTY_REVIEW_USER_RATING
        }no_of_reviews=${Number(
          userRating
        )}&rentalId=${id}&requestId=22&userRatings=${Number(ratingSet)}`
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully submitted Ratings");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });

  return (
    <>
      {/* searchbar */}
      <div className="px-[16px]  min-h-screen pb-[20px]">
        <div className="max-w-[1100px]  mx-auto">
          <h2 className="text-center text-[22px] pt-[30px]">
            Hotel Set Ratings
          </h2>

          <div className="mt-[20px] grid grid-cols-12 gap-x-[30px] justify-between items-center">
            <label className="relative block col-span-8">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                className="placeholder:italic text-txt-primary placeholder:text-txt-hint block bg-bg-white w-full border border-bg-semiblack rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-bg-semiblack focus:ring-bg-semiblack focus:ring-1 sm:text-sm"
                placeholder="Search for hotel name..."
                type="text"
                name="search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
            {/* <div className="col-span-4 flex gap-x-[10px] items-center justify-end">
          <p>Show Result for : </p>
          <select value={value} onChange={handleChange} className="p-[6px]">
            <option value="active">All</option>
            <option value="Deactivate">
              {pathname === "/admin/hotel_facility"
                ? "Without Facility"
                : "Without Thumb"}
            </option>
          </select>
        </div> */}
          </div>
          <div className="w-full bg-bg-primary mt-[25px]">
            {/* New Table */}
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px] z-50">
                  <tr>
                    <th>
                      <span>Hotel Name</span>
                    </th>
                    <th>Contact Number</th>
                    {/* <th>Ratings</th> */}
                    <th>Number of Review</th>
                    <th>User Rating</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.length > 0 &&
                    currentData?.map((property, index) => (
                      <tr key={index}>
                        <td
                          className="cursor-pointer"
                          //   onClick={() =>
                          //     navigate(
                          //       facility
                          //         ? `${adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY}/${property?.propertyId}`
                          //         : `${adminRoute.ADMIN_THUMB_IMAGE}/${property?.propertyId}`
                          //     )
                          //   }
                        >
                          {property?.propertyName}
                        </td>
                        <td>{property?.contactNo}</td>
                        {/* <td>4.5</td> */}
                        <td>
                          <div className="rounded w-[55%] mx-auto">
                            {/* <AdminSetRatingsSelect
                              setRatingSet={setRatingSet}
                              ratingSet={ratingSet}
                            ></AdminSetRatingsSelect> */}
                            <input
                              onChange={(e) => setUserRating(e.target.value)}
                              type="number"
                              className="px-[4px] py-[2px] rounded-[4px] border-[1px] border-btn-secondary"
                              placeholder="Enter Number of Review "
                            />
                          </div>
                        </td>

                        <td>
                          <div className="rounded w-[55%] mx-auto">
                            <input
                              onChange={(e) => setRatingSet(e.target.value)}
                              type="number"
                              className="px-[4px] py-[2px] rounded-[4px] border-[1px] border-btn-secondary"
                              placeholder="Enter User Rating "
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() => submitButton(property?.propertyId)}
                            className="bg-btn-primary text-bg-white w-[60%] px-[20px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                          >
                            Submit
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* <div className="">
          {pageSize > 1 && (
            <Paginations
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div> */}

            <div className=" my-10">
              <Paginations
                currentPage={currentPage}
                totalCount={hotelData}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSetReview;
