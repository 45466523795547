import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import Select from "react-select";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  colorStylesTour,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { toast } from "react-toastify";
import { convertStringToObject } from "../ParentPackageSelected";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";

export default function Transfer({
  setIsLoading,
  editAblePackage,
  changeSelectedNewItemList,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [modeList, setTransportationModeList] = useState([]);
  const [transferProvider, setTransferProvider] = useState([]);

  const [transferTour, setTransferRoute] = useState();
  const marchantId = localStorage.getItem("id");
  const [transferPackage, setTransferPackage] = useState({
    transfer_route: "",
    tour_transfer_mode_id: "",
    tour_transfer_provider_id: "",
    trip_type: "",
    is_ac: "",
    per_vehicle_per_trip_price: "",
    suitable_for_persons: "",
  });

  useEffect(() => {
    authAxios
      .get(
        `${apiEndPoint.MARCHANT_GET_TRANFER_ROUTE}${marchantId}/${convertData.subscribeId}?requestId=transfer_route`
      )
      .then((res) => {
        setTransferRoute(res.data?.short_addresses);
      });
  }, []);

  const getTransportationModeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_MODE_LIST_GET_API}requestId=10`
      )
      .then((res) => {
        setTransportationModeList(res?.data?.transportation_modes);
      })
      .catch((err) => console.log(err));
  };

  const getRoomCategoryList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_PROVIDER_LIST_GET_API}requestId=100`
      )
      .then((res) => {
        setTransferProvider(res?.data?.transportation_providers);
      })
      .catch((err) => console.log(err));
  };
  const transferModeArray = transformArray(
    modeList,
    "transportation_mode_id",
    "transportation_mode_name"
  );

  const accomodationCategoryArray = transformArray(
    transferProvider,
    "transportation_provider_id",
    "transportation_provider_name"
  );

  const is_AC = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const transportationTripTypeArray = [
    { value: "ONE_WAY", label: "ONE_WAY" },
    { value: "ROUND_TRIP", label: "ROUND_TRIP" },
  ];
  useEffect(() => {
    getTransportationModeList();
    getRoomCategoryList();
    getTransferPackage();
  }, []);

  const [transferPackageList, setTransferPackageList] = useState([]);
  const getTransferPackage = () => {
    // merchant/tour/transfer/package/get/all/by/7?requestId=123
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSFER_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setTransferPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };


  function transformPayload(payload) {
    const newPayload = {
      transfer_route: payload.transfer_route,
      tour_transfer_mode_id: payload.tour_transfer_mode_id.value,
      tour_transfer_provider_id: payload.tour_transfer_provider_id.value,
      trip_type: payload.trip_type.value,
      is_ac: payload.is_ac.value,
      per_vehicle_per_trip_price: parseInt(payload.per_vehicle_per_trip_price),
      suitable_for_persons: parseInt(payload.suitable_for_persons),
    };

    return newPayload;
  }

  const transformObject = (originalObject) => {
    const b = {
      tour_transfer_mode_id: {
        label: originalObject?.tour_package_transportation_mode_name,
        value: originalObject?.tour_package_transportation_mode_id,
      },
      tour_transfer_provider_id: {
        label: originalObject?.tour_package_transportation_provider_name,
        value: originalObject?.tour_package_transportation_provider_id,
      },
      trip_type: {
        label: originalObject?.trip_type,
        value: originalObject?.trip_type,
      },
      per_night_room_price:
        originalObject.per_vehicle_per_trip_price?.toString(),
      suitable_for_persons: originalObject?.suitable_for_persons?.toString(),
      is_ac: {
        value: originalObject?.is_ac,
        label: originalObject?.is_ac ? "Yes" : "No",
      },
      transfer_route: originalObject?.tour_package_transfer_route,
      per_vehicle_per_trip_price: originalObject?.per_vehicle_per_trip_price,
    };

    return b;
  };

  const handleEditTransfer = (trns) => {
    const editTransfer = transformObject(trns);
    setTransferPackage(editTransfer);
  };

  useEffect(() => {
    if (editAblePackage?.hasOwnProperty("tour_package_transfer_package_id") &&
    editAblePackage?.hasOwnProperty("tour_package_transfer_route") &&
    editAblePackage?.hasOwnProperty("tour_package_transportation_mode_id") &&
    editAblePackage?.hasOwnProperty("tour_package_transportation_mode_name") &&
    editAblePackage?.hasOwnProperty("tour_package_transportation_provider_id") &&
    editAblePackage?.hasOwnProperty("tour_package_transportation_provider_name") &&
    editAblePackage?.hasOwnProperty("is_ac") &&
    editAblePackage?.hasOwnProperty("trip_type") &&
    editAblePackage?.hasOwnProperty("per_vehicle_per_trip_price") &&
    editAblePackage?.hasOwnProperty("suitable_for_persons") ) {
      const editPackage = transformObject(editAblePackage);
      setTransferPackage(editPackage);
    }
  }, [editAblePackage]);

  const handleSubmitTransfer = () => {
    const newPayload = transformPayload(transferPackage);
    setIsLoading(true);
    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_TRANSFER_PACKAGE}`, {
        requestId: "transfer-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_transfer_package: newPayload,
      })
      .then((res) => {
        toast.success("Transfer Add");
        getTransferPackage();
        setSelectedValues([]);
        setTransferPackage({
          transfer_route: "",
          tour_transfer_mode_id: "",
          tour_transfer_provider_id: "",
          trip_type: "",
          is_ac: true,
          per_vehicle_per_trip_price: "",
          suitable_for_persons: "",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="py-[16px] pl-[16px]">
      {/* <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Transfer Add</h2>
      </div> */}
      <div className="flex justify-start gap-x-[170px] items-start">
        <div className="w-full flex flex-col gap-[8px]">
          <div className="flex flex-col gap-[4px]">
            <p className="text-tour-secondary text-[12px] font-normal">
              Transfer Route:{" "}
            </p>
            <div className="relative ">
              <div
                onClick={() => setShowDropDown(true)}
                className="w-full px-[12px] flex justify-start items-center h-[37px] rounded-[8px] relative border-btn-secondary border-[1.5px] "
              >
                <p className="text-[14px] ">
                  {transferPackage?.transfer_route ? (
                    transferPackage?.transfer_route
                  ) : (
                    <span className="text-tour-border text-[12px]">
                      Please select transfer route
                    </span>
                  )}
                </p>
                <button
                  onClick={() => {
                    setTransferPackage((prev) => ({
                      ...prev,
                      transfer_route: "",
                    }));
                    setSelectedValues([]);
                  }}
                  className="absolute right-[12px] cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[24px] h-[24px] text-[#E74C3C]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowDropDown(false);
                }}
              >
                {showDropDown && (
                  <div className="absolute bottom-[-40px] z-[50]  w-full h-full">
                    <div className="flex flex-col py-[4px] bg-bg-white justify-start items-start  border rounded-[4px] border-[#ddd] shadow-customShadow text-start">
                      {transferTour?.map((item, index) => (
                        <button
                          onClick={() => {
                            const currentValue = item;
                            if (!selectedValues.includes(currentValue)) {
                              const updatedValues = [
                                ...selectedValues,
                                currentValue,
                              ];
                              setSelectedValues(updatedValues);
                              setTransferPackage((prev) => ({
                                ...prev,
                                transfer_route: updatedValues.join("-"),
                              }));
                            }
                          }}
                          key={index}
                          className="hover:bg-[#d6eaf84a] py-[4px] w-full flex justify-start px-[12px] text-start"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          </div>

          <div className="flex flex-col gap-[8px]">
            <div className="flex flex-col gap-[4px]">
              <div>
                <p className="text-tour-secondary text-[12px] font-normal">
                  Tour Transfer Mode:{" "}
                </p>
              </div>
              <div className="rounded">
                <Select
                  value={transferPackage?.tour_transfer_mode_id}
                  onChange={(data) =>
                    setTransferPackage((prev) => ({
                      ...prev,
                      tour_transfer_mode_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={transferModeArray}
                  placeholder={"Select Transfer Mode: "}
                  styles={colorStylesTour}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Tour Transfer Provider:{" "}
              </p>
            </div>
            <div className="rounded">
              <Select
                value={transferPackage?.tour_transfer_provider_id}
                onChange={(data) =>
                  setTransferPackage((prev) => ({
                    ...prev,
                    tour_transfer_provider_id: data,
                  }))
                }
                components={animatedComponents}
                options={accomodationCategoryArray}
                placeholder={"Select Provider: "}
                styles={colorStylesTour}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Is Ac:{" "}
              </p>
            </div>
            <div className="rounded">
              <Select
                value={transferPackage?.is_ac}
                onChange={(data) =>
                  setTransferPackage((prev) => ({
                    ...prev,
                    is_ac: data,
                  }))
                }
                components={animatedComponents}
                options={is_AC}
                placeholder={"AC/NoNAC: "}
                styles={colorStylesTour}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Trip Type:{" "}
              </p>
            </div>
            <div className="rounded">
              <Select
                value={transferPackage?.trip_type}
                onChange={(data) =>
                  setTransferPackage((prev) => ({
                    ...prev,
                    trip_type: data,
                  }))
                }
                components={animatedComponents}
                options={transportationTripTypeArray}
                placeholder={"ONE_WAY/ROUND_TRIP: "}
                styles={colorStylesTour}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Suitable for Persons:{" "}
              </p>
            </div>
            <div className="rounded">
              <div className="">
                <input
                  onChange={(e) =>
                    setTransferPackage((prev) => ({
                      ...prev,
                      suitable_for_persons: e.target.value,
                    }))
                  }
                  className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                  type="number"
                  placeholder="Suitable for Persons "
                  value={transferPackage?.suitable_for_persons}
                  onWheel={numberInputOnWheelPreventChange}
                />
              </div>
              {/* <Select
                      value={transferPackage?.suitable_for_persons}
                      onChange={(data) =>
                        setTransferPackage((prev) => ({
                          ...prev,
                          suitable_for_persons: data,
                        }))
                      }
                      components={animatedComponents}
                      options={suitable_for_personsArray}
                      placeholder={"Select persons: "}
                      styles={colorStyles}
                    /> */}
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <div>
              <p className="text-tour-secondary text-[12px] font-normal">
                Per Vehicle Per Trip Price:{" "}
              </p>
            </div>
            <div className="rounded relative">
              <input
                className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;

                  if (!isNaN(inputVal)) {
                    setTransferPackage((prev) => ({
                      ...prev,
                      per_vehicle_per_trip_price: inputVal,
                    }));
                  }
                }}
                placeholder="Unit per price "
                onWheel={numberInputOnWheelPreventChange}
                value={transferPackage?.per_vehicle_per_trip_price}
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
                BDT
              </span>
            </div>
          </div>

          {/* <button
            onClick={() => handleSubmitTransfer()}
            className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
          >
            Submit
          </button> */}
          {/* <button
              // disabled={
              //   foodPackage?.food_items_ids?.length < 1 &&
              //   foodPackage?.meal_package_unit_price !== Object &&
              //   foodPackage?.meal_type_id !== Object
              // }
              onClick={() => handleSubmitTransfer()}
              className="w-full mt-[24px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Add
            </button> */}

          <div className="w-full flex gap-[16px] pt-[24px]">
            <button
              disabled={transferPackageList?.length === 0}
              onClick={() => changeSelectedNewItemList("Transportation", true)}
              className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Save & Next
            </button>
            <button
              disabled={
                transferPackage?.transfer_route?.length === 0 ||
                transferPackage?.tour_transfer_mode_id?.label === undefined ||
                transferPackage?.tour_transfer_mode_id?.label?.length === 0 ||
                transferPackage?.is_ac?.label === undefined ||
                transferPackage?.is_ac?.label?.length === 0 ||
                transferPackage?.trip_type?.label === undefined ||
                transferPackage?.trip_type?.label?.length === 0 ||
                transferPackage?.suitable_for_persons?.length === 0 ||
                transferPackage?.per_vehicle_per_trip_price?.length === 0
              }
              onClick={() => handleSubmitTransfer()}
              className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
            >
              Add more
            </button>
          </div>
        </div>

        {/* view */}
        {/* <div className="w-full">
          <div className="max-h-[1000px] overflow-y-scroll mb-[20px]">
            {transferPackageList?.map((item, index) => (
              <div
                key={index}
                className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded overflow-clip relative"
              >
                <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                  {item?.tour_package_transfer_route} -{" "}
                  {item?.tour_package_transportation_provider_name}
                </p>
                <div className="xl:text-[14px] text-[12px]">
                  <p className="text-txt-primary">
                    {" "}
                    <span className="text-bg-semiblack">Mode Type:</span>{" "}
                    {item?.tour_package_transportation_mode_name}{" "}
                  </p>

                  <p className="text-txt-primary">
                    {" "}
                    <span className="text-bg-semiblack">
                      suitable_for_persons :
                    </span>{" "}
                    {item?.suitable_for_persons}{" "}
                  </p>

                  <div className="flex justify-between">
                    <p className="text-txt-primary">
                      <span className="text-bg-semiblack">is_ac: </span>
                      {item?.is_ac ? "Yes" : "No"}
                    </p>
                    <p className="text-txt-primary">
                      <span className="text-bg-semiblack">trip_type: </span>
                      {item?.trip_type}
                    </p>
                  </div>
                  <p className="text-txt-primary">
                    <span className="text-bg-semiblack">
                      Price per quantity:{" "}
                    </span>
                    {item?.per_vehicle_per_trip_price}৳
                  </p>
                </div>
                <span
                  // onClick={() => handleRemoveAccommondation(index)}
                  className="absolute right-[2px] cursor-pointer top-[2px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[25px] h-[25px] text-btn-pink"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span
                  onClick={() => handleEditTransfer(item)}
                  className="absolute left-[2px] cursor-pointer top-[2px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                  </svg>
                </span>
              </div>
            ))}

            {transferPackageList?.length > 0 && (
              <button
                onClick={() => navigate(-1)}
                className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
              >
                Save
              </button>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
