import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MERCHANT_TOUR_ENDPOINT } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import TourSingleCard from "./Tours/TourSingleCard";
import TourCard from "./TourCard";

const AllTour = () => {
  const [allTourData, setAllTourData] = useState([]);
  const [tours, setTours] = useState({});

  const getAllTourList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TOURS_CREATED_TOUR_GET_API_ALL}?requestId=1`
      )
      .then((res) => {
        setAllTourData(res?.data?.tours);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    console.log("AllTour Called")
    getAllTourList();
  }, []);

  const handleViewButton = (tour) => {
    setTours(tour)
  }

  return (
    <>
      <div>
      <div className="flex gap-[32px]">
      <div className="w-[746px]">
      <div className="grid  mx-auto justify-center  grid-cols-2 md:grid-cols-3  items-center gap-[16px] ">
          {allTourData &&
            allTourData.map((tour, index) => (
              <TourSingleCard key={index} tour={tour} handleViewButton={handleViewButton} tourId={tours?.tour_id} />
            ))}
        </div>
      </div>

            {
              Object.keys(tours)?.length === 0 ? "" : 
              <div className="w-[304px] flex flex-col gap-[16px]">
                <p className="text-tour-text text-[24px] font-medium">Tour Preview</p>
                <TourCard tours={tours} subscribeButton={true}></TourCard>
              </div>
            }
            </div>
        
      </div>
    </>
  );
};

export default AllTour;
