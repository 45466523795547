import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import PaginationComponent from "../../components/common/Pagination";
import InputComponent from "../../components/InputComponent";
import FileMultipleCategory from "../../components/multipleimage/FileMultipleCategory";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";
import ButtonSecond from "../../components/ButtonSecond";
import InputComponentSecond from "../../components/InputComponentSecond";
import Paginations from "./Paginations/Paginations";

export default function IconAndImages() {
  const [iconList, setIconList] = useState([]);
  const [iconListFiltered, setIconListFiltered] = useState([]);
  const [iconName, setIconName] = useState("");
  const [total, setTotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [title, setTitle] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);

  const [selectedItemId, setSelectedItemId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  let pageSize = 10;

  console.log("currentPage", currentPage)
  console.log("iconName", iconName)

  const getIcons = () => {
    authAxios
      .post(
        `${apiEndPoint.ICON_SEARCH_LIST}${
          iconName ? iconName : " "
        }?pageNumber=${currentPage}&pageSize=${10}&requestId=ab`
      )
      .then((response) => {
        //console.log("category data: ", response.data.entries);
        setIconList(response.data.entries);
        setTotalElements(response.data?.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIcons();
  }, [ currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
    getIcons();
  }, [iconName]);

  const handleEdit = () => {
    if (selectedItemId) {
      authAxios
        .put(`${apiEndPoint.ADMIN_ICON_EDIT}${selectedItemId}`, {
          title,
          url: iconUrl,
        })
        .then(() => {
          const index = iconList.findIndex((el) => el.id === selectedItemId);
          const tmp = iconList;
          tmp[index] = { ...tmp[index], title, url: iconUrl };
          setIconList([...tmp]);
          setUrlTempStorageArray(() => []);
          setShowModal(false);
        });
    }
  };

  const addNew = () => {
    authAxios
      .post(`${apiEndPoint.ADMIN_ICON_POST}`, {
        title,
        url: urlTempStorageArray[0].url,
      })
      .then(() => {
        setTitle("");
        setIconUrl("");
        setUrlTempStorageArray(() => []);
        getIcons();
        setShowModal(false);
        toastMessage("Operation Successful");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (urlTempStorageArray.length > 0) {
      setIconUrl(urlTempStorageArray[0].url);
    }
  }, [urlTempStorageArray]);

  return (
    <div className="container mx-auto flex flex-cols">
      <div className="w-full">
        <h2 className="xl:text-4xl lg:text-3xl text-2xl mb-6 text-center my-10 text-btn-primary">
          Ghuddy Icons and Images
        </h2>
        <div className="my-10 flex items-center justify-between mx-4">
          <h2 className="text-xl md:text-2xl text-btn-secondary">
            Total Icon: {iconList.length}
          </h2>

          <div className="flex flex-col md:flex-row items-center">
            <div className=" md:mr-6 ">
              <span onClick={() => setShowModal(true)}>
                <ButtonSecond
                  height={"h-12"}
                  width={"w-48"}
                  name={"Add New"}
                ></ButtonSecond>
              </span>
            </div>
            <div className="my-4">
              <InputComponentSecond
                placeholder={"Search Icon"}
                height={"h-12"}
                width={"w-48 md:w-64"}
                value={iconName}
                setValue={setIconName}
                borderRadious={"rounded-md"}
              ></InputComponentSecond>
            </div>
          </div>
        </div>

        <table className="table-auto min-w-full bg-btn-base">
          <thead className="border-b-[2px] border-bg-semiwhite">
            <tr className="text-bg-primary text-[12px] xl:text-[16px] md:text-[14px] bg-[#48C9B0]">
              <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                ID
              </th>
              <th className="p-[12px] w-3/12 font-semibold text-bg-primary ">
                Title
              </th>

              <th className="p-[12px] w-2/12 font-semibold text-bg-primary">
                Icon
              </th>
              <th className="p-[12px] w-2/12 font-semibold text-bg-primary">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {iconList &&
              iconList.map((item, indx) => (
                <Fragment key={indx}>
                  <tr className="bg-bg-semiwhite text-center text-[12px] xl:text-[16px] md:text-[14px] hover:bg-[#48c9af16]">
                    <td className="h-[86px]">{item?.id}</td>
                    <td className="">{item?.title}</td>

                    <td className="">
                      <img
                        className="mx-auto h-6 w-6 md:h-9 md:w-9 xl:h-12 xl:w-12"
                        src={item?.url}
                        alt=""
                      />
                    </td>

                    <td>
                      <div className="flex justify-center items-center ">
                        <button
                          title="view details"
                          className="border px-[32px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[12px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedItemId(item.id);
                            setTitle(iconList[indx].title);
                            setIconUrl(iconList[indx].url);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr className="h-[20px]"></tr>
                </Fragment>
              ))}
          </tbody>
        </table>

        {/* Older Code */}
        {/* <h2 className="text-3xl mb-6 text-center">Ghuddy Admin</h2>
        <div className="flex justify-between gap-x-6 items-center">
          <div className="flex items-center gap-2 my-2">
            <h2 className="text-2xl w-2/3">Total Icons: {total}</h2>
            <ButtonFilled
              title={"Add"}
              w="40%"
              arrow={"no"}
              buttonFilledHandle={() => {
                setSelectedItemId(null);
                setTitle("");
                setIconUrl("");
                setShowModal(true);
              }}
            />
          </div>

          <div className="rounded border h-[50px]">
            <input
              className="px-4 w-full bg-[transparent] h-full"
              placeholder="Search Icon"
              value={iconName}
              onChange={(e) => setIconName(e.target.value)}
            />
          </div>
        </div>
        <table id="students" className="min-w-full leading-normal">
          <thead>
            <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Id
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Title
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Icon
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <div className="my-[14px]"></div>
          <tbody>
            {iconList &&
              iconList
                .filter((each) =>
                  each?.title?.toLowerCase().includes(iconName.toLowerCase())
                )
                .map((member, index) => {
                  const { id, title, url } = member; //destructuring
                  return (
                    <tr className="cursor-pointer" key={id}>
                      <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                        {id}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {title}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        <img
                          src={url}
                          alt={title}
                          width="70px"
                          height={"70px"}
                        />
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t border-r bg-white text-sm">
                        <div className="flex max-w-[300px]">
                          <ButtonOutlined
                            title={"Edit"}
                            w="100%"
                            buttonOutlinedHandle={() => {
                              setSelectedItemId(id);
                              setTitle(iconList[index].title);
                              setIconUrl(iconList[index].url);
                              setShowModal(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table> */}

        {/* <PaginationComponent
          totalElements={total}
          currentPage={currentPage}
          dataSize={iconList?.length}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          showCount={true}
        /> */}

        <div className=" my-10">
          <Paginations
            currentPage={currentPage}
            totalCount={totalElements}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <ReactModal
        style={{ content: { width: "60vw" } }}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="px-10 py-8 relative">
          <button
            className="absolute top-5 right-6"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                fill="#2A220E"
                fillOpacity="0.45"
              />
            </svg>
          </button>
          <div className="text-center text-2xl mb-4">
            {selectedItemId ? "Edit Item" : "Add Item"}
          </div>
          <div>Title</div>
          <InputComponent
            name={"title"}
            inputValue={title}
            setInputValue={setTitle}
            required={true}
          />

          <div className="mt-6"> Url </div>

          <InputComponent
            name={"Url"}
            inputValue={iconUrl}
            setInputValue={setIconUrl}
            required={true}
          />

          <React.Fragment>
            <div className="text-bg-semiblack mb-2 capitalize mt-8 text-center text-xl">
              File:
              {urlTempStorageArray.length > 0 ? (
                <span className="font-semibold">
                  {" "}
                  {urlTempStorageArray[0].filename}{" "}
                </span>
              ) : (
                " No file selected"
              )}
            </div>
            <div className="">
              <FileMultipleCategory
                id={1}
                setNeedUpdate={() => {}}
                urlTempStorageArray={urlTempStorageArray}
                setUrlTempStorageArray={setUrlTempStorageArray}
                multiple={false}
                type={"default"}
                upload={true}
              />
            </div>
          </React.Fragment>

          <div className="flex justify-center gap-2 mt-8">
            {selectedItemId && (
              <ButtonOutlined
                title={"Delete"}
                w="200px"
                buttonOutlinedHandle={() => {
                  setShowDeleteModal(true);
                  setShowModal(false);
                }}
              />
            )}
            <ButtonFilled
              title={"Submit"}
              w="200px"
              buttonFilledHandle={() => {
                selectedItemId && handleEdit();
                !selectedItemId && addNew();
              }}
            />
          </div>
        </div>
      </ReactModal>

      <ReactModal
        style={{ content: { width: "auto" } }}
        isOpen={showDeleteModal}
        onRequestClose={() => {}}
      >
        <div className="px-10 py-8">
          <div className="text-center text-2xl mb-4">
            Are you sure you want to delete this item ?
          </div>
          <div className="flex justify-center gap-2 mt-8">
            {selectedItemId && (
              <ButtonOutlined
                title={"No"}
                w="150px"
                buttonOutlinedHandle={() => {
                  setShowDeleteModal(false);
                  setShowModal(true);
                }}
              />
            )}
            <ButtonFilled
              title={"Yes"}
              w="150px"
              arrow={"no"}
              buttonFilledHandle={() => {}}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
