import { ReviewFor } from "../../../../contants/Constants";
import {
  apiEndPoint,
  apiEndPointForOwnerReviewSubmission,
  baseUrl,
  INDEXING,
} from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import { toastMessage } from "../../../../utils/toast";
import {
  submitReviewError,
  submitReviewRequest,
  submitReviewSuccess,
} from "../actions/actions";

export function reviewSubmission(
  reviewFor,
  id,
  data,
  setFetchSummeryCallAgain,
  errorHandle
) {
  return (dispatch) => {
    dispatch(submitReviewRequest());
    let url = "";
    switch (reviewFor) {
      case ReviewFor.OWNER:
        url = apiEndPointForOwnerReviewSubmission.OWNER_REVIEW_SUBMIT + id;
        break;
      case ReviewFor.CONTRACT:
        url = apiEndPoint.FETCH_OWNER_INFO_ADMINV2 + id + `/contract-info`;
        break;
      case ReviewFor.DOCUMENT:
        url = apiEndPoint.FETCH_OWNER_INFO_ADMINV2 + id + "/document";
        break;
      case ReviewFor.HOTEL:
        url = apiEndPointForOwnerReviewSubmission.HOTEL_REVIEW_SUBMIT + id;
        break;
      case ReviewFor.CATEGORY:
        url = apiEndPointForOwnerReviewSubmission.CATEGORY_REVIEW_SUBMIT + id;
        break;
      case ReviewFor.SUBMIT:
        url = `${apiEndPoint.SUBMIT_FOR_REVIEWV2}?requestId=qwe`;
        break;
      default:
        break;
    }

    if (reviewFor === ReviewFor.SUBMIT) {
      authAxios.post(url, data).then((response) => {
        dispatch(submitReviewSuccess(response));
        console.log(response, "submitdata3");
        setFetchSummeryCallAgain();
      });
    } else {
      authAxios
        .patch(url, data)
        .then((response) => {
          console.log("Submit data success1");
          if (reviewFor == ReviewFor.SUBMIT) {
            //   console.log("Submit data success2");
            //   dispatch(submitReviewSuccess(response));
            //   console.log(response, "submitdata3");
            //   setFetchSummeryCallAgain();
          } else if (reviewFor === ReviewFor.HOTEL) {
            authAxios.put(`${INDEXING.PLACE_GENERATE}`).then((res) => {
              authAxios.put(`${INDEXING.PLACE_INDEX}`).then((res) => {
                toastMessage("indexing successfully!");
                dispatch(submitReviewSuccess(response.data.data));
                errorHandle();
              });
            });
          } else {
            dispatch(submitReviewSuccess(response.data.data));

            console.log(response, "submitdata4");
          }
        })
        .catch((error) => {
          dispatch(submitReviewError(error));
          console.log(error, "submitdataerror");
          errorHandle();
        });
    }

    // authAxios
    //   .patch(url, data)
    //   .then((response) => {
    //     console.log("Submit data success1");
    //     if (reviewFor == ReviewFor.SUBMIT) {
    //         console.log("Submit data success2");
    //         dispatch(submitReviewSuccess(response));
    //         console.log(response, "submitdata3");
    //         setFetchSummeryCallAgain();
    //     } else if (reviewFor === ReviewFor.HOTEL) {
    //       authAxios.put(`${INDEXING.PLACE_GENERATE}`).then((res) => {
    //         authAxios.put(`${INDEXING.PLACE_INDEX}`).then((res) => {
    //           toastMessage("indexing successfully!");
    //           dispatch(submitReviewSuccess(response.data.data));
    //           errorHandle();
    //         });
    //       });
    //     } else {
    //       dispatch(submitReviewSuccess(response.data.data));
    //       console.log(response, "submitdata4");
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(submitReviewError(error));
    //     console.log(error, "submitdataerror");
    //     errorHandle();
    //   });
  };
}
