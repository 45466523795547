import { useEffect } from "react";
import { changeUrl } from "../utils/common";
import styles from "./stepers.module.css";

function Stepper({ steps, activeStep, setActiveStep }) {
  const handleUpdateStep = (step) => {
    const url = new URL(window.location.toString());
    if (Number(url.searchParams.get("id"))) {
      changeUrl(Number(url.searchParams.get("id")), step);
      setActiveStep(step);
    } else {
      setActiveStep(step);
    }

    // setActiveStep(step);
    // if (setActiveStep !== undefined) {
    //   if (step === 2 && state.loggedIn) {
    //     setActiveStep(2);
    //   } else if (state.loggedIn && step === 0) {
    //     setActiveStep(0);
    //   } else if (state.loggedIn) {
    //     setActiveStep(2);
    //   } else if (step < 3) {
    //     setActiveStep(step);
    //   }
    // }
  };

  return (
    <div className={`${styles.steps_container}`}>
      {steps.map((label, index) => (
        <div
          className={`${styles?.stepes} ${
            activeStep === index
              ? `${styles.step_active}`
              : activeStep > index
              ? `${styles?.step_done}`
              : `${styles?.step_inactive}`
          }`}
          key={index}
        >
          <div
            onClick={() => {
              handleUpdateStep(index);
            }}
            className=" cursor-pointer"
          >
            <div className={`${styles.circle}`}>
              <p className="flex justify-center items-center h-full text-[12px]">
                {index + 1}
              </p>
            </div>
          </div>
          <div className={`${styles.label}`}>Step {label}</div>
          {index < steps.length - 1 && <div className={`${styles.line}`}></div>}
        </div>
      ))}
    </div>
  );
}

function SteperRaper({ activeStep, setActiveStep, steps }) {
  // const steps = [
  //   "Step 1",
  //   "Step 2",
  //   "Step 3",
  //   "Step 4",
  //   "Step 5",
  //   "Step 6",
  //   "Step 7",
  //   "Step 8",
  //   "Step 9",
  //   "Step 10",
  // ];

  const createArray = (value) => {
    const newArray = Array.from({ length: value }, (_, index) => index + 1);
    return newArray;
  };

  const stepValue = createArray(steps);

  return (
    <div>
      <Stepper
        steps={stepValue}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      {/* <div style={{padding: '20px'}}>
        { getSectionComponent()  }
        { (activeStep !== 0 && activeStep !== steps.length - 1)
            && <button onClick={ () => setActiveStep(activeStep - 1) }>Previous</button>
        }
        { activeStep !== steps.length - 1
          && <button onClick={ () => setActiveStep(activeStep + 1) }>Next</button>
        }
      </div> */}
    </div>
  );
}

export default SteperRaper;
