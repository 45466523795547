import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import Select from "react-select";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import tourMealTypeReducer from "../../../store/admin/combinationPackageTour/reducers/tourMealTypeReducer";
import TourMerchantButton from "../../TourMerchantButton";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../../Utilities/SelectAnimation";
import FoodPackageDaySelect from "./FoodPackageDaySelect";

const FoodPackage = ({
  setPackageSelect,
  setMarchantCreateDatas,
  marchantTourCreateDatas,
  viewPageFood,
  setViewPageFood,
  allFoodPackage,
  setAllFoodPackage,
}) => {
  const [foodItemList, setFoodItemList] = useState([]);
  const [foodMealTypeList, setFoodMealTypeList] = useState([]);

  const [foodPackage, setFoodPackage] = useState({
    meal_type_id: "",
    food_items_ids: [],
    meal_package_unit_price: "",
  });
  const [updateSubmitButtonCondition, setUpdateSubmitButtonCondition] =
    useState(false);
  const [selectedMealPackageIndex, setSelectedMealPackageIndex] =
    useState(null);

  const handleBackButton = () => {
    setPackageSelect(null);
  };

  const getfoodItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_FOOD_ITEM_LIST_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.food_items);
        setFoodItemList(res?.data?.food_items);
      })
      .catch((err) => console.log(err));
  };

  const getMealItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_TYPE_LIST_GET_API}?page-number=1&page-size=10&requestId=1`
      )
      .then((res) => {
        // console.log(res?.data?.meal_types);
        setFoodMealTypeList(res?.data?.meal_types);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getfoodItemList();
    getMealItemList();
  }, []);

  const foodItems = transformArray(
    foodItemList,
    "food_item_id",
    "food_item_name"
  );

  const foodMealType = transformArray(
    foodMealTypeList,
    "meal_type_id",
    "meal_type_name"
  );

  const handleAddButtonClick = () => {
    if (
      foodPackage?.meal_type_id?.label !== null &&
      foodPackage?.food_items_ids?.length > 0 &&
      foodPackage?.meal_package_unit_price > 0
    ) {
      setAllFoodPackage((prevPackage) => ({
        ...prevPackage,
        tour_package_meal_packages: [
          ...prevPackage.tour_package_meal_packages,
          foodPackage,
        ],
      }));
      toast.success("Successfully Added");
      setFoodPackage({
        meal_type_id: "",

        food_items_ids: [],
        meal_package_unit_price: "",
      });
    }
  };

  const handleEditFoodpackageButton = (ind) => {
    setSelectedMealPackageIndex(ind);
    setUpdateSubmitButtonCondition(true);
    const newPackage = allFoodPackage?.tour_package_meal_packages?.find(
      (meal, index) => index === ind
    );
    setFoodPackage({
      meal_type_id: newPackage?.meal_type_id,
      food_items_ids: newPackage?.food_items_ids,
      meal_package_unit_price: newPackage?.meal_package_unit_price,
    });
  };

  const handleUpdateFoodPackageButton = () => {
    if (selectedMealPackageIndex !== null) {
      setAllFoodPackage((prevPackage) => {
        const updatedPackages = [...prevPackage.tour_package_meal_packages];
        updatedPackages[selectedMealPackageIndex] = foodPackage;

        return {
          ...prevPackage,
          tour_package_meal_packages: updatedPackages,
        };
      });

      setSelectedMealPackageIndex(null);
      setUpdateSubmitButtonCondition(false);
      setFoodPackage({
        meal_type_id: "",
        food_items_ids: [],
        meal_package_unit_price: "",
      });
    }
    toast.success("Successfully Updated.");
  };

  function keyToNumber(key, value) {
    if (key === "meal_package_unit_price") {
      return Number(value);
    }
    return value;
  }

  function transformObject(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(transformObject);
    }
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          if ("value" in value) {
            result[key] = keyToNumber(key, value.value);
          } else if (key === "food_items_ids") {
            result[key] = value.map((foodItem) =>
              keyToNumber("food_items_ids", foodItem.value)
            );
          } else {
            result[key] = transformObject(value);
          }
        } else {
          result[key] = keyToNumber(key, value);
        }
      }
    }
    return result;
  }
  const dispatch = useDispatch();
  const handleSubmitMeal = () => {
    // setPackageSelect(null);

    const payload = transformObject(allFoodPackage);

    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHENT_CHECK_FOOD_LIST}`, payload)
      .then((res) => {
        setMarchantCreateDatas((prevPackage) => ({
          ...prevPackage,
          tour_package_food_options: [...res?.data?.tour_package_food_options],
        }));

        if (res?.status === 200) {
          toast.success("Created successfully!");
          dispatch(
            tourMealTypeReducer({
              type: "RECEIVE_DATA",
              payload: res?.data?.meal_type_name_id_map,
            })
          );
          setViewPageFood(1);
        }
      })
      .catch((err) => {});
    setFoodPackage({});
  };
  const mealTypeName = useSelector((state) => state.tourMealTypeReducer?.data);
  const handleRemoveMeal = (ind) => {
    const newArray = allFoodPackage?.tour_package_meal_packages?.filter(
      (meal, index) => index !== ind
    );

    setAllFoodPackage((prevPackage) => ({
      ...prevPackage,
      tour_package_meal_packages: newArray,
    }));
    toast.success("Successfully removed!");
  };

  const getKeyFromValue = (value) => {
    for (const key in mealTypeName) {
      if (mealTypeName[key] === value) {
        return key;
      }
    }
    return null;
  };
  console.log("mealTypeName", mealTypeName);

  // const updateDefaultFoodOption = (indexToMatch) => {
  //   const updatedOptions = viewFoodPackage.tour_package_food_options.map(
  //     (option, index) => {
  //       if (index === indexToMatch) {
  //         return {
  //           ...option,
  //           tour_package_default_food_option:
  //             !option.tour_package_default_food_option,
  //         };
  //       }
  //       return option;
  //     }
  //   );

  //   setViewFoodPackage({
  //     ...viewFoodPackage,
  //     tour_package_food_options: updatedOptions,
  //   });
  //   setMarchantCreateDatas((prevPackage) => ({
  //     ...prevPackage,
  //     tour_package_food_options: viewFoodPackage,
  //   }));
  // };

  const updateFoodOptionAtIndex = (index) => {
    const updatedFoodOptions =
      marchantTourCreateDatas?.tour_package_food_options?.map((option, i) => {
        if (i === index) {
          return {
            ...option,
            tour_package_default_food_option:
              !option.tour_package_default_food_option,
          };
        }
        return option;
      });

    const updatedA = {
      ...marchantTourCreateDatas,
      tour_package_food_options: updatedFoodOptions,
    };

    setMarchantCreateDatas(updatedA);

    return updatedA;
  };

  return (
    <>
      {viewPageFood === null ? (
        <>
          {" "}
          <p className="text-btn-secondary text-center mb-[24px] xl:text-[22px] md:text-[18px] text-[16px]">
            Food
          </p>
          <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton(null)}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-[70%] w-full  rounded p-[16px] bg-bg-white h-full">
                <p className="w-full p-[12px] rounded bg-[#e2e8f0] text-btn-primary font-bold text-center">
                  Create Food:
                </p>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Meal Type : </p>
                  </div>
                  <div className="rounded">
                    <Select
                      // defaultValue={foodItemOption || ""}
                      value={foodPackage?.meal_type_id || ""}
                      onChange={(data) =>
                        setFoodPackage((prev) => ({
                          ...prev,
                          meal_type_id: data,
                        }))
                      }
                      isClearable
                      components={animatedComponents}
                      options={foodMealType}
                      placeholder={"Select Options: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Food Items: </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={foodPackage?.food_items_ids}
                      onChange={(data) => {
                        setFoodPackage((prev) => ({
                          ...prev,
                          food_items_ids: data,
                        }));
                      }}
                      components={animatedComponents}
                      options={foodItems}
                      placeholder={"Select Food: "}
                      styles={colorStyles}
                      isMulti
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Meal Unit per Price: </p>
                  </div>

                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Meal Price:"
                      type="number"
                      onChange={(e) => {
                        const inputVal = e.target.value;

                        if (!isNaN(inputVal)) {
                          setFoodPackage((prev) => ({
                            ...prev,
                            meal_package_unit_price: inputVal,
                          }));
                        }
                      }}
                      value={foodPackage?.meal_package_unit_price}
                    />
                  </div>
                </div>

                <div className="mt-[32px] mb-[16px]">
                  {updateSubmitButtonCondition ? (
                    <TourMerchantButton
                      name={"Update"}
                      width={"w-full"}
                      onClick={() => handleUpdateFoodPackageButton()}
                    ></TourMerchantButton>
                  ) : (
                    <TourMerchantButton
                      name={"Add"}
                      width={"w-full"}
                      onClick={handleAddButtonClick}
                    ></TourMerchantButton>
                  )}
                </div>
              </div>
              <div className="md:w-[30%] w-full bg-[#e5e7eb] md:pl-[16px] mt-[16px] md:mt-0">
                {allFoodPackage?.tour_package_meal_packages?.length === 0 && (
                  <div className="bg-bg-white p-[16px] w-full rounded text-btn-secondary text-center">
                    No Item Selected
                  </div>
                )}

                {/* Right-side Cart */}
                <div className="max-h-[600px] overflow-y-scroll mb-[20px]">
                  {allFoodPackage?.tour_package_meal_packages &&
                    allFoodPackage?.tour_package_meal_packages?.map(
                      (item, index) => (
                        <div
                          key={index}
                          className="bg-bg-white overflow-clip md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded  relative"
                        >
                          <div
                            className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[15px] left-[-47px] rotate-[-45deg] ${
                              item?.meal_package_is_default?.value
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            <p className="text-[14px] font-extrabold uppercase ">
                              Included
                            </p>
                          </div>
                          <p className="p-[8px] py-[4px] mr-[12px] w-full  xl:text-[18px] text-[16px] text-center bg-[#e2e8f0] text-btn-primary mb-[8px] rounded">
                            {item?.meal_type_id?.label}
                          </p>
                          <div className="mt-[16px] mb-[4px] w-full xl:text-[14px] text-[12px]">
                            <div className="flex flex-wrap">
                              <p className="text-bg-semiblack">Food Items: </p>
                              {item?.food_items_ids &&
                                item?.food_items_ids.map((food, indx) => (
                                  <div
                                    key={indx}
                                    className="text-txt-primary flex items-center justify-center"
                                  >
                                    &nbsp;
                                    <p>
                                      {" "}
                                      <AiOutlineCheck></AiOutlineCheck>{" "}
                                    </p>{" "}
                                    {food?.label}
                                    {indx >= 0 && ","}
                                  </div>
                                ))}
                            </div>
                          </div>
                          {/* <div className=" mb-[4px] xl:text-[14px] text-[12px]">
                        <p className="py-[2px] rounded">
                          <span className="text-bg-semiblack">
                            Package Included:
                          </span>
                          &nbsp; {item?.meal_package_is_default?.label}
                        </p>
                      </div> */}
                          <div className="xl:text-[14px] text-[12px]">
                            {/* <p className="">
                          <span className="text-bg-semiblack">
                            Price per quantity:{" "}
                          </span>
                          <span className="text-txt-primary">
                            {item?.meal_package_unit_price}৳ X{" "}
                            {item?.meal_package_quantity}
                          </span>
                        </p> */}
                            <p className="py-[4px] xl:text-[18px] text-[16px] text-center">
                              Price: {item?.meal_package_unit_price}৳
                            </p>
                          </div>
                          <span
                            onClick={() => handleRemoveMeal(index)}
                            className="absolute right-[2px] cursor-pointer top-[2px]"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-[25px] h-[25px] text-btn-pink"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                          <button
                            onClick={() => handleEditFoodpackageButton(index)}
                            className="absolute left-[7px] cursor-pointer top-[8px] border rounded-full p-[2px]"
                          >
                            <span className="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-[15px] h-[15px]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      )
                    )}
                </div>

                {/* {marchantTourCreateData?.tour_package?.tour_package_meal_packages
              ?.length >= 1 && (
              <div className="">
                <TourMerchantButton
                  onClick={() => handleSubmitMeal()}
                  name={"Submit"}
                  width={"w-full"}
                ></TourMerchantButton>
              </div>
            )} */}
              </div>
            </div>

            <div className="">
              <FoodPackageDaySelect
                setAllFoodPackage={setAllFoodPackage}
                allFoodPackage={allFoodPackage}
                foodMealType={foodMealType}
              ></FoodPackageDaySelect>
            </div>
          </div>
          <div className="bg-bg-semiwhite py-[20px] mt-[40px] roun-[4px]">
            <div className="flex justify-center items-center">
              <TourMerchantButton
                onClick={() => handleSubmitMeal()}
                name={"Check & Select default Options"}
              ></TourMerchantButton>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="grid grid-cols-3 gap-[20px] ">
            {marchantTourCreateDatas?.tour_package_food_options?.map(
              (foodPackage, index) => (
                <div className="flex gap-x-[10px] justify-start items-start w-full h-full ">
                  {/* default button */}
                  {/* <button
                    onClick={() => updateFoodOptionAtIndex(index)}
                    className=" min-w-[25px] min-h-[25px] max-w-[25px] max-h-[25px] border border-[#ddd] rounded-[4px] bg-bg-primary flex justify-center items-center"
                  >
                    {foodPackage?.tour_package_default_food_option && (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </span>
                    )}
                  </button> */}
                  <div
                    key={index}
                    className="w-full shadow-customShadow rounded-[4px] py-[20px] pr-[10px] pl-[20px] relative overflow-hidden text-[16px] h-full"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[15px] left-[-47px] rotate-[-45deg] ${
                        foodPackage?.tour_package_default_food_option
                          ? "block"
                          : "hidden"
                      } `}
                    >
                      <p className="text-[14px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>

                    <p className="text-center bg-btn-secondary py-[4px] rounded-[4px] text-bg-primary">
                      {foodPackage?.day_number} Day
                    </p>

                    <div className="flex flex-wrap gap-[8px] ">
                      {foodPackage?.tour_package_meal_packages?.map(
                        (mealPackage, index) => (
                          <div
                            className="mt-[10px] border p-[4px] rounded-[4px] border-[#ddd] flex flex-col gap-[4px]"
                            key={index}
                          >
                            <div className="flex flex-wrap gap-[4px]">
                              <p className="w-full text-center bg-btn-secondary text-bg-primary rounded-[4px]">
                                {getKeyFromValue(mealPackage?.meal_type_id)}
                              </p>
                              {mealPackage?.food_items?.map(
                                (foodItem, index) => (
                                  <div className="flex justify-start gap-x-[1px] items-center">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-[20px] h-[20px]"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M4.5 12.75l6 6 9-13.5"
                                        />
                                      </svg>
                                    </span>
                                    {foodItem?.food_item_name}
                                  </div>
                                )
                              )}
                            </div>

                            <p className="flex gap-x-[4px] justify-start items-center">
                              <span className="text-[14px]"> Unit Price:</span>
                              {mealPackage?.meal_package_unit_price}{" "}
                            </p>
                            <p className="flex gap-x-[4px] justify-start items-center">
                              <span className="text-[14px]">
                                {" "}
                                Total Price :
                              </span>
                              {foodPackage?.tour_package_food_option_price}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="flex mx-auto justify-end items-end gap-x-[16px]">
            <button
              onClick={() => {
                setViewPageFood(null);
                setPackageSelect(0);
              }}
              className="flex gap-x-[6px]  bg-btn-secondary text-bg-white rounded-[4px] py-[6px] w-[100px] text-center justify-center items-center "
            >
              Edit
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[20px] h-[20px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </span>
            </button>
            <button
              onClick={() => setPackageSelect(null)}
              className="mt-[20px]  w-[200px] rounded-[4px] bg-btn-secondary py-[6px] text-bg-primary"
            >
              Submit Meals
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FoodPackage;
