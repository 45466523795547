import React, { useEffect, useState } from "react";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { authAxios } from "../../utils/axiosWrapper";
import { toast } from "react-toastify";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  baseUrl,
  googleApi,
} from "../../contants/Endpoints";
import GoogleMapReact from "google-map-react";
import { changeUrl } from "../utils/common";
import Loading from "../../components/Loader/Loading";
import { IoLocationSharp } from "react-icons/io5";

const AnyReactComponent = ({ text }) => (
  <div>
    <IoLocationSharp className="w-[24px] h-[24px] text-[#ef4444]" />
  </div>
);

const MeetingPoint = ({ setActiveStep }) => {
  const [success, setSuccess] = useState(true);
  const [showError, setShowError] = useState(false);
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(0);

  const [groupData, setGroupData] = useState({
    name: "",
    description: "",
    address: "",
    lat: "",
    lng: "",
  });
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 21.419368412387854,
      lng: 91.99150702204554,
    },
    zoom: 11,
  });

  const payloadMethod = {
    address: groupData?.address,
    description: groupData?.description,
    excursionId: parseFloat(url.searchParams.get("id")),
    id: editId,
    latitude: parseFloat(groupData?.lat),
    longitude: parseFloat(groupData?.lng),
    name: groupData?.name,
    requestId: "161",
  };

  const onAddAndUpdate = () => {
    if (
      groupData?.name?.length === 0 ||
      groupData?.name === null ||
      groupData?.description?.length === 0 ||
      groupData?.name === null ||
      groupData?.address?.length === 0 ||
      groupData?.address === null ||
      groupData?.lat?.length === 0 ||
      groupData?.lat === null ||
      groupData?.lng?.length === 0 ||
      groupData?.lng === null
    ) {
      setShowError(true);
      toast.warning("All Field is Required");
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_MEETING_POINT}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Meeting Point added successfully!");
        setShowError(false);
        setSuccess(!success);
        changeUrl(res?.data?.excursionId, 3);
        setActiveStep(3);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setSuccess(false);
      });
  };

  // check already Data has
  useEffect(() => {
    if (excursionID !== 0) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_MEETING_BY_ID}?excursionId=${excursionID}&requestId=123`
        )
        .then((res) => {
          setIsLoading(false);
          setGroupData({
            ...groupData,
            name: res?.data?.name || "",
            description: res?.data?.description || "",
            address: res?.data?.address || "",
            lat:
              res?.data?.latitude !== null
                ? res?.data?.latitude
                : 21.419368412387854,
            lng:
              res?.data?.longitude !== null
                ? res?.data?.longitude
                : 91.99150702204554,
          });
          setDefaultProps({
            center: {
              lat:
                res?.data?.latitude !== null
                  ? res?.data?.latitude
                  : 21.419368412387854,
              lng:
                res?.data?.longitude !== null
                  ? res?.data?.longitude
                  : 91.99150702204554,
            },
            zoom: 11,
          });
          setEditId(res?.data?.id);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, []);

  const backAndSave = () => {
    if (
      groupData?.name?.length === 0 ||
      groupData?.name === null ||
      groupData?.description?.length === 0 ||
      groupData?.name === null ||
      groupData?.address?.length === 0 ||
      groupData?.address === null ||
      groupData?.lat?.length === 0 ||
      groupData?.lat === null ||
      groupData?.lng?.length === 0 ||
      groupData?.lng === null
    ) {
      setShowError(true);
      toast.warning("All Field is Required");
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_MEETING_POINT}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Meeting Point added successfully!");
        setShowError(false);
        setSuccess(!success);
        const url = new URL(window.location.toString());
        changeUrl(
          Number(url.searchParams.get("id")),
          Number(url.searchParams.get("step") - 1)
        );
        setActiveStep(Number(url.searchParams.get("step")) - 1);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setSuccess(false);
      });
  };

  const handleMapClick = (e) => {
    setGroupData((prev) => ({
      ...prev,
      lat: e.lat,
      lng: e.lng,
    }));
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <div className="w-[343px] md:w-[712px] ">
      <div className=" mb-[20px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
        >
          <span className="border-x-[4px] px-[8px]"> Meeting Point</span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Effortlessly pinpoint meeting spots for your travels.
        </p>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="shadow-customShadow py-[20px] px-[24px]">
          <div className="my-[16px]">
            <p className="text-btn-secondary">Name: </p>
            <div className="rounded">
              <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Name : "
                type="text"
                value={groupData.name || ""}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
              {showError &&
                (groupData?.name?.length === 0 || groupData?.name === null) && (
                  <p className="text-[14px]  text-[#f87171]">
                    Name is required.
                  </p>
                )}
            </div>
          </div>
          <div className="my-[16px]">
            <p className="text-btn-secondary">Description: </p>
            <div className="rounded">
              <textarea
                className="w-full p-[8px] rounded border-[2px] h-[100px] outline-none border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Description : "
                type="text"
                value={groupData?.description || ""}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
              {showError &&
                (groupData?.description?.length === 0 || groupData?.description === null) && (
                  <p className="text-[14px]  text-[#f87171]">
                    Description is required.
                  </p>
                )}
            </div>
          </div>

          <div className="my-[16px]">
            <p className="text-btn-secondary">Address: </p>
            <div className="rounded">
              <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Address : "
                type="text"
                value={groupData?.address || ""}
                onChange={(e) =>
                  setGroupData((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
              />
              {showError &&
                (groupData?.address?.length === 0 ||
                  groupData?.address === null) && (
                  <p className="text-[14px] text-[#f87171]">
                    Address is required.
                  </p>
                )}
            </div>
          </div>

          <div className="flex w-full my-[16px] gap-x-[8px] md:gap-x-[16px] ">
            <div className=" w-full">
              <p className="text-btn-secondary">latitude: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] disabled border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="latitude : "
                  type="text"
                  value={groupData?.lat || ""}
                  readOnly
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      lat: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.lat?.length === 0 || groupData?.lat === null) && (
                    <p className="text-[14px] text-[#f87171]">
                      Latitude is required.
                    </p>
                  )}
              </div>
            </div>
            <div className=" w-full">
              <p className="text-btn-secondary">longitude: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="longitude : "
                  readOnly
                  type="text"
                  value={groupData?.lng || ""}
                  onChange={(e) =>
                    setGroupData((prev) => ({
                      ...prev,
                      lng: e.target.value,
                    }))
                  }
                />
                {showError &&
                  (groupData?.lng?.length === 0 || groupData?.lng === null) && (
                    <p className="text-[14px] text-[#f87171]">
                      Longitude is required.
                    </p>
                  )}
              </div>
            </div>
          </div>

          {/* Added Map */}
          <div className="">
            <div style={{ height: "70vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: googleApi,
                }}
                // bootstrapURLKeys={{ key: ""}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onClick={handleMapClick}
                onChange={(e) => {
                  setGroupData((prev) => ({
                    ...prev,
                    lat: e.center.lat,
                    lng: e.center.lng,
                  }));
                }}
              >
                <AnyReactComponent
                  lat={groupData?.lat}
                  lng={groupData?.lng}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
          </div>

          <div className="mt-[40px] flex justify-center items-center w-full">
            <CustomButtonGroupExcruions
              // onSubmit={handleSubmit(onSubmitButton)}
              onSubmit={onAddAndUpdate}
              setSaveAndNext={backAndSave}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingPoint;
