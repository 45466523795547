import React, { useState } from 'react'
import SteperRaper from '../excursionMarchant/Steper/Steper'
import { useDispatch } from 'react-redux';
import { setTourStepperValue } from '../store/marchantDashboard/categoryTour/tourSetupStepper';
import { useSelector } from 'react-redux';

const TourStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  
  const activeStepperValue = useSelector(state => state?.tourSetupStepper?.value);


  return (
    <div className='px-[64px]'>
      <p className='text-[32px] text-tour-text font-medium py-[10px]'>Tour Setup</p>
      <SteperRaper
          activeStep={activeStepperValue}
          setActiveStep={setActiveStep}
          steps={3}
        />
        
    </div>
  )
}

export default TourStepper