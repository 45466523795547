import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router";
import RootComponent from "../rootComponent/RootComponent";
import GoogleMapReact from 'google-map-react';
import "./map.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
    width: "80%",
    borderRadius: "32px",
    backgroundColor: "#FFFBEF",
    padding: "0px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const MapModal = ({ setLat, setLng, lat, lng, type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  warningView,
  name }) => {
  const [modalTrigger, setModalTrigger] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [marker,setMarker] = useState({});
  const defaultProps = {
    center: {
      lat: 23.780462509023568,
      lng: 90.4166673958518,
    },
    zoom: 14,
  };
  const handleMapModal = () => {
    console.log("handleMapModal");
    //setModalTrigger(true);
    setIsOpen(true);
  };

  // function afterOpenModal() {
  //   // subtitle.style.color = "#f00";
  //   window.bkoigl.accessToken = "MTYyNDpFQVg4VlFaMUtB"; // required
  //   let map = new window.bkoigl.Map({
  //     container: "map",
  //     center: [91.97608188585023, 21.425171085200933],
  //     zoom: 12,
  //   });

  //   map.on("click", (e) => {
  //     console.log("clicked", e.lngLat, e.lngLat.lng, e.lngLat.lat);
  //     setLat(e.lngLat.lat);
  //     setLng(e.lngLat.lng);

  //     const marker = new window.bkoigl.Marker({ draggable: true })
  //       .setLngLat([e.lngLat.lng, e.lngLat.lat])
  //       .addTo(map);
  //   });
  // }

  function closeModal() {
    setIsOpen(false);
    // setModalTrigger(false);
  }
  
  const Marker = (props) => {
    
    return (
      <div className="z-[50]">
        <span>
          <img src="/location.svg" height='30px' width='30px' alt="" />
        </span>
      </div>
    );
  };
  const component = (
    <div className="w-[50px] h-full">

      {/* location button */}
      <div className="cursor-pointer h-full" onClick={handleMapModal}>
        {lat ? (
          <img src="/location.png" alt="" className="h-[50px] w-[55px]" />
        ) : (
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z"
              fill="#376576"
              className="hover:fill-btn-secondary"
            />
            <path
              d="M25 14.5C22.8128 14.5026 20.7158 15.3726 19.1692 16.9192C17.6226 18.4658 16.7526 20.5628 16.75 22.75C16.7474 24.5374 17.3312 26.2763 18.412 27.7C18.412 27.7 18.637 27.9963 18.6738 28.039L25 35.5L31.3293 28.0353C31.3623 27.9955 31.588 27.7 31.588 27.7L31.5888 27.6978C32.669 26.2747 33.2526 24.5366 33.25 22.75C33.2474 20.5628 32.3774 18.4658 30.8308 16.9192C29.2842 15.3726 27.1873 14.5026 25 14.5ZM25 25.75C24.4067 25.75 23.8266 25.5741 23.3333 25.2444C22.84 24.9148 22.4554 24.4462 22.2284 23.8981C22.0013 23.3499 21.9419 22.7467 22.0577 22.1647C22.1734 21.5828 22.4591 21.0482 22.8787 20.6287C23.2982 20.2091 23.8328 19.9234 24.4147 19.8076C24.9967 19.6919 25.5999 19.7513 26.1481 19.9784C26.6962 20.2054 27.1648 20.5899 27.4944 21.0833C27.8241 21.5766 28 22.1567 28 22.75C27.999 23.5453 27.6826 24.3078 27.1202 24.8702C26.5578 25.4326 25.7954 25.749 25 25.75Z"
              fill="#FFFBEF"
              className="hover:fill-btn-base"
            />
          </svg>
        )}
      </div>


       {/* modal that contain map */}
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="z-20 absolute pl-5 pt-5">
          <svg
            width="102"
            height="102"
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_3890_14715)">
              <rect
                x="19"
                y="16"
                width="70"
                height="70"
                rx="35"
                fill="#FFFBEF"
                fillOpacity="0.7"
                shapeRendering="crispEdges"
              />
              <path
                d="M55.4109 50.9993L62.701 43.7093C62.8648 43.518 62.9504 43.2719 62.9407 43.0202C62.9309 42.7686 62.8266 42.5298 62.6485 42.3517C62.4704 42.1736 62.2317 42.0693 61.98 42.0596C61.7283 42.0499 61.4823 42.1355 61.291 42.2993L54.0009 49.5893L46.711 42.2893C46.5196 42.1255 46.2736 42.0399 46.0219 42.0496C45.7702 42.0593 45.5315 42.1636 45.3534 42.3417C45.1753 42.5198 45.071 42.7586 45.0612 43.0102C45.0515 43.2619 45.1371 43.508 45.301 43.6993L52.591 50.9993L45.291 58.2893C45.1863 58.3789 45.1012 58.4893 45.0412 58.6133C44.9812 58.7374 44.9475 58.8725 44.9422 59.0102C44.9368 59.1479 44.96 59.2853 45.0103 59.4136C45.0606 59.5419 45.1368 59.6585 45.2343 59.7559C45.3318 59.8534 45.4483 59.9296 45.5766 59.9799C45.705 60.0302 45.8423 60.0534 45.98 60.0481C46.1177 60.0428 46.2529 60.009 46.3769 59.949C46.501 59.889 46.6113 59.804 46.701 59.6993L54.0009 52.4093L61.291 59.6993C61.4823 59.8631 61.7283 59.9487 61.98 59.939C62.2317 59.9293 62.4704 59.8249 62.6485 59.6468C62.8266 59.4688 62.9309 59.23 62.9407 58.9783C62.9504 58.7267 62.8648 58.4806 62.701 58.2893L55.4109 50.9993Z"
                fill="#2A220E"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_3890_14715"
                x="0"
                y="0"
                width="102"
                height="102"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="-3" />
                <feGaussianBlur stdDeviation="8" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_3890_14715"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_3890_14715"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>

        <div className="h-[50rem] flex ">
        {/* <div id="map" style={{ width: "100%", height: "100%" }}></div> */}

        <div className="w-full">
        <div className="border w-full h-full ">
      {/* <img className=" w-full object-cover" src="map.png" alt="" /> */}
      <GoogleMapReact
       bootstrapURLKeys={{ key: 'AIzaSyD6Khw2fWNrb4j2W98pWFPxe431u8F6ivA' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        center={defaultProps.center}
        margin={[50, 50, 50, 50]}
        options={{
          fullscreenControl: false,
        }}
        // options={''}
        // onChange={''}
        onClick={(e)=>{
          console.log(e,'mapu')
          setLat(e.lat);
          setLng(e.lng);
          setMarker({lat:e.lat,lng:e.lng});
        }}
      >
       
          <Marker lat={marker.lat} lng={marker.lng} />
      
      </GoogleMapReact>
    </div>
        </div>
        </div>
       
      
        <div
          id="mouse-info"
          className="shadow-2xl rounded-2xl bg-bg-primary self-center justify-self-center place-self-center justify-center self"
        >
          <p>{lat}</p>
          <p>{lng}</p>

        </div>
        {/* {
          component
        } */}

       
      </Modal>
    </div>
  )
  let readOnlyViewComponent = <p></p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        warningView={warningView}
        name={name}
        type={type}
      />
    </div>
  );
};

export default MapModal;
