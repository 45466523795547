import React from 'react'

const AddMore = ({title, handleAddMore}) => {
    return (
        <div onClick={handleAddMore} className="w-[150px] h-[150px] cursor-pointer font-medium text-center text-btn-secondary shadow-xl py-[50px] bg-bg-white text-lg m-[10px] rounded-[8px] ">
            <h2 className=' text-lg'>Add {title ? title : "Hotel"}</h2>
            <h1 className="bg-btn-secondary text-bg-white rounded-full ml-[40%] w-[25px] h-[25px]">+</h1>
        </div>
    )
}

export default AddMore
