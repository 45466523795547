import React, { useState } from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdOutlineSubscriptions, MdOutlineTour } from "react-icons/md";
import { TfiPackage } from "react-icons/tfi";
import { VscLayersActive } from "react-icons/vsc";

import { NavLink } from "react-router-dom";
import { merchantTourRoute } from "../contants/Constants";
import { useDispatch } from "react-redux";
import { setTourStepperValue } from "../store/marchantDashboard/categoryTour/tourSetupStepper";

const TourSideNav = () => {
  const [open, setOpen] = useState(true);
  const checkRoute = (route) => {
    if (window.location.pathname === route) {
      return true;
    }
    return false;
  };

  const dispatch = useDispatch();
  const handleSetStepperValue = (value) => {
    dispatch(setTourStepperValue(value));
  };

  return (
    <div>
      <div>
        {/* <NavLink>All Tours</NavLink> */}
        <ul>
          <NavLink to={merchantTourRoute.TOUR_DASHBOARD} onClick={() => handleSetStepperValue(0)}>
            <li
              className={`py-[8px] px-[12px] w-[198px] h-[38px] rounded-[8px] cursor-pointer duration-200 hover:text-tour-text hover:bg-tour-secondaryHint ${
                checkRoute(merchantTourRoute.TOUR_DASHBOARD)
                  ? "bg-tour-primary text-bg-white"
                  : "text-tour-text"
              }`}
            >
              All Tours
            </li>
          </NavLink>
          <NavLink to={merchantTourRoute.SUBSCRIBE_TOUR_WITH_PACKAGE} onClick={() => handleSetStepperValue(1)}>
            <li
              className={`py-[8px] px-[12px] w-[198px] h-[38px] rounded-[8px] cursor-pointer duration-200 hover:text-tour-text hover:bg-tour-secondaryHint ${
                checkRoute(merchantTourRoute.SUBSCRIBE_TOUR_WITH_PACKAGE)
                  ? "bg-tour-primary text-bg-white"
                  : "text-tour-text"
              }`}
            >
              Subscribed
            </li>
          </NavLink>
          <NavLink to={merchantTourRoute.SUBSCRIBE_TOUR} onClick={() => handleSetStepperValue(1)}>
            
            <li
              className={`py-[8px] px-[12px] w-[198px] h-[38px] rounded-[8px] cursor-pointer duration-200 hover:text-tour-text hover:bg-tour-secondaryHint ${
                checkRoute(merchantTourRoute.SUBSCRIBE_TOUR)
                  ? "bg-tour-primary text-bg-white"
                  : "text-tour-text"
              }`}
            >
              Tour with Pkg
            </li>
          </NavLink>
          <NavLink to={merchantTourRoute.ACTIVE_TOUR} onClick={() => handleSetStepperValue(0)}>
            {/* <li className="py-[8px] px-[12px] w-[198px]  text-[#142B33] rounded-[8px] hover:text-[#fff] hover:bg-[#4d5259]">Published/Active</li> */}
            <li
              className={`py-[8px] px-[12px] w-[198px] h-[38px] rounded-[8px] cursor-pointer duration-200 hover:text-tour-text hover:bg-tour-secondaryHint ${
                checkRoute(merchantTourRoute.ACTIVE_TOUR)
                  ? "bg-tour-primary text-bg-white"
                  : "text-tour-text"
              }`}
            >
              Published/Active
            </li>
          </NavLink>
        </ul>
      </div>
      {/* <div
        className={` bg-[#ffffff] h-full shadow-2xl ${
          open
            ? "lg:w-[250px] md:w-[256px] w-[240px]"
            : "lg:w-[80px] md:w-[64px] w-[4px]"
        } duration-300  lg:pt-[32px] md:pt-[32px] pt-[32px] relative`}
      >
        <div className=" px-[6px] md:px-[16px] flex justify-between items-center ">
          <div className="flex justify-center items-center">
            <div
              className={`w-[32px] h-[32px] rounded cursor-pointer float-left mr-[8px] duration-500 ${
                open && "rotate-[360deg]"
              }`}
            >
              <svg
                className="w-full h-full"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.9287 12.105L12.8237 0L0.717407 12.105L9.92367 21.3113L6.76992 24.9638H18.8762L15.7224 21.3113L24.9287 12.105ZM21.9399 12.8851L14.6999 20.1251L13.6037 18.8563V12.8851H21.9399ZM21.9399 11.3238H13.6037V2.98751L21.9399 11.3238ZM10.1812 23.4013L12.8237 20.3413L15.4662 23.4013H10.1812ZM12.0424 18.8576L10.9462 20.1263L3.70616 12.8863H12.0424V18.8576ZM3.70616 11.3238L12.0424 2.98751V11.3238H3.70616Z"
                  fill="#376576"
                ></path>
              </svg>
            </div>
            <div
              className=""
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open === false ? (
                <span className="text-[26px] cursor-pointer text-btn-secondary">
                  <AiFillCaretRight></AiFillCaretRight>
                </span>
              ) : null}
            </div>
            <h2
              className={` text-btn-primary origin-left font-medium lg:text-[24px] md:text-[24px] text-[18px]  duration-300 ${
                !open && "scale-0"
              }`}
            >
              Ghuddy
            </h2>
          </div>

          <div
            className=""
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <span className="text-[26px] cursor-pointer  text-btn-secondary">
                <AiFillCaretLeft></AiFillCaretLeft>{" "}
              </span>
            ) : null}
          </div>
        </div>

        <ul className="pt-[8px] text-bg-white ">
          <NavLink to={merchantTourRoute.TOUR_DASHBOARD}>
            <li
              className={`hover:text-[#4d5259] text-sm flex items-center gap-x-[16px] cursor-pointer py-[12px]   hover:bg-[#f9fafb] px-[6px] md:px-[18px] duration-200  ${
                checkRoute(merchantTourRoute.TOUR_DASHBOARD)
                  ? "bg-[#35c2b4] text-bg-white"
                  : "text-[#4d5259e2]"
              }`}
            >
              <span className="xl:text-[24px] lg:text-[22px] md:text-[20px] text-base block float-left">
                {" "}
                <MdOutlineTour></MdOutlineTour>{" "}
              </span>
              <span
                className={`xl:text-[18px] md:text-[16px] text-[14px] font-medium flex-1 duration-200 ${
                  !open && "hidden"
                }`}
              >
                ALL Tour
              </span>
            </li>
          </NavLink>

          <NavLink to={merchantTourRoute.SUBSCRIBE_TOUR_WITH_PACKAGE}>
            <li
              className={`  hover:text-[#4d5259] text-[16px] flex items-center gap-x-[16px] cursor-pointer py-[12px]   hover:bg-[#f9fafb] px-[6px] md:px-[18px] duration-200  ${
                checkRoute(merchantTourRoute.SUBSCRIBE_TOUR_WITH_PACKAGE)
                  ? "bg-[#35c2b4] text-bg-white"
                  : "text-[#4d5259e2]"
              }`}
            >
              <span className="xl:text-[24px] lg:text-[22px] md:text-[20px] text-[16px] block float-left">
                {" "}
                <MdOutlineSubscriptions></MdOutlineSubscriptions>{" "}
              </span>
              <span
                className={`xl:text-[18px] md:text-[16px] text-[14px] font-medium flex-1 duration-200 ${
                  !open && "hidden"
                }`}
              >
                Subscribed Tour
              </span>
            </li>
          </NavLink>

          <NavLink to={merchantTourRoute.SUBSCRIBE_TOUR}>
            <li
              className={` hover:text-[#4d5259] text-[16px] flex items-center gap-x-[16px] cursor-pointer py-[12px]   hover:bg-[#f9fafb] px-[6px] md:px-[18px] duration-200  ${
                checkRoute(merchantTourRoute.SUBSCRIBE_TOUR)
                  ? "bg-[#35c2b4] text-bg-white"
                  : "text-[#4d5259e2]"
              }`}
            >
              <span className="xl:text-[24px] lg:text-[22px] md:text-[20px] text-[16px] block float-left">
                {" "}
                <TfiPackage></TfiPackage>{" "}
              </span>
              <span
                className={`xl:text-[18px] md:text-[16px] text-[14px] font-medium flex-1 duration-200 ${
                  !open && "hidden"
                }`}
              >
                Tour With Pkg
              </span>
            </li>
          </NavLink>

          <NavLink to={merchantTourRoute.ACTIVE_TOUR}>
            <li
              className={` hover:text-[#4d5259] text-[16px] flex items-center gap-x-[16px] cursor-pointer py-[12px]   hover:bg-[#f9fafb] px-[6px] md:px-[18px] duration-200  ${
                checkRoute(merchantTourRoute.ACTIVE_TOUR)
                  ? "bg-[#35c2b4] text-bg-white"
                  : "text-[#4d5259e2]"
              }`}
            >
              <span className="xl:text-[24px] lg:text-[22px] md:text-[20px] text-[16px] block float-left">
                {" "}
                <VscLayersActive></VscLayersActive>{" "}
              </span>
              <span
                className={`xl:text-[18px] md:text-[16px] text-[14px] font-medium flex-1 duration-200 ${
                  !open && "hidden"
                }`}
              >
                Active Tour
              </span>
            </li>
          </NavLink>
        </ul>
      </div> */}
    </div>
  );
};

export default TourSideNav;
