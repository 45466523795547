import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../components/common/ModalCommon";
import { BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import Paginations from "./admin/Paginations/Paginations";
import { tcolor } from "./BookingReviewCart";
export default function BookingReviewByMarchent() {
  const [bookingRejectData, setBookingRejectData] = useState();
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShown, setToggleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const getBooking = () => {
    let url = `${BOOKING_ENDPOINTS.GET_REJECTED_BOOKING_ADMIN}pageNumber=1&pageSize=10&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingRejectData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  };
  const [singleBooking, setSingleBooking] = useState();
  const handleDetails = (data) => {
    console.log("data", data);
    setSingleBooking(data);
    setToggleModal(true);
  };
  const handleAgree = (id) => {
    // CONFIRM_BOOKING_ADMIN
    let url = `${BOOKING_ENDPOINTS.CONFIRM_BOOKING_ADMIN}${id}&requestId=12W`;
    authAxios.put(url).then((data) => {
      if (data?.status === 200) {
        toast.success("Admin agreed to the rejection!");
        setRender(true);
      }
    });
    setRender(true);
    getBooking();
  };

  const handleDisagree = (id) => {
    authAxios
      .put(`${BOOKING_ENDPOINTS.REJECTED_BOOKING_ADMIN}${id}&requestId=12W`)
      .then((data) => {
        toast.warn("Admin disagreed to the rejection!");

        if (data?.data?.success === true) {
          getBooking();
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    getBooking();
  }, [render]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-[30px] w-full max-w-[1100px] bg-bg-white p-[16px] mx-auto">
      <h2 className="text-center text-[18px]">Rejection By Marchent</h2>

      <div className="mt-[30px]">
        <table className="table-fixed  border-collapse  w-full">
          <thead className="sticky top-[88px] shadow-sm bg-[#48C9B0] text-bg-primary  text-left">
            <tr className="">
              <th className=" py-[10px] px-[15px]">Hotel Info.</th>
              <th className=" py-[10px] px-[15px]">User Info.</th>
              <th className=" py-[10px] px-[15px]">Reason</th>
              <th className=" py-[10px] px-[15px] text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {bookingRejectData?.rentalBookingReviewData?.map((booking) => (
              <tr className="hover:bg-[#E8F8F5] text-txt-primary cursor-pointer border-b border-[#eaecef]">
                <td className="py-[14px] px-[16px] flex flex-col">
                  <span>{booking?.hotelName}</span>
                  <span className="text-[12px]">
                    Booking Time: {booking?.bookingTime}
                  </span>
                </td>

                <td className="  py-[8px] px-[16px]">
                  <span className="flex flex-col gap-[4px]">
                    <span>Name : {booking?.userName}</span>
                    <span>Phone : {booking?.phoneNumber}</span>
                    <span className={`${tcolor[`${booking?.state}`]}`}>
                      Review id : #{booking?.rentalBookingReviewId}
                    </span>
                    <span className={`${tcolor[`${booking?.state}`]}`}>
                      OrderCode : {booking?.orderCode}
                    </span>
                  </span>
                </td>
                <td className="  py-[8px] px-[16px]">{booking?.note}</td>
                <td className="  py-[8px]">
                  <div className="flex justify-center items-center gap-x-[10px]">
                    <button
                      onClick={() =>
                        handleAgree(booking?.rentalBookingReviewId)
                      }
                      className="border border-btn-secondary rounded-full px-[24px] text-btn-secondary text-[14px] py-[6px]"
                    >
                      Agree
                    </button>
                    <button
                      onClick={() =>
                        handleDisagree(booking?.rentalBookingReviewId)
                      }
                      className="border border-btn-orange rounded-full px-[18px] text-btn-orange text-[14px] py-[6px]"
                    >
                      Disagree
                    </button>
                    <button
                      onClick={() => handleDetails(booking)}
                      className="cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-[20px] h-[20px]"
                      >
                        <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                        <path
                          fillRule="evenodd"
                          d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ModalCommon
          shown={modalShown}
          outSideOff={false}
          width="max-w-[910px]"
          height="min-h-[412px]"
          close={() => {
            setToggleModal(false);
          }}
        >
          <div className="mx-auto mt-[40px] ">
            <div className="flex justify-between  px-[30px]">
              <div className="flex flex-col gap-y-[10px]">
                <p>Hotel Name: {singleBooking?.hotelName}</p>
                <p>Coustomer Name : {singleBooking?.userName}</p>
                <p>Coustomer Email : {singleBooking?.email}</p>
                <p>Coustomer Phone No: {singleBooking?.phoneNumber}</p>
              </div>
              <div>
                <h2 className=" mb-[4px] text-txt-hint">Order Summary : </h2>
                <div className="flex flex-col gap-y-[6px] px-[12px] py-[12px] max-h-[320px] shadow-md rounded-[8px] overflow-y-scroll">
                  {singleBooking?.roomBookingData?.map((room, index) => (
                    <div
                      className={`${
                        index >= 0 &&
                        index !== singleBooking?.roomBookingData?.length - 1
                          ? " border-txt-hint border-b-[0.5px]"
                          : "border-none"
                      } pb-[6px]`}
                    >
                      <div className="flex items-center justify-start space-x-[2px]">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className={`w-[14px] h-[14px] text-[#F8C471]`}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                        <p>
                          {room?.roomCategoryName}{" "}
                          <span className={`text-[14px] text-[#F8C471]`}>
                            x{room?.noOfTimes}
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p>In : {room?.checkinDate}</p>
                        <p>Out : {room?.checkoutDate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalCommon>
      </div>
      <div className="">
        <Paginations
          currentPage={currentPage}
          totalCount={Math.ceil(bookingRejectData?.totalEntries / 10)}
          pageSize={10}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}
