import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EachCalenderDateCard from "../components/calender/EachCalenderDateCard";
import Loading from "../components/Loader/Loading";
import SingleSelectParentCategoryReturnCalender from "../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturnCalendar";
import { apiEndPoint } from "../contants/Endpoints";
import { categoryAvailabilityGetApi } from "../store/marchantDashboard/categoryAvailabilityCalenderGet/api/categoryAvailabilityApi";
import { noAuthAxios } from "../utils/axiosWrapperOpen";
import { DateFormatTranferToSixDigit } from "../utils/DateFormatTranferToSixDigit";
import "./availablity.css";
function AvailablityCalendar({ setCalenderOverlay, hotelShow }) {
  const date = new Date();
  const [allWeek, setAllWeek] = useState([]);
  const [month, setMonth] = React.useState(date.getMonth());
  const [year, setYear] = React.useState(date.getFullYear());
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  console.log(hotelShow.id, "hotelShowhotelShow");
  const dispatch = useDispatch();
  const allCategoriesMerchantReducer = useSelector(
    (state) => state.allCategoriesMerchantReducer.data.roomCategoryList
  );
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getAvailableDate = useSelector(
    (state) => state.categoryGetAvailabilityReducer
  );

  // state for calender availability
  const [getAvailableData, setAvailableData] = useState([]);
  useEffect(() => {
    if (startDate && endDate) {
      if (selectedCategory.roomCategoryId) {
        dispatch(
          categoryAvailabilityGetApi(
            selectedCategory.roomCategoryId,
            DateFormatTranferToSixDigit(startDate),
            DateFormatTranferToSixDigit(endDate),
            "category"
          )
        );
      } else {
        dispatch(
          categoryAvailabilityGetApi(
            hotelShow ? hotelShow.id : null,
            DateFormatTranferToSixDigit(startDate),
            DateFormatTranferToSixDigit(endDate),
            "hotel"
          )
        );
      }
    }
  }, [selectedCategory, startDate]);

  useEffect(() => {
    if (getAvailableDate) {
      setAvailableData(getAvailableDate.data.data);
    }
  }, [getAvailableDate]);

  // useEffect(() => {
  //   dispatch(getAllCategoriesMerchant());
  // }, []);

  // useEffect(() => {
  //   setCategories(allCategoriesMerchantReducer);
  // }, [allCategoriesMerchantReducer]);

  useEffect(() => {
    // dispatch(getAllCategoriesMerchant());
    noAuthAxios
      .get(
        `${apiEndPoint.ADMIN_GET_CATEGORY_BY_PROPERTY}${hotelShow.id}?requestId=2`
      )
      .then((res) => {
        setCategories(res.data.roomCategoryList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setStartDate(getStartDate(new Date(`${year}-${month + 1}-01`)));
    setEndDate(getEndDate(new Date(`${year}-${month + 1}-01`)));
  }, [month, year]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getPreviousMonthAndYear = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  function getStartDate(date) {
    let firstDate = 1;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var firstDayOfMonth = new Date(`${year}-${month}-${firstDate}`);
    return moment(firstDayOfMonth)
      .subtract(firstDayOfMonth.getDay(), "days")
      .toDate();
  }

  function getEndDate(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    console.log("lastDayOfMonth", month);
    var lastDayOfMonth = new Date(year, month, 0);
    console.log(lastDayOfMonth, "lastDayOfMonth");
    let diff = 6 - lastDayOfMonth.getDay();
    console.log(diff, lastDayOfMonth.getDay(), "lastDayOfMonth");
    return moment(lastDayOfMonth).add(diff, "days").toDate();
  }

  const getNextMonthAndYear = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  useEffect(() => {
    if (getAvailableData) {
      formatData(
        Object.keys(getAvailableData).map((key) => {
          return { ...getAvailableData[key], dayInt: key };
        })
      );
    }
  }, [getAvailableData]);

  const formatData = (array) => {
    const mainArray = [];
    let index = 0;
    for (let i = 0; i < array.length; i += 7) {
      const subArrray = [];
      for (let j = 0; j < 7; j++) {
        if (array[index]) {
          subArrray.push({ ...array[index] });
        }
        index++;
      }
      if (subArrray.length) {
        mainArray.push(subArrray);
      }
    }
    console.log(mainArray);
    setAllWeek([...mainArray]);
  };

  return (
    <div className="px-6 py-8 h-full relative w-[1180px]">
      <button
        onClick={() => setCalenderOverlay(false)}
        className="absolute  right-12 top-10 z-20"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
            fill="#2A220E"
            fillOpacity="0.45"
          />
        </svg>
      </button>
      <div className="absolute top-10 w-full">
        <div className="flex items-center justify-start w-[1160px] mx-auto relative">
          <div className="w-[315px] z-10 cursor-pointer">
            <SingleSelectParentCategoryReturnCalender
              selectedValue={selectedCategory}
              setSelectedValue={setSelectedCategory}
              data={categories}
              placeholder="Pick a Category"
              displayField={"categoryName"}
              selectedFieldName="roomCategoryId"
            />
          </div>
          <div className="flex-grow absolute w-full flex justify-center gap-x-4">
            <div
              className="shadow-2xl p-3 self-center cursor-pointer text-right"
              onClick={getPreviousMonthAndYear}
            >
              {"<"}
            </div>
            <div className="self-center text-center min-w-[180px]">{`${monthNames[month]}, ${year}`}</div>
            <div
              className=" shadow-2xl p-3 self-center cursor-pointer"
              onClick={getNextMonthAndYear}
            >
              {">"}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full overflow-y-auto pt-20">
        {!getAvailableDate.loading && (
          <table className="max-w-[1120px] mx-auto border-collapse columns-7 table">
            <thead className="">
              <td>S</td>
              <td>M</td>
              <td>T</td>
              <td>W</td>
              <td>T</td>
              <td>F</td>
              <td>S</td>
            </thead>
            <tbody>
              {allWeek &&
                allWeek.map((i, key) => (
                  <tr>
                    {i.map((data, index) => (
                      <div className="border w-[160px] px-1 py-1 h-[120px] table-cell">
                        <EachCalenderDateCard
                          data={data}
                          key={index}
                          selectedCategory={selectedCategory.roomCategoryId}
                        />
                      </div>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {getAvailableDate.loading && (
          <div className="w-full h-[300px] flex justify-center items-center">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}

export default AvailablityCalendar;
