import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import ModalCommon from "../../components/common/ModalCommon";
import { merchantTourRoute } from "../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";

import CreatePackage from "./CreatePackage";
import ParentPackageSelectedAllData from "./ParentPackageSelectedAllData/ParentPackageSelectedAllData";
import ParentPackageSelectedAllDataDefault from "./ParentPackageSelectedAllData/ParentPackageSelectedAllDataDefault";

export function convertStringToObject(str) {
  const keyValuePairs = str.split("&");
  const result = {};

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    result[key] = isNaN(value) ? value : Number(value);
  });

  return result;
}

export default function ParentPackageSelected() {
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const navigate = useNavigate();
  const [packageSelectCondition, setPackageSelectCondition] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [marchantTourCreateDatas, setMarchantCreateDatas] = useState({
    number_of_travellers: 5,
    tour_package_food_options: [],
    tour_package_accommodation_options: [],
    tour_package_transfer_options: [],
    tour_package_transportation_packages: [],
    tour_package_guide_options: [],
    tour_package_type_id: convertData.packageId,
    spot_entries: [],
  });
  const [allFoodPackage, setAllFoodPackage] = useState({
    requestId: "create",
    tour_package_meal_packages: [],
    meals_provided_in_days: {},
  });
  const handlePackageAdd = () => {
    setPackageSelectCondition(true);
  };
  const [packageDescription, setPackageDescription] = useState("");
  const convertObject = (a) => {
    const b = {
      requestId: "add-package",

      subscribed_tour_id: convertData.subscribeId,
      tour_package: {
        tour_package_description: packageDescription,
        package_total_price: 0,
        tour_package_type_id: a.tour_package_type_id,
        tour_package_food_options: a.tour_package_food_options.map(
          (option) => ({
            day_number: option.day_number,
            tour_package_meal_packages: option.tour_package_meal_packages.map(
              (meal) => ({
                meal_type_id: meal.meal_type_id,
                food_items_ids: meal.food_items_ids,
                meal_package_unit_price: meal.meal_package_unit_price,
              })
            ),
            tour_package_option_is_active: option.tour_package_option_is_active,
            tour_package_option_is_default:
              option.tour_package_option_is_default,
          })
        ),
        tour_package_accommodation_options:
          a.tour_package_accommodation_options.map((accommodation) => ({
            tour_package_accommodations_package_list:
              accommodation.tour_package_accommodations_package_list.map(
                (accommodationsPackage) => ({
                  night_number: accommodationsPackage.night_number,
                  accommodation_id: accommodationsPackage.accommodation_id,
                  room_category_id: accommodationsPackage.room_category_id,
                  room_type_id: accommodationsPackage.room_type_id,
                  suitable_for_persons:
                    accommodationsPackage.suitable_for_persons,
                  is_shareable: accommodationsPackage.is_shareable,
                  number_of_beds: accommodationsPackage.number_of_beds,
                  bed_configuration: accommodationsPackage.bed_configuration,
                  per_night_room_price:
                    accommodationsPackage.per_night_room_price,
                })
              ),
            tour_package_option_is_active:
              accommodation.tour_package_option_is_active,
            tour_package_option_is_default:
              accommodation.tour_package_option_is_default,
          })),

        tour_package_guide_options: a.tour_package_guide_options.map(
          (guides) => ({
            tour_package_guide_packages: guides.tour_package_guide_packages.map(
              (guide) => ({
                tour_guide_price_per_day: Number(
                  guide.tour_guide_price_per_day
                ),
                number_of_guides_for_day: Number(
                  guide.number_of_guides_for_day
                ),
                day_number: Number(guide.day_number),
              })
            ),
          })
        ),

        tour_package_transfer_options: a.tour_package_transfer_options.map(
          (transfer) => ({
            tour_package_transfer_packages:
              transfer.tour_package_transfer_packages.map(
                (transferPackage) => ({
                  transfer_route: transferPackage.transfer_route,
                  tour_transfer_provider_id:
                    transferPackage.tour_transfer_provider_id,
                  tour_transfer_mode_id: transferPackage.tour_transfer_mode_id,
                  trip_type: transferPackage.trip_type,
                  is_ac: transferPackage.is_ac,
                  suitable_for_persons: transferPackage.suitable_for_persons,
                  per_vehicle_per_trip_price:
                    transferPackage.per_vehicle_per_trip_price,
                })
              ),
            tour_package_option_is_active:
              transfer.tour_package_option_is_active,
            tour_package_option_is_default:
              transfer.tour_package_option_is_default,
          })
        ),
        tour_package_transportation_packages:
          a.tour_package_transportation_packages.map((transport) => ({
            is_ac: transport.is_ac,
            transportation_brand_id: transport.transportation_brand_id,
            transportation_mode_id: transport.transportation_mode_id,
            transportation_package_is_default:
              transport.transportation_package_is_default,
            transportation_package_per_unit_price:
              transport.transportation_package_per_unit_price,
            transportation_provider_id: transport.transportation_provider_id,
            transportation_route_id: transport.transportation_route_id,
            trip_type: transport.trip_type,
          })),
        spot_entries: a.spot_entries.map((spot) => ({
          activity_id: spot.activity_id,

          remark: spot.remark,

          spot_entry_price_per_person: Number(spot.spot_entry_price_per_person),
        })),
      },
    };
    return b;
  };

  const receivedDatas = {
    tour_package_component_combinations: [
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 8514.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 12343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 420,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                1: "Vhat",
                3: "Fish fry",
              },
              per_meal_package_price: 34,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                1: "Vhat",
                2: "Dal",
                3: "Fish fry",
              },
              per_meal_package_price: 54,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Dinner - Package#0",
              meal_type_id: 3,
              meal_type_name: "Dinner",
              food_items: {
                4: "Mutton Curry",
                5: "Beff Vhuna",
                8: "Bhuna Khichuri ",
              },
              per_meal_package_price: 332,
            },
          ],
          tour_package_meal_option_day: 1,
          number_of_meals: 3,
          number_of_breakfast: 1,
          number_of_lunch: 1,
          number_of_dinner: 1,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 662.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "nilachol",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: true,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 1325,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: false,
          transportation_package_unit_price: 543,
        },
        tour_package_total_option_price_per_person: 10140,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 8514.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 12343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 420,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                1: "Vhat",
                3: "Fish fry",
              },
              per_meal_package_price: 34,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                1: "Vhat",
                2: "Dal",
                3: "Fish fry",
              },
              per_meal_package_price: 54,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Dinner - Package#0",
              meal_type_id: 3,
              meal_type_name: "Dinner",
              food_items: {
                4: "Mutton Curry",
                5: "Beff Vhuna",
                8: "Bhuna Khichuri ",
              },
              per_meal_package_price: 332,
            },
          ],
          tour_package_meal_option_day: 2,
          number_of_meals: 3,
          number_of_breakfast: 1,
          number_of_lunch: 1,
          number_of_dinner: 1,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 662.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "nilachol",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: true,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 1325,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: false,
          transportation_package_unit_price: 543,
        },
        tour_package_total_option_price_per_person: 10140,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 8514.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 12343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 420,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                1: "Vhat",
                3: "Fish fry",
              },
              per_meal_package_price: 34,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                1: "Vhat",
                2: "Dal",
                3: "Fish fry",
              },
              per_meal_package_price: 54,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Dinner - Package#0",
              meal_type_id: 3,
              meal_type_name: "Dinner",
              food_items: {
                4: "Mutton Curry",
                5: "Beff Vhuna",
                8: "Bhuna Khichuri ",
              },
              per_meal_package_price: 332,
            },
          ],
          tour_package_meal_option_day: 3,
          number_of_meals: 3,
          number_of_breakfast: 1,
          number_of_lunch: 1,
          number_of_dinner: 1,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 662.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "nilachol",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: true,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 1325,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: false,
          transportation_package_unit_price: 543,
        },
        tour_package_total_option_price_per_person: 10140,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 8514.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 12343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 2343,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 88,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                1: "Vhat",
                3: "Fish fry",
              },
              per_meal_package_price: 34,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                1: "Vhat",
                2: "Dal",
                3: "Fish fry",
              },
              per_meal_package_price: 54,
            },
          ],
          tour_package_meal_option_day: 4,
          number_of_meals: 2,
          number_of_breakfast: 1,
          number_of_lunch: 1,
          number_of_dinner: 0,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 662.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "nilachol",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: true,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 1325,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: false,
          transportation_package_unit_price: 543,
        },
        tour_package_total_option_price_per_person: 9808,
      },
    ],
    tour_package_default_component_combination: {
      tour_package_default_accommodation_option: {
        tour_package_option_is_active: true,
        tour_package_option_is_default: true,
        tour_package_option_price_per_person: 8400,
        tour_package_accommodation_packages: [
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 4500,
            night_number: null,
          },
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 2450,
            night_number: null,
          },
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 1450,
            night_number: null,
          },
        ],
      },
      tour_package_default_food_option: {
        tour_package_option_is_active: true,
        tour_package_option_is_default: true,
        tour_package_option_price_per_person: 550,
        meal_packages: [
          {
            meal_package_id: null,
            meal_package_name: "null - Breakfast - Package#0",
            meal_type_id: 1,
            meal_type_name: "Breakfast",
            food_items: {
              1: "Vhat",
              3: "Fish fry",
            },
            per_meal_package_price: 120,
          },
          {
            meal_package_id: null,
            meal_package_name: "null - Lunch - Package#0",
            meal_type_id: 2,
            meal_type_name: "Lunch",
            food_items: {
              1: "Vhat",
              2: "Dal",
              3: "Fish fry",
            },
            per_meal_package_price: 220,
          },
          {
            meal_package_id: null,
            meal_package_name: "null - Dinner - Package#0",
            meal_type_id: 3,
            meal_type_name: "Dinner",
            food_items: {
              4: "Mutton Curry",
              5: "Beff Vhuna",
              8: "Bhuna Khichuri ",
            },
            per_meal_package_price: 210,
          },
        ],
        tour_package_meal_option_day: 1,
        number_of_meals: 3,
        number_of_breakfast: 1,
        number_of_lunch: 1,
        number_of_dinner: 1,
      },
      tour_package_default_transfer_option: {
        tour_package_option_is_active: null,
        tour_package_option_is_default: true,
        tour_package_option_price_per_person: 1220,
        tour_package_id: null,
        transfer_option_id: null,
        transfer_packages: [
          {
            tour_package_transfer_package_id: null,
            tour_package_transfer_route: "nilachol",
            tour_package_transportation_mode_id: 1,
            tour_package_transportation_mode_name: "Bus",
            tour_package_transportation_provider_id: 1,
            tour_package_transportation_provider_name: "Desh Travel",
            is_ac: true,
            trip_type: "ONE_WAY",
            per_vehicle_per_trip_price: 1220,
            suitable_for_persons: 3,
          },
        ],
      },
      tour_package_default_transportation_package: {
        tour_package_transportation_package_id: null,
        tour_package_transportation_route_id: 1,
        tour_package_transportation_route_name: "Rangamati - Dhaka",
        tour_package_transportation_mode_id: 1,
        tour_package_transportation_mode_name: "Bus",
        tour_package_transportation_brand_id: 2,
        tour_package_transportation_brand_name: "Scanea",
        tour_package_transportation_provider_id: 1,
        tour_package_transportation_provider_name: "Desh Travel",
        trip_type: "One Way",
        is_ac: false,
        transportation_package_unit_price: 820,
      },
      tour_package_default_option_price_per_person: 10990,
    },
  };

  const dispatch = useDispatch();
  // response.dispatch({
  //   type: "RECEIVE_DATA",
  //   payload: receivedData,
  // });

  // const handleDispatch = () => {
  //   dispatch(
  //     combinationPackageStore({
  //       type: "RECEIVE_DATA",
  //       payload: receivedData,
  //     })
  //   );
  // };

  const receivedData = useSelector(
    (state) => state.combinationPackageStore?.data
  );

  const payloadToCreate = useSelector(
    (state) => state.tourPackageCreatePayloadReducers?.data
  );

  console.log("payloadToCreate", payloadToCreate);
  const handleCreatePackageAdd = () => {
    // const payload = convertObject();
    const payloadToCreatePackage = convertObject(payloadToCreate);
    setOpenModal(false);
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.MARCHANT_CREATE_PACKAG}`,
        payloadToCreatePackage
      )
      .then((res) => {
        navigate(
          `${merchantTourRoute.TOURONE}tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}`
        );
        console.log(res);
        if (res.status === 201) {
          navigate(`${merchantTourRoute.SUBSCRIBE_TOUR}`);
          toast.success("Created successfully!");
        } else {
          toast.warn("Something went wrong!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handlePackageDetailsAdd = () => {
    setOpenModal(!openModal);
  };
  const handlePackageDetailsEdit = () => {
    setPackageSelectCondition(true);
  };
  return (
    <div className="">
      {packageSelectCondition ? (
        <CreatePackage
          setPackageSelectCondition={setPackageSelectCondition}
          setMarchantCreateDatas={setMarchantCreateDatas}
          marchantTourCreateDatas={marchantTourCreateDatas}
          allFoodPackage={allFoodPackage}
          setAllFoodPackage={setAllFoodPackage}
        />
      ) : (
        <>
          <div className="text-center">
            <h2 className="text-center text-[22px]">{convertData?.tourName}</h2>
            <p className="text-[16px]"> {convertData?.packageName}</p>
          </div>
          {/* New Style Start */}
          {receivedData?.tour_package_default_component_combination
            ?.tour_package_default_option_price_per_person > 0 && (
            <div className="mb-[20px]">
              {receivedData?.tour_package_default_component_combination && (
                <ParentPackageSelectedAllDataDefault
                  tour_package_default_component_combination={
                    receivedData?.tour_package_default_component_combination
                  }
                ></ParentPackageSelectedAllDataDefault>
              )}
            </div>
          )}

          <div className="m">
            {receivedData?.tour_package_component_combinations?.map(
              (tour_package_component_combinations, index) => (
                <ParentPackageSelectedAllData
                  key={index}
                  tour_package_component_combinations={
                    tour_package_component_combinations
                  }
                  index={index}
                ></ParentPackageSelectedAllData>
              )
            )}
          </div>
          {/* New Style End */}

          <div>
            {receivedData?.tour_package_component_combinations?.length < 0 ||
              (receivedData && (
                <div className="flex justify-center mx-auto mt-[30px]">
                  <button
                    onClick={() => handlePackageAdd()}
                    className="w-[350px]  h-[49px] bg-btn-secondary text-bg-white rounded-[4px]  mt-[30px] "
                  >
                    {receivedData?.tour_package_component_combinations?.length >
                    0
                      ? "Add or Modify"
                      : "Add New Option"}
                  </button>
                </div>
              ))}

            {receivedData?.tour_package_component_combinations?.length > 0 && (
              <div className="flex justify-end items-end">
                <button
                  onClick={() => handlePackageDetailsAdd()}
                  className="w-[250px]  h-[49px] bg-btn-secondary text-bg-white rounded-[4px]  mt-[30px] "
                >
                  Create Package
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <ModalCommon
        shown={openModal}
        width="max-w-[500px]"
        height="min-h-[300px]"
        outSideOff={false}
        close={() => {
          setOpenModal(false);
        }}
      >
        <div className="p-[30px]">
          <p>Add Tour Description:</p>
          <div className="w-full">
            <label className="custom-field one w-full">
              <textarea
                onChange={(e) => setPackageDescription(e.target.value)}
                className="input w-full min-h-[150px] max-h-[200px]"
                type="text"
                placeholder=" "
                value={packageDescription}
              ></textarea>
              <span className="placeholder">Description :</span>
            </label>
          </div>
          <button
            onClick={() => handleCreatePackageAdd()}
            className="bg-btn-secondary w-full h-[39px] rounded-[4px] text-bg-white"
          >
            Submit Package
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
