import React, { useEffect, useState } from "react";
import ModalCommon from "../../../components/common/ModalCommon";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";
import "./LiveHotel.css";

export default function LiveHotelToll() {
  const [incompleteData, setIncompleteData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [isLiveHotel, setIsLiveHotel] = useState(false);
  const [checked, setChecked] = useState(false);
  console.log("incompleteData", incompleteData);
  const dataFetch = () => {
    authAxios
      .get(
        `${apiEndPoint.ADMIN_GET_INCOMPLETE_ALL_HOTEL}pageNumber=${currentPage}&pageSize=10&&propertyNameKeyword=${searchTerm}&requestId=getIncompleteData`
      )
      .then((res) => {
        setIncompleteData(res.data);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    dataFetch();
  }, [currentPage, searchTerm]);

  const checkData = (data) => {
    let count = 0;
    for (const [key, value] of Object.entries(data)) {
      console.log(`${key}: ${value} check`);
      if (typeof value == "boolean") {
        if (value === true) {
          count++;
        }
      } else {
        if (Number(value) > 0) {
          count++;
        }
      }
    }

    if (count >= 7) {
      setChecked(true);
    }
  };

  // const handleSureModal = (data) => {
  //   setSelectedData(data);
  //   checkData(data);
  //   setOpen(true);
  // };

  const handleLive = (id) => {
    console.log("id", id);
    // checkData(data);
    if (id !== undefined) {
      authAxios
        .put(`${apiEndPoint.ADMIN_PUT_LIVE_HOTEL}${id}?requestId=123`)
        .then((data) => {
          if (data?.data?.success === true) {
            setLoading(true);
            setIsLiveHotel(true);
            dataFetch();
            setOpen(true);
            // toast.success("successfully Live!");
          } else {
            setIsLiveHotel(false);
            setOpen(true);
            // toast.warn("Please fill the red mark field then try again!!");
          }
        });
    }
  };
  console.log("checked", checked);
  return (
    <div className="mt-[25px] mb-[50px] max-w-[1100px] 2xl:max-w-[1440px] mx-auto">
      <div className="text-[22px] text-center font-bold">Hotel status </div>
      <div className="mb-[20px] w-[300px]">
        <label className="relative block">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </span>
          <input
            className="placeholder:italic placeholder:text-txt-hint block bg-white w-full border border-txt-hint rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-btn-secondary focus:ring-btn-secondary focus:ring-1 sm:text-sm"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search property name..."
            type="text"
            name="search"
          />
        </label>
      </div>

      {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <table className="fl-table">
          <thead className="sticky top-[88px]">
            <tr>
              <th>
                <span>Property</span>
              </th>
              <th>Thumb Image</th>
              <th>Property Speciality</th>
              <th>Display Facility</th>
              <th>Lat Lon</th>
              <th>Search Suggestion</th>
              <th>Category Price set </th>
              <th>Accepted Categories</th>
              <th>Without Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {incompleteData?.entries?.map((hotelData, index) => (
              <tr key={index}>
                <td>
                  <span className="flex flex-col justify-start items-start">
                    <span className="text-[10px]">#{hotelData?.id}</span>
                    <span className="whitespace-pre-line ">
                      {hotelData?.propertyName}
                    </span>
                  </span>
                </td>
                <td
                  className={`${
                    hotelData?.hasThumbImage === false
                      ? " text-[#E74C3C]"
                      : " text-[#239B56]"
                  }`}
                >
                  {hotelData?.hasThumbImage === false ? "False" : "True"}
                </td>
                <td
                  className={`${
                    hotelData?.noOfPropertySpeciality > 0
                      ? " text-[#239B56]"
                      : " text-[#E74C3C] "
                  }`}
                >
                  {hotelData?.noOfPropertySpeciality}
                </td>
                <td
                  className={`${
                    hotelData?.countDisplayFacility > 0
                      ? " text-[#239B56]"
                      : " text-[#E74C3C] "
                  }`}
                >
                  {" "}
                  {hotelData?.countDisplayFacility}
                </td>
                <td
                  className={`${
                    hotelData?.withLatLon === false
                      ? " text-[#E74C3C]"
                      : " text-[#239B56]"
                  }`}
                >
                  {hotelData?.withLatLon === false ? "False" : "True"}{" "}
                </td>
                <td
                  className={`${
                    hotelData?.inSearchSuggestion === false
                      ? " text-[#E74C3C]"
                      : " text-[#239B56]"
                  }`}
                >
                  {" "}
                  {hotelData?.inSearchSuggestion === false ? "False" : "True"}
                </td>
                <td
                  className={`${
                    hotelData?.noOfRoomCategoriesWithDailyPriceSet > 0
                      ? " text-[#239B56]"
                      : " text-[#E74C3C] "
                  }`}
                >
                  {hotelData?.noOfRoomCategoriesWithDailyPriceSet}
                </td>
                <td
                  className={`${
                    hotelData?.acceptedCategories > 0
                      ? " text-[#239B56]"
                      : " text-[#E74C3C] "
                  }`}
                >
                  {hotelData?.acceptedCategories}
                </td>
                <td
                  className={`${
                    hotelData?.categoriesWithoutImage === 0
                      ? " text-[#239B56]"
                      : " text-[#E74C3C] "
                  }`}
                >
                  {hotelData?.categoriesWithoutImage}
                </td>
                <td className={``}>
                  {hotelData?.status === "READY_TO_LIVE" ? (
                    <span
                      onClick={() => handleLive(hotelData?.id)}
                      className="text-[#239B56] "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-[50px] h-[50px] cursor-pointer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span
                      onClick={() => handleLive(hotelData?.id)}
                      className="text-[#F8C471] "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-[50px] h-[50px] cursor-pointer"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {open && (
        <ModalCommon
          shown={open}
          width="max-w-[410px]"
          outSideOff={false}
          height="min-h-[312px]"
          close={() => {
            setOpen(false);
            setChecked(false);
            setSelectedData();
          }}
        >
          <div className="flex justify-center items-center  flex-col px-[30px]">
            <div className="w-[230px] h-[190px] mt-[30px]">
              {isLiveHotel === true ? (
                <img
                  className="w-full h-full"
                  src="../succcess.gif"
                  loading="lazy"
                  alt="aa"
                />
              ) : (
                <img
                  className="w-full h-full"
                  src="../sure.gif"
                  loading="lazy"
                  alt="aa"
                />
              )}
            </div>
            {isLiveHotel === true ? (
              // <button
              //   // onClick={() => handleLive(selectedData?.id)}
              //   className="w-full mt-[20px] bg-btn-secondary h-[49px] rounded-[8px] text-bg-primary cursor-pointer"
              // >
              //   Already Live
              // </button>
              <p className="text-[22px] text-center text-btn-primary mt-[20px]">
                Already Live
              </p>
            ) : (
              <p className="text-[16px] text-center text-[#EC7063] mt-[20px]">
                Complete red-marked fields, then launch your hotel for live.
              </p>
            )}
          </div>
        </ModalCommon>
      )}
      <div className="">
        <Paginations
          currentPage={currentPage}
          totalCount={Math.ceil(incompleteData?.numberOfEntries / 10)}
          pageSize={10}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}
