import React, { useState } from "react";
import {
  animatedComponents,
  colorStyles,
} from "../../../ToursMarchent/Utilities/SelectAnimation";
import Select from "react-select";

const AdminSetRatingsSelect = ({ ratingSet, setRatingSet }) => {
  const [rating, setRating] = useState();

  const number_of_rating = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  return (
    <Select
      value={rating}
      onChange={(data) => {
        setRating(data);
        setRatingSet(data);
      }}
      components={animatedComponents}
      options={number_of_rating}
      placeholder={"Select: "}
      styles={colorStyles}
    />
  );
};

export default AdminSetRatingsSelect;
