import React from 'react'

const ParentPackageTransferCard = ({transfer}) => {
    const {tour_package_transfer_route, tour_package_transportation_mode_name, tour_package_transportation_provider_name, suitable_for_persons, per_vehicle_per_trip_price
    } = transfer;


  return (
    <div className="bg-bg-white py-[8px] px-[8px] shadow-customShadow rounded-[2px]">
      <div className="mb-[4px]">
        <p className="text-[13px] text-center bg-[#e2e8f0] text-btn-secondary">
          {tour_package_transfer_route}
        </p>
        </div>
        <div className="grid gap-y-[4px] p-[4px] ring-[1px] rounded-[2px]">
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Mode: </span>
          {tour_package_transportation_mode_name}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Provider: </span>
          {tour_package_transportation_provider_name}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Suitable for: </span>
          {suitable_for_persons} persons
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Ac: </span>
          {"No"}
        </p>
        <p className=" text-[12px]">
            <span className="text-bg-semiblack">Per Trip Price: </span>
          {per_vehicle_per_trip_price
} ৳
        </p>
        </div>
    </div>
  )
}

export default ParentPackageTransferCard