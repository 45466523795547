import { addDays, format } from "date-fns";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router";
import EditPriceModal from "../../components/modal/EditPriceModal";
import { DashboardRoutes, SummeryStatus } from "../../contants/Constants";
import AvailablityCalendar from "../../pages/AvailablityCalendar";
import { RoundThreeDot, SvgCross } from "../common/AllSVGImport";

function DashboardHotelCard({ hotel, hotelList }) {
  let navigate = useNavigate();
  const [menu, setMenu] = React.useState(false);
  const [overlay, setOverlay] = React.useState(false);
  const [calenderOverlay, setCalenderOverlay] = React.useState(false);

  useEffect(() => {
    if (calenderOverlay === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [calenderOverlay]);

  return (
    <div>
      <div className="relative ">
        <div className="">
          <img
            className={`rounded-[16px] h-[312px] w-full object-cover ${
              hotel.status === SummeryStatus.DRAFT
                ? " opacity-95 brightness-50 contrast-75 grayscale"
                : ""
            }`}
            src={
              hotel.images && hotel.images.length > 0
                ? hotel.images[0].url.split("?")[0]
                : "/hotelBg.jpg"
            }
            alt=""
          />
        </div>
        <div className="absolute top-0 w-full h-[312px] flex flex-col  rounded-xl ">
          <div className="flex justify-end">
            {menu ? (
              <>
                <div className="bg-bg-primary text-txt-primary text-sm h-[312px] w-2/3 absolute rounded-r-[16px] z-20">
                  <div className="flex flex-col h-full">
                    <div
                      onClick={() => setMenu(!menu)}
                      className="w-full flex justify-end p-4 cursor-pointer"
                    >
                      <SvgCross />
                    </div>

                    <div
                      onClick={() => {
                        setOverlay(true);
                        setMenu(false);
                      }}
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Edit Price</p>
                    </div>
                    <div
                      onClick={() => {
                        setCalenderOverlay(true);
                        setMenu(false);
                      }}
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Availablity</p>
                    </div>
                    <div
                      onClick={() => navigate(DashboardRoutes.POLICIES)}
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Edit Policies </p>
                    </div>
                    <div
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_FRONT_END}details?hotel=${
                            hotel.propertyName
                          }&location=dhaka&start=${format(
                            addDays(new Date(), 1),
                            "dd MMM, Y"
                          )}&end=${format(
                            addDays(new Date(), 3),
                            "dd MMM, Y"
                          )}&adult=2&child=0&propertyId=${hotel.id}`,
                          "_blank"
                        );
                      }}
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Preview </p>
                    </div>
                    {/* <div onClick={() => { setOverlay(true); setMenu(false) }} className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2">
                      <p>
                        Price
                      </p>
                    </div> */}
                    <div
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                      onClick={() =>
                        navigate(DashboardRoutes.HOTEL_DETAILS + hotel.id)
                      }
                    >
                      <p>Edit Hotel </p>
                    </div>
                    <div
                      onClick={() => navigate(DashboardRoutes.CATEGORIES)}
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Edit Categories </p>
                    </div>
                    {/* <div
                      onClick={() =>
                        navigate(
                          DashboardRoutes.MARCHENT_FETCH_SINGLE_HOTEL_TNC +
                            hotel.id
                        )
                      }
                      className="hover:text-txt-secondary hover:bg-btn-secondary cursor-pointer pl-8 pr-4 py-2"
                    >
                      <p>Add TNC </p>
                    </div> */}
                  </div>

                  {/* <div className="flex_column">
                    <div
                      className={overlay ? "overlay_shown" : "overlay_hidden"}
                    >

                    </div>
                  </div> */}
                </div>
              </>
            ) : null}
            <>
              <div onClick={() => setMenu(!menu)} className="py-1 px-4">
                <button className="cursor-pointer w-[51px] h-[51px] flex items-center justify-center">
                  <RoundThreeDot />
                </button>
              </div>
            </>
          </div>
          {hotel.status === SummeryStatus.DRAFT && (
            <h1 className="text-txt-hint text-[40px] h-[40px] text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              DRAFT
            </h1>
          )}
          <div className=" h-[150px] rounded-br-xl rounded-bl-xl mt-auto">
            <div className="flex justify-between items-center px-4 pt-4">
              <div className="flex items-center">
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 2.1875C14.3103 2.19126 11.2523 3.46005 8.99679 5.71553C6.74131 7.97101 5.47253 11.029 5.46876 14.2188C5.46494 16.8254 6.3164 19.3613 7.89251 21.4375C7.89251 21.4375 8.22064 21.8695 8.27423 21.9319L17.5 32.8125L26.7302 21.9264C26.7783 21.8684 27.1075 21.4375 27.1075 21.4375L27.1086 21.4342C28.6839 19.359 29.535 16.8242 29.5313 14.2188C29.5275 11.029 28.2587 7.97101 26.0032 5.71553C23.7478 3.46005 20.6897 2.19126 17.5 2.1875ZM17.5 18.5938C16.6347 18.5938 15.7889 18.3372 15.0694 17.8564C14.3499 17.3757 13.7892 16.6924 13.458 15.893C13.1269 15.0936 13.0403 14.2139 13.2091 13.3652C13.3779 12.5166 13.7946 11.737 14.4064 11.1252C15.0183 10.5133 15.7978 10.0966 16.6465 9.92781C17.4952 9.759 18.3748 9.84564 19.1743 10.1768C19.9737 10.5079 20.657 11.0687 21.1377 11.7881C21.6184 12.5076 21.875 13.3535 21.875 14.2188C21.8736 15.3786 21.4122 16.4906 20.592 17.3107C19.7718 18.1309 18.6599 18.5923 17.5 18.5938Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </span>
                <div className="ml-[10px]">
                  <h3 className="text-[18px]  leading-4 text-txt-secondary">
                    {hotel.propertyName}
                  </h3>
                  <p className="text-[10px] leading-4 text-txt-secondary">
                    {hotel.address?.street} {hotel.address?.place}-
                    {hotel.address?.zip}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center px-2">
                <button className="bg-bg-semiwhite w-[30px] h-[30px] flex items-center justify-center rounded-full">
                  <p className="text-[10px] leading-medium text-txt-secondary">
                    N/A
                  </p>
                </button>
                <p className="text-[10.17px] leading-small text-txt-secondary mt-1">
                  0 reviews
                </p>
              </div>
            </div>
            <hr className="bg-bg-white text-txt-secondary mt-[15px] mb-[22px]" />
            <div className="flex flex-col">
              <div className="px-2 flex items-center justify-start">
                <p className="text-[10.17px] px-2 leading-small text-txt-secondary">
                  {hotel.numberOfRooms || 0} Bedrooms
                </p>
                <div className="">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </div>
                <p className="text-[10.17px] px-2 leading-small text-txt-secondary">
                  Up to 6 Guests
                </p>
                <div className="">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.48781 7C5.07317 7 4.71951 6.85714 4.42683 6.57143C4.14228 6.27755 4 5.92245 4 5.50612C4 5.08163 4.14228 4.72653 4.42683 4.44082C4.71951 4.14694 5.07317 4 5.48781 4C5.91057 4 6.26829 4.14694 6.56098 4.44082C6.85366 4.72653 7 5.08163 7 5.50612C7 5.92245 6.85366 6.27755 6.56098 6.57143C6.26829 6.85714 5.91057 7 5.48781 7Z"
                      fill="#FFFBEF"
                    />
                  </svg>
                </div>
                <p className="text-[10.17px] px-2 leading-small text-txt-secondary">
                  {/* {hotel.facilities["families"][0].label} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {overlay && (
        <EditPriceModal
          openDefault={overlay}
          hotelList={hotelList}
          setOpenDefault={setOverlay}
          hotelToShow={hotel}
        />
      )}
      {calenderOverlay && (
        <ReactModal
          isOpen={calenderOverlay}
          onRequestClose={() => {
            setCalenderOverlay(false);
          }}
          ariaHideApp={true}
        >
          <AvailablityCalendar
            hotelShow={hotel}
            setCalenderOverlay={setCalenderOverlay}
          />
        </ReactModal>
      )}
    </div>
  );
}

export default DashboardHotelCard;
