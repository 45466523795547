import React, { useEffect, useState } from "react";
import Select from "react-select";
import { numberInputOnWheelPreventChange } from "../../../excursionMarchant/utils/common";
import {
  animatedComponents,
  colorStylesTour,
  transformArray,
} from "../../Utilities/SelectAnimation";
import Calender from "../../../components/Calender";
import { authAxios } from "../../../utils/axiosWrapper";
import { MERCHANT_TOUR_ENDPOINT } from "../../../contants/Endpoints";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

const TourSchedule = ({
  allTourSchedulePackage,
  setAllTourSchedulePackage,
  itemListNew,
  setRequestToPayload,
  handleToggleClick,
  setTourSchedulePackage,
  tourSchedulePackage,
}) => {
  // const [startDate, setStartDate] = useState();
  const [packageList, setPackageList] = useState({
    date: "",
    packageType: {},
    totalSeat: "",
    bookableSeat: "",
    startDate: "",
    endDate: "",
  });
  const [packageType, setPackageType] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.pathname);
  const number_days = parseInt(queryParams.get("number_of_days"));

  const getPackageType = () => {
    authAxios
      .get(`${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_PACKAGE_TYPE}`)
      .then((res) => {
        setPackageType(res?.data?.tour_package_types);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getPackageType();
  }, []);

  const packageArray = transformArray(
    packageType,
    "tour_package_type_id",
    "tour_package_type_name"
  );

  const iconObject = {
    date: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
      >
        <path
          d="M12.7474 3.33594H4.2474C2.68259 3.33594 1.41406 4.60446 1.41406 6.16927V13.2526C1.41406 14.8174 2.68259 16.0859 4.2474 16.0859H12.7474C14.3122 16.0859 15.5807 14.8174 15.5807 13.2526V6.16927C15.5807 4.60446 14.3122 3.33594 12.7474 3.33594Z"
          stroke="#879FA8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.66406 1.91797V4.7513"
          stroke="#879FA8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3281 1.91797V4.7513"
          stroke="#879FA8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.41406 7.58594H15.5807"
          stroke="#879FA8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  };


  const handleSubmitFirstSchedule = () => {
    if (
      tourSchedulePackage?.initialTotalSeat?.length === 0 &&
      tourSchedulePackage?.initialBookableSeat?.length === 0 &&
      tourSchedulePackage?.dateSchedule?.length === 0 &&
      Object.keys(tourSchedulePackage?.packageType)?.length === 0
    ) {
      setTourSchedulePackage({
        packageType: packageList?.packageType,
        initialTotalSeat: packageList?.totalSeat,
        initialBookableSeat: packageList?.bookableSeat,
        firstTimeAdded: true,
        tourDateIndex: -1,
        dateSchedule: [
          {
            date: packageList?.date,
            start_date: dateTransform(packageList?.date),
            end_date: dateTransform(addDays(packageList?.date, number_days)),
            total_seat: packageList?.totalSeat,
            bookable_seat: packageList?.bookableSeat,
          },
        ],
      });
    }
  };

  const handleSubmitAddMoreSchedule = ()=> {
    const isDateAlreadyExists = tourSchedulePackage.dateSchedule.some(
      (item) => new Date(item.date).getTime() === new Date(packageList.date).getTime()
    );
    
    if(isDateAlreadyExists){
      toast.warn("Date is already exist. Please select another date.")
    }
    if (!isDateAlreadyExists) {
      setTourSchedulePackage(prevState => ({
        ...prevState,
        dateSchedule: [...prevState.dateSchedule, {
          date: packageList?.date,
          start_date: dateTransform(packageList?.date),
          end_date: dateTransform(addDays(packageList?.date, number_days)),
          total_seat: packageList?.totalSeat,
          bookable_seat: packageList?.bookableSeat,
        },]
      }));
    }
   
  }

  const dateTransform = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const inputVal = `${year}-${month}-${day}`;

    const currentDate = new Date(inputVal);
    const currentDateStr = currentDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
    return currentDateStr;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const handleOnUpdateSchedule = () => {
    setTourSchedulePackage((prev) => {
      const updatedState = { ...prev };
      updatedState.dateSchedule[tourSchedulePackage?.tourDateIndex] = {
        date: packageList?.date,
            start_date: dateTransform(packageList?.date),
            end_date: dateTransform(addDays(packageList?.date, number_days)),
            total_seat: packageList?.totalSeat,
            bookable_seat: packageList?.bookableSeat,
      };
    
      toast.success("Updated successfully.")
      return updatedState;
    })
  }


  useEffect(()=> {
    setPackageList((prev)=> ({
      ...prev,
      totalSeat: tourSchedulePackage?.initialTotalSeat,
      bookableSeat: tourSchedulePackage?.initialBookableSeat
    }))
  },[tourSchedulePackage?.initialTotalSeat, tourSchedulePackage?.initialBookableSeat])

  return (
    <div className="flex pl-[16px] py-[16px]">
      <div className="w-full flex flex-col gap-[8px]">
        {Object.keys(tourSchedulePackage?.packageType).length === 0 && <div className="flex flex-col gap-[4px]">
          <div>
            <p className="text-tour-secondary text-[12px] font-normal">
              Package type:{" "}
            </p>
          </div>
          <div className="">
            <Select
              value={allTourSchedulePackage?.packageTypes || ""}
              onChange={(data) => {
                setAllTourSchedulePackage((prev) => ({
                  ...prev,
                  packageTypes: data,
                }));

                setPackageList((prev) => ({
                  ...prev,
                  packageType: data,
                }));
              }}
              components={animatedComponents}
              options={packageArray}
              placeholder={"Select Package type: "}
              styles={colorStylesTour}
            />
          </div>
        </div> }
        <div className="flex flex-col gap-[4px]">
          <div>
            <p className="text-tour-secondary text-[12px] font-normal">
              Dates:{" "}
            </p>
          </div>
          <div className="relative">
            {/* <input
              className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input outline-none"
              placeholder="Select Dates"
              type="date"
              onChange={handleDateChange}
              // onChange={(e) => {
              //   const inputVal = e.target.value;
              //   if (!selectedDates.includes(inputVal)) {
              //     setSelectedDates((prevDates) => [...prevDates, inputVal]);
              //   }
              // }}
              onWheel={numberInputOnWheelPreventChange}
              value={startDate || ""}
            /> */}

            <DatePicker
              className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
              selected={tourSchedulePackage?.tourDateIndex >= 0 ? tourSchedulePackage?.dateSchedule[tourSchedulePackage?.tourDateIndex]?.date : packageList?.date || ""}
              onChange={(date) => {
                // handleDateChange(date);
                setPackageList((prev) => ({
                  ...prev,
                  date: date,
                }));
              }}
              placeholder={"Select Dates"}
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[14px] text-tour-secondary">
              {iconObject?.date}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-[4px]">
          <div>
            <p className="text-tour-secondary text-[12px] font-normal">
              Total Seat:{" "}
            </p>
          </div>
          <div className="">
            <input
              className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
              placeholder="Set Total Seats Number"
              type="number"
              onChange={(e) => {
                const inputVal = e.target.value;
                if (!isNaN(inputVal)) {
                  setAllTourSchedulePackage((prev) => ({
                    ...prev,
                    totalSeat: inputVal,
                  }));
                }

                setPackageList((prev) => ({
                  ...prev,
                  totalSeat: e.target.value,
                }));
              }}
              onWheel={numberInputOnWheelPreventChange}
              // value={ tourSchedulePackage?.tourDateIndex >= 0 ? tourSchedulePackage?.dateSchedule[tourSchedulePackage?.tourDateIndex]?.total_seat : allTourSchedulePackage?.totalSeat}
              value={packageList?.totalSeat}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[4px]">
          <div>
            <p className="text-tour-secondary text-[12px] font-normal">
              Bookable Seat:{" "}
            </p>
          </div>
          <div className="">
            <input
              className="w-full py-[8px] px-[10px] text-[12px] rounded-[8px] border-[1px] border-tour-input hover:border-btn-secondary placeholder:text-tour-input"
              placeholder="Set Bookable Seats Number"
              type="number"
              onChange={(e) => {
                const inputVal = e.target.value;
                if (!isNaN(inputVal)) {
                  setAllTourSchedulePackage((prev) => ({
                    ...prev,
                    bookableSeat: inputVal,
                  }));
                }
                setPackageList((prev) => ({
                  ...prev,
                  bookableSeat: e.target.value,
                }));
              }}
              onWheel={numberInputOnWheelPreventChange}
              // value={tourSchedulePackage?.tourDateIndex >= 0 ? tourSchedulePackage?.dateSchedule[tourSchedulePackage?.tourDateIndex]?.bookable_seat : allTourSchedulePackage?.bookable_seats|| ""}
              value={packageList?.bookableSeat}
            />
          </div>
        </div>

        {/* <button
              onClick={() => handleSubmitGuide()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button> */}
        {/* <button
          // disabled={
          //   foodPackage?.food_items_ids?.length < 1 &&
          //   foodPackage?.meal_package_unit_price !== Object &&
          //   foodPackage?.meal_type_id !== Object
          // }
          onClick={() => handleSubmitTourSchedule()}
          className="w-full mt-[24px] flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
        >
          Add
        </button> */}
        <div className="w-full flex gap-[16px] pt-[16px]">
          <button
            disabled={tourSchedulePackage?.dateSchedule?.length === 0}
            onClick={() => handleToggleClick(1)}
            className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-primary bg-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px]
              hover:bg-tour-primary hover:text-bg-white transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
          >
            Save & Next
          </button>
          { (tourSchedulePackage?.firstTimeAdded === true  && tourSchedulePackage?.tourDateIndex === -1)? <button
          disabled={
            packageList?.date?.length === 0 || packageList?.totalSeat?.length === 0 || packageList?.bookableSeat?.length === 0
          }
          onClick={() => {
            // handleSubmitTourSchedule();
            handleSubmitAddMoreSchedule();
          }}
          className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
            hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
        >
          Add more
        </button>  : 
        tourSchedulePackage?.tourDateIndex >= 0 ? 
        <button
        disabled={
          packageList?.date?.length === 0 || packageList?.totalSeat?.length === 0 || packageList?.bookableSeat?.length === 0
        }
          onClick={() => {
            // handleSubmitTourSchedule();
            // handleSubmitFirstSchedule();
            handleOnUpdateSchedule()
          }}
          className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
            hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
        >
          Update
        </button> 
        :
          <button
          disabled={
            Object.keys(packageList?.packageType)?.length === 0 ||
            packageList?.date?.length === 0 || packageList?.totalSeat?.length === 0 || packageList?.bookableSeat?.length === 0
          }
          onClick={() => {
            // handleSubmitTourSchedule();
            handleSubmitFirstSchedule();
          }}
          className="w-full  flex justify-center items-center h-[36px] px-[16px] py-[10px] text-bg-white bg-tour-primary text-[14px] border-[1px] border-tour-primary rounded-[50px]
            hover:bg-bg-white hover:text-tour-primary transition ease-in duration-300 disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
        >
          Add
        </button> 
          }
        </div>
      </div>
      {/* view */}
      {/* <div className="w-full">
            <div className="flex flex-col  gap-[16px]">
              {guidePackageList?.map((guide, index) => (
                <div
                  key={index}
                  className="shadow-customShadow p-[10px] rounded-[4px] relative"
                >
                  <p>Guide Number : {guide?.number_of_guides_for_day}</p>
                  <p>Price : {guide?.total_guide_price_for_day}</p>
                  <p>Provided Day :</p>
                  <div className="flex gap-x-[6px] ">
                    <p
                      key={index}
                      className="bg-btn-secondary py-[4px] px-[6px] rounded-[4px] text-bg-white"
                    >
                      {guide?.day_number}
                    </p>
                  </div>
                  <span
                    // onClick={() => handleRemoveGuide(index)}
                    className="absolute right-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[25px] h-[25px] text-btn-pink"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span
                    onClick={() => handleEditGuide(guide)}
                    className="absolute left-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </span>
                </div>
              ))}

              {guidePackageList?.length > 0 && (
                <button
                  onClick={() => navigate(-1)}
                  className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
                >
                  Save
                </button>
              )}
            </div>
          </div> */}
    </div>
  );
};

export default TourSchedule;
