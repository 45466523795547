import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { adminRoute } from "../../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";

export default function AdminHotelTNC() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [hotelData, setHotelData] = useState([]);

  const getThumbData = () => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN
        }?pageNumber=${currentPage}&pageSize=10&requestId=123&propertyNameKeyword=${searchValue}`
      )
      .then((res) => {
        setHotelData(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getThumbData();
  }, [searchValue, currentPage]);

  return (
    <div className="px-[16px]  min-h-screen pb-[20px]">
      <div className="max-w-[1100px]  mx-auto">
        <h2 className="text-center text-[22px] pt-[30px]">Hotel TNC</h2>

        <>
          <div className="mt-[20px] grid grid-cols-12 gap-x-[30px] justify-between items-center">
            <label className="relative block col-span-8">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                className="placeholder:italic text-txt-primary placeholder:text-txt-hint block bg-bg-white w-full border border-bg-semiblack rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-bg-semiblack focus:ring-bg-semiblack focus:ring-1 sm:text-sm"
                placeholder="Search for hotel name..."
                type="text"
                name="search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="w-full bg-bg-primary mt-[25px]">
            {/* table all hotel images */}
            <table className="table-fixed  border-collapse  w-full">
              <thead className="sticky top-[88px] shadow-sm bg-btn-primary text-bg-primary  text-left">
                <tr>
                  <th className="px-[8px] border-b py-[10px] border-bg-semiblack w-[700px]">
                    Hotel Name
                  </th>
                  <th className=" border-b border-bg-semiblack w-[400px]">
                    Contact Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {hotelData?.numberOfEntries > 0 &&
                  hotelData?.entries?.map((property, index) => (
                    <tr
                      key={index}
                      onClick={() =>
                        navigate(
                          `${adminRoute.ADMIN_FETCH_ALL_HOTEL_TNC}/${property?.propertyId}?property=${property?.propertyName}`
                        )
                      }
                      className="hover:bg-bg-primaryShadow cursor-pointer border-b border-bg-semiblack"
                    >
                      <td className="  py-[8px] flex justify-start items-center gap-x-[12px]">
                        {/* {facility === true ? "" :
                        <img
                          className="img-fluid w-[70px] h-[50px] "
                          src={
                            property?.images[0]?.url ||
                            "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                          }
                          alt=""
                        /> */}

                        {property?.propertyName}
                      </td>

                      <td className="  py-[8px]">{property?.contactNo}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="">
              {
                <Paginations
                  currentPage={currentPage}
                  totalCount={hotelData?.totalPages}
                  pageSize={10}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              }
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
