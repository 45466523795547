import React, { Fragment } from "react";

const TransferPackageTable = ({
  setPackageSelect,
  setViewPage,
  marchantTourCreateDatas,
  handleEdit,
  setUpdateSubmitButtonCondition,
  setMarchantCreateDatas,
}) => {
  const goToNextStep = () => {
    setPackageSelect(null);
  };
  const goToAnotherOption = () => {
    setPackageSelect(3);
    setViewPage(null);
    setUpdateSubmitButtonCondition(false);
  };

  const handleAccommondationIncluded = (ind) => {
    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions =
        prevPackage.tour_package_transfer_options.map((option, index) => {
          if (index === ind) {
            return {
              ...option,
              tour_package_option_is_default:
                !option.tour_package_option_is_default,
            };
          }
          return option;
        });

      return {
        ...prevPackage,
        tour_package_transfer_options: updatedAccommodationOptions,
      };
    });
    // }
  };

  return (
    <div>
      <h2 className="text-[22px] text-center text-btn-secondary">
        Transfer View Selected
      </h2>

      <div>
        <div className="my-[32px]">
          {marchantTourCreateDatas?.tour_package_transfer_option?.length ===
          0 ? (
            <div className="my-[40px]">
              <p className="text-center">No Data Found!</p>
            </div>
          ) : (
            <div>
              {marchantTourCreateDatas?.tour_package_transfer_options?.map(
                (tourOption, index) => (
                  <Fragment key={index}>
                    <div className="mb-[16px]">
                      {/* <button
                        onClick={() => handleAccommondationIncluded(index)}
                        className="flex justify-start gap-x-[6px] items-center pb-[8px] cursor-pointer shadow-customShadow px-[10px] rounded-[4px] py-[10px]  bg-btn-secondary w-[250px] text-bg-primary"
                      >
                       
                        <div
                          className={`h-[20px] w-[20px] border rounded-[4px] flex justify-center items-center ${
                            tourOption?.tour_package_option_is_default
                              ? "bg-btn-secondary border-bg-primary text-bg-primary"
                              : ""
                          } `}
                        >
                          {tourOption?.tour_package_option_is_default && (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-[18px] h-[18px]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </span>
                          )}
                        </div>{" "}
                        Select as default
                      </button> */}
                    </div>
                    <table className="fl-table mb-[40px]">
                      <thead className="sticky top-[88px]">
                        <tr>
                          <th>
                            <span>SL. No.</span>
                          </th>
                          <th>Route Name</th>
                          <th>Transfer Provider</th>
                          <th>Transfer Mode</th>
                          <th>AC</th>
                          <th>Trip Type</th>
                          <th>Suitable</th>
                          <th>Uinit Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourOption?.tour_package_transfer_packages?.map(
                          (transferPackage, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{transferPackage.transfer_route}</td>
                              <td>
                                {
                                  transferPackage.tour_transfer_provider_id
                                    .label
                                }
                              </td>
                              <td>
                                {transferPackage.tour_transfer_mode_id.label}
                              </td>
                              <td>{transferPackage.is_ac.label}</td>
                              <td>{transferPackage.trip_type.label}</td>
                              <td>
                                {transferPackage.suitable_for_persons.label}
                              </td>
                              <td>
                                {transferPackage.per_vehicle_per_trip_price}
                              </td>
                              <td>
                                <div className="flex items-center justify-center">
                                  <div>
                                    <span
                                      onClick={() => {
                                        handleEdit(
                                          tourOption?.tour_package_transfer_packages,
                                          index
                                        );
                                      }}
                                      className="text-[#239B56] "
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-[30px] h-[30px] cursor-pointer"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Fragment>
                )
              )}
            </div>
          )}

          <div className="flex justify-end mt-[30px]">
            <div className="flex flex-row gap-x-[30px]">
              <button
                className="bg-btn-secondary text-bg-white py-[4px] px-[30px] rounded-[4px]"
                onClick={() => goToAnotherOption()}
              >
                Add Another
              </button>
              <button
                className="bg-btn-secondary text-bg-primary py-[4px] px-[30px] rounded-[4px]"
                onClick={() => goToNextStep()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPackageTable;
