import React from "react";
import { useLocation, useNavigate } from "react-router";
import {
  DashboardRoutes,
  merchantTourRoute,
} from "../../../contants/Constants";

const TourNav = ({ marked }) => {
  const location = useLocation();
  let navigate = useNavigate();
  // console.log(location, "locationlocation");
  return (
    <div>
      <ul className="list-none w-full overflow-hidden fixed top-[88px] z-[50] h-[39px] text-xs py-3 bg-btn-secondary text-btn-base flex justify-between px-6 2xl:px-52">
        <li className="float-left">
          <button
            onClick={() => navigate(DashboardRoutes.TOURS)}
            className={`${
              location.pathname === DashboardRoutes.TOURS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Tour
          </button>
        </li>
        <li className="float-left">
          <button
            onClick={() => navigate(DashboardRoutes.TOUR_BOOKINGS)}
            className={`${
              location.pathname === DashboardRoutes.TOUR_BOOKINGS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            Tour Bookings
          </button>
        </li>

        <li className="float-left">
          <button
            // onClick={() => navigate(DashboardRoutes.ALL_BOOKINGS)}
            className={`${
              location.pathname == DashboardRoutes.ALL_BOOKINGS
                ? "underline underline-offset-[8px]"
                : null
            } block text-center cursor-pointer`}
          >
            All Tour Bookings
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TourNav;
