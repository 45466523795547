import React, { useEffect, useState } from 'react';
import ButtonFilled from '../../components/ButtonFilled';
import InputComponent from '../../components/InputComponent';

const OptionPrice = () => {
    const [rackPrice, setRackPrice] = useState(0);
    const [corporatePrice, setCorporatePrice] = useState(0);
    const [corporateDiscountPercent, setCorporateDiscountPercent] = useState(0);
    const [ghuddyCommissionPercent, setGhuddyCommissionPercent] = useState(0);
    const [ghuddyCommissionAmount, setGhuddyCommissionAmount] = useState(0);
    const [shurjoCommissionPercent, setShurjoCommissionPercent] = useState('2.5');
    const [shurjoCommissionAmount, setShurjoCommissionAmount] = useState(0);

    const [ghuddyWebSiteBlackPrice, setGhuddyWebSiteBlackPrice] = useState(0);

    const [ghuddyWebSiteRedPrice, setGhuddyWebSiteRedPrice] = useState(0);

    const [ghuddySubsidyAmount, setGhuddySubsidyAmount] = useState(0);
    const [ghuddySubsidyPercent, setGhuddySubsidyPercent] = useState(0);

    const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);
    const [totalGhuddyCommissionAmount, setTotalGhuddyCommissionAmount] =
        useState(0);
    const [totalGhuddyCommissionPercent, setTotalGhuddyCommissionPercent] =
        useState(0);
    const [totalShurjoCommissionAmount, setTotalShurjoCommissionAmount] =
        useState(0);

    const [totalSeats, setTotalSeats] = useState(0);
    const [bookableSeats, setBookableSeats] = useState(0);
    
    const [afterGhuddyCommission, setAfterGhuddyCommision] = useState(0);



  useEffect(() => {
    const temp =
      parseFloat(corporatePrice) + parseFloat(ghuddyCommissionAmount);
    setAfterGhuddyCommision(temp);
  }, [
    rackPrice,
    ghuddyCommissionAmount,
    ghuddyCommissionPercent,
    corporatePrice,
    corporateDiscountPercent,
  ]);
  useEffect(() => {
    const temp =
      parseFloat(afterGhuddyCommission) + parseFloat(shurjoCommissionAmount);
    setGhuddyWebSiteBlackPrice(temp);
  }, [afterGhuddyCommission, shurjoCommissionAmount]);

  useEffect(() => {
    const subsidyTemp =
      parseFloat(ghuddyWebSiteBlackPrice) - parseFloat(ghuddyWebSiteRedPrice);

    if (ghuddyWebSiteBlackPrice > 0) {
      var subsidyPercentTemp =
        (parseFloat(subsidyTemp) / parseFloat(ghuddyWebSiteBlackPrice)) * 100;
    }

    const tempTotalShurjoCommision =
      (parseFloat(ghuddyWebSiteRedPrice) *
        parseFloat(shurjoCommissionPercent)) /
      100;
    setTotalShurjoCommissionAmount(tempTotalShurjoCommision);

    setGhuddySubsidyAmount(subsidyTemp);
    setGhuddySubsidyPercent(subsidyPercentTemp);
  }, [ghuddyWebSiteBlackPrice, ghuddyWebSiteRedPrice]);

  useEffect(() => {
    // const tempTotalCommission =
    //   parseFloat(ghuddyCommissionAmount) + parseFloat(shurjoCommissionAmount);

    const tempTotalCommission =
      parseFloat(ghuddyWebSiteRedPrice) -
      parseFloat(totalShurjoCommissionAmount) -
      parseFloat(corporatePrice);

    setTotalGhuddyCommissionAmount(tempTotalCommission);
    if (corporatePrice) {
      var tempTotalCommisionPercent =
        (tempTotalCommission / corporatePrice) * 100;
    }

    setTotalGhuddyCommissionPercent(tempTotalCommisionPercent);
  }, [
    ghuddyCommissionAmount,
    ghuddyCommissionPercent,
    shurjoCommissionAmount,
    shurjoCommissionPercent,
    corporatePrice,
    ghuddyWebSiteRedPrice,
    totalShurjoCommissionAmount,
  ]);

  useEffect(() => {
    if (rackPrice > 0) {
      var tempTotalDiscount =
        ((rackPrice - ghuddyWebSiteRedPrice) / rackPrice) * 100;
    }
    setTotalDiscountPercent(tempTotalDiscount);
  }, [rackPrice, ghuddyWebSiteRedPrice]);


      const handleAmountAndPercent = (type, val, setAmount, setPercent, base) => {
        if (type === "amount") {
          setAmount(parseFloat(val));
          setPercent(parseFloat((base - val) * 100) / base);
        } else {
          setPercent(parseFloat(val));
          setAmount(parseFloat(base - base * (val / 100)));
        }
      };
      const handleConversion = (type, val, setPercentAmount, setPercent, base) => {
        const valInt = parseFloat(val);
        if (type === "amount") {
          setPercentAmount(valInt);
          setPercent((valInt * 100) / base);
        } else {
          setPercent(valInt);
          setPercentAmount((base * valInt) / 100);
        }
      };

      useEffect(()=>{
        const tempSurjoComAmount = (afterGhuddyCommission * 2.5)/100;
        setShurjoCommissionAmount(tempSurjoComAmount);

      },[afterGhuddyCommission])

      useEffect(()=>{
      
            initializeAll();
            console.log('check',1);
        
      },[rackPrice])

    const handleSetPrice = ()=>{
        const apiObject = {
            rack_price : rackPrice,
            corporate_price :  corporatePrice,
            corporate_discount_percent : corporateDiscountPercent,
            ghuddy_commission_percent : Number(ghuddyCommissionPercent.toFixed(3)),
            ghuddy_commission_amount :  Number(ghuddyCommissionAmount.toFixed(3)),
            shurjo_commission_amount :  shurjoCommissionAmount,
            shurjo_commission_percent : Number(shurjoCommissionPercent),
            ghuddy_website_black_price :   ghuddyWebSiteBlackPrice,
            ghuddy_subsidy_amount : ghuddySubsidyAmount,
            ghuddy_subsidy_percent : ghuddySubsidyPercent,
            ghuddy_website_red_price : ghuddyWebSiteRedPrice,
            otal_discount_percent :  totalDiscountPercent,
            total_shurjo_commission_amount :  totalShurjoCommissionAmount,
            total_ghuddy_commission_amount :  totalGhuddyCommissionAmount,
            total_ghuddy_commission_percent :   totalGhuddyCommissionPercent,
            total_seats : totalSeats,
            bookable_seats: bookableSeats,
            tour_package_option_id: 15,
            deleteAllFilters: true,
            requestId: "11",  
          };
          console.log(apiObject,'check')
    }

    const initializeAll = () => {
        setCorporatePrice(0);
        setCorporateDiscountPercent(0);
        setGhuddyCommissionAmount(0);
        setGhuddyCommissionPercent(0);
        setShurjoCommissionAmount(0);
        setGhuddyWebSiteRedPrice(0);
        setGhuddySubsidyAmount(0);
        setGhuddySubsidyPercent(0);
        setTotalDiscountPercent(0);
        setTotalGhuddyCommissionAmount(0);
        setTotalGhuddyCommissionPercent(0);
        setTotalSeats(0);
        setBookableSeats(0);
      };
    return (
        <div className='w-full'>
        <div className="flex flex-col w-full max-w-[1140px] border rounded-[8px] border-txt-hint  px-[10px] pb-[20px]">
          <div className="text-center text-[27px] py-[20px] z-[50] sticky top-[0px] shadow bg-bg-primary ">
            {" "}
            <h1>SET OPTION PRICE</h1>
          </div>
          <div className="my-2">
            <div className="text-lg  mb-2">
              <span>RackPrice : </span>
              <InputComponent
                name={"RackPrice"}
                inputValue={rackPrice}
                setInputValue={(val) => setRackPrice(Number(val))}
                warningView={false}
                type="number"
                placeholder="Rack Price"
                required
                spanValue="BDT"
                addSpan={true}
              />
            </div>
          </div>

          <div>
            <div className="text-base mb-2">Corporate Discount</div>
            <div className="flex gap-2 items-center">
              <InputComponent
                name={"CorporatediscountAmount"}
                warningView={false}
                placeholder={"Corporate Rate"}
                inputValue={corporatePrice}
                setInputValue={(val) => {
                  // setDiscount(val);
                  handleAmountAndPercent(
                    "amount",
                    val,
                    setCorporatePrice,
                    setCorporateDiscountPercent,
                    rackPrice
                  );
                }}
                addSpan={true}
                spanValue="BDT"
                type="number"
              />
              or
              <InputComponent
                name={"discountPercent"}
                warningView={false}
                inputValue={corporateDiscountPercent}
                setInputValue={(val) => {
                  // setDiscountPercent(val);
                  handleAmountAndPercent(
                    "percent",
                    val,
                    setCorporatePrice,
                    setCorporateDiscountPercent,
                    rackPrice
                  );
                }}
                placeholder={"Corporate discount %"}
                type="number"
                addSpan={true}
                spanValue="%"
              />
            </div>
          </div>

          <div>
            <div className="text-base mb-2">Ghuddy commission </div>
            <div className="flex gap-2 items-center">
              <InputComponent
                name={"GhuddyCommissionAmount"}
                warningView={false}
                inputValue={ghuddyCommissionAmount}
                setInputValue={(val) => {
                 
                  handleConversion(
                    "amount",
                    val,
                    setGhuddyCommissionAmount,
                    setGhuddyCommissionPercent,
                    corporatePrice
                  );
                }}
                addSpan={true}
                spanValue="BDT"
                type="number"
              />
              or
              <InputComponent
                name={"GhuddyCommissionPercemt"}
                warningView={false}
                inputValue={ghuddyCommissionPercent}
                setInputValue={(val) => {
                 
                  handleConversion(
                    "percent",
                    val,
                    setGhuddyCommissionAmount,
                    setGhuddyCommissionPercent,
                    corporatePrice
                  );
                }}
                addSpan={true}
                spanValue="%"
                type="number"
                
              />
            </div>
          </div>
          <div className="py-4">
            Price after ghuddy commission : {Math.round(afterGhuddyCommission)}
          </div>
          <div>
            <div className="text-base mb-2">Shurjo commission </div>
            <div className="flex gap-2 items-center">
              <InputComponent
                name={"ShurjoCommissionAmount"}
                warningView={false}
                inputValue={shurjoCommissionAmount}
                setInputValue={(val) => {
                //   handleConversion(
                //     "amount",
                //     val,
                //     setShurjoCommissionAmount,
                //     setShurjoCommissionPercent,
                //     afterGhuddyCommission
                //   );
                }}
                addSpan={true}
                spanValue="BDT"
                type="number"
                isReadOnly={true}
              />
              or
              <InputComponent
                name={"ShurjoCommissionPercemt"}
                warningView={false}
                inputValue={shurjoCommissionPercent}
                setInputValue={(val) => {
                //   handleConversion(
                //     "percent",
                //     val,
                //     setShurjoCommissionAmount,
                //     setShurjoCommissionPercent,
                //     afterGhuddyCommission
                //   );
                }}
                addSpan={true}
                spanValue="%"
                type="number"
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="py-4">
            Price after shurjo commision (website black price) :{" "}
            {ghuddyWebSiteBlackPrice.toFixed(3)} BDT
          </div>

          <div className="text-base mb-2">ghuddy website Red price </div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"GhuddyWebsiteRedPrice"}
              warningView={false}
              inputValue={ghuddyWebSiteRedPrice}
              setInputValue={(val) => {
                setGhuddyWebSiteRedPrice(Number(val))
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
          </div>

          <div>
            <div>
              Ghuddy Subsidy Amount :{" "}
              {ghuddySubsidyAmount ? ghuddySubsidyAmount.toFixed(3) : 0} BDT
            </div>
            <div>
              Ghuddy Subsidy Percent :{" "}
              {ghuddySubsidyPercent ? ghuddySubsidyPercent.toFixed(3) : 0} %
            </div>
            <div>
              Total Shurjo commision Amount :{" "}
              {totalShurjoCommissionAmount
                ? totalShurjoCommissionAmount.toFixed(3)
                : 0}
            </div>
            <div>
              Ghuddy total discount :{" "}
              {totalDiscountPercent ? totalDiscountPercent.toFixed(3) : 0} %{" "}
            </div>
            <div>
              total ghuddy commission amount :{" "}
              {totalGhuddyCommissionAmount
                ? totalGhuddyCommissionAmount.toFixed(3)
                : 0}{" "}
              BDT
            </div>
            <div>
              total ghuddy commission Percent :{" "}
              {totalGhuddyCommissionPercent
                ? totalGhuddyCommissionPercent.toFixed(3)
                : 0}{" "}
              %
            </div>
          </div>

          <div className="space-y-[10px]">
            <InputComponent
              name={"TotalSeat"}
              inputValue={totalSeats}
              setInputValue={(val) => setTotalSeats(Number(val))}
              warningView={false}
              placeholder="Total Seats"
              type="number"
            />

            <InputComponent
              name={"bookableSeats"}
              inputValue={bookableSeats}
              setInputValue={(val) => setBookableSeats(Number(val))}
              warningView={false}
              placeholder="Bookable Seats"
              type="number"
            />

          </div>

          <div className="mt-[2rem] w-full">
            <ButtonFilled
              title="Submit"
              buttonFilledHandle={handleSetPrice}
              w="100%"
              h="49px"
              ml={"ml-[0px]"}
            />
          </div>
        </div>
        </div>
    );
};

export default OptionPrice;