import React, { useEffect, useState } from "react";
import { authAxios } from "../../../utils/axiosWrapper";
import { BLOG_CONTROLLER_API } from "../../../contants/Endpoints";
import TourMerchantButton from "../../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../../components/common/ModalCommon";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../../ToursMarchent/Utilities/SelectAnimation";
import { toast } from "react-toastify";
import Paginations from "../Paginations/Paginations";
import Loading from "../../../components/Loader/Loading";

const BlogTags = () => {
  const [allTags, setAllTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  let pageSize = 10;
  const [tagPackage, setTagPackage] = useState({
    tag_id: 0,
    tag_name: "",
    tag_type: "",
  });

  const handleBlogButtonClick = () => {
    window.open(
      "https://dev-partner.ghuddy.com/content/3bbblog/all/blog/ghuddy",
      "_blank"
    );
  };

  const getAllTags = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${BLOG_CONTROLLER_API.BLOGS_ALL_TAGS}page-number=${currentPage}&page-size=${pageSize}&requestId=123`
      )
      .then((res) => {
        setAllTags(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllTags();
  }, [currentPage]);

  const tagTypeOptions = [
    { label: "DESTINATION_LOCATION", value: "DESTINATION_LOCATION" },
    { label: "ACTIVITY", value: "ACTIVITY" },
  ];

  const payloadMethod = {
    blog_tag: {
      tag_id: 0,
      tag_name: tagPackage?.tag_name,
      tag_type: tagPackage?.tag_type?.label,
    },
    requestId: "1234",
  };

  const onAddNewTag = () => {
    setTagPackage({
      tag_id: 0,
      tag_name: "",
      tag_type: "",
    });
    setToggleModal(true);
  };

  const onAddNewBlog = () => {
    setIsLoading(true);
    authAxios
      .post(`${BLOG_CONTROLLER_API?.ADMIN_POST_BLOG_TAG}`, payloadMethod)
      .then((res) => {
        toast.success("Successfully added new blog tag");
        getAllTags();
        setToggleModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Blog tag add failed.");
        console.log(err);
        setIsLoading(false);
        setToggleModal(false);
      });
  };

  return (
    <div className="px-[12px]">
      <p className="text-[22px] text-btn-secondary text-center mb-[20px] mt-[8px]">
        All blog Tags
      </p>

      <div>
        <div className="flex justify-end gap-[20px]">
          <TourMerchantButton
            width={""}
            name={"Add New Tag"}
            onClick={() => onAddNewTag()}
          ></TourMerchantButton>
          <TourMerchantButton
            width={""}
            name={"Go to blog page."}
            onClick={() => handleBlogButtonClick()}
          ></TourMerchantButton>
        </div>
      </div>

      {/* {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div> :
        <p>Hello</p> } */}
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div>
          {allTags?.length > 0 && (
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Sl No.</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>ID</th>
                    {/* <th>Activated</th> */}
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {allTags?.length > 0 &&
                    allTags?.map((property, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + pageSize * currentPage - pageSize}.
                        </td>
                        <td className="cursor-pointer mx-auto">
                          {property?.tag_name}
                        </td>
                        <td className="whitespace-pre-line">
                          {property?.tag_type}
                        </td>
                        <td>{property?.tag_id}</td>
                        {/* <td className="whitespace-pre-line">
                          {property?.is_active ? "True" : "False"}
                        </td> */}
                        {/* <td>
                          <div className="flex flex-row gap-x-[8px]">
                            <button
                            //   onClick={() => onEditButton(property)}
                              className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                            >
                              Edit
                            </button>
                            <button
                            //   onClick={() => {
                            //     setToggleModalSecond(true);
                            //     setBlogId(property?.blog_post_id);
                            //   }}
                              className="bg-[#f87171] text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#f87171] rounded-md shadow-md transition-all duration-500 hover:text-[#f87171] hover:shadow-lg "
                            >
                              Delete
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div>
        <ModalCommon
          shown={toggleModal}
          width="max-w-[450px]"
          height="min-h-[300px]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
          }}
        >
          <div className="px-[40px] py-[20px]">
            <div>
              <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                Add new tags
              </h2>
              <div className="my-[16px]">
                <p className="text-btn-secondary">Name: </p>
                <div className="rounded">
                  <input
                    className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                    placeholder="Enter tag name : "
                    type="text"
                    value={tagPackage?.tag_name || ""}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setTagPackage((prev) => ({
                        ...prev,
                        tag_name: inputVal,
                      }));
                    }}
                    // value={fullSuitableFor?.title}
                  />
                </div>
                {/* {showError && fullSuitableFor?.title?.length === 0 && (
                  <p className="text-[13px] text-[#f87171] mt-[2px]">
                    Title is required.
                  </p>
                )} */}
              </div>

              <div>
                <p className="text-btn-secondary">Type: </p>
                <div className="rounded">
                  <Select
                    // defaultValue={foodItemOption || ""}
                    value={tagPackage?.tag_type || ""}
                    onChange={(data) =>
                      setTagPackage((prev) => ({
                        ...prev,
                        tag_type: data,
                      }))
                    }
                    isClearable
                    components={animatedComponents}
                    options={tagTypeOptions}
                    placeholder={"Select Options: "}
                    styles={colorStyles}
                  />
                </div>
              </div>

              <div className="mt-[20px]">
                <button
                  disabled={
                    tagPackage?.tag_name?.length === 0 ||
                    tagPackage?.tag_type?.label?.length === 0 ||
                    tagPackage?.tag_type?.label === undefined
                  }
                  onClick={() => onAddNewBlog()}
                  className="w-full flex justify-center items-center h-[36px] px-[16px] py-[16px] bg-tour-primary text-bg-white text-[16px] border-[1px] border-tour-primary rounded-[4px] hover:bg-tour-secondaryShade transition ease-in duration-300 disabled:bg-bg-semiblack disabled:border-bg-semiblack"
                >
                  Add Tag
                </button>
              </div>
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default BlogTags;
