import React from "react";
import { AiOutlineBorder } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import { toast } from "react-toastify";

const FoodPackageDaySelectCheckbox = ({
  dayNumber,
  foodMealType,
  setAllFoodPackage,
  allFoodPackage,
}) => {
  const handleMeals = (day, item) => {
    // setAllFoodPackage((prev) => {
    //   const updatedMeals = { ...prev.meals_provided_in_days };
    //   if (updatedMeals[day]) {
    //     updatedMeals[day].push(item);
    //   } else {
    //     updatedMeals[day] = [item];
    //   }
    //   return { ...prev, meals_provided_in_days: updatedMeals };
    // });

    const includesMealTypeValueTwo =
      allFoodPackage?.tour_package_meal_packages?.some(
        (mealFood) => mealFood.meal_type_id.value === item.value
      );

    if (includesMealTypeValueTwo) {
      setAllFoodPackage((prev) => {
        const updatedMeals = { ...prev.meals_provided_in_days };
        if (updatedMeals[day]) {
          if (!updatedMeals[day].includes(item.value)) {
            updatedMeals[day].push(item.value);
          }
        } else {
          updatedMeals[day] = [item.value];
        }
        return { ...prev, meals_provided_in_days: updatedMeals };
      });
    } else {
      toast.warn(`Please provide ${item.label}`);
    }
  };

  const handleUpdateMeals = (day, item) => {
    setAllFoodPackage((prev) => {
      const updatedMeals = { ...prev.meals_provided_in_days };
      const filteredKeys = Object.keys(updatedMeals).filter(
        (key) => key === day.toString()
      );

      filteredKeys.forEach((filteredKey) => {
        const existingItems = updatedMeals[filteredKey];
        if (existingItems.includes(item)) {
          const updatedItems = existingItems.filter((i) => i !== item);
          if (updatedItems.length === 0) {
            delete updatedMeals[filteredKey];
          } else {
            updatedMeals[filteredKey] = updatedItems;
          }
        } else {
          updatedMeals[filteredKey].push(item);
        }
      });

      return { ...prev, meals_provided_in_days: updatedMeals };
    });
  };

  const filterKeys = (obj, key) => {
    if (obj && obj[key]) {
      return obj[key];
    }
    return [];
  };

  const dayMealValue = filterKeys(
    allFoodPackage?.meals_provided_in_days,
    dayNumber
  );

  return (
    <div className="flex flex-row justify-center items-center rounded overflow-hidden my-[20px]">
      <div className="bg-[#020617] text-bg-white w-[13%] py-[20px] px-[20px] text-center">
        Day {dayNumber} :
      </div>
      {foodMealType?.map((item, indx) => (
        <div className="bg-[#94a3b8] text-bg-white w-[29%] py-[20px] px-[20px] text-center border-l">
          <div className="flex justify-center items-center">
            <div className="">
              <span className="  rounded-[4px] duration-500">
                {dayMealValue?.includes(indx + 1) ? (
                  <BsCheck2
                    onClick={() => handleUpdateMeals(dayNumber, item.value)}
                    className="text-[18px] bg-btn-primary rounded-[2px] cursor-pointer"
                  ></BsCheck2>
                ) : (
                  <>
                    <AiOutlineBorder
                      onClick={() => handleMeals(dayNumber, item)}
                      className="text-[18px] bg-bg-white rounded-[2px] cursor-pointer"
                    ></AiOutlineBorder>
                  </>
                )}
              </span>
            </div>
            <p className="ml-[8px]">{item?.label}</p>
          </div>
        </div>
      ))}
      {/* <div className="bg-[#94a3b8] text-bg-white w-[29%] py-[20px] px-[20px] text-center border-r">
              <div className="flex justify-center items-center">
                <div className="">
                  <span className="  rounded-[4px] duration-500">
                      {isCheckBreakfast ? <BsCheck2 onClick={() => setIsCheckBreakfast(!isCheckBreakfast)} className="text-[18px] bg-btn-primary rounded-[2px]"></BsCheck2> :
                      <AiOutlineBorder onClick={() => setIsCheckBreakfast(!isCheckBreakfast)}  className="text-[18px] bg-bg-white rounded-[2px]"></AiOutlineBorder> }
                  </span>
                </div>
                <p className="ml-[8px]">
                  BreakFast
                </p>
              </div>
            </div>
            <div className="bg-[#94a3b8] text-bg-white w-[29%] py-[20px] px-[20px] text-center border-r">
              <div className="flex justify-center items-center">
                <div className="">
                  <span className="  rounded-[4px] duration-500">
                      {isCheckLunch? <BsCheck2 onClick={() => setIsCheckLunch(!isCheckLunch)}  className="text-[18px] bg-btn-primary rounded-[2px]"></BsCheck2> :
                      <AiOutlineBorder onClick={() => setIsCheckLunch(!isCheckLunch)} className="text-[18px] bg-bg-white rounded-[2px]"></AiOutlineBorder> }
                  </span>
                </div>
                <p className="ml-[8px]">Lunch</p>
              </div>
            </div>
            <div className="bg-[#94a3b8] text-bg-white w-[29%] py-[20px] px-[20px] text-center">
              <div className="flex justify-center items-center">
                <div className="">
                  <span className="  rounded-[4px] duration-500">
                      {isCheckDinner ? <BsCheck2 onClick={() => setisCheckDinner(!isCheckDinner)} className="text-[18px] bg-btn-primary rounded-[2px]"></BsCheck2> :
                      <AiOutlineBorder onClick={() => setisCheckDinner(!isCheckDinner)} className="text-[18px] bg-bg-white rounded-[2px]"></AiOutlineBorder> }
                  </span>
                </div>
                <p className="ml-[8px]">Dinner</p>
              </div>
            </div> */}
    </div>
  );
};

export default FoodPackageDaySelectCheckbox;
