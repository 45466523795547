import React, { Fragment } from "react";

import { useNavigate } from "react-router";
import { merchantTourRoute } from "../../contants/Constants";
import SliderCart from "../../pages/admin/NewTourCreateToll/SliderCart";
import TourCardView from "../TourCardView";
export const convertTo12HourFormatWithAMPM = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":");
  let period = "AM";

  let formattedHours = parseInt(hours, 10);

  if (formattedHours === 0) {
    formattedHours = 12;
  } else if (formattedHours === 12) {
    period = "PM";
  } else if (formattedHours > 12) {
    formattedHours -= 12;
    period = "PM";
  }

  return `${formattedHours}:${minutes} ${period}`;
};
const SubscribeCard = ({ tour }) => {
  const navigate = useNavigate();

  // console.log({tour})

  // const navigateToOtherPage = () => {
  //   navigate(
  //     `/dashboard/tour-availability/tour_id=${getTours?.tour_id}&subscribeId=${subscribeId}&number_of_days=${getTours?.number_of_days}&number_of_nights=${getTours?.number_of_nights}`,
  //     {
  //       state: getTours,
  //     }
  //   );
  // };
  // console.log("tour", tour);
  return (
    <div className="py-[10px] px-[8px]  text-[14px]">
      <div className=" w-full gap-y-[30px]">
        {Object.keys(tour)?.map((location) => (
          <div key={location}>
            <h2 className="text-[18px] text-tour-text font-bold my-[16px]">{location}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 w-full gap-[20px] ">
              {tour[location]?.map((tour) => (
                <Fragment key={tour.subscribed_tour_id}>
                {/* <div
                  className="shadow-customShadow p-[10px] rounded-[4px] flex flex-col gap-y-[6px]"
                  key={tour.subscribed_tour_id}
                >
                  <h2 className="text-[16px] text-btn-secondary text-center">
                    {tour.subscribed_tour_name}
                  </h2>
                  <p className="flex gap-x-[3px]">
                    <span>Reporting Time:</span>
                    {convertTo12HourFormatWithAMPM(
                      tour.subscribed_tour_reporting_time
                    )}
                  </p>
                  <p>Reporting Place: {tour.subscribed_tour_reporting_place}</p>

                  <div className="mt-[10px]">
                    <p className="text-[12px] text-btn-primary">
                      Tour Itinerary
                    </p>
                    <div className="w-full">
                      <SliderCart
                        cart={tour?.subscribed_tour_itinerary}
                        containerWidth={"w-[343px]"}
                        cartWidth={130}
                        cartViewNumber={2.5}
                      >
                        {tour?.subscribed_tour_itinerary?.map(
                          (itinerary, index) => (
                            <div
                              key={index}
                              className="max-w-[130px] min-w-[130px]"
                            >
                              <p
                                title={itinerary?.activity_name}
                                className="text-[14px] truncate"
                              >
                                {itinerary?.activity_name}
                              </p>
                              <div className="w-[130px] h-[80px] rounded-[8px] overflow-hidden">
                                <img
                                  src={
                                    itinerary?.activity_images?.[0]
                                      ?.activity_image_url
                                  }
                                  alt="ghuddy"
                                  loading="lazy"
                                  className="object-cover w-full h-full"
                                />
                              </div>
                            </div>
                          )
                        )}
                      </SliderCart>
                    </div>
                  </div>

                  <button
                    className="bg-btn-secondary text-bg-white w-full  rounded-[4px]  py-[4px] cursor-pointer"
                    onClick={() => {
                      navigate(
                        `${merchantTourRoute.TOURONE}tour_id=${tour?.created_tour_id}&subscribeId=${tour?.subscribed_tour_id}`
                      );
                    }}
                  >
                    View
                  </button>
                </div> */}
                <TourCardView tours={tour} subscribeButton={true}></TourCardView>
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscribeCard;
