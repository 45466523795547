import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MultiSelectParent from "../../../../components/multiselect/MultiSelectParent";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import { noAuthAxios } from "../../../../utils/axiosWrapperOpen";
export default function TransportRoute() {
  const [getTransportationRoute, setGetTransportationRoute] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [selectedDestination1, setSelectedDestination1] = useState([]);

  const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});

  const getAllTransportationRoute = () => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint?.ADMIN_GET_ALL_TRANSPORTATION_ROUTE_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.transportationRouteDataList)
        setGetTransportationRoute(res?.data?.transportation_routes);
      });
  };

  useEffect(() => {
    getAllTransportationRoute();
  }, []);

  const getDestinationList = () => {
    authAxios
      .get(`${apiEndPoint?.ADMIN_GET_INSPIREDV2}`)
      .then((res) => {
        setDestinationList(res.data.destinationLocationDataOptimizedList);
      })
      .catch((err) => {
        // toastMessage("Error fething travel destination", "error");
      });
  };

  useEffect(() => {
    getDestinationList();
  }, []);

  const handleTourAdd = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_POST_TOUR_TRANSPORT_ROUTE_POST_API}`,
        {
          transportation_route: {
            destination_location_id: selectedDestination1[0],
            source_location_id: selectedDestination[0],
          },
          requestId: "string",
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toast.success("Added destination successfully");
          getAllTransportationRoute();
          setSelectedDestination([]);
          setSelectedDestination1([]);
        }
      });
    // http://192.168.0.101:8080/api/v1/admin/tours/add
  };

  return (
    <div className="max-w-[1100px] mt-[20px]">
      <p className="text-btn-secondary">Tour Added Route List</p>
      <div className="flex flex-col gap-y-[15px] w-full max-w-[450px] mt-[12px] overflow-y-scroll max-h-[550px] p-[6px]">
        {getTransportationRoute?.map((route, index) => (
          <div
            key={index}
            className="shadow-customShadow rounded-[4px] p-[10px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">Provider Route :</span>{" "}
              {route?.transportation_route_source_location}-
              {route?.transportation_route_destination_location}
            </p>
          </div>
        ))}
      </div>
      <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed top-[90px] right-[30px] w-full">
        <h2 className=" text-center text-[22px] underline">
          Transport Route Add
        </h2>

        <div className="">
          <p className="text-[16px] mb-[3px]">Starting </p>

          <MultiSelectParent
            required={true}
            selectedArray={selectedDestination}
            setSelectedArray={setSelectedDestination}
            displayField={"name"}
            selectionKey="id"
            data={destinationList}
            placeholder="Travel Destinations"
            // isAdmin={
            //   process.env.REACT_APP_USER_TYPE === UserType.ADMIN
            //     ? true
            //     : false
            // }
            isReadOnly={false}
            rejectionArray={facilitiesRejectArray}
            setRejectionArray={setFacilitiesRejectArray}
            name="selectedDestinations"
          />
        </div>
        <div className="">
          <p className="text-[16px] mb-[3px]">End</p>

          <MultiSelectParent
            required={true}
            selectedArray={selectedDestination1}
            setSelectedArray={setSelectedDestination1}
            displayField={"name"}
            selectionKey="id"
            data={destinationList}
            placeholder="Travel Destinations"
            // isAdmin={
            //   process.env.REACT_APP_USER_TYPE === UserType.ADMIN
            //     ? true
            //     : false
            // }
            isReadOnly={false}
            rejectionArray={facilitiesRejectArray}
            setRejectionArray={setFacilitiesRejectArray}
            name="selectedDestinations"
          />
        </div>

        <button
          onClick={() => handleTourAdd()}
          disabled={
            selectedDestination?.length === 0 ||
            selectedDestination1?.length === 0
          }
          className="bg-btn-primary h-[49px] w-full my-[16px] text-bg-white rounded-[4px] disabled:bg-bg-semiblack"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
