import "./termsAndConditions.css";

import React, { Fragment, useEffect, useState } from "react";

import ButtonFilled from "../../components/ButtonFilled";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalCommon from "../../components/common/ModalCommon";
import CustomTextEditor from "../CustomTextEditor";

import ReactQuill from "react-quill";

export default function TermsAndCondtionsIndex() {
  const [modalShwon, setModalShwon] = useState(false);
  const [getAllTNCByID, setGetAllTNCByID] = useState("");
  const [pageView, setPageView] = useState("ADD_TNC");
  const [getAllTNC, setGetAllTNC] = useState("");

  const [terms, setTerms] = useState({
    description: "",
    order: 0,
    title: "",
  });

  const [termsAndConditionDataList, setTermsAndConditionDataList] = useState({
    clauses: [],
    force: true,
    requestId: "string",
    tncVersion: 0,
    userType: "USER",
  });

  useEffect(() => {
    getData();
    getTncData();
  }, []);

  const getData = () => {
    authAxios.get(`${apiEndPoint.GET_ALL_TERMS_CONDITIONS}`).then((res) => {
      setTermsAndConditionDataList((prev) => ({
        ...prev,
        // tncVersion:
        //   res?.data?.termsAndConditionDataList?.[
        //     res?.data?.termsAndConditionDataList?.length - 1
        //   ]?.tncVersion + 1 ?? 0,
        tncVersion: Number(res?.data + 1),
      }));
    });
  };
  const getTncData = () => {
    authAxios
      .get(`${apiEndPoint.GET_ALL_TERMS_CONDITIONS_DATA}`)
      .then((res) => {
        setGetAllTNC(res?.data?.termsAndConditionDataList);
      });
  };

  const PostTerms = () => {
    authAxios
      .post(`${apiEndPoint.ADD_TERMS_CONDITIONS}`, termsAndConditionDataList)
      .then((res) => {
        getData();
        // alert("succesful!");
        toast.success("Succesfully Added 🔥", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTermsAndConditionDataList({
          clauses: [],
          force: true,
          requestId: "string",
          tncVersion: 0,
          userType: "USER",
        });
      })
      .catch((err) => {});
  };

  const handleTncSlag = () => {
    if (terms?.title !== "" && terms?.description !== "") {
      setTermsAndConditionDataList((prev) => ({
        ...prev,
        clauses: [...prev.clauses, terms],
      }));

      setTerms({
        title: "",
        order: 0,
        description: "",
      });
    }
  };
  const handleGetTNCBYID = (id) => {
    authAxios
      .get(`${apiEndPoint.GET_ALL_TERMS_CONDITIONS_BY_ID}/${id}?requestId=123`)
      .then((res) => {
        setGetAllTNCByID(res?.data?.termsAndConditionData);
      });
  };
  console.log("getAllTNCByID", getAllTNCByID);
  return (
    <>
      <div className=" mx-auto my-12 max-w-[1100px]">
        <div>
          <h2 className="xl:text-4xl lg:text-3xl text-2xl text-btn-primary text-center">
            Terms and Conditions
          </h2>
          {/* <div className="my-8 mx-4 flex justify-end">
            <ButtonSecond
              width={"xl:w-64 lg:w-56 md:w-52 w-40"}
              name={"View All TNC"}
              onClick={() => setShowModal(true)}
            ></ButtonSecond>
          </div> */}

          <div className="flex justify-end items-end py-[10px]">
            <div className="max-w-[300px]">
              <select
                value={pageView}
                onChange={(e) => setPageView(e.target.value)}
                className=" w-full bg-[transparent] border border-[#9e9989] rounded-md py-[4px] px-[8px] mr-[10px] cursor-pointer"
              >
                <option value={"ADD_TNC"}> ADD TNC </option>
                <option value={"VIEW"}> View </option>
              </select>
            </div>
          </div>
        </div>

        {pageView === "ADD_TNC" ? (
          <div className="flex justify-start items-start gap-x-[10px]">
            {termsAndConditionDataList?.clauses?.length > 0 && (
              <div className="w-full bg-bg-white rounded-[8px] p-[10px]">
                <div className="text-[14px]">
                  <p className="text-center text-[16px] text-btn-secondary">
                    Draft
                  </p>
                  {/* <p className="">
                    User Type : {termsAndConditionDataList?.userType}
                  </p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: termsAndConditionDataList?.userType,
                    }}
                  ></p>
                  <p>TNC Version : {termsAndConditionDataList?.tncVersion}</p>

                  <div className="max-h-[450px] overflow-x-scroll">
                    {termsAndConditionDataList?.clauses?.map(
                      (termsData, index) => (
                        <div key={index}>
                          <h2 className="text-[18px]">{termsData?.title}</h2>
                          {/* <div
                            className="quill-content"
                            dangerouslySetInnerHTML={{
                              __html: termsData?.description,
                            }}
                          /> */}
                          <ReactQuill
                            value={termsData?.description}
                            readOnly={true}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="flex justify-center gap-2 mt-8">
                  <ButtonFilled
                    title={"Submit"}
                    w="200px"
                    buttonFilledHandle={() => {
                      PostTerms();
                    }}
                  />
                </div>
              </div>
            )}

            <div className="bg-bg-white w-full rounded-[10px] py-[10px]">
              <div className=" relative">
                <div className="text-center text-2xl mb-4">
                  Add Terms And Condition
                </div>

                <div className="flex flex-row items-center justify-center my-6 px-[10px]">
                  <div className="w-1/2">
                    <div className="">Version</div>
                    <InputComponent
                      inputValue={termsAndConditionDataList?.tncVersion}
                      setInputValue={""}
                      required={true}
                      number={true}
                      isReadOnly={true}
                      name="version"
                    />
                  </div>

                  <div className="w-1/2 pr-2 ">
                    <div>User Type</div>
                    <select
                      value={termsAndConditionDataList?.userType}
                      onChange={(e) => {
                        setTermsAndConditionDataList((prev) => ({
                          ...prev,
                          userType: e.target.value,
                        }));
                      }}
                      className=" w-full bg-[transparent] border border-[#9e9989] rounded-md py-[10px] px-1 mr-4 cursor-pointer"
                    >
                      <option value={"USER"}> User </option>
                      <option value={"MERCHANT"}> Merchant </option>
                    </select>
                  </div>
                </div>

                {/* <div className="my-4 px-[10px]">
                <label>
                  Force?
                  <input
                    className="ml-2"
                    onChange={() => {
                      setForce(!force);
                    }}
                    type={"checkbox"}
                    checked={force}
                  />
                </label>
              </div> */}
                <div className="bg-[#EBF5FB] flex flex-col gap-y-[12px] px-[10px] py-[10px]">
                  <div className="w-full ">
                    <div>Title</div>
                    <InputComponent
                      inputValue={terms?.title}
                      setInputValue={(data) =>
                        setTerms((prev) => ({
                          ...prev,
                          title: data,
                        }))
                      }
                      required={true}
                      name="title"
                    />
                  </div>

                  <div className="w-full">
                    <CustomTextEditor
                      details={terms?.description}
                      setDetails={(data) =>
                        setTerms((prev) => ({
                          ...prev,
                          description: data,
                        }))
                      }
                      placeholderText={"Details :"}
                    ></CustomTextEditor>
                  </div>

                  <button
                    onClick={() => handleTncSlag()}
                    className="bg-btn-secondary h-[36px] rounded-[8px] hover:bg-btn-primary active:bg-btn-secondary text-bg-white"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <table className="table-auto min-w-full bg-btn-base">
                <thead className="border-b-[2px] border-bg-semiwhite">
                  <tr className="text-bg-primary text-[12px] xl:text-[16px] md:text-[14px] bg-[#48C9B0]">
                    <th className="p-[12px] h-[74px] md:w-[83px] xl:w-[100px] font-semibold text-bg-primary">
                      ID
                    </th>

                    <th className="p-[12px] min-h-[112px] md:min-w-[125px] xl:min-w-[150px] font-semibold text-bg-primary">
                      User Type
                    </th>
                    <th className="p-[12px] min-h-[75px] md:min-w-[83px] xl:min-w-[100px] font-semibold text-bg-primary">
                      Version
                    </th>

                    <th className="p-[12px] text-left px-[50px] min-h-[230px] md:min-w-[260px] xl:min-w-[300px] font-semibold text-bg-primary">
                      Details
                    </th>
                    <th className="p-[12px] min-h-[160px] md:min-w-[180px] xl:min-w-[200px] font-semibold text-bg-primary">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getAllTNC &&
                    getAllTNC?.map((item, indx) => (
                      <Fragment key={indx}>
                        <tr className="bg-bg-semiwhite text-center text-[12px] xl:text-[16px] md:text-[14px] hover:bg-[#48c9af16]">
                          <td className="py-[20px] h-[120px]">{item?.id}</td>

                          <td className="">{item?.userType}</td>
                          <td className="">{item?.tncVersion}</td>

                          <td className="text-left px-[50px]">
                            <div className="flex flex-col gap-y-[6px]">
                              {item?.clauses?.map((slag, ind) => (
                                <div key={ind}>
                                  <h2 className="text-[14px]">
                                    Title : {slag?.title}
                                  </h2>
                                  <div
                                    className="text-[12px]"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        slag?.description
                                          ?.split(" ")
                                          .slice(0, 20)
                                          .join(" ") +
                                        (slag?.description?.split(" ").length >
                                        20
                                          ? "..."
                                          : ""),
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </td>
                          <td className=" text-center">
                            <div className="flex items-center justify-center">
                              <button
                                className="border px-[4px] flex justify-center items-center cursor-pointer w-[120px] h-[28px] py-[20px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                                onClick={() => {
                                  setModalShwon(true);
                                  handleGetTNCBYID(item?.id);
                                }}
                              >
                                View Details
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr className="h-[32px]"></tr>
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <ModalCommon
          shown={modalShwon}
          width="max-w-[1100px]"
          height="max-h-[650px]"
          outSideOff={false}
          close={() => {
            setModalShwon(false);
          }}
        >
          <div className="p-[30px]">
            <div className="max-h-[600px]  overflow-y-scroll">
              {getAllTNCByID?.clauses?.map((slag, ind) => (
                <div key={ind}>
                  <h2 className="text-[18px]">{slag?.title}</h2>
                  {/* <div
                    className="text-[14px] leading-relaxed"
                    dangerouslySetInnerHTML={{
                      __html: slag?.description,
                    }}
                  /> */}
                  <ReactQuill
                            value={slag?.description}
                            readOnly={true}
                          />
                </div>
              ))}
            </div>
          </div>
        </ModalCommon>
      </div>
    </>
  );
}
