import React from "react";
import { useLocation } from "react-router";
import { UserType } from "../contants/Constants";
import { CATEGORY_APIS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
// import Service from "../utils/axiosWrapper";
function RemoveableImageUploadViewCategory({
  url,
  id,
  setNeedUpdate,
  setUrlTempStorageArray,
  urlTempStorageArray,
  userType,
  fetchSummeryData,
}) {
  const filterDeletedArray = (array) => {
    let tempArr = [];
    tempArr = urlTempStorageArray.filter((item) => item.url !== url);
    setUrlTempStorageArray(tempArr);
    // setNeedUpdate(Math.random());
  };
  const { pathname } = useLocation();
  async function handleRemoveImage() {
    if (id > 0 && fetchSummeryData !== "PENDING") {
      authAxios
        .delete(`${CATEGORY_APIS.REMOVE_IMAGE}${id}?requestId=delete`)
        .then((res) => {
          // setNeedUpdate(Math.random());
          filterDeletedArray();
        });
    } else {
      if (!fetchSummeryData !== "PENDING") {
        filterDeletedArray();
      }
    }
  }
  console.log(urlTempStorageArray, "urlTempStorageArray");
  return (
    <div className="">
      <div className="relative ">
        <div className="">
          <img
            // width={"430"}
            // height={"500"}
            //className = "object-cover w-full h-[100px]"
            className={`object-cover  ${
              pathname.includes("admin/create-blog")
                ? "w-full h-[130px]"
                : pathname.includes("admin/tour-activity-add")
                ? "w-[130px] h-[100px]"
                : "h-[180px] w-[252px]"
            }`}
            src={url ? url.split("?")[0] : ""}
            alt=""
          />
        </div>
        {userType === UserType.USER ? (
          <div className="absolute top-0 right-0 flex flex-col justify-between rounded">
            <div className="flex justify-end py-2 px-2 ">
              <button
                onClick={handleRemoveImage}
                className="border border-btn-pink bg-bg-semiwhite backdrop-blur-[4px] w-[51px] h-[51px] flex items-center justify-center rounded-full"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7049 7.70492L16.2949 6.29492L11.9999 10.5899L7.70492 6.29492L6.29492 7.70492L10.5899 11.9999L6.29492 16.2949L7.70492 17.7049L11.9999 13.4099L16.2949 17.7049L17.7049 16.2949L13.4099 11.9999L17.7049 7.70492Z"
                    fill="#376576"
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RemoveableImageUploadViewCategory;

// function RemoveableImageUploadView() {
//   return (
//     <div>
//       shjdf
//     </div>
//   )
// }

// export default RemoveableImageUploadView
