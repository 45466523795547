import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../../components/common/ModalCommon";
import SingleSelectNew from "../../../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";

export default function TransportMode() {
  const [getTransportationMode, setGetTransportationMode] = useState([]);
  const [transportModes, setTransportModes] = useState([]);

  const [iconsList, setIconsList] = useState([]);

  const [temp, setTemp] = useState("");
  const [transportMode, steTransportMode] = useState({
    description: "",
    mode_name: "",
    mode_icon_url: "",
  });

  const getAllTransportationMode = () => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint?.ADMIN_GET_ALL_TRANSPORTATION_MODE_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_modes)
        setGetTransportationMode(res?.data?.transportation_modes);
      });
  };

  useEffect(() => {
    getAllTransportationMode();
  }, [transportMode]);

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);
  const [modalShown, setToggleModal] = useState(false);
  const handleMeal = () => {
    setTransportModes([...transportModes, transportMode]);
    steTransportMode({ description: "", mode_name: "", mode_icon_url: "" });
    setToggleModal(false);
  };

  const handleMealDelete = (ind) => {
    const newArray = transportModes?.filter((meal, index) => index !== ind);
    setTransportModes(newArray);
  };

  const handleMealAdd = () => {
    let transportModeToSend = transportModes.map((item) => ({
      ...item,
      mode_icon_url: item?.mode_icon_url?.url,
    }));
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_TRANSPORT_MODE_POST_API}`, {
        transportation_modes: transportModeToSend,
        requestId: "string",
      })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Added transport mode successfully");
          setTransportModes([]);
          steTransportMode({
            description: "",
            mode_name: "",
            mode_icon_url: "",
          });
        }
      });
  };
  return (
    <div className="mt-[20px]">
      <p className="text-btn-secondary">Tour Added Mode List</p>
      <div className="flex flex-col gap-y-[15px] w-full max-w-[450px] mt-[12px] max-h-[550px] overflow-y-scroll p-[6px]">
        {getTransportationMode?.map((provider, index) => (
          <div
            key={index}
            className="shadow-customShadow rounded-[4px] p-[10px]"
          >
            <p className="text-[16px]">
              {" "}
              <span className="text-[12px]">
                Transportation Mode Name :
              </span>{" "}
              {provider?.transportation_mode_name}
            </p>
          </div>
        ))}
      </div>

      <div className="max-w-[450px] mx-auto mt-[30px] shadow-customShadow rounded-[8px] p-[12px] fixed top-[90px] right-[30px] w-full">
        <h2 className="text-[22px] text-center underline">
          Transport Mode Add
        </h2>
        <div className="flex flex-col gap-y-[10px] ">
          {transportModes?.map((meal, index) => (
            <div
              key={index}
              className="flex justify-between gap-x-[20px] relative  border rounded-[8px] border-txt-hint text-txt-primary p-[4px]"
            >
              {" "}
              <div className="flex flex-col gap-y-[7px]">
                <p className="">
                  <span className="text-[12px]">Mode Name:</span>{" "}
                  {meal?.mode_name}
                </p>
                <p className="">
                  {" "}
                  <span className="text-[12px]">Description:</span>{" "}
                  {meal?.description}
                </p>
              </div>
              <span
                onClick={() => handleMealDelete(index)}
                className=" cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          ))}
        </div>
        {transportModes?.length > 0 && (
          <div className="flex justify-end w-full">
            <button
              onClick={() => setToggleModal(true)}
              className="w-[150px] bg-btn-secondary h-[30px] mt-[10px] text-bg-white rounded-[8px] "
            >
              Add New
            </button>
          </div>
        )}
        {transportModes?.length > 0 ? (
          <button
            onClick={() => handleMealAdd()}
            className="h-[40px] bg-btn-secondary w-full mt-[40px] text-bg-white rounded-[8px]"
          >
            Submit Transport Mode
          </button>
        ) : (
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      mode_name: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportMode.mode_name}
                />
                <span className="placeholder">Mode Name :</span>
              </label>
            </div>

            <div className=" pt-[10px]">
              <p className="pb-[5px]">Mode Icon:</p>

              <div className="flex justify-start items-center gap-x-[8px]">
                <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                  <img
                    className="w-[40px] h-[40px]"
                    src={transportMode?.mode_icon_url?.url}
                    alt=""
                  />
                </div>

                <SingleSelectNew
                  warningView={false}
                  data={iconsList}
                  displayField="title"
                  placeholder={"Icons"}
                  selectedFieldName={"title"}
                  selectedValue={transportMode?.mode_icon_url?.url}
                  setSelectedValue={(mode_icon_url) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      mode_icon_url: mode_icon_url,
                    }))
                  }
                  setSelectedObject={() => {}}
                  image={true}
                  isReadOnly={false}
                  temp={temp}
                  setTemp={setTemp}
                />
              </div>
            </div>

            <div className="w-full">
              <label className="custom-field one w-full">
                <textarea
                  onChange={(e) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="input w-full min-h-[150px] max-h-[200px]"
                  type="text"
                  placeholder=" "
                ></textarea>
                <span className="placeholder">Description :</span>
              </label>
            </div>
            <button
              disabled={
                transportMode?.description?.length === 0 ||
                transportMode?.mode_name?.length === 0 ||
                transportMode?.mode_icon_url?.id === undefined
              }
              onClick={() => handleMeal()}
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        )}
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={false}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]">
          <div>
            <div className="mt-[10px]">
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      mode_name: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={transportMode.mode_name}
                />
                <span className="placeholder">Mode Name :</span>
              </label>
            </div>
            <div className=" pt-[10px]">
              <p className="pb-[5px]">Mode Icon:</p>

              <div className="flex justify-start items-center gap-x-[8px]">
                <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                  <img
                    className="w-[40px] h-[40px]"
                    src={transportMode?.mode_icon_url?.url}
                    alt=""
                  />
                </div>

                <SingleSelectNew
                  warningView={false}
                  data={iconsList}
                  displayField="title"
                  placeholder={"Icons"}
                  selectedFieldName={"title"}
                  selectedValue={transportMode?.mode_icon_url?.url}
                  setSelectedValue={(mode_icon_url) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      mode_icon_url: mode_icon_url,
                    }))
                  }
                  setSelectedObject={() => {}}
                  image={true}
                  isReadOnly={false}
                  temp={temp}
                  setTemp={setTemp}
                />
              </div>
            </div>

            <div className="w-full">
              <label className="custom-field one w-full">
                <textarea
                  onChange={(e) =>
                    steTransportMode((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="input w-full min-h-[150px] max-h-[200px]"
                  type="text"
                  placeholder=" "
                ></textarea>
                <span className="placeholder">Description :</span>
              </label>
            </div>
            <button
              onClick={() => handleMeal()}
              disabled={
                transportMode?.description?.length === 0 ||
                transportMode?.mode_name?.length === 0 ||
                transportMode?.mode_icon_url?.id === undefined
              }
              className="h-[49px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[20px] disabled:bg-bg-semiblack"
            >
              Submit
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
}
