import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import SingleSelectNew from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import {
  animatedComponents,
  colorStyles,
} from "../../../ToursMarchent/Utilities/SelectAnimation";
import { authAxios } from "../../../utils/axiosWrapper";

export default function TourCreatedDashboard() {
  const [openSpeciality, setOpenSpeciality] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [createTours, setCreateTours] = useState([]);

  const [temp, setTemp] = useState("");
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [specialityAdd, setSpecialityAdd] = useState({
    tour_speciality_description: "",
    tour_speciality_title: "",
    tour_speciality_icon_url: null,
  });

  const [tourCreateData, setTourCreateData] = useState({
    requestId: "tourCreateData",
    thumb_image_url: "",
    tour_activity_ids: [],
    tour_description: "",
    added_tour_id: 0,
    tour_specialities: [],
    tour_title: "",
  });

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ADMIN_GET_TOURS_GET_API}`)
      .then((res) => {
        setCreateTours(res?.data?.tours);
      })
      .catch((err) => {});
  }, []);

  const handleSpeciality = () => {
    let activitiesToSend = {
      ...specialityAdd,
      tour_speciality_icon_url: specialityAdd?.tour_speciality_icon_url?.url,
    };

    setTourCreateData((prev) => ({
      ...prev,
      tour_specialities: [...prev.tour_specialities, activitiesToSend],
    }));

    setSpecialityAdd({
      tour_speciality_description: "",
      tour_speciality_title: "",
      tour_speciality_icon_url: "",
    });
    setTemp("");
    setOpenSpeciality(false);
  };
  const [activitys, setAactivities] = useState([]);
  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ADMIN_GET_TOURS_ACTIVITY_GET_API}`)
      .then((res) => {
        setAactivities(res?.data?.activities);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);
  function simplifyPayload() {
    tourCreateData.tour_activity_ids = tourCreateData.tour_activity_ids.map(
      (activity) => activity?.value
    );
    tourCreateData.added_tour_id = tourCreateData.added_tour_id?.value;
    tourCreateData.thumb_image_url = urlTempStorageArray[0]?.url;
  }

  const handleTourCreate = () => {
    simplifyPayload();
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_ADD_TOUR_CREATE_VERY_EXCITED_TIME}`,
        {
          ...tourCreateData,
        }
      )
      .then((response) => {
        if (response?.status === 201) {
          toast.success("Tour created successfully 😇");
          setTourCreateData({
            requestId: "tourCreateData",
            thumb_image_url: "",
            tour_activity_ids: [],
            tour_description: "",
            added_tour_id: 0,
            tour_specialities: [],
            tour_title: "",
          });
          setUrlTempStorageArray([]);
          setSpecialityAdd({
            tour_speciality_description: "",
            tour_speciality_title: "",
            tour_speciality_icon_url: "",
          });
        }
      });
  };

  const handleSpecialityDelete = (ind) => {
    const newArray = tourCreateData.tour_specialities?.filter(
      (meal, index) => index !== ind
    );
    setTourCreateData((prev) => ({
      ...prev,
      tour_specialities: newArray,
    }));
  };

  const handleActivityRemove = (ind) => {
    const newArray = tourCreateData.tour_activity_ids?.filter(
      (meal, index) => index !== ind
    );
    setTourCreateData((prev) => ({
      ...prev,
      tour_activity_ids: newArray,
    }));
  };
  const transformedArray = createTours?.map(
    ({ added_tour_id, added_tour_name }) => ({
      label: added_tour_name + " " + "(" + `${added_tour_id}` + ")",
      value: added_tour_id,
    })
  );
  const transformedActivites = activitys?.map(
    ({ activity_id, activity_name }) => ({
      label: activity_name,
      value: activity_id,
    })
  );

  console.log("createTours", createTours);
  return (
    <div>
      <h2 className="text-[22px] mt-[20px] text-center underline">
        Tour Create
      </h2>
      <div className="max-w-[900px] mx-auto pb-[20px]">
        <div className=" mt-[30px] shadow-customShadow rounded-[8px] p-[12px] flex justify-between gap-x-[50px]">
          <div className="w-full">
            {/* <CustomInputWithDropdown
              selectedOption={tourCreateData?.added_tour_id?.label}
              setSelectedOption={(added_tour_id) =>
                setTourCreateData((prev) => ({
                  ...prev,
                  added_tour_id: added_tour_id,
                }))
              }
              labelText={"Tour Name :"}
              options={transformedArray}
            /> */}
            <Select
              value={tourCreateData?.added_tour_id}
              onChange={(added_tour_id) =>
                setTourCreateData((prev) => ({
                  ...prev,
                  added_tour_id: added_tour_id,
                }))
              }
              components={animatedComponents}
              options={transformedArray}
              placeholder={"Tour Name :"}
              styles={colorStyles}
            />
            <div>
              <label className="custom-field one w-full">
                <input
                  onChange={(e) =>
                    setTourCreateData((prev) => ({
                      ...prev,
                      tour_title: e.target.value,
                    }))
                  }
                  className="input w-full"
                  type="text"
                  placeholder=" "
                  value={tourCreateData?.tour_title}
                />
                <span className="placeholder">Title :</span>
              </label>
            </div>

            <div className="w-full">
              <label className="custom-field one w-full">
                <textarea
                  onChange={(e) =>
                    setTourCreateData((prev) => ({
                      ...prev,
                      tour_description: e.target.value,
                    }))
                  }
                  className="input w-full min-h-[150px] max-h-[200px]"
                  type="text"
                  placeholder=" "
                  value={tourCreateData?.tour_description}
                ></textarea>
                <span className="placeholder">Description :</span>
              </label>
            </div>

            <div className="flex justify-between gap-x-[10px] mt-[10px] max-w-[400px] mx-auto">
              <div className="mb-10  w-[200px]">
                <FileMultipleCategory
                  width={"w-full"}
                  id={1}
                  setNeedUpdate={() => {}}
                  urlTempStorageArray={urlTempStorageArray}
                  setUrlTempStorageArray={setUrlTempStorageArray}
                  multiple={false}
                  setIsLoading={setIsLoading}
                />
              </div>
              {isLoading ? (
                <div
                  className={`flex items-center justify-center  w-2/5 ${
                    urlTempStorageArray.length ? "h-[245px]" : ""
                  }`}
                >
                  <p>Loading...</p>
                </div>
              ) : (
                <div className="w-[200px] ">
                  <img
                    className={`rounded-lg w-full ${
                      urlTempStorageArray.length ? "h-[170px]" : ""
                    }`}
                    src={urlTempStorageArray[0]?.url || ""}
                    alt=""
                  />

                  <div className="text-txt-primary text-center mt-[10px] text-[12px]">
                    Image File:{" "}
                    {urlTempStorageArray.length > 0
                      ? urlTempStorageArray[urlTempStorageArray.length - 1]
                          .fileName
                      : `No image`}
                  </div>
                </div>
              )}
            </div>

            <div className="border border-[#ddd] p-[8px] rounded-[4px] mt-[10px]">
              <h2 className="text-[16px] text-center underline">
                Tour Specialities
              </h2>

              <div className="flex flex-col gap-y-[10px]  mt-[10px]">
                {tourCreateData.tour_specialities?.map((spclty, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-y-[4px] shadow-customShadow p-[10px] rounded-[8px] relative"
                  >
                    <p className="text-[16px]">
                      {spclty?.tour_speciality_title}
                    </p>
                    <img
                      src={spclty?.tour_speciality_icon_url}
                      alt=""
                      className="w-[25px] h-[25px]"
                    />
                    <p className="text-[12px]">
                      {spclty?.tour_speciality_description}
                    </p>

                    <span
                      onClick={() => handleSpecialityDelete(index)}
                      className=" cursor-pointer absolute right-[-8px] top-[-10px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[25px] h-[25px] text-[#EC7063]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
              <button
                className="w-[200px] bg-btn-secondary text-bg-white py-[4px] rounded-[4px] mt-[10px] mx-auto justify-center flex"
                onClick={() => {
                  setOpenSpeciality(!openSpeciality);
                }}
              >
                Add New
              </button>
            </div>
          </div>
          <div className="w-full">
            <div className="border border-[#ddd]  rounded-[4px] mt-[12px] p-[8px]">
              <h2 className="text-center text-[16px] underline mb-[6px]">
                Tour Activities
              </h2>

              <div className="flex flex-col gap-y-[10px] mb-[6px]">
                {tourCreateData.tour_activity_ids?.map((activ, index) => (
                  <div
                    key={index}
                    className="flex justify-start items-center gap-x-[4px] border rounded-[4px] p-[2px] border-[#ddd] relative"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                      />
                    </svg>
                    <p>{activ?.label}</p>

                    <span
                      onClick={() => handleActivityRemove(index)}
                      className="absolute right-[-7px] top-[-10px] border rounded-full border-[#F1948A] w-[20px] h-[20px] flex justify-center items-center cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-[20px] h-[20px] text-[#F1948A]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>

              {/* <CustomInputWithDropdown
                selectedOption={tourCreateData?.[0]?.activity_id}
                setSelectedOption={(activity_id) => {
                  setTourCreateData((prevState) => ({
                    ...prevState,
                    tour_activity_ids: [
                      ...prevState.tour_activity_ids,
                      activity_id,
                    ],
                  }));
                }}
                labelText={"Activity Name :"}
                options={transformedActivites}
              /> */}
              <Select
                value={tourCreateData?.[0]?.activity_id}
                onChange={(activity_id) =>
                  setTourCreateData((prevState) => ({
                    ...prevState,
                    tour_activity_ids: [
                      ...prevState.tour_activity_ids,
                      activity_id,
                    ],
                  }))
                }
                components={animatedComponents}
                options={transformedActivites}
                placeholder={"Activity Name  :"}
                styles={colorStyles}
              />
            </div>
          </div>
        </div>

        <button
          disabled={
            tourCreateData?.added_tour_id?.value === undefined ||
            tourCreateData?.tour_title?.length === 0 ||
            tourCreateData?.tour_description.length === 0 ||
            urlTempStorageArray?.length === 0 ||
            tourCreateData?.tour_specialities?.length === 0 ||
            tourCreateData?.tour_activity_ids?.length === 0
          }
          onClick={() => handleTourCreate()}
          className="bg-btn-primary w-full h-[49px] text-bg-white rounded-[4px] mt-[30px] disabled:bg-bg-semiblack"
        >
          Submit
        </button>
      </div>
      {/* dummy */}
      {/* <ModalCommon
        shown={openActivites}
        width="max-w-[470px]"
        height="min-h-[380px]"
        close={() => {
          setOpenActivities(false);
        }}
      >
        {openActivites && (
          <div className="flex flex-col gap-[8px] p-[20px] mt-[15px] border-[#ddd]">
            <CustomInputWithDropdown
              selectedOption={activitesAdd?.day_number}
              setSelectedOption={(day_number) => {
                setActivitesAdds((prev) => ({
                  ...prev,
                  day_number: day_number,
                }));
              }}
              labelText={"Select Day :"}
              options={[1, 2, 3]}
            />

            <CustomInputWithDropdown
              selectedOption={activitesAdd?.activity_id}
              setSelectedOption={(activity_id) => {
                setActivitesAdds((prev) => ({
                  ...prev,
                  activity_id: activity_id,
                }));
              }}
              labelText={"Activity Name :"}
              options={["Option 1", "Option 2", "Option 3"]}
            />
            <TimePicker
              required={true}
              selectedData={activitesAdd.start_time}
              setSelectedDate={(start_time) => {
                setActivitesAdds((prev) => ({
                  ...prev,
                  start_time: start_time,
                }));
              }}
              placeholder="Start Time"
              isAdmin={
                process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                  ? false
                  : false
              }
              isReadOnly={false}
              rejectionArray={propertyRejectArray}
              setRejectionArray={setPropertyRejectArray}
              name="checkinTime"
            />
            <TimePicker
              required={true}
              selectedData={activitesAdd.end_time}
              setSelectedDate={(end_time) => {
                setActivitesAdds((prev) => ({
                  ...prev,
                  end_time: end_time,
                }));
              }}
              placeholder="End Time"
              isAdmin={
                process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                  ? false
                  : false
              }
              isReadOnly={false}
              rejectionArray={propertyRejectArray}
              setRejectionArray={setPropertyRejectArray}
              name="checkoutTime"
            />

            <button
              onClick={() => handleActivityAdd()}
              className="bg-btn-primary w-full h-[49px] text-bg-white rounded-[4px] mt-[10px] "
            >
              Submit
            </button>
          </div>
        )}
      </ModalCommon> */}

      <ModalCommon
        shown={openSpeciality}
        width="max-w-[470px]"
        outSideOff={false}
        height="min-h-[380px]"
        close={() => {
          setOpenSpeciality(false);
        }}
      >
        <div className="flex flex-col gap-[8px] p-[20px] mt-[15px] border-[#ddd]">
          <div>
            <label className="custom-field one w-full">
              <input
                onChange={(e) =>
                  setSpecialityAdd((prev) => ({
                    ...prev,
                    tour_speciality_title: e.target.value,
                  }))
                }
                className="input w-full"
                type="text"
                placeholder=" "
                value={specialityAdd?.tour_speciality_title}
              />
              <span className="placeholder">Tour Title :</span>
            </label>
          </div>

          <div className="w-full">
            <label className="custom-field one w-full">
              <textarea
                onChange={(e) =>
                  setSpecialityAdd((prev) => ({
                    ...prev,
                    tour_speciality_description: e.target.value,
                  }))
                }
                className="input w-full min-h-[150px] max-h-[200px]"
                type="text"
                placeholder=" "
                value={specialityAdd?.tour_speciality_description}
              ></textarea>
              <span className="placeholder">Description :</span>
            </label>
          </div>
          <div className=" pt-[10px]">
            <p className="pb-[5px]">Speciality Icon:</p>

            <div className="flex justify-start items-center gap-x-[8px]">
              <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                <img
                  className="w-[40px] h-[40px]"
                  src={specialityAdd?.mode_icon_url?.url}
                  alt=""
                />
              </div>

              <SingleSelectNew
                warningView={false}
                data={iconsList}
                displayField="title"
                placeholder={"Icons"}
                selectedFieldName={"title"}
                // selectedValue={specialityAdd?.mode_icon_url || ""}
                setSelectedValue={(tour_speciality_icon_url) =>
                  setSpecialityAdd((prev) => ({
                    ...prev,
                    tour_speciality_icon_url: tour_speciality_icon_url,
                  }))
                }
                setSelectedObject={() => {}}
                image={true}
                isReadOnly={false}
                temp={temp}
                setTemp={setTemp}
              />
            </div>
          </div>
          <button
            onClick={() => handleSpeciality()}
            disabled={
              specialityAdd?.tour_speciality_description.length === 0 ||
              specialityAdd?.tour_speciality_title.length === 0 ||
              specialityAdd?.tour_speciality_icon_url?.id === undefined
            }
            className="bg-btn-primary h-[40px] text-bg-white rounded-[4px] w-full mt-[16px] disabled:bg-bg-semiblack"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
}
