import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { apiEndPoint } from "../contants/Endpoints";
import { signIn } from "../store/user/api";
import { authAxios } from "../utils/axiosWrapper";
import { noAuthAxios } from "../utils/axiosWrapperOpen";

const Terms = () => {
  const [tnc, setTnc] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signinReducer?.data);

  useEffect(() => {
    noAuthAxios
      .get(
        `${apiEndPoint.FETCH_TERMS_AND_CONDITIONS}?userType=${
          process.env.REACT_APP_USER_TYPE === "USER" ? "MERCHANT" : "ADMIN"
        }&requestId=string`
      )
      .then((res) => {
        setTnc(res.data.termsAndConditionData);
      })
      .catch((err) => {});
  }, []);

  const acceptTnc = () => {
    authAxios
      .post(`${apiEndPoint.ACCEPT_TERMS_AND_CONDITIONS}`, {
        tncVersion: tnc.tncVersion,
        requestId: "string",
      })
      .then((res) => {
        dispatch(
          signIn(user.data, () => {
            window.location.href = "/";
          })
        );
      })
      .catch((err) => {});
  };
  return (
    <div className=" bg-bg-primary">
      <div className="h-[calc(100vh-100px)] min-w-[375px] md:max-w-[712px] xl:max-w-[1024px] px-[20px] flex justify-center items-center flex-col mx-auto">
        <div className="pb-[4rem] max-h-[calc(100vh-200px)] overflow-y-scroll flex flex-col gap-y-[6px] ">
          {tnc?.clauses?.map((slag, ind) => (
            <div key={ind}>
              <h2 className="text-base14 md:text-base18 font-bold mb-[4px]">
                {slag?.title}
              </h2>
              <div
                className="leading-relaxed md:text-base16 text-base14n text-txt-primary_light"
                dangerouslySetInnerHTML={{ __html: slag.description }}
              />
            </div>
          ))}
        </div>
        <div
          onClick={() => {
            acceptTnc();
          }}
          className="w-[180px] flex items-center justify-center rounded-[8px] cursor-pointer text-bg-primary bg-btn-primary hover:bg-btn-secondary h-[39px]"
        >
          <span>Accept & Procced</span>
        </div>
      </div>
    </div>
  );
};

export default Terms;
