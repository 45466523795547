import { SummeryStatus } from "../contants/Constants";

export const reviewSubmitDisableDecision = (status, fetchSummeryStatus) => {
  {
    /* for new tnc update */
  }
  // if(status == SummeryStatus.OPEN || status == SummeryStatus.EMPTY ||status == SummeryStatus.PENDING){
  //     return "yes"
  // }else{
  //     if(categoryStatus == SummeryStatus.EMPTY || categoryStatus == SummeryStatus.OPEN){
  //         return "yes"
  //     }else{
  //         return "no"
  //     }
  // }
  if (
    status === SummeryStatus.OPEN ||
    status === SummeryStatus.EMPTY ||
    status === SummeryStatus.PENDING
  ) {
    return "yes";
  } else {
    if (
      fetchSummeryStatus.contractInfoStatus ===
        (SummeryStatus.OPEN || SummeryStatus.EMPTY) ||
      fetchSummeryStatus.documentInfoStatus ===
        (SummeryStatus.OPEN || SummeryStatus.EMPTY)
    ) {
      return "yes";
    } else {
      return "no";
    }
  }
};
