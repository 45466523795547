import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import bed_2 from "../../assets/icon/bed_2.svg";
import bus from "../../assets/icon/bus.svg";
import fastFood from "../../assets/icon/fast-food.svg";
import moving from "../../assets/icon/moving.svg";
import { merchantTourRoute } from "../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import combinationPackageStore from "../../store/admin/combinationPackageTour/reducers/tourPackageCombinationReducer";
import tourPackageCreatePayloadReducers from "../../store/admin/combinationPackageTour/reducers/tourPackageCreatePayloadReducers";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";
import AccomodationPackage from "./AccomodationPackage";
import AccomodationPackageInititial from "./AccomodationPackageInititial";
import FoodPackage from "./FoodPackages/FoodPackage";
import FoodPackageInitial from "./FoodPackages/FoodPackageInitial";
import SpotFee from "./SpotFee";
import TourGuide from "./TourGuide";
import TourGuideInitail from "./TourGuideInitail";
import TourSpotFeeInitail from "./TourSpotFeeInitial";
import TransferPackage from "./TransferPackage";
import TransferPackageInitial from "./TransferPackageInitial";
import TransportationPackage from "./TransportationPackage";
import TransportationPackageInitial from "./TransportationPackageInitial";

const CreatePackage = ({
  setPackageSelectCondition,
  marchantTourCreateDatas,
  setMarchantCreateDatas,
  allFoodPackage,
  setAllFoodPackage,
}) => {
  const navigate = useNavigate();

  const [viewPage, setViewPage] = useState(null);
  const [viewPageTransfer, setViewPageTransfer] = useState(null);
  const [viewPageGuide, setViewPageGuide] = useState(null);
  const [viewPageFood, setViewPageFood] = useState(null);
  const [viewPageSpot, setViewPageSpot] = useState(null);

  const payloadToCreate = useSelector(
    (state) => state.tourPackageCreatePayloadReducers?.data
  );
  console.log("marchantTourCreateDatas", marchantTourCreateDatas);
  console.log("payloadToCreate", payloadToCreate);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setMarchantCreateDatas({
  //     number_of_travellers: 5,
  //     tour_package_transfer_options:
  //       payloadToCreate?.tour_package_transfer_options,
  //     tour_package_accommodation_options:
  //       payloadToCreate?.tour_package_accommodation_options,
  //     tour_package_transportation_packages:
  //       payloadToCreate.tour_package_transportation_packages,
  //     tour_package_type_id: convertData.packageId,
  //     tour_package_guide_options: payloadToCreate?.tour_package_guide_options,
  //   });
  // }, []);

  console.log("allFoodPackage", allFoodPackage);

  const packagess = [
    // {
    //   icon: menu,
    //   title: "Package Type",
    //   links: merchantTourRoute.CREATE_TOUR_PACKAGE_TYPE,
    // },
    {
      icon: fastFood,
      title: "Food",
      links: merchantTourRoute.CREATE_TOUR_FOOD_PACKAGE,
    },
    {
      icon: bed_2,
      title: "Accomodation",
      links: merchantTourRoute.CREATE_TOUR_ACCOMODATION_PACKAGE,
    },
    {
      icon: bus,
      title: "Transportation",
      links: merchantTourRoute.CREATE_TOUR_TRANSPORTATION_PACKAGE,
    },
    {
      icon: moving,
      title: "Transfer",
      links: merchantTourRoute.CREATE_TOUR_PACKAGE_TYPE,
    },
    {
      icon: moving,
      title: "Tour Guide",
      links: merchantTourRoute.CREATE_TOUR_TOUR_GUIDE,
    },
    {
      icon: moving,
      title: "Spot Fee",
      links: merchantTourRoute.CREATE_TOUR_TOUR_SPOT_FEE,
    },
  ];

  const [packageSelect, setPackageSelect] = useState(null);

  const handlePackage = (ind, condi) => {
    if (condi === "chose") {
      setPackageSelect(ind);
    } else if (condi === "acco") {
      setViewPage(1);
      setPackageSelect(ind);
    } else if (condi === "trans") {
      setViewPageTransfer(1);
      setPackageSelect(ind);
    } else if (condi === "food") {
      setViewPageFood(1);
      setPackageSelect(ind);
    } else if (condi === "guide") {
      setViewPageGuide(4);
      setPackageSelect(ind);
    } else if (condi === "spot") {
      setViewPageSpot(5);
      setPackageSelect(ind);
    }
  };

  function transformObject(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(transformObject);
    }
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          if ("value" in value) {
            result[key] = keyToNumber(key, value.value);
          } else if (key === "food_items_ids") {
            result[key] = value.map((foodItem) =>
              keyToNumber("food_items_ids", foodItem.value)
            );
          } else {
            result[key] = transformObject(value);
          }
        } else {
          result[key] = keyToNumber(key, value);
        }
      }
    }
    return result;
  }

  function keyToNumber(key, value) {
    const numericFields = [
      "meal_package_unit_price",
      "accommodation_package_unit_price",
      "meal_package_quantity",
      "transportation_package_quantity",
      "transportation_package_unit_price",
      "suitable_for_persons",
      "number_of_beds",
      "accommodation_package_quantity",
    ];

    if (numericFields.includes(key)) {
      return parseFloat(value);
    }

    return value;
  }

  // const convertPayload = (payload) => {
  //   const newPayload = { ...payload };

  //   newPayload.tour_package_accommodation_options.forEach((option) => {
  //     option.tour_package_accommodations_package_list.forEach((item) => {
  //       const fieldsToConvert = [
  //         "number_of_beds",
  //         "per_night_room_price",
  //         "suitable_for_persons",
  //       ];
  //       fieldsToConvert.forEach((field) => {
  //         if (item[field]) {
  //           item[field] = Number(item[field]);
  //         }
  //       });
  //     });
  //   });

  //   newPayload.tour_package_transfer_options.forEach((option) => {
  //     option.tour_package_transfer_packages.forEach((item) => {
  //       const fieldsToConvert = [
  //         "per_vehicle_per_trip_price",
  //         "suitable_for_persons",
  //         "per_vehicle_per_trip_price",
  //         "transportation_package_quantity",
  //         "transportation_package_unit_price",
  //       ];
  //       fieldsToConvert.forEach((field) => {
  //         if (item[field]) {
  //           item[field] = Number(item[field]);
  //         }
  //       });
  //     });
  //   });

  //   newPayload.tour_package_transportation_packages.forEach((item) => {
  //     const fieldsToConvert = [
  //       "transportation_package_quantity",
  //       "transportation_package_unit_price",
  //     ];
  //     fieldsToConvert.forEach((field) => {
  //       if (item[field]) {
  //         item[field] = Number(item[field]);
  //       }
  //     });
  //   });

  //   return newPayload;
  // };

  const b = {
    tour_package_component_combinations: [
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 649.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 666,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                5: "Beff Vhuna",
              },
              per_meal_package_price: 666,
            },
          ],
          tour_package_meal_option_day: 1,
          number_of_meals: 1,
          number_of_breakfast: 1,
          number_of_lunch: 0,
          number_of_dinner: 0,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 327.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: false,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 655,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: true,
          transportation_package_unit_price: 65,
        },
        tour_package_total_option_price_per_person: 1708,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 649.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 56,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                4: "Mutton Curry",
              },
              per_meal_package_price: 56,
            },
          ],
          tour_package_meal_option_day: 2,
          number_of_meals: 1,
          number_of_breakfast: 0,
          number_of_lunch: 1,
          number_of_dinner: 0,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 327.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: false,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 655,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: true,
          transportation_package_unit_price: 65,
        },
        tour_package_total_option_price_per_person: 1098,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 649.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 722,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Breakfast - Package#0",
              meal_type_id: 1,
              meal_type_name: "Breakfast",
              food_items: {
                5: "Beff Vhuna",
              },
              per_meal_package_price: 666,
            },
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                4: "Mutton Curry",
              },
              per_meal_package_price: 56,
            },
          ],
          tour_package_meal_option_day: 3,
          number_of_meals: 2,
          number_of_breakfast: 1,
          number_of_lunch: 1,
          number_of_dinner: 0,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 327.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: false,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 655,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: true,
          transportation_package_unit_price: 65,
        },
        tour_package_total_option_price_per_person: 1764,
      },
      {
        tour_package_accommodation_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 649.5,
          tour_package_accommodation_packages: [
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
            {
              tour_package_accommodation_package_id: null,
              tour_package_accommodation_id: 1,
              tour_package_accommodation_name: "Nilachol",
              tour_package_room_category_id: 1,
              tour_package_room_category_name: "Standerd Couple",
              tour_package_room_type_id: 1,
              tour_package_room_type_name: "Delux Room",
              is_room_shareable: true,
              suitable_for_persons: 2,
              bed_count: 2,
              bed_configuration: "king",
              per_night_room_price: 433,
              night_number: null,
            },
          ],
        },
        tour_package_food_option: {
          tour_package_option_is_active: true,
          tour_package_option_is_default: false,
          tour_package_option_price_per_person: 56,
          meal_packages: [
            {
              meal_package_id: null,
              meal_package_name: "null - Lunch - Package#0",
              meal_type_id: 2,
              meal_type_name: "Lunch",
              food_items: {
                4: "Mutton Curry",
              },
              per_meal_package_price: 56,
            },
          ],
          tour_package_meal_option_day: 4,
          number_of_meals: 1,
          number_of_breakfast: 0,
          number_of_lunch: 1,
          number_of_dinner: 0,
        },
        tour_package_transfer_option: {
          tour_package_option_is_active: null,
          tour_package_option_is_default: true,
          tour_package_option_price_per_person: 327.5,
          tour_package_id: null,
          transfer_option_id: null,
          transfer_packages: [
            {
              tour_package_transfer_package_id: null,
              tour_package_transfer_route: "",
              tour_package_transportation_mode_id: 1,
              tour_package_transportation_mode_name: "Bus",
              tour_package_transportation_provider_id: 1,
              tour_package_transportation_provider_name: "Desh Travel",
              is_ac: false,
              trip_type: "ONE_WAY",
              per_vehicle_per_trip_price: 655,
              suitable_for_persons: 3,
            },
          ],
        },
        tour_package_transportation_package: {
          tour_package_transportation_package_id: null,
          tour_package_transportation_route_id: 1,
          tour_package_transportation_route_name: "Rangamati - Dhaka",
          tour_package_transportation_mode_id: 1,
          tour_package_transportation_mode_name: "Bus",
          tour_package_transportation_brand_id: 2,
          tour_package_transportation_brand_name: "Scanea",
          tour_package_transportation_provider_id: 1,
          tour_package_transportation_provider_name: "Desh Travel",
          trip_type: "One Way",
          is_ac: true,
          transportation_package_unit_price: 65,
        },
        tour_package_total_option_price_per_person: 1098,
      },
    ],
    tour_package_default_component_combination: {
      tour_package_default_accommodation_option: {
        tour_package_option_is_active: true,
        tour_package_option_is_default: true,
        tour_package_option_price_per_person: 649.5,
        tour_package_accommodation_packages: [
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 433,
            night_number: null,
          },
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 433,
            night_number: null,
          },
          {
            tour_package_accommodation_package_id: null,
            tour_package_accommodation_id: 1,
            tour_package_accommodation_name: "Nilachol",
            tour_package_room_category_id: 1,
            tour_package_room_category_name: "Standerd Couple",
            tour_package_room_type_id: 1,
            tour_package_room_type_name: "Delux Room",
            is_room_shareable: true,
            suitable_for_persons: 2,
            bed_count: 2,
            bed_configuration: "king",
            per_night_room_price: 433,
            night_number: null,
          },
        ],
      },
      tour_package_default_food_option: null,
      tour_package_default_transfer_option: {
        tour_package_option_is_active: null,
        tour_package_option_is_default: true,
        tour_package_option_price_per_person: 327.5,
        tour_package_id: null,
        transfer_option_id: null,
        transfer_packages: [
          {
            tour_package_transfer_package_id: null,
            tour_package_transfer_route: "",
            tour_package_transportation_mode_id: 1,
            tour_package_transportation_mode_name: "Bus",
            tour_package_transportation_provider_id: 1,
            tour_package_transportation_provider_name: "Desh Travel",
            is_ac: false,
            trip_type: "ONE_WAY",
            per_vehicle_per_trip_price: 655,
            suitable_for_persons: 3,
          },
        ],
      },
      tour_package_default_option_price_per_person: 977,
    },
  };

  function convertObject(a) {
    const b = {
      requestId: "string",
      subscribed_tour_id: 1,
      tour_packages: a.tour_package_component_combinations.map((item) => ({
        package_total_price: item.tour_package_total_option_price_per_person,
        tour_package_accommodation_options: [
          {
            tour_package_accommodations_package_list:
              item.tour_package_accommodation_option.tour_package_accommodation_packages.map(
                (accommodation) => ({
                  accommodation_id: accommodation.tour_package_accommodation_id,
                  bed_configuration: accommodation.bed_configuration,
                  is_shareable: accommodation.is_room_shareable,
                  nightNumber: accommodation.night_number,
                  number_of_beds: accommodation.bed_count,
                  per_night_room_price: accommodation.per_night_room_price,
                  room_category_id: accommodation.tour_package_room_category_id,
                  room_type_id: accommodation.tour_package_room_type_id,
                  suitable_for_persons: accommodation.suitable_for_persons,
                })
              ),
            tour_package_option_is_active:
              item.tour_package_accommodation_option
                .tour_package_option_is_active,
            tour_package_option_is_default:
              item.tour_package_accommodation_option
                .tour_package_option_is_default,
          },
        ],
        tour_package_description: "tour package description",
        tour_package_food_options:
          item.tour_package_food_option.meal_packages.map((meal) => ({
            day_number:
              item.tour_package_food_option.tour_package_meal_option_day,
            tour_package_meal_packages: [
              {
                food_items_ids: Object.keys(meal.food_items).map(Number),
                meal_package_unit_price: meal.per_meal_package_price,
                meal_type_id: meal.meal_type_id,
              },
            ],
            tour_package_option_is_active:
              item.tour_package_food_option.tour_package_option_is_active,
            tour_package_option_is_default:
              item.tour_package_food_option.tour_package_option_is_default,
          })),
        tour_package_guide_options: {
          number_of_guides: 0,
          tour_guide_price_per_day: 700,
          tour_guide_provided_in_days: [1, 2],
          tour_package_option_is_active: false,
          tour_package_option_is_default: false,
        },
        tour_package_transfer_options: [
          {
            tour_package_option_is_active:
              item.tour_package_transfer_option.tour_package_option_is_active,
            tour_package_option_is_default:
              item.tour_package_transfer_option.tour_package_option_is_default,
            tour_package_transfer_packages:
              item.tour_package_transfer_option.transfer_packages.map(
                (transfer) => ({
                  is_ac: transfer.is_ac,
                  per_vehicle_per_trip_price:
                    transfer.per_vehicle_per_trip_price,
                  suitable_for_persons: transfer.suitable_for_persons,
                  tour_transfer_mode_id:
                    transfer.tour_package_transportation_mode_id,
                  tour_transfer_provider_id:
                    transfer.tour_package_transportation_provider_id,
                  transfer_route: transfer.tour_package_transfer_route,
                  trip_type: transfer.trip_type,
                })
              ),
          },
        ],
        tour_package_transportation_packages: [
          {
            is_ac: item.tour_package_transportation_package.is_ac,
            transportation_brand_id:
              item.tour_package_transportation_package
                .tour_package_transportation_brand_id,
            transportation_mode_id:
              item.tour_package_transportation_package
                .tour_package_transportation_mode_id,
            transportation_package_is_default: true,
            transportation_package_per_unit_price:
              item.tour_package_transportation_package
                .transportation_package_unit_price,
            transportation_provider_id:
              item.tour_package_transportation_package
                .tour_package_transportation_provider_id,
            transportation_route_id:
              item.tour_package_transportation_package
                .tour_package_transportation_route_id,
            trip_type: item.tour_package_transportation_package.trip_type,
          },
        ],
        tour_package_type_id: 1,
      })),
    };

    return b;
  }

  const convertPayload = (myPayload) => {
    const travelars = myPayload.number_of_travellers;
    const tourPackage = myPayload.tour_package_type_id;

    const newFoodOptions = myPayload.tour_package_food_options.map((option) => {
      const newMealPackages = option.tour_package_meal_packages.map((pack) => {
        const newFoodItems = pack.food_items.map((item) => {
          return item.food_item_id;
        });

        return {
          meal_type_id: pack.meal_type_id,
          food_items_ids: newFoodItems,
          meal_package_unit_price: pack.meal_package_unit_price,
        };
      });

      return {
        day_number: option.day_number,
        tour_package_meal_packages: newMealPackages,
        tour_package_option_is_default: option.tour_package_default_food_option,
        tour_package_option_is_active: true,
        // tour_package_food_option_price: option.tour_package_food_option_price,
      };
    });

    const newAccommodationOptions =
      myPayload.tour_package_accommodation_options.map((option) => {
        const newAccommodations =
          option.tour_package_accommodations_package_list.map(
            (accommodation) => {
              return {
                night_number: accommodation.night_number,
                accommodation_id: accommodation.accommodation_id.value,
                room_category_id: accommodation.room_category_id.value,
                room_type_id: accommodation.room_type_id.value,
                suitable_for_persons: Number(
                  accommodation.suitable_for_persons
                ),
                is_shareable: accommodation.is_shareable.value,
                number_of_beds: Number(accommodation.number_of_beds),
                bed_configuration: accommodation.bed_configuration,
                per_night_room_price: Number(
                  accommodation.per_night_room_price
                ),
              };
            }
          );

        return {
          tour_package_accommodations_package_list: newAccommodations,
          tour_package_option_is_active: true,
          tour_package_option_is_default: option.tour_package_option_is_default,
        };
      });

    const newTransferOptions = myPayload.tour_package_transfer_options.map(
      (option) => {
        const newTransferPackages = option.tour_package_transfer_packages.map(
          (pack) => {
            return {
              transfer_route: pack.transfer_route,
              tour_transfer_provider_id: pack.tour_transfer_provider_id.value,
              tour_transfer_mode_id: pack.tour_transfer_mode_id.value,
              trip_type: pack.trip_type.value,
              is_ac: pack.is_ac.value,
              suitable_for_persons: Number(pack.suitable_for_persons),
              per_vehicle_per_trip_price: Number(
                pack.per_vehicle_per_trip_price
              ),
            };
          }
        );

        return {
          tour_package_transfer_packages: newTransferPackages,

          tour_package_option_is_active: true,
          tour_package_option_is_default: option.tour_package_option_is_default,
        };
      }
    );

    const newTransportationPackages =
      myPayload.tour_package_transportation_packages.map((pack) => {
        return {
          transportation_route_id: pack.transportation_route_id.value,
          transportation_provider_id: pack.transportation_provider_id.value,
          transportation_mode_id: pack.transportation_mode_id.value,
          transportation_brand_id: pack.transportation_brand_id.value,
          trip_type: pack.trip_type.value,
          is_ac: pack.is_ac.value,
          transportation_package_per_unit_price: Number(
            pack.transportation_package_unit_price
          ),

          transportation_package_is_default:
            pack.transportation_package_is_default.value,
        };
      });

    // const newGuide = {
    //   tour_package_option_is_active:
    //     myPayload.tour_package_guide_options.tour_package_option_is_active.value,
    //   tour_package_option_is_default:
    //     myPayload.tour_package_guide_options.tour_package_option_is_default,
    //   tour_guide_price_per_day: Number(
    //     myPayload.tour_package_guide_options.tour_guide_price_per_day
    //   ),
    //   number_of_guides: Number(myPayload.tour_package_guide_options.number_of_guides),
    //   tour_guide_provided_in_days:
    //     myPayload.tour_package_guide_options.tour_guide_provided_in_days?.map(
    //       (day) => day.value
    //     ),
    // };
    const newGuide = myPayload.tour_package_guide_options.map((option) => {
      const newGuidePackages = option.tour_package_guide_packages.map(
        (pack) => {
          return {
            tour_guide_price_per_day: Number(pack.tour_guide_price_per_day),
            number_of_guides_for_day: Number(pack.number_of_guides_for_day),
            day_number: pack.day_number?.value,
          };
        }
      );

      return {
        tour_package_guide_packages: newGuidePackages,
      };
    });
    // const newGuide = myPayload.tour_package_guide_options.map((pack) => {
    //   return {
    //     tour_package_option_is_active: pack.tour_package_option_is_active.value,
    //     tour_package_option_is_default: pack.tour_package_option_is_default,
    //     tour_guide_price_per_day: Number(pack.tour_guide_price_per_day),
    //     number_of_guides: Number(pack.number_of_guides),
    //     tour_guide_provided_in_days: pack.tour_guide_provided_in_days?.map(
    //       (day) => day.value
    //     ),
    //   };
    // });
    const spotFee = myPayload.spot_entries.map((pack) => {
      return {
        activity_id: pack.activity_id.id,

        remark: pack.remark,

        spot_entry_price_per_person: Number(pack.spot_entry_price_per_person),
      };
    });

    const newPayload = {
      number_of_travellers: travelars,
      tour_package_type_id: tourPackage,
      tour_package_food_options: newFoodOptions,
      tour_package_accommodation_options: newAccommodationOptions,
      tour_package_transfer_options: newTransferOptions,
      tour_package_transportation_packages: newTransportationPackages,
      tour_package_guide_options: newGuide,
      spot_entries: spotFee,
    };

    return newPayload;
  };

  const handlePackageCreate = async () => {
    const newPayload = await transformObject(marchantTourCreateDatas);
    authAxios
      .post(`${baseUrl + apiEndPoint.MARCHENT_POST_PACKAGE_TOUR_API}`, {
        ...newPayload,
      })
      .then((res) => {
        console.log(res);
        if (res?.status === 201) {
          toast.success("Successfully created package");
          navigate(merchantTourRoute.TOURONE);
        } else {
          toast.success("Something went wrong");
        }
        console.log(res);
      })
      .catch((err) => console.log(err));
    console.log("newPayload", newPayload);
  };

  const handleSubmitCheckOption = () => {
    const convertedPayload = convertPayload(marchantTourCreateDatas);

    console.log("convertedPayload", convertedPayload);
    dispatch(
      tourPackageCreatePayloadReducers({
        type: "RECEIVE_DATA_PAYLOAD",
        payload: convertedPayload,
      })
    );
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.MARCHANT_CHECK_PACKAG_COMBINATION}`,
        convertedPayload
      )
      .then((res) => {
        dispatch(
          combinationPackageStore({
            type: "RECEIVE_DATA",
            payload: res?.data,
          })
        );
        setPackageSelectCondition(false);
        toast.success("Option checked please review!");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className="my-4 ">
      <p
        className={`${
          packageSelect === null ? "block" : "hidden"
        } text-btn-secondary text-center mb-6 xl:text-[20px] md:text-[18px] text-[16px]`}
      >
        Create Package
      </p>

      <div
        className={`shadow-lg ${packageSelect === null ? "block" : "hidden"}`}
      >
        {/* <PackageTypeInitial></PackageTypeInitial> */}
        {packagess?.map((item, index) => (
          <Fragment key={index}>
            {index === 0 &&
            marchantTourCreateDatas?.tour_package_food_options?.length >= 1 ? (
              <FoodPackageInitial
                handlePackage={handlePackage}
                foodPackages={
                  marchantTourCreateDatas?.tour_package_food_options
                }
              ></FoodPackageInitial>
            ) : index === 1 &&
              marchantTourCreateDatas?.tour_package_accommodation_options
                ?.length >= 1 ? (
              <AccomodationPackageInititial
                handlePackage={handlePackage}
                tour_package_accommodation_packages={
                  marchantTourCreateDatas?.tour_package_accommodation_options
                }
              ></AccomodationPackageInititial>
            ) : index === 2 &&
              marchantTourCreateDatas?.tour_package_transportation_packages
                ?.length >= 1 ? (
              <TransportationPackageInitial
                handlePackage={handlePackage}
                tour_package_transportation_packages={
                  marchantTourCreateDatas?.tour_package_transportation_packages
                }
              ></TransportationPackageInitial>
            ) : index === 3 &&
              marchantTourCreateDatas?.tour_package_transfer_options?.length >=
                1 ? (
              <TransferPackageInitial
                handlePackage={handlePackage}
                tour_package_transfer_package={
                  marchantTourCreateDatas?.tour_package_transfer_options
                }
              ></TransferPackageInitial>
            ) : index === 4 &&
              marchantTourCreateDatas?.tour_package_guide_options?.length >=
                1 ? (
              <TourGuideInitail
                handlePackage={handlePackage}
                tour_package_guide_options={
                  marchantTourCreateDatas?.tour_package_guide_options
                }
              ></TourGuideInitail>
            ) : index === 5 &&
              marchantTourCreateDatas?.spot_entries?.length >= 1 ? (
              <TourSpotFeeInitail
                handlePackage={handlePackage}
                spot_entries={marchantTourCreateDatas?.spot_entries}
              ></TourSpotFeeInitail>
            ) : (
              <>
                <div className="w-full flex xl:p-[12px] md:p-[8px] p-[4px]   bg-bg-white rounded">
                  <div className="lg:w-1/12 w-2/12  flex items-center justify-center ">
                    <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee] rounded">
                      <img
                        className="xl:w-[60px] md:w-[40px] w-[32px] xl:h-[60px] md:h-[40px] h-[32px]"
                        src={item.icon}
                        alt="ghuddy"
                      />
                    </span>
                  </div>

                  <div className="w-9/12 xl:px-[32px] lg:px-[24px] md:px-[16px] px-[8px] flex items-center  xl:text-[18px] lg:text-[16px] md:text-[14px] text-[12px]">
                    <span className="bg-btn-secondary text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] rounded">
                      {item.title}
                    </span>
                  </div>

                  <div className="w-2/12 flex items-center justify-end  ">
                    <TourMerchantButton
                      onClick={() => handlePackage(index, "chose")}
                      name={"Chose"}
                    ></TourMerchantButton>
                  </div>
                </div>
                <div
                  className={`${
                    packagess.length - 1 === index
                      ? ""
                      : "xl:h-[32px] md:h-[24px] h-[16px]"
                  }`}
                ></div>
              </>
            )}
          </Fragment>
        ))}
      </div>
      {
        // packageSelect === 0 ? (
        //   <>
        //     {" "}
        //     <PackageType
        //       setPackageSelect={setPackageSelect}
        //       setMarchantCreateData={setMarchantCreateData}
        //       marchantTourCreateData={marchantTourCreateData}
        //     />{" "}
        //   </>
        // ) :

        packageSelect === 0 ? (
          <>
            {" "}
            <FoodPackage
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
              viewPageFood={viewPageFood}
              setViewPageFood={setViewPageFood}
              allFoodPackage={allFoodPackage}
              setAllFoodPackage={setAllFoodPackage}
            />{" "}
          </>
        ) : packageSelect === 1 ? (
          <>
            {" "}
            <AccomodationPackage
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
              setViewPage={setViewPage}
              viewPage={viewPage}
            />{" "}
          </>
        ) : packageSelect === 2 ? (
          <>
            {" "}
            <TransportationPackage
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
            />{" "}
          </>
        ) : packageSelect === 3 ? (
          <>
            {" "}
            <TransferPackage
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
              setViewPage={setViewPageTransfer}
              viewPage={viewPageTransfer}
            />{" "}
          </>
        ) : packageSelect === 4 ? (
          <>
            {" "}
            <TourGuide
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
              setViewPage={setViewPageGuide}
              viewPage={viewPageGuide}
            />{" "}
          </>
        ) : packageSelect === 5 ? (
          <>
            <SpotFee
              setMarchantCreateDatas={setMarchantCreateDatas}
              setPackageSelect={setPackageSelect}
              marchantTourCreateDatas={marchantTourCreateDatas}
              setViewPage={setViewPageSpot}
              viewPage={viewPageSpot}
            />
          </>
        ) : null
      }

      {marchantTourCreateDatas?.tour_package_transfer_options?.length > 0 &&
      packageSelect === null ? (
        <button
          className="bg-btn-secondary flex justify-end  px-[40px] py-[8px] ml-auto mt-[30px] text-bg-white rounded-[4px] active:bg-bg-white active:border active:border-btn-secondary active:text-btn-secondary hover:bg-btn-primary "
          // onClick={() => setPackageSelectCondition(false)}
          onClick={() => handleSubmitCheckOption()}
        >
          Check Package
        </button>
      ) : null}
    </div>
  );
};

export default CreatePackage;
