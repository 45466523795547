export const baseUrl = process.env.REACT_APP_BASE_API;
export const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API;

export const apiEndPoint = {
  CREATE_OWNER: "/user/owner/",
  CREATE_OWNERV2: "/merchant/owner/info/upsert",
  CREATE_CONTRACT: "contract/addinfo/",
  CREATE_CONTRACTV2: "merchant/contract/info/update",
  EDIT_CONTRACT: "contract/updateinfo/",
  // CREATE_HOTEL_PROPERTY: "admin/controller/rentalproperty/",
  CREATE_HOTEL_PROPERTY: "merchant/property",
  CREATE_CATEGORY: "merchant/rental-property/room-category",
  //CREATE_CATEGORY: "/admin/controller/rental-property/room-category/create",
  SUBMIT_FOR_REVIEW: "contract/submit/",
  SUBMIT_FOR_REVIEWV2: "merchant/contract/submit/",
  GET_COUNTRY_DETAILS: "open/v1/frontendcontentcontroller/fetchallcountry",
  GET_COUNTRY_BY_COUNTRY_CODE_AND_ZIP_CODE:
    "open/v1/frontendcontentcontroller/fetch-location",
  SIGNUP: "open/firebase/sign-up",
  SIGNIN: "open/sign-in/",
  SIGN_UP_IN: "open/firebase/sign-up-and-in",
  CHECK_USER: "open/firebase/user-exists-by-phone-number",
  OTP_RESEND: "open/otp/generate/",
  CHECK_OTP_IS_VALID: "open/otp/check",
  //RENTAL_PROPERTY_IMAGE_UPLOAD: "admin/controller/rentalproperty/images/add",
  RENTAL_PROPERTY_IMAGE_UPLOAD: "merchant/property/images/add",
  RENTAL_PROPERTY_IMAGE_DELETE: "merchant/property/image/delete/",
  FETCH_SUMMERY: "contract/fetchsummary/byuserid/",
  FETCH_SUMMERYV2:
    "merchant/contract/check/active/contract?requestId=fetch-acitivity",
  POST_DOCUMENT_LIST_ADD: "merchant/contract/document/list/add",
  ADMIN_CONTRACT: "admin/contract/",
  ADMIN_CREATE_USER: "admin/createUser/",
  ADMIN_PAYMENTS: "admin/payment",
  ADMIN_BOOKINGS: "admin/booking/contract",
  ADMIN_BOOKINGS_BY_USER_ID: "admin/booking/user",
  ADMIN_GET_NON_EXPRESS_BOOKINGS: "admin/express-booking",
  ADMIN_DIRECT_BOOKINGS: "admin/direct-booking",
  ADMIN_DIRECT_BOOKING_MERGE: "admin/booking/direct",
  ADMIN_REFUNDS: "admin/booking/contract/refund",
  ADMIN_PAYMENT_VERIFY: "admin/payment/",
  ADMIN_BOOKING_PAYMENT_STATUS: "admin/booking/payment-status/",
  ADMIN_BOOKING_INVOICE_STATUS: "admin/booking/invoice-status/",
  ADMIN_BOOKING_REFUND_STATUS: "admin/booking/refund-status/",
  ADMIN_ADD_LOCATION: "admin/destination-location/update",
  ADMIN_LOCATION_INDEXING:
    "admin/destination-location/index-all?requestId=indexing",
  ADMIN_DELETE_INSPIRED: "admin/inspired-location",
  ADMIN_SEND_EMAIL: "admin/email",
  ADMIN_SEND_EMAIL_HOTEL_LIST: "admin/email/hotel-list",
  ADMIN_SEND_EMAIL_PAYMENT_LINK: "admin/email/payment-link",
  ADMIN_GET_INSPIRED:
    "open/rental-property/all-destination-locations?requestId=22",
  ADMIN_GET_INSPIREDV2: "merchant/destination/location/get/all?requestId=wer",
  ADMIN_GET_DESTINATION: "open/destination-location/get-all/paginated?",
  CATEGORY_IMAGE_UPLOAD: "merchant/rental-property/room-category/images/add",
  //CATEGORY_IMAGE_UPLOAD: "admin/controller/rental-property/room-category/image/add",
  ADMIN_SET_TOP_HOTEL: "admin/activate?",
  ADMIN_REMOVE_TOP_HOTEL: "admin/deactivate?",
  CATEGORY_FETCH_BY_ID: "open/controller/roomcategory/fetchbyid/",

  ADMIN_GET_CATEGORY_BY_PROPERTY:
    "admin/controller/rental-property/room-category/fetch-all-by-property-id/",
  MERCHANT_GET_CATEGORY_BY_PROPERTY:
    "merchant/controller/rental-property/room-category/fetch-all-by-property-id/",
  ADMIN_GET_PAYMENT_LINK: "admin/booking/direct/confirm",
  ADMIN_BOOKING_CONFIRM: "booking/confirm",
  ADMIN_ICON_POST: "admin/icon",
  ADMIN_ICON_EDIT: "admin/icon/",
  ADMIN_GET_FACILITY_TYPE: "admin/facility/drop-downs",
  ADMIN_GET_FACILITY_ALL: "admin/facility",
  ADMIN_BILL_CALCULATE: "admin/availability/category",
  ADMIN_SET_AVAILABILITY: "admin/availability/category/",
  ADMIN_GET_AVAILABILITY: "admin/availability/category/",
  ADMIN_CREATE_DEAL: `admin/deal/property`,
  ADMIN_GET_DEAL_BY_PROPERTY: `admin/deal/property`,
  ADMIN_DELETE_DEAL: `admin/deal/property`,
  ADMIN_SYNC_HOTEL: `admin/availability/property/`,
  ADMIN_CREATE_TOUR: `admin/tour`,
  ADMIN_GET_TOUR: `admin/tour`,
  ADMIN_GET_ALL_DISCOUNT: `admin/discount`,
  ADMIN_CREATE_DISCOUNT: `admin/discount`,
  ADMIN_GET_ALL_ACTIVE_DISCOUNT: `admin/discount/active`,
  ADMIN_BOOKING_DAHSBOARD: `admin/booking/dashboard`,
  ADMIN_GET_TOUR_BOOKINGS: `admin/tour/booking`,
  ADMIN_CREATE_TOUR_PACKAGE: `admin/tour/category`,
  ADMIN_ADD_TOUR_SERVICE: `admin/tour/service/booking`,
  ADMIN_GET_INCOMPLETE_ALL_HOTEL:
    "admin/property/incomplete-rental-properties?",
  ADMIN_PUT_LIVE_HOTEL: "admin/property/bring-live/",

  //coupons api
  ADMIN_GET_SEARCH_COUPONS: `admin/coupon/get/all/paginated`,
  ADMIN_UPDATE_COUPONS: `admin/coupon/update`,
  ADMIN_ACTIVATE_COUPONS: `admin/coupon/activate`,
  ADMIN_DEACTIVATE_COUPONS: `admin/coupon/deactivate`,

  ADMIN_ADD_MANUAL_BOOKING: `admin/manual-booking/create`,
  ADMIN_ALL_INVOICE_RECORDS: `admin/manual-booking/get-all`,
  ADMIN_GET_INVOICE_BY_PHONE: `admin/manual-booking/invoice/search/phone-no`,
  ADMIN_GET_SINGLE_INVOICE_DETAILS: `admin/manual-booking/invoice`,
  ADMIN_GET_PAYMENT_METHOD_TYPE: `admin/manual-booking/payment-method/types/get-all?requestId=11`,
  ADMIN_CREATE_PAYMENT_METHOD: `admin/manual-booking/payment-method/create`,
  ADMIN_GET_ALL_PAYMENT_METHOD: `admin/manual-booking/payment-method/get-all?requestId=11`,
  ADMIN_CREATE_PAYMENT_SLIP: `admin/manual-booking/payment/create`,
  ADMIN_GET_ALL_UNVERIFIED_PAYMENTS: `admin/manual-booking/invoice/payment/fetchall/unverified`,
  ADMIN_VERIFY_PAYMENT_BY_ID: `admin/manual-booking/payment/verify`,
  ICON_LIST: "open/user/icon",
  ICON_SEARCH_LIST: "open/user/icon/search/",

  //availability controller
  ADMIN_SET_PRICE_FOR_CATEGORY: "admin/price-availability/category/set",
  ADMIN_SET_AVAILABILITY_FILTER:
    "admin/price-availability/filter/add/by-category-id/",
  ADMIN_GET_PRICE_DATA_BY_ID: "admin/price-availability/category/get/",
  ADMIN_SET_AVAILABILITY_FOR_CATEGORY:
    "admin/price-availability/daily/generate/for-category/for-period/",
  ADMIN_GET_AVAILABILITY_DATA:
    "admin/price-availability/daily/get/for-category/",
  ADMIN_DELETE_FILTER_BY_ID:
    "admin/price-availability/filter/delete/by-filter-id/",
  ADMIN_ES_INDEXING: "admin/rental-property/index/by/propertyId/",

  // create role function
  CREATE_ROLE: "admin/roles",
  FETCH_ROLE: "admin/roles",
  EDIT_ROLE: "admin/roles/",
  DELETE_ROLE: "admin/roles/",
  GET_FUNCTION_TO_ROLE: "admin/roles/",
  ASSIGN_FUNCTION_ROLE: "admin/roles/",
  ADMIN_GET_FACILITY: "admin/facility/",
  ADMIN_CREATE_FACILITY: "admin/facility/",

  CREATE_FUNCTION: "admin/functions",
  FETCH_FUNCTION: "admin/functions",
  EDIT_FUNCTION: "admin/functions/",
  DELETE_FUNCTION: "admin/functions/",

  // owner and admin
  FETCH_OWNER_INFO: "user/review/owner",
  FETCH_OWNER_INFOV2: "merchant/owner/get/my/info",
  FETCH_CONTRACT_INFO: "user/review/contract-info",
  FETCH_CONTRACT_INFOV2: "merchant/contract/get/my/contract/info",
  FETCH_DOCUMENTS_INFO: "user/review/document",
  FETCH_DOCUMENTS_INFOV2: "merchant/contract/get/my/documents",
  FETCH_HOTELS_INFO: "user/review/property",
  FETCH_HOTELS_DETAILS: "user/review/property/",
  FETCH_CATEGORYS_INFO: "user/review/category",
  FETCH_CATEGORY_DETAILS: "user/review/category/",

  FETCH_TERMS_AND_CONDITIONS: "open/terms-and-condition/latest",
  ACCEPT_TERMS_AND_CONDITIONS: "terms-and-condition/accept",

  FETCH_OWNER_INFO_ADMINV2: "admin/contract/owner/get/by/",
  FETCH_CONTRACT_INFO_ADMINV2: "admin/contract/info/get/by/",
  FETCH_DOCUMENT_INFO_ADMINV2: "admin/contract/document/get/all/by/",
  FETCH_OWNER_INFO_ADMIN: "admin/review/contract/",
  ADMIN_SEND_REVIEW_REQUEST: "admin/contract/approve",

  FETCH_HOTELS_LIST: "admin/controller/contractId/",
  FETCH_HOTELS_DETAILS_ADMIN: "admin/review/property/",
  FETCH_CATEGORY_DETAILS_ADMIN: "admin/review/category/",

  FETCH_ALL_CONTRACT: "contract/fetchall",
  FETCH_ALL_CONTRACTV2: "admin/contract/get/all",
  USER_DETAIL: "user/",

  GET_ALL_TERMS_CONDITIONS: `admin/terms-and-condition/get/latest/tnc/version?requestId=getTnc`,
  GET_ALL_TERMS_CONDITIONS_DATA: `admin/terms-and-condition/fetch-all?requestId=getDetailsTnc`,
  GET_ALL_TERMS_CONDITIONS_BY_ID: `admin/terms-and-condition`,
  ADD_TERMS_CONDITIONS: `admin/terms-and-condition`,

  GET_CURRENCY: `open/booking/currency?requestId=1`,
  MERCHANT_DASHBOARD_DATA: `merchant/booking/dashboard`,
  MERCHANT_GET_OWN_HOTELS: `merchant/property`,
  FETCH_ALL_HOTELS_BY_NAME: `admin/property/property-name`,
  FETCH_ALL_HOTELS_FOR_ADMIN: `admin/all-rental-properties`,
  FETCH_ALL_TOP_HOTELS_FOR_ADMIN: "admin/get_all/top_hotel?requestId=ewf",
  FETCH_ALL_HOTELS_TOP_FOR_ADMIN: `admin/get_all/top_hotel?requestId=sadcs`,
  DELETE_HOTELS_TOP_FOR_ADMIN: "admin/delete/top_hotel?",
  FETCH_WITHOUT_ALL_HOTELS_FOR_ADMIN: `admin/rental-properties/without-thumb-image`,
  FETCH_ALL_IMAGES_BY_ID_ADMIN: `admin/rentalpropertyimages/getallbyid`,
  SET_THUMB_IMAGES: "admin/image/thumbnail",
  FETCH_ALL_FCILITY_BY_ID_ADMIN: `admin/property`,
  FETCH_ALL_SINGLE_HOTEL_TNC: "admin/get/all/rental_property_tnc?",
  FETCH_ALL_GENAREL_TERMS: "admin/get/all/general_tnc?",
  FETCH_ALL_PRODUCT_TERMS: "admin/tnc/get/all",

  ADMIN_UPDATE_TNC: "admin/update/rental_property_tnc",
  ADMIN_UPDATE_GENAREL_TNC: "admin/update/general_tnc",
  ADMIN_DELETE_TNC: "admin/delete/rental_property_tnc?",
  ADMIN_DELETE_GENAREL_TNC: "admin/delete/general_tnc?id=",

  FETCH_PROPERTY_HOTEL_ID: `admin/controller/rentalproperty/fetchbyid`,
  FETCH_PROPERTY_WITHOUT_SPECIALITY: `admin/rental-properties/without-property-speciality`,
  FETCH_PROPERTY_SPECIALITY_BY_ID: `admin/rental-properties/property-speciality/fetch-by-propertyId`,
  CREATE_UPDATE_SPECIALITY: `admin/rental-properties/property-speciality/update-property-speciality`,
  FETCH_WITHOUT_ALL_FACILLITY_FOR_ADMIN: `admin/rental-properties/without-display-facility`,
  DELETE_SPECIALITY: `admin/rental-properties/property-speciality/delete-by-id`,
  MERCHANT_ADD_POLICY: "merchant/property/policy",
  MERCHANT_GET_POLICY: "merchant/property",
  MERCHANT_EDIT_POLICY: "merchant/property/policy",
  MERCHANT_DELETE_POLICY: "merchant/property/policy",
  MARCHENT_ADD_HOTEL_TNC: "merchant/create/rental_property_tnc",
  MARCHENT_CHECK_FOOD_LIST: "merchant/food/meal-package/combination/check",
  MARCHANT_CHECK_PACKAG_COMBINATION: "merchant/tour-package/option/check",
  MARCHANT_ADD_ACCOMMONDATION_PACKAGE:
    "merchant/tour/accommodation/package/add",
  MARCHANT_ADD_FOOD_PACKAGE: "merchant/tour/food/meal-package/add",
  MARCHANT_ADD_TRANSFER_PACKAGE: "merchant/tour/transfer/package/add",
  MARCHANT_ADD_TRANSPORTATION_PACKAGE:
    "merchant/tour/transportation/package/add",
  MARCHANT_ADD_GUIDE_PACKAGE: "merchant/tour/guide/package/add",
  MARCHANT_ADD_SPOTFEE_PACKAGE: "merchant/tour/spot/entry/package/add",
  MARCHANT_CREATE_PACKAG: "merchant/tour-package/add",
  MARCHANT_GET_TRANFER_ROUTE: "merchant/tour/subscribed/get/all/address/by/",
  MARCHANT_GET_PACKAGE_SUMMARY: "merchant/tour-package/summary/get/all/by/",
  MARCHANT_GET_PACKAGE_AVAILABILITY:
    "merchant/tour-package/availability/get/all/components/by/",
  MARCHANT_GET_DETAILS_PACKAGE_SUMMARY: "merchant/tour-package/get/detail/by/",
  MARCHANT_POST_PACKAGE_AVAILABILITY: "merchant/tour-package/availability/set",
  // https://dev.ghuddy.link/api/v1/open/booking/currency?requestId=1

  ADMIN_ADD_DISPLAY_SPECIALITY: "admin/facility/update/display-group",
  ADMIN_GET_DISPLAY_SPECIALITY: "admin/facility/get/all/display-group",
  ADMIN_ADD_UIFILTER: "admin/facility/update/update-ui-filter",
  ADMIN_GET_UIFILTER: "admin/facility/get/all/ui-filters",
  ADMIN_ADD_PROPERTY_FACILITY: "admin/facility/update/property-facility-type",
  ADMIN_GET_PROPERY_FACILITY:
    "admin/facility/get/all/rental-property-facility-type",
  ADMIN_GET_ALL_PROPERTY: "admin/facility/get/paginated/property-facility",
  ADMIN_ADD_ALL_PROPERTY: "admin/facility/update/property-facility",
  ADMIN_GET_CATEGORY_PROPERTY_TYPE:
    "admin/facility/get/all/category-facility-type",
  ADMIN_ADD_CATEGORY_PROPERTY_TYPE:
    "admin/facility/update/category-facility-type",
  ADMIN_GET_CETEGORY_FACILITY: "admin/facility/get/paginated/category-facility",
  ADMIN_ADD_CATEGORY_FACILITY: "admin/facility/update/category-facility",
  ADMIN_ADD_HOTEL_TNC: "admin/create/rental_property_tnc",
  ADMIN_ADD_GENAREL_TNC: "admin/create/general_tnc?requestId=SWQ",
  ADMIN_ADD_PRODUCT_TNC: "admin/tnc/add",

  // tour management api
  ADMIN_TOUR_MEAL_TYPES_POST_API: "admin/food/meal-type/list/add?requestId=SWQ",
  ADMIN_TOUR_FOOD_POST_API: "admin/food/item/list/add?requestId=SWQ",
  ADMIN_ADD_MEAL_PACKAGE_POST_API:
    "admin/food/meal-package/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_PACKAGE_POST_API:
    "admin/tour/package/type/list/add?requestId=SWQ",
  ADMIN_GET_TOUR_PACKAGE_TYPE_API:
    "admin/tour/package/type/get/all?requestId=1233",
  ADMIN_ADD_ACCOMMONDATION_ADD: "admin/accommodation/list/add",
  ADMIN_ADD_TOUR_ACCOMMANDATION_TYPE_POST_API:
    "admin/accommodation/type/list/add?requestId=SWQ",
  ADMIN_ADD_ROOM_TYPE_POST_API: "admin/room/type/list/add?requestId=SWQ",
  ADMIN_ADD_ROOM_CATEGORY_POST_API:
    "admin/room/category/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_ADD_POST_API: "admin/tours/add?requestId=SWQ",
  ADMIN_ADD_ACTIVITY_TYPE_ADD_POST_API:
    "admin/activity/type/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_ACTIVITY_ADD_POST_API: "admin/activity/add?requestId=SWQ",
  ADMIN_ADD_TOUR_TRANSPORT_PROVIDER_POST_API:
    "admin/transportation/provider/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_TRANSPORT_MODE_POST_API:
    "admin/transportation/mode/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_TRANSPORT_BRANDS_POST_API:
    "admin/transportation/brand/list/add?requestId=SWQ",
  ADMIN_ADD_TOUR_TRANSPORT_LIST_POST_API:
    "admin/tour-package/transportation/list/add?requestId=SWQ",
  ADMIN_FETCH_TOUR_FOOD_LIST_GET_API: "admin/food/item/get/all?requestId=12",
  ADMIN_FETCH_TOURS_LIST_GET_API: "admin/tours/get-all?requestId=hjk",
  ADMIN_FETCH_ACTIVITY_LIST_GET_API: "admin/activity/get/all?requestId=ytfdfs",
  ADMIN_FETCH_ACTIVITY_TYPE_GET_API: "admin/activity/type/get/all?requestId=wr",
  ADMIN_DELETE_ACTIVITY_TYPE_DELETE_API: "admin/activity/type/delete/",
  ADMIN_FETCH_ACTIVITY_TYPE_GET_BY_SEARCH_API:
    "admin/activity/type/get/all?requestId=wr",
  ADMIN_DELETE_ACTIVITY_DELETE_API: "admin/activity/delete/",
  ADMIN_GET_ALL_ACCOMMANDATION_TYPE_GET_API:
    "admin/accommodation/type/get/all?requestId=wed",
  ADMIN_GET_ALL_ACCOMMANDATION_GET_API:
    "admin/accommodation/get/all?requestId=asdfd",
  ADMIN_POST_TOUR_TRANSPORT_ROUTE_POST_API: "admin/transportation/route/add",
  ADMIN_GET_TOURS_GET_API: "admin/tours/added-tours/get/all?requestId=cvb",
  ADMIN_GET_ALL_ROOM_TYPE_GET_API: "admin/room/type/get/all?requestId=edfrvg",
  ADMIN_GET_ALL_ROOM_CATEGORY_GET_API:
    "admin/room/category/get/all?requestId=kjh",
  ADMIN_GET_ALL_MEAL_TYPE_GET_API: "admin/meal/type/get/all?requestId=oih",
  ADMIN_GET_TOURS_ACTIVITY_GET_API: "admin/activity/get/all?requestId=wsd",
  ADMIN_GET_ALL_TRANSPORTATION_ROUTE_GET_API:
    "admin/transportation/route/get/all?requestId=wsd",
  ADMIN_GET_ALL_TRANSPORTATION_PROVIDER_GET_API:
    "admin/transportation/provider/get/all?requestId=wsd",
  ADMIN_GET_ALL_TRANSPORTATION_MODE_GET_API:
    "admin/transportation/mode/get/all?requestId=wsd",
  ADMIN_GET_ALL_TRANSPORTATION_BRAND_GET_API:
    "admin/transportation/brand/get/all?requestId=wsd",
  ADMIN_ADD_TOUR_CREATE_VERY_EXCITED_TIME: "admin/tours/create",
  ADMIN_GET_ALL_TOURS_GET_API:
    "admin/tours/created-tours/get/all?requestId=133",
  ADMIN_PUT_RENTAL_PROPERTY_STARS: "admin/update/rental-property/stars?",
  ADMIN_PUT_RENTAL_PROPERTY_REVIEW_USER_RATING:
    "admin/update/rental-property/user/ratings/and/no_of_reviews?",

  MARCHENT_GET_SINGLE_TOUR_API: "merchant/tours/created-tours/get/by/",
  MARCHENT_POST_SUBSCRIBE_TOUR_API:
    "merchant/tour/subscribe?requestId=subscribe",
  MARCHENT_POST_PACKAGE_TOUR_API: "merchant/tour-package/add",

  // created by talal
  ADMIN_GET_FACILITY_GROUP_BYID: "admin/get/facility/group/by/id",
  ADMIN_GET_FACILITIES_GROUP: "admin/get/all/facility-group",
  ADMIN_DELETE_FACILITIES_GROUP_BYID: "admin/delete/facility/group/by/id",
  ADMIN_ADD_FACILITIES_GROUP: "admin/update/facility-group",

  ADMIN_GET_FACILITY_BYID: "admin/get/facility/by/id",
  ADMIN_DELETE_FACILITIES: "admin/delete/facility/by/id",
  ADMIN_GET_FACILITIES: "admin/get/all/facility",
  ADMIN_ADD_FACILITIES: "admin/update/facility",
  ADMIN_GET_PAGINATION_PACKAGE_VARIABLE:
    "admin/get/all/excursion/package/variable",
  ADMIN_ADD_UPDATE_PACKAGE_VARIABLE: "admin/update/excursion/package/variable",
  ADMIN_DELETE_BY_ID_VARIABLE: "admin/delete/excursion/package/variable",

  ADMIN_CATAGORIES_GET_GROUP_ONE: "admin/get/all/category/group-one",
  ADMIN_CATAGORIES_GET_GROUP_TWO: "admin/get/all/category/group-two",
  ADMIN_CATAGORIES_GET_GROUP_THREE: "admin/get/all/category/group-three",

  ADMIN_CATAGORIES_GET_GROUP_ONE_BYID:
    "admin/get/excursion/category/group/one/by/id",
  ADMIN_CATAGORIES_GET_GROUP_TWO_BYID:
    "admin/get/excursion/category/group/two/by/id",
  ADMIN_CATAGORIES_GET_GROUP_THREE_BYID:
    "admin/get/excursion/category/group/three/by/id",

  ADMIN_CATAGORIES_ADD_GROUP_ONE: "admin/update/excursion/category/group-one",
  ADMIN_CATAGORIES_ADD_GROUP_TWO: "admin/update/excursion/category/group-two",
  ADMIN_CATAGORIES_ADD_GROUP_THREE:
    "admin/update/excursion/category/group-three",

  ADMIN_CATAGORIES_DELETE_GROUP_ONE:
    "admin/delete/excursion/category/group/one/by/id",
  ADMIN_CATAGORIES_DELETE_GROUP_TWO:
    "admin/delete/excursion/category/group/two/by/id",
  ADMIN_CATAGORIES_DELETE_GROUP_THREE:
    "admin/delete/excursion/category/group/three/by/id",

  ADMIN_EXCURSION_ADD_COMPONENT: "admin/update/excursion/component",
  ADMIN_EXCURSION_DELETE_COMPONENT_BYID:
    "admin/delete/excursion/component/by/id",
  ADMIN_EXCURSION_COMPONENT_GET_ALL: "admin/get/all/excursion/component",
  ADMIN_EXCURSION_COMPONENT_GET_BYID: "admin/get/excursion/component/by/id",
  ADMIN_EXCURSION_SPECIALITY: "admin/excursion/speciality",

  // email send
  SEND_EMAIL_MANUAL: "open/sendMailToCustomerAfterPayment?",
  // ghuddy product
  GHUDDY_ALL_PRODUCT: "merchant/ghuddy/product/get/all?requestId=1234",
  GHUDDY_PRODUCT_TNC: "merchant/tnc/get/latest/by/",
  GHUDDY_PRODUCT_TNC_ADD: "merchant/ghuddy/product/subscribe",
};

export const HOTEL_PROPERTY_API = {
  HOTEL_PROPERTY_TYPE_GET:
    "merchant/rental/property/type/get/all?requestId=esd",
};

export const apiForDashboard = {
  CATEGORY_AVAILABILITY: "user/availability/category/",
  HOTEL_CALENDAR_AVAILABILITY: "user/availability/rental-property/",
};

export const apiEndPointForOwnerReviewSubmission = {
  OWNER_REVIEW_SUBMIT: "admin/review/owner/",
  HOTEL_REVIEW_SUBMIT: "admin/review/property/",
  CATEGORY_REVIEW_SUBMIT: "admin/review/category/",
};

export const CATEGORY_APIS = {
  ENABLE: `admin/controller/roomcategory/activate/`,
  DISABLE: `admin/rentalproperty/category/deactivate/`,
  DELETE: `admin/rentalproperty/category/delete/`,
  REMOVE_IMAGE: `merchant/rental-property/room-category/image/delete/`,
  //REMOVE_IMAGE: `admin/controller/rental-property/room-category/image/delete/`,
};

export const PROPERTY_ENDPOINTS = {
  FETCH_FACILITIES: `open/v1/frontendcontentcontroller/fetchallfacilities`,
  FETCH_RENTAL_ADMIN: `admin/controller/contractId/property`,
  FETCH_RENTAL_MERCHANT: "merchant/property?requestId=getHotelMerchant",
  FETCH_RENTAL_ADMIN_BY_ID: `admin/controller/rentalproperty/fetchbyid/`,
  FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE: `open/v1/frontendcontentcontroller/fetchaddressattributes/bycountrycode/`,
};

export const BOOKING_ENDPOINTS = {
  GET_BOOKINGS: "merchant/booking",

  // marchent api
  GET_BOOKINGS_BY_PROPERTY: "merchant/getAllRentalReviewsNew?",
  GET_INPROGRESS_RENTAL_REVIEWS: "merchant/getAllRentalReviewsInProgress?",
  GET_ACCEPTED_BOOKING: "merchant/getAllRentalReviewsAccepted?",
  GET_REJECTED_BOOKING: "merchant/getAllRentalReviewsRejected?",
  GET_SINGLE_BOOKING:
    "merchant/getRentalBookingDataByRentalBookingReviewId?rentalBookingReviewId=",
  CONFIRM_BOOKING: "merchant/rentalbooking/accepted?rentalBookingReviewId=",
  REJECTED_BOOKING: "/merchant/rentalbooking/rejected?",
  SET_INPROGRESS_RENTAL_REVIEWS:
    "merchant/rentalbooking/open?rentalBookingReviewId=",
  GET_SEARCH_BY_ORDER_CODE_BOOKING: "merchant/getAllRentalReview?",

  // admin api
  GET_BOOKINGSALL_BY_PROPERTY_ADMIN: "admin/getAllRentalReview?",
  GET_BOOKINGSNEW_BY_PROPERTY_ADMIN: "admin/getNewRentalReview?",
  GET_REJECTED_BY_ADMIN: "/admin/getAllRentalReviewsRejected?",
  GET_BOOKINGSINPROGRESS_BY_PROPERTY_ADMIN: "admin/getInProgressRentalReview?",
  GET_BOOKINGSACCEPT_BY_PROPERTY_ADMIN: "admin/getAcceptedRentalReview?",
  GET_INPROGRESS_RENTAL_REVIEWS_ADMIN: "admin/getInProgressRentalReview?",
  GET_ACCEPTED_BOOKING_ADMIN: "admin/getAcceptedRentalReview?",
  // GET_REJECTED_BOOKING_ADMIN: "admin/getAllRentalReviewsRejected?",
  GET_REJECTED_BOOKING_ADMIN: "admin/getAllRentalReviewsRejectedByMerchant?",
  GET_SINGLE_BOOKING_ADMIN:
    "merchant/getRentalBookingDataByRentalBookingReviewId?rentalBookingReviewId=",
  CONFIRM_BOOKING_ADMIN: "admin/reviewAcceptedByAdmin?rentalBookingReviewId=",
  REJECTED_BOOKING_ADMIN: "admin/reviewRejectedByAdmin?rentalBookingReviewId=",
  ADMIN_GET_ALL_SALESORDER: "admin/rentalBookingWithSalesOrder?",
  ADMIN_GET_ALL_SALES_ORDER_FOR_SEARCH_DETAILS: "admin/sales-order/search/all?",

  CHANGE_STATUS: "merchant/booking/invoice-status",

  DIRECT_CHANGE_STATUS: "admin/booking/direct/status",

  // tour booking api
  GET_BOOKINGSALL_BY_TOUR_ADMIN: "admin/get/all/tour/booking/review?",
  GET_BOOKINGSNEW_BY_TOUR_ADMIN: "admin/get/new/tour/booking/review?",
  GET_REJECTED_TOUR_BY_ADMIN: "admin/get/rejected/tour/booking/review?",
  GET_BOOKINGSINPROGRESS_BY_TOUR_ADMIN:
    "admin/get/in-progress/tour/booking/review?",
  GET_ACCEPTED_BOOKING_TOUR_ADMIN: "admin/get/accepted/tour/booking/review?",
  GET_REJECTED_BOOKING_TOUR_ADMIN: "admin/get/rejected/tour/booking/review?",
  CONFIRM_TOUR_BOOKING_ADMIN: "admin/review/accepted/by/admin?",
  REJECTED_TOUR_BOOKING_ADMIN: "admin/review/rejected/by/admin?",
};

export const BOOKING_ENDPOINTS_TOUR = {
  GET_BOOKINGS_BY_NEW_TOUR: "merchant/get/all/new/tour/booking/review?",
  SET_INPROGRESS_TOUR_REVIEWS:
    "merchant/tour/booking/open?requestId=acceptInprogress&tourBookingReviewId=",
  GET_INPROGRESS_TOUR_REVIEWS:
    "merchant/get/all/in-progress/tour/booking/review?",
  GET_SINGLE_BOOKING_TOUR:
    "merchant/get/tour/booking/package/option/by/tour/booking/review/id?requestId=getById&tourBookingReviewId=",
  GET_ACCEPTED_BOOKING_TOUR: "merchant/get/all/accepted/tour/booking/review?",
  GET_REJECTED_BOOKING_TOUR: "merchant/get/all/rejected/tour/booking/review?",
  PUT_ACCEPTED_BOOKING_TOUR:
    "merchant/tour/booking/accepted?requestId=1&tourBookingReviewId=",
  PUT_REJECTED_BOOKING_TOUR: "merchant/tour/booking/rejected?",
};

export const CANCELLATION_ENDPOINTS = {
  POST_CANCELLATION: `${baseUrl}merchant/property/cancellation-policy/`,
  POST_CANCELLATION_CATEGORY: `${baseUrl}merchant/property/category/cancellation`,
  //"https://dev.ghuddy.link/api/v1/merchant/property/category/cancellation",
  GET_CANCELLATION_BY_PROPERTY: `${baseUrl}merchant/property/`,
  //https://dev.ghuddy.link/api/v1/merchant/property/1/cancellation?requestId=11
  GET_CANCELLATION_BY_CATEGORY: `${baseUrl}merchant/category/`,
  //https://dev.ghuddy.link/api/v1/merchant/category/1/cancellation?requestId=11
};

export const FAQ_ENDPOINTS = {
  POST_FAQ: `${baseUrl}admin/faq`,
  //https://dev.ghuddy.link/api/v1/admin/faq
  GET_FAQ: `${baseUrl}open/user/faq?pageNo=0&pageSize=20`,
  EDIT_FAQ: `${baseUrl}admin/faq/`,
  DELETE_FAQ: `${baseUrl}admin/faq/`,

  ADMIN_GET_FAQ_FAQ_GROUP_SEARCH: "admin/faq/faq-group/search",
  ADMIN_GET_FAQ_CONTENT: "/admin/faq/faq-content/",
  ADMIN_GET_FAQ_FAQ_CONTENT_BY_FAQ_GROUP_ID:
    "admin/faq/faq-content/get-all/by-group-id",
  ADMIN_POST_FAQ_GROUP: "/admin/faq/faq-group",
  ADMIN_PATCH_FAQ_GROUP: "admin/faq/faq-group/update",
  ADMIN_PUT_FAQ_INDEX_ALL: "admin/faq/faq-group/index/all?requestId=123",
  ADMIN_POST_FAQ_CONTENT: "/admin/faq/faq-content",
  ADMIN_PATCH_FAQ_CONTENT: "/admin/faq/faq-content/update",
  ADMIN_DELETE_FAQ_CONTENT_BY_ID: "/admin/faq/faq-content/",

  GET_ALL_FAQ_WITH_PAGINATION: `open/faq/faq-group/get-all/paginated`,
};

export const INDEXING = {
  PLACE_GENERATE: "admin/location/place/generate",
  PLACE_INDEX: "admin/location/places/index",
};

// Merchant Tour
export const MERCHANT_TOUR_ENDPOINT = {
  MERCHANT_TOURS_TOURS_PACKAGES_TYPE_GET_API_ALL:
    "merchant/tour-package/type/get/all",
  MERCHANT_TOURS_CREATED_TOUR_GET_API_ALL:
    "merchant/tours/created-tours/get/all",
  MERCHANT_FOOD_PACKAGE_FOOD_ITEM_LIST_GET_API:
    "merchant/food/item/get/all?requestId=foodItem",
  MERCHANT_FOOD_PACKAGE_MEAL_TYPE_LIST_GET_API:
    "merchant/meal/type/get/all?requestId=mealType",
  MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API:
    "merchant/tour/meal/package/flat/get/all/by/",
  MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API_KEY:
    "merchant/tour/meal/package/get/all/by/",
  MERCHANT_ACCOMMODATION_PACKAGE_GET_ALL_PAGINATED_API:
    "merchant/accommodation/get/all?",
  MERCHANT_ACCOMMODATION_PACKAGE_ROOM_CATEGORY_GET_PAGINATED_API:
    "merchant/room/category/get/all?",
  MERCHANT_ACCOMMODATION_PACKAGE_ROOM_TYPE_GET_PAGINATED_API:
    "merchant/room/type/get/all?",
  MERCHANT_ACCOMMODATION_GET_API:
    "merchant/tour/accommodation/package/get/all/by/",
  MERCHANT_TRANSPORTATION_PACKAGE_BRAND_LIST_GET_API:
    "merchant/tour/transportation/brand/get/all?",
  MERCHANT_TRANSPORTATION_PACKAGE_MODE_LIST_GET_API:
    "merchant/tour/transportation/mode/get/all?",
  MERCHANT_TRANSPORTATION_PACKAGE_PROVIDER_LIST_GET_API:
    "merchant/tour/transportation/provider/get/all?",
  MERCHANT_TRANSPORTATION_PACKAGE_ROUTE_LIST_GET_API:
    "merchant/tour/transportation/route/get/all?",

  MERCHANT_GET_SUBSCRIPTION_WITH_PACKAGE:
    "merchant/tour/subscribed/card-data/get/all/by/",

  MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE:
    "merchant/tour/transportation/package/get/all/by/",

  MERCHANT_GET_TRANSFER_ALL_PACKAGE:
    "merchant/tour/transfer/package/get/all/by/",
  MERCHANT_GET_GUIDE_ALL_PACKAGE: "merchant/tour/guide/package/get/all/by/",
  MERCHANT_GET_SPOT_ALL_PACKAGE: "merchant/tour/spot/entry/package/get/all/by/",
  MERCHANT_GET_PACKAGE_TYPE:
    "merchant/tour/package/type/get/all?requestId=GETTYPE",
  MERCHANT_POST_TOUR_PACKAGE:
    "merchant/tour/package/list/availability/set?requestId=create",
  MERCHANT_PUT_TOUR_PACKAGE_AVAILABILITY_DE_ACTIVATE_BY_AVAILABILITY_TOUR_PACKAGE_ID:
    "merchant/tour/package/availability/de-activate",

  MERCHANT_GET_TOUR_PACKAGE_COMBINATION:
    "merchant/tour-package/options/combination/get/by/",
  MERCHANT_GET_TOUR_PACKAGE: "merchant/tour/package/type/get/all?",
  MARCHANT_VIEW_PACKAGE_AVAILABEL_DATE:
    "merchant/tour/package/get/date/range/for/",
  MARCHANT_VIEW_PACKAGE_AVAILABEL_OPTION:
    "merchant/tour/package/get/available/tour/package/with/default/options/by/",
  MARCHANT_GET_ALL_ACCOMMODATION_OPTION:
    "merchant/tour/package/accommodation/option/get/all/by/",
  MARCHANT_GET_ALL_TRANSFER_OPTION:
    "merchant/tour/package/transfer/option/get/all/by/",
  MARCHANT_GET_ALL_GUIDE_OPTION:
    "merchant/tour/package/guide/option/get/all/by/",
  MARCHANT_GET_ALL_SPOT_OPTION:
    "merchant/tour/package/spot/entry/option/get/all/by/",
  MARCHANT_GET_ALL_FOOD_OPTION: "merchant/tour/package/food/option/get/all/by/",
  MARCHANT_GET_ALL_TRANSPORTATION_OPTION:
    "merchant/tour/package/transportation/option/get/all/by/",
  MARCHANT_GET_ALL_AVAILABLE_PACKAGE_DATA:
    "merchant/tour/package/available/get/all/by/",
};

export const MERCHANT_EXCURSION_ENDPOINT = {
  EXCURSION_CREATE: "merchant/update/excursion",
  MERCHANT_EXCURSION_GET_ALL: "merchant/get/excursion/by/merchant",
  MERCHANT_EXCURSION_GET_ALL_WITH_PAGINATION:
    "merchant/fetch/paginated/excursion",
  MERCHANT_GET_CANCELLATION_POLICY_BY_ID:
    "merchant/fetch/cancellation/policy/term/by/excursion/id",
  MERCHANT_EXCURSION_POST_CANCELLATION_POLICY:
    "merchant/excursion/cancellation-policy",

  MERCHANT_EXCURSION_GET_BY_ID: "admin/get/excursion/by/id",
  MERCHANT_EXCURSION_GET_ADDRESS_BY_ID:
    "merchant/fetch/address/by/excursion/id",
  MERCHANT_EXCURSION_GET_MEETING_BY_ID:
    "merchant/fetch/meeting/point/by/excursion/id",
  MERCHANT_EXCURSION_GET_ADDITIONAL_INFORMATION_BY_ID:
    "merchant/fetch/additional/information/by/excursion/id",
  MERCHANT_EXCURSION_DELETE_ADDITIONAL_INFORMATION_BY_EXCURSION_ADDITIONAL_ID:
    "merchant/delete/additional_information/by/additional/information/id",
  MERCHANT_EXCURSION_GET_SUITABLE_FOR_BY_ID:
    "merchant/fetch/suitable/for/by/excursion/id",
  MERCHANT_EXCURSION_DELETE_SUITABLE_FOR_BY_SUITABLE_ID:
    "merchant/delete/suitable/for/by/excursion_suitable_for/id",
  MERCHANT_EXCURSION_GET_UNSUITABLE_FOR_BY_ID:
    "merchant/fetch/unsuitable/for/by/excursion/id",
  MERCHANT_EXCURSION_DELETE_UNSUITABLE_FOR_BY_SUITABLE_ID:
    "merchant/delete/unsuitable/for/by/excursion_unsuitable_for/id",
  MERCHANT_EXCURSION_GET_ITINERARY_BY_ID:
    "merchant/get/excursion/itinerary/step/by/excursion/id",

  MERCHANT_EXCURSION_GET_ALL_NAME_BY_ID:
    "merchant/get/excursion/name/id?requestId=123",
  MERCHANT_EXCURSION_GET_ALL_CREATE_PACKAGE_BY_ID:
    "merchant/get/excursion/package/name/id",
  MERCHANT_EXCURSION_GET_ALL_CREATE_PACKAGE_TIME_SLOT_BY_ID:
    "merchant/get/excursion/package/time/slot/name/id",

  MERCHANT_EXCURSION_DELETE_BY_ID: "admin/excursion/deleted/by/id",
  MERCHANT_ALL_EXCURSION:
    "open/v1/frontend/content/controller/fetch-all/excursion/components",
  MERCHANT_UPDATE_EXCURSION_ADDRESS: "merchant/update/excursion/address",
  MERCHANT_UPDATE_EXCURSION_MEETING_POINT:
    "merchant/update/excursion/meeting/point",
  MERCHANT_UPDATE_EXCURSION_ADDITIONAL_INFORMATION:
    "merchant/update/excursion/additional/information",
  MERCHANT_UPDATE_EXCURSION_SUITABLE_FOR:
    "merchant/update/excursion/suitable/for",
  MERCHANT_UPDATE_EXCURSION_UNSUITABLE_FOR:
    "merchant/update/excursion/unsuitable/for",
  MERCHANT_UPDATE_EXCURSION_INCLUDES: "merchant/excursion/include/component",
  MERCHANT_UPDATE_EXCURSION_EXCLUDES: "merchant/excursion/exclude/component",
  MERCHANT_GET_EXCURSION_FETCH_ALL_COMPONENTS:
    "open/v1/frontend/content/controller/fetch-all/excursion/components",
  MERCHANT_UPDATE_EXCURSION_ITINERARY:
    "merchant/update/excursion/itinerary/step",
  VEHICLES_ALL: "open/get/all/vehicles",

  MERCHANT_GET_ALL_EXCURSION_IMAGES: "merchant/get/images/by/excursion/id",
  MERCHANT_UPDATE_EXCURSION_IMAGES_ADD: "merchant/excursion/images/add",
  MERCHANT_DELETE_EXCURSION_IMAGE_ID: "merchant/excursion/image/delete/",

  MERCHANT_UPDATE_EXCURSION_PACKAGE: "merchant/update/excursion/package",
  MERCHANT_GET_EXCURSION_LIST_BY_ID_PACKAGE:
    "merchant/get/excursion/package/list/by/excursion/id",
  MERCHANT_DELETE_EXCURSION_PACKAGE_BY_ID: "merchant/delete/excursion/package?",
  MERCHANT_GET_EXCURSION_PACKAGE_BY_PACKAGE_ID:
    "merchant/get/excursion/package/by/id?",
  MERCHANT_EXCURSION_PACKAGE_DELETE_BY_PACKAGE_ID:
    "merchant/delete/excursion/package/component?",
  MERCHANT_UPDATE_PACKAGE_TIME_SLOT:
    "merchant/update/excursion/package/time/slot",
  MERCHANT_GET_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID:
    "merchant/get/excursion/time/slot/by/excursion/package/id?",
  MERCHANT_GET_SINGLE_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID:
    "merchant/get/excursion/time/slot/by/id?",
  MERCHANT_DELETE_PACKAGE_TIME_SLOT_BY_EXCURSION_PACKAGE_ID:
    "merchant/delete/excursion/time/slot?",

  MERCHANT_GET_EXCURSION_MULTI_PACKAGE: "merchant/is/excursion/multi/package?",
  MERCHANT_SET_EXCURSION_MULTI_PACKAGE: "merchant/set/excursion/multi/package?",

  MERCHANT_SET_PACKAGE_MULTI_TIME_SLOT: "merchant/set/excursion/multi/slot?",
  MERCHANT_GET_PACKAGE_MULTI_TIME_SLOT:
    "merchant/is/excursion/package/multi/slot?",
};

export const MERCHANT_PRICE_AVAILABILITY = {
  MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_SET:
    "admin/price-availability/excursion/set",
  MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_PACKAGE_SET:
    "admin/price-availability/excursion/package/set",
  MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_PACKAGE_TIME_SLOT_SET:
    "admin/price-availability/excursion/package/time/slot/set",
};

export const BLOG_CONTROLLER_API = {
  USER_CREATE_BLOG: "user/blog/create",
  USER_UPDATE_BLOG: "user/blog/update",
  MERCHANT_ALL_BLOGS: "open/blog/get/all",
  ALL_BLOG_PAGINATION: "open/blog/get/all/paginated?",
  USER_BLOG_TAG_GET_ALL: "user/blog/tag/get/all",
  USER_BLOG_DELETE_BY_ID: "user/blog/delete/by",
  USER_GET_ALL_BLOGS: "user/blog/get/all/for/user?requestId=22",
  ADMIN_BLOG_INDEX_ALL: "admin/blog/index/all",
  BLOGS_ALL_TAGS: "user/blog/tag/get/all/paginated?",
  ADMIN_POST_BLOG_TAG: "admin/admin/blog/tag/create",
};

export const AIRTICKET_CONTROLLER_API = {
  AIRPORT_INFO_API: "admin/airports/update",
  AIRPORT_INFO_GET_ALL_API: "admin/airports/search/all",
  AIRLINES_LOGO_POST: "admin/airlines/update",
  AIRLINES_LOGO_GET_ALL_API: "admin/airlines/get/all",
  FLIGHT_TICKET_STATUS_GET_API: "admin/flight-booking/search/all",
  OTP_QUERY_CHECK: "admin/otp/search",
};
