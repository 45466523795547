import React, { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  apiEndPoint,
  baseUrl,
} from "../../contants/Endpoints";
import { toast } from "react-toastify";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../components/common/ModalCommon";
import SingleSelectNew from "../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
// import { fakeArray } from "./fakeData";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import Loading from "../../components/Loader/Loading";
import { changeUrl, numberInputOnWheelPreventChange } from "../utils/common";
import Paginations from "../../pages/admin/Paginations/Paginations";
import { FaCheck } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";

const Itinerary = ({ setActiveStep }) => {
  const [itineraryList, setItineraryList] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalMultiPackage, setToggleModalMultiPackage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  let [temp, setTemp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(0);
  const [isCheckBox, setIsCheckBox] = useState(false);

  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));

  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 5;

  console.log({ isCheckBox });

  const [itinerary, setItinerary] = useState({
    title: "",
    duration: 0,
    address: "",
    description: "",
    vehicle: "",
    mode_icon_url: "",
  });

  const payloadMethod = {
    address: itinerary?.address,
    description: itinerary?.description,
    duration: parseInt(itinerary?.duration),
    excursionId: Number(Number(url.searchParams.get("id"))),
    imageUrl: itinerary?.mode_icon_url?.url,
    id: editId,
    requestId: "1123",
    title: itinerary?.title,
    vehicle: itinerary?.vehicle,
  };

  const handleAdd = () => {
    setToggleModal(true);
    setTemp("");
    setItinerary({
      ...itinerary,
      title: "",
      duration: 0,
      address: "",
      description: "",
      vehicle: "",
      mode_icon_url: "",
    });
    setEditId(0);
  };

  useEffect(() => {
    authAxios
      ?.get(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_GET_EXCURSION_MULTI_PACKAGE}excursionId=${excursionID}&requestId=123`
      )
      .then((res) => {
        setIsCheckBox(res?.data?.success);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEdit = () => {
    setToggleModal(true);
  };

  const getItinerary = () => {
    if (excursionID !== 0) {
      setIsLoading(true);
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ITINERARY_BY_ID}?excursionId=${excursionID}&pageNumber=${currentPage}&pageSize=${pageSize}&requestId=123`
        )
        .then((res) => {
          const tempData = res?.data?.entries;
          setItineraryList(tempData);
          setTotalPage(res?.data?.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const addItinerary = () => {
    if (
      itinerary?.title?.length === 0 ||
      itinerary?.description?.length === 0 ||
      itinerary?.address?.length === 0 ||
      itinerary?.vehicle?.length === 0 ||
      itinerary?.mode_icon_url?.length === 0 ||
      itinerary?.duration <= 0
    ) {
      setShowError(true);
      toast.warn("All the field is required!");
      return;
    }

    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_ITINERARY}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Itinerary Added successfully!");
        itinerary.title = "";
        itinerary.description = "";
        itinerary.mode_icon_url = "";
        setTemp = "";
        setToggleModal(false);
        setShowError(false);
        getItinerary();
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
      });
  };

  const [vehicleList, setVehicleList] = useState([]);

  const onEditButton = (item) => {
    setEditId(item.id);
    setItinerary({
      ...itinerary,
      title: item.title,
      duration: item.duration,
      address: item.address,
      description: item.description,
      vehicle: item.vehicle,
      mode_icon_url: { url: item.imageUrl },
    });
    handleEdit();
  };
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + MERCHANT_EXCURSION_ENDPOINT.VEHICLES_ALL}?requestId=123456`
      )
      .then((res) => {
        setVehicleList(res?.data?.vehicles);
      })
      .catch((err) => {});
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getItinerary();
  }, [currentPage, pageSize]);

  const backAndSave = () => {
    const url = new URL(window.location.toString());
    changeUrl(
      Number(url.searchParams.get("id")),
      Number(url.searchParams.get("step") - 1)
    );
    setActiveStep(Number(url.searchParams.get("step")) - 1);
  };

  // const onSubmitButton = () => {
  //     setActiveStep(9);
  //   changeUrl(Number(url.searchParams.get("id")), 9);
  // };

  const onCheckBoxChange = () => {
    setIsCheckBox(!isCheckBox);
  };

  const onSubmitButton = () => {
    if (isCheckBox) {
      setActiveStep(10);
      changeUrl(Number(url.searchParams.get("id")), 10);
    } else {
      setToggleModalMultiPackage(true);
    }
    // authAxios?.post()
  };

  const onSubmitMultiPackage = () => {
    authAxios
      .post(
        `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_SET_EXCURSION_MULTI_PACKAGE}excursionId=${excursionID}&requestId=123`
      )
      .then((res) => {
        toast.success("Successfully Added.");
        setActiveStep(10);
        changeUrl(Number(url.searchParams.get("id")), 10);
      })
      .catch((err) => {
        console.log(err);
      });

    // if (isCheckBox) {
    //   authAxios
    //     .post(
    //       `${MERCHANT_EXCURSION_ENDPOINT?.MERCHANT_SET_EXCURSION_MULTI_PACKAGE}excursionId=${excursionID}&requestId=123`
    //     )
    //     .then((res) => {
    //       toast.success("Successfully Added.")
    //       setActiveStep(9);
    //       changeUrl(Number(url.searchParams.get("id")), 9);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
    // else{
    //   setActiveStep(11);
    //       changeUrl(Number(url.searchParams.get("id")), 11);
    // }
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <>
      <div className="w-[343px] md:w-[712px] xl:w-[1100px]">
        <div className=" mb-[20px]">
          <p
            className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
          >
            <span className="border-x-[4px] px-[8px]"> Itinerary</span>
          </p>
          <p className="text-center text-btn-secondary text-[14px]">
            Crafting an itinerary tailored to your interests
          </p>
        </div>
        <div className="md:w-[300px] w-[200px] mx-auto">
          <TourMerchantButton
            width={"w-full"}
            name={"Add New"}
            onClick={handleAdd}
          ></TourMerchantButton>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loading></Loading>
          </div>
        ) : (
          itineraryList?.length > 0 && (
            <div className=" my-[20px]">
              <table className="fl-table ">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Sl No.</th>
                    <th>Icon</th>
                    <th>Title</th>
                    <th>ID</th>
                    {/* <th>Ratings</th> */}
                    <th>Description</th>
                    <th>Duration</th>
                    <th>Vehicle</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {itineraryList?.length > 0 &&
                    itineraryList?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + pageSize * currentPage - pageSize}.
                        </td>
                        <td className="cursor-pointer mx-auto">
                          <img
                            className="mx-auto max-w-[40px] max-h-[40px]"
                            src={item?.imageUrl}
                            alt=""
                          />
                        </td>
                        <td>{item?.title}</td>
                        <td>{item?.id}</td>
                        <td>{item?.description}</td>
                        <td>{item?.duration}</td>
                        <td>{item?.vehicle}</td>
                        <td>
                          <button
                            onClick={() => onEditButton(item)}
                            className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>

              {/* <div className="mt-[200px] flex justify-center items-center w-full">
                <CustomButtonGroupExcruions
                  setSaveAndNext={backAndSave}
                  onSubmit={onSubmitButton}
                />
              </div> */}
            </div>
          )
        )}

        <div>
          <ModalCommon
            shown={toggleModal}
            width="max-w-[450px]"
            height="min-h-[300px]"
            outSideOff={false}
            close={() => {
              setToggleModal(false);
              setShowError(false);
            }}
          >
            <div className="px-[40px] py-[20px]">
              <div>
                <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                  Itinerary
                </h2>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Title: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Title : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          title: inputVal,
                        }));
                      }}
                      value={itinerary?.title}
                    />
                  </div>
                  {showError && itinerary?.title.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Title is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Duration: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Duration : "
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          duration: inputVal,
                        }));
                      }}
                      value={itinerary?.duration}
                    />
                  </div>
                  {showError && itinerary?.duration <= 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Duration is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Address: </p>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      placeholder="Address : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          address: inputVal,
                        }));
                      }}
                      value={itinerary?.address}
                    />
                  </div>
                  {showError && itinerary?.address.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Address is required
                    </p>
                  )}
                </div>
                <div className="my-[16px]">
                  <p className="text-btn-secondary">Description: </p>
                  <div className="rounded">
                    <textarea
                      className="w-full p-[8px] h-[150px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                      placeholder="Description : "
                      type="text"
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        setItinerary((prev) => ({
                          ...prev,
                          description: inputVal,
                        }));
                      }}
                      value={itinerary?.description}
                    />
                  </div>
                  {showError && itinerary?.description.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Description is required
                    </p>
                  )}
                </div>

                <div className="my-[16px]">
                  <p className="text-btn-secondary">Vehicle: </p>
                  <Select
                    value={
                      itinerary.vehicle
                        ? { label: itinerary.vehicle, value: itinerary.vehicle }
                        : null
                    }
                    onChange={(data) => {
                      setItinerary((prev) => ({
                        ...prev,
                        vehicle: data ? data.label : "",
                      }));
                    }}
                    components={animatedComponents}
                    options={vehicleList.map((item, index) => ({
                      label: item,
                      value: index,
                    }))}
                    placeholder={"Select vehicle: "}
                    styles={colorStyles}
                  />
                  {showError && itinerary?.vehicle.length === 0 && (
                    <p className=" text-[#da5252] py-[2px] text-[14px]">
                      Vehicle is required
                    </p>
                  )}
                </div>

                <div className=" pt-[10px]">
                  <p className="pb-[5px]">Mode Icon:</p>

                  <div className="flex justify-start items-center gap-x-[8px]">
                    <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                      <img
                        className="w-[40px] h-[40px]"
                        src={itinerary?.mode_icon_url?.url}
                        alt=""
                      />
                    </div>

                    <SingleSelectNew
                      warningView={false}
                      data={iconsList}
                      displayField="title"
                      placeholder={"Icons"}
                      selectedFieldName={"title"}
                      selectedValue={itinerary?.mode_icon_url?.url}
                      setSelectedValue={(mode_icon_url) =>
                        setItinerary((prev) => ({
                          ...prev,
                          mode_icon_url: mode_icon_url,
                        }))
                      }
                      setSelectedObject={() => {}}
                      image={true}
                      isReadOnly={false}
                      temp={temp}
                      setTemp={setTemp}
                    />
                  </div>
                </div>
                {showError && itinerary?.mode_icon_url.length === 0 && (
                  <p className=" text-[#da5252] py-[2px] text-[14px]">
                    Mode icon is required
                  </p>
                )}
              </div>

              <div className="my-[20px]">
                {/* <button
                  name="submit"
                  className="px-[12px] py-[6px] bg-btn-secondary w-full flex justify-center items-center text-bg-primary rounded-[4px]"
                  onClick={addItinerary}
                >
                  Add
                </button> */}
                <TourMerchantButton
                  onClick={addItinerary}
                  width={"w-full"}
                  name={"Add"}
                ></TourMerchantButton>
              </div>
            </div>
          </ModalCommon>
        </div>

        {/* For checking Multi package */}
        <div>
          <ModalCommon
            shown={toggleModalMultiPackage}
            width="max-w-[360px]"
            height="min-h-[120px]"
            outSideOff={false}
            close={() => {
              setToggleModalMultiPackage(false);
              setShowError(false);
            }}
          >
            <div className="py-[32px] px-[32px]">
              <div className=" rounded-[4px] flex items-center gap-x-[12px]">
                <div
                  onClick={() => onCheckBoxChange()}
                  className={`border-[2px] bg-btn-primary  text-txt-secondary p-[4px] w-[28px] h-[28px] rounded-[5px] cursor-pointer`}
                >
                  {isCheckBox ? (
                    <FaCheck className="text-bg-white" />
                  ) : (
                    <HiXMark className="text-bg-white" />
                  )}
                </div>
                <p className="text-btn-secondary">
                  Is Multi category/package ?
                </p>
              </div>
              <p className="pl-[32px] text-[12px] text-btn-primary">
                {isCheckBox ? "( Already Selected )" : "( Please Select )"}
              </p>
              <div className="flex justify-center mt-[20px]">
                <button
                  onClick={() => onSubmitMultiPackage()}
                  className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                >
                  Submit
                </button>
              </div>
            </div>
          </ModalCommon>
        </div>
        <div className="mt-[20px] flex justify-center items-center w-full">
          <CustomButtonGroupExcruions
            setSaveAndNext={backAndSave}
            onSubmit={onSubmitButton}
          />
        </div>
      </div>
    </>
  );
};

export default Itinerary;
