import React, { useEffect, useState, useRef } from "react";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import SingleSelect from "../SingleSelect";
import Pagenation from "../pagenation/Pagenation";
import { useSelector } from "react-redux";
import { termsAndConditionsGet } from "../../store/registration/termsAndConditions/api/termsAndConditionsApi";
import SingleSelectPhoneTwo from "../dropdown/SearchPhoneTwo";
import { apiEndPoint } from "../../contants/Endpoints";
import ReactModal from "react-modal";
import ButtonOutlined from "../ButtonOutlined";
import ButtonFilled from "../ButtonFilled";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import InputComponent from "../InputComponent";
import { Link, useNavigate } from "react-router-dom";

const RegistrationComponent = ({
  action,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneCode,
  setPhoneCode,
  phone,
  setPhone,
  email,
  setEmail,
  password,
  setPassword,
  rePassword,
  setRePassword,
  value,
  setValue,
  values,
  setTncVersion,
  confirmed,
  showOtpModal,
  showSuccess,
  setShowSuccess,
}) => {
  // some helper state
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  // terms and condition state and function
  const [termsAndConditionsData, setTermsAndConditionsData] = useState();

  const [checkTerm, setCheckTerm] = useState(true);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    termsAndConditionsGet();
  }, []);

  const formikRef = useRef(null);
  // terms and condition not enter to the api return

  // terms and consition fetch
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };

    noAuthAxios
      .get(`${apiEndPoint.FETCH_TERMS_AND_CONDITIONS}?requestId=wrwr`, {
        headers: headers,
      })
      .then((response) => {
        setTermsAndConditionsData(response.data.termsAndConditionData);
        setTncVersion(response.data.termsAndConditionData.tncVersion);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  // fetch all country -> phone code
  useEffect(() => {
    noAuthAxios
      .get(`${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=asdf`)
      .then((response) => {
        console.log("response all country data:", response.data.countries);
        setCountryPhoneCodeArr(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toggleShowPass = (elementId) => {
    console.log("ldkjfldsjkf");
    const input = document.getElementById(elementId);
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
    } else {
      input.setAttribute("type", "password");
    }
  };

  const handleApprove = () => {
    setCheckTerm(true);
    setOverlay(false);
  };
  const handleCancel = () => {
    setCheckTerm(false);
    setOverlay(false);
  };

  const sendOTP = () => {
    showOtpModal();
  };
  return (
    <>
      <div>
        <Pagenation Registration="Registration" owner="owner" />
        <HorizontalBar registration="registration" selectedBar="Registration" />
        <div className="container">
          <div className="w-full lg:w-[900px] p-4 mt-12">
            <div
              className="flex gap-1 md:gap-5 min-w-full items-center h-[50px]"
              ref={ref}
            >
              <div className="w-[103px] h-[50px]">
                <SingleSelect
                  title={value}
                  setTitle={setValue}
                  width={width + 1}
                />
              </div>
              <div className="w-full h-full">
                <div className="h-full">
                  <InputComponent
                    warningView={false}
                    required={true}
                    inputValue={firstName}
                    setInputValue={setFirstName}
                    placeholder={"First Name"}
                    name="firstName"
                  />
                </div>
              </div>
            </div>

            <div className=" h-[50px] my-6 rounded-[8px]">
              <InputComponent
                warningView={false}
                required={true}
                inputValue={lastName}
                setInputValue={setLastName}
                placeholder={"Last Name"}
                name="lastName"
              />
            </div>
            {/* <ContactInputDropdown inputValue={phone} setInputValue={setPhone} phoneCode={phoneCode} setPhoneCode={setPhoneCode} /> */}
            <div className="my-6">
              <SingleSelectPhoneTwo
                required={true}
                title={phoneCode}
                setTitle={setPhoneCode}
                confirmed={confirmed}
                setInputValue={setPhone}
                inputValue={phone || ""}
                showOtpModal={sendOTP}
                register={true}
                name={"phone"}
                warningView={false}
                dataArray={countryPhoneCodeArr}
              />
            </div>

            <div className=" h-[50px] w-full">
              <InputComponent
                warningView={false}
                required={true}
                inputValue={email}
                setInputValue={setEmail}
                placeholder={"Email"}
                name="email"
              />
            </div>
            <input
              className="bg-transparent h-0 w-0"
              type="text"
              name="email"
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 gap-y-5 mb-5">
              <div className="col-span-1">
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={password}
                  setInputValue={setPassword}
                  placeholder={"Password"}
                  type={"password"}
                  name="pass"
                  password={true}
                  passwordToggle={toggleShowPass}
                />
              </div>
              <div className="col-span-1">
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={rePassword}
                  setInputValue={setRePassword}
                  placeholder={"Repeat password"}
                  type={"password"}
                  name="confirmPass"
                  password={true}
                  passwordToggle={toggleShowPass}
                />
              </div>
            </div>
            <div className=" mt-6 text-left ">
              <label className="text-[16px] min-w-full flex gap-3 space-start text-txt-primary cursor-pointer hover:text-txt-hint">
                <div className="pt-[0.5px]">
                  <input
                    className="text-2xl text-class"
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                </div>
                <span className="">
                  I consent to receive promotional offers. I can withdraw my
                  consent at any given time
                </span>
              </label>
            </div>
            {/* terms and conditions */}
            {/* <div className=" mt-6 text-left " onClick={(e) => { e.preventDefault(); setCheckTerm(!checkTerm) }} >
              <label className="text-[16px] text-txt-primary  min-w-full flex gap-3 space-start cursor-pointer hover:text-txt-hint">
                <div className="pt-[0.5px]">
                  <input
                    className="text-2xl"
                    type="checkbox"
                    checked={checkTerm}
                    onClick={e => { e.stopPropagation(); setCheckTerm(!checkTerm) }}
                  />
                </div>
                I accept
                <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOverlay(true) }} className="capitalize underline hover:text-txt-hint">terms and conditions</span>
              </label>
            </div> */}

            <div className="text-left w-full text-base18 mt-4 cursor-default">
              <span className="flex gap-2">
                By signing in or creating an account, you agree with our
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open("/terms-and-conditions", "_");
                  }}
                  rel="noopener norefferer"
                >
                  <span className="text-[#0099cc]"> Terms & conditions </span>
                </div>
                and
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    window.open("/privacy", "_");
                  }}
                  rel="noopener noreferrer"
                >
                  <span className=" text-[#0099cc]"> Privacy statement</span>
                </div>
              </span>
            </div>

            <button
              disabled={!checkTerm && !checked}
              onClick={() => {
                action();
              }}
              className={`flex justify-center items-center mt-12 h-[49px] rounded-[300px] w-full px-2 border border-[#9e9989] ${
                checkTerm && checked
                  ? "bg-btn-primary hover:bg-btn-secondary text-bg-white "
                  : "text-[#9e9989] bg-btn-base"
              }`}
            >
              Submit
            </button>
          </div>
        </div>
        <ReactModal isOpen={overlay} onRequestClose={() => setOverlay(false)}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOverlay(false);
            }}
            className="flex flex-col h-full py-8 px-16"
          >
            <div className="flex-grow">
              <h1 className="text-[27px]">Terms and Conditions </h1>
              <div className="my-4 h-auto max-h-[350px] overflow-scroll">
                <div className="">
                  <p className="text-left text-xs whitespace-pre-line">
                    {termsAndConditionsData?.details}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-6 justify-end px-12 ml-auto pb-10">
              <ButtonOutlined
                buttonOutlinedHandle={handleCancel}
                w="200px"
                h="40px"
                title="I Reject"
              />
              <ButtonFilled
                buttonFilledHandle={handleApprove}
                w="200px"
                h="40px"
                title="I Accept"
                arrow="none"
              />
            </div>
          </form>
        </ReactModal>

        <ReactModal
          isOpen={showSuccess}
          onRequestClose={() => setShowSuccess(false)}
        >
          <div className=" flex items-center justify-center bg-bg-primary p-10">
            <div className="text-center w-full">
              <div className="text-3xl w-full mb-10">
                Registration complete!
              </div>
              <ButtonFilled
                title={"Continue"}
                w="100%"
                buttonFilledHandle={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default RegistrationComponent;
