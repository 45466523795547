import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { adminRoute } from "../contants/Constants";
import { BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import CommonSearch from "./admin/CommonSearch";
import Paginations from "./admin/Paginations/Paginations";
import { bcolor, color, tcolor } from "./BookingReviewCart";
const tcolorDrop = {
  All: "text-[#4E90A8]",
  New: "text-[#FF9500]",
  Cancel: "text-[#63a561]",
  Inprogress: "text-[#FE9A9A]",
  Accept: "text-[#FE9A9A]",
};
export default function BookingReviewAdmin() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [dataState, setDataState] = useState("All Bookings");
  const [bookingData, setBookingData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const handleState = (state) => {
    setDataState(state);
    setModalOpen(false);
    setCurrentPage(1);
  };

  const getBooking = () => {
    let url =
      dataState === "All Bookings"
        ? `${BOOKING_ENDPOINTS.GET_BOOKINGSALL_BY_PROPERTY_ADMIN}pageNumber=${currentPage}&pageSize=10&queryString=${searchValue}&requestId=df`
        : dataState === "New Bookings"
        ? `${BOOKING_ENDPOINTS.GET_BOOKINGSNEW_BY_PROPERTY_ADMIN}pageNumber=${currentPage}&pageSize=10&requestId=11`
        : dataState === "Cancel Bookings"
        ? `${BOOKING_ENDPOINTS.GET_REJECTED_BY_ADMIN}pageNumber=${currentPage}&pageSize=10&requestId=11`
        : dataState === "Inprogress Bookings"
        ? `${BOOKING_ENDPOINTS.GET_BOOKINGSINPROGRESS_BY_PROPERTY_ADMIN}pageNumber=${currentPage}&pageSize=10&requestId=11`
        : dataState === "Confirm Bookings"
        ? `${BOOKING_ENDPOINTS.GET_BOOKINGSACCEPT_BY_PROPERTY_ADMIN}pageNumber=${currentPage}&pageSize=10&requestId=11`
        : "";

    authAxios
      .get(url)
      .then((res) => {
        setBookingData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  };

  useEffect(() => {
    setLoading(true);
    getBooking();
  }, [dataState, currentPage, searchValue]);

  console.log("bookingData", currentPage);

  return (
    <div className="mt-[20px] max-w-[1100px] mx-auto">
      {/* filter option */}

      <div className=" text-[14px] relative flex justify-center  items-center">
        <div
          className={`w-[40%] ${
            dataState === "All Bookings" ? "block" : "hidden"
          }`}
        >
          <CommonSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div
          className={`flex ${
            dataState === "All Bookings" ? "w-[60%]" : "w-full"
          }  justify-between items-center gap-x-[40px]`}
        >
          <h2
            className={`${
              dataState === "All Bookings"
                ? "text-[#48C9B0]"
                : dataState === "New Bookings"
                ? "text-[#4E90A8]"
                : dataState === "Cancel Bookings"
                ? "text-[#FE9A9A]"
                : dataState === "Inprogress Bookings"
                ? "text-[#FF9500]"
                : dataState === "Confirm Bookings"
                ? "text-[#63a561]"
                : ""
            } text-[22px]`}
          >
            {dataState}
          </h2>
          <div className="w-[200px]">
            <div
              onClick={() => setModalOpen(!modalOpen)}
              className={`border cursor-pointer   rounded-full py-[2px] px-[10px] flex justify-between items-center ${
                dataState === "All Bookings"
                  ? "text-[#48C9B0]"
                  : dataState === "New Bookings"
                  ? "text-[#4E90A8]"
                  : dataState === "Cancel Bookings"
                  ? "text-[#FE9A9A]"
                  : dataState === "Inprogress Bookings"
                  ? "text-[#FF9500]"
                  : dataState === "Confirm Bookings"
                  ? "text-[#63a561]"
                  : ""
              } ${
                dataState === "All Bookings"
                  ? "border-[#48C9B0]"
                  : dataState === "New Bookings"
                  ? "border-[#4E90A8]"
                  : dataState === "Cancel Bookings"
                  ? "border-[#FE9A9A]"
                  : dataState === "Inprogress Bookings"
                  ? "border-[#FF9500]"
                  : dataState === "Confirm Bookings"
                  ? "border-[#63a561]"
                  : ""
              } `}
            >
              <span>{dataState}</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </div>
            {modalOpen && (
              <div
                className={`absolute w-[200px] z-[60] top-[32px] bg-[#E8F8F5] `}
              >
                <div className="w-full shadow-customShadow  rounded-[5px] flex flex-col justify-center items-center  cursor-pointer">
                  <p
                    onClick={() => handleState("All Bookings")}
                    className="hover:bg-bg-semiwhite w-full text-center text-[#48C9B0] py-[6px]"
                  >
                    All Bookings
                  </p>

                  <p
                    onClick={() => handleState("New Bookings")}
                    className="hover:bg-bg-semiwhite w-full text-[#4E90A8] text-center py-[6px]"
                  >
                    New Bookings
                  </p>
                  <p
                    onClick={() => handleState("Inprogress Bookings")}
                    className="hover:bg-bg-semiwhite w-full text-[#FF9500] text-center py-[6px]"
                  >
                    Inprogress Bookings
                  </p>
                  <p
                    onClick={() => handleState("Confirm Bookings")}
                    className="hover:bg-bg-semiwhite w-full text-center py-[6px] text-[#63a561]"
                  >
                    Confirm Bookings
                  </p>

                  <p
                    onClick={() => handleState("Cancel Bookings")}
                    className="hover:bg-bg-semiwhite w-full text-center py-[6px] text-[#FE9A9A]"
                  >
                    Cancel Bookings
                  </p>

                  <p
                    onClick={() =>
                      navigate(adminRoute.ADMIN_REJECTIONS_BOOKING)
                    }
                    className="hover:bg-bg-semiwhite w-full text-center py-[6px] text-[#E74C3C]"
                  >
                    Rejected By Marchent
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* view table */}
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-[40px] max-w-[1100px]">
            <table
              className={`table-fixed rounded-[8px] border-collapse  w-full`}
            >
              <thead
                className={`sticky top-[88px] shadow-sm ${
                  dataState === "All Bookings"
                    ? "bg-[#48C9B0]"
                    : dataState === "New Bookings"
                    ? "bg-[#4E90A8]"
                    : dataState === "Cancel Bookings"
                    ? "bg-[#FE9A9A]"
                    : dataState === "Inprogress Bookings"
                    ? "bg-[#FF9500]"
                    : dataState === "Confirm Bookings"
                    ? "bg-[#63a561]"
                    : ""
                }  text-bg-primary  text-left`}
              >
                <tr>
                  <th className="  py-[10px] px-[8px]   w-[350px]">
                    Hotel Info.
                  </th>
                  <th className="px-[8px]  py-[10px]  w-[350px]">User Info.</th>

                  <th className=" px-[8px]  py-[10px]  w-[350px]">
                    Booking Info.
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookingData?.rentalBookingReviewData?.map((booking) => (
                  <tr
                    className={`${
                      dataState === "All Bookings"
                        ? "hover:bg-[#48c9af16]"
                        : dataState === "New Bookings"
                        ? "hover:bg-[#4e90a819]"
                        : dataState === "Cancel Bookings"
                        ? "hover:bg-[#fe9a9a11]"
                        : dataState === "Inprogress Bookings"
                        ? "hover:bg-[#ff950012]"
                        : dataState === "Confirm Bookings"
                        ? "hover:bg-[#63a56119]"
                        : ""
                    }  cursor-pointer border-b text-[14px] border-[#eaecef]`}
                  >
                    <td className="py-[8px] px-[28px] gap-x-[12px] ">
                      <div className="flex flex-col">
                        <span className="text-[16px]">
                          {" "}
                          Hotel Name : {booking?.hotelName}
                        </span>
                        <span className="text-[12px]">
                          Booking Time: {booking?.bookingTime}
                        </span>
                      </div>
                    </td>
                    <td className=" py-[10px] px-[8px] ">
                      <div className="flex flex-col items-start justify-start gap-[6px] ">
                        <span>Name : {booking?.userName}</span>
                        <span>Email : {booking?.email}</span>
                        <span>Phone : {booking?.phoneNumber}</span>
                        <span className={`${tcolor[`${booking?.state}`]}`}>
                          Review id : #{booking?.rentalBookingReviewId}
                        </span>

                        <span className={`${tcolor[`${booking?.state}`]}`}>
                          {" "}
                          Order Code : {booking?.orderCode}
                        </span>
                      </div>
                    </td>

                    <td className=" py-[10px] pr-[28px]  ">
                      <div>
                        <h2 className=" mb-[4px] text-txt-hint">
                          Order Summary :{" "}
                        </h2>
                        <div className="flex flex-col gap-y-[6px]">
                          {booking?.roomBookingData?.map((room, index) => (
                            <div
                              className={`${
                                index >= 0 &&
                                index !== booking?.roomBookingData?.length - 1
                                  ? " border-txt-hint border-b-[0.5px]"
                                  : "border-none"
                              } pb-[6px]`}
                            >
                              <div className="flex items-center justify-start space-x-[2px]">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className={`w-[14px] h-[14px] text-txt-hint ${
                                      tcolor[`${booking?.state}`]
                                    } `}
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                                <p>
                                  {room?.roomCategoryName}{" "}
                                  <span
                                    className={`text-[14px] ${
                                      tcolor[`${booking?.state}`]
                                    }`}
                                  >
                                    x{room?.noOfTimes}
                                  </span>
                                </p>
                              </div>
                              <div className="flex justify-between">
                                <p>In : {room?.checkinDate}</p>
                                <p>Out : {room?.checkoutDate}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex justify-between ">
                        <div
                          className={`flex border border-[0.3px] px-[5px] ${
                            bcolor[`${booking?.state}`]
                          } rounded-[4px] `}
                        >
                          <p className="text-txt-hint">Total :</p>
                          <p className="text-txt-primary pl-[2px]">
                            {booking?.totalAmount} BDT
                          </p>
                        </div>
                        <p
                          className={`${
                            color[`${booking?.state}`]
                          } px-[6px] py-[1px] text-bg-white rounded-[2px]`}
                        >
                          {booking?.state}
                        </p>
                      </div>
                      {/* <span
                    className={`${
                      color[`${booking?.state}`]
                    } px-[8px] py-[3px] rounded-[4px]`}
                  >
                    {booking?.state}
                  </span>
                  <span className="flex  flex-col gap-[6px]">
                    {booking?.roomBookingData?.map((roomeBooking) => (
                      <div className="flex flex-col gap-[6px] border p-[4px] rounded-[8px]  text-[12px]">
                        <span>Category : {roomeBooking?.roomCategoryName}</span>
                        <span>Check In : {roomeBooking?.checkinDate}</span>
                        <span>Check Out : {roomeBooking?.checkoutDate}</span>
                      </div>
                    ))}
                  </span> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="">
            <Paginations
              currentPage={currentPage}
              totalCount={Math.ceil(bookingData?.totalEntries / 10)}
              pageSize={10}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}
    </div>
  );
}
