import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { convertStringToObject } from "../ParentPackageSelected";
import FoodPackageDaySelectCheckbox from "./FoodPackageDaySelectCheckbox";

const FoodPackageDaySelect = ({
  foodMealType,
  setAllFoodPackage,
  allFoodPackage,
}) => {
  // const array = [1,2,3];
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [getToursById, setGetToursById] = useState();

  // console.log("convertData", convertData.tour_id)
  console.log("convertData", convertData);

  // For get Days
  const numberOfDay = Array.from(
    { length: getToursById?.number_of_days },
    (_, index) => index + 1
  );
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData?.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetToursById(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  // console.log("getToursById Days:", getToursById?.number_of_days)

  return (
    <div className="my-[40px]">
      <div className="">
        <p className="xl:text-[20px] md:text-[18px]">
          <span className="bg-btn-orange text-bg-white px-[12px] py-[8px] rounded-[4px]">
            Daywise Selection :
          </span>
        </p>
      </div>

      <div className="my-[20px]">
        {numberOfDay?.map((dayNumber, index) => (
          <Fragment key={index}>
            <FoodPackageDaySelectCheckbox
              setAllFoodPackage={setAllFoodPackage}
              foodMealType={foodMealType}
              dayNumber={dayNumber}
              allFoodPackage={allFoodPackage}
            ></FoodPackageDaySelectCheckbox>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default FoodPackageDaySelect;
