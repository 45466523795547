import { CiEdit } from "react-icons/ci";
import bus from "../../assets/icon/bus.svg";

const TransportationPackageInitial = ({
  tour_package_transportation_packages,
  handlePackage,
}) => {
  // console.log(tour_package_transportation_packages);

  return (
    <>
      <div className="w-full flex xl:p-[16px] md:p-[8px] p-[4px]   bg-bg-white rounded">
        <div className="w-[8%]  flex items-center justify-center ">
          <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee]">
            <img
              className="xl:w-16 md:w-12 w-8 xl:h-16 md:h-12 h-[32px]"
              src={bus}
              alt=""
            />
          </span>
        </div>

        <div className=" w-[84%] xl:px-[12px] lg:px-[10px] md:px-[8px] px-[6px] flex flex-col items-start  xl:text-[14px] lg:text-[14px] md:text-[12px] text-[10px]">
          <div className="bg-btn-secondary  text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] mb-[8px] rounded">
            {"Transportation"}
          </div>
          <div>
            <div className=" flex flex-wrap w-full gap-[16px]">
              {tour_package_transportation_packages &&
                tour_package_transportation_packages?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between w-[250px] rounded p-[8px]  shadow-customShadow  text-[10px] relative overflow-clip"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[10px] left-[-55px] rotate-[-45deg] ${
                        item?.transportation_package_is_default?.value
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <p className="text-[10px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>
                    <p className="bg-[#e1e5ed] text-btn-secondary xl:text-[16px] text-[14px] text-center px-[4px] py-[2px] rounded mb-[8px]">
                      {item?.transportation_route_id?.label}
                    </p>

                    <div className="xl:text-[12px] text-[10px]">
                      <p className="text-txt-primary">
                        {" "}
                        <span className="text-bg-semiblack">
                          {item?.transportation_mode_id?.label}:
                        </span>{" "}
                        {item?.transportation_provider_id?.label} -{" "}
                        {item?.transportation_brand_id.label}
                      </p>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">Trip type: </span>
                        {item?.trip_type?.label}
                      </p>
                      <div className="">
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Included: </span>
                          {item?.transportation_package_is_default?.label}
                        </p>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">Ac: </span>
                          {item?.is_ac?.label}
                        </p>
                      </div>
                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">
                          Price per quantity:{" "}
                        </span>
                        {item?.transportation_package_unit_price}৳ X{" "}
                        {item?.transportation_package_quantity}
                      </p>
                      <p className="py-[4px] xl:text-[16px] text-[14px] text-center">
                        Total Price: {item?.transportation_package_total_price}৳
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="w-[8%] flex items-center text-[30px]">
          <div className="flex">
            {/* <button
              className="cursor-pointer text-[#ef4444] mr-[8px]"
              title="Remove"
            >
              <CiCircleRemove></CiCircleRemove>
            </button> */}
            <button
              onClick={() => handlePackage(2, "chose")}
              className="cursor-pointer text-[#22c55e]"
              title="Edit"
            >
              <CiEdit></CiEdit>
            </button>
          </div>
        </div>
      </div>
      <div className={`${"xl:h-[32px] md:h-[24px] h-[16px]"}`}></div>
    </>
  );
};

export default TransportationPackageInitial;
