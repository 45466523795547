import React from "react";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";
import fastFood from "../../../assets/icon/fast-food.svg";

const FoodPackageInitial = ({ foodPackages, handlePackage }) => {
  // console.log(tour_package_meal_packages);
  console.log("foodPackages", foodPackages);

  const mealTypeName = useSelector((state) => state.tourMealTypeReducer?.data);
  const getKeyFromValue = (value) => {
    for (const key in mealTypeName) {
      if (mealTypeName[key] === value) {
        return key;
      }
    }
    return null;
  };

  return (
    <>
      <div className="w-full flex xl:p-[16px] md:p-[8px] p-[4px]   bg-bg-white rounded">
        <div className="w-[8%]  flex items-center justify-center ">
          <span className="xl:px-[8px] md:px-[6px] px-[4px]  xl:py-[4px] md:py-[3px] py-[2px] bg-[#dce9ee]">
            <img
              className="xl:w-16 md:w-12 w-8 xl:h-16 md:h-12 h-[32px]"
              src={fastFood}
              alt=""
            />
          </span>
        </div>

        <div className=" w-[84%] xl:px-[12px] lg:px-[10px] md:px-[8px] px-[6px] flex flex-col items-start  xl:text-[14px] lg:text-[14px] md:text-[12px] text-[10px]">
          <div className="bg-btn-secondary  text-bg-white px-[8px] lg:py-[8px] md:py-[6px] py-[4px] mb-[8px] rounded">
            {"Food"}
          </div>
          <div>
            <div className=" flex flex-wrap gap-[16px] w-full">
              {/* {foodPackages &&
                foodPackages?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between w-[220px] rounded p-[8px]  shadow-customShadow overflow-clip relative"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[10px] left-[-55px] rotate-[-45deg] ${
                        item?.meal_package_is_default?.value
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <p className="text-[10px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>
                    <p className="bg-[#e1e5ed] text-btn-secondary xl:text-[16px] text-[14px] text-center px-[4px] py-[2px] rounded">
                      {item?.meal_type_id?.label}
                    </p>
                    <div className="flex flex-wrap mt-[4px] xl:text-[12px] text-[10px]">
                      <p className="text-bg-semiblack ">Food Items: &nbsp;</p>
                      {item?.food_items_ids &&
                        item?.food_items_ids.map((food, ind) => (
                          <p
                            key={ind}
                            className="flex gap-x-[1px] justify-start items-center"
                          >
                            <AiOutlineCheck></AiOutlineCheck>
                            {food?.label}
                            {","}&nbsp;
                          </p>
                        ))}
                    </div>

                    <div className="xl:text-[12px] text-[10px]">
                      <p className="text-txt-primary">
                        {" "}
                        <span className="text-bg-semiblack">
                          Package include:
                        </span>{" "}
                        {item?.meal_package_is_default?.label}{" "}
                      </p>

                      <p className="text-txt-primary">
                        <span className="text-bg-semiblack">
                          Price per quantity:{" "}
                        </span>
                        {item?.meal_package_unit_price}৳ X{" "}
                        {item?.meal_package_quantity}
                      </p>
                      <p className="py-[4px] xl:text-[16px] text-[14px] text-center">
                        Total Price: {item?.meal_package_total_price}৳
                      </p>
                    </div>
                  </div>
                ))} */}
              <div className="grid grid-cols-3 gap-[10px] ">
                {foodPackages?.map((foodPackage, index) => (
                  <div
                    key={index}
                    className=" shadow-customShadow rounded-[4px] py-[10px] pr-[10px] pl-[15px] relative overflow-hidden text-[14px]"
                  >
                    <div
                      className={`bg-btn-secondary w-[150px] text-bg-primary text-center absolute top-[15px] left-[-47px] rotate-[-45deg] ${
                        foodPackage?.tour_package_default_food_option
                          ? "block"
                          : "hidden"
                      } `}
                    >
                      <p className="text-[14px] font-extrabold uppercase ">
                        Included
                      </p>
                    </div>

                    <p className="text-center bg-btn-secondary py-[4px] rounded-[4px] text-bg-primary">
                      {foodPackage?.day_number} Day
                    </p>

                    <div className="flex flex-wrap gap-[8px] ">
                      {foodPackage?.tour_package_meal_packages?.map(
                        (mealPackage, index) => (
                          <div
                            className="mt-[10px] border p-[4px] rounded-[4px] border-[#ddd] flex flex-col gap-[4px]"
                            key={index}
                          >
                            <div className="flex flex-wrap gap-[4px]">
                              <p className="w-full text-center bg-btn-secondary text-bg-primary rounded-[4px]">
                                {getKeyFromValue(mealPackage?.meal_type_id)}
                              </p>
                              {mealPackage?.food_items?.map(
                                (foodItem, index) => (
                                  <div className="flex justify-start gap-x-[1px] items-center">
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-[20px] h-[20px]"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M4.5 12.75l6 6 9-13.5"
                                        />
                                      </svg>
                                    </span>
                                    {foodItem?.food_item_name}
                                  </div>
                                )
                              )}
                            </div>

                            <p className="flex gap-x-[4px] justify-start items-center">
                              <span className="text-[12px]"> Unit Price:</span>
                              {mealPackage?.meal_package_unit_price}{" "}
                            </p>
                            <p className="flex gap-x-[4px] justify-start items-center">
                              <span className="text-[12px]">
                                {" "}
                                Total Price :
                              </span>
                              {foodPackage?.tour_package_food_option_price}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-[8%] flex items-center text-[30px]">
          <div className="flex">
            {/* <button
              className="cursor-pointer text-[#ef4444] mr-[8px]"
              title="Remove"
            >
              <CiCircleRemove></CiCircleRemove>
            </button> */}
            <button
              onClick={() => handlePackage(0, "food")}
              className="cursor-pointer text-[#22c55e]"
              title="Edit"
            >
              <CiEdit></CiEdit>
            </button>
          </div>
        </div>
      </div>
      <div className={`${"xl:h-[32px] md:h-[24px] h-[16px]"}`}></div>
    </>
  );
};

export default FoodPackageInitial;
