import React from "react";
import TourMerchantButton from "../TourMerchantButton";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const AbibilityPackageOption = () => {
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1)
      }

    const submitButton = () => {
        toast.success("Nothing to show")
    }

  const optionId = [
    {
      _id: "PP101",
      date: "01-11-23",
      calcuclated_cost: 11380,
      rack_rate: 14000,
      corporate_rate: 12000,
      bookable_seat: 2,
    },
    {
      _id: "PP102",
      date: "01-11-23",
      calcuclated_cost: 11380,
      rack_rate: 14000,
      corporate_rate: 12000,
      bookable_seat: 2,
    },
    {
      _id: "PP102",
      date: "01-11-23",
      calcuclated_cost: 11380,
      rack_rate: 14000,
      corporate_rate: 12000,
      bookable_seat: 2,
    },
  ];

  

  return (
    <div>
      <div>
        <p className="md:text-[20px] text-[18px] text-center text-btn-secondary my-[20px]">
          Family Package Option Id: {"P101"}
        </p>
      </div>

      <div className="my-[20px]">
        <table className="fl-table mb-[40px]">
          <thead className="sticky top-[88px]">
            <tr>
              <th>Date</th>
              <th>Calculated Cost</th>
              <th>Rack Rate</th>
              <th>Corporate Rate</th>
              <th>Bookable Seat</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {optionId?.map(
              (item, i) => (
                <tr key={i}>
                  <td>{item?.date}</td>
                  
                  <td>{item?.calcuclated_cost}</td>
                  <td>{item?.rack_rate}</td>
                  <td>{item?.corporate_rate}</td>
                  <td>{item?.bookable_seat}</td>
                  <td>
                  <div>
                    <TourMerchantButton name={"Make Availble"} onClick={() => submitButton()}
                    ></TourMerchantButton>
                  </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton()}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>
    </div>
  );
};

export default AbibilityPackageOption;
