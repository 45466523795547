import React, { useEffect, useState } from "react";
import TourMerchantButton from "../../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../../components/common/ModalCommon";
import SingleSelectNew from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { authAxios } from "../../../utils/axiosWrapper";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { toast } from "react-toastify";
import Paginations from "../Paginations/Paginations";

const ActPackageVariable = () => {
  const [success, setSuccess] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [temp, setTemp] = useState("");
  const [iconsList, setIconsList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchValue, setSearchValue] = useState(" ");
  let pageSize = 5;

  const handleEdit = () => {
    setToggleModal(true);
  };

  const [groupData, setGroupData] = useState({
    mode_icon_url: {},
    variable: "",
    label: "",
    id: 0
  });

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  const payloadMethod = {
    value: groupData?.variable,
    label: groupData?.variable,
    imageUrl: groupData?.mode_icon_url?.url,
    id: groupData?.id,
    requestId: "123",
  };

  const onSubmit = () => {
    setSuccess(true);
    authAxios
      .post(`${apiEndPoint.ADMIN_ADD_UPDATE_PACKAGE_VARIABLE}`, payloadMethod)
      .then((res) => {
        toast.success("Successfully Added!");
        setSuccess(false);
        setToggleModal(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Added Failed!");
        setSuccess(false);
      });
  };

  const onEditButton = (property) => {
    setToggleModal(true);
    setGroupData((prev) => ({
        ...prev,
        mode_icon_url: {url: property?.imageUrl},
        variable: property?.value,
        label: property?.label,
        id: property?.id
    }))
  }

  const onDeleteById = (id) => {
    authAxios.delete(`${apiEndPoint?.ADMIN_DELETE_BY_ID_VARIABLE}?id=${id}&requestId=123`)
    .then((res) => {
        setSuccess(true)
        toast.success("Successfully Deleted")
        setSuccess(false)
    })
    .catch((err) => {
        console.log(err)
        toast.error("Delete Item failed!")
    })
  }

  useEffect(() => {
    authAxios
      .get(
        `${apiEndPoint.ADMIN_GET_PAGINATION_PACKAGE_VARIABLE}?pageNumber=${currentPage}&pageSize=${pageSize}&queryString=${searchValue}&requestId=123`
      )
      .then((res) => {
        setAllData(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [success, currentPage, pageSize, searchValue]);



  return (
    <div className="my-[32px] px-[40px]">
      <div>
        <TourMerchantButton
          name={"Add Package Variable"}
          onClick={handleEdit}
        ></TourMerchantButton>
      </div>

      {/* Table */}
      <div className="">
        {allData?.length > 0 && (
          <div>
            <table className="fl-table my-[20px]">
              <thead className="sticky top-[88px]">
                <tr>
                  <th>Sl No.</th>
                  <th>Icon</th>
                  <th>Name</th>
                  <th>ID</th>
                  <th className="w-[150px] lg:w-[300px]">Action</th>
                </tr>
              </thead>
              <tbody>
                {allData?.length > 0 &&
                  allData?.map((property, index) => (
                    <tr key={index}>
                      <td>{index + 1 + pageSize * currentPage - pageSize}.</td>
                      <td className="cursor-pointer mx-auto">
                        <img
                          className="mx-auto"
                          src={property?.imageUrl}
                          alt=""
                        />
                      </td>
                      <td>{property?.label}</td>
                      <td>{property?.id}</td>
                      <td className="flex py-[16px] gap-x-[8px]">
                        <button
                            onClick={() => onEditButton(property)}
                          className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                        >
                          Edit
                        </button>
                        <button
                            onClick={() => onDeleteById(property?.id)}
                          className="bg-[#ef4444] text-bg-white w-full px-[10px] py-[8px] hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="my-10">
              <Paginations
                currentPage={currentPage}
                totalCount={totalPage}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>

      {/* For Modal */}
      <div>
        <ModalCommon
          shown={toggleModal}
          width="max-w-[500px]"
          height="min-h-[200px]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
          }}
        >
          <div className="py-[24px] px-[32px]">
            <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
              Add Package Variable
            </h2>

            <div className="my-[16px]">
              <p className="text-btn-secondary">Add Variable: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] "
                  placeholder="Like : 125 CC Pulser"
                  type="text"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setGroupData((prev) => ({
                      ...prev,
                      variable: inputVal,
                    }));
                  }}
                  value={groupData?.variable}
                />
              </div>
              {showError && groupData?.variable?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  Title is required.
                </p>
              )}
            </div>

            <div>
              <div>
                <p className="pb-[5px] text-btn-secondary">Mode Icon:</p>
              </div>
              <div className="flex justify-start items-center gap-x-[8px]">
                <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                  <img
                    className="w-[40px] h-[40px]"
                    src={
                      groupData?.mode_icon_url?.url
                        ? groupData?.mode_icon_url?.url
                        : groupData?.mode_icon_url
                    }
                    alt=""
                  />
                </div>

                <SingleSelectNew
                  warningView={false}
                  data={iconsList}
                  displayField="title"
                  placeholder={"Icons"}
                  selectedFieldName={"title"}
                  selectedValue={groupData?.mode_icon_url?.url}
                  setSelectedValue={(mode_icon_url) =>
                    setGroupData((prev) => ({
                      ...prev,
                      mode_icon_url: mode_icon_url,
                    }))
                  }
                  setSelectedObject={() => {}}
                  image={true}
                  isReadOnly={false}
                  temp={temp}
                  setTemp={setTemp}
                />
              </div>
            </div>

            <div className="my-[24px]">
              <TourMerchantButton
                onClick={onSubmit}
                name={"Submit"}
                width={"w-full"}
              ></TourMerchantButton>
              
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default ActPackageVariable;
