import React, { useState, useEffect, Fragment, useRef } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { useNavigate } from "react-router";
import { adminRoute } from "../../contants/Constants";
import { apiEndPoint } from "../../contants/Endpoints";
import PaginationComponent from "../../components/common/Pagination";
import Loading from "../../components/Loader/Loading";
import InputComponentSecond from "../../components/InputComponentSecond";

const AllContracts = ({ setPosition, setSuperContractId }) => {
  const navigate = useNavigate();
  // const router = useRoute
  const [categoryListData, setCategoryListData] = useState([]);
  const [contractName, setContractName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [total, setTotal] = useState(0);

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [loaderSpinner, setLoadderSpinner] = useState(false);

  function getStylesByValue(status) {
    switch (status) {
      case "REJECTED":
        return "bg-[#ef4444] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
      case "PENDING":
        return "bg-[#f59e0b] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
      case "ACCEPTED":
        return "bg-[#22c55e] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
      case "OPEN":
        return "bg-[#84cc16] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
      case "EMPTY":
        return "bg-[#f43f5e] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
      default:
        return "bg-[#64748b] text-[#FFFBEF]  text-[10px] xl:text-[14px] md:text-[12px]";
    }
  }

  // fetch country all
  useEffect(() => {
    getContracts();
  }, [currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(0);
    getContracts();
  }, [window.location.pathname, contractName, phoneNumber]);

  const getContracts = () => {
    setLoadderSpinner(true);
    authAxios
      .get(
        `${
          apiEndPoint.FETCH_ALL_CONTRACTV2
        }?contractName=${contractName}&phoneNumber=${phoneNumber}&requestId=werwer&type=${
          window.location.pathname.includes("all") ? "" : "SUBMITTED"
        }&pageSize=${pageSize}&pageNo=${currentPage}`
      )
      .then((response) => {
        //console.log("category data: ", response.data.contractList);
        setCategoryListData(response.data?.contractSummaryDataList);
        setTotal(response.data?.totalElements);
        setLoadderSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickContract = (superContractId) => {
    navigate(`${adminRoute.ADMIN_OWNERV2}?ownerId=${superContractId}`);
    localStorage.setItem("superContractId", superContractId);
  };

  return (
    <>
      {/* <div className="flex flex-cols w-full">
        <div>
          <h2 className="text-3xl mb-6 text-center">Ghuddy Admin</h2>
          <div className="flex justify-between gap-x-6 items-center">
            <h2 className="text-2xl my-6">Total Contract: {total}</h2>

            <div className="flex gap-2">
              <div className="rounded border h-[50px]">
                <input
                  className="px-4 w-full bg-[transparent] h-full"
                  placeholder="Search phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="rounded border h-[50px]">
                <input
                  className="px-4 w-full bg-[transparent] h-full"
                  placeholder="Search contract name"
                  value={contractName}
                  onChange={(e) => setContractName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <table id="students" className="min-w-full leading-normal">
            <thead>
              <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Contract ID
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Contract Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Phone Number
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Owner Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Contract Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Document Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Property Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Category Status
                </th>
              </tr>
            </thead>

            <tbody>
              {categoryListData &&
                categoryListData.map((member, index) => {
                  const {
                    contractId,
                    status,
                    ownerStatus,
                    contractInfoStatus,
                    phoneNumber,
                    documentStatus,
                    propertyStatus,
                    categoryStatus,
                    contractName,
                  } = member; //destructuring
                  return (
                    <tr
                      className="cursor-pointer"
                      onClick={() => handleClickContract(contractId)}
                      key={contractId}
                    >
                      <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                        {contractId}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {contractName}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {phoneNumber}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {status}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {ownerStatus}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {contractInfoStatus}
                      </td>
                      <td className="px-5 py-5 border-b border-t bg-white text-sm">
                        {documentStatus}
                      </td>
                      <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                        {propertyStatus}
                      </td>
                      <td className="px-5 py-5 border-b border-r border-t bg-white text-sm">
                        {categoryStatus}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <PaginationComponent
            totalElements={total}
            currentPage={currentPage}
            dataSize={categoryListData.length}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            showCount={true}
          />
        </div>
      </div> */}

      <div className="my-16">
        <div className="xl:text-4xl lg:text-3xl text-2xl  mb-6 text-center text-btn-primary">
          Ghuddy Contracts
        </div>

        <div className="flex justify-between items-center">
          <div className="text-2xl text-btn-secondary">
            Total Contract : {categoryListData?.length}
          </div>

          <div className="my-8 flex gap-4 justify-end mx-4">
            <div className="relative text-gray-600">
              <InputComponentSecond
                type={"number"}
                name={"search"}
                value={phoneNumber}
                placeholder={"Search phone number"}
                setValue={setPhoneNumber}
                height={"h-12"}
                width={"xl:w-72 md:w-60 w-48"}
              ></InputComponentSecond>
            </div>
            <div className="relative text-gray-600">
              <InputComponentSecond
                type={"text"}
                name={"search"}
                value={contractName}
                placeholder={"Search contract name"}
                setValue={setContractName}
                height={"h-12"}
                width={"xl:w-72 md:w-60 w-48"}
              ></InputComponentSecond>
            </div>
          </div>
        </div>

        {loaderSpinner ? (
          <div className="flex items-center justify-center">
            <Loading></Loading>
          </div>
        ) : categoryListData?.length === 0 ? (
          <div className="my-10 text-center">
            <h2 className="text-2xl md:text-3xl xl:text-4xl text-btn-secondary">
              No Data Found!
            </h2>
          </div>
        ) : (
          <>
            <table className="table-auto min-w-full bg-btn-base">
              <thead className="border-b-[2px] border-bg-semiwhite">
                <tr className="text-bg-primary text-[12px] xl:text-[16px] md:text-[14px] bg-[#48C9B0]">
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Contract ID
                  </th>
                  <th className="p-[12px] w-2/12 font-semibold text-bg-primary ">
                    Contract Name
                  </th>

                  <th className="p-[12px] w-2/12 font-semibold text-bg-primary">
                    Phone Number
                  </th>
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Status
                  </th>
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Owner Status
                  </th>
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Contract Status
                  </th>
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Document Status
                  </th>
                  {/* for new tnc update */}
                  {/* <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Property Status
                  </th>
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Category Status
                  </th> */}
                  <th className="p-[12px] w-1/12 font-semibold text-bg-primary">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {categoryListData &&
                  categoryListData?.map((item, indx) => (
                    <Fragment key={item?.contractId}>
                      <tr className="bg-bg-semiwhite text-center text-[12px] xl:text-[16px] md:text-[14px] hover:bg-[#48c9af16]">
                        <td className="h-[86px]">{item?.contractId}</td>
                        <td className="">{item?.contractName}</td>

                        <td className="">{item?.phoneNumber}</td>
                        <td>
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.status
                            )}`}
                          >
                            {item?.status}
                          </span>
                        </td>
                        <td className="">
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.ownerInfoStatus
                            )}`}
                          >
                            {item?.ownerInfoStatus}
                          </span>
                        </td>
                        <td className="">
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.contractInfoStatus
                            )}`}
                          >
                            {item?.contractInfoStatus}
                          </span>
                        </td>
                        <td className="">
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.documentInfoStatus
                            )}`}
                          >
                            {item?.documentInfoStatus}
                          </span>
                        </td>
                        {/* for new tnc update */}
                        {/* <td className="">
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.propertyStatus
                            )}`}
                          >
                            {item?.propertyStatus}
                          </span>
                        </td>
                        <td className="">
                          <span
                            className={`px-2 py-1 rounded ${getStylesByValue(
                              item?.categoryStatus
                            )}`}
                          >
                            {item?.categoryStatus}
                          </span>
                        </td> */}
                        <td>
                          <div className="flex justify-center items-center ">
                            <button
                              title="view details"
                              onClick={() =>
                                handleClickContract(item.merchantId)
                              }
                              className="border px-[32px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[12px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="h-[20px]"></tr>
                    </Fragment>
                  ))}
              </tbody>
            </table>

            <div className="my-8">
              <PaginationComponent
                totalElements={total}
                currentPage={currentPage}
                dataSize={categoryListData?.length}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                showCount={true}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllContracts;
