import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ModalCommon from "../../components/common/ModalCommon";

import { merchantTourRoute } from "../../contants/Constants";

import { toast } from "react-toastify";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import CustomInputWithDropdown from "../../pages/admin/NewTourCreateToll/CustomInputWithDropdown";
import SliderCart from "../../pages/admin/NewTourCreateToll/SliderCart";
import { authAxios } from "../../utils/axiosWrapper";
import MarchantTourSubscribeCard from "./MarchantTourSubscribeCard";
import TourCard from "../TourCard";
import { useDispatch } from "react-redux";
import { setTourStepperValue } from "../../store/marchantDashboard/categoryTour/tourSetupStepper";

export function convertTo12HourFormat(time24) {
  const [hours, minutes] = time24.split(":");
  const date = new Date(0, 0, 0, hours, minutes);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

export function generateArray(endValue) {
  const result = [];
  for (let i = 1; i <= endValue; i++) {
    result.push(i);
  }
  return result;
}
const MarchantTourSubscribe = () => {
  const [getTours, setGetTours] = useState();
  const [modalShown, setToggleModal] = useState(false);

  const navigate = useNavigate();
  const marchantId = localStorage.getItem("id");
  const { id } = useParams();

  const [activites, setActivites] = useState({
    activity_day_number: 0,
    activity_end_time: "",
    activity_id: 0,
    activity_start_time: "",
  });

  const [tourSubscribe, setTourSubscribe] = useState({
    merchant_id: Number(marchantId),
    tour_activities: [],
    tour_id: Number(id),
    tour_reporting_time: "",
    tour_starting_place: "",
  });

  const dayArray = generateArray(getTours?.number_of_days);
  const number_of_daysArray = dayArray?.map((day) => ({
    label: day,
    value: day,
  }));

  useEffect(() => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API
        }${id}?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
      })
      .catch((err) => {});
  }, []);

  const activityVariable = getTours?.tour_itinerary?.map((activity) => ({
    label: activity.tour_activity_name,
    id: activity.tour_activity_id,
  }));

  // const handleRemoveActivites = (ind) => {
  //   const newArray = tourSubscribe?.tour_activities?.filter(
  //     (activity, index) => index !== ind
  //   );
  //   setTourSubscribe((prev) => ({
  //     ...prev,
  //     tour_activities: newArray,
  //   }));
  // };
  const subscribeTour = (exit) => {
    const modifiedObject = {
      ...tourSubscribe,
      tour_activities: tourSubscribe.tour_activities.map((activity) => ({
        ...activity,

        activity_day_number: activity.activity_day_number.value,
      })),
    };

    authAxios
      .post(`${baseUrl + apiEndPoint.MARCHENT_POST_SUBSCRIBE_TOUR_API}`, {
        ...modifiedObject,
      })
      .then((res) => {
        if (res?.status === 200) {
          // navigate(
          //   `${merchantTourRoute.CREATE_TOUR}${res?.data?.subscribed_tour?.subscribed_tour_id}`
          // );
          if (exit === false) {
            navigate(
              `${merchantTourRoute.TOURONE}tour_id=${id}&subscribeId=${res?.data?.subscribed_tour?.subscribed_tour_id}`
            );
          } else {
            navigate(merchantTourRoute.TOUR_DASHBOARD);
          }
        } else {
          toast.success("Something went wrong");
        }
      })
      .catch((err) => console.log(err));

    // navigate(merchantTourRoute.CREATE_TOUR);
  };

  const iconObject = {
    location: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M6 0C4.54184 0.00172024 3.14389 0.581735 2.11282 1.61281C1.08174 2.64389 0.501726 4.04184 0.500006 5.5C0.498259 6.69161 0.887496 7.85089 1.608 8.8C1.608 8.8 1.758 8.9975 1.7825 9.026L6 14L10.2195 9.0235C10.2415 8.997 10.392 8.8 10.392 8.8L10.3925 8.7985C11.1126 7.84981 11.5017 6.69106 11.5 5.5C11.4983 4.04184 10.9183 2.64389 9.88718 1.61281C8.85611 0.581735 7.45816 0.00172024 6 0ZM6 7.5C5.60444 7.5 5.21776 7.3827 4.88886 7.16294C4.55996 6.94318 4.30362 6.63082 4.15224 6.26537C4.00087 5.89991 3.96126 5.49778 4.03843 5.10982C4.1156 4.72186 4.30608 4.36549 4.58579 4.08579C4.86549 3.80608 5.22186 3.6156 5.60982 3.53843C5.99778 3.46126 6.39991 3.50087 6.76537 3.65224C7.13082 3.80362 7.44317 4.05996 7.66294 4.38886C7.8827 4.71776 8 5.10444 8 5.5C7.99934 6.03023 7.78841 6.53855 7.41348 6.91348C7.03855 7.28841 6.53023 7.49934 6 7.5Z"
          fill="white"
        />
      </svg>
    ),

    timer: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_11251_6181)">
          <path
            d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM6.5 5.9395C6.50006 6.02426 6.47856 6.10764 6.43754 6.1818C6.39652 6.25597 6.33732 6.3185 6.2655 6.3635L4.3455 7.5635C4.28969 7.59837 4.22756 7.6219 4.16265 7.63275C4.09775 7.64361 4.03134 7.64157 3.96722 7.62676C3.9031 7.61195 3.84253 7.58465 3.78896 7.54643C3.73539 7.50821 3.68987 7.45981 3.655 7.404C3.62014 7.34819 3.59661 7.28606 3.58575 7.22115C3.5749 7.15624 3.57693 7.08984 3.59175 7.02572C3.60656 6.9616 3.63385 6.90102 3.67207 6.84745C3.71029 6.79388 3.75869 6.74837 3.8145 6.7135L5.5 5.6625V3.5C5.5 3.36739 5.55268 3.24022 5.64645 3.14645C5.74022 3.05268 5.86739 3 6 3C6.13261 3 6.25979 3.05268 6.35356 3.14645C6.44732 3.24022 6.5 3.36739 6.5 3.5V5.9395Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_11251_6181">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  const dispatch = useDispatch();
  const handleSetStepperValue = (value) => {
    dispatch(setTourStepperValue(value));
  };

  const changeToSetup = () => {
    handleSetStepperValue(1)
    subscribeTour(false)
  }


  return (
    <div>
      <div className="max-w-[1100px] mx-auto">
        {/* New Design */}
        <div>
          <div className="flex gap-x-[32px]">
            <div>
              {getTours && (
                <TourCard tours={getTours} subscribeButton={false}></TourCard>
              )}
            </div>
            <div className="w-full flex flex-col gap-[32px]">
              <div className="flex flex-col gap-y-[8px]">
                <div className="flex flex-col gap-[4px] ">
                  <p className="text-[12px] text-tour-secondary">
                    Meeting point
                  </p>
                  <div className="flex gap-[8px] h-[36px]">
                    <input
                      className="w-full p-[8px] text-[14px] text-tour-text rounded-[8px] font-normal border-[1px] border-tour-input placeholder:text-tour-input"
                      type="text"
                      placeholder="Enter Meeting Point"
                      onChange={(e) =>
                        setTourSubscribe((prev) => ({
                          ...prev,
                          tour_starting_place: e.target.value,
                        }))
                      }
                    />
                    <div className="flex justify-center items-center bg-tour-primary w-[36px] h-[36px] rounded-[8px]">
                      {iconObject?.location}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-[4px] ">
                  <p className="text-[12px] text-tour-secondary">
                    Meeting time
                  </p>
                  <div className="flex gap-[8px] h-[36px]">
                    <input
                      className="w-full p-[8px] text-[14px] text-tour-text rounded-[8px] font-normal border-[1px] border-tour-input outline-none placeholder:text-tour-input"
                      type="time"
                      placeholder="Select Meeting Time"
                      id="session-time"
                      onChange={(e) =>
                        setTourSubscribe((prev) => ({
                          ...prev,
                          tour_reporting_time: e.target.value,
                        }))
                      }
                    />
                    <div
                      className="flex justify-center items-center bg-tour-primary w-[36px] h-[36px] rounded-[8px]"
                      id="session-time"
                    >
                      {iconObject?.timer}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[24px]  ">
                {getTours?.tour_itinerary &&
                  getTours?.tour_itinerary?.map((tour, index) => (
                    <Fragment key={index}>
                      <div className="">
                        <MarchantTourSubscribeCard
                          key={index}
                          tour={tour}
                          number_of_daysArray={number_of_daysArray}
                          tourSubscribe={tourSubscribe}
                          setTourSubscribe={setTourSubscribe}
                        ></MarchantTourSubscribeCard>
                      </div>
                    </Fragment>
                  ))}

                <div className="pt-[48px] flex gap-[32px]">
                  <button
                    disabled={
                      tourSubscribe?.tour_activities?.length !==
                        getTours?.tour_itinerary?.length ||
                      tourSubscribe?.tour_starting_place?.length === 0 ||
                      tourSubscribe?.tour_reporting_time?.length === 0
                    }
                    className="w-full h-[48px] px-[16px] py-[10px] border-[1px] border-[#2E6980] rounded-[50px] text-[16px] text-[#2E6980] transition ease-in duration-300 hover:bg-[#2E6980] hover:text-bg-white disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input "
                    onClick={() => subscribeTour(true)}
                  >
                    Save & Exit
                  </button>
                  <button
                    disabled={
                      tourSubscribe?.tour_activities?.length !==
                        getTours?.tour_itinerary?.length ||
                      tourSubscribe?.tour_starting_place?.length === 0 ||
                      tourSubscribe?.tour_reporting_time?.length === 0
                    }
                    className="w-full h-[48px] px-[16px] py-[10px] border-[1px] border-[#2E6980] rounded-[50px] text-[16px] text-[#2E6980] transition ease-in duration-300 hover:bg-[#2E6980] hover:text-bg-white disabled:bg-tour-input disabled:text-bg-white disabled:border-tour-input"
                    onClick={() => changeToSetup()}
                  >
                    Proceed to Amenities Setup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* New Design end */}
        {/* <div className="grid grid-cols-6 gap-[20px]">
          <div className=" col-span-2 shadow-customShadow rounded-t-[8px] overflow-hidden max-h-[610px] bg-bg-white rounded-[6px] ">
            <div className=" mx-auto w-full h-[180px] relative">
              <img
                src={getTours?.tour_thumb_image_url}
                alt="ghuddy"
                loading="lazy"
                className="object-cover w-full h-full"
              />
              <p className="text-bg-white z-[10] absolute bottom-[10px] left-[10px] text-[16px] ">
                #{getTours?.tour_title}
              </p>
            </div>
            <div className="mt-[14px] px-[6px]">
              <h2 className="text-[16px]">{getTours?.tour_name}</h2>
              <p className="text-[12px]">
                {getTours?.tour_description?.slice(0, 150)}
              </p>
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Itinerary</p>

                <SliderCart
                  cart={getTours?.tour_itinerary}
                  containerWidth={"w-[343px]"}
                  cartWidth={130}
                  cartViewNumber={2.5}
                >
                  {getTours?.tour_itinerary?.map((itinerary, index) => (
                    <div key={index} className="max-w-[130px] min-w-[130px]">
                      <p
                        title={itinerary?.tour_activity_name}
                        className="text-[14px] truncate"
                      >
                        {itinerary?.tour_activity_name}
                      </p>
                      <div className="w-[130px] h-[80px] rounded-[8px] overflow-hidden">
                        <img
                          src={
                            itinerary?.tour_activity_images[0]
                              ?.activity_image_url
                          }
                          alt="ghuddy"
                          loading="lazy"
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                  ))}
                </SliderCart>
              </div>
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Speciality</p>

                <SliderCart
                  cart={getTours?.tour_specialities}
                  containerWidth={"w-[343px]"}
                  cartWidth={230}
                  cartViewNumber={1.5}
                >
                  {getTours?.tour_specialities?.map((speciality, index) => (
                    <div
                      key={index}
                      className="min-w-[230px] max-w-[230px] p-[8px] border-btn-secondary border-[0.0.001px] shadow-customShadow my-[6px]"
                    >
                      <p className="text-[13px] font-extrabold text-btn-secondary flex justify-start gap-x-[4px] items-center">
                        <span className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]">
                          <img
                            className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]"
                            src={speciality?.tour_speciality_icon_url}
                            alt="Ghuddy"
                          />
                        </span>
                        {speciality?.tour_speciality_title}
                      </p>
                      <p className="text-[12px] mt-[3px]">
                        {speciality?.tour_speciality_description?.slice(0, 80)}
                      </p>
                    </div>
                  ))}
                </SliderCart>
              </div>
            </div>
          </div>
          <div className="col-span-4 px-[14px] ">
            <div className="max-w-[670px]">
              <div className="w-full mx-auto flex flex-col gap-y-[10px]">
                <div className="w-full">
                  <label className="custom-field one w-full">
                    <input
                      onChange={(e) =>
                        setTourSubscribe((prev) => ({
                          ...prev,
                          tour_starting_place: e.target.value,
                        }))
                      }
                      className="input w-full"
                      type="text"
                      placeholder=" "
                      value={tourSubscribe?.tour_starting_place}
                    />
                    <span className="placeholder">Starting Place :</span>
                  </label>
                </div>

                <div className="w-full">
                  <p className="text-[12px]">Reporting Time : </p>
                  <input
                    onChange={(e) =>
                      setTourSubscribe((prev) => ({
                        ...prev,
                        tour_reporting_time: e.target.value,
                      }))
                    }
                    type="time"
                    id="session-time"
                    name="appt"
                    step="0"
                    value={tourSubscribe?.tour_reporting_time}
                    required
                    style={{
                      padding: "4px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      width: "100%",
                      outline: "none",
                      backgroundColor: "#f5f5f5",
                      color: "#9e9989",
                      fontFamily: "Arial, sans-serif",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      transition:
                        "border-color 0.3s ease, box-shadow 0.3s ease",
                    }}
                  />
                </div>

                
              </div>
              <div className="flex flex-col gap-[12px]  ">
                {getTours?.tour_itinerary &&
                  getTours?.tour_itinerary?.map((tour, index) => (
                    <Fragment key={index}>
                      <div className="mt-[20px]">
                        <MarchantTourSubscribeCard
                          key={index}
                          tour={tour}
                          number_of_daysArray={number_of_daysArray}
                          tourSubscribe={tourSubscribe}
                          setTourSubscribe={setTourSubscribe}
                        ></MarchantTourSubscribeCard>
                      </div>
                     
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="flex justify-between items-center mt-[30px]">
          <button
            onClick={() => navigate(merchantTourRoute.TOUR_DASHBOARD)}
            className="bg-[#DC7633] px-[40px] rounded-[4px] text-bg-white py-[8px]"
          >
            Back Tour List
          </button>
          <button
            disabled={
              tourSubscribe?.tour_starting_place !== undefined &&
              tourSubscribe?.tour_activities?.length !==
                getTours?.tour_itinerary?.length
            }
            onClick={() => subscribeTour()}
            className="bg-btn-secondary px-[40px] rounded-[4px] text-bg-white py-[8px] disabled:bg-[#A6ACAF]"
          >
            Subscribe
          </button>
        </div> */}
      </div>

      {/* <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={true}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px] flex flex-col gap-y-[10px]">
          <div>
            <CustomInputWithDropdown
              setSelectedOption={(activity_day_number) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_day_number: activity_day_number,
                }))
              }
              selectedOption={activites?.activity_day_number}
              labelText={"Day Of Number"}
              options={generateArray(getTours?.number_of_days)}
            />
          </div>

          <div>
            <CustomInputWithDropdown
              setSelectedOption={(activity_id) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_id: activity_id,
                }))
              }
              selectedOption={activites?.activity_id?.label}
              labelText={"Activity Name"}
              options={activityVariable}
            />
          </div>

          <div className="w-full">
            <p className="text-[12px]">Activity Start Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_start_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_start_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>

          <div className="w-full">
            <p className="text-[12px]">Activity End Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_end_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_end_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>
          <button
            // onClick={() => handleActivites()}
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[10px]"
          >
            Submit
          </button>
        </div>
      </ModalCommon> */}
    </div>
  );
};

export default MarchantTourSubscribe;
