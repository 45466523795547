import React, { useEffect, useState } from "react";
import CustomButtonGroupExcruions from "./CustomButtonGroupExcruions";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  PROPERTY_ENDPOINTS,
  apiEndPoint,
  baseUrl,
} from "../../contants/Endpoints";
import SingleSelectParent from "../../components/singleSelect/SingleSelectParent";
import SingleSelectFromSimpleArray from "../../components/singleSelect/SingleSelectFromSimpleArray";
import InputComponent from "../../components/InputComponent";
import { authAxios } from "../../utils/axiosWrapper";
import { toast } from "react-toastify";
import { changeUrl } from "../utils/common";
import Loading from "../../components/Loader/Loading";

const Address = ({
  setActiveStep,
  activeStep,
  excursionData,
  isLoadingExcursion,
}) => {
  const [allCountries, setAllCountries] = useState([]);
  const [countryCode, setCountryCode] = useState(
    excursionData?.address?.country || ""
  );
  const [apiDivision, setApiDivision] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiMunicipality, setApiMunicipality] = useState();
  const [apiCommunity, setApiCommunity] = useState();
  const [apiZips, setApiZips] = useState();
  const [showError, setShowError] = useState(false);
  const url = new URL(window.location.toString());
  const excursionID = Number(url.searchParams.get("id"));
  const [isLoading, setIsLoading] = useState(false);

  const [fullAddress, setFullAddress] = useState({
    country: "",
    zip_code: "",
    address: "",
    address_optional: "",
    city: "",
    municipality: "",
    community: "",
    division: "",
    organization_name: "",
    id: 0,
  });

  useEffect(() => {
    authAxios
      .get(`${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${1234}`)
      .then((response) => {
        setAllCountries(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // fetch country
  useEffect(() => {
    if (countryCode) {
      authAxios
        .get(
          `${PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE}${countryCode}?requestId=${excursionID}`
        )
        .then((response) => {
          setApiDivision(response.data.admin1s);
          setApiCity(response.data.admin2s);
          setApiMunicipality(response.data.admin3s);
          setApiCommunity(response.data.places);
          setApiZips(response.data.zips);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [countryCode, excursionID]);

  const handleFullAddressChange = (fieldName, value) => {
    setFullAddress((prevAddress) => ({
      ...prevAddress,
      [fieldName]: value,
    }));
  };

  // fetch country and zip
  useEffect(() => {
    if (countryCode?.length > 0 && fullAddress?.zip_code > 0) {
      authAxios
        ?.get(
          `${apiEndPoint?.GET_COUNTRY_BY_COUNTRY_CODE_AND_ZIP_CODE}/${countryCode}/${fullAddress?.zip_code}?requestId=123`
        )
        .then((res) => {
          console.log({ res });
          setFullAddress({
            ...fullAddress,
            division: res?.data?.data?.admin1,
            city: res?.data?.data?.admin2,
            municipality: res?.data?.data?.admin3,
            community: res?.data?.data?.place,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [countryCode, fullAddress?.zip_code]);

  const payloadMethod = {
    admin1: fullAddress?.division,
    admin2: fullAddress?.city,
    admin3: fullAddress?.municipality,
    admin4: fullAddress?.community,
    billingAddress: true,
    country: fullAddress?.country,
    excursionId: parseInt(excursionID),
    id: fullAddress?.id || 0,
    latitude: 0,
    longitude: 0,
    optionalPart: fullAddress?.address_optional,
    organizationName: fullAddress?.organization_name,
    place: fullAddress?.address,
    primaryAddress: true,
    requestId: "123",
    street: "string",
    zip: fullAddress?.zip_code,
  };

  const onSubmitButton = () => {
    if (
      fullAddress?.country?.length === 0 ||
      fullAddress?.zip_code?.length === 0 ||
      fullAddress?.address?.length === 0 ||
      fullAddress?.city?.length === 0 ||
      fullAddress?.municipality?.length === 0 ||
      fullAddress?.community?.length === 0 ||
      fullAddress?.division?.length === 0 ||
      fullAddress?.organization_name?.length === 0
    ) {
      toast.warn("Required All the field!");
      setShowError(true);
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_ADDRESS}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Address Added successfully!");
        setActiveStep(2);
        changeUrl(Number(url.searchParams.get("id")), 2);
        setShowError(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setShowError(true);
        setActiveStep(1);
      });
  };

  // excursionID
  // check already Data has
  // console.log("excursionId: ", excursionID);
  useEffect(() => {
    // console.log("called");
    setIsLoading(true);
    if (excursionID !== 0) {
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ADDRESS_BY_ID}?excursionId=${excursionID}&requestId=123`
        )
        .then((res) => {
          setFullAddress({
            ...fullAddress,
            division: res?.data?.admin1,
            city: res?.data?.admin2,
            municipality: res?.data?.admin3,
            community: res?.data?.admin4,
            country: res?.data?.country,
            organization_name: res?.data?.organizationName,
            zip_code: res?.data?.zip,
            address_optional: res?.data?.optionalPart,
            address: res?.data?.place,
            id: res?.data?.id,
          });
          setCountryCode(res?.data?.country);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [excursionID]);

  // useEffect(() => {
  //   authAxios
  //   .get(`${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ADDRESS_BY_ID}?excursionId=${excursionID}&requestId=123`)
  //   .then((res) => {
  //     console.log("new res: ", res?.data)
  //   })
  //   .catch((err) => {
  //     console.log("err: ", err)
  //   })
  // },[])

  const backAndSave = () => {
    if (
      fullAddress?.country?.length === 0 ||
      fullAddress?.zip_code?.length === 0 ||
      fullAddress?.address?.length === 0 ||
      fullAddress?.city?.length === 0 ||
      fullAddress?.municipality?.length === 0 ||
      fullAddress?.community?.length === 0 ||
      fullAddress?.division?.length === 0 ||
      fullAddress?.organization_name?.length === 0
    ) {
      toast.warn("Required All the field!");
      setShowError(true);
      return;
    }
    authAxios
      .post(
        `${baseUrl}${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_UPDATE_EXCURSION_ADDRESS}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Address Added successfully!");
        const url = new URL(window.location.toString());
        changeUrl(
          Number(url.searchParams.get("id")),
          Number(url.searchParams.get("step") - 1)
        );
        setActiveStep(Number(url.searchParams.get("step")) - 1);
        setShowError(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.warn("Add failed!");
        setShowError(true);
        setActiveStep(1);
      });
  };

  useEffect(() => {
    localStorage.removeItem("excursionCreatePackageId");
  }, []);

  return (
    <>
      {/* <h2 onClick={() => setActiveStep(2)}>hh</h2> */}
      <div className="w-[343px] md:w-[712px]">
        <div className=" mb-[20px]">
          <p
            className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary 
      "
          >
            <span className="border-x-[4px] px-[8px]"> Address</span>
          </p>
          <p className="text-center text-btn-secondary text-[14px]">
            Effortlessly refine location details for pinpoint accuracy.
          </p>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loading></Loading>
          </div>
        ) : (
          <div className="shadow-customShadow py-[24px] pl-[40px] rounded-[4px]">
            <div className="flex flex-col gap-y-[8px] md:gap-y-[16px]">
              <div className="flex gap-x-[8px] md:gap-x-[16px]">
                <div className="w-full">
                  <p className="text-btn-secondary">Country : </p>

                  {/* <div className="rounded">
                <Select
                  value={suitable_for_personsArray.label}
                  onChange={(data) =>
                    setFullAddress((prev) => ({
                      ...prev,
                      country: data,
                    }))
                  }
                  components={animatedComponents}
                  options={suitable_for_personsArray}
                  placeholder={"Select Country:"}
                  styles={colorStyles}
                />
              </div> */}
                  <SingleSelectParent
                    required={true}
                    selectedValue={countryCode}
                    setSelectedValue={(selectedCountry) => {
                      setCountryCode(selectedCountry);
                      setFullAddress((prevAddress) => ({
                        ...prevAddress,
                        country: selectedCountry,
                      }));
                    }}
                    data={allCountries || ""}
                    placeholder="Country"
                    displayField={"country"}
                    selectedFieldName="countryCode"
                    isReadOnly={false}
                    name="country"
                  />
                  {showError &&
                    (fullAddress?.country?.length === 0 ||
                      fullAddress?.country === undefined) && (
                      <p className="text-[13px] text-[#fca5a5] mt-[2px]">
                        Country is required.
                      </p>
                    )}
                </div>

                <div className="w-full">
                  <p className="text-btn-secondary">Zip Code: </p>

                  <SingleSelectFromSimpleArray
                    required={true}
                    data={apiZips}
                    selectedValue={fullAddress?.zip_code || ""}
                    setSelectedValue={(selectedZip) => {
                      setFullAddress((zip) => ({
                        ...zip,
                        zip_code: selectedZip,
                      }));
                    }}
                    placeholder="Zip Code"
                    isReadOnly={false}
                    name="zipCode"
                  />
                  {showError &&
                    (fullAddress?.zip_code?.length === 0 ||
                      fullAddress?.zip_code === undefined) && (
                      <p className="text-[13px] text-[#fca5a5] mt-[2px]">
                        Zip Code is required.
                      </p>
                    )}
                </div>
              </div>

              <div className="w-full">
                <p className="text-btn-secondary">Address: </p>
                <InputComponent
                  inputValue={fullAddress?.address || ""}
                  setInputValue={(inputAddress) =>
                    handleFullAddressChange("address", inputAddress)
                  }
                  name="address"
                  // number={true}
                  placeholder={"Address"}
                  width={"w-0"}
                />
                {showError &&
                  (fullAddress?.address?.length === 0 ||
                    fullAddress?.address === undefined) && (
                    <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                      Address is required.
                    </p>
                  )}
              </div>

              <div className="w-full">
                <p className="text-btn-secondary">Address Optional: </p>
                <InputComponent
                  inputValue={fullAddress?.address_optional || ""}
                  setInputValue={(inputAddressOptional) =>
                    handleFullAddressChange(
                      "address_optional",
                      inputAddressOptional
                    )
                  }
                  name="address_optional"
                  // number={true}
                  placeholder={"Address Optional"}
                  width={"w-0"}
                />
              </div>

              {/* <div className="flex gap-x-[8px] md:gap-x-[16px]">
                <div className="w-full">
                  <p className="text-btn-secondary">Division : </p>
                  <SingleSelectFromSimpleArray
                    required={true}
                    data={apiDivision}
                    selectedValue={fullAddress?.division}
                    setSelectedValue={(selectedDivision) => {
                      setFullAddress((prev) => ({
                        ...prev,
                        division: selectedDivision,
                      }));
                    }}
                    placeholder="Division"
                    isReadOnly={true}
                    name="division"
                  />
                  {showError &&
                    (fullAddress?.division?.length === 0 ||
                      fullAddress?.division === undefined) && (
                      <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                        Division is required.
                      </p>
                    )}
                </div>

                <div className="w-full">
                  <p className="text-btn-secondary">City : </p>
                  <SingleSelectFromSimpleArray
                    required={true}
                    data={apiCity}
                    selectedValue={fullAddress?.city}
                    setSelectedValue={(selectedCity) => {
                      setFullAddress((prev) => ({
                        ...prev,
                        city: selectedCity,
                      }));
                    }}
                    placeholder="City"
                    name="city"
                  />
                  {showError &&
                    (fullAddress?.city?.length === 0 ||
                      fullAddress?.city === undefined) && (
                      <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                        City is required.
                      </p>
                    )}
                </div>
              </div> */}

              <div className="flex">
                <div className="w-full mt-[8px]">
                  <div className="text-btn-secondary">Division</div>
                  <InputComponent
                    inputValue={fullAddress?.division}
                    // setInputValue={setDivision}
                    name="division"
                    placeholder={"Division"}
                    isReadOnly={true}
                    width={"w-0"}
                  />
                  {showError &&
                    (fullAddress?.division?.length === 0 ||
                      fullAddress?.division === undefined) && (
                      <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                        Division is required.
                      </p>
                    )}
                </div>

                <div className="w-full mt-[8px] ">
                  <div className="text-btn-secondary">City</div>
                  <InputComponent
                    inputValue={fullAddress?.city}
                    // setInputValue={setCity}
                    name="city"
                    placeholder={"City"}
                    isReadOnly={true}
                    width={"w-0"}
                  />
                  {showError &&
                    (fullAddress?.city?.length === 0 ||
                      fullAddress?.city === undefined) && (
                      <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                        City is required.
                      </p>
                    )}
                </div>
              </div>

              {/* <div className="w-full">
                <p className="text-btn-secondary">Municipality : </p>
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiMunicipality}
                  selectedValue={fullAddress?.municipality}
                  setSelectedValue={(selectedMunicipality) => {
                    setFullAddress((prev) => ({
                      ...prev,
                      municipality: selectedMunicipality,
                    }));
                  }}
                  placeholder="Municipality"
                  name="municipality"
                />
                {showError &&
                  (fullAddress?.municipality?.length === 0 ||
                    fullAddress?.municipality === undefined) && (
                    <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                      Municipality is required.
                    </p>
                  )}
              </div> */}

              <div className="w-full mt-[8px] ">
                <div className="text-btn-secondary">Municipality</div>
                <InputComponent
                  inputValue={fullAddress?.municipality}
                  // setInputValue={setCity}
                  name="municipality"
                  placeholder={"Municipality"}
                  isReadOnly={true}
                  width={"w-0"}
                />
                {showError &&
                  (fullAddress?.municipality?.length === 0 ||
                    fullAddress?.municipality === undefined) && (
                    <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                      Municipality is required.
                    </p>
                  )}
              </div>

              {/* <div className="w-full">
                <p className="text-btn-secondary">Community : </p>
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiCommunity}
                  selectedValue={fullAddress?.community}
                  setSelectedValue={(selectedCommunity) => {
                    setFullAddress((prev) => ({
                      ...prev,
                      community: selectedCommunity,
                    }));
                  }}
                  placeholder="Community"
                  name="community"
                />
                {showError &&
                  (fullAddress?.community?.length === 0 ||
                    fullAddress?.community === undefined) && (
                    <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                      Community is required.
                    </p>
                  )}
              </div> */}

              <div className="w-full mt-[8px] ">
                <div className="text-btn-secondary">Community</div>
                <InputComponent
                  inputValue={fullAddress?.community}
                  // setInputValue={setCity}
                  name="community"
                  placeholder={"Community"}
                  isReadOnly={true}
                  width={"w-0"}
                />
                {showError &&
                  (fullAddress?.community?.length === 0 ||
                    fullAddress?.community === undefined) && (
                    <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                      Community is required.
                    </p>
                  )}
              </div>
            </div>

            <div className="w-full mt-[12px]">
              <p className="text-btn-secondary">Organization Name: </p>
              <InputComponent
                inputValue={fullAddress?.organization_name || ""}
                setInputValue={(inputOrganizationName) =>
                  handleFullAddressChange(
                    "organization_name",
                    inputOrganizationName
                  )
                }
                name="organization_name"
                placeholder={"Organization Name: "}
                width={"w-0"}
              />
              {showError &&
                (fullAddress?.organization_name?.length === 0 ||
                  fullAddress?.organization_name === undefined) && (
                  <p className="text-[12px] text-[#fca5a5] mt-[2px]">
                    Organization Name is required.
                  </p>
                )}
            </div>

            {/* <div>
          <button
            onClick={() => onViewButton(2)}
            className="px-[8px] py-[4px] my-[20px] bg-bg-warning"
          >
            View Button
          </button>
        </div> */}

            <div className="mt-[40px] flex justify-center items-center w-full">
              <CustomButtonGroupExcruions
                setSaveAndNext={backAndSave}
                step={activeStep}
                onSubmit={onSubmitButton}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Address;
