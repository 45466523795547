import React, { useState } from "react";
export const numberToArray = (day) => {
  const dayNumber = [];
  for (let i = 0; i < day; i++) {
    dayNumber.push(i + 1);
  }
  return dayNumber;
};

const DragAndDropGuide = ({
  keyName,
  selectedNumber,
  items,
  IdentifyName,
  setTargets,
  targets,
}) => {
  const convertedArray = numberToArray(selectedNumber);

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();

    const targetId = `${keyName}${targetIndex}`;

    if (targets[targetId]?.includes(draggedItem)) {
      return;
    }

    // Update the state with the dropped item in the targets object
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      newTargets[targetId] = [...(newTargets[targetId] || []), draggedItem];
      return newTargets;
    });

    // Reset the dragged item state
    setDraggedItem(null);
  };

  const handleRemoveItem = (targetId, item) => {
    setTargets((prevTargets) => {
      const newTargets = { ...prevTargets };
      newTargets[targetId] = newTargets[targetId].filter((i) => i !== item);

      // Check if the array length is 0, and delete the key
      if (newTargets[targetId].length === 0) {
        delete newTargets[targetId];
      }

      return newTargets;
    });
  };
  console.log("items", items);
  return (
    <div className="flex flex-col gap-y-[20px] ">
      <div className="min-h-[270px] overflow-y-scroll">
        <h2 className="text-[18px]">Availabile Packages :</h2>
        <div className="flex justify-start gap-[16px] max-h-[250px] overflow-y-scroll p-[10px] flex-row border-b border-btn-secondary">
          {items?.map((item, index) => (
            <div
              draggable
              className="shadow-customShadow border border-btn-secondary px-[10px] py-[4px] rounded-[4px] text-txt-primary w-full"
              onDragStart={(e) => handleDragStart(e, item)}
              key={index}
            >
              <div className="w-full">
                <p>
                  {item?.day_number} Day {item?.number_of_guides_for_day} Guide
                </p>
                <p>Guide price per Day: {item?.total_guide_price_for_day}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="max-h-[250px] overflow-y-scroll">
        {/* {convertedArray?.map((index) => (
          <div
            key={index}
            id={`${keyName}${index}`}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, index)}
            className="shadow-customShadow  p-[10px] rounded-[4px] "
          >
            <div className="text-[16px] bg-btn-secondary text-bg-white p-[4px] text-center">
              {" "}
              {IdentifyName} {keyName} {index}
            </div>
            <ul className="mt-[10px] flex flex-row gap-[10px]">
              {targets[`${keyName}${index}`]?.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                  className="flex justify-start gap-[10px] flex-row shadow-customShadow p-[10px] relative w-[200px]"
                >
                  <div className="w-full">
                    <p>
                      {item?.day_number} Day {item?.number_of_guides_for_day}{" "}
                      Guide
                    </p>
                    <p>
                      Guide price per Day: {item?.total_guide_price_for_day}
                    </p>
                  </div>
                  <div className="absolute top-[-6px] right-[-6px]">
                    <button
                      className="border rounded-full  w-[25px] h-[25px] border-btn-pink text-btn-pink"
                      onClick={() =>
                        handleRemoveItem(`${keyName}${index}`, item)
                      }
                    >
                      X
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))} */}
        <div
          id={`${keyName}${1}`}
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e, 1)}
          className="shadow-customShadow  p-[10px] rounded-[4px] "
        >
          <div className="text-[16px] bg-btn-secondary text-bg-white p-[4px] text-center">
            {" "}
            {IdentifyName} {keyName} {1}
          </div>
          <ul className="mt-[10px] flex flex-row gap-[10px]">
            {targets[`${keyName}${1}`]?.map((item, itemIndex) => (
              <li
                key={itemIndex}
                draggable
                onDragStart={(e) => handleDragStart(e, item)}
                className="flex justify-start gap-[10px] flex-row shadow-customShadow p-[10px] relative w-[200px]"
              >
                <div className="w-full">
                  <p>
                    {item?.day_number} Day {item?.number_of_guides_for_day}{" "}
                    Guide
                  </p>
                  <p>Guide price per Day: {item?.total_guide_price_for_day}</p>
                </div>
                <div className="absolute top-[-6px] right-[-6px]">
                  <button
                    className="border rounded-full  w-[25px] h-[25px] border-btn-pink text-btn-pink"
                    onClick={() => handleRemoveItem(`${keyName}${1}`, item)}
                  >
                    X
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DragAndDropGuide;
