import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useParams } from "react-router";
import Select from "react-select";

import { apiEndPoint, MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import TourMerchantButton from "../TourMerchantButton";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../Utilities/SelectAnimation";
import { convertStringToObject } from "./ParentPackageSelected";
import TransferPackageTable from "./TransferPackageTable";

const TransferPackage = ({
  setMarchantCreateDatas,
  setPackageSelect,

  marchantTourCreateDatas,
  setViewPage,
  viewPage,
}) => {
  const [modeList, setTransportationModeList] = useState([]);
  const [transferProvider, setTransferProvider] = useState([]);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const marchantId = localStorage.getItem("id");
  const [transferTour, setTransferRoute] = useState();
  const [selectedValues, setSelectedValues] = useState([]);

  const [updateButtonEnable, setUpdateButtonEnable] = useState(false);
  console.log("modeList", modeList);
  const handleBackButton = () => {
    setPackageSelect(null);
  };

  const getTransportationModeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_MODE_LIST_GET_API}?page-number=1&page-size=10&requestId=10`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_modes);
        setTransportationModeList(res?.data?.transportation_modes);
      })
      .catch((err) => console.log(err));
  };

  const getRoomCategoryList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TRANSPORTATION_PACKAGE_PROVIDER_LIST_GET_API}?page-number=1&page-size=10&requestId=100`
      )
      .then((res) => {
        setTransferProvider(res?.data?.transportation_providers);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoomCategoryList();

    getTransportationModeList();
  }, []);

  const [transferPackageList, setTransferPackageList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const [transferPackage, setTransferPackage] = useState({
    transfer_route: "",
    tour_transfer_provider_id: 0,
    tour_transfer_mode_id: 0,
    is_ac: false,
    trip_type: false,
    suitable_for_persons: "",
    per_vehicle_per_trip_price: "",
  });

  const [updateIndex, setUpdateIndex] = useState(null);
  const [updateSubmitButtonCondition, setUpdateSubmitButtonCondition] =
    useState(false);
  const handleEdit = (packageList, ind) => {
    setTransferPackageList(packageList);
    setPackageSelect(3);
    setViewPage(null);
    setUpdateSubmitButtonCondition(true);
    setUpdateIndex(ind);
  };

  const is_AC = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const transportationTripTypeArray = [
    { value: "ONE_WAY", label: "ONE_WAY" },
    { value: "ROUND_TRIP", label: "ROUND_TRIP" },
  ];

  const transferModeArray = transformArray(
    modeList,
    "transportation_mode_id",
    "transportation_mode_name"
  );
  const accomodationCategoryArray = transformArray(
    transferProvider,
    "transportation_provider_id",
    "transportation_provider_name"
  );

  const handleAddButtonClick = () => {
    if (
      transferPackage?.transfer_route !== undefined &&
      transferPackage?.tour_transfer_mode_id?.label !== undefined &&
      transferPackage?.tour_transfer_provider_id?.label !== undefined &&
      transferPackage?.suitable_for_persons > 0 &&
      transferPackage?.per_vehicle_per_trip_price > 0
    ) {
      setTransferPackageList((prev) => [
        ...prev,
        {
          transfer_route: transferPackage.transfer_route,
          tour_transfer_provider_id: transferPackage.tour_transfer_provider_id,
          tour_transfer_mode_id: transferPackage.tour_transfer_mode_id,

          trip_type: transferPackage.trip_type,
          is_ac: transferPackage.is_ac,
          suitable_for_persons: transferPackage.suitable_for_persons,

          per_vehicle_per_trip_price:
            transferPackage.per_vehicle_per_trip_price,
        },
      ]);

      setTransferPackage({
        transfer_route: "",
        tour_transfer_provider_id: 0,
        tour_transfer_mode_id: 0,
        is_ac: false,
        trip_type: false,
        suitable_for_persons: "",
        per_vehicle_per_trip_price: "",
      });
      setSelectedValues([]);
    }
  };

  console.log("transferPackage", transferPackage);

  const handleUpdateButton = () => {
    if (
      transferPackage?.transfer_route !== undefined &&
      transferPackage?.tour_transfer_mode_id?.label !== undefined &&
      transferPackage?.tour_transfer_provider_id?.label !== undefined &&
      transferPackage?.suitable_for_persons > 0 &&
      transferPackage?.per_vehicle_per_trip_price > 0
    ) {
      setTransferPackageList((prev) => {
        const updatedArray = prev.map((item, index) => {
          if (index === currentIndex) {
            return transferPackage;
          }
          return item;
        });

        return updatedArray;
      });

      setUpdateButtonEnable(false);
      setTransferPackage({
        transfer_route: "",
        tour_transfer_provider_id: 0,
        tour_transfer_mode_id: 0,
        is_ac: false,
        trip_type: false,
        suitable_for_persons: "",
        per_vehicle_per_trip_price: "",
      });
    }
  };
  const [transferIncluded, setTransferIncluded] = useState(false);
  const handleSubmitAccommondation = () => {
    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions = [
        ...prevPackage.tour_package_transfer_options,
        {
          tour_package_transfer_packages: transferPackageList,
          tour_package_option_is_active: true,
          tour_package_option_is_default: transferIncluded,
        },
      ];

      return {
        ...prevPackage,
        tour_package_transfer_options: updatedAccommodationOptions,
      };
    });

    setTransferPackageList([]);
    setSelectedValues([]);
    setTransferPackage({
      transfer_route: "",
      tour_transfer_provider_id: 0,
      tour_transfer_mode_id: 0,
      is_ac: false,
      trip_type: false,
      suitable_for_persons: "",
      per_vehicle_per_trip_price: "",
    });

    setViewPage(1);
  };

  const handleSubmitAccommondationUpdate = () => {
    setMarchantCreateDatas((prevPackage) => {
      const { tour_package_transfer_options } = prevPackage;

      const indexToUpdate = updateIndex;

      const updatedAccommodationOptions = [...tour_package_transfer_options];

      updatedAccommodationOptions[indexToUpdate] = {
        ...updatedAccommodationOptions[indexToUpdate],
        tour_package_transfer_packages: transferPackageList,
      };

      return {
        ...prevPackage,
        tour_package_transfer_options: updatedAccommodationOptions,
      };
    });
    setTransferPackageList([]);
    setSelectedValues([]);
    setTransferPackage({
      transfer_route: "",
      tour_transfer_provider_id: 0,
      tour_transfer_mode_id: 0,
      is_ac: false,
      trip_type: false,
      suitable_for_persons: "",
      per_vehicle_per_trip_price: "",
    });

    setViewPage(1);
  };

  const handleRemoveAccommondation = (ind) => {
    const newArray = transferPackageList?.filter(
      (meal, index) => index !== ind
    );

    setTransferPackageList(newArray);
    setMarchantCreateDatas((prevPackage) => {
      const updatedAccommodationOptions =
        newArray?.length > 0
          ? [
              {
                tour_package_transfer_packages: newArray,
                tour_package_default_transfer_option: false,
              },
            ]
          : [];

      return {
        ...prevPackage,
        tour_package_transfer_options: updatedAccommodationOptions,
      };
    });

    // setMarchantCreateData((prevPackage) => ({
    //   ...prevPackage,
    //   tour_package: {
    //     ...prevPackage.tour_package,
    //     tour_package_accommodation_packages: newArray,
    //   },
    // }));
  };

  useEffect(() => {
    authAxios
      .get(
        `${apiEndPoint.MARCHANT_GET_TRANFER_ROUTE}${marchantId}/${convertData.subscribeId}?requestId=transfer_route`
      )
      .then((res) => {
        setTransferRoute(res.data?.short_addresses);
      });
  }, []);

  const handleEditAccommondation = (ind) => {
    // const newArray = accommondationPackageList?.filter(
    //   (meal, index) => index === ind
    // );

    let newArray = transferPackageList?.filter(
      (packages, index) => index === ind
    );
    setTransferPackage({
      transfer_route: newArray[0].transfer_route,
      tour_transfer_provider_id: newArray[0].tour_transfer_provider_id,
      tour_transfer_mode_id: newArray[0].tour_transfer_mode_id,

      trip_type: newArray[0].trip_type,
      is_ac: newArray[0].is_ac,
      suitable_for_persons: newArray[0].suitable_for_persons,

      per_vehicle_per_trip_price: newArray[0].per_vehicle_per_trip_price,
    });

    setCurrentIndex(ind);
    setUpdateButtonEnable(true);
  };

  const handleTransferIncluded = () => {
    setTransferIncluded(!transferIncluded);
  };

  return (
    <>
      {viewPage === null ? (
        <>
          <p className="text-btn-secondary text-center mb-[24px] xl:text-[20px] md:text-[18px] text-[16px]">
            Transfer
          </p>

          <div className="my-[10px] ">
            <button
              onClick={() => handleBackButton(null)}
              className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
            >
              <span className="text-start text-[20px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>

          <div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-[70%] w-full  rounded p-[16px] bg-bg-white h-full">
                <div className="mt-[16px] mb-[8px] ">
                  <p className="text-btn-secondary">Transfer Route: </p>
                  <div className="relative ">
                    <div
                      onClick={() => setShowDropDown(true)}
                      className="w-full px-[12px] flex justify-start items-center h-[37px] rounded-[4px] relative border-btn-secondary border-[1.5px] "
                    >
                      <p className="text-[14px]">
                        {transferPackage?.transfer_route ? (
                          transferPackage?.transfer_route
                        ) : (
                          <span className="text-[#9a9999]">
                            Please select tranfer route
                          </span>
                        )}
                      </p>
                      <button
                        onClick={() => {
                          setTransferPackage((prev) => ({
                            ...prev,
                            transfer_route: "",
                          }));
                          setSelectedValues([]);
                        }}
                        className="absolute right-[12px] cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[24px] h-[24px] text-[#E74C3C]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>

                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setShowDropDown(false);
                      }}
                    >
                      {showDropDown && (
                        <div className="absolute bottom-[-40px] z-[50]  w-full h-full">
                          <div className="flex flex-col py-[4px] bg-bg-white justify-start items-start  border rounded-[4px] border-[#ddd] shadow-customShadow text-start">
                            {transferTour?.map((item, index) => (
                              <button
                                onClick={() => {
                                  const currentValue = item;
                                  if (!selectedValues.includes(currentValue)) {
                                    const updatedValues = [
                                      ...selectedValues,
                                      currentValue,
                                    ];
                                    setSelectedValues(updatedValues);
                                    setTransferPackage((prev) => ({
                                      ...prev,
                                      transfer_route: updatedValues.join("-"),
                                    }));
                                  }
                                }}
                                key={index}
                                className="hover:bg-[#d6eaf84a] py-[4px] w-full flex justify-start px-[12px] text-start"
                              >
                                {item}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>
                </div>

                <div className="flex flex-row justify-between">
                  <div className="mt-[8px] mb-[8px] w-full">
                    <div>
                      <p className="text-btn-secondary">Tour Transfer Mode: </p>
                    </div>
                    <div className="rounded">
                      <Select
                        value={transferPackage?.tour_transfer_mode_id}
                        onChange={(data) =>
                          setTransferPackage((prev) => ({
                            ...prev,
                            tour_transfer_mode_id: data,
                          }))
                        }
                        components={animatedComponents}
                        options={transferModeArray}
                        placeholder={"Select Transfer Mode: "}
                        styles={colorStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-[8px]">
                  <div>
                    <p className="text-btn-secondary">
                      Tour Transfer Provider:{" "}
                    </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={transferPackage?.tour_transfer_provider_id}
                      onChange={(data) =>
                        setTransferPackage((prev) => ({
                          ...prev,
                          tour_transfer_provider_id: data,
                        }))
                      }
                      components={animatedComponents}
                      options={accomodationCategoryArray}
                      placeholder={"Select Provider: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Is Ac: </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={transferPackage?.is_ac}
                      onChange={(data) =>
                        setTransferPackage((prev) => ({
                          ...prev,
                          is_ac: data,
                        }))
                      }
                      components={animatedComponents}
                      options={is_AC}
                      placeholder={"AC/NoNAC: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>
                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Trip Type: </p>
                  </div>
                  <div className="rounded">
                    <Select
                      value={transferPackage?.trip_type}
                      onChange={(data) =>
                        setTransferPackage((prev) => ({
                          ...prev,
                          trip_type: data,
                        }))
                      }
                      components={animatedComponents}
                      options={transportationTripTypeArray}
                      placeholder={"ONE_WAY/ROUND_TRIP: "}
                      styles={colorStyles}
                    />
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">Suitable for Persons: </p>
                  </div>
                  <div className="rounded">
                    <div className="mt-[10px]">
                      <input
                        onChange={(e) =>
                          setTransferPackage((prev) => ({
                            ...prev,
                            suitable_for_persons: e.target.value,
                          }))
                        }
                        className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                        type="text"
                        placeholder="Suitable for Persons "
                        value={transferPackage?.suitable_for_persons}
                      />
                    </div>
                    {/* <Select
                      value={transferPackage?.suitable_for_persons}
                      onChange={(data) =>
                        setTransferPackage((prev) => ({
                          ...prev,
                          suitable_for_persons: data,
                        }))
                      }
                      components={animatedComponents}
                      options={suitable_for_personsArray}
                      placeholder={"Select persons: "}
                      styles={colorStyles}
                    /> */}
                  </div>
                </div>

                <div className="my-[16px]">
                  <div>
                    <p className="text-btn-secondary">
                      Per Vehicle Per Trip Price:{" "}
                    </p>
                  </div>
                  <div className="rounded">
                    <input
                      className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                      type="number"
                      onChange={(e) => {
                        const inputVal = e.target.value;

                        if (!isNaN(inputVal)) {
                          setTransferPackage((prev) => ({
                            ...prev,
                            per_vehicle_per_trip_price: inputVal,
                          }));
                        }
                      }}
                      placeholder="Unit per price "
                      value={transferPackage?.per_vehicle_per_trip_price}
                    />
                  </div>
                </div>

                <div className="mt-[32px] mb-[16px]">
                  {updateButtonEnable ? (
                    <TourMerchantButton
                      name={"Update"}
                      width={"w-full"}
                      onClick={() => handleUpdateButton()}
                    ></TourMerchantButton>
                  ) : (
                    <TourMerchantButton
                      name={"Add"}
                      width={"w-full"}
                      onClick={() => handleAddButtonClick()}
                    ></TourMerchantButton>
                  )}
                </div>
                {/* {night === 4 &&
              marchantTourCreateData?.tour_package
                ?.tour_package_accommodation_packages?.length >= 1 && (
                <div className="">
                  <TourMerchantButton
                    onClick={() => handleSubmitAccommondation()}
                    name={"Submit"}
                    width={"w-full"}
                  ></TourMerchantButton>
                </div>
              )} */}
              </div>
              <div className="md:w-[30%] w-full bg-[#e5e7eb] md:pl-[16px] mt-[16px] md:mt-0">
                {transferPackageList?.length === 0 && (
                  <div className="bg-bg-white p-[16px] w-full rounded text-btn-secondary text-center">
                    No Item Selected
                  </div>
                )}

                {/* Right-Side Nav */}
                <div className="max-h-[1000px] overflow-y-scroll mb-[20px]">
                  {transferPackageList?.map((item, index) => (
                    <div
                      key={index}
                      className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded overflow-clip relative"
                    >
                      <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                        {item?.transfer_route} -{" "}
                        {item?.tour_transfer_provider_id?.label}
                      </p>
                      <div className="xl:text-[14px] text-[12px]">
                        <p className="text-txt-primary">
                          {" "}
                          <span className="text-bg-semiblack">
                            Mode Type:
                          </span>{" "}
                          {item?.tour_transfer_mode_id?.label}{" "}
                        </p>

                        <p className="text-txt-primary">
                          {" "}
                          <span className="text-bg-semiblack">
                            suitable_for_persons :
                          </span>{" "}
                          {item?.suitable_for_persons}{" "}
                        </p>

                        <div className="flex justify-between">
                          <p className="text-txt-primary">
                            <span className="text-bg-semiblack">is_ac: </span>
                            {item?.is_ac?.label}
                          </p>
                          <p className="text-txt-primary">
                            <span className="text-bg-semiblack">
                              trip_type:{" "}
                            </span>
                            {item?.trip_type?.label}
                          </p>
                        </div>
                        <p className="text-txt-primary">
                          <span className="text-bg-semiblack">
                            Price per quantity:{" "}
                          </span>
                          {item?.per_vehicle_per_trip_price}৳
                        </p>
                      </div>
                      <span
                        onClick={() => handleRemoveAccommondation(index)}
                        className="absolute right-[2px] cursor-pointer top-[2px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[25px] h-[25px] text-btn-pink"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                      <span
                        onClick={() => handleEditAccommondation(index)}
                        className="absolute left-[2px] cursor-pointer top-[2px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
                {transferPackageList?.length >= 1 && (
                  <>
                    {updateSubmitButtonCondition ? (
                      <div className="">
                        <TourMerchantButton
                          onClick={() => handleSubmitAccommondationUpdate()}
                          name={"Update"}
                          width={"w-full"}
                        ></TourMerchantButton>
                      </div>
                    ) : (
                      <div className="">
                        <TourMerchantButton
                          onClick={() => handleSubmitAccommondation()}
                          name={"Submit"}
                          width={"w-full"}
                        ></TourMerchantButton>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <TransferPackageTable
          setPackageSelect={setPackageSelect}
          setViewPage={setViewPage}
          marchantTourCreateDatas={marchantTourCreateDatas}
          setMarchantCreateDatas={setMarchantCreateDatas}
          handleEdit={handleEdit}
          setUpdateSubmitButtonCondition={setUpdateSubmitButtonCondition}
        />
      )}
    </>
  );
};

export default TransferPackage;
