import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import Checkbox from "../../components/Checkbox";
import InputComponent from "../../components/InputComponent";
import SingleSelectParentReturnObject from "../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";

export default function AdminFacilityManage() {
  const [facilities, setFacilities] = useState(null);
  const [iconList, setIconList] = useState([]);
  const [uiFilterList, setUiFilterList] = useState([]);
  const [dataTypes, setDataType] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [uiFacility, setUiFacility] = useState(null);

  const [featured, setFeatured] = useState(false);
  const [displayGroup, setDisplayGroup] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [facilityType, setFacilityType] = useState(null);
  const [setUiFilter] = useState(null);
  const [categoryFacilityTypeDataList, setCategoryFacilityTypeDataList] =
    useState([]);
  const [propertyFacilityTypeDataList, setPropertyFacilityTypeDataList] =
    useState([]);

  const [currentView, setCurrentView] = useState("property");

  const ResetValues = () => {
    setFeatured(false);
    setDisplayGroup("");
    setLabel("");
    setValue("");
    setFacilityType(null);
    setUiFilter(null);
    setSelectedIcon(null);
    setSelectedDataType(null);
  };

  const handleSubmit = () => {
    console.log("called");
    // const data = {
    //     featured,
    //     displayGroup,
    //     label,
    //     value,
    //     icon: selectedIcon,
    //     facilityType,
    //     uiFilter,
    // };
    const data = {
      dataType: selectedDataType?.type,
      definition: "",
      displayGroup: displayGroup,
      displayPriority: 0,
      facilityFor: currentView === "property" ? "PROPERTY" : "CATEGORY",
      facilityTypeId: facilityType?.id ? facilityType.id : null,
      featured: featured,
      iconId: selectedIcon?.id ? selectedIcon.id : null,
      label: label,
      requestId: "facility",
      uiFilterId: uiFacility.id,
      value: value,
    };
    console.log(data);
    authAxios
      .post(apiEndPoint.ADMIN_CREATE_FACILITY, data)
      .then((res) => {
        console.log(res);
        setShowPopUp(false);
        // show success toast
        toastMessage("Facility created successfully", "success");
        ResetValues();
        getFacilities();
      })

      .catch((err) => {
        console.log(err);
        // show error toast
        toastMessage("Error creating facility", "error");
      });
  };
  const getFacilities = () => {
    authAxios.get(`${apiEndPoint.ADMIN_GET_FACILITY_ALL}`).then((res) => {
      setFacilities(res.data);
    });
  };

  const getFacilityTypes = () => {
    authAxios.get(`${apiEndPoint.ADMIN_GET_FACILITY_TYPE}`).then((res) => {
      // setFacilityTypeList(res.data.propertyFacilityTypeDataList);
      setIconList(res?.data.iconEntityList);
      setUiFilterList(res.data.uiFilterList);
      setDataType(res.data.dataTypeEnumList);
      setCategoryFacilityTypeDataList(res.data.categoryFacilityTypeDataList);
      setPropertyFacilityTypeDataList(res.data.propertyFacilityTypeDataList);
    });
  };
  console.log("icons list in facility:", iconList);
  console.log("filter list:", uiFilterList);
  console.log("data Type:", dataTypes);
  useEffect(() => {
    getFacilityTypes();
    getFacilities();
  }, []);

  // useEffect(() => {
  //     console.log(uiFilter, "test");
  // }, [uiFilter]);

  return (
    <div className="container mx-auto">
      <div className="flex gap-2 justify-center">
        <button
          onClick={() => {
            setCurrentView("property");
          }}
          className={`text-xl px-3 ${
            currentView === "property" && "bg-btn-primary text-txt-secondary"
          }  py-2 rounded-t-xl`}
        >
          Property
        </button>
        <button
          onClick={() => {
            setCurrentView("category");
          }}
          className={`text-xl px-3 ${
            currentView === "category" && "bg-btn-primary text-txt-secondary"
          }  py-2 rounded-t-xl`}
        >
          Category
        </button>
      </div>
      <div className="flex items-center my-2 gap-2 justify-between">
        <div className="text-3xl font-bold">
          {currentView === "property" && "Rental Property Facilities"}
          {currentView === "category" && "Category Facilities"}
        </div>
        <div className="w-56">
          <ButtonFilled
            w={"100%"}
            title={"Add new"}
            buttonFilledHandle={() => {
              setShowPopUp(true);
            }}
          />
        </div>
      </div>

      {facilities && (
        <div>
          <div className="my-4">
            {Object.keys(
              facilities[
                currentView === "property"
                  ? "propertyFacilityList"
                  : "categoryFacilityList"
              ]
            ).map((key2) => (
              <div className="my-4">
                <div
                  className="capitalize text-xl font-semibold mb-1 flex items-center gap-2"
                  key={key2}
                >
                  {key2
                    .replace(/([A-Z])/g, " $1")
                    .replace("_", " ")
                    .replace("_", " ")}
                  <button
                    onClick={() => {
                      setDisplayGroup(key2);
                      setShowPopUp(true);
                    }}
                    className="border-btn-primary border rounded-full w-6 h-6 flex justify-center items-center text-btn-primary active:scale-95 hover:text-txt-secondary hover:bg-btn-secondary"
                  >
                    +
                  </button>
                </div>
                <div className="flex gap-x-2 items-center flex-wrap">
                  <ol>
                    {facilities[
                      currentView === "property"
                        ? "propertyFacilityList"
                        : "categoryFacilityList"
                    ][key2].map((fac) => (
                      <li
                        // onClick={() => {
                        //   setLabel(fac.label);
                        //   setValue(fac.value);
                        //   setDisplayGroup(key2);
                        //   setFeatured(fac.featured);
                        //   setSelectedIcon(fac.icon);
                        //   currentView === "category" &&
                        //     setUiFilter({
                        //       type: fac.dataType,
                        //     });
                        //   // const facTypeIndex = facilityTypeList.findIndex(item => item.value.toLowerCase() === key2);
                        //   setFacilityType(fac.facilityType);
                        //   setShowPopUp(true);
                        // }}
                        key={fac.value}
                        className="capitalize flex items-center gap-2 cursor-pointer active:scale-90 duration-150"
                        onMouseEnter={() => {
                          document.getElementById(
                            `${fac.label}Button`
                          ).style.opacity = 1;
                        }}
                        onMouseLeave={() => {
                          document.getElementById(
                            `${fac.label}Button`
                          ).style.opacity = 0;
                        }}
                      >
                        {fac.label}
                        {/* <button
                          id={`${fac.label}Button`}
                          className="opacity-0 duration-200 "
                        >
                          <i className={`fa fa-edit  `}></i>
                        </button> */}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            ))}
            <div></div>
          </div>
        </div>
      )}

      <ReactModal
        style={{ content: { width: "auto" } }}
        isOpen={showPopUp}
        onRequestClose={() => {
          setShowPopUp(false);
          ResetValues();
        }}
      >
        <div className="min-h-16 px-4 py-6">
          <div className="relative">
            <button
              className="absolute top-0 right-6"
              onClick={() => {
                setShowPopUp(false);
                ResetValues();
              }}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                  fill="#2A220E"
                  fillOpacity="0.45"
                />
              </svg>
            </button>
          </div>

          {/* <div className="text-2xl font-light mb-4">
            {label ? "Edit Item" : "Add new"}
          </div> */}

          <div className="flex flex-col gap-4 min-h-[400px]">
            <div>
              <InputComponent
                warningView={false}
                name={"label"}
                inputValue={label}
                setInputValue={setLabel}
                placeholder={"Label"}
              />
            </div>
            <div>
              <InputComponent
                warningView={false}
                name={"value"}
                inputValue={value}
                setInputValue={setValue}
                placeholder={"Value"}
              />
            </div>
            <div>
              <InputComponent
                warningView={false}
                name={"group"}
                inputValue={displayGroup}
                setInputValue={setDisplayGroup}
                placeholder={"Display Group"}
              />
            </div>

            <div>
              <SingleSelectParentReturnObject
                warningView={false}
                data={uiFilterList}
                displayField="label"
                placeholder={"UI Facility"}
                selectedFieldName={"label"}
                selectedValue={uiFacility}
                setSelectedValue={setUiFacility}
                setSelectedObject={() => {}}
                // image={true}
              />
            </div>
            <div>
              <SingleSelectParentReturnObject
                warningView={false}
                data={iconList}
                displayField="title"
                placeholder={"Icon"}
                selectedFieldName={"title"}
                selectedValue={selectedIcon}
                setSelectedValue={setSelectedIcon}
                setSelectedObject={() => {}}
                image={true}
              />
            </div>
            <div>
              <SingleSelectParentReturnObject
                warningView={false}
                data={
                  currentView === "property"
                    ? propertyFacilityTypeDataList
                    : categoryFacilityTypeDataList
                }
                displayField="label"
                placeholder={"Facility Type"}
                selectedFieldName={"label"}
                selectedValue={facilityType}
                setSelectedValue={setFacilityType}
                setSelectedObject={() => {}}
              />
            </div>
            {currentView === "category" && (
              <div>
                <SingleSelectParentReturnObject
                  warningView={false}
                  data={dataTypes}
                  displayField="type"
                  placeholder={"Data Type"}
                  selectedFieldName={"type"}
                  selectedValue={selectedDataType}
                  setSelectedValue={setSelectedDataType}
                  setSelectedObject={() => {}}
                />
              </div>
            )}
            <div>
              <Checkbox
                title={"Featured?"}
                checked={featured}
                handleChange={() => setFeatured(!featured)}
              />
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <div className="w-72">
              <ButtonFilled
                w={"100%"}
                title={"Submit"}
                buttonFilledHandle={handleSubmit}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
