import React from "react";
import { useNavigate } from "react-router";
import { adminRoute } from "../../contants/Constants";

const AdminCategoryReview = () => {
  const newcategory = [
    { categoryId: 1, categoryName: "name of category", hotelName: "hotel1" },
    {
      categoryId: 2,
      categoryName: "name of category",
      hotelName: "Pan Pacefic",
    },
    { categoryId: 58, categoryName: "name of category", hotelName: "hotel1" },
    { categoryId: 59, categoryName: "name of category", hotelName: "red" },
    { categoryId: 60, categoryName: "name of category", hotelName: "blue red" },
  ];
  const navigate = useNavigate();
  const handleToReview = (id) => {
    navigate(`${adminRoute.ADMIN_CATEGORY}${id}`);
  };
  return (
    <div>
      <div className="flex flex-cols w-full">
        <div>
          <h2 className="text-3xl mb-6 text-center">Ghuddy Admin</h2>
          <h1 className="text-2xl text-btn-secondary">
            Review Category to accept
          </h1>
          <h2 className="text-xl my-6">
            Total category : {newcategory.length}
          </h2>
          <table id="students" className="min-w-full leading-normal">
            <thead>
              <tr className="h-[78px] px-5 py-3 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                <th className="px-5 py-3 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Category ID
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Category Name
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                  Hotel Name
                </th>
                <th className="px-5 py-3 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider pl-14">
                  Action
                </th>
              </tr>
            </thead>
            <div className="my-[14px]"></div>
            <tbody>
              {newcategory &&
                newcategory.map((member, index) => {
                  const { categoryId, categoryName, hotelName } = member; //destructuring
                  return (
                    <tr
                      className="cursor-pointer"
                      //onClick={() => handleClickcategory(categoryId)}
                      key={categoryId}
                    >
                      <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm border-txt-hint">
                        {categoryId}
                      </td>
                      <td className="h-[80px] px-7 py-5 border-b border-t bg-white text-sm border-txt-hint">
                        {categoryName}
                      </td>
                      <td className="h-[80px] px-7 py-5 border-b border-t bg-white text-sm border-txt-hint ">
                        <span className="text-btn-pink">{hotelName}</span>
                      </td>
                      <td className="px-5 py-5 border-b border-r border-t bg-white text-sm border-txt-hint ">
                        <span
                          onClick={() => handleToReview(categoryId)}
                          className=" ml-8 border py-[1rem] px-[0.8rem] cursor-pointer rounded-[0.8rem] hover:text-bg-primary hover:bg-btn-secondary"
                        >
                          Review Category
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminCategoryReview;
