import React from "react";

const InputComponentSecond = ({
  name,
  width,
  height,
  placeholder,
  value,
  setValue,
  borderRadious,
  onFocusValue,
  type,
}) => {
  const clearInputValue = () => {
    setValue("");
  };

  return (
    <div className="relative group">
      <input
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className={`bg-[#48c9af16] 
          ${height ? height : ""} 
          ${
            width ? width : ""
          } pl-10 pr-5 text-sm focus:outline-none focus:border-[#48C9B0] border-[#86efac] border
          ${borderRadious ? borderRadious : "rounded-full"}
          focus:shadow-md shadow-sm`}
      />
      <div className="absolute inset-y-0 left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-[#86efac] group-hover:text-[#48C9B0]">
        <i className="fa-solid fa-magnifying-glass"></i>
      </div>
      {value && (
        <div
          className="absolute inset-y-0 right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-[#6b7280] cursor-pointer"
          onClick={clearInputValue}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
      )}
    </div>
  );
};

export default InputComponentSecond;
