import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../components/common/ModalCommon";
import { BOOKING_ENDPOINTS_TOUR } from "../../contants/Endpoints";
import TourNav from "../../pages/admin/comp/TourNav";
import { color } from "../../pages/BookingReviewCart";
import { authAxios } from "../../utils/axiosWrapper";
import BookingReviewCartTour from "./BookingReviewCartTour";
import { CollapseNew } from "./CollapseNew";

import "./tourtask.css";

export const formatDateTOAMPM = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(inputDate);

  return formattedDate;
};

const TourBookings = () => {
  const [bookingNewData, setBookingNewData] = useState();
  const [bookingInprogress, setBookingInprogress] = useState();
  const [bookingCompleted, setBookingCompleted] = useState();
  const [bookingCancel, setBookingCancel] = useState();
  const [singleBooking, setSingleBooking] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [pageCountP, setPageCountP] = useState(1);
  const [pageCountCom, setPageCountCom] = useState(1);
  const [pageCountCan, setPageCountCan] = useState(1);
  const [modalShown, setToggleModal] = useState(false);
  const [modalShown2, setToggleModal2] = useState(false);
  const [removeCondition, setRemoveCondition] = useState(false);
  const [reason, setReason] = useState("");
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingInprogress, setLoadingInprogress] = useState(false);

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [isAccommodation, setAccommodation] = useState(false);
  const [isTransportation, setTransferIsOpen] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [isGuide, setIsGuide] = useState(false);
  const [isFood, setIsFood] = useState(true);
  const [isSpot, setIsSpot] = useState(false);

  const getInprogressBooking = () => {
    let url = `${
      BOOKING_ENDPOINTS_TOUR.GET_INPROGRESS_TOUR_REVIEWS
    }pageNumber=1&pageSize=${pageCountP * 10}&requestId=getInprogressBooking`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingInprogress(res.data);
        setLoadingInprogress(false);
      })
      .catch((err) => {
        //
      });
  };

  const getBooking = () => {
    let url = `${
      BOOKING_ENDPOINTS_TOUR.GET_BOOKINGS_BY_NEW_TOUR
    }pageNumber=1&pageSize=${pageCount * 10}&requestId=getNewBooking`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingNewData(res.data);
        setLoadingNew(false);
      })
      .catch((err) => {
        //
      });
  };

  const getAcceptedBooking = () => {
    let url = `${
      BOOKING_ENDPOINTS_TOUR.GET_ACCEPTED_BOOKING_TOUR
    }pageNumber=1&pageSize=${pageCountCom * 10}&requestId=getAcceptedBooking`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingCompleted(res.data);
        setLoadingConfirm(false);
      })
      .catch((err) => {
        //
      });
  };

  const getBookingRejected = () => {
    let url = `${
      BOOKING_ENDPOINTS_TOUR.GET_REJECTED_BOOKING_TOUR
    }pageNumber=1&pageSize=${pageCountCan * 10}&requestId=11`;
    authAxios
      .get(url)
      .then((res) => {
        setBookingCancel(res.data);
        setLoadingCancel(false);
      })
      .catch((err) => {
        //
      });
  };
  useEffect(() => {
    setLoadingConfirm(true);
    getAcceptedBooking();
  }, [pageCountCom]);

  useEffect(() => {
    setLoadingInprogress(true);
    getInprogressBooking();
  }, [pageCountP]);

  useEffect(() => {
    setLoadingNew(true);
    getBooking();
  }, [pageCount]);

  useEffect(() => {
    setLoadingCancel(true);
    getBookingRejected();
  }, [pageCountCan]);

  const handleInProgressState = (id, state) => {
    if (state) {
      let url = `${BOOKING_ENDPOINTS_TOUR.SET_INPROGRESS_TOUR_REVIEWS}${id}`;
      authAxios.post(url).then((res) => {
        getInprogressBooking();
        getBooking();
      });
    }
    let url = `${BOOKING_ENDPOINTS_TOUR.GET_SINGLE_BOOKING_TOUR}${id}`;
    authAxios
      .get(url)
      .then((res) => {
        setSingleBooking(res?.data);
      })
      .catch((err) => {
        //
      });
    setToggleModal(!modalShown);
  };

  const handleConfirm = (id) => {
    let url = `${BOOKING_ENDPOINTS_TOUR.PUT_ACCEPTED_BOOKING_TOUR}${id}`;
    authAxios.put(url).then((data) => {
      setToggleModal(!modalShown);
      toast.success("successfully Accepted!");
      getAcceptedBooking();
      getInprogressBooking();
    });
  };

  const handleRejected = (id) => {
    setRemoveCondition(true);
    if (reason?.length === 0) {
      setToggleModal2(true);
    }
    if (reason?.length > 2) {
      authAxios
        .put(
          `${BOOKING_ENDPOINTS_TOUR.PUT_REJECTED_BOOKING_TOUR}note=${reason}&requestId=rejectedTour&tourBookingReviewId=${id}`
        )
        .then((data) => {
          setToggleModal(!modalShown);
          toast.success("successfully Rejected!");
          setReason("");
          getBookingRejected();
          setToggleModal2(false);
          getInprogressBooking();
          setRemoveCondition(false);
        });
    }
  };
  console.log("singleBooking", singleBooking);

  const [accommodationPagination, setAccommodationPagination] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);

  const getArrayLength = (index) => {
    return singleBooking?.tour_package_option_booking[index]?.booked_food_option
      ?.tour_package_available_meal_packages
      ? Object.keys(
          singleBooking?.tour_package_option_booking[index]?.booked_food_option
            ?.tour_package_available_meal_packages
        ).length
      : 0;
  };

  const getArrayLengthAccommodation = (index) => {
    return singleBooking?.tour_package_option_booking[index]
      ?.booked_accommodation_option
      ?.tour_package_available_accommodation_packages
      ? Object.keys(
          singleBooking?.tour_package_option_booking[index]
            ?.booked_accommodation_option
            ?.tour_package_available_accommodation_packages
        ).length
      : 0;
  };
  const handlePaginationAccommodation = (ind) => {
    setAccommodationPagination((prevPage) =>
      accommodationPagination < ind ? prevPage + 1 : prevPage
    );
  };

  const handlePaginationFood = (ind) => {
    setCurrentPage((prevPage) => (currentPage < ind ? prevPage + 1 : prevPage));
  };
  return (
    <div>
      {process.env.REACT_APP_USER_TYPE === "USER" && <TourNav />}
      <div
        className={`app ${
          process.env.REACT_APP_USER_TYPE === "USER" && "mt-10"
        } `}
      >
        <div className="container mx-auto p-4">
          {/* main wraper area */}
          <div className="grid grid-cols-4 w-[1300px] gap-[16px]">
            {/* new card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow ">
              {/* heading area */}
              <div
                className={` ${color.NEW} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">New</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingNewData?.entries?.map((booking, ind) => (
                    <BookingReviewCartTour
                      ind={ind}
                      booking={booking}
                      handleInProgressState={handleInProgressState}
                      status={true}
                      dotView={true}
                    />
                  ))}
                </div>
                {loadingNew ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCount((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingNewData?.numberOfEntries <= pageCount * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* In Progress card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.IN_PROGRESS} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">In Progress</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingInprogress?.entries?.map((booking, ind) => (
                    <BookingReviewCartTour
                      ind={ind}
                      booking={booking}
                      handleInProgressState={handleInProgressState}
                      status={false}
                      dotView={true}
                    />
                  ))}
                </div>
                {loadingInprogress ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountP((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingInprogress?.numberOfEntries <= pageCountP * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* Confirmed card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.ACCEPTED} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">Confirmed</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingCompleted?.entries?.map((booking) => (
                    <BookingReviewCartTour booking={booking} dotView={false} />
                  ))}
                </div>
                {loadingConfirm ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountCom((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingCompleted?.numberOfEntries <= pageCountCom * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>

            {/* Cancelled card area  */}
            <div className="min-w-[315px] rounded-[12px] h-[647px] overflow-hidden shadow-customShadow">
              {/* heading area */}
              <div
                className={`${color.REJECTED} h-[45px] flex justify-center items-center text-bg-primary `}
              >
                <p className="text-[18px]">Cancelled</p>
              </div>
              {/* card area */}
              <div className="overflow-y-scroll max-h-[600px]">
                <div className="mx-auto flex justify-center items-center w-full mt-[15px] flex-col gap-y-[20px]  pb-[20px]">
                  {/* single card */}
                  {bookingCancel?.entries?.map((booking) => (
                    <BookingReviewCartTour booking={booking} dotView={false} />
                  ))}
                </div>
                {loadingCancel ? (
                  <div className="flex justify-center items-center w-full">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setPageCountCan((prev) => prev + 1)}
                    className={`mx-auto w-full bg-btn-primary py-[4px] text-bg-white cursor-pointer ${
                      bookingCancel?.numberOfEntries <= pageCountCan * 10
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    See All
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalCommon
          shown={modalShown}
          width="md:max-w-[744px] md:min-w-[744px] xl:max-w-[1148px] min-w-[100vw]"
          height="h-[100vh] xl:min-h-[calc(100vh-50px)] xl:max-h-[calc(100vh-50px)] md:min-h-[calc(100vh-100px)] md:max-h-[calc(100vh-100px)]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
            setRemoveCondition(false);
            setToggleModal2(false);
            setReason("");
          }}
        >
          <div className="px-[43px] md:max-h-[calc(100vh-170px)] max-h-[calc(100vh-100px)]  overflow-y-scroll ">
            <h2 className="mt-[29px] text-[20px]">Booking Details</h2>

            {/* view all order data */}
            {singleBooking?.tour_package_option_booking?.map(
              (defaultData, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-y-[12px] min-h-[420px] max-h-[420px] overflow-y-scroll"
                >
                  {/* packae area */}
                  <div className="mt-[16px]">
                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => setIsFood(!isFood)}
                        className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                      >
                        <div
                          className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                            isFood ? "border-[5px]" : "border-[1px]"
                          }`}
                        ></div>
                        <p className="whitespace-nowrap">Food</p>
                        {isFood ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        )}
                      </button>
                      <div className="flex gap-x-[8px]">
                        <button
                          onClick={() =>
                            setCurrentPage((prev) =>
                              currentPage === 1 ? 1 : prev - 1
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-[24px] h-[24px] text-txt-tourTextLight"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 19.5 8.25 12l7.5-7.5"
                            />
                          </svg>
                        </button>

                        <p
                          className="text-txt-tourTextLight text-base16     
                                                                            md:text-base18 "
                        >
                          {currentPage} / {Math.ceil(getArrayLength(index) / 4)}
                        </p>
                        <button
                          onClick={() =>
                            handlePaginationFood(
                              Math.ceil(getArrayLength(index) / 4)
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-[24px] h-[24px] text-txt-tourTextLight"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <CollapseNew isExpanded={isFood}>
                      <div className="">
                        {defaultData?.booked_food_option && (
                          <TourPackageFoodDetails
                            tourPackageFoodOption={
                              defaultData?.booked_food_option
                            }
                            paginationInd={currentPage}
                          />
                        )}
                      </div>
                    </CollapseNew>
                  </div>

                  {/* accommodation */}
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => setAccommodation(!isAccommodation)}
                        className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                      >
                        <div
                          className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                            isAccommodation ? "border-[5px]" : "border-[1px]"
                          }`}
                        ></div>
                        <p className="whitespace-nowrap">Accommodation</p>
                        {isAccommodation ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        )}
                      </button>

                      {/* pagination */}

                      <div className="flex gap-x-[8px]">
                        <button
                          onClick={() =>
                            setAccommodationPagination(
                              accommodationPagination === 1
                                ? 1
                                : accommodationPagination - 1
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-[24px] h-[24px] text-txt-tourTextLight"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 19.5 8.25 12l7.5-7.5"
                            />
                          </svg>
                        </button>

                        <p
                          className="text-txt-tourTextLight 
                                                                            text-base16
                                                                            md:text-base18 whitespace-nowrap"
                        >
                          {accommodationPagination} /{" "}
                          {Math.ceil(getArrayLengthAccommodation(index) / 4)}
                        </p>
                        <button
                          onClick={() =>
                            handlePaginationAccommodation(
                              Math.ceil(getArrayLengthAccommodation(index) / 4)
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-[24px] h-[24px] text-txt-tourTextLight"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <CollapseNew isExpanded={isAccommodation}>
                      <div className="">
                        {defaultData?.booked_accommodation_option && (
                          <TourPackageAccomodation
                            accommodationData={
                              defaultData?.booked_accommodation_option
                                ?.tour_package_available_accommodation_packages
                            }
                            accommodationPagination={accommodationPagination}
                          />
                        )}
                      </div>
                    </CollapseNew>
                  </div>

                  {/* Transportation */}

                  {/* <div className="w-full">
                    <button
                      onClick={() => setTransferIsOpen(!isTransportation)}
                      className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                    >
                      <div
                        className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                          isTransportation ? "border-[5px]" : "border-[1px]"
                        }`}
                      ></div>
                      <p className="whitespace-nowrap">
                        Transportation (Included)
                      </p>
                      {isTransportation ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      )}
                    </button>
                    <CollapseNew isExpanded={isTransportation}>
                      <div className="">
                        <h2 className="text-base14n">Dhaka-Chittagong-Dhaka</h2>
                        <div className="mt-[8px] flex justify-start gap-x-[12px]">
                          <div className="flex flex-col gap-y-[8px] text-base14n">
                            <p>Vehicle:</p>
                            <p>Provider:</p>
                            <p>Engine:</p>
                            <p>A/C:</p>
                          </div>
                          <div className="flex flex-col gap-y-[8px] text-base14n text-txt-tourTextLight ">
                            <p>Bus</p>
                            <p>Hanif Bus</p>
                            <p>Hino</p>
                            <p>Yes</p>
                          </div>
                        </div>
                      </div>
                    </CollapseNew>
                  </div> */}

                  {/* transfer */}
                  <div className="w-full">
                    <button
                      onClick={() => setIsTransfer(!isTransfer)}
                      className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                    >
                      <div
                        className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                          isTransfer ? "border-[5px]" : "border-[1px]"
                        }`}
                      ></div>
                      <p className="whitespace-nowrap">Transfer</p>
                      {isTransfer ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      )}
                    </button>

                    <CollapseNew isExpanded={isTransfer}>
                      <div className="flex flex-col gap-y-[12px]">
                        <div>
                          <div className="mt-[13px]">
                            {defaultData?.booked_transfer_option?.tour_package_available_transfer_packages?.map(
                              (transferData, index) => (
                                <>
                                  <h2 className="text-base14n">
                                    {
                                      transferData?.tour_package_available_transfer_route
                                    }
                                  </h2>
                                  <div className="mt-[8px] flex justify-start gap-x-[12px]">
                                    <div className="flex flex-col gap-y-[8px] text-base14n">
                                      <p>Vehicle:</p>

                                      <p>Provider:</p>

                                      <p>A/C:</p>
                                      <p>Trip type:</p>
                                      <p>Suitable for:</p>
                                    </div>

                                    <div className="flex flex-col gap-y-[8px] text-base14n text-txt-tourTextLight ">
                                      <p>
                                        {
                                          transferData?.tour_package_transportation_mode_name
                                        }
                                      </p>

                                      <p>
                                        {
                                          transferData?.tour_package_transportation_provider_name
                                        }
                                      </p>
                                      <p>
                                        {transferData?.is_ac ? "YES" : "NO"}
                                      </p>
                                      <p>{transferData?.trip_type}</p>
                                      <p>
                                        {transferData?.suitable_for_persons}{" "}
                                        person
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </CollapseNew>
                  </div>

                  {/* Guide */}
                  <div className="w-full">
                    <button
                      onClick={() => setIsGuide(!isGuide)}
                      className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                    >
                      <div
                        className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                          isGuide ? "border-[5px]" : "border-[1px]"
                        }`}
                      ></div>
                      <p className="whitespace-nowrap">Guide</p>
                      {isGuide ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      )}
                    </button>
                    <CollapseNew isExpanded={isGuide}>
                      <div className="flex flex-col gap-y-[12px]">
                        <div>
                          <div className="">
                            {defaultData?.booked_guide_option?.tour_package_guide_option?.map(
                              (guideDta, index) => (
                                <div
                                  key={index}
                                  className=" flex justify-start gap-x-[12px]"
                                >
                                  <div className="flex flex-col gap-y-[8px] text-base14n">
                                    <p>Provided Day:</p>
                                    <p>Guide Quantity:</p>
                                  </div>
                                  <div className="flex flex-col gap-y-[8px] text-base14n text-txt-tourTextLight ">
                                    <p>{guideDta?.day_number} Day</p>
                                    <p>
                                      {guideDta?.number_of_guides_for_day}{" "}
                                      Person
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </CollapseNew>
                  </div>

                  {/* Spot */}
                  <div className="w-full">
                    <button
                      onClick={() => setIsSpot(!isSpot)}
                      className="flex justify-start gap-x-[8px] items-center cursor-pointer mt-[12px]"
                    >
                      <div
                        className={`w-[16px] h-[16px] bg-bg-white rounded-full  border-btn-secondary ${
                          isSpot ? "border-[5px]" : "border-[1px]"
                        }`}
                      ></div>
                      <p className="whitespace-nowrap">Spot</p>
                      {isSpot ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      )}
                    </button>
                    <CollapseNew isExpanded={isSpot}>
                      <div className="flex flex-col gap-y-[12px]">
                        <div>
                          <div className="">
                            {defaultData?.booked_spot_entry_option?.tour_package_available_spot_entry_packages?.map(
                              (spotData, index) => (
                                <div
                                  key={index}
                                  className=" flex justify-start gap-x-[12px]"
                                >
                                  <div className="flex flex-col gap-y-[8px] text-base14n">
                                    <p>Spot :</p>
                                    <p>For:</p>
                                  </div>
                                  <div className="flex flex-col gap-y-[8px] text-base14n text-txt-tourTextLight ">
                                    <p>
                                      {spotData?.activity?.activity_name} Day
                                    </p>
                                    <p>{spotData?.remark}</p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </CollapseNew>
                  </div>
                </div>
              )
            )}

            {/* desicison area */}
            <div className="mt-[30px] absolute bottom-[20px] right-[30px] mb-[5px] flex justify-end items-end gap-x-[30px] ">
              {modalShown2 && (
                <div className="w-[380px] absolute bottom-[60px]">
                  <textarea
                    className="border w-full min-h-[100px] max-h-[100px]  py-[6px] px-[12px] rounded-[8px] border-btn-border"
                    onChange={(e) => setReason(e.target.value)}
                    type="text"
                    placeholder="Type your reason"
                  ></textarea>
                  <div className="flex justify-center items-center gap-x-[20px]">
                    {/* <button
                      onClick={() => handleRejectedReason()}
                      className="w-[80px] bg-btn-secondary text-bg-primary rounded-[4px] cursor-pointer"
                    >
                      Done
                    </button> */}
                    {/* <button
                      onClick={() => setToggleModal2(false)}
                      className="w-[80px] text-bg-primary rounded-[4px] bg-btn-orange"
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              )}

              <button
                onClick={() =>
                  handleRejected(singleBooking?.tour_booking_review_id)
                }
                className={`w-[185px] border border-btn-primary rounded-full text-btn-secondary cursor-pointer h-[43px] flex justify-center items-center  ${
                  reason?.length > 2 ? "bg-btn-primary text-bg-primary" : ""
                }`}
              >
                Reject
              </button>
              {removeCondition === false && (
                <button
                  onClick={() =>
                    handleConfirm(singleBooking?.tour_booking_review_id)
                  }
                  className="w-[292px] bg-btn-primary rounded-full text-bg-primary h-[43px] flex justify-center items-center cursor-pointer"
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default TourBookings;

export const TourPackageFoodDetails = ({
  tourPackageFoodOption,
  paginationInd,
}) => {
  function sortedFoodItem(foodItem) {
    const sortedFoodItems = foodItem?.sort((a, b) => a.length - b.length);

    return sortedFoodItems;
  }
  console.log("tourPackageFoodOption", tourPackageFoodOption);

  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[16px]">
      {Object.keys(tourPackageFoodOption.tour_package_available_meal_packages)
        ?.slice(paginationInd * 4 - 4, paginationInd * 4)
        ?.map((dayNumber) => {
          const mealPackages =
            tourPackageFoodOption.tour_package_available_meal_packages[
              dayNumber
            ];

          return (
            <div key={dayNumber} className="min-w-[231px] max-w-[231px] ">
              <h2 className="text-[12px]  md:text-[14px] font-bold mb-[4px]">
                Day {dayNumber}:
              </h2>
              {Object.keys(mealPackages).map((mealName) => {
                const meal = mealPackages[mealName];
                return (
                  <div
                    key={mealName}
                    className="mt-[8px] flex gap-x-[12px] w-full"
                  >
                    <h3 className="text-[12px] md:text-[14px] min-w-[69px] max-w-[59px] text-txt-primary_light">
                      {meal?.meal_type_name}:
                    </h3>
                    <div className="min-w-[170px] max-w-[170px] flex flex-wrap gap-x-[3px] justify-start whitespace-wrap">
                      {Object.entries(meal?.food_items).map(([key, value]) => (
                        <p key={key}>{value},</p>
                      ))}
                      {/* {sortedFoodItem(meal?.food_items)?.map(
                      (foodItem, index) => (
                        <p
                          key={index}
                          className="text-base12 md:text-base14n text-txt-tourTextLight"
                        >
                          {foodItem?.food_item_name}
                          {meal?.food_items?.length - (index + 1) ? "," : "."}
                        </p>
                      )
                    )} */}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export const TourPackageAccomodation = ({
  accommodationData,
  accommodationPagination,
}) => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[16px]">
      {Object.keys(accommodationData)
        ?.slice(accommodationPagination * 4 - 4, accommodationPagination * 4)
        ?.map((nightNumber) => {
          const AccommodationPackages = accommodationData[nightNumber];

          return (
            <div key={nightNumber} className="min-w-[251px]">
              <h2 className="text-base14 font-bold mb-[4px] ">
                Night {nightNumber}:
              </h2>
              <div className="mt-[8px] flex gap-x-[12px] w-full">
                <div className="flex justify-start items-start gap-x-[12px]">
                  <div className="min-w-[74px] max-w-[74px] flex gap-y-[8px] flex-col">
                    <p className="text-base14n ">Hotel : </p>
                    <p className="text-base14n ">Category:</p>
                    <p className="text-base14n ">Type:</p>
                  </div>
                  <div className="w-full flex gap-y-[8px] flex-col">
                    <p className="text-base14n text-txt-tourTextLight">
                      {AccommodationPackages?.tour_package_accommodation_name}
                    </p>
                    <p className="text-base14n text-txt-tourTextLight">
                      {AccommodationPackages?.tour_package_room_category_name}
                    </p>
                    <p className="text-base14n text-txt-tourTextLight">
                      {AccommodationPackages?.tour_package_room_type_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
