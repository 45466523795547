import React, { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { useLocation, useNavigate, useParams } from "react-router";

const TourPackageSelections = ({
  setIsLoading,
  getTours,
  isLoading,
  selectedNewListItem,
  setSelectedNewListItem,
  setEditAblePackage,
  setItemListNew,
}) => {
  const navigate = useNavigate();
  const [packageFoodList, setFoodPackageList] = useState([]);
  const [packageAccommodationList, setPackageAccommodationList] = useState([]);
  const [packageTransferList, setPackageTransferList] = useState([]);
  const [packageTransportationList, setPackageTransportationList] = useState(
    []
  );
  const [packageGuideList, setPackageGuideList] = useState([]);
  const [packageSpotEntryList, setPackageSpotEntryList] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.pathname);
  const subscribeId = parseInt(queryParams.get("subscribeId"));

  const iconObject = {
    edit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M13.3817 0.644005C12.9851 0.248036 12.4476 0.0256348 11.8872 0.0256348C11.3268 0.0256348 10.7893 0.248036 10.3927 0.644005L0.905373 10.1313C0.633687 10.4015 0.418268 10.7228 0.271581 11.0768C0.124893 11.4308 0.0498491 11.8103 0.05079 12.1934V13.391C0.05079 13.5457 0.112248 13.6941 0.221644 13.8035C0.331041 13.9129 0.479414 13.9743 0.634123 13.9743H1.83171C2.21482 13.9754 2.59434 13.9005 2.9483 13.7539C3.30226 13.6073 3.62362 13.392 3.89379 13.1203L13.3817 3.63242C13.7775 3.23587 13.9998 2.69849 13.9998 2.13821C13.9998 1.57794 13.7775 1.04056 13.3817 0.644005ZM3.06896 12.2955C2.73996 12.6223 2.29543 12.8063 1.83171 12.8077H1.21746V12.1934C1.21687 11.9635 1.26188 11.7358 1.3499 11.5234C1.43791 11.3111 1.56717 11.1183 1.73021 10.9562L8.93029 3.75609L10.272 5.09776L3.06896 12.2955ZM12.5563 2.80759L11.0945 4.27001L9.75279 2.93125L11.2152 1.46884C11.3033 1.38093 11.4078 1.31124 11.5229 1.26374C11.6379 1.21624 11.7612 1.19187 11.8856 1.192C12.0101 1.19214 12.1333 1.21678 12.2482 1.26453C12.3631 1.31228 12.4675 1.3822 12.5554 1.4703C12.6433 1.55839 12.713 1.66294 12.7605 1.77797C12.808 1.893 12.8324 2.01625 12.8323 2.1407C12.8321 2.26515 12.8075 2.38836 12.7597 2.50328C12.712 2.61821 12.6421 2.7226 12.554 2.81051L12.5563 2.80759Z"
          fill="#466773"
        />
      </svg>
    ),
    duplicate: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M11.1738 1.90867L9.84142 0.532583C9.67779 0.364563 9.48226 0.230907 9.2663 0.139452C9.05033 0.0479981 8.81828 0.000587085 8.58375 0L5.83333 0C5.16121 0.000848473 4.50994 0.233386 3.98927 0.658419C3.46861 1.08345 3.11039 1.67499 2.975 2.33333H2.91667C2.1434 2.33426 1.40208 2.64185 0.855295 3.18863C0.308515 3.73541 0.00092625 4.47674 0 5.25V11.0833C0.00092625 11.8566 0.308515 12.5979 0.855295 13.1447C1.40208 13.6915 2.1434 13.9991 2.91667 14H6.41667C7.18993 13.9991 7.93126 13.6915 8.47804 13.1447C9.02482 12.5979 9.33241 11.8566 9.33333 11.0833V11.025C9.99168 10.8896 10.5832 10.5314 11.0083 10.0107C11.4333 9.49006 11.6658 8.83879 11.6667 8.16667V3.12667C11.6675 2.67191 11.4907 2.23482 11.1738 1.90867ZM6.41667 12.8333H2.91667C2.45254 12.8333 2.00742 12.649 1.67923 12.3208C1.35104 11.9926 1.16667 11.5475 1.16667 11.0833V5.25C1.16667 4.78587 1.35104 4.34075 1.67923 4.01256C2.00742 3.68437 2.45254 3.5 2.91667 3.5V8.16667C2.91759 8.93993 3.22518 9.68126 3.77196 10.228C4.31874 10.7748 5.06007 11.0824 5.83333 11.0833H8.16667C8.16667 11.5475 7.98229 11.9926 7.65411 12.3208C7.32592 12.649 6.8808 12.8333 6.41667 12.8333ZM8.75 9.91667H5.83333C5.36921 9.91667 4.92409 9.73229 4.5959 9.4041C4.26771 9.07591 4.08333 8.6308 4.08333 8.16667V2.91667C4.08333 2.45254 4.26771 2.00742 4.5959 1.67923C4.92409 1.35104 5.36921 1.16667 5.83333 1.16667H8.16667V2.33333C8.16667 2.64275 8.28958 2.9395 8.50838 3.15829C8.72717 3.37708 9.02392 3.5 9.33333 3.5H10.5V8.16667C10.5 8.6308 10.3156 9.07591 9.98744 9.4041C9.65925 9.73229 9.21413 9.91667 8.75 9.91667Z"
          fill="#466773"
        />
      </svg>
    ),
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccommodation = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_GET_API}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageAccommodationList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransferPackage = () => {
    // merchant/tour/transfer/package/get/all/by/7?requestId=123
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSFER_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageTransferList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransportationPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE}${subscribeId}?requestId=10`
      )
      .then((res) => {
        setPackageTransportationList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageGuideList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpotEntry = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${subscribeId}?requestId=100`
      )
      .then((res) => {
        setPackageSpotEntryList(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      selectedNewListItem?.name === "Food" &&
      selectedNewListItem?.toggle === true
    ) {
      getMealPackage();
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Accommodation" &&
      selectedNewListItem?.toggle === true
    ) {
      getAccommodation();
      setFoodPackageList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Transfer" &&
      selectedNewListItem?.toggle === true
    ) {
      getTransferPackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Transportation" &&
      selectedNewListItem?.toggle === true
    ) {
      getTransportationPackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Guide" &&
      selectedNewListItem?.toggle === true
    ) {
      getGuidePackage();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageSpotEntryList([]);
    } else if (
      selectedNewListItem?.name === "Spot-Fee" &&
      selectedNewListItem?.toggle === true
    ) {
      getSpotEntry();
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
    } else {
      setFoodPackageList([]);
      setPackageAccommodationList([]);
      setPackageTransferList([]);
      setPackageTransportationList([]);
      setPackageGuideList([]);
      setPackageSpotEntryList([]);
    }
  }, [isLoading, selectedNewListItem]);

  const handleButtonPackage = (selectedPackage) => {
    setEditAblePackage(selectedPackage);
  };

  const changeSelectedNewItemList = (name, toggle) => {
    setSelectedNewListItem({ name: name, toggle: toggle });
    setItemListNew((prevList) => {
      return prevList?.map((item) => {
        return { ...item, toggle: item?.label === name ? toggle : false };
      });
    });
  };

  const navigateToOtherPage = () => {
    navigate(
      `/dashboard/tour-availability/tour_id=${getTours?.tour_id}&subscribeId=${subscribeId}&number_of_days=${getTours?.number_of_days}&number_of_nights=${getTours?.number_of_nights}`,
      {
        state: getTours,
      }
    );
  };

  return (
    <div className="w-[357px] flex flex-col gap-[24px]">
      <p className="text-tour-text text-[18px] font-medium">
        {selectedNewListItem?.name ? selectedNewListItem?.name : "Amenities"}{" "}
        Selections
      </p>

      {packageFoodList?.length === 0 &&
        packageAccommodationList?.length === 0 &&
        packageTransferList?.length === 0 &&
        packageTransportationList?.length === 0 &&
        packageGuideList?.length === 0 &&
        packageSpotEntryList?.length === 0 && (
          <div className="h-[52px] p-[16px] rounded-[8px] border-[1px] border-tour-input">
            <p className="text-[14px] text-tour-text font-normal">
              Start adding Items on the left menu
            </p>
          </div>
        )}

      {/* Food Package */}
      {packageFoodList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[500px] overflow-auto  ">
          {packageFoodList?.map((food, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px]  text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {" "}
                  {food?.meal_type_name}
                </p>
                <div className="flex flex-col gap-[8px] px-[12px] pt-[12px] pb-[16px]">
                  {/* <div className="flex flex-col gap-[4px]"> */}
                  <p className="text-[14px] font-normal">
                    <span className="text-tour-text">
                      {selectedNewListItem?.name} :{" "}
                    </span>
                    <span className="text-tour-secondary">
                      {food?.food_items?.map((itm, ind) => (
                        <span key={ind}>
                          {itm?.food_item_name}
                          {", "}
                        </span>
                      ))}
                    </span>
                  </p>
                  <p className="text-tour-text  ">
                    <span className="text-[14px] font-normal">Price: </span>
                    <span className="text-[16px] font-medium">
                      {food?.per_meal_package_price} BDT
                    </span>
                  </p>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(food)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(food)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(food)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit
                    </button>
                    <button 
                    onClick={() => handleButtonPackage(food)}
                    className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300">
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageFoodList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() => changeSelectedNewItemList("Accommodation", true)}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed with Accommodation
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}

      {/*Accommodation Package */}
      {packageAccommodationList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[500px] overflow-auto">
          {packageAccommodationList?.map((accommodation, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {accommodation?.tour_package_accommodation_name}
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">
                        {selectedNewListItem?.name} :{" "}
                      </span>
                      <span className="text-tour-secondary">
                        {accommodation?.bed_configuration}{" "}
                        {accommodation?.bed_count} bed
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Type Name"} : </span>
                      <span className="text-tour-secondary">
                        {accommodation?.tour_package_room_type_name}{" "}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Category"} : </span>
                      <span className="text-tour-secondary">
                        {accommodation?.tour_package_room_category_name}{" "}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">
                        {"Suitable For"} :{" "}
                      </span>
                      <span className="text-tour-secondary">
                        {accommodation?.suitable_for_persons}
                        {" persons"}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Shareable"} : </span>
                      <span className="text-tour-secondary">
                        {accommodation?.is_room_shareable ? "Yes" : "No"}
                      </span>
                    </p>
                    <p className="text-tour-text  ">
                      <span className="text-[14px] font-normal">Price: </span>
                      <span className="text-[16px] font-medium">
                        {accommodation?.per_night_room_price} BDT
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(accommodation)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(accommodation)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(accommodation)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit's
                    </button>
                    <button
                      onClick={() => handleButtonPackage(accommodation)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                    >
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageAccommodationList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() => changeSelectedNewItemList("Transfer", true)}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed with Transfer's
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}

      {/* Transfer Package */}
      {packageTransferList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[600px] overflow-auto  ">
          {packageTransferList?.map((transfer, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {" "}
                  {transfer?.tour_package_transfer_route}
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Provider: "}</span>
                      <span className="text-tour-secondary">
                        {transfer?.tour_package_transportation_provider_name}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Mode: "}</span>
                      <span className="text-tour-secondary">
                        {transfer?.tour_package_transportation_mode_name}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Suitable for: "}</span>
                      <span className="text-tour-secondary">
                        {transfer?.suitable_for_persons} {" persons"}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Trip type: "}</span>
                      <span className="text-tour-secondary">
                        {transfer?.trip_type}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Available Ac: "}</span>
                      <span className="text-tour-secondary">
                        {transfer?.is_ac ? "Yes" : "No"}
                      </span>
                    </p>
                    <p className="text-tour-text  ">
                      <span className="text-[14px] font-normal">Price: </span>
                      <span className="text-[16px] font-medium">
                        {transfer?.per_vehicle_per_trip_price} BDT
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(transfer)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(transfer)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(transfer)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleButtonPackage(transfer)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                    >
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageTransferList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() =>
                  changeSelectedNewItemList("Transportation", true)
                }
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed with Transportation
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}

      {/* Transportation Package */}
      {packageTransportationList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[600px] overflow-auto  ">
          {packageTransportationList?.map((transportation, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {" "}
                  {transportation?.tour_package_transportation_route_name}
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Bus type: "}</span>
                      <span className="text-tour-secondary">
                        {
                          transportation?.tour_package_transportation_provider_name
                        }
                        {" - "}

                        {transportation?.tour_package_transportation_brand_name}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Trip type: "}</span>
                      <span className="text-tour-secondary">
                        {transportation?.trip_type}
                      </span>
                    </p>
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Ac: "}</span>
                      <span className="text-tour-secondary">
                        {transportation?.is_ac ? "Yes" : "No"}
                      </span>
                    </p>
                    <p className="text-tour-text  ">
                      <span className="text-[14px] font-normal">Price: </span>
                      <span className="text-[16px] font-medium">
                        {transportation?.transportation_package_unit_price} BDT
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(transportation)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(transportation)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(transportation)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleButtonPackage(transportation)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                    >
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageTransportationList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() => changeSelectedNewItemList("Guide", true)}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed with Guide
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}

      {/* Guide Package */}
      {packageGuideList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[600px] overflow-auto  ">
          {packageGuideList?.map((guide, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {guide?.day_number} {" Day"}
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">
                        {"Guide Quantity: "}
                      </span>
                      <span className="text-tour-secondary">
                        {guide?.number_of_guides_for_day}
                      </span>
                    </p>
                    <p className="text-tour-text  ">
                      <span className="text-[14px] font-normal">Price: </span>
                      <span className="text-[16px] font-medium">
                        {guide?.total_guide_price_for_day} BDT
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(guide)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(guide)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(guide)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleButtonPackage(guide)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                    >
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageGuideList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() => changeSelectedNewItemList("Spot-Fee", true)}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed with Spot fee
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}

      {/* Spot Fee Package */}
      {packageSpotEntryList?.length !== 0 && (
        <div className="w-full flex flex-col gap-y-[24px] max-h-[600px] overflow-auto  ">
          {packageSpotEntryList?.map((spot, i) => (
            <div key={i} className="">
              <div className="border-[1px] border-tour-input rounded-[12px]">
                <p className="px-[12px] py-[6px] text-tour-text border-b-[1px] border-tour-secondaryHint font-normal">
                  {spot?.activity?.activity_name}
                </p>
                <div className="flex flex-col gap-[16px] px-[12px] pt-[12px] pb-[16px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] font-normal">
                      <span className="text-tour-text">{"Location: "}</span>
                      <span className="text-tour-secondary">
                        {spot?.activity?.activity_short_location}
                      </span>
                    </p>
                    <p className="text-tour-text  ">
                      <span className="text-[14px] font-normal">Price: </span>
                      <span className="text-[16px] font-medium">
                        {spot?.spot_entry_price_per_person} BDT
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end items-center gap-[16px]">
                    <div
                      onClick={() => handleButtonPackage(spot)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.edit}</p>
                      <p>Edit</p>
                    </div>
                    <div className="h-[12px] w-[1px] bg-tour-secondary"></div>
                    <div
                      onClick={() => handleButtonPackage(spot)}
                      className="flex flex-row justify-center items-center gap-[4px] text-tour-secondary cursor-pointer border-b-[1px] border-bg-white hover:border-tour-secondary"
                    >
                      <p>{iconObject?.duplicate}</p>
                      <p>Duplicate</p>
                    </div>
                  </div>
                  {/* <div className="flex gap-[16px]">
                    <button
                      onClick={() => handleButtonPackage(spot)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
                    hover:bg-tour-secondaryHint transition ease-in duration-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleButtonPackage(spot)}
                      className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] bg-tour-primary text-bg-white text-[14px] border-[1px] border-tour-primary rounded-[50px] hover:bg-tour-secondaryShade transition ease-in duration-300"
                    >
                      Duplicate
                    </button>
                  </div> */}
                </div>
              </div>

              {/* <div
            //   onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div> */}
            </div>
          ))}

          {/* {packageSpotEntryList?.length > 0 && (
            <div className="pt-[8px]">
              <button
                onClick={() => navigateToOtherPage()}
                className="w-full flex justify-center items-center h-[36px] px-[16px] py-[10px] text-tour-secondaryShade text-[14px] border-[1px] border-tour-secondaryShade rounded-[50px]
              hover:bg-tour-secondaryShade hover:text-bg-white transition ease-in duration-300"
              >
                Proceed to set availability
              </button>
            </div>
          )} */}

          {/* {packageList?.length > 0 && (
            <button
              //   onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )} */}
        </div>
      )}
    </div>
  );
};

export default TourPackageSelections;
