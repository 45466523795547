import React, { useEffect, useState } from "react";
import { MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import SubscribeCard from "../Tours/SubscribeCart";

const SubscribeTour = () => {
  const [allTourData, setAllTourData] = useState([]);
  const marchantId = localStorage.getItem("id");
  const getAllTourList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SUBSCRIPTION_WITH_PACKAGE}${marchantId}/SUBSCRIBED_TOUR_WITH_PACKAGE?requestId=123`
      )
      .then((res) => {
        // console.log(res?.data?.tours);
        setAllTourData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllTourList();
  }, []);
  // console.log("allTourData", allTourData);
  return (
    <>
      <div className="  mx-auto w-full ">
        {/* {allTourData?.subscribed_tours &&
          allTourData?.subscribed_tours?.map((tour, index) => (
            <SubscribeCard key={index} tour={tour} />
          ))} */}
        {allTourData?.subscribed_tours && (
          <SubscribeCard tour={allTourData?.subscribed_tours} />
        )}
      </div>
    </>
  );
};

export default SubscribeTour;
