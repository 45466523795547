import React from 'react'

const TourSearch = () => {
  return (
    <div className="flex flex-col gap-y-[8px]">
          <div className="px-[64px] py-[10px]">
            <p className="text-tour-text text-[32px]">Tours Catalogue</p>
          </div>
          {/* Search and filter */}
          <div className="px-[64px] flex items-center gap-x-[32px]">
            
              <div
                className="min-w-[976px] h-[52px] flex border-[1px] py-[4px] border-tour-primary 
             rounded-[70px] gap-x-[16px]"
              >
                <div className="flex h-[44px] w-[854px] py-[14px] items-center justify-center ">
                  {/* <p className="text-tour-text">Location Search</p> */}
                  <input
                    className="placeholder:text-tour-text"
                    type="text"
                    placeholder="Location Search"
                  />
                </div>
                <div className="w-[100px] flex items-center justify-center px-[24px] py-[14px] border-[1px] border-tour-primary rounded-[70px]">
                  <p className="text-[14px] text-tour-primary">Search</p>
                </div>
              </div>
              <div className="w-[303px] h-[52px] border-[1px] bg-tour-primary border-tour-primary rounded-[70px]">
                <div className="flex justify-center items-center py-[12px] px-[32px] gap-[8px]">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_11534_1028)">
                        <path
                          d="M0.5 2.37513H1.868C1.97532 2.77 2.20959 3.11858 2.53466 3.36709C2.85974 3.61561 3.25756 3.75026 3.66675 3.75026C4.07594 3.75026 4.47376 3.61561 4.79884 3.36709C5.12391 3.11858 5.35818 2.77 5.4655 2.37513H11.5C11.6326 2.37513 11.7598 2.32245 11.8536 2.22868C11.9473 2.13491 12 2.00774 12 1.87513C12 1.74252 11.9473 1.61534 11.8536 1.52158C11.7598 1.42781 11.6326 1.37513 11.5 1.37513H5.4655C5.35818 0.980264 5.12391 0.631682 4.79884 0.383164C4.47376 0.134646 4.07594 0 3.66675 0C3.25756 0 2.85974 0.134646 2.53466 0.383164C2.20959 0.631682 1.97532 0.980264 1.868 1.37513H0.5C0.367392 1.37513 0.240215 1.42781 0.146447 1.52158C0.0526784 1.61534 0 1.74252 0 1.87513C0 2.00774 0.0526784 2.13491 0.146447 2.22868C0.240215 2.32245 0.367392 2.37513 0.5 2.37513ZM3.6665 1.00013C3.83956 1.00013 4.00873 1.05145 4.15262 1.14759C4.29652 1.24374 4.40867 1.3804 4.47489 1.54028C4.54112 1.70017 4.55845 1.8761 4.52469 2.04583C4.49093 2.21557 4.40759 2.37148 4.28522 2.49385C4.16285 2.61622 4.00694 2.69955 3.8372 2.73332C3.66747 2.76708 3.49154 2.74975 3.33165 2.68352C3.17177 2.6173 3.03511 2.50515 2.93896 2.36125C2.84282 2.21736 2.7915 2.04819 2.7915 1.87513C2.79176 1.64315 2.88404 1.42074 3.04807 1.2567C3.21211 1.09267 3.43452 1.00039 3.6665 1.00013Z"
                          fill="white"
                        />
                        <path
                          d="M11.5 5.49964H10.132C10.0249 5.10468 9.79069 4.75597 9.46564 4.50735C9.14058 4.25873 8.74273 4.12402 8.3335 4.12402C7.92427 4.12402 7.52642 4.25873 7.20136 4.50735C6.87631 4.75597 6.64214 5.10468 6.535 5.49964H0.5C0.367392 5.49964 0.240215 5.55232 0.146447 5.64608C0.0526784 5.73985 0 5.86703 0 5.99964C0 6.13224 0.0526784 6.25942 0.146447 6.35319C0.240215 6.44696 0.367392 6.49964 0.5 6.49964H6.535C6.64214 6.89459 6.87631 7.2433 7.20136 7.49192C7.52642 7.74054 7.92427 7.87525 8.3335 7.87525C8.74273 7.87525 9.14058 7.74054 9.46564 7.49192C9.79069 7.2433 10.0249 6.89459 10.132 6.49964H11.5C11.6326 6.49964 11.7598 6.44696 11.8536 6.35319C11.9473 6.25942 12 6.13224 12 5.99964C12 5.86703 11.9473 5.73985 11.8536 5.64608C11.7598 5.55232 11.6326 5.49964 11.5 5.49964ZM8.3335 6.87464C8.16044 6.87464 7.99127 6.82332 7.84738 6.72717C7.70348 6.63103 7.59133 6.49437 7.52511 6.33448C7.45888 6.1746 7.44155 5.99867 7.47531 5.82893C7.50907 5.6592 7.59241 5.50329 7.71478 5.38092C7.83715 5.25855 7.99306 5.17521 8.1628 5.14145C8.33253 5.10769 8.50846 5.12502 8.66835 5.19124C8.82823 5.25747 8.96489 5.36962 9.06104 5.51351C9.15718 5.65741 9.2085 5.82658 9.2085 5.99964C9.20823 6.23162 9.11596 6.45403 8.95193 6.61806C8.78789 6.7821 8.56548 6.87437 8.3335 6.87464Z"
                          fill="white"
                        />
                        <path
                          d="M11.5 9.62512H5.4655C5.35818 9.23026 5.12391 8.88168 4.79884 8.63316C4.47376 8.38465 4.07594 8.25 3.66675 8.25C3.25756 8.25 2.85974 8.38465 2.53466 8.63316C2.20959 8.88168 1.97532 9.23026 1.868 9.62512H0.5C0.367392 9.62512 0.240215 9.6778 0.146447 9.77157C0.0526784 9.86534 0 9.99251 0 10.1251C0 10.2577 0.0526784 10.3849 0.146447 10.4787C0.240215 10.5724 0.367392 10.6251 0.5 10.6251H1.868C1.97532 11.02 2.20959 11.3686 2.53466 11.6171C2.85974 11.8656 3.25756 12.0002 3.66675 12.0002C4.07594 12.0002 4.47376 11.8656 4.79884 11.6171C5.12391 11.3686 5.35818 11.02 5.4655 10.6251H11.5C11.6326 10.6251 11.7598 10.5724 11.8536 10.4787C11.9473 10.3849 12 10.2577 12 10.1251C12 9.99251 11.9473 9.86534 11.8536 9.77157C11.7598 9.6778 11.6326 9.62512 11.5 9.62512ZM3.6665 11.0001C3.49344 11.0001 3.32427 10.9488 3.18038 10.8527C3.03648 10.7565 2.92433 10.6199 2.85811 10.46C2.79188 10.3001 2.77455 10.1242 2.80831 9.95442C2.84207 9.78469 2.92541 9.62878 3.04778 9.50641C3.17015 9.38404 3.32606 9.3007 3.4958 9.26694C3.66553 9.23318 3.84146 9.2505 4.00135 9.31673C4.16123 9.38296 4.29789 9.49511 4.39404 9.639C4.49018 9.78289 4.5415 9.95206 4.5415 10.1251C4.5411 10.3571 4.44879 10.5794 4.28478 10.7434C4.12077 10.9074 3.89844 10.9997 3.6665 11.0001Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11534_1028">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="text-[14px] text-bg-white">Filter</p>
                </div>
              </div>
            
          </div>
        </div>
  )
}

export default TourSearch