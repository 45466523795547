import React, { useEffect, useState } from "react";
import ModalCommon from "../components/common/ModalCommon";
import InputComponentSecond from "../components/InputComponentSecond";
import Loading from "../components/Loader/Loading";
import { BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import Paginations from "./admin/Paginations/Paginations";

const BookingAllSearchAndSeeDetails = () => {
  const [allSalesOrder, setAllSalesOrder] = useState([]);
  const [payments, setPayments] = useState([]);
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalSecond, setToggleModalSecond] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let pageSize = 10;

  console.log({ allSalesOrder });

  const onClickPayments = (payment) => {
    setPayments(payment);
    setToggleModal(!toggleModal);
  };

  const onClickProducts = (product) => {
    setProducts(product);
    setToggleModalSecond(!toggleModalSecond);
  };

  const getAllSalesOrder = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${BOOKING_ENDPOINTS?.ADMIN_GET_ALL_SALES_ORDER_FOR_SEARCH_DETAILS}pageNumber=${currentPage}&pageSize=${pageSize}&queryString=${searchValue}`
      )
      .then((res) => {
        setAllSalesOrder(res?.data?.entries);
        setTotalPage(res?.data?.totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllSalesOrder();
  }, [currentPage, pageSize, searchValue]);

  return (
    <div>
      <div className="my-[20px]">
        <p className="xl:text-4xl lg:text-3xl text-2xl  mb-6 text-center text-btn-primary">
          Ghuddy Sales Search and See Details
        </p>
      </div>

      <div className="flex justify-end px-[40px]">
        <InputComponentSecond
          type={"text"}
          name={"search"}
          value={searchValue}
          placeholder={"Search sales order code"}
          setValue={setSearchValue}
          height={"h-12"}
          width={"xl:w-72 md:w-60 w-48"}
        ></InputComponentSecond>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : allSalesOrder.length === 0 ? (
        <div className="my-10 text-center">
          <h2 className="text-2xl md:text-3xl xl:text-4xl text-btn-secondary">
            No Data Found!
          </h2>
        </div>
      ) : (
        <div>
          {/* Table for data */}
          <div>
            <div className="px-[20px]">
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Sl No.</th>
                    <th>Sales order Code</th>
                    <th>Customer name</th>
                    <th>Customer phone no</th>
                    <th>No of payment trails</th>
                    <th>No of products</th>
                    <th>Created at</th>
                    <th>Status</th>
                    <th>Total payable</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {allSalesOrder?.map((item, index) => (
                    <tr key={item?.invoiceId}>
                      <td>{index + 1 + pageSize * currentPage - pageSize}.</td>
                      <td>{item?.orderCode}</td>
                      <td className="whitespace-pre-line">
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td>{item?.username}</td>
                      <td className="">
                        <button
                          onClick={() => onClickPayments(item?.payments)}
                          className="bg-btn-secondary text-bg-white w-[70px] px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-md shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg "
                        >
                          {item?.payments?.length}
                        </button>
                      </td>
                      <td className="">
                        <button
                          onClick={() => onClickProducts(item?.productList)}
                          className="bg-btn-secondary text-bg-white w-[70px] px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-md shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg "
                        >
                          {item?.productList?.length}
                        </button>
                      </td>
                      <td className="whitespace-pre-line">{item?.createdAt}</td>
                      <td>
                        {item?.invoiceUrl !== null ? (
                          <span className="text-[#22c55e]">
                            {item?.orderStatus}
                          </span>
                        ) : (
                          <>{item?.orderStatus}</>
                        )}
                      </td>
                      <td>{item?.totalPayable} BDT</td>
                      <td>
                        {item?.invoiceUrl !== null ? (
                          <button
                            download={item?.invoiceUrl}
                            title="Download Invoice"
                            className={`bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg `}
                          >
                            <a download href={item?.invoiceUrl}>
                              Download
                            </a>
                          </button>
                        ) : (
                          <button
                            disabled
                            title="Invoice Invalid"
                            className={`bg-bg-semiblack text-bg-primary w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-bg-semiblack rounded-md shadow-md transition-all duration-500 hover:text-bg-semiblack hover:shadow-lg `}
                          >
                            Download
                          </button>
                        )}
                      </td>
                      {/* <td>
                  <button
                    // onClick={() => onEditButton(property)}
                    className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                  >
                    Edit
                  </button>
                </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>

          {/* For Payments view */}
          <ModalCommon
            shown={toggleModal}
            width="max-w-[763px]"
            height="min-h-[500px]"
            outSideOff={false}
            close={() => {
              setToggleModal(false);
            }}
          >
            <div className="py-[16px] px-[32px]">
              <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                Payment Details
              </h2>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Rank</th>
                    <th>Method</th>
                    <th>Gateway</th>
                    <th>Transaction Id</th>
                    <th>Status</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.map((payment, index) => (
                    <tr key={index}>
                      <td>
                        {payment?.payment_rank === null
                          ? "No Data"
                          : payment?.payment_rank}
                      </td>
                      <td>
                        {payment?.payment_method === null
                          ? "No Data"
                          : payment?.payment_method}
                      </td>
                      <td>
                        {payment?.payment_gateway === null
                          ? "No Data"
                          : payment?.payment_gateway}
                      </td>
                      <td>
                        {payment?.payment_transaction_id === null
                          ? "No Data"
                          : payment?.payment_transaction_id}
                      </td>
                      {/* <td>{payment?.payment_status}</td> */}
                      <td>
                        {payment?.payment_status === "Success" ||
                        payment?.payment_status === "Completed" ? (
                          <span className="text-[#22c55e]">
                            {payment?.payment_status}
                          </span>
                        ) : (
                          <>
                            {payment?.payment_status === null
                              ? "No Data"
                              : payment?.payment_status}
                          </>
                        )}
                      </td>
                      <td className="whitespace-pre-line">
                        {payment?.payment_action_time === null
                          ? "No Data"
                          : payment?.payment_action_time}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ModalCommon>

          {/* For Products view */}
          <ModalCommon
            shown={toggleModalSecond}
            width="max-w-[1100px]"
            height="min-h-[500px]"
            outSideOff={false}
            close={() => {
              setToggleModalSecond(false);
            }}
          >
            <div className="py-[16px] px-[32px]">
              <h2 className="text-btn-primary text-center xl:text-[22px] md:text-[20px] underline mb-[20px]">
                Products Details
              </h2>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Config Id</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Check in Date</th>
                    <th>Check out Date</th>
                    <th>Total Black Price</th>
                    <th>Total Red Price</th>
                    <th>Product Type</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((payment, index) => (
                    <tr key={index}>
                      <td className="break-words">
                        {payment?.configId === null
                          ? "No Data"
                          : payment?.configId}
                      </td>
                      <td className="whitespace-pre-line">
                        {payment?.title === null ? "No Data" : payment?.title}
                      </td>
                      <td className="whitespace-pre-line">
                        {payment?.description === null
                          ? "No Data"
                          : payment?.description}
                      </td>
                      <td>
                        {payment?.quantity === null
                          ? "No Data"
                          : payment?.quantity}
                      </td>
                      {payment?.productType === "TOUR" ? (
                        <>
                          <td>
                            {payment?.customFields
                              ?.tour_package_available_start_date === null
                              ? "No Data"
                              : payment?.customFields
                                  ?.tour_package_available_start_date}
                          </td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td>
                            {payment?.customFields?.checkinDate === null
                              ? "No Data"
                              : payment?.customFields?.checkinDate}
                          </td>
                          <td>
                            {payment?.customFields?.checkoutDate === null
                              ? "No Data"
                              : payment?.customFields?.checkoutDate}
                          </td>
                        </>
                      )}

                      <td>
                        {payment?.totalBlackPrice === null
                          ? "No Data"
                          : payment?.totalBlackPrice}{" "}
                        BDT
                      </td>
                      <td>
                        {payment?.totalRedPrice === null
                          ? "No Data"
                          : payment?.totalRedPrice}{" "}
                        BDT
                      </td>
                      <td>{payment?.productType}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ModalCommon>
        </div>
      )}
    </div>
  );
};

export default BookingAllSearchAndSeeDetails;
