import React, { useState } from "react";
import PrivateRoute from "../utils/privateRoute";
import ExcursionNav from "./ExcursionNav";

const ExcursionLayout = ({ Component }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <ExcursionNav />

      <div className="my-[39px] max-w-[1440px] mx-auto">
        <div className="flex min-h-screen ">
          {/* <div className="sticky top-[125px] h-screen">
            <TourSideNav></TourSideNav>
          </div> */}
          <div className="p-[22px] w-full bg-[#f3f5f7] ">
            <PrivateRoute Component={Component} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExcursionLayout;
