import React, { useEffect, useRef } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import SingleSelect from "./SingleSelect";

import RootComponent from "../rootComponent/RootComponent";
import { ErrorMessage, Field } from "formik";
import { customValidationSingle } from "../../utils/customValidation";

function SingleSelectParent({
  data,
  selectedValue,
  setSelectedValue,
  placeholder,
  displayField,
  selectedFieldName,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
  required = false,
  onBlur = null,
}) {
  const [visible, setVisible] = React.useState(false);
  const [searchValue, SetSearchValue] = React.useState("");

  // state for ref width support
  const [width, setWidth] = React.useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const focusHandler = () => {
    const input = document.getElementById(`input${name}`);
    input.focus();
  };

  // console.log(selectedValue, "selectedValue");
  // console.log(data, "datadata123");

  const findCountryByCode = (code) => {
    if (data && data.length && selectedValue) {
      const selectedValueIndex = data.findIndex(
        (item) => item[selectedFieldName] === selectedValue
      );
      if (selectedValueIndex >= 0) {
        return data.find((country) => country[selectedFieldName] === code)
          .country;
      }
    } else {
      return null;
    }
  };

  const handleSearchChange = (e) => {
    SetSearchValue(e.target.value);
    setSelectedValue(e.target.value);
  };

  let component = (
    <ReactOutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div
        id={name}
        className={`w-full flex flex-col rounded-lg relative ${
          required && "required"
        }`}
      >
        <div className="bg-[transparent]">
          <div
            className="w-full rounded-[9px] h-[49px] flex outline-none"
            ref={ref}
            onClick={() => {
              setVisible(!visible);
              focusHandler();
            }}
          >
            <p
              onClick={() => {
                setVisible(!visible);
                focusHandler();
              }}
              className={` w-full h-full ${
                selectedValue ? "text-txt-primary" : "text-bg-semiblack"
              }`}
            >
              {/* {selectedValue ? findCountryByCode(selectedValue) : placeholder} */}
              <input
                autoComplete="off"
                id={`input${name}`}
                value={selectedValue ? findCountryByCode(selectedValue) : ""}
                placeholder={placeholder}
                onClick={(e) => e.preventDefault()}
                className="w-full text-left pl-4 h-full  bg-[transparent] validation focus:outline-none active:outline-none border-[#9e9989] rounded-l-[8px] rounded-r-0 border border-r-0 text-xs"
                onChange={(e) => {
                  handleSearchChange(e);
                  setTimeout(() => {
                    customValidationSingle(name);
                  }, 200);
                }}
                style={{
                  background: "rgba(0,0,0,0)",
                  outline: "none",
                }}
                onBlur={() => customValidationSingle(name)}
              />
            </p>
            <button
              onClick={() => {
                setVisible(!visible);
                focusHandler();
              }}
              className="validation bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-r-[8px] rounded-l-none border border-l-0 border-[#314747] px-5 hover:bg-btn-secondary duration-500"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#FFFBEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {visible && (
          <div
            className=" pt-4 rounded-[8px] mt-16 bg-bg-primary custom-shadow z-50 absolute max-h-[200px] h-auto overflow-scroll"
            style={{ width: width }}
          >
            <div className="">
              {data && data.length
                ? data
                    .filter((code) =>
                      JSON.stringify(code)
                        .toLocaleLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <SingleSelect
                          displayField={displayField}
                          key={index}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                          item={item}
                          setVisible={setVisible}
                          selectedFieldName={selectedFieldName}
                          validation={required}
                          name={name}
                        />
                      );
                    })
                : null}
            </div>
          </div>
        )}
        <div className="errorMessage validationError"></div>
      </div>
    </ReactOutsideClickHandler>
  );
  let readOnlyViewComponent = (
    <p>
      {findCountryByCode(selectedValue)
        ? findCountryByCode(selectedValue)
        : null}
    </p>
  );
  return (
    <div className="w-full">
      <p className="text-[12px]">{placeholder}</p>
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default SingleSelectParent;
