import { useEffect, useState } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { FAQ_ENDPOINTS, apiEndPoint, baseUrl } from "../../contants/Endpoints";
import Paginations from "./Paginations/Paginations";
import ModalCommon from "../../components/common/ModalCommon";
import SingleSelectNew from "../../components/singleSelect/singleSelectObjectReturn/SingleSelectNew";
import { useNavigate } from "react-router";
import { adminRoute } from "../../contants/Constants";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import InputComponentSearch from "../../components/InputComponentSearch";

export default function AdminFaq() {
  const navigate = useNavigate();
  const [allFaq, setAllFaq] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [isOldGroup, setIsOldGroup] = useState(false);
  const [faqGroupId, setFaqGroupId] = useState(0);
  const [faqGroupIconId, setFaqGroupIconId] = useState(0);
  let [temp, setTemp] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const pageSize = 20;

  const [newGroupFaqData, setNewGroupFaqData] = useState({
    group_name: "",
    mode_icon_url: "",
  });

  const getAllFaqWithPagination = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${FAQ_ENDPOINTS.ADMIN_GET_FAQ_FAQ_GROUP_SEARCH}?requestId=123`
      )
      .then((res) => {
        setAllFaq(res?.data?.data_list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(searchValue?.length > 0){
      setIsLoading(true);
    authAxios
      .get(
        `${FAQ_ENDPOINTS.ADMIN_GET_FAQ_FAQ_GROUP_SEARCH}?queryString=${searchValue}&requestId=123`
      )
      .then((res) => {
        setAllFaq(res?.data?.data_list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else if(searchValue?.length === 0){
      getAllFaqWithPagination();
    }
  }, [searchValue])
  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data?.iconData);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getAllFaqWithPagination();
  }, []);

  const onEditAddNewGroup = () => {
    setIsOldGroup(false);
    setNewGroupFaqData({
      group_name: "",
      mode_icon_url: "",
    });
    setTemp("");
    setToggleModal(true);
  };

  const payloadMethod = {
    groupName: newGroupFaqData?.group_name,
    iconId: newGroupFaqData?.mode_icon_url?.id,
    requestId: "123",
  };

  const onSubmitNewFaq = () => {
    setIsLoading(true);
    authAxios
      ?.post(`${FAQ_ENDPOINTS.ADMIN_POST_FAQ_GROUP}`, payloadMethod)
      .then((res) => {
        setToggleModal(false);
        toast.success("Successfully Added.");
        getAllFaqWithPagination();
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Added failed!");
      });
  };

  const onViewButton = (id, name) => {
    navigate(`${adminRoute.ADMIN_FAQ_GROUP_ID}${id}`, {
      state: { groupName: name },
    });
  };

  const onUpdateButton = (property) => {
    setIsOldGroup(true);
    setNewGroupFaqData((prev) => ({
      ...prev,
      group_name: property?.group_name,
      mode_icon_url: { url: property?.icon_url },
    }));
    // setTemp(property?.icon_url)
    setFaqGroupId(property?.group_id);
    setFaqGroupIconId(property?.icon_id)
    setToggleModal(true);
  };

  const updatePayload = {
    groupId: faqGroupId,
    newGroupName: newGroupFaqData?.group_name,
    newIconId: newGroupFaqData?.mode_icon_url?.id || faqGroupId,
    requestId: "string",
  };

  const onUpdateGroupFaq = () => {
    authAxios.patch(`${FAQ_ENDPOINTS?.ADMIN_PATCH_FAQ_GROUP}`, updatePayload)
    .then((res) => {
      setToggleModal(false)
      getAllFaqWithPagination();
      toast.success("Successfully Updated.")

    })
    .catch((err) => {
      console.log(err)
      toast.error("Update failed!")
    })
  };

  const checkStatus = () => {
    return (
      newGroupFaqData?.group_name?.length === 0 ||
      newGroupFaqData?.mode_icon_url?.length === 0
    );
  };

  return (
    <div className="container mx-auto">
      <h2 className="lg:text-4xl md:text-3xl text-2xl my-10 text-center text-btn-primary">
        Frequently Asked Questions
      </h2>

      <div className="flex justify-end ">
        <button
          onClick={() => onEditAddNewGroup()}
          className="bg-btn-primary text-bg-white w-[200px] px-[10px] py-[10px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
        >
          Add New Group
        </button>

        <div className="ml-[12px] mr-[20px] mt-[16px]">
              <InputComponentSearch
                placeholder={"Search group"}
                width={"xl:w-96 md:72 w-60"}
                height={"h-12"}
                borderRadius={"rounded"}
                value={searchValue}
                setValue={setSearchValue}
                onFocusValue={true}
              ></InputComponentSearch>
            </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="mx-[20px]">
          {/* Show if data has */}
          {allFaq?.length > 0 && (
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>ID</th>
                    <th>Icon</th>
                    <th>Group Name</th>
                    <th>No of Questions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allFaq?.length > 0 &&
                    allFaq?.map((property, index) => (
                      <tr key={index}>
                        <td>{property?.group_id}</td>
                        <td className="cursor-pointer mx-auto">
                          <img
                            className="mx-auto w-[40px] h-[40px]"
                            src={property?.icon_url}
                            alt=""
                          />
                        </td>
                        <td>{property?.group_name}</td>
                        <td>
                          {property?.no_of_questions}
                          {/* <button
                            //   onClick={() => onEditButton(property)}
                              className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                            >
                              Edit
                            </button> */}
                        </td>
                        {/* <td>
                        <button
                
                              onClick={() => onViewButton(property?.group_id, property?.group_name)}
                              className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                            >
                              View
                            </button>
                      </td> */}
                        <td className="flex justify-center gap-x-[8px]">
                          <button
                            onClick={() => onUpdateButton(property)}
                            className="bg-btn-secondary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-secondary rounded-md shadow-md transition-all duration-500 hover:text-btn-secondary hover:shadow-lg "
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              onViewButton(
                                property?.group_id,
                                property?.group_name
                              )
                            }
                            className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="my-10">
                <Paginations
                  currentPage={currentPage}
                  totalCount={totalPage}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div>
        <ModalCommon
          shown={toggleModal}
          width="max-w-[450px]"
          height="min-h-[300px]"
          outSideOff={false}
          close={() => {
            setToggleModal(false);
          }}
        >
          <div className="my-[20px] mx-[32px]">
            <p className="text-btn-primary text-center text-[20px]">
              Add a new group
            </p>
            <div className="my-[16px] ">
              <p className="text-btn-secondary">Group Name: </p>
              <div className="rounded">
                <input
                  className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] "
                  placeholder="Group Name: "
                  type="text"
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setNewGroupFaqData((prev) => ({
                      ...prev,
                      group_name: inputVal,
                    }));
                  }}
                  value={newGroupFaqData?.group_name}
                />
              </div>
              {showError && newGroupFaqData?.group_name?.length === 0 && (
                <p className="text-[13px] text-[#f87171] mt-[2px]">
                  Group name is required.
                </p>
              )}
            </div>

            <div className=" ">
              <p className="pb-[5px] text-btn-secondary">Mode Icon:</p>

              <div className="flex justify-start items-center gap-x-[8px]">
                <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                  <img
                    className="w-[40px] h-[40px]"
                    src={
                      newGroupFaqData?.mode_icon_url?.url
                        ? newGroupFaqData?.mode_icon_url?.url
                        : newGroupFaqData?.mode_icon_url
                    }
                    alt=""
                  />
                </div>

                <SingleSelectNew
                  warningView={false}
                  data={iconsList}
                  displayField="title"
                  placeholder={"Icons"}
                  selectedFieldName={"title"}
                  selectedValue={newGroupFaqData?.mode_icon_url?.url}
                  setSelectedValue={(mode_icon_url) =>
                    setNewGroupFaqData((prev) => ({
                      ...prev,
                      mode_icon_url: mode_icon_url,
                    }))
                  }
                  setSelectedObject={() => {}}
                  image={true}
                  isReadOnly={false}
                  temp={temp}
                  setTemp={setTemp}
                />
              </div>
              {/* {showError &&
                      fullInformation?.mode_icon_url?.length === 0 && (
                        <p className="text-[13px] text-[#f87171] mt-[2px]">
                          Icon is required.
                        </p>
                      )} */}
            </div>

            {!isOldGroup ? (
              <button
                disabled={checkStatus()}
                onClick={() => onSubmitNewFaq()}
                className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
              >
                Submit
              </button>
            ) : (
              <button
                disabled={checkStatus()}
                onClick={() => onUpdateGroupFaq()}
                className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
              >
                Update
              </button>
            )}
          </div>
        </ModalCommon>
      </div>
    </div>
  );
}