import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ModalCommon from "../../../../components/common/ModalCommon";
import { MERCHANT_TOUR_ENDPOINT } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import { convertStringToObject } from "../../ParentPackageSelected";
import DragAndDropAccommodation from "../DragAndDropAccomodation";
import DragAndDropGuide from "../DragAndDropGuide";
import DragAndDropTransfer from "../DragAndDropTransfer";
import DragAndDropTransportation from "../DragAndDropTransportation";
import DragAndDrop from "../DragItem";
import { sliderStyles, switchStyles } from "./FoodPackage";

export default function GuidePackage({
  requestToPayload,
  setActiveStep,
  setRequestToPayload,
}) {
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [toggleModal, setToggleModal] = useState(false);
  const [targets, setTargets] = useState({});
  const [editInd, setEditInd] = useState(null);

  const [guidePackageList, setGuidePackageList] = useState([]);

  console.log("requestToPayload", requestToPayload);
  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setGuidePackageList(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getGuidePackage();
  }, []);
  const handleSetAccommodation = () => {
    if (editInd === null) {
      setRequestToPayload((prev) => ({
        ...prev,
        tour_package_guide_options: [
          ...prev.tour_package_guide_options,
          targets,
        ],
      }));
    } else {
      setRequestToPayload((prev) => {
        const updatedTourPackageAccommodationOptions =
          prev.tour_package_guide_options.map((item, index) => {
            if (index === editInd) {
              return targets;
            }

            return item;
          });

        return {
          ...prev,
          tour_package_guide_options: updatedTourPackageAccommodationOptions,
        };
      });
    }

    setToggleModal(false);
    setTargets({});
  };
  const handleEdit = (data, ind) => {
    setTargets(data);
    setEditInd(ind);
    setToggleModal(true);
  };
  return (
    <div className="mt-[10px]">
      <h2 className="text-[18px] text-center">Guide Package Create</h2>

      <div className="flex justify-center items-center gap-x-[20px] mt-[20px]">
        <p>Is Item Is Inclusive by Default: </p>
        <div style={switchStyles}>
          <label
            style={sliderStyles}
            className={`${
              requestToPayload?.tour_package_guide_is_inclusive
                ? "bg-btn-primary"
                : "bg-btn-orange"
            } `}
          >
            <input
              type="checkbox"
              checked={requestToPayload?.tour_package_guide_is_inclusive}
              onChange={() =>
                setRequestToPayload((prev) => ({
                  ...prev,
                  tour_package_guide_is_inclusive:
                    !requestToPayload?.tour_package_guide_is_inclusive,
                }))
              }
              style={{ display: "none" }}
            />
            <span
              style={{
                transform: `translateX(${
                  requestToPayload?.tour_package_guide_is_inclusive
                    ? "26px"
                    : "0"
                })`,
                transition: "transform 0.3s ease",
                content: '""',
                position: "absolute",
                height: "26px",
                width: "26px",
                left: "4px",
                bottom: "4px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            ></span>
          </label>
          {/* <p>{open ? "Active" : "Disabled"}</p> */}
        </div>
      </div>

      <div className="flex justify-center items-center h-full mt-[30px] flex-col relative">
        <div className="w-full  flex flex-col gap-y-[20px]">
          {requestToPayload?.tour_package_guide_options?.map(
            (transfer, index) => (
              <div
                onClick={() => handleEdit(transfer, index)}
                className="bg-btn-secondary text-center text-bg-white py-[10px] rounded-[6px]"
              >
                Option {index + 1}
              </div>
            )
          )}
        </div>
        <button
          onClick={() => {
            setToggleModal(true);
            setTargets({});
            setEditInd(null);
          }}
          className="mt-[30px] text-bg-white bg-btn-secondary active:bg-btn-secondary hover:bg-btn-primary h-[39px]  px-[20px] rounded-[6px]"
        >
          Add Guide Option
        </button>

        <div className="w-full  mt-[30px] bottom-[30px]">
          <div className="flex justify-between items-center w-full">
            <button
              onClick={() => setActiveStep(6)}
              className="bg-btn-secondary w-[130px]  h-[39px] rounded-[4px] text-bg-white hover:bg-btn-primary active:bg-btn-secondary"
            >
              Skip
            </button>
            <button
              onClick={() => setActiveStep(6)}
              className="bg-btn-secondary w-[130px]  h-[39px] rounded-[4px] text-bg-white hover:bg-btn-primary active:bg-btn-secondary"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ModalCommon
        shown={toggleModal}
        width="md:max-w-[744px] md:min-w-[744px] xl:max-w-[1148px] min-w-[100vw]"
        height="h-[100vh] xl:min-h-[calc(100vh-100px)] xl:max-h-[calc(100vh-100px)] md:min-h-[calc(100vh-100px)] md:max-h-[calc(100vh-100px)]"
        outSideOff={false}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px]  max-h-[calc(100vh-250px)]  md:max-h-[calc(100vh-200px)]  overflow-y-scroll">
          <DragAndDropGuide
            keyName={"package"}
            items={guidePackageList}
            selectedNumber={convertData?.number_of_days}
            IdentifyName={"Select"}
            setTargets={setTargets}
            targets={targets}
          />
          <div className="flex justify-center items-center ">
            <div className="flex justify-start items-center gap-x-[40px] absolute bottom-[20px]">
              <button
                onClick={() => setToggleModal(false)}
                className="text-bg-white bg-btn-orange active:bg-btn-secondary hover:bg-btn-orange h-[39px]  px-[20px] rounded-[6px]"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSetAccommodation()}
                className="text-bg-white bg-btn-secondary active:bg-btn-secondary hover:bg-btn-primary h-[39px]  px-[20px] rounded-[6px]"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
}
