import React, { useEffect, useState } from "react";
import { numberInputOnWheelPreventChange } from "../utils/common";
import Select from "react-select";
import {
  animatedComponents,
  colorStyles,
} from "../../ToursMarchent/Utilities/SelectAnimation";
import { authAxios } from "../../utils/axiosWrapper";
import {
  MERCHANT_EXCURSION_ENDPOINT,
  MERCHANT_PRICE_AVAILABILITY,
} from "../../contants/Endpoints";
import { makeSelectOptions } from "../utils/price";
import { ToastContainer, toast } from "react-toastify";

const ExcursionMerchantPriceTools = () => {
  const [showError, setShowError] = useState(false);
  const [excursionAllData, setExcursionAllData] = useState([]);
  const [excursionSelectedOptions, setExcursionSelectedOptions] = useState([]);
  const [createPackageAllOptions, setCreatePackageAllOptions] = useState([]);
  const [createPackageSelectedOptions, setCreatePackageSelectedOptions] =
    useState([]);
  const [timeSlotOptions, setTimeSlotOptions] = useState([]);
  const [timeSlotSelectedOptions, setTimeSlotSelectedOptions] = useState([]);
  const [groupData, setGroupData] = useState({
    rack_price: "",
    corporate_price: "",
    corporate_discount: "",
    total_seats: "",
    bookable_seats: "",
    deactivated_seats: "",
  });
  const [dynamicField, setDynamicField] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    authAxios
      .get(
        `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ALL_NAME_BY_ID}`
      )
      .then((res) => {
        console.log({ res });
        setExcursionAllData(
          makeSelectOptions(res?.data?.excursionIdAndNameData)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (excursionSelectedOptions?.value > 0) {
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ALL_CREATE_PACKAGE_BY_ID}?excursionId=${excursionSelectedOptions?.value}&requestId=123`
        )
        .then((res) => {
          console.log("create pack: ", res);
          setCreatePackageAllOptions(
            makeSelectOptions(res?.data?.excursionIdAndNameData)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [excursionSelectedOptions]);

  useEffect(() => {
    if (createPackageSelectedOptions?.value > 0) {
      authAxios
        .get(
          `${MERCHANT_EXCURSION_ENDPOINT.MERCHANT_EXCURSION_GET_ALL_CREATE_PACKAGE_TIME_SLOT_BY_ID}?excursionPackageId=${createPackageSelectedOptions?.value}&requestId=1234`
        )
        .then((res) => {
          console.log("create pack time slot: ", res);
          setTimeSlotOptions(
            makeSelectOptions(res?.data?.excursionIdAndNameData)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [createPackageSelectedOptions?.value]);

  useEffect(() => {
    if (timeSlotSelectedOptions?.label && timeSlotSelectedOptions?.value) {
      setDynamicField({
        name: "excursionPackageTimeSlotId",
        id: timeSlotSelectedOptions.value,
      });
    } else if (
      createPackageSelectedOptions?.label &&
      createPackageSelectedOptions?.value
    ) {
      setDynamicField({
        name: "excursionPackageId",
        id: createPackageSelectedOptions.value,
      });
    } else if (
      excursionSelectedOptions?.label &&
      excursionSelectedOptions?.value
    ) {
      setDynamicField({
        name: "excursionId",
        id: excursionSelectedOptions.value,
      });
    }
  }, [
    timeSlotSelectedOptions,
    createPackageSelectedOptions,
    excursionSelectedOptions,
  ]);

  // console.log({ excursionSelectedOptions });
  // console.log({ createPackageSelectedOptions });
  // console.log("createPackageAllOptions: ", createPackageAllOptions.length);
  // console.log("timeSlotOptions: ", timeSlotOptions.length);
  // // console.log({timeSlotSelectedOptions});
  // console.log("timeSlotSelectedOptions: ", timeSlotSelectedOptions.length);
  // console.log({ dynamicField });

  useEffect(() => {
    setCreatePackageSelectedOptions([]);
    setTimeSlotSelectedOptions([]);
  }, [excursionSelectedOptions]);

  useEffect(() => {
    setTimeSlotSelectedOptions([]);
  }, [createPackageSelectedOptions]);

  const payloadMethod = {
    bookableSeats: parseFloat(groupData?.bookable_seats),
    corporatePrice: parseFloat(groupData?.corporate_price),
    deactivatedSeats: parseFloat(groupData?.corporate_price),
    deleteAllFilters: true,
    [dynamicField?.name]: parseFloat(dynamicField?.id),
    rackPrice: parseFloat(groupData?.rack_price),
    requestId: "1234",
    totalSeats: parseFloat(groupData?.total_seats),
  };

  console.log({ groupData });
  console.log({showError})

  const onSubmitData = () => {
    console.log(parseInt(groupData?.rack_price))
    if(groupData?.rack_price?.length === 0 || groupData?.corporate_price?.length === 0 || groupData?.corporate_discount?.length === 0 || groupData?.total_seats?.length === 0 || groupData?.bookable_seats?.length === 0 || groupData?.deactivated_seats?.length === 0){
      setShowError(true)
      console.log("clicked")
     }
    // console.log({ groupData });
    // console.log({ payloadMethod });
    // if (
    //   excursionAllData?.length > 0 &&
    //   createPackageAllOptions?.length > 0 &&
    //   timeSlotOptions?.length > 0
    // ) {
    //   console.log("call 3:", { payloadMethod });
    //   authAxios
    //     .post(
    //       `${MERCHANT_PRICE_AVAILABILITY?.MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_PACKAGE_TIME_SLOT_SET}`,
    //       payloadMethod
    //     )
    //     .then((res) => {
    //       console.log({ res });
    //       toast.success(
    //         "Successfully added excursion package time-slot price."
    //       );
    //     })
    //     .catch((err) => console.log(err));
    // } else if (
    //   excursionAllData?.length > 0 &&
    //   createPackageAllOptions?.length > 0
    // ) {
    //   console.log("call 2:", { payloadMethod });
    //   authAxios
    //     .post(
    //       `${MERCHANT_PRICE_AVAILABILITY?.MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_PACKAGE_SET}`,
    //       payloadMethod
    //     )
    //     .then((res) => {
    //       console.log({ res });
    //       toast.success("Successfully added excursion package price.");
    //     })
    //     .catch((err) => console.log(err));
    // } else if (excursionAllData?.length > 0) {
    //   console.log("call 1:", { payloadMethod });
    //   authAxios
    //     .post(
    //       `${MERCHANT_PRICE_AVAILABILITY?.MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_SET}`,
    //       payloadMethod
    //     )
    //     .then((res) => {
    //       console.log({ res });
    //       toast.success("Successfully added excursion price.");
    //     })
    //     .catch((err) => console.log(err));
    // }





    // authAxios.post(`${MERCHANT_PRICE_AVAILABILITY?.MERCHANT_POST_PRICE_AVAILABILITY_EXCURSION_SET}`, payloadMethod)
    // .then((res) => {
    //   console.log({res})
    // })
    // .catch((err) => console.log(err))

    
  };

  // useEffect(() => {
  //   handleAmountAndPercent();
  // }, [groupData.corporate_price, groupData.corporate_discount]);

  // const handleAmountAndPercent = () => {
  //   const {corporate_price, corporate_discount, rack_price} = groupData;
  //   setGroupData((prev) => ({
  //     ...prev,
  //     corporate_discount: rack_price - rack_price * (corporate_price /100)
  //   }))
  // }

  // const handleAmountAndCorporateRate = () => {
  //   const {corporate_price, corporate_discount, rack_price} = groupData;
  //   setGroupData((prev) => ({
  //     ...prev,
  //     corporate_discount: rack_price - rack_price * (corporate_price /100)
  //   }))
  // }


  console.log({groupData})

  return (
    <div className="w-[343px] md:w-[712px] mx-auto">
      <div className=" mb-[30px]">
        <p className="text-center xl:text-[24px] md:text-[22px] text-[20px] text-btn-primary">
          <span className="border-x-[4px] px-[8px]">
            {" "}
            Excursion Price Tools
          </span>
        </p>
        <p className="text-center text-btn-secondary text-[14px]">
          Set competitive rates, analyze market trends, and maximize revenue
          effortlessly for a more profitable venture.
        </p>
      </div>

      <div className="shadow-customShadow bg-btn-base py-[20px] px-[40px] rounded-[4px]">
        <p
          className="text-center xl:text-[24px] md:text-[22px] text-[20px] bg-btn-base text-btn-primary 
      shadow-customShadow py-[8px]  rounded"
        >
          1. Set Excursion Price
        </p>
        <div className=" gap-x-[20px]">
          {/* New Excursion */}
          <div className="my-[12px] w-full">
            <p className="text-[20px] text-btn-secondary">Excursion: </p>
            <div className="rounded-[4px]">
              <Select
                options={excursionAllData}
                components={animatedComponents}
                styles={colorStyles}
                value={excursionSelectedOptions}
                placeholder={"Select excursion: "}
                onChange={(data) => {
                  setExcursionSelectedOptions(data);
                }}
              ></Select>
            </div>

            {(showError && groupData?.excursionSelectedOptions?.length === 0) && (<p className="text-[13px] text-[#f87171] ">
                  This field is required
                </p>)}
          </div>
          {/* Create Package */}
          {createPackageAllOptions?.length >= 1 && (
            <div className="my-[12px] w-full">
              <p className="text-[20px] text-btn-secondary">Create package: </p>
              <div className="rounded-[4px]">
                <Select
                  options={createPackageAllOptions}
                  components={animatedComponents}
                  styles={colorStyles}
                  value={createPackageSelectedOptions}
                  placeholder={"Select Create: "}
                  onChange={(data) => {
                    setCreatePackageSelectedOptions(data);
                  }}
                ></Select>
              </div>

              {/* {(showError && selectLanguages?.length === 0) && (<p className="text-[13px] text-[#f87171] ">
                  This field is required
                </p>)} */}
            </div>
          )}

          {/* New Time slot */}
          {timeSlotOptions?.length >= 1 && (
            <div className="my-[12px] w-full">
              <p className="text-[20px] text-btn-secondary">Time slot: </p>
              <div className="rounded-[4px]">
                <Select
                  options={timeSlotOptions}
                  components={animatedComponents}
                  styles={colorStyles}
                  value={timeSlotSelectedOptions}
                  placeholder={"Select time slot: "}
                  onChange={(data) => {
                    setTimeSlotSelectedOptions(data);
                  }}
                ></Select>
              </div>

              {/* {(showError && selectLanguages?.length === 0) && (<p className="text-[13px] text-[#f87171] ">
                  This field is required
                </p>)} */}
            </div>
          )}
        </div>
        <div className="my-[8px] w-full">
          <p className="text-btn-secondary text-[14px]">Rack Price: </p>
          <div className="rounded">
            <input
              className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
              placeholder="Enter your rack price : "
              type="number"
              onChange={(e) => {
                const inputVal = e.target.value;
                setGroupData((prev) => ({
                  ...prev,
                  rack_price: inputVal,
                }));
              }}
              value={groupData?.rack_price}
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>
          {showError && groupData?.rack_price?.length === 0 && (
            <p className="text-[13px] text-[#f87171] mt-[2px]">
              This field is required.
            </p>
          )}
        </div>

        <div className="">
          <div className="my-[8px] w-full">
            <p className="text-btn-secondary text-[14px]">Corporate Rate: </p>
            <div className="rounded">
              <input
                className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                placeholder="Corporate Rate: "
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;
                  setGroupData((prev) => ({
                    ...prev,
                    corporate_price: inputVal,
                    corporate_discount: groupData?.rack_price - groupData?.rack_price * (inputVal /100)
                  }));
                }}
                value={groupData?.corporate_price}
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
            {showError && groupData?.corporate_price?.length === 0 && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                This field is required.
              </p>
            )}
            {(showError && parseInt(groupData?.rack_price) < parseInt(groupData?.corporate_price)) && (<p className="text-[13px] text-[#f87171] ">
                  Corporate rate must be lower than rack price
                </p>)}
          </div>
          <div className="my-[8px] w-full">
            <p className="text-btn-secondary text-[14px]">
              Corporate discount:
            </p>
            <div className="flex items-center rounded">
              <input
                className="w-full p-[8px] rounded-l border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                placeholder="Discount"
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;
                  setGroupData((prev) => ({
                    ...prev,
                    corporate_discount: inputVal,
                    corporate_price: ((groupData?.rack_price - groupData?.corporate_price)*100)/100
                  }));
                }}
                value={groupData?.corporate_discount}
                onWheel={numberInputOnWheelPreventChange}
              />
              <span className="py-[7px] px-[64px] text-[20px] bg-[#afc1c8] text-btn-primary rounded-r">
                %
              </span>
            </div>
            {showError && groupData?.corporate_discount?.length === 0 && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                This field is required.
              </p>
            )}
            {/* {(parseFloat(groupData?.corporate_discount <= 0) || parseFloat(groupData?.corporate_discount >= 100)) && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                Value must be between 0-100.
              </p>
            )} */}
          </div>
        </div>

        <div className="">
          <div className="my-[8px] w-full">
            <p className="text-btn-secondary text-[14px]">Total seats </p>
            <div className="rounded">
              <input
                className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                placeholder="Total seats: "
                type="email"
                onChange={(e) => {
                  const inputVal = e.target.value;
                  setGroupData((prev) => ({
                    ...prev,
                    total_seats: inputVal,
                  }));
                }}
                value={groupData?.total_seats}
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
            {showError && groupData?.total_seats?.length === 0 && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                This field is required.
              </p>
            )}
          </div>
          <div className="my-[8px] w-full">
            <p className="text-btn-secondary text-[14px]">Bookable seats: </p>
            <div className="rounded">
              <input
                className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                placeholder="Bookable seats: "
                type="email"
                onChange={(e) => {
                  const inputVal = e.target.value;
                  setGroupData((prev) => ({
                    ...prev,
                    bookable_seats: inputVal,
                  }));
                }}
                value={groupData?.bookable_seats}
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
            {showError && groupData?.bookable_seats?.length === 0 && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                This field is required.
              </p>
            )}
          </div>
          <div className="my-[8px] w-full">
            <p className="text-btn-secondary text-[14px]">Deactivated seats </p>
            <div className="rounded">
              <input
                className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] outline-none"
                placeholder="Deactivated seats: "
                type="email"
                onChange={(e) => {
                  const inputVal = e.target.value;
                  setGroupData((prev) => ({
                    ...prev,
                    deactivated_seats: inputVal,
                  }));
                }}
                value={groupData?.deactivated_seats}
                onWheel={numberInputOnWheelPreventChange}
              />
            </div>
            {showError && groupData?.deactivated_seats?.length ===  0 && (
              <p className="text-[13px] text-[#f87171] mt-[2px]">
                This field is required.
              </p>
            )}
          </div>
        </div>

        <div>
          <button
            onClick={() => onSubmitData()}
            className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
            // disabled={checkDisabled()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExcursionMerchantPriceTools;
