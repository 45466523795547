import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { authAxios } from "../../utils/axiosWrapper";
import { FAQ_ENDPOINTS } from "../../contants/Endpoints";
import TourMerchantButton from "../../ToursMarchent/TourMerchantButton";
import ModalCommon from "../../components/common/ModalCommon";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import CustomTextEditor from "../CustomTextEditor";
import ReactModal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "300px",
    width: "600px",
    borderRadius: "32px",
    backgroundColor: "#FFFBEF",
  },
};

const AdminFaqGroupId = () => {
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const groupName = location.state?.groupName;

  const [allContentData, setAllContentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalSecond, setToggleModalSecond] = useState(false);
  const [isNewContent, setIsNewContent] = useState(false);
  const [showError, setShowError] = useState(false);
  const [singleContentId, setSingleContentId] = useState();
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");


  const getAllFaqContentByGroupId = () => {
    setIsLoading(true);
    authAxios
      .get(
        `${FAQ_ENDPOINTS.ADMIN_GET_FAQ_FAQ_CONTENT_BY_FAQ_GROUP_ID}/${parseInt(
          id
        )}?requestId=123`
      )
      .then((res) => {
        setAllContentData(res?.data?.data_list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllFaqContentByGroupId();
  }, []);

  const resetData = () => {
    setAnswer("");
    setQuestion("");
  };

  const payloadMethod = {
    answer: answer,
    faqGroupId: parseInt(id),
    question: question,
    requestId: "123",
  };

  const updatePayloadMethod = {
    contentId: parseInt(singleContentId),
    faqGroupId: parseInt(id),
    newAnswer: answer,
    newQuestion: question,
    requestId: "123",
  };

  const onAddNew = () => {
    resetData();
    setIsNewContent(false);
    setToggleModal(true);
  };

  const indexAll = () => {
    setIsLoading(true);
    authAxios
      .put(`${FAQ_ENDPOINTS.ADMIN_PUT_FAQ_INDEX_ALL}`)
      .then((res) => {
        toast.success("indexing successfully!");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("indexing failed!");
      });
  };

  const onEditContent = (contentId) => {
    setIsNewContent(true);
    authAxios
      ?.get(`${FAQ_ENDPOINTS.ADMIN_GET_FAQ_CONTENT}${contentId}?requestId=1234`)
      .then((res) => {
        setQuestion(res?.data?.data?.question);
        setAnswer(res?.data?.data?.answer);
        setSingleContentId(res?.data?.data?.faq_content_id);
        setToggleModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
    // setToggleModal(true);
  };

  const onSubmitContent = () => {
    authAxios
      ?.post(`${FAQ_ENDPOINTS.ADMIN_POST_FAQ_CONTENT}`, payloadMethod)
      .then((res) => {
        toast.success("Successful added.");
        setToggleModal(false);
        getAllFaqContentByGroupId();
        indexAll();
      })
      .catch((err) => {
        console.log(err);
        toast.err("Added failed");
      });
  };

  const onUpdateContent = () => {
    authAxios
      ?.patch(`${FAQ_ENDPOINTS.ADMIN_PATCH_FAQ_CONTENT}`, updatePayloadMethod)
      .then((res) => {
        toast.success("Successful Updated.");
        setToggleModal(false);
        getAllFaqContentByGroupId();
        indexAll();
      })
      .catch((err) => {
        console.log(err);
        toast.err("Update failed");
      });
  };

  const onDeleteContent = (contentId) => {
    setToggleModalSecond(true);
    setSingleContentId(contentId);
  };

  const onConfirmDelete = () => {
    authAxios
      ?.delete(
        `${FAQ_ENDPOINTS.ADMIN_DELETE_FAQ_CONTENT_BY_ID}${id}/${singleContentId}?requestId=123`
      )
      .then((res) => {
        toast.success("Successfully deleted!");
        getAllFaqContentByGroupId();
        setToggleModalSecond(false);
      })
      .catch((err) => {
        toast.error("Failed to delete.");
        console.log(err);
      });
  };

  const checkGroupDataStatus = () => {
    return question?.length === 0 || answer?.length === 0;
  };

  return (
    <div className="container mx-auto">
      <h2 className="lg:text-[36px] md:text-[30px] text-[24px] mt-[40px] text-center text-btn-primary">
        FAQ Content
      </h2>
      <p className="text-center text-[14px] text-bg-semiblack mb-[10px]">
        Browse through our FAQ section for tailored answers on
        <span className="text-btn-secondary"> ({groupName})</span>.{" "}
      </p>

      <div
        className={`flex ${
          allContentData?.length === 0 ? "justify-center" : "justify-end"
        } mr-[20px]`}
      >
        <TourMerchantButton
          width={""}
          name={"Add New Content"}
          onClick={onAddNew}
        ></TourMerchantButton>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="mx-[20px]">
          {/* Show if data has */}
          {allContentData?.length === 0 && (
            <p className="text-[18px] text-center text-btn-secondary my-[20px]">
              Please add some new content.
            </p>
          )}
          {allContentData?.length > 0 && (
            <div>
              <table className="fl-table my-[20px]">
                <thead className="sticky top-[88px]">
                  <tr>
                    <th>Content ID</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allContentData?.length > 0 &&
                    allContentData?.map((property, index) => (
                      <tr key={index}>
                        <td>{property?.faq_content_id}</td>
                        <td className="whitespace-pre-line">
                          {property?.question}
                        </td>
                        <td className="whitespace-pre-line">
                          {/* {property?.answer} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: property?.answer,
                            }}
                          ></div>
                        </td>
                        <td className="flex justify-center gap-x-[8px]">
                          <button
                            onClick={() =>
                              onEditContent(property?.faq_content_id)
                            }
                            className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              onDeleteContent(property?.faq_content_id)
                            }
                            className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      <div>
      <ReactModal
              isOpen={toggleModal}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick={true}
              style={customStyles}
              onRequestClose={() => setToggleModal(false)}
            >
<div className="my-[20px] mx-[32px]">
            <p className="text-btn-primary text-center text-[20px]">
              Add a new content
            </p>

            <div>
              <div className="my-[16px] ">
                <p className="text-btn-secondary">Question: </p>
                <div className="rounded">
                  <input
                    className="w-full p-[8px]  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] "
                    placeholder="Question: "
                    type="text"
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setQuestion(inputVal);
                    }}
                    value={question}
                  />
                </div>
                {showError && question?.length === 0 && (
                  <p className="text-[13px] text-[#f87171] mt-[2px]">
                    Question is required.
                  </p>
                )}
              </div>
              {/* <div className="my-[16px] ">
                <p className="text-btn-secondary">Answer: </p>
                <div className="rounded">
                  <textarea
                    className="w-full min-h-[200px] p-[8px] outline-none  rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8] "
                    placeholder="Answer: "
                    type="text"
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      setGroupData((prev) => ({
                        ...prev,
                        answer: inputVal,
                      }));
                    }}
                    value={answer}
                  />
                </div>
                {showError && setGroupData?.answer?.length === 0 && (
                  <p className="text-[13px] text-[#f87171] mt-[2px]">
                    Answer is required.
                  </p>
                )}
              </div> */}
              {/* Editor */}
              <div>
              <p className="text-btn-secondary">Answer: </p>
                <CustomTextEditor
                  details={answer}
                  setDetails={setAnswer}
                  placeholderText={"Your Answer : "}
                ></CustomTextEditor>
              </div>
            </div>
            <div className={`pb-[10px]`}>
              {!isNewContent ? (
                <button
                  disabled={checkGroupDataStatus()}
                  onClick={() => onSubmitContent()}
                  className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
                >
                  Submit new content
                </button>
              ) : (
                <button
                  disabled={checkGroupDataStatus()}
                  onClick={() => onUpdateContent()}
                  className="bg-btn-primary text-bg-white w-full px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
                >
                  Update content
                </button>
              )}
            </div>
          </div>
              </ReactModal>
        

        <ModalCommon
          shown={toggleModalSecond}
          width="max-w-[280px]"
          height="min-h-[150px]"
          outSideOff={false}
          close={() => {
            setToggleModalSecond(false);
          }}
        >
          <div className="p-[24px]">
            <p className="text-btn-primary">Are you sure you want to delete?</p>
            <div className="flex justify-between">
              <button
                onClick={() => setToggleModalSecond(false)}
                className="bg-btn-primary text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg "
              >
                CANCEL
              </button>
              <button
                onClick={() => onConfirmDelete()}
                className="bg-[#ef4444] text-bg-white  px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-[#ef4444] rounded-md shadow-md transition-all duration-500 hover:text-[#ef4444] hover:shadow-lg "
              >
                CONFIRM
              </button>
            </div>
          </div>
        </ModalCommon>
      </div>
    </div>
  );
};

export default AdminFaqGroupId;
