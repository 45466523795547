import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputComponent from "../../components/InputComponent";
import Loading from "../../components/Loader/Loading";
import FileMultipleCategory from "../../components/multipleimage/FileMultipleCategory";
import SingleSelectFromSimpleArray from "../../components/singleSelect/SingleSelectFromSimpleArray";
import SingleSelectParent from "../../components/singleSelect/SingleSelectParent";
import { adminRoute } from "../../contants/Constants";
import {
  apiEndPoint,
  INDEXING,
  PROPERTY_ENDPOINTS,
} from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import { toastMessage } from "../../utils/toast";
// import { GoogleMap,  LoadScript } from '@react-google-maps/api';
import GoogleMapReact from "google-map-react";
import { IoLocationSharp } from "react-icons/io5";
import { toast } from "react-toastify";
const AnyReactComponent = ({ text }) => (
  <div>
    <IoLocationSharp className="w-[24px] h-[24px] text-[#ef4444]" />
  </div>
);

export default function AdminAddLocation(props, { edit, fileIsUploading }) {
  const { setLoadderSpinner } = props;

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [zipCode, setZipCode] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState(0);
  //const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // api country data
  const [allCountries, setAllCountries] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiMunicipility, setApiMunicipility] = useState();
  const [apicomm, setApicomm] = useState();
  const [apiDivision, setApiDivision] = useState();
  const [apiZips, setApiZips] = useState();

  const [countryCode, setCountryCode] = useState("");
  const [division, setDivision] = useState("");
  const [city, setCity] = useState("");
  const [municipility, setMunicipility] = useState("");
  const [community, setCommunity] = useState("");

  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
  const [userSelectLocation, setUserSelectLocation] = useState({
    country: "",
    countryCode: "",
    countryCode3: "",
    currency: "",
    currencyCode: "",
    currencySymbol: "",
    phoneCode: "",
    place: "",
  });

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 21.416928583771007,
      lng: 91.98686727507086,
    },
    zoom: 12,
  });

  const [markerPosition, setMarkerPosition] = useState({
    lat: 59.955413,
    lng: 30.337844,
  });

  const handleMapClick = (e) => {
    setMarkerPosition({
      lat: e.lat,
      lng: e.lng,
    });
  };

  const { selectedData, setIsEdit, open } = props;
  useEffect(() => {
    if (selectedData) {
      setCountryCode(selectedData?.country?.countryCode);
      setDivision(selectedData?.admin1);
      setCity(selectedData?.admin2);
      setMunicipility(selectedData?.admin3);
      setCommunity(selectedData?.admin4);
      setAddress(selectedData?.address);
      setDescription(selectedData?.metaData?.description);
      setName(selectedData?.name);
      setImgUrl(selectedData?.imageUrl);
      setId(selectedData?.id);
      setZipCode(selectedData?.zipCode);
      setTitle(selectedData?.metaData?.title);
      setMarkerPosition({
        lat: selectedData?.coordinate?.lat,
        lng: selectedData?.coordinate?.lng,
      });
      setDefaultProps((prev) => ({
        ...prev,
        center: {
          lat: selectedData?.coordinate?.lat,
          lng: selectedData?.coordinate?.lng,
        },
      }));
    }
  }, [selectedData]);

  const indexing = () => {
    setIsLoading(true);
    authAxios.put(`${apiEndPoint?.ADMIN_LOCATION_INDEXING}`).then((res) => {
      authAxios.put(`${INDEXING?.PLACE_GENERATE}`).then((res) => {
        // toastMessage("Generate successfully!");
        authAxios
          .put(`${INDEXING?.PLACE_INDEX}`)
          .then((res) => {
            // setIsEdit(false);
            window.location.href = adminRoute?.ADMIN_INSPIRED_LOCATION;
            toastMessage("indexing successfully!");
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      });
    });
  };


  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage?.getItem("jwt")}`,
    };
    noAuthAxios
      .get(
        `${apiEndPoint?.GET_COUNTRY_DETAILS}?requestId=${localStorage?.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response?.data?.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // fetch country
  useEffect(() => {
    if (countryCode) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      noAuthAxios
        .get(
          `${
            PROPERTY_ENDPOINTS?.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE
          }${countryCode}?requestId=${localStorage.getItem("id")}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setApiDivision(response.data.admin1s);
          setApiCity(response.data.admin2s);
          setApiMunicipility(response.data.admin3s);
          setApicomm(response.data.places);
          setApiZips(response.data.zips);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [countryCode]);

  useEffect(() => {
    const lastImageUrl =
      urlTempStorageArray[urlTempStorageArray?.length - 1]?.url;

    if (lastImageUrl) {
      setImgUrl(lastImageUrl);
    }
  }, [urlTempStorageArray]);

  useEffect(() => {
    if (countryCode?.length > 0 && zipCode > 0) {
      authAxios
        ?.get(
          `${apiEndPoint?.GET_COUNTRY_BY_COUNTRY_CODE_AND_ZIP_CODE}/${countryCode}/${zipCode}?requestId=123`
        )
        .then((res) => {
          setDivision(res?.data?.data?.admin1);
          setCity(res?.data?.data?.admin2);
          setMunicipility(res?.data?.data?.admin3);
          setCommunity(res?.data?.data?.place);
          setUserSelectLocation((prev) => ({
            ...prev,
            country: res?.data?.data?.country?.country,
            countryCode: res?.data?.data?.country?.countryCode,
            countryCode3: res?.data?.data?.country?.countryCode3,
            currency: res?.data?.data?.country?.currency,
            currencyCode: res?.data?.data?.country?.currencyCode,
            currencySymbol: res?.data?.data?.country?.currencySymbol,
            phoneCode: res?.data?.data?.country?.phoneCode,
            place: res?.data?.data?.phoneCode,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [countryCode, zipCode]);

  const payloadMethod = {
    coordinate: {
      lat: markerPosition?.lat,
      lng: markerPosition?.lng,
    },
    country: countryCode,
    id: id,
    imageUrl: imgUrl,
    metaData: {
      description: description,
      title: title,
    },
    name: name,
    requestId: "123",
    shortAddress: community,
    zipCode: zipCode,
  };

  const onSubmitLocation = () => {
    if (
      name?.length === 0 ||
      description?.length === 0 ||
      countryCode?.length === 0 ||
      address?.length === 0 ||
      title?.length === 0 ||
      address?.length === 0
    ) {
      setIsError(true);
      toast.error("Please fill all the field.");
    } else {
      setIsError(false);
      setIsLoading(true);
      authAxios
        ?.post(`${apiEndPoint?.ADMIN_ADD_LOCATION}`, payloadMethod)
        .then((res) => {
          toast.success(
            "Successfully added Location. Please wait for indexing."
          );
          indexing();
          // setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Add Failed, Please fill up all the data.");
        });
    }
  };

  const checkDisabled = () => {
    return (
      name?.length === 0 ||
      description?.length === 0 ||
      countryCode?.length === 0 ||
      address?.length === 0 ||
      title?.length === 0 ||
      zipCode?.length === 0 || 
      municipility?.length === 0 || 
      community?.length === 0 || 
      city?.length === 0 || 
      division?.length === 0 ||
      markerPosition?.lat?.length === 0 || 
      markerPosition?.lng?.length === 0
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <div className="container mx-auto flex flex-col items-center w-full my-12">
          <div className="flex gap-4 items-center mt-6">
            <button onClick={() => (open ? setIsEdit(false) : navigate(-1))}>
              {" "}
              <i className="fa fa-arrow-left"> </i>{" "}
            </button>
            <div className="text-2xl">Add Inspired Locations</div>
          </div>

          <div className="xl:w-full w-full mt-6 mx-auto">
            <div className="flex items-center justify-center">
              <div className="w-full ml-[49px]">
                <div>Location Name</div>
                <InputComponent
                  inputValue={name}
                  setInputValue={setName}
                  name="name"
                  placeholder={"Name"}
                  width={"w-0"}
                  required={true}
                />
              </div>

              <div className="mt-2 w-full">
                <div>Country</div>
                <SingleSelectParent
                  required={true}
                  selectedValue={countryCode}
                  setSelectedValue={setCountryCode}
                  data={allCountries}
                  placeholder="Country"
                  displayField={"country"}
                  selectedFieldName="countryCode"
                  // isAdmin={
                  //     process.env
                  //         .REACT_APP_USER_TYPE ===
                  //     UserType.ADMIN
                  //         ? true
                  //         : false
                  // }
                  isReadOnly={false}
                  // rejectionArray={
                  //     addressRejectArray
                  // }
                  // setRejectionArray={
                  //     setAddressRejectArray
                  // }
                  name="country"
                />
              </div>
            </div>
            <div className="flex">
              <div className="mt-2 w-full ml-[49px]">
                <div>Zip Code</div>
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiZips}
                  selectedValue={zipCode}
                  setSelectedValue={setZipCode}
                  placeholder="Zip Code"
                  // isAdmin={
                  //     process.env
                  //         .REACT_APP_USER_TYPE ===
                  //     UserType.ADMIN
                  //         ? true
                  //         : false
                  // }
                  // isReadOnly={true}
                  // rejectionArray={
                  //     addressRejectArray
                  // }
                  // setRejectionArray={
                  //     setAddressRejectArray
                  // }
                  name="zipCode"
                />
              </div>

              <div className="w-full mt-[8px]">
                <div>Division</div>
                <InputComponent
                  inputValue={division}
                  setInputValue={setDivision}
                  name="division"
                  placeholder={"Division"}
                  isReadOnly={true}
                  width={"w-0"}
                />
              </div>

              {/* <div className="mt-2 w-full">
            <div>Division</div>
            <SingleSelectFromSimpleArray
              required={true}
              data={apiDivision}
              selectedValue={division}
              setSelectedValue={setDivision}
              placeholder="Division"
              isReadOnly={true}
              // isAdmin={
              //     process.env
              //         .REACT_APP_USER_TYPE ===
              //     UserType.ADMIN
              //         ? true
              //         : false
              // }
              // isReadOnly={false}
              // rejectionArray={
              //     addressRejectArray
              // }
              // setRejectionArray={
              //     setAddressRejectArray
              // }
              name="division"
            />
          </div> */}
            </div>
            <div className="flex">
              {/* <div className="mt-2 ml-[49px] w-full">
            <div>City</div>
            <SingleSelectFromSimpleArray
              required={true}
              data={apiCity}
              selectedValue={city}
              setSelectedValue={setCity}
              placeholder="City"
              // isAdmin={
              //     process.env
              //         .REACT_APP_USER_TYPE ===
              //     UserType.ADMIN
              //         ? true
              //         : false
              // }
              // isReadOnly={false}
              // rejectionArray={
              //     addressRejectArray
              // }
              // setRejectionArray={
              //     setAddressRejectArray
              // }
              name="city"
            />
          </div> */}

              <div className="w-full mt-[8px] ml-[49px]">
                <div>City</div>
                <InputComponent
                  inputValue={city}
                  setInputValue={setCity}
                  name="city"
                  placeholder={"City"}
                  isReadOnly={true}
                  width={"w-0"}
                />
              </div>

              {/* <div className="grid grid-cols-1 mt-2 w-full">
            <div>Municipality</div>
            <SingleSelectFromSimpleArray
              required={true}
              data={apiMunicipility}
              selectedValue={municipility}
              setSelectedValue={setMunicipility}
              placeholder="Municipility"
              // isAdmin={
              //     process.env
              //         .REACT_APP_USER_TYPE ===
              //     UserType.ADMIN
              //         ? true
              //         : false
              // }
              // isReadOnly={false}
              // rejectionArray={
              //     addressRejectArray
              // }
              // setRejectionArray={
              //     setAddressRejectArray
              // }
              name="municipility"
            />
          </div> */}
              <div className="w-full mt-[8px]">
                <div>Municipality</div>
                <InputComponent
                  inputValue={municipility}
                  setInputValue={setMunicipility}
                  name="municipality"
                  placeholder={"Municipality"}
                  isReadOnly={true}
                  width={"w-0"}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mt-[8px] ml-[49px]">
                <div>Community</div>
                <InputComponent
                  inputValue={community}
                  setInputValue={setCommunity}
                  name="community"
                  placeholder={"Community"}
                  isReadOnly={true}
                  width={"w-0"}
                />
              </div>
              <div className="w-full">
                <div className="mt-2">Address</div>
                <InputComponent
                  required={true}
                  inputValue={address}
                  setInputValue={setAddress}
                  name="address"
                  // number={true}
                  placeholder={"Address"}
                  width={"w-0"}
                />
              </div>
            </div>
            {/* New Added */}
            <div className="flex justify-center">
              <div className="w-full ml-[49px] ">
                <div className="mt-2">Title</div>
                <InputComponent
                  inputValue={title}
                  setInputValue={setTitle}
                  name="Title"
                  // number={true}
                  placeholder={"Title"}
                  width={"w-0"}
                  required={true}
                />
              </div>
            </div>
            <div className="mx-[49px]">
              <div className="mt-2">Location Description</div>
              <textarea
                value={description}
                onChange={(e) => setDescription(e?.target?.value)}
                name="description"
                placeholder={"Description"}
                rows="4"
                className="w-full bg-bg-primary outline-none border border-[#9e9989] rounded-[8px] p-4 "
              />
              {isError && description?.length === 0 && (
                <p className="text-[12px] text-[#f87171]">
                  This field is required.
                </p>
              )}
            </div>

            {/* Lat and lng Add */}
            <div className="flex mx-[49px] gap-x-[40px] my-[8px]">
            <div className=" w-full">
              <p className="">latitude: </p>
              <div className="rounded-[8px]">
                <input
                  className="w-full p-[12px] rounded border-[1px] bg-btn-base"
                  placeholder="latitude : "
                  type="text"
                  value={markerPosition?.lat || ""}
                  onChange={(e) =>
                    setMarkerPosition((prev) => ({
                      ...prev,
                      lat: e.target.value,
                    }))
                  }
                />
                {isError && markerPosition?.lat?.length === 0 && (
                <p className="text-[12px] text-[#f87171]">
                  This field is required.
                </p>
              )}
              </div>
            </div>
            <div className=" w-full">
              <p className="">longitude: </p>
              <div className="rounded-[8px]">
                <input
                  className="w-full p-[12px] rounded border-[1px] bg-btn-base"
                  placeholder="longitude : "
                  type="text"
                  value={markerPosition?.lng || ""}
                  onChange={(e) =>
                    setMarkerPosition((prev) => ({
                      ...prev,
                      lng: e.target.value,
                    }))
                  }
                />
                {isError && markerPosition?.lng?.length === 0 && (
                <p className="text-[12px] text-[#f87171]">
                  This field is required.
                </p>
              )}
              </div>
            </div>
            </div>
          
            <div className="mx-[49px]">
              <div className="mt-4 mb-2">Upload Image:</div>
              {/* <RemoveableImageUploadViewCategory/> */}
              <div className="flex">
                <div className="mb-10  w-3/5">
                  <FileMultipleCategory
                    width={"w-full"}
                    id={1}
                    setNeedUpdate={() => {}}
                    urlTempStorageArray={urlTempStorageArray}
                    setUrlTempStorageArray={setUrlTempStorageArray}
                    multiple={false}
                    // setIsLoading={setIsLoading}
                  />
                </div>
                {isLoading ? (
                  <div
                    className={`flex items-center justify-center  w-2/5 ${
                      urlTempStorageArray?.length ? "h-[245px]" : ""
                    }`}
                  >
                    <Loading />
                  </div>
                ) : (
                  <div className="w-2/5 ml-[20px]">
                    <img
                      className={`rounded-lg w-full ${
                        urlTempStorageArray?.length || imgUrl ? "h-[250px]" : ""
                      }`}
                      src={imgUrl || selectedData?.imageUrl || ""}
                      alt="Ghuddy"
                    />
                    <div className="text-txt-primary text-center mt-[10px] text-[12px]">
                      Image File:{" "}
                      {urlTempStorageArray?.length > 0
                        ? urlTempStorageArray[urlTempStorageArray?.length - 1]
                            .fileName
                        : `${name ? name : "No"} image`}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="ml-[49px]">
              <div className="">
                <div style={{ height: "100vh", width: "93.5%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyB_av7qSBKUwLjk8Q4VDdLT5P7sUSCLgSY",
                    }}
                    // bootstrapURLKeys={{ key: ""}}
                    defaultCenter={defaultProps?.center}
                    defaultZoom={defaultProps?.zoom}
                    onClick={handleMapClick}
                    onChange={(e) => {
                      setMarkerPosition((prev) => ({
                        ...prev,
                        lat: e?.center?.lat,
                        lng: e?.center?.lng,
                      }));
                    }}
                  >
                    <AnyReactComponent
                      lat={markerPosition?.lat}
                      lng={markerPosition?.lng}
                      text="My Marker"
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
            <div className=" flex justify-center my-[20px]">
              <button
                onClick={() => onSubmitLocation()}
                className="bg-btn-primary text-bg-white w-[400px] px-[10px] py-[8px] my-[16px]  hover:bg-[#48c9af16] border-[2px] border-btn-primary rounded-md shadow-md transition-all duration-500 hover:text-btn-primary hover:shadow-lg disabled:bg-btn-border disabled:border-btn-border disabled:hover:text-bg-white"
                disabled={checkDisabled()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}